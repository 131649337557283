/**
 * Template for Specialty Page Click Shield
 * Uses ES6 template literal to composite the markup for the overlay
 */
import { htmlNode } from 'utils';

export default () => htmlNode`
    <div class="specialty-page__click-shield"></div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

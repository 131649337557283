import { htmlNode } from 'utils';

/**
 * @function
 * @name renderAMGIcons
 * @description render AMG Icons
 * @param {Object} dealer object
 * @return {String} template string
 */
const renderAMGIcons = (dealer) => {
    let str = '';
    if (dealer.isAmg) {
        str += '<i class="dealer-info__icon icon-AMG_logo-performance-center"></i>';
    }
    if (dealer.isAmgElite) {
        str += '<i class="dealer-info__icon icon-AMG_logo-performance-center-elite"></i>';
    }
    return str;
};

/**
 * Template for a Dealer view.
 * Uses ES6 template literal to composite the markup for a Slim Dealer module
 */
export default (dealer, isPreferred) => htmlNode `
    <div class="dealer-info dealer-info--slim" data-is-preferred="${isPreferred}" data-analytics-trigger="cta"
    data-analytics-container="{ 'dealer-card': '${dealer.name}', 'preferred': ${isPreferred}}">
        <address class="dealer-info__address-container-dealer" tabindex="0">
            <div class="dealer-info__dealer-name">${dealer.name}</div>
            <div class="dealer-info__address dealer-info__address--main">${dealer.city}, ${dealer.state}, ${dealer.zip}</div>
            ${renderAMGIcons(dealer)}
        </address>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import * as DOMPurify from 'dompurify';

export {
    BatchAssetLoader,
    ClickOutside,
    DisableBodyScroll,
    Swiper,
    TabOutside,
    Tabbables,
    Touch,
    checkReadyState,
    cookie,
    customEventDispatcher,
    dimensions,
    debounce,
    deserializeObject,
    detect,
    domState,
    easing,
    findAncestor,
    formatDate,
    formatNumber,
    formatString,
    generateUniqueID,
    getLocationSuffix,
    getPathInfo,
    history,
    html,
    htmlNode,
    isScrolledIntoView,
    isScrolledIntoViewHorizontally,
    isScrolledToTop,
    isStickyElement,
    noop,
    parseQueries,
    renderer,
    screen,
    scrollTo,
    serializeObject,
    removeSimpleSelectors,
    splitArray,
    storage,
    stringReplace,
    throttle,
    time,
    tokenReplace,
    tokenReplaceJSX,
    validate,
    viewportObserver
} from '@mbusa/now-ui-utils.oneweb';

/**
 * @method isBBD
 * @description Checks whether current vehicle year/model supports BBD Media Server
 * Note:
 * CA: 2017 E400C4 is test vehicle, BBD Media Server is supported by 2019+ vehicles
 * US: For the pilot program for US, BBD Media Server is supported by 2020 GLE350W4 & GLE450W4 vehicles
 * MX: All vehicles
 * @param country {String} Country, ex: ca | us | mx
 * @param year {String} Vehicle Year, ex: 2017
 * @param model {String} Vehicle Model, ex: E400C4
 * @returns {Boolean} True if supports BBD
 */
export function isBBD(country, year, model) {
    const isCASupported = country === 'ca' && (parseInt(year, 10) >= 2019 || (year === '2017' && model === 'E400C4'));
    const isUSSupported = country === 'us' && (parseInt(year, 10) === 2020 && (model === 'GLE350W4' || model === 'GLE450W4'));
    const isMXSupported = country === 'mx';

    return isCASupported || isUSSupported || isMXSupported;
}

/**
 * @method uppercaseWorker
 * @param str
 * @return {string} string with the word worker in uppercase
 */
export function uppercaseWorker(str) {
    return str.replace(/\bworker\b/ig, 'WORKER');
}

/**
 * @method focusFirstInputWithError
 */
export function focusFirstInputWithError() {
    window.setTimeout(() => {
        const firstInputWithError = (
            document.querySelector('[aria-required="true"][aria-invalid="true"]') ||
            document.querySelector('[aria-describedby^="error"]')
        );
        if (firstInputWithError) {
            firstInputWithError.focus();
        }
    });
}

/**
* @function validateSource
* @description cleans user input using DOMPurify and returns sanitized string
* @param {string} input - the input to be sanitized
* @return {string} returns a cleaned version of the given input string
*/
export function validateSource(input) {
    const cleanedInput = DOMPurify.sanitize(input);
    return cleanedInput;
}

/**
* @method validateDOMNode
* @description checks if the given argument is an instance of Node, HTMLElement or SVGElement
* @param {Node|HTMLElement|SVGElement} node - the potential DOM node to be checked
* @return {node|null} if node is an instance of Node,
* HTMLElement or SVGElement, returns the node. otherwise, returns null.
*/
export function validateDOMNode(node) {
    if (node instanceof Node || node instanceof HTMLElement || node instanceof SVGElement) {
        return node;
    }
    return null;
}

/**
* @method validateString
* @description checks if the given argument is an string
* @param {any} input - the potential string to be checked
* @return {string|null} if input is an string, returns the string. otherwise, returns null.
*/
export function validateString(input) {
    if (typeof input === 'string') {
        return input;
    }
    return null;
}

/**
* @method validateObject
* @description checks if the given argument is an object
* @param {any} input - the potential object to be checked
* @return {object|null} if input is an object, returns the object. otherwise, returns null.
*/
export function validateObject(input) {
    if (typeof input === 'object') {
        return input;
    }
    return null;
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * An api for creating model finder light items from specially tagged DOM elements
 *
 * Example markup for creating a model finder light item from html tags:
 *
 * <div data-model-finder-light-item
 *      data-model-finder-light-category="[string]">
 *         ...
 * </div>
 */

/**
 * @const ATTRIBUTES
 * @description Collection of data attributes for querying
 * @type {{ITEM: string}}
 */
const ATTRIBUTES = {
    ITEM: 'data-model-finder-light-item'
};

/**
 * @method getDataFromElement
 * @description parses element and returns the data for the model finder light item
 */
function getDataFromElement(index, element) {
    const itemObject = {};

    // property names for label and value are per SelectControl interface
    itemObject.element = element;
    itemObject.label = element.dataset.modelFinderLightCategory;
    itemObject.selected = !!element.dataset.modelFinderLightSelected;
    itemObject.value = index || 0;

    return itemObject;
}

/**
 * @method queryModelFinderLightElements
 * @description
 * Retrieves a collection of the model finder light elements from a DOM element
 * @returns {NodeList}
 */
function getModelFinderLightElements(element) {
    return element.querySelectorAll(`[${ATTRIBUTES.ITEM}]`);
}

/**
 * @method getItems
 * @description Iterates over returned nodes, looking for model finder light tags and returns an array of objects
 */
export function getItems(element) {
    const modelFinderLightElements = getModelFinderLightElements(element);
    const elements = [];

    for (let i = 0; i < modelFinderLightElements.length; i += 1) {
        elements.push(
            getDataFromElement(i.toString(), modelFinderLightElements[i])
        );
    }

    return elements;
}

/**
 * export public api
 */
export default {
    getItems
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a Lazy Loading Aspect Ratio Style Tag.
 * Uses ES6 template literal to composite the markup for a style tag
 */
export default (id, { smallAspectRatio, largeAspectRatio, mediaQuery }) => htmlNode `
<style id="styleTag${id}">
    ${largeAspectRatio ? `
        [data-lazyloader-id="${id}"] {
            padding-top: ${100 / largeAspectRatio}%;
            position: relative;
        }
        [data-lazyloader-id="${id}"] img.lazy-loader__asset {
            height: 100%;
            position: absolute;
            top: 0;
        }
    ` : ''}
    ${smallAspectRatio ? `@media screen and (${mediaQuery}) {
        [data-lazyloader-id="${id}"] {
            padding-top: ${100 / smallAspectRatio}%;
        }
    }` : ''}
</style>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { InputTextControl, InputCheckboxControl } from 'partials/input-control';
import { ReCAPTCHA } from 'partials/recaptcha';
import { FormControl, FormError } from 'partials/form-control';
import { preferenceCenterApi } from 'partials/preference-center';

// Module dependencies
import { tokenReplace, stringReplace, noop } from 'utils';

// Local dependencies
import QuickRegistrationLogin from './QuickRegistrationLogin';
import quickRegistrationFormTemplate from './../templates/quickRegistrationFormTemplate';
import { submitQuickReg } from './../api/quickRegistrationAPI';

/**
 * @properties
 * @description Profile endpoint
 */
const { profileServiceLocale } = window.mbVans.applicationProperties;

/**
 * @const defaultConfig
 * @type {Object} {
 *   analyticsTrigger: string,
 *   disableLogin: boolean,
 *   onLoginCallback: function,
 *   onSubmitCallback: function
 *   userInfo: object
 * }
 */
const defaultConfig = {
    analyticsTrigger: 'account-register-quick',
    disableLogin: false,
    onLoginCallback: noop,
    onSubmitCallback: noop,
    userInfo: null,
    analyticsTriggerLogin: 'account-login'
};

/**
 * @const serviceErrorCode
 * @description Holds the quick registration service error codes
 * @type {Object} {
 *   EXISTING_EMAIL: string
 * }
 */
const serviceErrorCode = {
    EXISTING_EMAIL: 'ERR_CONFIRMED_USER_EXISTS'
};

/**
 * @const defaultLocalization
 * @description Default localization object that is used as a fallback if no data has been defined
 * @type {{
 *  firstName: string,
 *  lastName: string,
 *  email: string,
 *  pleaseEnterFirstName: string,
 *  pleaseEnterLastName: string,
 *  invalidEmail: string,
 *  privacyPolicyAgreement: string,
 *  policies: string,
 *  register: string,
 *  validationError: string
 *  severError: object,
 *  existingEmailError: object,
 *  alreadyRegistered: string,
 *  loginHere: string
 * }}
 */
const defaultLocalization = {
    notRegCreAccount: 'Not Registered? Create an account.',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    pleaseEnterFirstName: 'Please enter your first name',
    pleaseEnterLastName: 'Please enter your last name',
    invalidEmail: 'Please enter a valid email address',
    privacyPolicyAgreement: 'I acknowledge that I have read and agree to the Mercedes-Benz {0}',
    policies: 'policies',
    register: 'Register',
    validationError: 'Please fix the information highlighted above',
    serverError: {
        title: 'We\'re sorry, an error has occurred.',
        description: 'We were unable to process your request. Please try again.'
    },
    existingEmailError: {
        title: 'Registration was unsuccessful.',
        description: 'A user account with this email address already exists.'
    },
    registeredDescription: 'By registering for a Mercedes me ID, you can access saved builds, inventory and more. Already have a Mercedes me ID? {0}.',
    loginHere: 'Login here',
    updates: 'Yes, send me updates about my vehicles. (Optional)'
};

/**
 * @const FORM_ID
 * @description Unique Id for the FormControlComponent and Inputs
 * @type {string}
 */
const FORM_ID = 'quickRegistrationForm';

/**
 * @const CLASSES
 * @description has the defined classes to cache the dom
 * @type {Object}
 */
const CLASSES = {
    FORM: 'mbs-quick-registration__form',
    FIRST_NAME: 'mbs-quick-registration__first-name',
    LAST_NAME: 'mbs-quick-registration__last-name',
    EMAIL: 'mbs-quick-registration__email',
    RECAPTCHA: 'mbs-quick-registration__recaptcha',
    POLICY: 'mbs-quick-registration__policy',
    UPDATES: 'mbs-quick-registration__updates',
    SUBMIT: 'mbs-quick-registration__submit',
    ERROR_CONTAINER: 'mbs-quick-registration__error-message',
    CONTENT: 'mbs-quick-registration__content',
    FORM_CONTAINER: 'mb-quick-registration__form-container',
    LOGIN_CONTAINER: 'mbs-quick-registration__login-container'
};

/**
 * @const data
 * @description constant for the localization content related to quick registration created
 * on src/main/content/jcr_root/apps/mb-vans/pages/base/partials/footlibs.html
 */
const data = window.mbVans.ns('pageData').registration;

/**
 * @class QuickRegistrationForm
 * @description View for the quick registration form part
 */
export default class QuickRegistrationForm {
    /**
     * @method constructor
     * @description  set the default state for inheritance
     *  @param {Object} config
     *  @param {Object} content
     */
    constructor(config, content = {}) {
        this.content = { ...defaultLocalization, ...data, ...content };
        this.config = { ...defaultConfig, ...config };
        this.element = quickRegistrationFormTemplate({
            submitButtonLabelText: this.content.register,
            content: this.content,
            analyticsTrigger: this.config.analyticsTrigger
        })({ getNode: true });
        this.onSubmitCallback = this.config.onSubmitCallback;
        this.onPrevalidate = this.onPrevalidate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onInvalid = this.onInvalid.bind(this);
        this.setSubmitState = this.setSubmitState.bind(this);
        this.updateSubscriptions = this.updateSubscriptions.bind(this);

        this.init();
    }

    /**
     * @method  init
     * @description  initilize basic functions
     */
    init() {
        this.cacheDOM();
        this.createInputs();
        this.createRecaptcha();
        this.createForm();

        if (!this.config.disableLogin) {
            this.createLogin();
        }
    }

    /**
     * @method cacheDOM
     * @description  in charge of set the elements from the DOM
     */
    cacheDOM() {
        this.formElement = this.element.querySelector(`.${CLASSES.FORM}`);
        this.firstName = this.element.querySelector(`.${CLASSES.FIRST_NAME}`);
        this.lastName = this.element.querySelector(`.${CLASSES.LAST_NAME}`);
        this.email = this.element.querySelector(`.${CLASSES.EMAIL}`);
        this.recaptchaContainer = this.element.querySelector(`.${CLASSES.RECAPTCHA}`);
        this.policy = this.element.querySelector(`.${CLASSES.POLICY}`);
        this.updates = this.element.querySelector(`.${CLASSES.UPDATES}`);
        this.submit = this.element.querySelector(`.${CLASSES.SUBMIT}`);
        this.errorContainerElement = this.element.querySelector(`.${CLASSES.ERROR_CONTAINER}`);
        this.formContainer = this.element.querySelector(`.${CLASSES.FORM_CONTAINER}`);
        this.loginContainer = this.element.querySelector(`.${CLASSES.LOGIN_CONTAINER}`);
    }

    /**
     * @method createLogin
     * @description  create the login view on the quick registration form
     */
    createLogin() {
        this.login = new QuickRegistrationLogin(this.content, this.config);

        this.loginContainer.appendChild(this.login.render());
    }

    /**
     * @method createForm
     * @description Instantiates FormControl responsible for managing state of form
     */
    createForm() {
        const formConfig = {
            element: this.formElement,
            id: FORM_ID,
            onPrevalidate: this.onPrevalidate,
            onSubmit: this.onSubmit,
            onInvalid: this.onInvalid,
            prevalidate: true
        };

        this.form = new FormControl(formConfig);
        this.form.register(this.inputs.firstName);
        this.form.register(this.inputs.lastName);
        this.form.register(this.inputs.email);
        this.form.register(this.inputs.policy);
        this.form.register(this.inputs.updates);
        this.form.register(this.recaptcha);

        // render the form control to the dom
        this.element.appendChild(this.form.render());
    }

    /**
     * @method createInputs
     * @description instantiates a collection of InputTexControl views
     * and appends them to the form
     */
    createInputs() {
        const {
            userInfo
        } = this.config;

        this.inputs = {
            firstName: new InputTextControl({
                formId: FORM_ID,
                type: 'text',
                id: 'qr-first-name',
                required: true,
                labelText: this.content.firstName,
                maxLength: 255,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: this.content.pleaseEnterFirstName
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: this.content.pleaseEnterFirstName
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
                errorMessage: this.content.pleaseEnterFirstName,
                valueText: (userInfo && userInfo.firstName) || '',
                analyticsTrigger: 'cta-form-firstname'
            }),
            lastName: new InputTextControl({
                formId: FORM_ID,
                type: 'text',
                id: 'qr-last-name',
                required: true,
                labelText: this.content.lastName,
                maxLength: 255,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: this.content.pleaseEnterLastName
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: this.content.pleaseEnterLastName
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
                errorMessage: this.content.pleaseEnterLastName,
                valueText: (userInfo && userInfo.lastName) || '',
                analyticsTrigger: 'cta-form-lastname'
            }),
            email: new InputTextControl({
                formId: FORM_ID,
                type: 'text',
                id: 'qr-email',
                required: true,
                labelText: this.content.email,
                maxLength: 50,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: this.content.invalidEmail
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: this.content.invalidEmail
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.EMAIL,
                        errorMessage: this.content.invalidEmail
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.EMAIL_SPECIAL_CHARS],
                valueText: (userInfo && userInfo.email) || '',
                validateOnBlur: true,
                analyticsTrigger: 'cta-form-email'
            }),
            policy: new InputCheckboxControl({
                formId: FORM_ID,
                id: 'qr-policy',
                required: true,
                checked: false,
                labelText: this.getPrivacyPolicyLabelText(),
                analyticsTrigger: 'checkmark-agree-tos',
                errorMessage: this.content.invalidLegalNotice,
                onChangeCallback: this.setSubmitState
            }),
            updates: new InputCheckboxControl({
                formId: FORM_ID,
                id: 'qr-updates',
                checked: false,
                labelText: this.content.updates,
                analyticsTrigger: 'cta-crm-opt-in',
                inputClassNames: ['mbs-quick-registration__updates-checkbox'],
                onChangeCallback: this.setSubmitState
            })
        };

        this.firstName.parentNode.replaceChild(this.inputs.firstName.render(), this.firstName);
        this.lastName.parentNode.replaceChild(this.inputs.lastName.render(), this.lastName);
        this.email.parentNode.replaceChild(this.inputs.email.render(), this.email);
        this.policy.parentNode.replaceChild(this.inputs.policy.render(), this.policy);
        if (this.updates) {
            this.updates.parentNode.replaceChild(this.inputs.updates.render(), this.updates);
        }
    }

    /**
     * @method getPrivacyPolicyLabelText
     * @description Returns tokenized string for privacy policy label text
     * @returns {String}
     */
    getPrivacyPolicyLabelText() {
        const privacyPolicyAgreement = stringReplace(this.content.privacyPolicyAgreement, /(Mercedes-Benz)/g, ['<span class="mb-title-non-breaking">Mercedes-Benz</span>']);
        const link = `<a href='${this.content.legalNoticeURI}' 
                         class='link link_plain-link' 
                         target='_blank'
                         data-analytics-trigger="cta">${this.content.policies}</a>`;
        return tokenReplace(privacyPolicyAgreement, [link]);
    }

    /**
     * @method creteRecaptcha
     * @description  Implement the ReCAPTCHA view for the form
     */
    createRecaptcha() {
        this.recaptcha = new ReCAPTCHA({
            formId: FORM_ID,
            errorMessage: data.recaptchaEmptyError,
            onSuccess: this.setSubmitState

        });
        this.recaptchaContainer.appendChild(this.recaptcha.render());
    }

    /**
     * @method onPrevalidate
     * @description Callback method applied when a form is prevalidated that hides the validation error
     * @param isValid
     */
    onPrevalidate(isValid) {
        // Hide the validation error if the form is valid and a validation error exists
        if (isValid && this.validationError) {
            this.hideValidationError();
        }
    }

    /**
     * @method onSubmit
     * @description Callback for form submission after passing validation
     */
    onSubmit() {
        const endpoint = `${profileServiceLocale}/ciam/quick/register`;

        // Destroying the instance of ValidationError if the form valid upon submit
        if (this.validationError) {
            this.hideValidationError();
        }

        if (this.serverError) {
            this.hideServerError();
        }

        submitQuickReg(endpoint, this.getQuickRegData())
            .then(this.onSubmitSuccess.bind(this))
            .catch(this.onSubmitError.bind(this));
    }

    /**
     * @method getQuickRegData
     * @description get the values from the inputs
     * @return {Object}
     */
    getQuickRegData() {
        return {
            fname: this.inputs.firstName.getValue(),
            lname: this.inputs.lastName.getValue(),
            email: this.inputs.email.getValue(),
            'g-recaptcha-response': this.recaptcha.getValue()
        };
    }

    /**
     * @method onSubmitSuccess
     * @description after submission show the success state
     */
    onSubmitSuccess() {
        const submitData = {
            email: this.inputs.email.getValue(),
            firstName: this.inputs.firstName.getValue()
        };

        if (this.inputs.updates.getValue()) {
            this.updateSubscriptions(submitData.email);
        }

        this.onSubmitCallback(submitData);
    }

    /**
     * @method updateSubscriptions
     * @description Updates user email preferences if appropriate checkbox is selected
     * @param email
     */
    updateSubscriptions(email) {
        const config = {
            country: window.mbVans.pageData.country,
            endpoints: this.content.endpoints,
            showAccountSubscriptions: true
        };

        const updatesCheck = this.inputs.updates.getValue();

        const subscriptionsData = {
            subscriptions: [
                {
                    subscriptionId: 'saved_inventory_price_change',
                    optIn: updatesCheck
                },
                {
                    subscriptionId: 'res_req_price_change',
                    optIn: updatesCheck
                },
                {
                    subscriptionId: 'saved_inventory_veh_sold',
                    optIn: updatesCheck
                },
                {
                    subscriptionId: 'res_req_veh_sold',
                    optIn: updatesCheck
                },
                {
                    subscriptionId: 'saved_inventory_res_status',
                    optIn: updatesCheck
                },
                {
                    subscriptionId: 'saved_build_view_similar',
                    optIn: updatesCheck
                }
            ]
        };

        preferenceCenterApi.submitUpdate(
            {
                emailId: encodeURIComponent(email)
            },
            subscriptionsData,
            config
        );
    }

    /**
     * @method onSubmitError
     * @description after submission shows if theres any error on the server side
     */
    onSubmitError(response) {
        this.showServerError(response);
    }

    /**
     * @method onInvalid
     * @description callback for when form validation fails
     */
    onInvalid() {
        this.showValidationError();
    }

    /**
     * @method showValidationError
     * @description Instantiates ValidationError message field and renders it
     */
    showValidationError() {
        if (!this.validationError) {
            this.validationError = new FormError({
                description: this.content.validationError
            });
        }
        if (this.serverError) {
            this.hideServerError();
        }
        this.validationError.show(this.errorContainerElement);
    }

    /**
     * @method showServerError
     * @description show message when is an error on the server side
     */
    showServerError(response) {
        const isExistingEmailError = response.message === serviceErrorCode.EXISTING_EMAIL;
        if (!this.serverError) {
            this.serverError = new FormError({
                title:
                    isExistingEmailError ?
                        this.content.existingEmailError.title
                        :
                        this.content.serverError.title,
                description:
                    isExistingEmailError ?
                        this.content.existingEmailError.description
                        :
                        this.content.serverError.description
            });
        }
        if (this.validationError) {
            this.hideValidationError();
        }
        this.serverError.show(this.errorContainerElement);
    }

    /**
     * @method hideValidationError
     * @description Removes the validationError message field
     */
    hideValidationError() {
        this.validationError.hide();
    }

    /**
     * @method hideServerError
     * @description Remove the serverError
     */
    hideServerError() {
        this.serverError.hide();
    }

    /**
     * @method setSubmitState
     * @description If reCAPTCHA checkbox is checked, enable the submit button,
     * otherwise disable the submit button
     */
    setSubmitState() {
        this.inputs.policy.validate();
        const enableSubmit = this.recaptcha.isValid() && this.inputs.policy.isValid();
        this.submit.classList[enableSubmit ? 'remove' : 'add']('disabled');
    }

    /**
     * @method render
     * @description  return the DOM element
     * @return {XML}
     */
    render() {
        return this.element;
    }

    /**
     * @method destroyForm
     * @description Iterates through and destroys the inputs controls and the form control
     */
    destroyForm() {
        Object.values(this.inputs).forEach((input) => {
            input.destroy();
        });

        this.form.destroy();
    }

    /**
     * @method destroy
     * @description  method to destroy the instance
     */
    destroy() {
        this.destroyForm();
        this.element.remove();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization labels for Dealer partial
 * @type {{label: String}}
 */
const defaultLocalization = {
    certifiedLabel: 'Elite Aluminum Structural Certification'
};

const {
    certifiedLabel
} = window.mbVans.ns('pageData').localization || defaultLocalization;

export default {
    certifiedLabel
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

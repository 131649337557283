/**
 * API responsible to the business logic of setting vehicle filters
 */

/**
 * @method filterModelsBy
 * @description filters the bodyStyleClasses list based on searchFilter value
 * @param {Object} bodyStyleClasses - Filter options object
 * @param {String} searchFilter - Active Filters object
 * @returns {Object} Modified bodyStyleClasses object that is filter by searchFilter
 */
function filterModelsBy(bodyStyleClasses, searchFilter) {
    const filtered = bodyStyleClasses.reduce((accumulator, bodyClass) => {
        const filteredModels = bodyClass.models.filter((model) => model.value.toLowerCase().replace(/\s+/g, '').indexOf(searchFilter) !== -1);
        if (filteredModels.length > 0) {
            accumulator.push({
                ...bodyClass,
                models: filteredModels
            });
        }
        return accumulator;
    }, []);
    return filtered;
}

export default {
    filterModelsBy
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization strings for YouTube elements
 * @type {{label: String}}
 */
const defaultLocalization = {
    alertYouTube: 'Click here to watch video.',
    hideVideoTranscript: 'Hide Video Transcript',
    modalAlertYouTube: 'Click here to launch video in a pop-up.',
    showVideoTranscript: 'Show Video Transcript'
};

// Deconstruct localization object
const {
    alertYouTube,
    hideVideoTranscript,
    modalAlertYouTube,
    showVideoTranscript
} = Object.assign(defaultLocalization, window.mbVans.ns('pageData', 'youtube').localization);

export default {
    alertYouTube,
    hideVideoTranscript,
    modalAlertYouTube,
    showVideoTranscript
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { UserProfile } from 'partials/profile';

// Plugin dependencies
import DealerLocatorPlugin from 'plugins/dealer-locator/js';

// Util dependencies
import {
    noop,
    renderer
} from 'utils';

// Local dependencies
import {
    content,
    dlContent,
    dlFlags,
    PAGE_DATA_COUNTRY,
    PAGE_DATA_LANGUAGE,
    PAGE_DATA_BRAND,
    VARIANT_INFO
} from './../config';

// import DealerSelectorLocationSearch from './DealerSelectorLocationSearch';
import DealerSelectorActiveDealer from './DealerSelectorActiveDealer';
import DealerSelectorDealerSearch from './DealerSelectorDealerSearch';
import dealerSelectorTemplate from './../templates/dealerSelectorTemplate';

/**
 * @property defaultConfig
 * @description Default configuration for DealerSelector
 * @type {{
 *     enableSelectedDealer: boolean,
 *     dealer: object,
 *     displaySelectedDealer: boolean,
 *     onDealerContinue: function,
 *     onDealerSelect: function,
 *     filterBy: string,
 *     location: object
 *  }}
 */
const defaultConfig = {
    dealer: null,
    displaySelectedDealer: true,
    enableSelectedDealer: true,
    filterBy: '',
    isNewInventory: true,
    location: null,
    mapModalToggleDisabled: false,
    onDealerContinue: noop,
    onDealerSelect: noop,
};

/**
 * @class DealerSelector
 * @description The entry point for the dealer selector partial
 * @param config {object} Configurable options containing:
 *        enableSelectedDealer: {[Boolean]} Flag for whether to enable displaying
 *                                           the active/selected dealer when loading
 *        dealer: {[Object]} Optional dealer object to set
 *        displaySelectedDealer {string} Flag for whether to display
 *                                       the selected dealer as the active dealer
 *        onDealerContinue {function} Callback when dealer "continue is selected
 *        onDealerSelect {function} Callback when dealer is selected
 */
export default class DealerSelector {
    /**
     * @static FILTER_TYPES
     * @description FilterBy key values
     * @type {{INVENTORY: string}}
     */
    static FILTER_TYPES = { ...DealerSelectorDealerSearch.FILTER_TYPES };

    constructor(config = {}) {
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.profile = new UserProfile();
        this.element = dealerSelectorTemplate()({ getNode: true });
        this.defaultLocation = null;

        this.onLocationSelect = this.onLocationSelect.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onDealerContinue = this.onDealerContinue.bind(this);
        this.onDealerSelect = this.onDealerSelect.bind(this);

        this.init();
    }

    /**
     * @method init
     * @description Initializes the view by determining:
     * if a dealer has been defined render the ActiveDealer view,
     * if a user has a Preferred Dealer render the ActiveDealer view;
     * otherwise renders a LocationSearch view
     */
    init() {
        // if config dealer available -> renderActiveDealer
        // if inventory dealer available -> renderInventoryDealer
        // if preferred dealer available -> renderActiveDealer
        // else renderSearch
        const inventoryDealer = this.profile.getInventoryDealer();
        const preferredDealer = this.profile.getPreferredDealer();
        const lastLocation = this.profile.getLastLocation();
        const {
            dealer,
            enableSelectedDealer,
            location
        } = this.config;
        const activeDealer = dealer || inventoryDealer || preferredDealer;

        // set the active dealer's location as the default location
        if (activeDealer && !location) {
            this.setDefaultLocation('zip', activeDealer.zip);
        } else if (location) {
            this.setDefaultLocation(location.type, location.value);
        } else if (lastLocation) {
            this.setDefaultLocation(lastLocation.searchByType, lastLocation.searchLocation);
        }

        // render the corresponding step based on active dealer
        if (dealer && enableSelectedDealer) {
            this.renderActiveDealer(dealer, false);
        } else if (activeDealer && enableSelectedDealer) {
            this.renderActiveDealer(activeDealer, true);
        } else {
            this.renderSearch();
        }
    }

    /**
     * @method setDefaultLocation
     * @method Sets the defaultLocation based on a dealer
     * @param type {String} Location type ('zip' || 'place')
     * @param value {String} Location value
     */
    setDefaultLocation(type, value) {
        this.defaultLocation = {
            searchLocation: value,
            searchByType: type,
            searchString: value
        };
    }

    /**
     * @method onDealerContinue
     * @description Callback when user confirms continue with current active dealer
     * @param dealer {Object}
     */
    onDealerContinue(dealer) {
        this.config.onDealerContinue(dealer);
    }

    /**
     * @method onDealerSelect
     * @description Callback handler for when a dealer is selected to
     * apply the config.onDealerContinue and render the selected dealer
     * as the active dealer
     * @param dealer {Object}
     */
    onDealerSelect(dealer) {
        // destroy previous views
        this.destroySearch();

        this.config.onDealerSelect(dealer);

        if (this.config.displaySelectedDealer) {
            this.renderActiveDealer(dealer);
        }
    }

    /**
     * @method onLocationSelect
     * @description Callback when location is selected
     * @param location {Object} Location object which includes the search type and location
     */
    onLocationSelect(location) {
        // destroy previous views
        this.destroySearch();
        this.destroyActiveDealer();

        // update defaultLocation based on selection
        this.defaultLocation = location;

        // render results view
        this.renderResults(location);
    }

    /**
     * @method onLocationChange
     * @description Callback when user wants to change location, show the
     * location search view
     */
    onLocationChange() {
        // destroy previous views
        this.destroyResults();
        this.destroyActiveDealer();

        // render search view
        this.renderSearch();
    }

    /**
     * @method renderSearch
     * @description Renders the DealerSelectorLocationSearch view
     */
    renderSearch() {
        const dlConfig = {
            country: PAGE_DATA_COUNTRY,
            language: PAGE_DATA_LANGUAGE,
            brand: PAGE_DATA_BRAND,
            isURLParamUsed: false,
            isFullPageLayout: false,
            filterBy: this.config.filterBy,
            filter: 'mbDealer',
            mapModalToggleDisabled: this.config.mapModalToggleDisabled,
        };
        this.dealerLocatorPlugin = new DealerLocatorPlugin(
            this.element,
            {
                ...dlConfig,
                featureFlags: dlFlags,
                onDealerSelect: this.onDealerSelect,
                theme: DealerLocatorPlugin.THEME.MEDIUM_SIZE,
                defaultLocation: this.defaultLocation,
                variantInfo: VARIANT_INFO
            },
            dlContent);
    }

    /**
     * @method destroySearch
     * @description Destroys the reference to the DealerSelectorLocationSearch view
     */
    destroySearch() {
        if (this.dealerLocatorPlugin) {
            this.dealerLocatorPlugin.destroy();
            this.dealerLocatorPlugin = null;
        }
    }

    /**
     * @method renderActiveDealer
     * @description Renders the DealerSelectorActiveDealer view
     */
    renderActiveDealer(dealer, isPreferred) {
        this.activeDealerView = new DealerSelectorActiveDealer({
            dealer,
            isPreferred,
            content,
            onDealerContinue: this.onDealerContinue,
            onLocationChange: this.onLocationChange
        });
        renderer.insert(this.activeDealerView.render(), this.element);
    }

    /**
     * @method destroyActiveDealer
     * @description Destroys the reference to the DealerSelectorActiveDealer view
     */
    destroyActiveDealer() {
        if (this.activeDealerView) {
            this.activeDealerView.destroy();
            this.activeDealerView = null;
        }
    }

    /**
     * @method renderResults
     * @description Renders the DealerSelectorDealerSearch view
     */
    renderResults(location) {
        this.dealerSelectorDealerSearchView = new DealerSelectorDealerSearch(
            {
                country: PAGE_DATA_COUNTRY,
                language: PAGE_DATA_LANGUAGE,
                brand: PAGE_DATA_BRAND,
                filterBy: this.config.filterBy,
                isNewInventory: this.config.isNewInventory
            },
            location,
            content,
            {
                onLocationChange: this.onLocationChange,
                onDealerSelect: this.onDealerSelect
            }
        );

        renderer.insert(this.dealerSelectorDealerSearchView.render(), this.element);
    }

    /**
     * @method destroyResults
     * @description Destroys the reference to the DealerSelectorDealerSearch view
     */
    destroyResults() {
        if (this.dealerSelectorDealerSearchView) {
            this.dealerSelectorDealerSearchView.destroy();
            this.dealerSelectorDealerSearchView = null;
        }
    }

    /**
     * @method destroy
     * @description Destroys the view and its references
     */
    destroy() {
        this.destroySearch();
        this.destroyResults();
        this.destroyActiveDealer();
    }

    /**
     * @method render
     * @description Returns the view's element
     * @return {*}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

export default {
    DATA_LOADED: 'data loaded',
    CLOSE_PROFILE_MODAL: 'close profile modal',
    CLOSE_SELECT_CONTROL: 'close select control',
    COLLAPSE_DEALER_INFO: 'collapse dealer info',
    GLOBAL_NAV_DEALER_SEARCH: 'global nav dealer search',
    GLOBAL_NAV_DISABLE_TABBABLES: 'global nav disable tabbables',
    GLOBAL_NAV_ENABLE_TABBABLES: 'global nav enable tabbables',
    PAUSE_YOUTUBE_VIDEO: 'pause youtube video',
    YOUTUBE_VIDEO_STATE_CHANGE: 'youtube video state change',
    CONTAINER_DISPLAY_STATE_CHANGED: 'container display state changed',
    STICKY_NAV_TRIGGER: 'sticky nav trigger',
    CLOSE_CLICK: 'closeclick',
    PREFERRED_DEALER_UPDATED: 'preferred dealer updated',
    PREFERRED_DEALER_VALIDATE: 'preferred dealer validate',
    UPDATE_EXTERIOR_COLOR: 'update exterior color',
    TOGGLE_DRAWER_TRIGGER: 'toggle drawer trigger',
    GLOBAL_NAV_MENU_OPENED: 'global nav menu opened',
    GLOBAL_NAV_MENU_CLOSED: 'global nav menu closed',
    SPECIALTY_PAGE_SCROLL: 'specialty page scroll',
    SPECIALTY_PAGE_SCROLL_ABORT: 'specialty page scroll abort',
    SPECIALTY_PAGE_HASH_UPDATED: 'specialty page url hash updated',
    SPECIALTY_PAGE_LOCK_SCROLL: 'specialty page lock scroll',
    SPECIALTY_PAGE_UNLOCK_SCROLL: 'specialty page unlock scroll',
    CONTENT_TILE_VEHICLE_OVERLAY_TRIGGER: 'content tile vehicle overlay trigger',
    CONTENT_TILE_VEHICLE_OVERLAY_BUTTON_TRIGGER: 'content tile vehicle overlay button trigger',
    LIFECYCLE_TRANSITION: 'lifecycle transition',
    COMPLETE: 'complete',
    TRANSITION_BEGUN: 'transition begun',
    TRANSITION_COMPLETE: 'transition complete',
    TAB_CLICKED: 'tab clicked',
    DOCKED_FOOTER: {
        TOGGLE_TRIGGER: 'toggle trigger',
        OPEN: 'open',
        CLOSE: 'close',
        OPEN_FROM_DISCLAIMER: 'open from disclaimer',
        OPEN_DISCLAIMER: 'open disclaimer',
        CLOSE_DISCLAIMER: 'close disclaimer',
        SHOW_FOOTER_BUTTON: 'show footer button',
        HIDE_FOOTER_BUTTON: 'hide footer button'
    },
    SPECIAL_OFFERS_MARKET_CHANGE: 'Special Offers market change',
    RELATED_VIDEO_SELECTED: 'Related video selected',
    FORCE_ASSET_LOAD: 'Force asset load',
    CLOSE_MODAL_BLUR: 'close modal blur',
    RENDER_PREFERRED_DEALER: 'render preferred dealer'
};

export const TRANSITION_STAGES = {
    PLAYIN_BEGUN: 'playin begun',
    PLAYIN_COMPLETE: 'playin complete',
    PLAYOUT_BEGUN: 'playout begun',
    PLAYOUT_COMPLETE: 'playout complete'
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode, html } from 'utils';

import youTubeModalItemTemplate from 'partials/youtube/templates/youTubeModalItemTemplate';

/**
 * @method renderTitle
 * @description method will form anchor link or span element based on the passed argument
 * @param title
 * @param endpoint
 * @returns anchor link or span element or empty string
 */
function renderTitle(title, endpoint) {
    if (endpoint && title) {
        return `<a href="${endpoint}" class="link link_plain-link gallery-info__model" data-analytics-trigger="cta">${title}</a>`;
    } else if (title) {
        return `<span class="gallery-info__model">${title}</span>`;
    }

    return '';
}

/**
 * @method renderCaptionHeading
 * @description method will create span element containing captionHeading if available
 * @param captionHeading
 * @returns span element or empty string
 */
function renderCaptionHeading(captionHeading) {
    return captionHeading ?
            `<span class="gallery-info__caption-heading">${captionHeading}</span>` :
                '';
}

/**
 * @method renderAnalyticsTrigger
 * @description method will create analytics data-attribute if available
 * @param cta
 * @returns analytics data-attribute
 */
function renderAnalyticsTrigger(cta) {
    return Object.prototype.hasOwnProperty.call(cta, 'dataAnalyticsTrigger') ?
        `data-analytics-trigger="${cta.dataAnalyticsTrigger}"` :
        '';
}

/**
 * @method renderCaptionDescription
 * @description method will create span element containing captionDescription if available
 * @param captionDescription
 * @returns span element or empty string
 */
function renderCaptionDescription(captionDescription) {
    return captionDescription ?
            `<p class="gallery-info__caption-description">${captionDescription != null ? captionDescription : ''}</p>` :
                '';
}

/**
 * Template for YouTube video player in modal
 * Uses ES6 template literal to composite the markup
 */
export default ({ videoId, videoTranscript, videoTriggerImage, modelTitle, modelPath, captionHeading, captionDescription, CTAs = [] }) => htmlNode `
    <div class="modal-carousel modal-carousel--youtube">
        <div class="modal-carousel__image">
            ${youTubeModalItemTemplate({
                videoId,
                videoTranscript,
                imageUrl: videoTriggerImage
            })({ getNode: false })}
        </div>
        <div class="wrapper gallery-info__content ${videoTranscript ? 'gallery-info__content--with-transcript' : ''}">
            <div class="gallery-info__copy-container">
                ${renderTitle(modelTitle, modelPath)}
                ${renderCaptionHeading(captionHeading)}
                ${renderCaptionDescription(captionDescription)}
            </div>
            <ul class="gallery-info__cta-container">
                ${CTAs.map((cta) => html`
                    <li class="gallery-info__cta-list-item">
                        <a class="link link_plain-link gallery-info__cta ${cta.className}" href="${cta.endpoint}" target="${cta.target}" ${cta.type === 'download' ? 'download' : ''} ${renderAnalyticsTrigger(cta)}>${cta.label}</a>
                    </li>
                `).join('')}
            </ul>
            <button aria-hidden="true"></button>
        </div>
    </div>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

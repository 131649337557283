/**
 * @const CREDIT_SCORES
 * @description credit score values for payment driver forms
 */
export default {
    EXCELLENT: {
        label: 'Excellent',
        score: '710',
        value: 'EXCELLENT'
    },
    GOOD: {
        label: 'Good',
        score: '670',
        value: 'GOOD'
    },
    FAIR: {
        label: 'Fair',
        score: '640',
        value: 'FAIR'
    },
    POOR: {
        label: 'Poor',
        score: '620',
        value: 'POOR'
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import {
    serializeObject as serialize,
    generateUniqueID as uniqueId,
    getLocationSuffix,
    formatNumber,
    formatString
} from 'utils';

// Partial dependencies
import { googleLocationsApi } from 'partials/google-maps';

// Config dependencies
import constants from './../config/constants';

/**
 * @property directionsApi
 * @description Google directions link root endpoint
 */
const directionsApi = constants.googleDirectionsApi;

/**
 * @properties dealerLocatorService, inventoryService
 * @description Dealer Locator and Inventory web service root endpoints
 */
const {
    dealerLocatorService,
    inventoryConfig: {
        inventoryUrl,
        inventoryService,
        isInventoryEnabled
    }
} = window.mbVans.applicationProperties;

/**
 * @constant TYPES
 * @description Location types key values
 * @type {{dealers: string, collisionCenters: string}}
 */
const TYPES = {
    dealers: 'dealers',
    collisionCenters: 'cc'
};

/**
 * @constant FILTER_TYPES
 * @description FilterBy key values
 * @type {{INVENTORY: string}}
 */
const FILTER_TYPES = {
    INVENTORY: 'inventory'
};

/**
 * @property INVENTORY_DEALER_RESPONSE
 * @description Stores the inventory dealer response, and caches it for further usage.
 */
const INVENTORY_DEALER_RESPONSE = {};

/**
 * @function
 * @name getPhoneNumber
 * @description return first phone number from data
 * @param {Array} contactArr array of contact info
 * @return {String} phone number
 */
function getPhoneNumber(contactArr) {
    let number = '';
    contactArr.forEach((item) => {
        if (item.type === 'phone') {
            number = item.value;
        }
    });
    return number;
}

/**
 * @function
 * @name getServiceUrl
 * @description return service url from data
 * @param {Array} activitiesArr array of activities objects
 * @return {String} url
 */
function getServiceUrl(dealerItem) {
    const activitiesArr = dealerItem.activities;
    let url = 'NO_SCHEDULE_SERVICE_URL';
    if (activitiesArr) {
        activitiesArr.forEach((item) => {
            if (item.name === 'service') {
                url = item.url && (item.url.indexOf('://') !== -1 ? item.url : `//${item.url}`) || '';
            }
        });
    }
    return url;
}

/**
 * @method createOWInventoryUrl
 * @description Creates a OW inventory url from a dealer object
 * @param dealer
 * @return {string}
 */
function createOWInventoryUrl(dealer) {
    const inventoryEndpoint = inventoryUrl.split('.')[0];
    const dealerName = formatString.hyphenate(
        formatString.toAlphaNumeric(dealer.name)
    );

    return `${inventoryEndpoint}/${dealerName}/${dealer.id}${getLocationSuffix()}`;
}

/**
 * @method createClassicInventoryUrl
 * @description Creates a Classic inventory url from a dealer object
 * @param dealer
 * @return {string}
 */
function createClassicInventoryUrl(dealer) {
    const inventoryEndpoint = inventoryUrl.split('.')[0];
    return `${inventoryEndpoint}/dealer/${dealer.id}`;
}

/**
 * @method createClassicCaInventoryUrl
 * @description Creates a Classic Canada inventory url from a dealer object
 * @param dealer
 * @return {string}
 */
function createClassicCaInventoryUrl(dealer) {
    const zipRaw = ((dealer.address || [])[0] || {}).zip || '';
    const zip = zipRaw.replace(/\s/g, '');
    const inventoryEndpoint = inventoryUrl.split('.')[0];
    return `${inventoryEndpoint}/postal:${zip}`;
}

/**
 * @function
 * @name getInventoryUrl
 * @description return inventory url from data
 * @param {Object} dealerItem object
 * @return {String} url
 */
function getInventoryUrl(dealerItem) {
    let url = dealerItem.url;

    const isClassicInventory = window.mbVans.ns('applicationProperties', 'inventoryConfig').isClassicInventory;
    const isCanada = window.mbVans.ns('pageData').country === 'ca';

    // default to the dealer url if no sales url
    if (isInventoryEnabled) {
        if (isClassicInventory) {
            if (isCanada) {
                url = createClassicCaInventoryUrl(dealerItem);
            } else {
                url = createClassicInventoryUrl(dealerItem);
            }
        } else {
            url = createOWInventoryUrl(dealerItem);
        }
    } else {
        url = url && (url.indexOf('://') !== -1 ? url : `//${url}`) || '';
    }

    return url;
}

/**
 * @function hasInventory
 * @description Finds if the dealer has inventory. Has country specific logic to test inventory availability.
 * @param {Object} dealer - Dealer object from API response
 * @param {String} country - Country ISO code
 * @param {Array} inventoryDealerIds - List of dealer ID which have inventory
 * @returns {Boolean} True if the dealer has inventory
 */
function hasInventory(dealer, country, inventoryDealerIds) {
    // For Vans, if there is no inventoryDealers service, set hasInventory as true for every dealer
    if (!inventoryDealerIds) {
        return true;
    }

    if (country !== 'ca') {
        return inventoryDealerIds.indexOf(dealer.id) !== -1;
    } else {
        // For CA there are three sets of dealers (service dealer & sales dealer) which share dealer ID
        // Since they share dealer ID, only way to know that a dealer is service only location is
        // to check the activities array for `sales`
        return inventoryDealerIds.indexOf(dealer.id) !== -1 &&
                    dealer.activities &&
                    dealer.activities.some((activity) => activity.name && activity.name === 'sales');
    }
}

/**
 * @function
 * @name createDirectionsLink
 * @description return a google maps direction link
 * @param {Object} dealerItem/collisionCenters object
 * @return {String} url
 */
function createDirectionsLink(item) {
    const url = encodeURI(
        `${directionsApi}/${item.name}+`
        + `${item.address[0].line1}+`
        + `${item.address[0].city}+`
        + `${item.address[0].state}+`
        + `${item.address[0].zip}`);
    return url;
}

/**
 * @function
 * @name getDistance
 * @description Constructs the distance string
 * @param {Object} address - Address object from dealer api response
 * for each dealer
 * @param {String} language - Language context used to format the distance
 * @param {String} distAbbr - Abbreviation for the distance unit for our stub data
 * @return {String} Distance+Distance unit
 */
function getDistance(address, language, distAbbr) {
    const distance = address.location.dist;
    const dealerLocatorData = window.mbVans.pageData.dealerLocator;
    const distanceUnitAbbr = dealerLocatorData && dealerLocatorData.config.distanceUnitAbbr;

    if (distance) {
        const dist = `${parseFloat(distance).toFixed(1)}`;
        const distUnit = distAbbr || distanceUnitAbbr || address.location.distunit;
        if (language === 'en') {
            return `${dist} ${distUnit}`;
        } else if (language === 'fr') {
            const distFr = dist.replace(/\./, ',');
            return `${distFr} ${distUnit}`;
        } else if (language === 'es') {
            return `${dist} ${distUnit}`;
        }
    }

    return '';
}

/**
 * @method formatDealer
 * @description format the dealers object
 * NOTE: if a new attribute with object or array value is added,
 * update {@link deepCloneDealer} with appropriate cloning methods
 * @param {Array} dealerItem - dealers object
 * @param {String} language - language from config
 * @param {String} distAbbr - Abbreviation for the distance unit for our stub data
 * @return {Object} Formatted Dealer object
 */
function formatDealer(dealerItem, language, distAbbr) {
    const dealerAddress = dealerItem.address[0];

    return { address: dealerAddress && dealerAddress.line1,
        orignalDealerObject: { ...dealerItem },
        badges: dealerItem.badges,
        city: dealerAddress && dealerAddress.city,
        contact: dealerItem.contact,
        country: dealerAddress && dealerAddress.country,
        dealerId: dealerItem.id,
        directionsLink: createDirectionsLink(dealerItem),
        distance: getDistance(dealerItem.address[0], language, distAbbr),
        hasVanCare: dealerItem.badges ? dealerItem.badges.indexOf('VanCAREExpress') !== -1 : false,
        hasVanCareMobile: dealerItem.badges ? dealerItem.badges.indexOf('VanCAREMobile') !== -1 : false,
        id: uniqueId(),
        inventoryUrl: dealerItem.activities && getInventoryUrl(dealerItem),
        isAmg: dealerItem.badges ? dealerItem.badges.indexOf('amg') !== -1 : false,
        isAmgElite: dealerItem.badges ? dealerItem.badges.indexOf('amgelite') !== -1 : false,
        isDealership: dealerItem.type === 'DEALER',
        location: {
            lat: dealerAddress && dealerAddress.location.lat,
            lng: dealerAddress && dealerAddress.location.lng
        },
        marketCode: dealerItem.marketCode,
        name: dealerItem.name,
        phone: dealerItem.contact && getPhoneNumber(dealerItem.contact),
        phoneFormatted: dealerItem.contact && formatNumber.toPhone(getPhoneNumber(dealerItem.contact)),
        regionCode: dealerItem.regionCode,
        scheduleServiceUrl: getServiceUrl(dealerItem),
        state: dealerAddress && dealerAddress.state.toUpperCase(),
        url: dealerItem.url && (dealerItem.url.indexOf('://') !== -1 ? dealerItem.url : `//${dealerItem.url}`) || '',
        zip: dealerAddress && dealerAddress.zip, };
}

/**
 * @function deepCloneDealer
 * @description This function makes deep clone of the dealer object generated in {@link formatDealer}.
 * The following properties need deep clone: badges, contact, location
 * @param {Object} dealer - Dealer object with same structure as in {@link formatDealer}
 * @returns {Object} A cloned object
 */
function deepCloneDealer(dealer) {
    return {

        ...dealer,
        badges: Object.values(dealer.badges),
        contact: Object.values(dealer.contact).map((c) => ({ ...c })),
        location: { ...dealer.location } };
}

/**
 * @method formatDealers
 * @description format the dealers array
  * @param {Array} dealersArr - dealers array
  * @param {String} language - language from config
 */
function formatDealers(dealersArr, language) {
    return dealersArr.map((dealerItem) => formatDealer(dealerItem, language));
}

/**
 * @method formatCollisionCenters
 * @description format the collisionCenters array
 * @param {Array} collisionCentersArr - collision centers array
 * @param {String} language - language from config
 */
const formatCollisionCenters = (collisionCentersArr, language) => {
    const collisionCenters = [];
    collisionCentersArr.forEach((item) => {
        collisionCenters.push({
            id: uniqueId(),
            name: item.name,
            dealer: item.dealer.name,
            address: item.address[0].line1,
            city: item.address[0].city,
            state: item.address[0].state.toUpperCase(),
            zip: item.address[0].zip,
            country: item.address[0].country,
            distance: getDistance(item.address[0], language),
            location: {
                lat: item.address[0].location.lat,
                lng: item.address[0].location.lng
            },
            phone: getPhoneNumber(item.contact),
            phoneFormatted: formatNumber.toPhone(getPhoneNumber(item.contact)),
            isCertified: item.certified === 'true',
            directionsLink: createDirectionsLink(item)
        });
    });
    return collisionCenters;
};

/**
 * @method parseResponse
 * @description parse Response from fetch
 * @param {Object} data - data returned from fetch
 * @param {String} language - language from config
 */
function parseResponse(data, language) {
    let list = null;

    if (data.dealers && data.dealers.length > 0) {
        list = formatDealers(data.dealers, language);

        // add hasInventory boolean to each dealer
        if (data.inventoryDealerIds) {
            list.forEach((dealer) => {
                dealer.hasInventory = data.inventoryDealerIds.indexOf(dealer.dealerId) !== -1;
            });
        } else {
            // For Vans, if there is no inventoryDealers service, set hasInventory for all
            list.forEach((dealer) => {
                dealer.hasInventory = true;
            });
        }
    } else if (data.cc && data.cc.length > 0) {
        list = formatCollisionCenters(data.cc, language);
    }

    return list;
}

/**
 * @method parseResponseAlt
 * @description parse Response from fetch
 * @param {Object} data - data returned from fetch
 * @param {String} country - country from config
 * @param {String} language - language from config
 */
function parseResponseAlt(data, country, language) {
    const dealersObject = {
        results: []
    };

    if (data.lcp && data.lcp.name) {
        dealersObject.lcpHeader = data.lcp.name;
    }

    if (data.status.code !== 200) {
        if (data.status.code.toString().charAt(0) === '4') {
            throw new Error('CLIENT_ERROR');
        } else if (data.status.code.toString().charAt(0) === '5') {
            throw new Error('SERVER_ERROR');
        }
    }

    if (data.results && data.results.length > 0) {
        dealersObject.results = data.results.map((dealerItem) => {
            const formattedDealer = formatDealer(dealerItem, language);
            formattedDealer.hasInventory = hasInventory(dealerItem, country, data.inventoryDealerIds);
            return formattedDealer;
        });

        // Condition to stop adding aoi dealers if we are appending
        if (data.aoiDealer && data.requestParameters.start === 0) {
            const aoi = {
                ...formatDealer(data.aoiDealer, language),
                hasInventory: hasInventory(data.aoiDealer, country, data.inventoryDealerIds),
                isAOIDealer: true,
                isDealership: true
            };

            dealersObject.results.unshift(aoi);
        }
    }

    dealersObject.totalCount = data.totalCount;

    return dealersObject;
}

/**
 * @method getLocations
 * @description Fetches a list of search location groups
 * based on country (e.g. states/provinces)
 * @param country {String} Country to retrieve locations from
 * @param {String} language - language from config
 * @param {String} brand - brand from config
 */
function getLocations(country, language, brand = 'mb') {
    const endpoint = `${dealerLocatorService}/${country}/states?lang=${language}&brand=${brand}`;

    return fetch(endpoint)
        .then((response) => response.json());
}

/**
 * @method fetchDealers
 * @description Fetches dealers according to endpoint
 * @param {String} endpoint - dealer service endpoint
 */
function fetchDealers(endpoint) {
    return fetch(endpoint)
        .then((response) => response.json());
}

/**
 * @function
 * @name getInventoryDealers
 * @param {Boolean} isNew - flag to differentiate between new and cpo
 * @description Calls the inventory service and gets a list of dealer ids that have inventory
 */
function getInventoryDealers(isNew) {
    const dealerType = isNew ? 'new' : 'cpo';

    const inventoryDealersEndpoint = `${inventoryService}/${dealerType}/dealers`;

    if (!INVENTORY_DEALER_RESPONSE[dealerType]) {
        return fetch(inventoryDealersEndpoint)
            .then((response) => response.json())
            .then((result) => {
                INVENTORY_DEALER_RESPONSE[dealerType] = result;
                return INVENTORY_DEALER_RESPONSE[dealerType];
            });
    }

    return Promise.resolve(INVENTORY_DEALER_RESPONSE[dealerType]);
}

/**
 * @method getDealersData
 * @description Resolves promise for fetching dealers and list of dealer ids who have inventory
 * @param {String} dealersEndpoint - dealer service endpoint
 * @param {Boolean} isNew - flag to differentiate between new and cpo
 * @return object containing dealers info and array of dealers with inventory
 */
function getDealersData(dealersEndpoint, isNew = true) {
    const dealerServices = [fetchDealers(dealersEndpoint)];

    // TODO: enable when 'dealers' from Inventory service is available for Vans
    const enableGetInventoryDealers = false;

    // if inventory is enabled, get the map of inventory dealers
    if (isInventoryEnabled && enableGetInventoryDealers) {
        dealerServices.push(getInventoryDealers(isNew));
    }

    return Promise.all(dealerServices)
        .then((results) => ({
            ...results[0],
            inventoryDealerIds: results[1] && results[1].result
        }));
}

/**
 * @method filterDealers
 * @description Filters dealers based on filterBy param
 * @param {Array} dealers - Array of dealer objects from API response
 * @param {String} filterBy - filter type
 */
function filterDealers(dealers, filterBy) {
    switch (filterBy) {
    case FILTER_TYPES.INVENTORY:
        return dealers.filter((dealer) => dealer.hasInventory);
    default:
        return dealers;
    }
}

/**
 * @method getDealersByLocation
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.zip - Zip code
 * @param {String} opts.lat - Latitude
 * @param {String} opts.lng - Longitude
 * @param {String} opts.radius - Radius for search
 * @param {String} opts.brand - Brand code
 * @param {String} opts.country - Country code
 * @param {String} opts.type - Type of search, dealers or cc
 * @param {String} opts.language - the language from config
 * @param {String} opts.limit - Flag used to find closest dealers in pre-defined radius range
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 */
function getDealersByLocation({
    zip,
    lat,
    lng,
    radius = 25,
    brand,
    country,
    type,
    language,
    limit,
    isNew
} = {}) {
    const dealersEndpoint = `${dealerLocatorService}/${country}/${type}${serialize({ zip, lat, lng, radius, limit, brand })}`;

    return getDealersData(dealersEndpoint, isNew)
        .then((data) => parseResponse(data, language));
}

/**
 * @method getDealersByLocationAlt
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.zip - Zip code
 * @param {String} opts.lat - Latitude
 * @param {String} opts.lng - Longitude
 * @param {String} opts.brand - Brand code
 * @param {String} opts.country - Country code
 * @param {String} opts.language - the language from config
 * @param {Number} opts.start - Index to start query at
 * @param {Number} opts.count - number of result items to show for query from start
 * @param {String} opts.filter - type of dealer to filter through
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 *                               dealers with inventory
 */
function getDealersByLocationAlt({
    zip,
    lat,
    lng,
    brand,
    country,
    language,
    start,
    count,
    filter = 'all',
    isNew,
    state
} = {}) {
    const dealersEndpoint = `${dealerLocatorService}/${country}/search${serialize({ zip, lat, lng, start, count, filter, state, brand })}`;
    return getDealersData(dealersEndpoint, isNew)
        .then((data) => parseResponseAlt(data, country, language));
}

/**
 * @method getDealersByLCP
 * @description Gets a list of dealers based on lcp code
 * @param {String} country - Country code
 * @param {String} language - the language from config
 * @param {String} code - LCP code
 * @param {String} brand - brand code
 */
function getDealersByLCP(country, language, code, brand = 'mb') {
    const endpoint = `${dealerLocatorService}/${country}/search?lcp=${code}&brand=${brand}`;

    return getDealersData(endpoint)
        .then((data) => parseResponseAlt(data, country, language));
}

/**
 * @method getByState
 * @description Gets a collection of dealer/collision centers
 * based on state/province and country location
 * @param country {String} Country code
 * @param state {String} State/Province code
 * @param type {String} Type of locations to retrieve (dealer or collision center)
 * @param {String} language - language from config
 * @param {String} brand - brand code
 */
function getByState(country, state, type, language, brand = 'mb') {
    const endpoint = `${dealerLocatorService}/${country}/${type}?state=${state}&brand=${brand}`;

    return getDealersData(endpoint)
        .then((data) => parseResponse(data, language));
}

/**
 * @method getOptions
 * @description Builds out the options object
 * Gets the request params needed for dealer search
 * If the current searchByType is zip, resolves to zip
 * If the current searchByType is place, makes google api call to get lat/lng
 * @param {Object} opts - Options for service call
 * @param {String} opts.radius - Radius for search
 * @param {String} opts.searchType - 'dealer' or 'collisionCenter'
 * @param {String} opts.brand - Brand code ('mb' or 'fl')
 * @param {String} opts.country - Country code
 * @param {String} opts.language - The language from config
 * @param {String} opts.limit - Flag used to find closest dealers in pre-defined radius range
 * @param {String} opts.searchByType - 'zip' or 'place'
 * @param {String} opts.searchLocation - Postcode location
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 */
function getOptions({
    radius,
    searchType,
    brand,
    country,
    language,
    limit,
    searchByType,
    searchLocation,
    isNew,
    start,
    count,
    filter,
    state
} = {}) {
    const opts = {
        radius,
        type: searchType,
        brand,
        country,
        language,
        limit,
        isNew,
        start,
        count,
        filter,
        state
    };

    if (searchByType === 'zip') {
        opts.zip = searchLocation;
    } else if (searchByType === 'place') {
        return googleLocationsApi.getPlaceCoordinates(searchLocation)
            .then(({ lat, lng }) => {
                opts.lat = lat;
                opts.lng = lng;
                return opts;
            });
    }

    return Promise.resolve(opts);
}

/**
 * @method getDealer
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.id - Dealer Id to retrieve
 * @param {String} opts.country - Country code
 * @param {String} opts.type - Type of search, dealers or cc
 * @param {String} opts.language - the language from config
 */
function getDealer({ id, country, language, type } = {}) {
    const endpoint = `${dealerLocatorService}/${country}/${type}${serialize({ id })}`;

    return fetch(endpoint)
        .then((response) => response.json())
        .then((data) => parseResponse(data, language))
        .then((dealers) => {
            if (dealers && dealers.length) {
                return dealers[0];
            }

            throw new Error(`No dealers found with the id ${id}`);
        });
}

/**
 * @method getDealers
 * @description Gets the dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.radius - Radius for search
 * @param {String} opts.searchType - 'dealers' or 'cc'
 * @param {String} opts.brand - Brand code ('mb' or 'fl')
 * @param {String} opts.country - Country code
 * @param {String} opts.language - The language from config
 * @param {String} opts.limit - Flag used to find closest dealers in pre-defined radius range
 * @param {String} opts.searchByType - 'zip' or 'place'
 * @param {String} opts.searchLocation - Postcode location
 * @param {String} opts.filterBy - Optional FILTER_TYPES value
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 */
function getDealers({
    radius = 'all',
    searchType = TYPES.dealers,
    brand = 'mb',
    country = 'us',
    language = 'en',
    limit,
    searchByType,
    searchLocation,
    filterBy = null,
    isNew = true
} = {}) {
    return getOptions({
        radius,
        searchType,
        brand,
        country,
        language,
        limit,
        searchByType,
        searchLocation,
        isNew
    })
        .then(getDealersByLocation)
        .then((dealers) => {
            // filter dealers if filterBy was set
            if (dealers && filterBy) {
                return filterDealers(dealers, filterBy);
            }

            return dealers;
        });
}

/**
 * @method getDealersAlt
 * @description Alternative method to grab dealers based on params
 * @param {Object} opts - Options for service call
 * @param {String} opts.brand - Brand code ('mb' or 'fl')
 * @param {String} opts.country - Country code
 * @param {String} opts.language - The language from config
 * @param {String} opts.searchByType - 'zip' or 'place'
 * @param {String} opts.searchLocation - Postcode location
 * @param {String} opts.filterBy - Optional FILTER_TYPES value
 * @param {Boolean} opts.isNew - flag to differentiate between new and cpo when querying for
 * dealers with inventory
 * @param {String} opts.start - starting number to start querying items from
 * @param {String} opts.count - number to result items to show per query
 */
function getDealersAlt({
    brand = 'mb',
    country = 'us',
    language = 'en',
    searchByType,
    searchLocation,
    filterBy = null,
    isNew = true,
    start,
    count,
    filter,
    state
} = {}) {
    return getOptions({
        brand,
        country,
        language,
        searchByType,
        searchLocation,
        filterBy,
        isNew,
        start,
        count,
        filter,
        state
    })
        .then(getDealersByLocationAlt)
        .then((dealersObject) => {
            // filter dealers if filterBy was set
            if (dealersObject.results && filterBy) {
                return {
                    results: filterDealers(dealersObject.results, filterBy),
                    totalCount: dealersObject.totalCount
                };
            }

            return dealersObject;
        });
}


/**
 * Export public api methods
 */
export default {
    TYPES,
    FILTER_TYPES,
    formatDealer,
    deepCloneDealer,
    getLocations,
    getByState,
    getDealers,
    getDealer,
    getDealersAlt,
    getDealersByLCP
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a Toggle Buttons view.
 * Uses ES6 template literal to composite the markup for a ToggleButtons
 */
import { htmlNode } from 'utils';

/**
 * @method renderButtons
 * @description Iterates array to render a button for each
 * @param buttons {Array}
 * @return {string} Button elements
 */
function renderButtons(buttons) {
    let buttonsMarkup = '';
    buttons.forEach((button) => {
        const dataAnalyticsTrigger = button.dataAnalyticsTrigger ?
            `data-analytics-trigger="${button.dataAnalyticsTrigger}"` : '';

        buttonsMarkup += `<span ${dataAnalyticsTrigger}>
            <input type="radio"
                   id="${button.id}"
                   class="toggle-buttons__input"
                   name="toggle-element"
                   value="${button.label}"
                   data-button-id="${button.id}"
                   ${button.isActive ? 'checked' : ''}/>
            <label for="${button.id}"
                   class="toggle-buttons__button"
                   data-button-id="${button.id}" data-is-active="${button.isActive || false}">${button.label}</label></span>`;
    });

    return buttonsMarkup;
}

/**
 *
 * @param buttons {Array} Collection of buttons to render
 */
export default (buttons) => htmlNode`
    <div class="toggle-buttons">
        ${renderButtons(buttons)}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

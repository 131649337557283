/**
 * Tabbed Module
 * Has methods related to composition of the tabbed module
 */

import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import { screen, debounce, customEventDispatcher } from 'utils';
import { accordionParser } from 'partials/accordion';

const CLASSES = {
    TABBED_MODULE_BODY: 'individual-tab__accordion-item-content',
    TABBED_MODULE_LINK: 'tabbed-module__header-link',
    TABBED_MODULE_ITEM: 'tabbed-module__header-item',
    IS_ACTIVE: 'active',
    NAV_LEFT_ARROW: 'tabbed-module__nav-controls--left-arrow',
    NAV_RIGHT_ARROW: 'tabbed-module__nav-controls--right-arrow'
};

const ATTRIBUTES = {
    DATA_ACTIVE: 'data-active',
    DATA_TAB_INDEX: 'data-tab-index',
    ACCORDION_TITLE: 'data-accordion-title',
    ACCORDION_BUTTON: 'data-accordion-button',
    ACCORDION_PANE: 'data-accordion-pane',
    FUNCTIONAL_THEME: 'data-functional-theme'
};

/**
 * @const ARIA_ATTRIBUTES
 * @description Collection of constant values for related aria attributes of the module
 * @type {{HIDDEN: string}}
 */
const ARIA_ATTRIBUTES = {
    HIDDEN: 'aria-hidden',
    ARIA_SELECTED: 'aria-selected'
};

const PROPS = {
    MAX_ITEMS_DESKTOP: 5,
    MAX_ITEMS_TABLET: 4,
    UNITS: 'px'
};

export default class TabbedModule {
    constructor(element) {
        this.element = element;
        this.accordionMode = false;
        this.navControls = {};
        this.navOffset = 0;
        this.navOffsetCount = 0;
        this.navItemWidth = 0;
        this.currentScreenState = screen.getState();
        this.maxItemCount = (screen.gte(screen.SIZES.XLARGE))
            ? PROPS.MAX_ITEMS_DESKTOP : PROPS.MAX_ITEMS_TABLET;
        this.currentNavIndex = 0;
        this.apparentNavIndex = 0;

        // method aliases
        this.onScreenResize = this.onScreenResize.bind(this);
        this.paginationListener = this.paginationListener.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.touchListenerStartDebounce = debounce(this.touchListenerStart.bind(this), 10);
        this.touchListenerMoveDebounce = debounce(this.touchListenerMove.bind(this), 20);
        this.init();
    }

    /**
     * init()
     * calls initial functions
     */
    init() {
        this.cacheDOM();
        this.accordionMode = this.element.getAttribute(ATTRIBUTES.FUNCTIONAL_THEME) === 'accordion';
        if (!this.accordionMode) {
            this.initTabs();
        } else {
            this.initAccordion();
        }
    }

    cacheDOM() {
        this.tabbedModuleLinks = this.element.querySelectorAll(`.${CLASSES.TABBED_MODULE_LINK}`);
        this.tabbedModuleBody = this.element.querySelectorAll(`.${CLASSES.TABBED_MODULE_BODY}`);
        this.tabbedModuleItems = this.element.querySelectorAll(`.${CLASSES.TABBED_MODULE_ITEM}`);
        this.navControls.leftArrow = this.element.querySelector(`.${CLASSES.NAV_LEFT_ARROW}`);
        this.navControls.rightArrow = this.element.querySelector(`.${CLASSES.NAV_RIGHT_ARROW}`);
        this.accordionTitle = this.element.querySelector(`[${ATTRIBUTES.ACCORDION_TITLE}]`);
        this.accordionButton = this.element.querySelector(`[${ATTRIBUTES.ACCORDION_BUTTON}]`);
        this.accordionPane = this.element.querySelector(`[${ATTRIBUTES.ACCORDION_PANE}]`);
        this.accordionElement = this.element.querySelector('[data-accordion]');
    }

    initTabs() {
        if (this.accordionElement &&
            this.accordionTitle && this.accordionButton && this.accordionPane) {
            this.attachEvents();
            this.setPagination();
            this.initAccordion();
        }
        if (this.tabbedModuleBody.length > 0) {
            this.tabbedModuleLinks[this.currentNavIndex].classList.add(CLASSES.IS_ACTIVE);
            this.activeBodySection(this.currentNavIndex);
        }
    }

    initAccordion() {
        this.accordion = accordionParser.createAccordion({
            element: this.accordionElement
        });
    }

    /**
     * @method destroy
     * @description Destroys the modules by detaching its events and
     * destroying the accordion instance if defined
     */
    destroy() {
        this.detachEvents();

        if (this.accordion) {
            this.accordion.destroy();
        }
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners and callback methods for view
     */
    attachEvents() {
        this.navControls.rightArrow.addEventListener(EVENTS.CLICK, this.paginationListener);

        this.navControls.leftArrow.addEventListener(EVENTS.CLICK, this.paginationListener);

        [].slice.call(this.tabbedModuleLinks).forEach((tabbedModuleLink) => {
            tabbedModuleLink.addEventListener(EVENTS.CLICK, this.setActiveTab);
            tabbedModuleLink.addEventListener(EVENTS.TOUCHSTART, this.touchListenerStartDebounce);
            tabbedModuleLink.addEventListener(EVENTS.TOUCHMOVE, this.touchListenerMoveDebounce);
        });

        screen.addResizeListener(this.onScreenResize);
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callback methods for view
     */
    detachEvents() {
        this.navControls.rightArrow.removeEventListener(EVENTS.CLICK, this.paginationListener);

        this.navControls.leftArrow.removeEventListener(EVENTS.CLICK, this.paginationListener);

        [].slice.call(this.tabbedModuleLinks).forEach((tabbedModuleLink) => {
            tabbedModuleLink.removeEventListener(EVENTS.CLICK, this.setActiveTab);

            tabbedModuleLink.removeEventListener(
                EVENTS.TOUCHSTART, this.touchListenerStartDebounce
            );

            tabbedModuleLink.removeEventListener(EVENTS.TOUCHMOVE, this.touchListenerMoveDebounce);
        });

        screen.removeResizeListener(this.onScreenResize);
    }

    /**
     * @method onScreenResize()
     * @description Handles screen resize events; resets pagination if necessary
     */
    onScreenResize(screenState) {
        if (screenState !== this.currentScreenState) {
            this.currentScreenState = screenState;
            this.recalculateOffset();
        }
    }

    /**
     * @method setPagination()
     * @description Right arrow is active if pagination is more than max items
     */
    setPagination() {
        this.navItemWidth = this.tabbedModuleItems[this.currentNavIndex].offsetWidth;

        if (this.tabbedModuleItems.length > this.maxItemCount) {
            this.navControls.rightArrow.classList.add(CLASSES.IS_ACTIVE);
        }
    }

    /**
     * @method recalculateOffset()
     * @description recalculate navOffsetCount when window resize crosses responsive breakpoint
     */
    recalculateOffset() {
        this.navItemWidth = this.tabbedModuleItems[this.currentNavIndex].offsetWidth;
        this.maxItemCount = (screen.gte(screen.SIZES.XLARGE))
            ? PROPS.MAX_ITEMS_DESKTOP : PROPS.MAX_ITEMS_TABLET;

        if (this.tabbedModuleLinks.length > this.maxItemCount) {
            const minValidOffset = this.currentNavIndex <= 1 ? 0 : -(this.currentNavIndex - 1);
            const maxValidOffset = this.tabbedModuleItems.length - (this.maxItemCount - 1);

            this.navOffsetCount = this.currentNavIndex >= maxValidOffset
                ? -(maxValidOffset - 1) : minValidOffset;
            this.paginateBasedOnOffset();
        } else {
            this.resetPagination();
        }
    }

    /**
     * @method paginationListener()
     * @description callback for left and right click event for the pagination controls
     * */
    paginationListener(event) {
        const isRightArrow = event.currentTarget.classList.contains(CLASSES.NAV_RIGHT_ARROW);
        this.navOffsetCount += isRightArrow ? -1 : 1;
        this.paginateBasedOnOffset();
    }

    /**
     * @method paginateBasedOnOffset()
     * @description paginate the items based on the actual navOffsetCount
     * */
    paginateBasedOnOffset() {
        this.navOffset = this.navItemWidth * Math.abs(this.navOffsetCount);

        const disableRightArrow = this.navOffset > this.navItemWidth *
                                (this.tabbedModuleItems.length - this.maxItemCount - 1);

        this.navControls.leftArrow.classList[this.navOffset === 0 ? 'remove' : 'add'](CLASSES.IS_ACTIVE);
        this.navControls.rightArrow.classList[disableRightArrow ? 'remove' : 'add'](CLASSES.IS_ACTIVE);

        this.tabbedModuleItems[0].style.marginLeft = `-${this.navOffset}${PROPS.UNITS}`;
    }

    /**
     * @method resetPagination
     * @description clears navigation arrows and recenters tabs
     */
    resetPagination() {
        this.navControls.leftArrow.classList.remove(CLASSES.IS_ACTIVE);
        this.navControls.rightArrow.classList.remove(CLASSES.IS_ACTIVE);
        this.navOffset = 0;
        this.tabbedModuleItems[0].style.marginLeft = `-${this.navOffset}${PROPS.UNITS}`;
    }

    /**
     * @method touchListenerStart
     * @param {Event} event - a touchstart event
     */
    touchListenerStart(event) {
        this.xCoordinate = event.touches[0].pageX;
    }

    /**
     * @method touchListenerMove
     * @param {Event} event - a touchmove event
     */
    touchListenerMove(event) {
        if (!this.xCoordinate) {
            return;
        }
        const xUp = event.touches[0].pageX;
        const xDiff = this.xCoordinate - xUp;

        if (xDiff > 0 && this.navControls.rightArrow.classList.contains(CLASSES.IS_ACTIVE)) {
            // left swipe
            this.navControls.rightArrow.click();
        } else if (xDiff < 0 && this.navControls.leftArrow.classList.contains(CLASSES.IS_ACTIVE)) {
            // right swipe
            this.navControls.leftArrow.click();
        }
    }

    /**
     * @method setActiveTab
     * @description sets up active nav tab
     */
    setActiveTab(e) {
        const currentItem = e.currentTarget;
        this.currentNavIndex = parseInt(currentItem.getAttribute(ATTRIBUTES.DATA_TAB_INDEX), 10);
        this.apparentNavIndex = this.currentNavIndex + this.navOffsetCount;
        currentItem.classList.add(CLASSES.IS_ACTIVE);
        currentItem.setAttribute(ARIA_ATTRIBUTES.ARIA_SELECTED, true);

        [].slice.call(this.tabbedModuleLinks).filter((tabbedModuleLink) =>
            (tabbedModuleLink !== currentItem))
        .forEach((tabbedModuleLink) => {
            tabbedModuleLink.classList.remove(CLASSES.IS_ACTIVE);
            tabbedModuleLink.setAttribute(ARIA_ATTRIBUTES.ARIA_SELECTED, false);
        });

        this.activeBodySection(this.currentNavIndex);

        if (this.apparentNavIndex === 0 &&
            this.navControls.leftArrow.classList.contains(CLASSES.IS_ACTIVE)) {
            this.navControls.leftArrow.click();
        } else if (this.apparentNavIndex === this.maxItemCount - 1 &&
                   this.navControls.rightArrow.classList.contains(CLASSES.IS_ACTIVE)) {
            this.navControls.rightArrow.click();
        }
    }

    /**
     * @method activeBodySection
     * @description sets up active body content of the active nav tab
     */
    activeBodySection(index) {
        const currentBody = this.tabbedModuleBody[index];
        currentBody.setAttribute(ATTRIBUTES.DATA_ACTIVE, 'true');
        currentBody.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'false');

        [].slice.call(this.tabbedModuleBody).filter((tabbedModuleBody) =>
            (tabbedModuleBody !== currentBody))
        .forEach((tabbedModuleBody) => {
            tabbedModuleBody.removeAttribute(ATTRIBUTES.DATA_ACTIVE);
            tabbedModuleBody.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'true');
        });

        this.dispatchDisplayState();
    }

    /**
     * @method dispatchDisplayState
     * @description dispatches custom event to set active state
     */
    dispatchDisplayState() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.CONTAINER_DISPLAY_STATE_CHANGED)
        );
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * API responsible for fetching and constructing data from the Warranty & Maintenance - Content As A Service (CAAS)
 */

/**
 * @function getMoreInfoLinks
 * @description Gets the moreInfoLinks from a product and filters them based on the contentConfig and the link
 * `isNew` and `isCPO` values if defined
 * @param moreInfoLinks {Object} Object of link objects
 * @param contentConfig {Object} Indicators for the vehicle type (isNew | isCPO)
 * @return {Array} Filters moreInfoLinks
 */
function getMoreInfoLinks(moreInfoLinks, contentConfig) {
    const links = [];

    Object.keys(moreInfoLinks).forEach((linkKey) => {
        const filterKeys = {
            CPO: 'cpo',
            NEW: 'new'
        };
        const hasVehicleCondition = contentConfig.isNew || contentConfig.isCPO;
        const isFilterLink = Object.values(filterKeys).includes(linkKey);
        const filterCPO = contentConfig.isCPO && linkKey === filterKeys.CPO;
        const filterNew = contentConfig.isNew && linkKey === filterKeys.NEW;

        if (hasVehicleCondition && (filterCPO || filterNew)) {
            links.push(moreInfoLinks[linkKey]);
        } else if ((hasVehicleCondition && !isFilterLink) || !hasVehicleCondition) {
            links.push(moreInfoLinks[linkKey]);
        }
    });

    return links;
}

/**
 * @function getElwHeaderDescription
 * @description Gets the ELW header description value based on the vehicle type from the contentConfig
 * @param elwContent {Object} CAAS content.productListing.products.elw object
 * @param contentConfig {Object} Indicators for the vehicle type (isNew | isCPO)
 * @return {string}
 */
function getElwHeaderDescription(elwContent, contentConfig) {
    const {
        isCPO,
        isNew
    } = contentConfig || {};

    const {
        headerDescription,
        subheadCpo,
        subheadNew
    } = elwContent || {};

    if (isNew && subheadNew) {
        return subheadNew;
    } else if (isCPO && subheadCpo) {
        return subheadCpo;
    }

    return headerDescription;
}

/**
 * @function getData
 * @description Parses the Warranty & Maintenance data that is returned from the CAAS response
 * and constructs the config and content data based on the content configuration
 * @param data {Object} CAAS response object with content and config objects
 * @param contentConfig {Object} Configuration for how to construct the content
 * @param contentConfig.isCPO {boolean} Indicator for if the content should be constructed for CPO/Pre-owned vehicles
 * @param contentConfig.isNew {boolean} Indicator for if the content should be constructed for New vehicles
 * @return {{config: *, content: *}|null}
 */
function getData(data, contentConfig = {}) {
    try {
        const {
            config = {},
            content = {}
        } = data || {};

        const {
            productListing: contentProductListing
        } = content || {};

        const {
            products: productListingProducts
        } = contentProductListing || {};

        const {
            elw: productsElw,
            ppm: productsPpm,
        } = productListingProducts || {};

        const {
            moreInfoLinks: productsElwMoreInfoLinks = []
        } = productsElw || {};

        const {
            moreInfoLinks: productsPpmMoreInfoLinks = []
        } = productsPpm || {};

        const responseContent = {
            ...data.content,
            productListing: {
                ...contentProductListing,
                products: {
                    ...productListingProducts,
                    elw: {
                        ...productsElw,
                        headerDescription: getElwHeaderDescription(
                            productsElw, contentConfig
                        ),
                        moreInfoLinks: getMoreInfoLinks(
                            productsElwMoreInfoLinks, contentConfig
                        )
                    },
                    ppm: {
                        ...productsPpm,
                        moreInfoLinks: getMoreInfoLinks(
                            productsPpmMoreInfoLinks, contentConfig
                        )
                    }
                }
            }
        };

        return {
            config,
            content: responseContent
        };
    } catch (err) {
        console.error('Error parsing the Warranty and Maintenance CAAS data', err);
        return null;
    }
}

export default {
    getData
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

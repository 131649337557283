// Util dependencies
import { viewportObserver, generateUniqueID, customEventDispatcher, renderer } from 'utils';
import { CUSTOM_EVENTS } from 'Constants';

// Local dependencies
import lazyLoaderAspectRatioTemplate from './templates/lazyLoaderAspectRatioTemplate';

/**
 * @const CLASSES
 * @description Class names associated with LazyLoader
 * @type {{
 *      LOADER: string,
 *      LOADED: string,
 *      LOADING: string,
 *      ASSET: string,
 *      ASSET_LOADED: string,
 *      ASSET_MINIFIED: string
 * }}
 */
const CLASSES = {
    LOADER: 'lazy-loader',
    LOADED: 'lazy-loader--loaded',
    LOADING: 'lazy-loader--loading',
    ASSET: 'lazy-loader__asset',
    ASSET_LOADED: 'lazy-loader__asset--loaded',
    ASSET_MINIMIZED: 'lazy-loader__asset--minimized'
};

/**
 * @const TYPES
 * @description Node Name types that can be interfaced with
 * @type {{PICTURE: string, IMAGE: string, SOURCE: string}}
 */
const TYPES = {
    PICTURE: 'PICTURE',
    IMAGE: 'IMG',
    SOURCE: 'SOURCE'
};

/**
 * @const WINDOW_THRESHOLD
 * @description Window threshold buffer to load a LazyLoader asset
 *
 * @type {number}
 */
const WINDOW_THRESHOLD = 200;

/**
 * @class LazyLoader
 * @description Module for lazy loading a picture or image element and its sources
 *
 * Example Usage:
 *
 * <picture>
 *     <source media="(max-width: 767px)" srcset="empty.gif" data-lazy-src="{src}" />
 *     <img src="empty.gif" alt="ImageAlt" data-lazy-src="{src}" />
 * </picture>
 *
 * <img src="empty.gif" alt="ImageAlt" data-lazy-src="{src}" />
 */
export default class LazyLoader {
    /**
     * @constructor
     * @description On instantiation, sets the state, binds alias methods, parses and
     * sets the assets to load, creates necessary styling, and attaches events
     * @param element {Node} The DOM element to lazy load
     * @param rootElementId {String} ID for the element that is used as the viewport for
     * checking visibility of the target
     */
    constructor(element, rootElementId = null) {
        this.element = element;
        this.root = rootElementId ? document.getElementById(rootElementId) : null;
        this.forceLoad = false;
        this.isLoaded = false;
        this.assets = null;
        this.id = generateUniqueID();
        this.aspectRatios = this.element.dataset.aspectRatios;
        this.init();
    }

    /**
     * @method init
     * @description Initialize the LazyLoader Module
     */
    init() {
        this.setBindings();
        this.cacheDOM();
        const { isOnDisplay } = window.mbVans.ns('pageData', 'variantInfo');
        this.setAssets();
        this.createStyles();
        this.setUniqueID();
        if (this.aspectRatios) {
            this.setAspectRatios();
        }
        this.setCustomEvent();
        if (isOnDisplay || this.forceLoad) {
            // Turn off lazy loading for DDT OnDisplay variant because it has a caching mechanism
            this.loadAssets();
        } else {
            this.attachEvents();
        }
    }

    /**
     * @method cacheDOM
     * @description Caches reference to DOM elements from the view
     */
    cacheDOM() {
        this.allowExternalForceLoad = this.element.hasAttribute(`${'data-allow-external-force-load'}`);
        this.forceLoad = !!this.element.dataset && this.element.dataset.lazyForceLoad === 'true';
    }

    /**
     * @method setBindings
     * @description Sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onForceAssetLoad = this.onForceAssetLoad.bind(this);
        this.onObserver = this.onObserver.bind(this);
        this.unsetAspectRatios = this.unsetAspectRatios.bind(this);
    }

    /**
     * @method attachEvents
     * @description Adds event listeners
     */
    attachEvents() {
        this.vObserver = viewportObserver.addObserver(
            this.element,
            this.onObserver,
            {
                threshold: 0,
                root: this.root,
                rootMargin: `${WINDOW_THRESHOLD}px 0px`
            }
        );
    }

    /**
     * @method detachEvents
     * @description Removes event listeners
     */
    detachEvents() {
        viewportObserver.removeObserver(this.vObserver);
    }

    /**
     * @method destroy
     * @description Detaches event listeners
     */
    destroy() {
        this.detachEvents();
    }

    /**
     * @method onObserver
     * @description Event handler for IntersectionObserver
     */
    onObserver(entries) {
        if (entries[0].isIntersecting && !this.isLoaded) {
            this.loadAssets();
            this.detachEvents();
        }
    }

    /**
     * @method createStyles
     * @description Adds associated classes to the lazy loading element and asset elements
     */
    createStyles() {
        this.element.classList.add(CLASSES.LOADER);

        this.assets.forEach((asset) => {
            asset.classList.add(CLASSES.ASSET);
        });
    }

    /**
     * @method setUniqueID
     * @description adds a unique id to the element called data-lazyloader-id
     */
    setUniqueID() {
        this.element.setAttribute('data-lazyloader-id', this.id);
    }

    /**
     * @method setAspectRatios
     * @description Adds styles for forcing placeholder aspect ratio, until assets are lazy loaded
     */
    setAspectRatios() {
        this.aspectRatioTemplate = lazyLoaderAspectRatioTemplate(
            this.id,
            {
                smallAspectRatio: this.element.dataset.smallMediaAspectRatio,
                largeAspectRatio: this.element.dataset.largeMediaAspectRatio,
                mediaQuery: this.element.dataset.mediaQuery
            }
        )({ getNode: true });
        renderer.insertAdjacentElement(
            this.aspectRatioTemplate,
            this.element,
            renderer.POSITIONS.BEFORE_BEGIN
        );
    }

    /**
     * @method unsetAspectRatios
     * @description Removes styles for forcing placeholder aspect ratio, once assets are lazy loaded
     */
    unsetAspectRatios() {
        this.aspectRatioTemplate.remove();
        this.aspectRatioTemplate = null;
        delete this.aspectRatioTemplate;
    }

    /**
     * @method setCustomEvent
     * @description checks if there allow external force is true and adds
     * a custom event listener to call force asset load
     */
    setCustomEvent() {
        if (this.allowExternalForceLoad) {
            customEventDispatcher.addEventListener(CUSTOM_EVENTS.FORCE_ASSET_LOAD, this.onForceAssetLoad);
        }
    }


    /**
     * @method setAssets
     * @description Parses and sets image and source elements from the lazy loading element
     */
    setAssets() {
        if (this.element.nodeName === TYPES.PICTURE) {
            const imageElm = this.element.querySelectorAll('img');
            const sourceElms = this.element.querySelectorAll('source');

            this.assets = [...imageElm].concat([...sourceElms]);
        } else if (this.element.nodeName === TYPES.IMAGE) {
            this.assets = [this.element];
        }
    }

    /**
     * @method loadAssets
     * @description Interates each asset element to load its asset and sets the isLoaded state
     */
    loadAssets() {
        this.assets.forEach((asset) => {
            this.loadAsset(asset);
        });
        this.isLoaded = true;
    }

    /**
     * @method loadAsset
     * @description Sets the source of the assets to load and applies a callback
     * to set its styling when fully loaded
     * @param asset {Node} Element to load the asset for
     * @return {LazyLoader} Returns itself for chaining methods
     */
    loadAsset(asset) {
        const { lazySrc } = asset.dataset;
        const sourceType = asset.nodeName === TYPES.SOURCE ? 'srcset' : 'src';

        // if an asset was previously loaded and we are now loading
        // a new one remove the loaded class
        if (asset.classList.contains(CLASSES.LOADED)) {
            this.element.classList.remove(CLASSES.LOADED);
        }

        if (lazySrc) {
            asset.onload = () => {
                asset.classList.add(CLASSES.ASSET_LOADED);
                asset.classList.remove(CLASSES.ASSET_MINIMIZED);
                this.element.classList.add(CLASSES.LOADED);
                if (this.aspectRatios) {
                    this.unsetAspectRatios();
                }
            };
            asset[sourceType] = lazySrc;
        }

        return this;
    }

    /**
     * @method onForceAssetLoad
     * @description Forces assets to load based on the unique id
     * @param e {Event} event to get the unique id from
     */
    onForceAssetLoad(e) {
        e.detail.lazyloaderIdSet.forEach((lazyloaderId) => {
            if (lazyloaderId === this.id) {
                this.loadAssets();
                this.detachEvents();
            }
        });
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Image Slider Module
 * This module updates width of image within a slider when user slides right or left
 * to view more of the corresponding image.
 */
import { EVENTS } from 'Constants';

/**
 * @const IDS
 * @description Collection of constant values for ids of elements of the module
 * @type {{
 *   IMAGE_SLIDER: string,
 *   IMAGE_DIVIDER: string
 * }}
 */
const IDS = {
    IMAGE_SLIDER: 'image-slider',
    IMAGE_DIVIDER: 'image-slider-divider'
};

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{
 *   IMAGE_RULE: string
 * }}
 */
const CLASSES = {
    IMAGE_RULE: 'image-slider__rule'
};

export default class ImageSlider {
    constructor(element) {
        this.element = element;
        this.cacheDOMElements.bind(this);
        this.attachEvents.bind(this);
        this.init();
    }

    init() {
        this.cacheDOMElements();
        this.attachEvents();
        this.moveDivisor();
    }

    cacheDOMElements() {
        this.divider = this.element.querySelector(`#${IDS.IMAGE_DIVIDER}`);
        this.slider = this.element.querySelector(`#${IDS.IMAGE_SLIDER}`);
        this.rule = this.element.querySelector(`.${CLASSES.IMAGE_RULE}`);
    }

    attachEvents() {
        this.slider.addEventListener(EVENTS.INPUT, this.moveDivisor.bind(this));

        // the change event is needed for IE11
        this.slider.addEventListener(EVENTS.CHANGE, this.moveDivisor.bind(this));
    }

    moveDivisor() {
        const pos = this.slider.value / 100 * this.divider.offsetWidth;
        this.divider.style.clip = `rect(0px ${pos}px ${this.divider.offsetHeight}px 0px)`;
        this.rule.style.left = `${pos}px`;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';
/**
 * Template for a Dealer Info Map view.
 * Uses ES6 template literal to composite the markup for a DealerInfoMap
 */
export default () => htmlNode`
    <div class="dealer-info-map">
        <div class="dealer-info-map__info-card"></div>
        <div class="dealer-info-map__info-map-container">
            <div class="dealer-info-map__info-map" data-dealer-map></div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

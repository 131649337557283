import DependencyLoader from 'utils/DependencyLoader';

/**
 * @const PLUGIN_CONTAINER_EXTENSION
 * @description plugin container id extension
 */
const PLUGIN_CONTAINER_EXTENSION = '-plugin-container';

/**
 * @class PlugInModule
 * @description Component for including a plug-in-module
 * @param {Object} element
 */
export default class PlugInModule {
    /**
     * @constructor
     * @method Create a Component containing a PlugInModule
     * @param element {Object}
     */
    constructor(element) {
        this.element = element;
        this.pluginName = this.element.dataset.pluginId;
        this.dependencies = this.element.dataset.pluginDependencies;

        this.dependencyLoader = new DependencyLoader();
        this.loadDependencies = this.loadDependencies.bind(this);
        this.loadPlugin = this.loadPlugin.bind(this);

        this.cacheDOM();
        this.loadDependencies()
            .then(this.loadPlugin);
    }

    /**
     * @method cacheDOM
     * @description Caches DOM Element
     */
    cacheDOM() {
        this.pluginContainer = this.element.querySelector(`#${this.pluginName}${PLUGIN_CONTAINER_EXTENSION}`);
    }

    /**
     * @method loadDependencies
     * @description Loads dependencies that are passed from plugin
     */
    loadDependencies() {
        if (this.dependencies) {
            try {
                const dependencies = JSON.parse(this.dependencies);

                return Promise.all([
                    this.dependencyLoader.loadLibraries(dependencies.libraries),
                    this.dependencyLoader.loadPlatforms(dependencies.platforms)
                ]);
            } catch (error) {
                console.error('Could not load dependencies', error);
            }
        }

        return Promise.resolve();
    }

    /**
     * @method loadPlugin
     * @description Loads the plug-in-module if the container is available
     */
    loadPlugin() {
        if (this.pluginContainer) {
            return this.dependencyLoader.loadPlugins([this.pluginName]);
        }

        return Promise.resolve();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

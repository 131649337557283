// APIs
export { default as InventoryRecentlyViewedApi } from './api/inventoryRecentlyViewedApi';

// Constants
export { default as INVENTORY_RECENTLY_VIEWED_EVENTS } from './constants/events';

// Views
export { default as InventoryRecentlyViewedBannerControl } from './views/InventoryRecentlyViewedBannerControl';

export default { };
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';
/**
 * Template for a Dealer Info Map view.
 * Uses ES6 template literal to composite the markup for a dealer notes in the DealerInfoMap module
 * @param {String} heading - Heading of Dealer Notes
 * @param {String} notes - Dealer notes
 */
export default (heading, notes) => htmlNode`
    <div class="dealer-info-map__dealer-notes-container">
        <div class="dealer-info-map__dealer-notes-heading">${heading}</div>
        <div class="dealer-info-map__dealer-notes">${notes}</div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode as html } from 'utils';

const CTA_ARIA_LABEL = window.mbVans.ns('pageData').localization.launch360;

/**
 * @description Template for 360 player
 * Uses ES6 template literal to composite the markup
 * @param {String} smallImagePath - Image used for small screen
 * @param {String} largeImagePath - Image used for large screen
 * @param {String} heading - heading text
 * @param {String} subheading - subheading text
 * @param {String} containerCSSClass
 */
export default (smallImagePath, largeImagePath, heading, subheading, { containerCSSClass = 'three-sixty-container', altText = '' } = {}) => html`
    <div class="gallery__${containerCSSClass}" >
        <button class="gallery__three-sixty-placeholder" type="button" aria-label="${CTA_ARIA_LABEL}">
            <picture>
                ${smallImagePath ? `<source media="(max-width: 767px)" srcset="${smallImagePath}" />` : ''}
                <img src="${largeImagePath}" alt="${altText}" class="gallery__three-sixty-placeholder-image"/>
            </picture>
            <div class="gallery__three-sixty-placeholder-cta">
            </div>
            <div class="gallery__info-layer">
                <div class="gallery__info-heading">${heading}</div>
                <div class="gallery__info-subheading">${subheading}</div>
            </div>
        </button>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

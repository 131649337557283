import { htmlNode } from 'utils';

/**
 * Template for a Lazy Loading Style Tag.
 * Uses ES6 template literal to composite the markup for a style tag
 */
export default ({ selector, imageSmall, imageLarge, imageXlarge, imageXxlarge }) => htmlNode `
<style>
    ${imageSmall ? `${selector} {
        background-image: url("${imageSmall}");
    }` : ''}
    ${imageLarge ? `@media screen and (min-width: 768px) {
        ${selector} {
            background-image: url("${imageLarge}");
        }
    }` : ''}
    ${imageXlarge ? `@media screen and (min-width: 980px) {
        ${selector} {
            background-image: url("${imageXlarge}");
        }
    }` : ''}
    ${imageXxlarge ? `@media screen and (min-width: 1440px) {
        ${selector} {
            background-image: url("${imageXxlarge}");
        }
    }` : ''}
</style>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

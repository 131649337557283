// Local dependencies
import AuthenticationApi from './AuthenticationApi';

/**
 * @const defaultOptions
 * @description Default fetch options
 */
const defaultOptions = {
    body: null,
    headers: {
        'Content-type': 'application/json',
        'Referrer-Policy': 'origin',
        Referer: window.location.href
    },
    method: 'POST',
    shouldLogout: true
};

/**
 * @method authenticationFetch
 * @description Fetches provided Profile PCF endpoint with required headers.
 * In case of 403 response, logout the user.
 * @param {String} url - Service endpoint
 * @param {Object} options - fetch options
 * @return {Promise}
 */
export function authenticationFetch(url, options = {}) {
    const authApi = new AuthenticationApi();
    const token = authApi.getLoginToken();
    const fetchOptions = { ...defaultOptions, ...options };

    return fetch(url, {
        headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${token}`
        },
        body: fetchOptions.body ? JSON.stringify(fetchOptions.body) : null,
        method: fetchOptions.method
    })
        .then((response) => {
            if (response.status === 403) {
                if (fetchOptions.shouldLogout) {
                    // If response is 403 logout and redirect to homepage
                    authApi.authRedirect(AuthenticationApi.AUTH_PAGES.LOGOUT, `${window.mbVans.pageData.basePath}/home`);
                }
                return response;
            } else {
                return response.json();
            }
        })
        .catch((err) => {
            console.warn(err);
            throw err;
        });
}

export default {
    authenticationFetch
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import { customEventDispatcher } from 'utils';

// Partials
import { Modal } from 'partials/modal';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {
 *     TILE_LINK: String,
 *     TRIGGER_LINK: String,
 *     TILE_IMAGE_CONTAINER: String
 * }
 */
const CLASSES = {
    TILE_LINK: 'content-tile__link',
    TRIGGER_LINK: 'content-tile__tile--button',
    TILE_IMAGE_CONTAINER: 'content-tile__image-container'
};

/**
 * @const ARIA_ATTRIBUTES
 * @description constant for the attribute used for accessibility purposes
 */
const ARIA_ATTRIBUTES = {
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_CONTROLS: 'aria-controls',
    ARIA_EXPANDED: 'aria-expanded'
};

/**
 * @const ATTRIBUTES
 * @description constant for the attribute used to find grid container element
 */
const ATTRIBUTES = {
    ID: 'id'
};

/**
 * @class ContentTileVehicleOverlay
 * @description Interactive part of the ContentTileVehicleOverlay AEM component
 */
export default class ContentTileVehicleOverlay {
    /**
     * @method constructor
     * @description Instantiates the OverlayVideoGrid Module
     * @param element {Object} DOM representation of tile
     */
    constructor(element) {
        this.element = element;

        this.modalOpenersElm = [];
        this.targetModelElm = null;

        this.init();
    }

    /**
     * @method init
     * @description  this method executed exactly after all the properties
     * have been setup by default.
     */
    init() {
        this.setBindings();
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.modalOpenersElm = this.element.querySelectorAll(`.${CLASSES.TILE_LINK}`);
        this.triggersElm = this.element.querySelectorAll(`.${CLASSES.TRIGGER_LINK}`);
    }

    /**
     * @method attachEvents
     * @description Applies click event listeners to the modal openers
     */
    attachEvents() {
        [].slice.call(this.modalOpenersElm).forEach((opener) => {
            opener.addEventListener(EVENTS.CLICK, this.onModalOpenerClick);
        });

        [].slice.call(this.triggersElm).forEach((trigger) => {
            trigger.addEventListener(EVENTS.CLICK, this.triggerCustomEvent);
        });
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onModalOpenerClick = this.onModalOpenerClick.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.triggerCustomEvent = this.triggerCustomEvent.bind(this);
    }

    onModalOpenerClick(e) {
        this.triggerModalCustomEvent(true);
        this.openModal(e);
    }

    onModalClose() {
        this.triggerModalCustomEvent(false);
    }

    /**
     * @method openModal
     * @description Open the Modal with the respective options by package
     * @param {Object} currentTarget the clicked opener Element
     */
    openModal({ currentTarget }) {
        const targetModalId = currentTarget.getAttribute(ARIA_ATTRIBUTES.ARIA_CONTROLS);
        this.targetModalElm = document.getElementById(targetModalId).cloneNode(true);
        const newModalId = `${targetModalId}-in-modal`;
        this.targetModalElm.setAttribute(ARIA_ATTRIBUTES.ARIA_HIDDEN, false);
        this.targetModalElm.setAttribute(ATTRIBUTES.ID, newModalId);

        const modal = new Modal(undefined, {
            modalContent: this.targetModalElm,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.DEFAULT,
            callbacks: {
                beforeClose: this.onModalClose
            }
        });

        modal.open({ callingContainer: currentTarget });
    }

    /**
     * @method triggerModalCustomEvent
     * @description Triggers an event so that the container can do whatever it needs to do; passes the id of
     * the image container
     */
    triggerModalCustomEvent(modalOpen) {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_TRIGGER,
                { detail: {
                    modalOpen,
                    tile: this.element.parentElement
                }
                }
            )
        );
    }

    /**
     * @method triggerCustomEvent
     * @description Triggers an event so that the container can do whatever it needs to do; passes the id of
     * the image container
     */
    triggerCustomEvent() {
        const imageContainer = this.element.querySelector(`.${CLASSES.TILE_IMAGE_CONTAINER}`);
        const imageContainerId = imageContainer.getAttribute(ATTRIBUTES.ID);
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_BUTTON_TRIGGER,
                { detail: {
                    tile: this.element.parentElement,
                    imageContainer: imageContainerId
                }
                }
            )
        );
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

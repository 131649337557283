// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for Form Error Message
 * Uses ES6 template literal to composite the markup for a Form Error Message
 */
export default ({ title = '', description = '', ctaLabel = '' }) => htmlNode`
    <div class="form-error-message" tabindex="-1" role="alert">
        <div class="form-error-message__icon">
        </div>
        <div class="form-error-message__text">
            <div class="form-error-message__title">${title}</div>
            <div class="form-error-message__description">
                ${description}
                ${ctaLabel ?
        `<button class="link link_plain-link form-error-message__cta-link">
                        ${ctaLabel}
                    </button>` :
        ''
    }
            </div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

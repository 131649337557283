
// local dependencies
import VEHICLE_CONDITIONS from '../constants/vehicleConditionTypes';

/**
 * @function parseConfigOptions
 * @description Parse config config to convert string values from default config into number
 * @param {Object} config - Config options that need to be parsed
 * @param {Number} price - Price of th vehicle
 * @returns {Object} Parsed out config options
 */
function parseConfigOptions(config, price) {
    return {
        lease: {
            annualMileage: config.leaseAnnualMileage,
            annualMileageOptions: config.leaseAnnualMileageOptions.split(','),
            downPayment: parseFloat(config.downPayment),
            leaseTerm: config.leaseTerm,
            leaseTermOptions: config.leaseTermOptions.split(','),
            maxDownPayment: parseFloat(config.minFinanceAmount),
            minFinanceAmount: price - parseFloat(config.minFinanceAmount),
            minLeaseRate: parseFloat(config.minLeaseRate),
            tradeValue: parseFloat(config.tradeValue)
        },
        finance: {
            downPayment: parseFloat(config.downPayment),
            financeTerm: config.financeTerm,
            financeTermOptions: config.financeTermOptions.split(','),
            maxDownPayment: parseFloat(config.minFinanceAmount),
            minFinanceAmount: price - parseFloat(config.minFinanceAmount),
            tradeValue: parseFloat(config.tradeValue)
        }
    };
}

/**
 * @function createSelectControlItems
 * @description Helper to create array of option items for SelectControl
 * @param type {Array} Holds the array of options values
 * @param label {String} Additional SelectControl option label text
 * @param formatValCallback {Function} Callback to format the value in the label text
 * @return {Array} Array of option values configured for SelectControl
 */
function createSelectControlItems(type, label = '', formatValCallback = (val) => val) {
    const selectControlItemArr = [];

    type.forEach((item) => {
        selectControlItemArr.push({
            label: `${formatValCallback(item)} ${label}`,
            value: item
        });
    });

    return selectControlItemArr;
}

/**
 * @method setVehicleCondition
 * @description Sets vehicle condition based on type of vehicle and certified status
 * @param type {string} type of vehicle (NEW | PRE)
 * @param certified {Boolean} Indicator of certified pre-owned status
 * @return {String} vehicle condition value (new, certified, used)
 */
function setVehicleCondition(type, certified) {
    let vehicleCondition = null;
    if (type === 'PRE') {
        vehicleCondition = certified ? VEHICLE_CONDITIONS.CERTIFIED : VEHICLE_CONDITIONS.USED;
    } else {
        vehicleCondition = VEHICLE_CONDITIONS.NEW;
    }
    return vehicleCondition;
}

export default {
    createSelectControlItems,
    parseConfigOptions,
    setVehicleCondition
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

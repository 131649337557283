// Constant dependencies
import { EVENTS } from 'Constants';

// Partials dependencies
import { FORM_CONTROL_EVENTS } from 'partials/form-control';
import { ToolTip } from 'partials/tool-tip';

// Util dependencies
import {
    customEventDispatcher,
    generateUniqueID,
    noop,
    htmlNode
} from 'utils';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 */
const ATTRIBUTES = {
    ARIA_DESCRIBEDBY: 'aria-describedby',
    ARIA_INVALID: 'aria-invalid',
    ARIA_REQUIRED: 'aria-required',
    REQUIRED: 'data-required',
    ROLE: 'role',
    ARIA_ACTIVEDESCENDANT: 'aria-activedescendant',
    ARIA_EXPANDED: 'aria-expanded',
};

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    ACTIVE_TOOL_TIP: 'tool-tip-active',
    ERROR: 'error',
    FOCUSED: 'focused',
    FORM__INPUT: 'form__input',
};

const ICON_TYPES = {
    ERROR: 'error'
};

/**
 * @const defaultLocalization
 * @description Default localization labels for InputControl
 */
const defaultLocalization = {
    blacklistErrorMessage: 'Please remove special characters: <, >, /'
};

/**
 * Abstract super class that will set up an inputs configuration and shared functionality
 */
export default class InputControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputControl
     * @type {Object}
     * @const analyticsTrigger {String} Analytic trigger tag
     * @const ariaLabel {String} Aria label text
     * @const dataAttributes {Array} Collection of data attributes for input
     * @const disablePaste {Boolean} Whether the paste is enabled or not
     * @const formatting {Array} Collection of objects with formatting type and config properties
     * @const formatting.type {String} Type of formatting to use
     * @const formatting.config {[Object]} Configuration options for formatting
     * @const formatMask {[Object]} Configuration object for masking an input
     * @const formatMask.type {String} Type of masking to use
     * @const formatMask.config {[Object]} Configuration options for masking
     * @const formId {String} Value of form id that the input is associated with
     * @const id {String} Value of input id attribute and label for attribute
     * @const name {String} Name of the input used to associate values during form submission
     * @const inputClassNames {Array} Collection of class names for input wrapper
     * @const inputMode {String} Mode for input keyboard entry type
     * @const labelClassNames {Array} Collection of class names for input label
     * @const labelIconClass {String} Class name of icon for input label
     * @const labelText {String} Default label text
     * @const maxLength {Number} specifying max character length input can take
     * @const maxValue {Number} Max value allowed for input
     * @const minLength {String} Allowed minlength for input
     * @const onBlurCallback {Function} Callback function for blur event
     * @const onChangeCallback {Function} Callback function for change event
     * @const onFocusCallback {Function} Callback function for focus event
     * @const onKeydownCallback {Function} Callback function for keydown event
     * @const onKeyupCallback {Function} Callback function for keyup event
     * @const pattern {String} Allows number keypad on mobile for input
     * @const readOnly {String} Flag to set readonly attribute to input
     * @const renderedInput {NodeElement} Existing input DOM element
     * @const required {Boolean} Whether the input is required or not
     * @const restrictions {Array} Collection of restriction rules to apply to input
     * @const tooltip {[Element]} Optional element to display in a tooltip next to the label
     * @const tooltipLabel {[String]} Optional label to display for tooltip
     * @const type {String} Type of input (text, password, etc)
     * @const validate {Boolean} Whether the input should validate on init
     * @const validateOnBlur {Boolean} Whether the input should validate on blur
     * @const validation {Array} Collection of objects with type and errorMessage properties
     * @const validation.type {String} Type of validation to use
     * @const validation.errorMessage {String} Error message to display when invalid
     * @const valueText {String} Default input value
     */
    static DEFAULT_CONFIG = {
        analyticsTrigger: '',
        ariaLabel: '',
        dataAttributes: [],
        disabled: false,
        disablePaste: false,
        focused: false,
        formatting: [],
        formatMask: null,
        formId: null,
        id: null,
        inputClassNames: [],
        inputMode: '',
        labelClassNames: [],
        labelIconClass: '',
        labelText: '',
        maxLength: '',
        maxValue: '',
        minLength: '',
        onBlurCallback: noop,
        onChangeCallback: noop,
        onFocusCallback: noop,
        onKeydownCallback: noop,
        onKeyupCallback: noop,
        pattern: '',
        placeholder: '',
        preserveBlank: false,
        readOnly: false,
        renderedInput: null,
        required: false,
        restrictions: [],
        toolTip: null,
        toolTipAnalyticsTrigger: '',
        toolTipLabel: null,
        type: '',
        validate: false,
        validateOnBlur: false,
        validation: [],
        valueText: ''
    };

    /**
     * @constructor Create an InputControl
     * @param config {Object} Configuration data
     */
    constructor(config = InputControl.DEFAULT_CONFIG) {
        this.input = null; // stores the input template element
        this.inputElement = null; // stores the input DOM element
        this.errorElement = null; // stores the error DOM element
        this.labelElement = null; // stores the label DOM element
        this.toolTip = null; // stores an instance of a ToolTip
        this.config = {
            ...InputControl.DEFAULT_CONFIG,
            ...config,
            id: config.id || generateUniqueID()
        };

        this.id = this.config.id;
        this.name = this.config.name;
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeydown = this.onKeydown.bind(this);
        this.onKeyup = this.onKeyup.bind(this);
        this.formLocalization = window.mbVans.ns('pageData', 'formLocalization') || defaultLocalization;
    }

    /**
     * @method init
     */
    init() {
        this.cacheDOM();
        this.attachEvents();

        if (this.getConfig('validate')) {
            this.validate();
        }

        if (this.getConfig('formatting').length > 0) {
            this.format();
        }

        // if a toolTip element is defined, render it
        if (this.config.toolTip && this.config.toolTip instanceof HTMLElement) {
            this.renderToolTip();
        }

        this.toggleHasValueClass();
    }

    /**
     * @method getInput
     * @description Returns this.input
     * @returns {Element} Input template element
     */
    getInput() {
        return this.input;
    }

    /**
     * @method getInputElement
     * @description Returns this.inputElement
     * @returns {Element} Input DOM element
     */
    getInputElement() {
        return this.inputElement;
    }

    /**
     * @method getLabelElement
     * @description Returns the label DOM element `this.labelElement`
     * @return {null}
     */
    getLabelElement() {
        return this.labelElement;
    }

    /**
     * @method getValue
     * @description Gets the value of `this.inputElement`.
     * @returns {String} All text inputs that inherit from this returns the `value` attribute of
     * the input.
     */
    getValue() {
        return this.getInputElement().value;
    }

    /**
     * @method getName
     * @description Gets the name of `this.inputElement`.
     * @returns {String} All text inputs that inherit from this returns the `name` attribute of
     * the input.
     */
    getName() {
        return this.name;
    }

    /**
     * @method getConfig
     * @description Returns this.config[`${property}`] if defined, or entire this.config object
     * @param property {String} Name of property to retrieve
     * @returns {((String|Boolean|Array|Function)|Object)} Either property value or config object
     */
    getConfig(property) {
        return property ? this.config[`${property}`] : this.config;
    }

    /**
     * @method setInputElement
     * @description Sets this.inputElement to value passed in
     * @param newInputElement {Element} value of new input element
     */
    setInputElement(newInputElement) {
        this.inputElement = newInputElement;
    }

    /**
     * @method createView
     * @description Create view of input based on input type
     */
    createView(inputTemplate) {
        this.input = inputTemplate;
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {}

    /**
     * @method attachEvents
     * @description Adds event listeners common to all types of InputControls
     */
    attachEvents() {
        this.inputElement.addEventListener(EVENTS.CHANGE, this.onChange);
        this.inputElement.addEventListener(EVENTS.CLICK, this.onClick);
        this.inputElement.addEventListener(EVENTS.FOCUS, this.onFocus);
        this.inputElement.addEventListener(EVENTS.BLUR, this.onBlur);
        this.inputElement.addEventListener(EVENTS.KEYDOWN, this.onKeydown);
        this.inputElement.addEventListener(EVENTS.KEYUP, this.onKeyup);
    }

    /**
     * @method detachEvents
     * @description Removes common event listeners and callback
     */
    detachEvents() {
        this.inputElement.removeEventListener(EVENTS.CHANGE, this.onChange);
        this.inputElement.removeEventListener(EVENTS.CLICK, this.onClick);
        this.inputElement.removeEventListener(EVENTS.FOCUS, this.onFocus);
        this.inputElement.removeEventListener(EVENTS.BLUR, this.onBlur);
        this.inputElement.removeEventListener(EVENTS.KEYDOWN, this.onKeydown);
        this.inputElement.removeEventListener(EVENTS.KEYUP, this.onKeyup);
    }

    /**
     * @method register
     * @description Dispatches an event to notify a form that an input should be registered
     */
    register() {
        if (this.getConfig('formId')) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.REGISTER,
                    {
                        detail: {
                            formId: this.getConfig('formId'),
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method unregister
     * @description Dispatches an event to notify a form that an input should be unregistered
     */
    unregister() {
        if (this.getConfig('formId')) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.UNREGISTER,
                    {
                        detail: {
                            formId: this.getConfig('formId'),
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method dispatchChange
     * @description Dispatches an event to notify a form that an input has changed its value
     */
    dispatchChange() {
        if (this.getConfig('formId')) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.INPUT_CHANGE,
                    {
                        detail: {
                            formId: this.getConfig('formId'),
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method onChange
     * @description Updates inputElement value and calls this.config.onChangeCallback
     * @param e {Event} Event object from change event
     */
    onChange(e) {
        this.inputElement.value = e.currentTarget.value;

        // format the value before applying the onChangeCallback
        // so that the input has the correct value that is displayed
        if (this.getConfig('formatting')) {
            this.format();
        }

        this.config.onChangeCallback(this.inputElement.value, e);
        this.dispatchChange();
    }

    onClick() {
        //
    }

    /**
     * @method onFocus
     * @description Calls this.config.onFocusCallback
     * @param e {Event} Event object from focus event
     */
    onFocus(e) {
        this.config.onFocusCallback(e);
    }

    /**
     * @method onBlur
     * @description Calls this.config.onBlurCallback
     * @param e {Event} Event object from blur event
     */
    onBlur(e) {
        if (this.getConfig('validateOnBlur')) {
            this.validate();
        }

        this.config.onBlurCallback(e, this);
    }

    /**
     * @method onKeydown
     * @description Calls this.config.onKeydownCallback
     * @param e {Event} Event object from key down event
     */
    onKeydown(e) {
        this.config.onKeydownCallback(e);
    }

    /**
     * @method onKeyup
     * @description Calls this.config.onKeyupCallback
     * @param e {Event} Event object from key up event
     */
    onKeyup(e) {
        this.config.onKeyupCallback(e);
    }

    /**
    * @method set focus
    * @description gives DOM element focus
    */
    set focus(focus = true) {
        this.inputElement[focus ? 'focus' : 'blur']();
    }

    /**
     * @method format
     * @description abstract method for formatting an input value
     * Note: this should be implemented in a subclass
     */
    format() {}

    /**
     * @method validate
     * @description abstract method for validating an input value
     * Note: this should be implemented in a subclass
     */
    validate() {}

    /**
     * @method prevalidate
     * @description prevalidates the input; however bypasses display an error message to the ui
     */
    prevalidate() {
        this.validate(false);
    }

    /**
     * @method appendIcon
     * @description Appends error icon based on the iconType param
     * @memberof InputControl
     * @param {string} iconType will give us the type of icon to append
     */
    appendIcon(iconType) {
        if (iconType === ICON_TYPES.ERROR && this.input.querySelector('.error-icon') === null) {
            const ERROR_ICON = htmlNode`
            <svg class="form__input--icon error-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12" r="12" fill="currentColor"/>
                <path d="M11.5 6.01196H13.5V13.012H11.5V6.01196Z" fill="white"/>
                <path d="M12.5 17.512C13.3284 17.512 14 16.8404 14 16.012C14 15.1835 13.3284 14.512 12.5 14.512C11.6716 14.512 11 15.1835 11 16.012C11 16.8404 11.6716 17.512 12.5 17.512Z" fill="white"/>
            </svg>
            `;
            this.input.appendChild(ERROR_ICON({ getNode: true }));
        }
    }

    /**
     * @method setErrorStatus
     * @description Toggles error class, aria-invalid and aria-describedby attributes
     * @param error {Boolean} whether there is an error
     * @param message {String} Error message to display
     */
    setErrorStatus(error, message) {
        if (error) {
            this.input.classList.add(CLASSES.ERROR);
            if (this.config.validationIconEnabled) {
                this.appendIcon(ICON_TYPES.ERROR);
            }
            this.inputElement.setAttribute(ATTRIBUTES.ARIA_INVALID, 'true');
            this.inputElement.setAttribute(ATTRIBUTES.ARIA_DESCRIBEDBY, `error${this.id}`);
            this.errorElement.setAttribute(ATTRIBUTES.ROLE, 'alert');
            this.errorElement.innerHTML = message;
        } else {
            this.input.classList.remove(CLASSES.ERROR);
            this.input.querySelector('.error-icon')?.remove();
            this.inputElement.setAttribute(ATTRIBUTES.ARIA_INVALID, 'false');
            this.inputElement.removeAttribute(ATTRIBUTES.ARIA_DESCRIBEDBY);
            this.errorElement.removeAttribute(ATTRIBUTES.ROLE);
            this.errorElement.innerHTML = '';
        }
    }

    /**
     * @method setRequired
     * @description Toggles data-required and aria-required attributes
     * @param isRequired {Boolean} whether field is required
     */
    setRequired(isRequired) {
        this.config.required = isRequired;
        if (isRequired) {
            this.inputElement.setAttribute(ATTRIBUTES.REQUIRED, '');
        } else {
            this.inputElement.removeAttribute(ATTRIBUTES.REQUIRED);
        }

        this.inputElement.setAttribute(ATTRIBUTES.ARIA_REQUIRED, `${isRequired}`);
    }

    /**
     * @method setDisabled
     * @description Sets the disabled attribute of the input element based on the disabledFlag
     * @param {Boolean} disabledFlag - Disabled Flag
     */
    setDisabled(disabledFlag) {
        this.config.disabled = disabledFlag;

        if (disabledFlag) {
            this.getInputElement().setAttribute('disabled', 'disabled');
        } else {
            this.getInputElement().removeAttribute('disabled');
        }
    }

    /**
     * @method setValidation
     * @description Sets the validation configuration
     * @param validation {Array} Collection of validation configuration objects
     */
    setValidation(validation) {
        try {
            if (Array.isArray(validation)) {
                this.config.validation = validation;
            } else {
                throw new Error('validation must be an Array');
            }
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.detachEvents();
        this.input.remove();
    }

    /**
     * @method renderToolTip
     * @description Creates and appends a ToolTip to the label element
     */
    renderToolTip() {
        if (!this.toolTip && this.getLabelElement()) {
            this.toolTip = new ToolTip(this.config.toolTip, {
                analyticsTrigger: this.config.toolTipAnalyticsTrigger,
                label: this.config.toolTipLabel,
                onOpen: this.onToolTipOpen.bind(this),
                onClose: this.onToolTipClose.bind(this)
            });
        }

        this.getLabelElement().appendChild(this.toolTip.render());
    }

    /**
     * @method onToolTipOpen
     * @description Callback method applied when a ToolTip opens to
     * add an active class to the label element
     */
    onToolTipOpen() {
        this.getLabelElement().classList.add(CLASSES.ACTIVE_TOOL_TIP);
    }

    /**
     * @method onToolTipOpen
     * @description Callback method applied when a ToolTip closes to
     * remove the active class from the label element
     */
    onToolTipClose() {
        this.getLabelElement().classList.remove(CLASSES.ACTIVE_TOOL_TIP);
    }

    /**
     * @method toggleHasValueClass
     * @description Toggles the CSS class associated with having a value
     */
    toggleHasValueClass() {}

    /**
     * @method render
     * @description Return the input
     * @returns {Element} Input template element
     */
    render() {
        return this.input;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a QuickRegistration view.
 * Uses ES6 template literal to composite the markup for a QuickRegistration view
 */
export default ({ submitButtonLabelText, content, analyticsTrigger }) => htmlNode `
    <div class="mb-quick-registration__form-container" data-analytics-container="{'blade': 'quick-registration'}">
        <div class="mbs-quick-registration__form">
            <div class="mbs-quick-registration__header">
                ${content.heading}
            </div>
            <div class="mbs-quick-registration__login-container"></div>
            
            <div class="mbs-quick-registration__input-container">
                <div class="mbs-quick-registration__first-name"></div>
            </div>
            <div class="mbs-quick-registration__input-container">
                <div class="mbs-quick-registration__last-name"/></div>
            </div>
            <div class="mbs-quick-registration__input-container">
                <div class="mbs-quick-registration__email"></div>
            </div>
             <div class="mbs-quick-registration__recaptcha-container">
                <div class="mbs-quick-registration__recaptcha">
                </div>
            </div>
            <div class="mbs-quick-registration__policy-container">
                <div class="mbs-quick-registration__policy"/></div>
            </div>
            ${content.hideEmailOptIn === 'false' ? `
            <div class="mbs-quick-registration__updates-container">
                <h2 class="mbs-quick-registration__updates-header">${content.updatesHeader}</h2>
                <div class="mbs-quick-registration__updates"/></div>
            </div>` : ''
            }
            <div class="mbs-quick-registration__submit-container">
                <button class="mbs-quick-registration__submit button button_primary button--full-width disabled disabled--visually"
                        type="submit"
                        data-analytics-trigger="${analyticsTrigger}">
                    ${submitButtonLabelText}
                </button>
            </div>
            <div id="error-message-display-info" class="mbs-quick-registration__error-message"></div>
        </div>
    </div>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

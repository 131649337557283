// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    formatNumber,
    generateUniqueID as ID,
    noop
} from 'utils';

// Local dependencies
import rangeControlTemplate from './../templates/rangeControlTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    SLIDER_CONTAINER: 'range-control__container',
    TRACK: 'range-control__track',
    TRACK_FILL: 'range-control__fill',
    HANDLE_MIN: 'range-control__handle--min',
    HANDLE_MAX: 'range-control__handle--max',
    LABEL_MIN: 'range-control__label--min',
    LABEL_MAX: 'range-control__label--max'
};

/**
 * @const defaultConfig
 * @description Default configuration options for a select control
 * @type {{
 *  country: string,
 *  currency: string,
 *  language: string,
 *  cssClass: string,
 *  formatType: string,
 min: number,
 max: number,
 *  increment: number,
 *  minDifference: number,
 *  currentMin: number,
 *  currentMax: number,
 *  onSelect: function
 * }}
 */
const defaultConfig = {
    analyticsTrigger: 'cta',
    country: 'us',
    currency: 'USD',
    language: 'en',
    cssClass: '',
    formatType: 'default',
    min: 30000,
    max: 150000,
    labelMin: null,
    labelMax: null,
    increment: 1000,
    minDifference: 5000,
    currentMin: null,
    currentMax: null,
    addPlusSignToMax: false,
    onSelect: noop,
    containerElm: document.body,
    title: ''
};

/**
 * @class RangeControl
 * @description View component for displaying a RangeControl
 */
export default class RangeControl {
    static FORMAT_TYPE = {
        DEFAULT: 'default',
        CURRENCY: 'currency',
        YEAR: 'year'
    };

    /**
     * Create a RangeControl
     * @param config {Object} Configuration data
     */
    constructor(config = defaultConfig) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.element = null;
        this.valid = true; // stores the validity of the range control
        this.slider = null;
        this.steps = [];
        this.dragging = false;
        this.currentHandle = null;
        this.values = {
            min: this.config.min,
            max: this.config.max
        };

        this.startDrag = this.startDrag.bind(this);
        this.stopDrag = this.stopDrag.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onHandleMove = this.onHandleMove.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.id = this.config.id || ID();

        if (!this.element) {
            this.createView();
        }

        this.cacheDOM();
        this.attachEvents();

        // Set min and max values if they are defined in config
        if (!!(this.config.currentMin === 0 ? true : this.config.currentMin) && !!this.config.currentMax) {
            this.values = {
                min: this.config.currentMin,
                max: this.config.currentMax
            };
        }
    }

    /**
     * @method destroy
     */
    destroy() {
        this.detachEvents();
        this.element = null;
    }

    /**
     * @method setSteps
     * @description Create steps object and updates range fill and handles
     */
    setSteps() {
        this.steps = this.getSteps(
            this.slider.offsetWidth,
            this.getNumberOfSteps(),
            this.getHandleOffset()
        );

        this.updateFillAndHandles();
    }

    /**
     * @method createView
     * @description Create view
     */
    createView() {
        this.element = rangeControlTemplate({
            id: this.id,
            cssClass: this.config.cssClass,
            labelMin: this.config.labelMin ?
                this.config.labelMin : this.getStepLabel(this.config.min),
            labelMax: this.config.labelMax ?
                this.config.labelMax : this.getStepLabel(this.config.max),
            title: this.config.title,
            analyticsTrigger: this.config.analyticsTrigger
        })({ getNode: true });
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.slider = this.element.querySelector(`.${CLASSES.SLIDER_CONTAINER}`);
        this.track = this.element.querySelector(`.${CLASSES.TRACK}`);
        this.trackFill = this.element.querySelector(`.${CLASSES.TRACK_FILL}`);
        this.handleMin = this.element.querySelector(`.${CLASSES.HANDLE_MIN}`);
        this.handleMax = this.element.querySelector(`.${CLASSES.HANDLE_MAX}`);
        this.handles = [this.handleMin, this.handleMax];
        this.labelMin = this.element.querySelector(`.${CLASSES.LABEL_MIN}`);
        this.labelMax = this.element.querySelector(`.${CLASSES.LABEL_MAX}`);
    }

    /**
     * @method attachEvents
     * @description Attaches events to drag range min and max handles, resize,
     * and keyboard
     */
    attachEvents() {
        this.handles.forEach((handle) => {
            handle.addEventListener(EVENTS.MOUSEDOWN, this.startDrag);
            handle.addEventListener(EVENTS.TOUCHSTART, this.startDrag);
        });
        window.addEventListener(EVENTS.RESIZE, this.onWindowResize);
        this.element.addEventListener(EVENTS.KEYDOWN, this.onKeyDown);
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callbacks from the view
     */
    detachEvents() {
        this.handles.forEach((handle) => {
            handle.removeEventListener(EVENTS.MOUSEDOWN, this.startDrag);
            handle.removeEventListener(EVENTS.TOUCHSTART, this.startDrag);
        });

        this.element.removeEventListener(EVENTS.KEYDOWN, this.onKeyDown);
        window.removeEventListener(EVENTS.RESIZE, this.onWindowResize);
    }

    /**
     * @method getHandleOffset
     * @description Return half width of the handle
     */
    getHandleOffset() {
        return this.handles[0].offsetWidth / 2;
    }

    /**
     * @method getTrackWidth
     * @description Return track width
     */
    getTrackWidth() {
        return this.track.offsetWidth;
    }

    /**
     * @method getFocusedHandle
     * @description Return the handle that is focused (left or right)
     */
    getFocusedHandle() {
        return this.handles.find((handle) =>
            document.activeElement === handle
        );
    }

    /**
     * @method getStepLabel
     * @description Return the formatted string for the given value
     * @param value {Number} step value
     * @return {String} formatted value
     */
    getStepLabel(value) {
        switch (this.config.formatType) {
        case RangeControl.FORMAT_TYPE.CURRENCY:
            return formatNumber.toCurrency(
                value,
                this.config.country,
                this.config.language,
                this.config.currency
            );
        case RangeControl.FORMAT_TYPE.YEAR:
            return value;
        default:
            return formatNumber.toStringNumber(
                value,
                this.config.country,
                this.config.language
            );
        }
    }

    /**
     * @method getSteps
     * @description Creates array of all track steps
     * @param sliderWidth {Number} slider width
     * @param stepsTotal {Number} Number of steps
     * @param handleOffset {Number} width of half handle
     */
    getSteps(sliderWidth, stepsTotal, handleOffset) {
        const steps = [];
        const delta = (sliderWidth - 2 * handleOffset) / stepsTotal;

        for (let i = 0; i <= stepsTotal; i += 1) {
            const stepX = i * delta + handleOffset;
            const stepPercent = (i * (95 / stepsTotal)).toFixed(2);
            const value = i * this.config.increment + this.config.min;
            let label = this.getStepLabel(value);

            if (i === stepsTotal && this.config.addPlusSignToMax) {
                label += '+';
            }

            steps.push({
                value,
                stepX,
                stepPercent,
                label
            });
        }

        return steps;
    }

    /**
     * @method getNumberOfSteps
     * @description Gets number of steps
     */
    getNumberOfSteps() {
        return (this.config.max - this.config.min) / this.config.increment;
    }

    /**
     * @method startDrag
     * @description Sets current handle (left or right)_and sets dragging as true
     * @param event {Event}
     */
    startDrag(event) {
        this.currentHandle = event.target;
        this.dragging = true;
        this.config.containerElm.addEventListener(EVENTS.MOUSEUP, this.stopDrag);
        this.config.containerElm.addEventListener(EVENTS.TOUCHEND, this.stopDrag);
        this.config.containerElm.addEventListener(EVENTS.CLICK, this.stopDrag);
        this.config.containerElm.addEventListener(EVENTS.MOUSEMOVE, this.onHandleMove);
        this.config.containerElm.addEventListener(EVENTS.TOUCHMOVE, this.onHandleMove);
    }

    /**
     * @method stopDrag
     * @description Sets flag to stop dragging
     */
    stopDrag() {
        this.dragging = false;
        this.config.onSelect(this.getValue());
        this.config.containerElm.removeEventListener(EVENTS.MOUSEUP, this.stopDrag);
        this.config.containerElm.removeEventListener(EVENTS.TOUCHEND, this.stopDrag);
        this.config.containerElm.removeEventListener(EVENTS.CLICK, this.stopDrag);
        this.config.containerElm.removeEventListener(EVENTS.MOUSEMOVE, this.onHandleMove);
        this.config.containerElm.removeEventListener(EVENTS.TOUCHMOVE, this.onHandleMove);
    }

    /**
     * @method getClosestStep
     * @description Gets closest step to X value
     * @param newX {Number} X position
     */
    getClosestStep(newX) {
        const sliderWidth = this.slider.offsetWidth;
        const stepsTotal = this.getNumberOfSteps();
        const handleOffset = this.getHandleOffset();
        const delta = (sliderWidth - 2 * handleOffset) / stepsTotal;
        const indexOfClosest = Math.round((newX - handleOffset) / delta);

        return this.steps[indexOfClosest];
    }

    /**
     * @method getStep
     * @description Gets the step for the given value
     * @param value
     */
    getStep(value) {
        return this.steps.find((step) => step.value === value);
    }

    /**
     * @method updateFill
     * @description Updates width and position of the range that should be filled
     */
    updateFill() {
        const trackWidth = this.getTrackWidth();
        const minStep = this.getStep(this.values.min);
        const maxStep = this.getStep(this.values.max);
        const newWidth =
            trackWidth - (minStep.stepX + (trackWidth - maxStep.stepX));
        const percentage = newWidth / trackWidth * 100;
        this.trackFill.style.width = `${percentage}%`;
        this.trackFill.style.left = `${minStep.stepPercent}%`;
    }

    /**
     * @method updateHandles
     * @description Updates style of handles
     */
    updateHandles() {
        this.handleMin.style.left = `${this.getStep(this.values.min).stepPercent}%`;
        this.handleMax.style.left = `${this.getStep(this.values.max).stepPercent}%`;
    }

    /**
     * @method updateLabels
     * @description Updates values of min and max labels
     */
    updateLabels() {
        const minStep = this.getStep(this.values.min);
        const maxStep = this.getStep(this.values.max);
        const minStepLabel = minStep.label === this.config.min ? this.config.labelMin : minStep.label;
        let maxStepLabel = maxStep.label === this.config.max ? this.config.labelMax : maxStep.label;
        if (maxStepLabel === this.config.min) {
            maxStepLabel = this.config.labelMin;
        }

        // set min values
        this.labelMin.innerHTML = minStepLabel;
        this.handleMin.setAttribute('aria-valuenow', minStep.value);
        this.handleMin.setAttribute('aria-valuemin', this.config.min);
        this.handleMin.setAttribute('aria-valuemax', this.config.max);
        this.handleMin.setAttribute('aria-valuetext', minStepLabel);

        // set max values
        this.labelMax.innerHTML = maxStepLabel;
        this.handleMax.setAttribute('aria-valuenow', maxStep.value);
        this.handleMax.setAttribute('aria-valuemin', this.config.min);
        this.handleMax.setAttribute('aria-valuemax', this.config.max);
        this.handleMax.setAttribute('aria-valuetext', maxStepLabel);
    }

    /**
     * @method updateMinMaxValues
     * @description This sets the min and max values
     * Required as the handles may set these incorrectly while user is interacting and crossover occurs.
     */
    updateMinMaxValues() {
        const min = Math.min(this.values.min, this.values.max);
        const max = Math.max(this.values.min, this.values.max);
        this.values.min = min;
        this.values.max = max;
    }

    /**
     * @method swapHandles
     * @description This is to handle crossovers, i.e. min becomes max and vice-versa
     * The min handle DOM node is set as the first child to ensure tabbing order stays intact.
     */
    swapHandles() {
        if (this.values.min > this.values.max || this.values.max < this.values.min) {
            // Swap the handles
            const swapHandle = this.handleMax;
            this.handleMax = this.handleMin;
            this.handleMin = swapHandle;
            // Update all the relevant attributes
            this.handleMin.setAttribute('data-handle-position', 'min');
            this.handleMin.setAttribute('aria-label', `${this.config.title} min`);
            this.handleMin.classList.remove(CLASSES.HANDLE_MAX);
            this.handleMin.classList.add(CLASSES.HANDLE_MIN);
            this.handleMax.setAttribute('data-handle-position', 'max');
            this.handleMax.setAttribute('aria-label', `${this.config.title} max`);
            this.handleMax.classList.remove(CLASSES.HANDLE_MIN);
            this.handleMax.classList.add(CLASSES.HANDLE_MAX);
            // Handle focus
            const focussedHandle = this.getFocusedHandle();
            this.handleMin.parentNode.insertBefore(this.handleMin, this.handleMax);
            focussedHandle.focus();
            // Update the values
            this.updateMinMaxValues();
        }
    }

    /**
     * @method updateFillAndHandles
     * @description Calls methods to update look of fill and handles
     * according to current values
     */
    updateFillAndHandles() {
        this.swapHandles();
        this.updateFill();
        this.updateHandles();
        this.updateLabels();
    }

    /**
     * @method onHandleMove
     * @description When a handle moves update fill and handles
     * @param event {Event}
     */
    onHandleMove(event) {
        if (!this.dragging) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        const handleOffset = this.getHandleOffset();
        const clientX = event.clientX || event.touches[0].clientX;

        window.requestAnimationFrame(() => {
            if (!this.dragging) {
                return;
            }

            const mouseX = clientX - this.slider.offsetLeft;
            const handlePosition = this.currentHandle.dataset.handlePosition;
            const newX = Math.max(
                handleOffset,
                Math.min(mouseX, this.slider.offsetWidth - handleOffset));
            const currentStep = this.getClosestStep(newX, handlePosition);

            this.values[handlePosition] = currentStep.value;
            this.updateFillAndHandles();
        });
    }

    /**
     * @method onKeyDown
     * @description On keyDown move handle
     * @param event {Event}
     */
    onKeyDown(event) {
        const keyCode = event.keyCode;
        const handle = this.getFocusedHandle();
        const keys = {
            37: 'left',
            39: 'right'
        };

        const arrowKey = keys[keyCode];

        if (!handle || !arrowKey) {
            return;
        }

        event.preventDefault();

        const handlePosition = handle.dataset.handlePosition;
        const stepIncrement = arrowKey === 'left' ? -1 : 1;
        const stepIndex = this.steps.findIndex((step) =>
            step.value === this.values[handlePosition]
        );
        const newIndex = stepIndex + stepIncrement;

        if (newIndex < 0 || newIndex >= this.steps.length) {
            return;
        }

        this.values[handlePosition] = this.steps[newIndex].value;
        this.updateFillAndHandles();
        this.config.onSelect(this.getValue());
    }

    /**
     * @method onWindowResize
     * @description On resize gets steps according to width and updates
     * fill and handles
     */
    onWindowResize() {
        this.setSteps();
    }

    /**
     * @method reset
     * @description Reset range control to its 'min' and 'max' values
     */
    reset() {
        this.values = {
            min: this.config.min,
            max: this.config.max
        };

        this.updateFillAndHandles();
    }

    /**
     * @method getValue
     * @description Gets min and max values
     * @returns {Object} Object with min and max values
     */
    getValue() {
        return {
            min: this.values.min,
            max: this.values.max
        };
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if select value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method validate
     * @description
     */
    validate() {
        this.valid = true;
    }

    /**
     * @method render
     * @description Return the element containing the custom range
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

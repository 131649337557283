import { htmlNode } from 'utils';

/**
 * @description Template for overlay video grid copy
 * Uses ES6 template literal to composite the markup for overlay video grid copy for a video
 */
export default (data = {}, content = {}, tag = {}) => htmlNode`
    <h5 class="overlay-video-grid__modal-videos-tag">${tag.title}</h5>
    <h4 class="overlay-video-grid__modal-videos-heading">
        ${data.heading}
    </h4>
    <div>
        ${data.body}
    </div>
    ${data.transcript && data.transcript.length > 0 ? `<div class="overlay-video-grid__modal-transcript">
        <button class="toggle-drawer-cta" aria-expanded="false" aria-controls="overlay-video-grid__transcript-${tag.name}-${data.video}"
                data-analytics-trigger="cta-transcript-open">
            <span data-toggle-drawer-button>${content.videoTranscript}</span>
            <span class="icon-mb icon-caret-down-thin"></span>
        </button>
        <div class="toggle-drawer" id="overlay-video-grid__transcript-${tag.name}-${data.video}" aria-hidden="true">
            ${data.transcript}
        </div>
    </div>` : ''}
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import ImagePromiseCollection from './ImagePromiseCollection';

/**
 * @class ImagePromise
 * @description Creates an image object and applies Promise-like methods
 * for on success/error, along with canceling the image request.
 *
 * Additional static methods are also available for tracking the status of
 * a group of images, and canceling a group
 */
class ImagePromise {
    /**
     * @constructor Creates an image object based on the imagePath
     * @param imagePath
     */
    constructor(imagePath) {
        this.image = new Image();
        this.isLoaded = false;

        // set image props
        this.image.crossOrigin = 'Anonymous';
        this.image.src = imagePath;
    }

    /**
     * @method success
     * @description Event handler to apply a callback when the image successfully loads
     * @param successCallback {Function} Callback to call on success
     * @returns {ImagePromise}
     */
    success(successCallback) {
        // resolve image on load
        this.image.onload = () => {
            this.isLoaded = true;

            if (typeof successCallback === 'function') {
                successCallback.call(this, this.image);
            }
        };

        return this;
    }

    /**
     * @method error
     * @description Event handler to apply a callback when the image encounters an error loading
     * @param errorCallback {Function} Callback to call on error
     * @returns {ImagePromise}
     */
    error(errorCallback) {
        // eject image on error
        this.image.onerror = () => {
            if (typeof errorCallback === 'function') {
                errorCallback.call(this, this.image);
            }
        };

        return this;
    }

    /**
     * @method cancel
     * @description Cancels the image request by emptying the source attribute
     */
    cancel() {
        if (!this.isLoaded) {
            this.image.src = '';
        }
    }

    /**
     * @static all
     * @description Creates a promise wrapper for a collection of 'imagePromises' that
     * resolves or rejects based on the images success/error
     * @param imagePromises
     * @param percentCallback
     * @returns {ImagePromiseCollection}
     */
    static all(imagePromises, percentCallback) {
        return new ImagePromiseCollection(imagePromises, percentCallback);
    }
}

export default ImagePromise;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const ICONS
 * @description Optimized paths for dealer and collision center pins
 */
export default {
    dealers: '/etc/designs/mb-vans/images/icon-mb-dealer-location_black.png',
    preferredDealer: '/etc/designs/mb-vans/images/icon-mb-dealer-location_denim.png',
    collisionCenters: '/etc/designs/mb-vans/images/icon-mb-collision-center_black.png'
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

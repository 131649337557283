import { EVENTS } from 'Constants';
import { renderer } from 'utils';

// Partials
import { ModalCarousel } from 'partials/modal-carousel';
import { TilesSections } from 'partials/tiles-sections';
import ViewMore from 'partials/view-more';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {
 *     MULTIMEDIA_TILE: String,
 *     MULTIMEDIA_TILE_ENABLED: String,
 *     TILES_CONTAINER: String,
 *     TILE_LINK: String
 * }
 */
const CLASSES = {
    OVERLAY_TILE_GRID_CONTAINER: 'overlay-tile-grid__container',
    MULTIMEDIA_TILE: 'multimedia-tile',
    MULTIMEDIA_TILE_CONTAINER: 'multimedia-tile__container',
    MULTIMEDIA_TILE_ENABLED: 'multimedia-tile--enabled',
    TILES_CONTAINER: 'overlay-tile-grid__tile-items',
    TILE_LINK: 'content-tile__link'
};

/**
 * @const defaultConfig
 * @description Default configuration options
 * @type {{initialSections: number, moreSections: number, firstSectionTiles: number}}
 */
const defaultConfig = {
    initialSections: 1,
    moreSections: 1,
    firstSectionTiles: 4
};

/**
 * @const modalDefaultConfig
 * @description Stores a the default modal configuration
 * @type {dataAnalyticContainer: string}
 */
const modalDefaultConfig = {
    dataAnalyticContainer: "{'blade': 'model overlay title grid modal'}"
};

/**
 * @const carouselDefaultConfig
 * @description Stores a the default carousel configuration
 * @type {infinite: boolean}
 */
const carouselDefaultConfig = {
    infinite: false
};

/**
 * @class  OverlayTileGrid
 * @description  class to manage the OverlayTileGrid Module
 * @param {Object} element
 */
export default class OverlayTileGrid {
    /**
     * @method constructor
     * @description Instantiates the ModelPackagesList Module
     * @param {Object} element
     */
    constructor(element = {}) {
        this.element = element;
        this.configuration = defaultConfig;
        this.modalConfig = modalDefaultConfig;
        this.setConfiguration();
        this.setModalConfiguration();
        this.modalOpenersElm = [];
        this.tilesSections = null;
        this.overlayTileGridContainer = null;
        this.openModal = this.openModal.bind(this);
        this.setTilesCallback = this.setTilesCallback.bind(this);
        this.init();
    }

    /**
     * @method init
     * @description this method executed exactly after all the properties
     * has been setup by default.
     */
    init() {
        this.cacheDOM();
        this.attachEvents();
    }

    /**
    * @method setConfiguration
    * @description this method set a configuration overwrite the default config
    */
    setConfiguration() {
        const dataInitialSectionsConfig = this.element.dataset.initialSections;
        const dataMoreSectionsConfig = this.element.dataset.moreSections;
        const dataFirstSectionTilesConfig = this.element.dataset.firstSectionTiles;

        if (dataInitialSectionsConfig) {
            this.configuration.initialSections = Number(dataInitialSectionsConfig);
        }
        if (dataMoreSectionsConfig) {
            this.configuration.moreSections = Number(dataMoreSectionsConfig);
        }
        if (dataFirstSectionTilesConfig) {
            this.configuration.firstSectionTiles = Number(dataFirstSectionTilesConfig);
        }
    }

    /**
    * @method setModalConfiguration
    * @description this method set a configuration overwrite the default config for the modal size
    */
    setModalConfiguration() {
        const dataOverlayConfig = this.element.dataset.galleryOverlay;
        if (dataOverlayConfig) {
            this.modalConfig = {
                dataAnalyticContainer: "{'blade': 'model overlay title grid modal'}",
                sizeLarge: dataOverlayConfig
            };
        }
    }
    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.modalOpenersElm = this.element.querySelectorAll(`.${CLASSES.MULTIMEDIA_TILE}`);
        this.multimedaiTileContainers = this.element.querySelectorAll(`.${CLASSES.MULTIMEDIA_TILE_CONTAINER}`);
        this.tilesContainer = this.element.querySelector(`.${CLASSES.TILES_CONTAINER}`);
        this.overlayTileGridContainer = this.element.querySelector(`.${CLASSES.OVERLAY_TILE_GRID_CONTAINER}`);

        if (this.modalOpenersElm.length > this.configuration.firstSectionTiles) {
            this.tilesSections = new TilesSections(
                this.tilesContainer,
                [...this.modalOpenersElm],
                this.configuration,
                this.setTilesCallback
            );
            this.createViewMore(this.tilesSections.getSections());
        }
    }

    /**
     * @method createViewMore
     * @description Instantiates the ViewMore component
     * @param {Object} The section elements
     */
    createViewMore(sections) {
        const { initialSections, moreSections } = this.configuration;
        this.viewMore = new ViewMore(sections, { initialSections, moreSections });
        renderer.append(this.viewMore.render(), this.tilesContainer);
    }

    /**
     * @method setTilesCallback
     * @description Callback method for when the tiles are set and you
     * need to handle the DOM.
     * @param element {Object} The section element containers
     */
    setTilesCallback(element) {
        this.modalOpenersElm = element.querySelectorAll(`.${CLASSES.MULTIMEDIA_TILE}`);
    }

    /**
     * @method attachEvents
     * @description Applies click event listeners to the modal openers
     */
    attachEvents() {
        [].slice.call(this.modalOpenersElm).forEach((opener) => {
            opener.addEventListener(EVENTS.CLICK, this.openModal);
            opener.classList.add(CLASSES.MULTIMEDIA_TILE_ENABLED);
        });
    }

    /**
     * @method detachEvents
     * @description Iterates over the modal opener elements and removes the event listener
     * for when a multimedia tile is clicked
     */
    detachEvents() {
        [].slice.call(this.modalOpenersElm).forEach((opener) => {
            opener.removeEventListener(
                EVENTS.CLICK, this.openModal
            );
        });
    }

    /**
     * @method destroy
     * @description Detaches all events and removes the element node
     */
    destroy() {
        this.detachEvents();
        this.modalOpenersElm = [];
        this.tilesSections = null;
        this.element.remove();
    }

    /**
     * @method openModal
     * @description Open the ModalCarousel with the respective options by package
     * @param {Object} evt event fired by the clicked opener
     * @param {Object} evt.currentTarget the clicked opener Element
     */
    openModal({ currentTarget }) {
        const tilesData = this.getVisibleTiles();
        const currentMultimediaTileContainer = currentTarget.querySelector(`.${CLASSES.MULTIMEDIA_TILE_CONTAINER}`);
        const startIndex = [...this.modalOpenersElm].indexOf(currentTarget);

        const config = {
            carousel: {
                startIndex,
                ...carouselDefaultConfig
            },
            modal: {
                ...this.modalConfig
            }
        };
        const modal = new ModalCarousel(tilesData, config);
        modal.open({ callingContainer: currentMultimediaTileContainer });
    }

    /**
     * @method getVisibleTiles
     * @description filter the openers based on parentElement
     * @param {String} packageName element that is going to be used as filter condition
     */
    getVisibleTiles() {
        const ctaStyle = this.overlayTileGridContainer.dataset.ctaStyle || 'link';
        const ctaClass = `${CLASSES.OVERLAY_TILE_GRID_CONTAINER}--${ctaStyle}`;

        return [].slice.call(this.multimedaiTileContainers).reduce((tiles, opener) => {
            const tileData = JSON.parse(JSON.stringify(opener.dataset));
            const contentLink = opener.querySelector(`.${CLASSES.TILE_LINK}`);

            if (tileData.disclaimers && typeof tileData.disclaimers === 'string') {
                tileData.disclaimers = JSON.parse(tileData.disclaimers);

                tileData.disclaimers = tileData.disclaimers.map((disclaimer) => ({
                    code: disclaimer.legend,
                    desc: disclaimer.disclaimerText
                }));
            }

            if (contentLink) {
                tileData.link = contentLink.outerHTML;
            }

            tileData.ctaClass = ctaClass;

            tiles.push(tileData);
            return tiles;
        }, []);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

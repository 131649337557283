/**
 * DealerLevelThreeMenu Module
 * Has methods related to composition of the header module
 */
import LevelThreeMenu from './LevelThreeMenu';
import DealerSearch from './views/DealerSearch';

export default class DealerLevelThreeMenu extends LevelThreeMenu {
    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.searchBar = new DealerSearch(this.element);
        super.cacheDOMElements();
    }

    /**
     * @method deactivate
     * @description deactivate dealer menu content
     */
    deactivateContent() {
        if (this.searchBar) {
            this.searchBar.clearInput();
        }
        super.deactivateContent();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

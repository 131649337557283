/**
 * Template for a ModalCarouselImageItemTemplate view.
 * Uses ES6 template literal to composite the markup for a ModalCarouselImageItem view
 */
export default (imageUrl, imageAlt) => `
  <picture>
      <source media="(max-width: 767px)">
      <img class="responsive-image" src="${imageUrl}" alt="${imageAlt}">
  </picture>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

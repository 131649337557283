import { renderer, tokenReplace } from 'utils';

import { Tabs } from 'partials/tabs';
import { accordionParser } from 'partials/accordion';

import StayInformedForm from './StayInformedForm';

import { recallDetailInfoContainer, recallInfoAccordion, recallTakataInfo } from './../templates/recallDetailInfoTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for classes of elements that will hold
 * the Recalls Details
 */
const CLASSES = {
    RECALL_INFO_CONTAINER: 'recalls-info__container',
    RECALL_INFO_HEADING: 'recalls-info__heading-copy',
    RECALL_INFO_TAKATA: 'recalls-info__takata-container'
};

/**
 * @const TAKATA_IDS
 * @description List of takata recall campaign IDs
 */
const TAKATA_IDS = [
    '16V081',
    '16V363',
    '17V017',
    '18V043',
    '19V010'
];

/**
 * View responsible for the recalls detailed information associated with a VIN
 */
export default class RecallDetailInfo {
    /**
     * @constructor
     * @description Constructs the RecallDetailInfo
     * @param (object} content - Content associated with the detail info
     * @param (object} config - Config associated with the detail info
     * @param {object} recallInfo - Recall info object from the API response
     */
    constructor(content, config, recallInfo) {
        this.content = content;
        this.config = config;
        this.recallInfo = recallInfo;
        this.element = recallDetailInfoContainer(content, recallInfo)({ getNode: true });

        this.cacheDOM();

        if (recallInfo.recallsAvailable) {
            this.renderTabs();
        } else {
            this.recallHeadingElm.innerHTML = content.heading.noRecalls;
        }

        const isTakataVehicle = recallInfo.recalls.some((campaign) => TAKATA_IDS.includes(campaign.nhtsaRecallNumber));

        if (isTakataVehicle) {
            this.renderTakataInfo();

            if (content.stayInformed.isEnabled) {
                this.renderTakataStayInformedForm();
            }
        }
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.recallDetailContainerElm = this.element.querySelector(`.${CLASSES.RECALL_INFO_CONTAINER}`);
        this.recallHeadingElm = this.element.querySelector(`.${CLASSES.RECALL_INFO_HEADING}`);
        this.recallTakataContainer = this.element.querySelector(`.${CLASSES.RECALL_INFO_TAKATA}`);
    }

    /**
     * @method destroy
     * @description Destroys the sub components and detaches any events
     */
    destroy() {

    }

    /**
     * @method renderTabs
     * @description Renders the tab component into the container element
     */
    renderTabs() {
        const tabOptions = this.content.tabList.headings.map((heading) => ({
            tabLabel: `${heading.label} (${this.recallInfo[heading.type].length})`,
            tabPanel: this.renderTabPanel(this.recallInfo[heading.type], heading.label, this.recallInfo.model)
        }));

        this.tabList = new Tabs(tabOptions);
        renderer.insert(this.tabList.render(), this.recallDetailContainerElm);
    }

    /**
     * @method renderTabPanel
     * @description Renders the panels under each tab
     */
    renderTabPanel(recallList, labelToken, modelCode) {
        // map model code to one of the brands (MB or FL)
        const brandCode = /^f/i.test(modelCode) ? 'fl-vans' : 'mb-vans';

        if (recallList.length) {
            return accordionParser.createAccordion({
                element: recallInfoAccordion(this.content.tabList.campaign, recallList, brandCode,
                    this.config)({ getNode: true }) }).render();
        } else {
            return renderer.fromTemplate(
                `<div class="recalls-detail__no-campaign">
                    <div class="recalls-detail__no-campaign-header">
                        ${tokenReplace(this.content.tabList.campaign.noRecallMessage, [labelToken.toLowerCase()])}
                    </div>
                    <div class="recalls-detail__no-campign-desc">
                        ${tokenReplace(this.content.tabList.campaign.noRecallDesc, [labelToken.toLowerCase()])}
                    </div>
                </div>`);
        }
    }

    /**
     * @method renderTakataInfo
     * @description Renders the takata info tout
     */
    renderTakataInfo() {
        this.takataInfoElm = recallTakataInfo(this.content, this.config)({ getNode: true });
        this.recallTakataContainer.appendChild(this.takataInfoElm);
    }

    /**
     * @method renderTakataStayInformedForm
     * @description Renders the StayInformedForm component
     */
    renderTakataStayInformedForm() {
        this.stayInformedForm = new StayInformedForm(
            this.content.stayInformed,
            this.config,
            this.recallInfo.vin,
            this.recallInfo.recalls);
        this.recallTakataContainer.appendChild(this.stayInformedForm.render());
    }

    /**
     * @method render
     * @description Returns the DOM element for RecallDetailInfo
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import {
    YouTubeTriggerElement,
    YouTubeModal,
    YouTubeModalContent
} from 'partials/youtube';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{MODAL_TRIGGER: string}}
 */
const CLASSES = {
    MODAL_TRIGGER: 'youtube-trigger'
};

class MultiMediaYouTube {
    constructor(element) {
        this.element = element;
        this.videoId = this.element.dataset.videoId;
        this.videoTranscript = this.element.dataset.videoTranscript;
        this.youTubeTrigger = null;
        this.youTubeModal = null;
        this.init();
    }

    /**
     * @method init
     * @description initialize the component
     */
    init() {
        this.setBindings();
        this.cacheDOM();
        if (this.modalTriggerElem) {
            this.youTubeTrigger = new YouTubeTriggerElement(
                this.modalTriggerElem,
                this.videoId,
                this.onModalTriggerClick
            );
        }
        this.youTubeModalContent = new YouTubeModalContent({
            videoId: this.videoId,
            videoTranscript: this.videoTranscript
        });
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onModalTriggerClick = this.onModalTriggerClick.bind(this);
        this.onBeforeModalClose = this.onBeforeModalClose.bind(this);
    }

    /**
     * @method attachEvents
     * @description adds event listeners
     */
    cacheDOM() {
        this.modalTriggerElem = this.element.querySelector(`.${CLASSES.MODAL_TRIGGER}`);
    }

    /**
     * @method onBeforeModalClose
     * @description Set focus back to triggered element when modal close
     */
    onBeforeModalClose() {
        this.youTubeModalContent.beforeYouTubeModalClose();
        this.youTubeTrigger.focus();
    }

    /**
     * @method onModalTriggerClick
     * @description Click event handler for modal trigger button element
     */
    onModalTriggerClick() {
        this.youTubeModal = new YouTubeModal(
            this.videoId,
            this.youTubeModalContent.createYouTubeModalElement(),
            {
                afterModalOpen: this.youTubeModalContent.initialize,
                beforeModalClose: this.onBeforeModalClose
            }
        );
    }
}

export default MultiMediaYouTube;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

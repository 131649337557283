import { FormControl, FormError } from 'partials/form-control';
import DOMPurify from 'dompurify';
import { InputTextControl } from 'partials/input-control';
import { ReCAPTCHA } from 'partials/recaptcha';
import { renderer, tokenReplace } from 'utils';
import { ERROR_STATUS, getWarranty, searchRecalls } from './../api/recallsAPI';
import recallsVINTemplate from './../templates/recallsVINTemplate';

/**
 * @const FORM_ID
 * @description Unique Id for the FormControl and Inputs
 * @type {string}
 */
const FORM_ID = 'recallsVIN';

/**
 * @const CLASSES
 * @description The defined classes to cache the dom
 * @type {Object}
 */
const CLASSES = {
    VIN_INPUT: 'recalls-vin__input',
    FORM: 'recalls-vin__form',
    FORM_ERROR: 'recalls-vin__error',
    DISCLAIMER: 'recalls-vin__disclaimer',
};

export default class RecallsVINView {
    constructor(content, endpoint, onSubmitCallback, vinInput = '', pageType) {
        this.content = content;
        this.endpoint = endpoint;
        this.onSubmitCallback = onSubmitCallback;
        this.vinInput = vinInput;

        this.element = recallsVINTemplate(
            content.vin,
            pageType
        )({ getNode: true });
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);

        this.cacheDOM();
        this.renderInputs();
        this.createForm();
        if (pageType !== 'warranty') {
            this.renderUpdatedDate();
        }
        this.pageType = pageType;
    }

    validateDOMNode(node) {
        if (
            node instanceof Node &&
            node.tagName !== 'SCRIPT' &&
            (node instanceof HTMLElement || node instanceof SVGElement)
        ) {
            return node;
        }
        return null;
    }

    validateString(input) {
        if (typeof input === 'string') {
            return input;
        }
        return null;
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.vinInputElm = this.element.querySelector(`.${CLASSES.VIN_INPUT}`);
        this.formContainer = this.element.querySelector(`.${CLASSES.FORM}`);
        this.serverErrorElm = this.element.querySelector(
            `.${CLASSES.FORM_ERROR}`
        );
        this.disclaimerElm = this.element.querySelector(
            `.${CLASSES.DISCLAIMER}`
        );
    }

    /**
     * @method createForm
     * @description Instantiates FormControl responsible for managing state of form
     */
    createForm() {
        const formConfig = {
            element: this.validateDOMNode(this.formContainer),
            id: DOMPurify.sanitize(FORM_ID),
            onSubmit: this.onSubmit,
        };
        // Sanitize user-controlled input (formConfig) using DOMPurify
        // const sanitizedFormConfig = DOMPurify.sanitize(formConfig);
        this.form = new FormControl(formConfig);
        this.form.register(this.inputs.vin);
        this.form.register(this.inputs.recaptcha);
        this.element.appendChild(this.validateDOMNode(this.form.render()));
    }

    /**
     * @method renderInputs
     * @description Renders the vin input and recaptcha
     */
    renderInputs() {
        this.inputs = {
            vin: new InputTextControl({
                type: 'text',
                id: 'vin',
                formId: FORM_ID,
                required: true,
                labelText: this.content.vin.label,
                maxLength: 17,
                minLength: 17,
                valueText: this.vinInput,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: this.content.vin.error,
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MIN_LENGTH,
                        errorMessage: this.content.vin.error,
                    },
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.ALPHA_NUMERIC],
            }),
            recaptcha: new ReCAPTCHA({
                formId: FORM_ID,
                errorMessage: this.content.vin.recaptchaError,
            }),
        };

        renderer.append(this.inputs.vin.render(), this.vinInputElm);
        renderer.append(this.inputs.recaptcha.render(), this.vinInputElm);
    }

    /**
     * @method onSubmitError
     * @description Renders the disclaimer with the correct date based on current date
     */
    renderUpdatedDate() {
        const yesterday = new Date();
        yesterday.setDate(new Date().getDate() - 1);
        const disclaimerDate = tokenReplace(this.content.vin.disclaimer, [
            yesterday.toDateString().substring(3, 10),
            yesterday.toDateString().substring(11),
        ]);
        this.disclaimerElm.innerHTML = this.validateString(disclaimerDate);
    }

    /**
     * @method onSubmitError
     * @description Callback for when there is a service error when VIN is submitted
     */
    onSubmitError(error) {
        console.log(error);
        if (ERROR_STATUS.VIN_ERROR === error) {
            this.serverError = new FormError({
                title: this.content.vin.serverErrorTitle,
                description: this.content.vin.vinErrorDesc,
            });
        } else {
            this.serverError = new FormError({
                title: this.content.vin.serverErrorTitle,
                description: this.content.vin.serverErrorDesc,
            });
        }

        renderer.insert(this.serverError.render(), this.serverErrorElm);
    }

    /**
     * @method onSubmit
     * @description Callback for when the user submits their VIN
     */
    onSubmit() {
        const data = {
            vin: this.inputs.vin.getValue(),
            recaptchaResponse: this.inputs.recaptcha.getValue(),
        };

        if (this.serverError) {
            this.serverErrorElm.innerHTML = '';
        }

        const recallsResponse = {};

        searchRecalls(data.vin, data.recaptchaResponse, this.endpoint.vinSearch)
            .then((response) => {
                recallsResponse.recallsDetail = response;
            })
            .then(() => {
                if (this.pageType === 'warranty') {
                    return getWarranty(
                        recallsResponse.recallsDetail.result.page.year,
                        recallsResponse.recallsDetail.result.page.model,
                        this.endpoint.warrantyBooklet
                    );
                } else {
                    return {};
                }
            })
            .then((response) => {
                recallsResponse.warrantyDetail = response;
                recallsResponse.vin = data.vin;
                return recallsResponse;
            })
            .then(this.onSubmitCallback)
            .catch(this.onSubmitError)
            .finally(() => {
                this.inputs.recaptcha.reset();
            });
    }

    /**
     * @method render
     * @description Renders the vin input view
     * @return {XML}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

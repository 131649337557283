/**
 * DockingHeader Module
 * Has methods related to composition of the header module
 */
import { CUSTOM_EVENTS, EVENTS, DELAY } from 'Constants';
import { customEventDispatcher, screen, throttle } from 'utils';
import SpecialityHeader from './SpecialityHeader';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    GLOBAL_HEADER_LOCKED: 'global-header--locked',
    TOP_NAV_LOGO: 'top-bar__logo',
    TOP_NAV_SKIP_NAV: 'top-bar__skip-nav',
    GLOBAL_HEADER_SHOW_NAV_BTN: 'global-header__show-nav-btn',
    GLOBAL_HEADER_SHOW_NAV_BTN_LOCKED: 'global-header__show-nav-btn--locked',
    GLOBAL_HEADER_SHOW_NAV_HIDDEN: 'global-header__show-nav--hidden',
    TOP_BAR_HAMBURGER_BUTTON: 'top-bar__hamburger-button',
    GLOBAL_HEADER_MENU_L1_INNER: 'global-header__menu-l1__inner',
    GLOBAL_HEADER_MENU_L3: 'global-header__menu-l3 ',
    GLOBAL_HEADER_MENU_L3_OPEN: 'global-header__menu-l3--open',
    SPECIALTY_PAGE: 'specialty-page',
    EXTERNALLY_LOCKED: 'externally-locked'
};

/**
 * @const ATTRIBUTES
 * @description Stores a collection of attributes for use in the DOM
 * @type {{TABINDEX: string, ARIA_EXPANDED: string, ARIA_HIDDEN: string}}
 */
const ATTRIBUTES = {
    TABINDEX: 'tabindex',
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_HIDDEN: 'aria-hidden'
};

/**
 * @const LOCAL_CONSTANTS
 * @type {{MENU_HEIGHT: number}}
 */
const LOCAL_CONSTANTS = {
    MENU_HEIGHT: 60
};

export default class DockingHeader extends SpecialityHeader {
    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onSpecialtyPageHashUpdate = this.onSpecialtyPageHashUpdate.bind(this);
        this.onDockedHeaderFocus = this.onDockedHeaderFocus.bind(this);
        this.onShowNavClick = this.onShowNavClick.bind(this);
        this.onHideNavClick = this.onHideNavClick.bind(this);
        this.onSkipNavClick = this.onSkipNavClick.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseMoveThrottle = throttle(this.onMouseMove, DELAY.DELAY_300MS);
        super.setBindings();
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.hamburgerBtnElm = this.element.querySelector(`.${CLASSES.TOP_BAR_HAMBURGER_BUTTON}`);
        this.showHideNavBtnElm = document.querySelector(`.${CLASSES.GLOBAL_HEADER_SHOW_NAV_BTN}`);
        this.skipNavBtnElm = this.element.querySelector(`.${CLASSES.TOP_NAV_SKIP_NAV}`);
        this.topNavLogoElm = this.element.querySelector(`.${CLASSES.TOP_NAV_LOGO}`);
        this.levelThreeMenu = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L3}`);
        super.cacheDOMElements();
    }

    /**
     * @method attachEvents
     * @description Attaches event listener
     */
    attachEvents() {
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.SPECIALTY_PAGE_HASH_UPDATED,
            this.onSpecialtyPageHashUpdate);
        super.attachEvents();
    }

    /**
     * @method activate
     * @description handler that attaches event listeners when the docking header is active
     */
    activate() {
        this.showHideNavBtnElm.addEventListener(EVENTS.CLICK, this.onShowNavClick);
        this.element.addEventListener(EVENTS.MOUSEENTER, this.onMouseEnter);
        this.element.addEventListener(EVENTS.MOUSELEAVE, this.onMouseLeave);
        window.addEventListener(EVENTS.MOUSEMOVE, this.onMouseMoveThrottle);
        screen.addResizeListener(this.onScreenResize.bind(this));
    }

    /**
     * @method deactivate
     * @description handler that deataches event listeners when the docking header is no longer active
     */
    deactivate() {
        this.hamburgerBtnElm.removeEventListener(EVENTS.FOCUS, this.onDockedHeaderFocus);
        this.showHideNavBtnElm.removeEventListener(EVENTS.CLICK, this.onShowNavClick);
        this.skipNavBtnElm.removeEventListener(EVENTS.CLICK, this.onSkipNavClick);
        this.element.removeEventListener(EVENTS.MOUSEENTER, this.onMouseEnter);
        this.element.removeEventListener(EVENTS.MOUSELEAVE, this.onMouseLeave);
        window.removeEventListener(EVENTS.MOUSEMOVE, this.onMouseMoveThrottle);
        screen.removeResizeListener(this.onScreenResize.bind(this));
    }

    /**
     * @method onSpecialtyPageHashUpdate
     * @description handler for the SpecialtyPageHashUpdate
     * @param detail {Object} detail of the SpecialtyPageHashUpdate event
     */
    onSpecialtyPageHashUpdate({ detail }) {
        const { isLocked, isXLargePlus, isDockingActive } = this.getState();
        if (!isDockingActive) {
            this.activate();
            this.updateState({ isDockingActive: true });
        }

        if (this.hamburgerBtnElm.getAttribute(ATTRIBUTES.ARIA_EXPANDED) === 'true') {
            this.hamburgerBtnElm.click();
        }
        this.currentChapterIndex = detail.nextChapterData.index;
        const isChapterOne = this.currentChapterIndex === 0;


        if (!isLocked || !isXLargePlus) {
            this.setDockingState(!isChapterOne);
        }

        if (isChapterOne && isXLargePlus && !isLocked) {
            setTimeout(() => {
                if (!this.getState().isVisible) {
                    this.setDockingState(isChapterOne);
                }
            }, DELAY.DELAY_2500MS);
        }
    }

    /**
     * @method onDockedHeaderFocus
     * @description handler for when docked menu is focused on for S/L
     */
    onDockedHeaderFocus() {
        if (!this.getState().isXLargePlus) {
            this.setDockingState(false);
        }
    }

    /**
     * @method onMouseMove
     * @description handler to un-dock the header when mouse moves to the top of the page
     * @param clientY
     */
    onMouseMove({ clientY }) {
        const { isLocked, isXLargePlus } = this.getState();
        const isExternallyLocked = this.element.classList.contains(CLASSES.EXTERNALLY_LOCKED);
        if (!isLocked && !isExternallyLocked && isXLargePlus) {
            if (clientY <= LOCAL_CONSTANTS.MENU_HEIGHT) {
                this.updateState({ isVisible: true });
                this.updateHeaderState(true);
            } else if (!this.levelThreeMenu.classList.contains(CLASSES.GLOBAL_HEADER_MENU_L3_OPEN)) {
                this.onMouseLeave();
            }
        }
    }

    /**
     * @method onMouseLeave
     * @description handler for when the mouse leaves the XL+ nav
     *              resets and docks the menu
     */
    onMouseLeave() {
        this.updateState({ isVisible: false });
        setTimeout(() => {
            const { isXLargePlus, isLocked, isVisible } = this.getState();
            if (isXLargePlus && !isLocked && !isVisible) {
                this.updateHeaderState(false);
            }
        }, DELAY.DELAY_750MS);
    }

    /**
     * @method onMouseEnter
     * @description handler for when the mouse enters the XL+ nav
     *              if the menu isn't locked, set the state to visible
     */
    onMouseEnter() {
        const { isLocked, isXLargePlus } = this.getState();
        if (!isLocked && isXLargePlus) {
            this.updateState({ isVisible: true });
        }
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        const isXLargePlus = screen.gte(screen.SIZES.XLARGE);

        if (this.currentChapterIndex && isXLargePlus && !this.getState().isLocked) {
            this.updateHeaderState(this.currentChapterIndex !== 0);
        } else if (!isXLargePlus) {
            // S/L screens are always unlocked and have no show nav button
            this.setLockedState(false);
            this.updateShowHideNavButton(false);
            this.setDockingState(this.currentChapterIndex !== 0);
        }

        super.onScreenResize();
    }

    /**
     * @method onShowNavClick
     * @description handles the state for when show nav is clicked on
     */
    onShowNavClick() {
        this.updateState({ isVisible: false });
        this.enterLockedState();
    }

    /**
     * @method onHideNavClick
     * @description click handler for 'Hide Navigation' button
     */
    onHideNavClick() {
        this.leaveLockedState();
    }

    /**
     * @method onSkipNavClick
     * @description click handler for 'Skip Navigation' link
     */
    onSkipNavClick() {
        this.leaveLockedState();
    }

    /**
     * @method enterLockedState
     * @description handles entering locked state
     */
    enterLockedState() {
        this.setLockedState(true);
        this.updateHeaderState(true);
        this.updateEventListeners(false);
        this.showHideNavBtnElm.removeEventListener(EVENTS.CLICK, this.onShowNavClick);
        this.showHideNavBtnElm.addEventListener(EVENTS.CLICK, this.onHideNavClick);
        this.topNavLogoElm.focus();
    }

    /**
     * @method leaveLockedState
     * @description handles exiting locked state
     */
    leaveLockedState() {
        this.setLockedState(false);
        this.updateHeaderState(false);
        this.updateEventListeners(true);
        this.showHideNavBtnElm.removeEventListener(EVENTS.CLICK, this.onHideNavClick);
        this.showHideNavBtnElm.addEventListener(EVENTS.CLICK, this.onShowNavClick);
    }

    /**
     * @method updateEventListeners
     * @description helper method to add/remove event listeners depending on header state
     * @param addEvents {Boolean} whether to add (true) or remove (false) event listeners
     */
    updateEventListeners(addEvents) {
        const listenerAction = addEvents ? 'addEventListener' : 'removeEventListener';
        this.element[listenerAction](EVENTS.MOUSELEAVE, this.onMouseLeave);
        this.element[listenerAction](EVENTS.MOUSEENTER, this.onMouseEnter);
        window[listenerAction](EVENTS.MOUSEMOVE, this.onMouseMoveThrottle);
    }

    /**
     * @method updateHeaderState
     * @description helper function that toggles the Headers's docking, locked, and show nav button states
     * @param dockingState {Boolean} docking state of the header
     */
    updateHeaderState(dockingState) {
        this.updateShowHideNavButton(dockingState);
        this.setDockingState(!dockingState);
    }

    /**
     * @method setLockedState
     * @description toggles locked class on the header + updates the state
     * @param isLocked {Boolean}
     */
    setLockedState(isLocked) {
        this.updateState({ isLocked });
        this.element.classList[isLocked ? 'add' : 'remove'](CLASSES.GLOBAL_HEADER_LOCKED);
    }

    /**
     * @method updateShowHideNavButton
     * @description toggles the
     * @param isLocked {Boolean}
     */
    updateShowHideNavButton(isLocked) {
        const { isVisible, isXLargePlus } = this.getState();
        const lockNav = isLocked && !isVisible && isXLargePlus;
        const hideButton = isVisible || !isXLargePlus;

        this.showHideNavBtnElm.classList[hideButton ? 'add' : 'remove'](CLASSES.GLOBAL_HEADER_SHOW_NAV_HIDDEN);
        this.showHideNavBtnElm.classList[lockNav ? 'add' : 'remove'](CLASSES.GLOBAL_HEADER_SHOW_NAV_BTN_LOCKED);
        this.showHideNavBtnElm.innerText = this.localization[lockNav ? 'hideNav' : 'showNav'];
    }

    /**
     * @method destroy
     */
    destroy() {
        this.deactivate();
        super.destroy();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultKeyboardConfig
 * @description Default configuration options passed to simple-keyboard constructor
 * @type {Object}
 */
export const defaultKeyboardConfig = {
    theme: 'hg-theme-default virtual-keyboard__simple-theme',
    buttonTheme: [
        {
            class: 'virtual-keyboard__variable-button-theme',
            buttons: '{tab}'
        }
    ],
    /**
     * to use {caps} or {shift} you must have a
     * "default" and "shift" layout
     *
     * to use {numbers} you must have a "numbers" layout
     * to use {symbols} you must have a "symbols" layout
     * {abc} reverts to your "default" layout
     */
    layout: {
        default: [
            '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            '{tab} q w e r t y u i o p [ ] \\',
            '{lock} a s d f g h j k l ; \' {enter}',
            '{shift} z x c v b n m , . / {shift}',
            '.com @ {space}'
        ],
        shift: [
            '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
            '{tab} Q W E R T Y U I O P { } |',
            '{lock} A S D F G H J K L : " {enter}',
            '{shift} Z X C V B N M < > ? {shift}',
            '.com @ {space}'
        ]
    },
    /**
     * Virtual Keyboard has the following options
     * available for use --> string values can be overridden:
     *
     * '{bksp}': 'backspace',
     * '{enter}': '< enter',
     * '{shift}': 'shift',
     * '{SHIFT}': 'SHIFT',
     * '{s}': 'shift',
     * '{tab}': 'tab',
     * '{lock}': 'caps',
     * '{LOCK}': 'CAPS',
     * '{numbers}': '.?123',
     * '{symbols}': '#+=',
     * '{abc}': 'ABC',
     * '{accept}': 'submit'
     * '{space}': ' ',
     * '{//}': ' '
     */
    display: {
        '{bksp}': 'backspace',
        '{enter}': '< enter',
        '{shift}': 'shift',
        '{s}': 'shift',
        '{tab}': 'tab',
        '{lock}': 'caps',
        '{space}': ' ',
        '{//}': ' '
    },
    availableLayouts: {
        DEFAULT: 'default',
        SHIFT: 'shift'
    },
};

export default { defaultKeyboardConfig };
// do not delete 9fbef606107a605d69c0edbcd8029e5d

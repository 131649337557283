// Constant dependencies
import { EVENTS } from 'Constants';

// Utility dependencies
import { noop } from 'utils';

class YouTubeTriggerElement {
    constructor(element, videoId, clickCallback = noop) {
        this.element = element;
        this.videoId = videoId;
        this.clickCallback = clickCallback;
        this.init();
    }

    /**
     * @method init
     * @description initialize the component
     */
    init() {
        this.setBindings();
        this.attachEvents();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onClick = this.onClick.bind(this);
    }

    /**
     * @method attachEvents
     * @description adds event listeners
     */
    attachEvents() {
        if (this.element) {
            this.element.addEventListener(EVENTS.CLICK, this.onClick);
        }
    }

    /**
     * @method detachEvents
     * @description removes event listeners
     */
    detachEvents() {
        if (this.element) {
            this.element.removeEventListener(EVENTS.CLICK, this.onClick);
        }
    }

    /**
     * @method onClick
     * @description Click event handler for modal trigger button element
     */
    onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.clickCallback(this.element, this.videoId);
    }

    focus() {
        this.element.focus();
    }

    destroy() {
        this.detachEvents();
    }
}

export default YouTubeTriggerElement;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for tool tip
 * Uses ES6 template literal to composite the markup for a input label tool tip
 */
export default (title, description) => htmlNode`
    <div class="payment-estimator-tool-tip">
        <p class="payment-estimator-tool-tip__title">
            ${title}
            </p>
        <p class="payment-estimator-tool-tip__description">
            ${description}
        </p>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

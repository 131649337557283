/**
 * @description payment type values for payment driver forms
 * @type {{
 *  FINANCE: string,
 *  LEASE: string
 * }}
 */

export default {
    FINANCE: 'finance',
    LEASE: 'lease'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/* eslint max-len: ["error", { "code": 120, "ignoreComments": true }] */

// Library dependencies
import {
    isAlpha,
    isAlphaNumeric,
    isCharacterPresent,
    isEmail,
    isEmpty,
    isFormattedPhoneNumber,
    isInDateRange,
    isMatchValue,
    isMaxLength,
    isMaxValue,
    isMinLength,
    isMinValue,
    isPhoneAllSameCharacter,
    isPhoneNumber,
    isValidAge,
    isValidDate,
    isValidFileSize,
    isValidFileType,
    isValidCASSN,
    isValidSSN,
    isValidZip
} from '@mbusa/now-ui-utils.validate';
import formatNumber from '@mbusa/now-ui-utils.format-number';

/**
 * @module inputValidator
 * @description API for validating inputs
 */

/**
 * @const VALIDATION_TYPE
 * @description Collection of constant values for validation types
 * @memberof inputValidator
 * @type {Object}
 * @type {string} VALIDATION_TYPE.ACCEPTED_FILE_TYPES='acceptedFileTypes' String identifier for accepted file types validation
 * @type {string} VALIDATION_TYPE.AGE_RESTRICTION='ageRestriction' String identifier for age restriction validation
 * @type {string} VALIDATION_TYPE.ALPHA='alpha' String identifier for alpha validation
 * @type {string} VALIDATION_TYPE.ALPHA_NUMERIC='alphaNumeric' String identifier for alpha numeric validation
 * @type {string} VALIDATION_TYPE.CHARACTER_PRESENT='characterPresent' String identifier for character present validation
 * @type {string} VALIDATION_TYPE.CUSTOM='custom' String identifier for custom validation
 * @type {string} VALIDATION_TYPE.DATE='date' String identifier for date validation
 * @type {string} VALIDATION_TYPE.DATE_RANGE='dateRange' String identifier for date range validation
 * @type {string} VALIDATION_TYPE.EMAIL='email' String identifier for email validation
 * @type {string} VALIDATION_TYPE.MATCH='match' String identifier for match validation
 * @type {string} VALIDATION_TYPE.MAX_FILE_SIZE='maxFileSize' String identifier for max file size validation
 * @type {string} VALIDATION_TYPE.MAX_LENGTH='maxLength' String identifier for max length validation
 * @type {string} VALIDATION_TYPE.MAX_VALUE='maxValue' String identifier for max value validation
 * @type {string} VALIDATION_TYPE.MAX_VALUE_FORMATTED='maxValueFormatted' String identifier for
 * formatted max value validation
 * @type {string} VALIDATION_TYPE.MIN_LENGTH='minLength' String identifier for min length validation
 * @type {string} VALIDATION_TYPE.MIN_VALUE='minValue' String identifier for min value validation
 * @type {string} VALIDATION_TYPE.MIN_VALUE_FORMATTED='maxValueFormatted' String identifier for formatted
 * min value validation
 * @type {string} VALIDATION_TYPE.NUMBER_FORMATTED='numberFormatted' String identifier for formatted number validation
 * @type {string} VALIDATION_TYPE.PHONE='phone' String identifier for phone number validation
 * @type {string} VALIDATION_TYPE.PHONE_ALL_SAME_CHARACTER='phoneAllSameCharacter' String identifier for phone numbers of
 * all the same character validation
 * @type {string} VALIDATION_TYPE.PHONE_FORMATTED='phoneFormatted' String identifier for formatted phone number validation
 * @type {string} VALIDATION_TYPE.REQUIRED='required' String identifier for required field validation
 * @type {string} VALIDATION_TYPE.SSN='ssn' String identifier for Social Security Number validation
 * @type {string} VALIDATION_TYPE.CASSN='cassn' String identifier for Canada Social Security Number validation
 * @type {string} VALIDATION_TYPE.ZIP_CODE='zipcode' String identifier for zip code validation
 */
export const VALIDATION_TYPE = {
    ACCEPTED_FILE_TYPES: 'acceptedFileTypes',
    AGE_RESTRICTION: 'ageRestriction',
    ALPHA: 'alpha',
    ALPHA_NUMERIC: 'alphaNumeric',
    CHARACTER_PRESENT: 'characterPresent',
    CUSTOM: 'custom',
    DATE: 'date',
    DATE_RANGE: 'dateRange',
    EMAIL: 'email',
    MATCH: 'match',
    MAX_FILE_SIZE: 'maxFileSize',
    MAX_LENGTH: 'maxLength',
    MAX_VALUE: 'maxValue',
    MAX_VALUE_FORMATTED: 'maxValueFormatted',
    MIN_LENGTH: 'minLength',
    MIN_VALUE: 'minValue',
    MIN_VALUE_FORMATTED: 'minValueFormatted',
    NUMBER_FORMATTED: 'numberFormatted',
    PHONE: 'phone',
    PHONE_ALL_SAME_CHARACTER: 'phoneAllSameCharacter',
    PHONE_FORMATTED: 'phoneFormatted',
    REQUIRED: 'required',
    SSN: 'ssn',
    CASSN: 'cassn',
    ZIP_CODE: 'zipcode'
};

/**
 * @const VALIDATION_EVENT
 * @description Collection of constant values for custom validation events
 * @memberof inputValidator
 * @type {Object}
 * @type {string} VALIDATION_EVENT.MATCH='input-validator-match' String Identifier for the match events
 */
export const VALIDATION_EVENT = {
    MATCH: 'input-validator-match'
};

/**
 * @method verify
 * @description Get value of restricted characters for validation type
 * @memberof inputValidator
 * @param {string} value Value of input
 * @param {string} validationType Validation type
 * @param {Object} inputConfig list of minLength and maxLength for element
 * @param {Object} validationConfig - Config for validation
 * @returns {boolean} True if value passes validation
 */
export function verify(value, validationType, inputConfig, validationConfig) {
    let pass = true;

    if (validationType === VALIDATION_TYPE.CUSTOM) {
        if (typeof validationConfig.callback === 'function') {
            pass = validationConfig.callback(value);
        } else {
            console.error('No callback function available to validate');
        }
    } else if (validationType === VALIDATION_TYPE.EMAIL) {
        pass = isEmail(value);
    } else if (validationType === VALIDATION_TYPE.MAX_LENGTH && inputConfig.maxLength) {
        pass = isMaxLength(inputConfig.maxLength, value);
    } else if (validationType === VALIDATION_TYPE.MAX_VALUE) {
        pass = isMaxValue(inputConfig.maxValue, value);
    } else if (validationType === VALIDATION_TYPE.MAX_VALUE_FORMATTED) {
        const parsedInputValue = formatNumber.toNumber(value, validationConfig.country, validationConfig.language);
        const isValueNumber = !isNaN(parsedInputValue);
        pass = !value ? true : isValueNumber && isMaxValue(
            inputConfig.maxValue, formatNumber.toNumber(value, validationConfig.country, validationConfig.language)
        );
    } else if (validationType === VALIDATION_TYPE.MIN_LENGTH && inputConfig.minLength) {
        pass = isMinLength(inputConfig.minLength, value);
    } else if (validationType === VALIDATION_TYPE.MIN_VALUE) {
        pass = isMinValue(inputConfig.minValue, value);
    } else if (validationType === VALIDATION_TYPE.MIN_VALUE_FORMATTED) {
        pass = !value ? true : isMinValue(
            inputConfig.minValue, formatNumber.toNumber(value, validationConfig.country, validationConfig.language)
        );
    } else if (validationType === VALIDATION_TYPE.PHONE) {
        pass = isPhoneNumber(value);
    } else if (validationType === VALIDATION_TYPE.PHONE_FORMATTED) {
        pass = !isEmpty(value) ? isFormattedPhoneNumber(value) : true;
    } else if (validationType === VALIDATION_TYPE.REQUIRED) {
        pass = !isEmpty(value);
    } else if (validationType === VALIDATION_TYPE.ZIP_CODE) {
        pass = isValidZip(value);
    } else if (validationType === VALIDATION_TYPE.DATE) {
        pass = !value ? true : isValidDate(value);
    } else if (validationType === VALIDATION_TYPE.DATE_RANGE) {
        pass = !value ? true : isInDateRange(value, validationConfig);
    } else if (validationType === VALIDATION_TYPE.MATCH) {
        pass = !isEmpty(value) && !isEmpty(validationConfig.compareValue) ?
            isMatchValue(validationConfig.compareValue, value) :
            true;
    } else if (validationType === VALIDATION_TYPE.SSN) {
        pass = isValidSSN(value);
    } else if (validationType === VALIDATION_TYPE.CASSN) {
        pass = isValidCASSN(value);
    } else if (validationType === VALIDATION_TYPE.AGE_RESTRICTION) {
        pass = isValidAge(value, validationConfig.ageRestriction);
    } else if (validationType === VALIDATION_TYPE.ALPHA) {
        pass = isAlpha(value);
    } else if (validationType === VALIDATION_TYPE.ALPHA_NUMERIC) {
        pass = isAlphaNumeric(value);
    } else if (validationType === VALIDATION_TYPE.ACCEPTED_FILE_TYPES) {
        pass = isValidFileType(value, inputConfig.acceptedFileTypes);
    } else if (validationType === VALIDATION_TYPE.MAX_FILE_SIZE) {
        pass = isValidFileSize(value, validationConfig.maxFileSize);
    } else if (validationType === VALIDATION_TYPE.PHONE_ALL_SAME_CHARACTER) {
        pass = !isPhoneAllSameCharacter(value);
    } else if (validationType === VALIDATION_TYPE.CHARACTER_PRESENT) {
        pass = !isCharacterPresent(validationConfig.character, value);
    } else if (validationType === VALIDATION_TYPE.NUMBER_FORMATTED) {
        const parsedInputValue = formatNumber.toNumber(value, validationConfig.country, validationConfig.language);
        pass = !isNaN(parsedInputValue);
    }

    return pass;
}

/**
 * export inputValidator public api
 */
export default {
    VALIDATION_EVENT,
    VALIDATION_TYPE,
    verify
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

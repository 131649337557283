// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for tool tip
 * Uses ES6 template literal to composite the markup for a input label tool tip
 */
export default (heading, list = []) => htmlNode`
    <div class="payment-estimator-tool-tip">
        <p class="payment-estimator-tool-tip__heading">
            ${heading}
        </p>
        <ul class="payment-estimator-tool-tip__list">
        ${
            list.map((item) => `
                <li class="payment-estimator-tool-tip__list-item">
                    <p class="payment-estimator-tool-tip__title">
                        ${item.title}
                    </p>
                    <p class="payment-estimator-tool-tip__description">
                        ${item.description}
                    </p>
                <li>
            `).join('')
        }
        <ul>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

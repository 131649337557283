import { accordionParser } from 'partials/accordion';

export default class FAQModule {
    constructor(element) {
        this.element = element;
        this.init();
    }

    /**
     * Transforms the DOM nodes into the one required by accordion
     */
    init() {
        console.log('FAQModule loaded');
        this.accordionElement = this.element.querySelector('[data-accordion]');
        this.accordion = accordionParser.createAccordion({ element: this.accordionElement });
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

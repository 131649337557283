import { EVENTS } from 'Constants';

import appConfig from './../config';

import dealerDetailTemplate from './../templates/dealerDetailTemplate';

/**
 * @const CLASSES
 * @type {{BACK_TO_RESULTS: string}}
 */
const CLASSES = {
    BACK_TO_RESULTS: 'dealer-detail__back-cta',
    PREFERRED_CTA: 'dealer-detail__preferred-cta',
    IS_PREFERRED: 'dealer-detail--is-preferred'
};

/**
 * Detailed view of the dealer with more information
 */
export default class DealerDetail {
    /**
     * @constructor
     * @description Constructs the DealerDetail view
     * @param {Object} dealer - Dealer object
     * @param {Object} content - Content object
     * @param {Object} config - Configuration object
     * @param {Function} backToResultsCallback - Callback for Back To Results CTA
     * @param {Function} preferredDealerCallback - Callback for Preferred dealer toggle
     */
    constructor(dealer, content, config, backToResultsCallback, preferredDealerCallback) {
        this.dealer = dealer;
        this.isPreferred = dealer.isPreferred;
        this.config = config;
        this.content = content;
        this.backToResultsCallback = backToResultsCallback;
        this.preferredDealerCallback = preferredDealerCallback;

        this.element = dealerDetailTemplate(
            dealer,
            content,
            this.getMainCTALink(),
            this.getSecondaryCTALink(),
            this.config.showDealerUrl
        )({ getNode: true });

        this.togglePreferred = this.togglePreferred.bind(this);

        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method destroy
     * @description Removes the events and DOM
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM element
     */
    cacheDOM() {
        this.backToResultsElm = this.element.querySelector(`.${CLASSES.BACK_TO_RESULTS}`);
        this.preferredDealerCTAElm = this.element.querySelector(`.${CLASSES.PREFERRED_CTA}`);
    }

    /**
     * @method attachEvents
     * @description Attaches events to DOM elements for Dealer Detail
     */
    attachEvents() {
        this.backToResultsElm.addEventListener(EVENTS.CLICK, this.backToResultsCallback);
        if (this.preferredDealerCTAElm) {
            this.preferredDealerCTAElm.addEventListener(EVENTS.CLICK, this.togglePreferred);
        }
    }

    /**
     * @method detachEvents
     * @description Detaches events from DOM elements of this view
     */
    detachEvents() {
        this.backToResultsElm.removeEventListener(EVENTS.CLICK, this.backToResultsCallback);
    }

    /**
     * @method getMainCTALink
     * @description Gets the Main CTA URL
     * @returns {Object} An object with text & href as properties.
     * For schedule service get the schedule service URL, if dealer has inventory, use inventory URL.
     * By default use dealer website.
     */
    getMainCTALink() {
        const linkObject = {
            text: this.content.viewWebsite,
            href: this.dealer.url
        };

        if (this.config.useScheduleServiceURL) {
            linkObject.href = this.dealer.scheduleServiceUrl !== 'NO_SCHEDULE_SERVICE_URL' ? this.dealer.scheduleServiceUrl : null;
            linkObject.text = this.content.dealerMainCTA;
        } else if (this.dealer.hasInventory) {
            linkObject.href = this.dealer.inventoryUrl;
            linkObject.text = this.content.dealerMainCTA;
        }

        return linkObject;
    }

    /**
     * @method togglePreferred
     * @description Calls the preferred dealer callback
     */
    togglePreferred() {
        this.preferredDealerCallback(!this.isPreferred, this.dealer);
    }

    /**
     * @method setPreferred
     * @description Sets the preferred dealer flag, and adds/removes CSS class
     * @param {Boolean} isPreferred - Preferred dealer flag
     */
    setPreferred(isPreferred) {
        this.isPreferred = isPreferred;
        this.element.classList[isPreferred ? 'add' : 'remove'](CLASSES.IS_PREFERRED);
    }

    /**
     * @method getSecondaryCTALink
     * @description Gets the secondary CTA URL
     * @returns {String} For schedule service return no URL, otherwise return Schedule Service URL
     */
    getSecondaryCTALink() {
        if (this.config.useScheduleServiceURL) {
            return null;
        }

        const {
            leads: {
                cta: {
                    sourceType,
                    uri,
                    text,
                } = {}
            } = {}
        } = appConfig;

        const {
            dealerId
        } = this.dealer;

        const scheduleTestDriveUrl = uri;
        const scheduleTestDriveUrlHash = [
            `dealerId=${dealerId}`,
            `src=${sourceType}`,
        ];

        return { href: `${scheduleTestDriveUrl}?${scheduleTestDriveUrlHash.join('&')}`,
            text };
    }

    /**
     * @method setFocusBackToResults
     * @description Set focus on first element of details
     */
    setFocusBackToResults() {
        this.backToResultsElm.focus();
    }

    /**
     * @method render
     * @description Returns the DOM Element associated with the view
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a ModalCarouselHtml5VideoItem view.
 * Uses ES6 template literal to composite the markup for a ModalCarouselHtml5VideoItem view
 */
export default (html5video, imgLarge) => `
  <video class="multimedia-asset__video" poster="${imgLarge}"
         autoplay="" loop="" role="presentation" playsinline="" muted="">
      <source src="${html5video}" type="video/mp4">
  </video>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

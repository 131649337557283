/**
 * Template for a Dealer Locator Search Types view.
 * Uses ES6 template literal to composite the markup for a DealerSearchTypes
 */
import { htmlNode } from 'utils';
import config from 'apps/dealer-locator/config';
import dealerTemplate from './dealerTemplate';

export default (dealer, content) => htmlNode`
    <div class="preferred-dealer">
        <h4 class="preferred-dealer__heading">${content.yourPreferredDealer}<span class="icon-mb icon-preferred-dealer dealer-info__preferred-dealer-icon"></span></h4>
        ${dealerTemplate(dealer, content, 0, true, config.variant)({ getNode: false })}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { html } from 'utils';
import { getClassNames } from './menuSelectorTemplate';
/**
 * @description Template for a menu selector item of type radio
 */
export default ({ items = [], defaultSelection, group, inputClasses, labelClasses, legend, theme, a11yTip }) => `
    <fieldset>
        <legend class="${getClassNames(theme, '__legend')}">${legend}</legend>
        ${a11yTip ? `<p class="${getClassNames(theme, '__a11y-tip')} offscreen">${a11yTip}</p>` : ''}
        <div id="menu-selector-list-${group}" class="${getClassNames(theme, '__item-list')}">
            ${items.map((item, index) => html`
                <div class="${getClassNames(theme, '__item')}
                    ${index === defaultSelection ? `${getClassNames(theme, '__item--selected')}` : ''}">
                    <input
                        id="menu-selector-radio-${group}-${index}"
                        class="${inputClasses}"
                        type="radio"
                        name="${group}"
                        value="${index}"
                        ${index === defaultSelection ? ' checked' : ''}
                    />
                    <label
                        class="${labelClasses}"
                        for="menu-selector-radio-${group}-${index}"
                        data-value="${item.value}"
                        data-index=${index}
                        data-analytics-trigger="${item.trigger}">
                            ${item.label}
                    </label>
                </div>
            `).join('')}
        </div>
    </fieldset>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    scrollTo,
    debounce,
    screen,
    Touch
} from 'utils';

// Local dependencies
import slideDirections from './../constants/slideDirections';
import sliderTemplate from './../templates/sliderTemplate';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    PREV_BUTTON: 'prevButton',
    NEXT_BUTTON: 'nextButton',
    CONTAINER: 'slider__list-container',
    DISABLED_BUTTON: 'slider__button--disabled',
    ENABLED_PREV: 'slider__button--prev',
    ENABLED_NEXT: 'slider__button--next',
    SLIDER_ITEM: 'slider__item',
    SLIDER_ITEM_ACTIVE: 'slider__item--active'
};

/**
 * @const SLIDER_TYPE
 * @description Detemines slider movement
 */
export const SLIDER_TYPE = {
    SCROLL: 'scroll',
    STEP: 'step',
    ENDLESS: 'endless'
};

export default class Slider {
     /**
     * @constructor
     * @description Creates new Slider
     * @param {Array} sliderItems Array of HTML elements representing the badges
     * @param {Object} content Localization object
     * @param {Boolean} disabledLarge Check to disable slider in certain windows
     * @param {String} optionalClass Optional class attribute for modifying the element
     * @param {String} type step (default) or scroll, determine slider movement @see Slider.SLIDER_TYPE
     * @param {Boolean} buttonHideSmall Toggles button visibility on small breakpoint
     */
    constructor(
        sliderItems,
        content,
        disabledLarge = false,
        optionalClass = '',
        type = SLIDER_TYPE.STEP,
        buttonHideSmall = false
    ) {
        this.content = content;
        this.disabledLarge = disabledLarge;
        this.optionalClass = optionalClass;
        this.type = type;
        this.buttonHideSmall = buttonHideSmall;

        this.element = sliderTemplate(
            content,
            {
                buttonHideSmall,
                disabledLarge,
                optionalClass
            }
        )({ getNode: true });
        this.init(sliderItems);
    }

    /**
     * @method init
     * @description initializes Slider instance
     */
    init(sliderItems) {
        // Slider state
        this.position = 0;
        this.currentPage = 1;
        this.totalPages = sliderItems.length;

        this.cacheDOM();
        this.swipeContainer = new Touch(this.element);
        this.setBindings();
        this.attachEvents();
        this.renderSliderItems(sliderItems);
        this.scrollToPage(this.currentPage);
        this.setButtonsStatus();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.setButtonsStatus = this.setButtonsStatus.bind(this);
        this.setPrevButton = this.setPrevButton.bind(this);
        this.setNextButton = this.setNextButton.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.scrollToPage = this.scrollToPage.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollDebounce = debounce(this.onScroll, 25, false).bind(this);
        this.onResize = this.onResize.bind(this);
        this.slideLeft = this.setPosition.bind(this, slideDirections.LEFT);
        this.slideRight = this.setPosition.bind(this, slideDirections.RIGHT);
        this.onSwipeRight = this.onSwipeRight.bind(this);
        this.onSwipeLeft = this.onSwipeLeft.bind(this);
        this.scrollNavigation = this.scrollNavigation.bind(this);
    }

    /**
     * @method destroy
     * @description Destroys the instance. This should also remove any event listeners
     */
    destroy() {
        this.element.remove();
        this.detachEvents();
    }

     /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.prevButtonElm = this.element.querySelector(`.${CLASSES.PREV_BUTTON}`);
        this.nextButtonElm = this.element.querySelector(`.${CLASSES.NEXT_BUTTON}`);
        this.sliderList = this.element.querySelector(`.${CLASSES.CONTAINER}`);
    }

    /**
     * @method renderSliderItems
     * @description Renders the badges of the slider and appends them as children
     * @param sliderItems {Array} Array of HTML elements representing the badges
     */
    renderSliderItems(sliderItems) {
        sliderItems.forEach((item) => {
            item.classList.add(CLASSES.SLIDER_ITEM);
            this.sliderList.appendChild(item);
        });
    }

    /**
     * @method setButtonsStatus
     * @description Calls to set prev/next buttons enabled or disabled
     */
    setButtonsStatus() {
        this.setPrevButton();
        this.setNextButton();
    }

    /**
     * @method setPrevButton
     * @description Determines if the slider has previous items to show
     * and if not disables the previous button
     */
    setPrevButton() {
        if (this.position <= 0) {
            this.position = 0;
            this.prevButtonElm.classList.add(CLASSES.DISABLED_BUTTON);
            this.prevButtonElm.classList.remove(CLASSES.ENABLED_PREV);
        } else {
            this.prevButtonElm.classList.remove(CLASSES.DISABLED_BUTTON);
            this.prevButtonElm.classList.add(CLASSES.ENABLED_PREV);
        }
    }

    /**
     * @method setNextButton
     * @description Determines if the slider can go further right and if not
     * disables the next button
     */
    setNextButton() {
        let nextDisabled = false;
        const visibleWidth = this.sliderList.offsetWidth;
        const scrollLeft = this.sliderList.scrollLeft;
        // Subtract one from the scrollWidth to resolve issue with fractional pixel values
        const scrollWidth = this.sliderList.scrollWidth - 1;
        const showNextButton = (scrollLeft + visibleWidth) >= scrollWidth;

        switch (this.type) {
        case SLIDER_TYPE.STEP:
            nextDisabled = this.currentPage >= this.totalPages || showNextButton;
            break;

        case SLIDER_TYPE.SCROLL:
            nextDisabled = showNextButton;
            break;

        default:
            nextDisabled = false;
            break;
        }

        if (nextDisabled) {
            this.nextButtonElm.classList.add(CLASSES.DISABLED_BUTTON);
            this.nextButtonElm.classList.remove(CLASSES.ENABLED_NEXT);
        } else {
            this.nextButtonElm.classList.remove(CLASSES.DISABLED_BUTTON);
            this.nextButtonElm.classList.add(CLASSES.ENABLED_NEXT);
        }
    }

    /**
     * @method attachEvents
     * @description Attaches scroll and window resize events and callback to the sliderList
     */
    attachEvents() {
        this.prevButtonElm.addEventListener(EVENTS.CLICK, this.slideLeft);
        this.nextButtonElm.addEventListener(EVENTS.CLICK, this.slideRight);
        this.sliderList.addEventListener(EVENTS.SCROLL, this.onScrollDebounce);
        this.swipeContainer.on(Touch.EVENTS.SWIPE_RIGHT, this.onSwipeRight);
        this.swipeContainer.on(Touch.EVENTS.SWIPE_LEFT, this.onSwipeLeft);
        window.addEventListener(EVENTS.RESIZE, this.onResize);
        this.mutationObserver = new window.MutationObserver(this.setButtonsStatus);
        this.mutationObserver.observe(this.sliderList, {
            childList: true
        });
    }

    /**
     * @method detachEvents
     * @description Removes scroll and window resize events and callback from the sliderList
     */
    detachEvents() {
        this.prevButtonElm.removeEventListener(EVENTS.CLICK, this.slideLeft);
        this.nextButtonElm.removeEventListener(EVENTS.CLICK, this.slideRight);
        this.sliderList.removeEventListener(EVENTS.SCROLL, this.onScrollDebounce);
        this.swipeContainer.off(Touch.EVENTS.SWIPE_RIGHT, this.onSwipeRight);
        this.swipeContainer.off(Touch.EVENTS.SWIPE_LEFT, this.onSwipeLeft);
        window.removeEventListener(EVENTS.RESIZE, this.onResize);
        this.mutationObserver.disconnect();
        this.mutationObserver = null;
    }

    /**
     * @method setPosition
     * @description Sets the left position of the slider
     * @param direction {String} 'left' or 'right' direction value
     */
    setPosition(direction) {
        let buttonWidth = 0;
        let visibleWidthRight = 0;
        const visibleWidth = this.sliderList.offsetWidth;

        if (direction === slideDirections.LEFT) {
            buttonWidth = this.prevButtonElm.offsetWidth;
            visibleWidthRight = this.position + buttonWidth - visibleWidth;
            // Find the first item that has its left edge to the right of the prev button
            [].slice.call(this.sliderList.children).some((item) => {
                const left = item.offsetLeft;
                switch (this.type) {
                case SLIDER_TYPE.STEP:
                    if (this.currentPage > 1) {
                        this.scrollToPage(this.currentPage - 1);
                        return true;
                    }
                    break;

                case SLIDER_TYPE.SCROLL:
                    if (left >= visibleWidthRight) {
                        // Account for button width when scrolling so that item is lined up to button
                        // If scrolled too far left, set position to 0
                        this.position = visibleWidthRight >= 0 ? (left - buttonWidth) : 0;
                        this.scrollToPosition();
                        return true;
                    }
                    break;

                default:
                    return false;
                }
                return false;
            });
        } else {
            buttonWidth = this.nextButtonElm.offsetWidth;
            visibleWidthRight = this.position + buttonWidth + visibleWidth;
            // Find the first item that has its left edge within scroll width
            // and right edge outside of scrollwidth
            [].slice.call(this.sliderList.children).some((item) => {
                const left = item.offsetLeft;
                const right = left + item.offsetWidth;
                switch (this.type) {
                case SLIDER_TYPE.ENDLESS:
                    if (this.totalPages > 1) {
                        this.scrollToPage(2);
                        return true;
                    }
                    break;

                case SLIDER_TYPE.STEP:
                    if (this.currentPage < this.totalPages) {
                        this.scrollToPage(this.currentPage + 1);
                        return true;
                    }
                    break;

                case SLIDER_TYPE.SCROLL:
                    if (right >= visibleWidthRight) {
                        // Account for button width when scrolling so that item is lined up to button
                        this.position = left - buttonWidth;
                        this.scrollToPosition();
                        return true;
                    }
                    break;

                default:
                    return false;
                }
                return false;
            });
        }
    }

    /**
     * @method scrollToPosition
     * @description Scrolls slider horizontally to this.position
     */
    scrollToPosition() {
        return scrollTo(this.position, 500, 'horizontal', this.sliderList);
    }

    /**
     * @method onSwipeRight
     * @description handler for swipe right
     */
    onSwipeRight() {
        if (this.type === SLIDER_TYPE.STEP || this.type === SLIDER_TYPE.ENDLESS) {
            this.slideRight();
        }
    }

    /**
     * @method onSwipeLeft
     * @description handler for swipe left
     */
    onSwipeLeft() {
        if (this.type === SLIDER_TYPE.STEP) {
            this.slideLeft();
        }
    }

    /**
     * @method onScroll
     * @description On scroll reapplies current state.
     */
    onScroll() {
        if (this.type === SLIDER_TYPE.SCROLL) {
            this.position = this.sliderList.scrollLeft;
        }
        if (this.sliderList.children[0].classList.contains('hero-image-tile__card') && !this.sliderList.className === 'scroll_triggered') {
            this.sliderList.querySelectorAll('hero-image-tile__card').forEach((el) => {
                el.classList.remove('slider__item--active');
            });
            this.sliderList.children[0].classList.add('slider__item--active');
        }
        this.setButtonsStatus();
    }

    /**
     * @method onResize
     * @description Handle window resize
     */
    onResize() {
        switch (this.type) {
        case SLIDER_TYPE.ENDLESS:
            if (this.totalPages > 1) {
                this.scrollToPage(2);
            }
            break;

        case SLIDER_TYPE.STEP:
            this.scrollToPage(this.currentPage);
            break;

        case SLIDER_TYPE.SCROLL:
            this.position = this.sliderList.scrollLeft;
            this.scrollToPosition();
            break;

        default:
            break;
        }
        this.setButtonsStatus();
    }

    scrollNavigation(index, listSelector) {
        // location.hash = `#${index}_slide`;
        this.sliderList.classList.add('scroll_triggered');
        const currentElm = this.sliderList.children[index];
        this.sliderList.querySelectorAll(`.${listSelector}`).forEach((el) => {
            el.classList.remove(CLASSES.SLIDER_ITEM_ACTIVE);
        });
        currentElm.classList.add(CLASSES.SLIDER_ITEM_ACTIVE);
        this.sliderList.scrollLeft = '100%';
        this.sliderList.classList.remove('scroll_triggered');
    }
    /**
     * @method scrollToPage
     * @description Scroll page into view
     */
    scrollToPage(page) {
        this.currentPage = page;
        let peek = 0;
        const slideElm = this.sliderList.children[this.currentPage - 1];
        if (slideElm) {
            if (!screen.gte(screen.SIZES.LARGE)) {
                const peekWidth = (this.sliderList.offsetWidth - slideElm.offsetWidth) / 2;
                peek = this.currentPage > 1 || this.currentPage < this.totalPages ? peekWidth : 0;
            }
            this.position = slideElm.offsetLeft - peek;
            if (this.currentSlideElm) {
                this.currentSlideElm.classList.remove(CLASSES.SLIDER_ITEM_ACTIVE);
            }
            slideElm.classList.add(CLASSES.SLIDER_ITEM_ACTIVE);
            this.currentSlideElm = slideElm;
        }
        this.scrollToPosition().then(() => {
            if (this.type === SLIDER_TYPE.ENDLESS) {
                // Shift first slide to the end
                if (this.currentPage > 1) {
                    this.sliderList.appendChild(this.sliderList.removeChild(this.sliderList.firstChild));
                }
                this.sliderList.scrollLeft = 0;
            }
        });
    }

    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

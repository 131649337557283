import { htmlNode as html } from 'utils';

export const dealerSelectorSearchContent = ({ content = {} }) => html`
    <div class="dealer-selector-dealer-search__content">
        <div class="dealer-selector-dealer-search__list-container">
            <div class="dealer-selector-list">
                <div class="dealer-selector-list__header">
                    <p class="dealer-selector-list__header-label" data-dealer-results-desc></p>
                    <div class="dealer-selector-list__cta">
                        <button class="link link_plain-link" data-analytics-trigger="cta" data-change-location-cta>${content.changeLocation}</button>
                    </div>
                </div>
                <div class="dealer-selector-list__results-list" data-analytics-container="dealer-list" data-results-list></div>
            </div>
        </div>
        <div class="dealer-selector-dealer-search__map" data-dealer-list-map></div>
    </div>
`;

export const dealerSelectorSearchContainer = ({ content = {} }) => html`
    <div class="dealer-selector-dealer-search">
        <h1 class="dealer-selector__title">${content.dealerSelectorFindTitle}</h1>
        <p class="dealer-selector__desc">${content.selectDealer}</p>
        <div data-dealer-search-content></div>
    </div>
`;

export default {
    dealerSelectorSearchContent,
    dealerSelectorSearchContainer
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

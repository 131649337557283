import { htmlNode } from 'utils';

/**
 * Template for a QuickRegistration view.
 * Uses ES6 template literal to composite the markup for a QuickRegistration view
 */
export default () => htmlNode `
    <div class="mbs-quick-registration">
        <div class="mbs-quick-registration__content">
        </div>
    </div>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

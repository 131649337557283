const CONSTANTS = {
    APP_ID: 'byo',
    COUNTRY: {
        US: 'us',
        CA: 'ca'
    },
    CLASS: {
        METRIS: 'METRIS'
    },
    FORMATCLASS: {
        SPRINTER: 'Sprinter',
        ESPRINTER: 'eSprinter'
    },
    CAT_FEES: 'fees',
    ENGINE_ID: 'byoBuild',
    PERSONALIZATION: {
        PROVIDERS: {
            TARGET: 'target'
        }
    },
    THEMEABLE_COMPONENTS: {
        BUILD_STEPS: 'BuildSteps',
        BUILD_SUMMARY: 'BuildSummary'
    },
    SINGLE_SELECT_GROUPS: {
        PAINT: 'PAINT',
        UPHOLSTERY: 'UPHOLSTERY',
        UPFIT: 'UPFIT',
    },
    SINGLE_SELECT_GROUPS_UPFIT: {
        ACCESSORIES: 'Accessories',
        PARTITIONS: 'Partitions',
        SHELVING: 'Shelving',
        REFRIGERATION: 'Refrigeration'
    },
    SINGLE_SELECT_UPFIT_CONSTRAINT_TYPES: {
        GROUP: 'GROUP',
        VENDOR: 'VENDOR',
        BUCKET: 'BUCKET'
    },
    KEY_CODES: {
        KEYCODE_TAB: 9,
    },
    KEYS: {
        TAB: 'Tab',
    },
    KEYBOARD_EVENTS: {
        KEYDOWN: 'keydown'
    },
    INITIAL_CONGIGURATION_ERROR: 'Error in getting initial configuration-id. Response from CCS API:'
};

export default {
    ...CONSTANTS
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * SpecialityHeader Module
 * Has methods related to composition of the header module
 */
import { screen } from 'utils';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    GLOBAL_HEADER_DOCKING: 'global-header--docking-state',
    GLOBAL_HEADER_MENU_L1_BUTTON: 'global-header__menu-l1-list-button',
};

/**
 * @const ATTRIBUTES
 * @description Stores a collection of attributes for use in the DOM
 * @type {{TABINDEX: string, ARIA_EXPANDED: string, ARIA_HIDDEN: string}}
 */
const ATTRIBUTES = {
    TABINDEX: 'tabindex',
    ARIA_HIDDEN: 'aria-hidden'
};

/**
 * @const defaultLocalization
 * @description Default localization labels for DockedHeader
 * @type {{hideNav: string, showNav: string, goToNav: string}}
 */
const defaultLocalization = {
    showNav: 'Show Navigation',
    goToNav: 'Go To Navigation',
    hideNav: 'Hide Navigation'
};

export default class SpecialityHeader {
    constructor(element, closeMenusCallback) {
        this.element = element;
        this.localization = Object.assign(defaultLocalization, window.mbVans.ns('pageData').localization);
        this.closeMenusCallback = closeMenusCallback;
        this.init();
        this.afterInit();
    }

    init() {
        this.setBindings();
        this.cacheDOMElements();
        this.attachEvents();
        this.state = {
            isDockingActive: false,
            isLocked: false,
            isVisible: false,
            isXLargePlus: screen.gte(screen.SIZES.XLARGE),
            lastScrollPosition: 0
        };
    }

    /**
     * @method afterInit
     * @description to called after init method
     */
    afterInit() {

    }

    /**
     * @method attachEvents
     * @description Attaches event listener
     */
    attachEvents() {

    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.setDockingState = this.setDockingState.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.menuL1ButtonElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L1_BUTTON}`)];
    }

    /**
     * @method setDockingState
     * @description toggles docking state of the header
     * @param dockingState {Boolean} docking state of the header
     */
    setDockingState(dockingState) {
        if (dockingState) {
            this.closeMenusCallback();
        }

        this.element.classList[dockingState ? 'add' : 'remove'](CLASSES.GLOBAL_HEADER_DOCKING);
        const ariaHidden = this.getState().isXLargePlus ? dockingState : false;
        this.element.setAttribute(ATTRIBUTES.ARIA_HIDDEN, ariaHidden);
        this.menuL1ButtonElms.forEach((accessibleButton) => (
            ariaHidden ? accessibleButton.setAttribute(ATTRIBUTES.TABINDEX, '-1') : accessibleButton.removeAttribute(ATTRIBUTES.TABINDEX)
        ));
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        this.updateState({ isXLargePlus: screen.gte(screen.SIZES.XLARGE) });
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {object} new state value
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return this.state;
    }

    /**
     * @method destroy
     */
    destroy() {
        this.updateState({ isDockingActive: false });
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { serializeObject as serialize } from 'utils';

/**
 * @method successfulUpdate
 * @description  verify if the status after the update
 * is 'OK' and create the respective cookies, if not return false
 * @param  {Object} data
 * @return {Object}
 */
function successfulUpdate(data) {
    if (data.status.code === 200) {
        return {
            successful: data.status.ok
        };
    }

    return {
        successful: false
    };
}

/**
 * @method subscriptionsList
 * @description verify if the status after the request is ok and return
 * subscriptions list, if not return false
 * @param  {Object} data
 * @return {Object}
 */
function subscriptionsList(data) {
    if (data.status.code === 200) {
        return {
            successful: data.status.ok,
            subscriptions: data.result && data.result.subscriptions,
            unsubscribedUser: data.result && data.result.emailAddress,
            emailAddress: data.result && data.result.emailAddress,
            subscriberId: data.result && data.result.subscriberId,
            language_en: data.result && data.result.language_en === 'True',
            interestedModel: data.result && data.result.interestedModel,
            startDate: data.result.startDate,
        };
    }

    return {
        successful: false
    };
}

/**
 * @method submitUpdate
 * @description  fetch the data to the endpoint and verify
 * is was successful if not throw and error
 * @param  {Object} emailData
 * @param  {Object} data
 * @return {Promise}
 */
export function submitUpdate(emailData, data, config) {
    const urlParams = serialize({
        country: config.country,
        subscriberId: emailData.subId,
        emailId: emailData.emailId,
        interestedModel: data.interestedModel,
        site: config.site
    }, true);
    const url = `${config.endpoints.updateSubscriptionsURI}&${urlParams}`;

    return fetch(url, {
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST'
    })
    .then((response) => response.json())
    .then(successfulUpdate)
    .then((response) => {
        if (!response.successful) {
            throw Error('Subscriptions Update Not Successful');
        }
    });
}

/**
 * @method submitUnsubscribe
 * @description  fetch the data to the endpoint and verify
 * is was successful if not throw and error
 * @param  {String} endpoint
 * @param  {Object} data
 * @return {Promise}
 */
export function submitUnsubscribe(data, config) {
    const urlParams = serialize({
        country: config.country,
        subscriberId: data.subId,
        emailId: data.emailId,
        site: config.site
    }, true);
    const url = `${config.endpoints.unsubscribeURI}&${urlParams}`;

    return fetch(url, {
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST'
    })
    .then((response) => response.json())
    .then(successfulUpdate)
    .then((response) => {
        if (!response.successful) {
            throw Error('Unsubscribe Not Successful');
        }
    });
}

/**
 * @method getSubscriptionsList
 * @description Gets the list of email subscriptions for a given email ID and/or subscriptionID
 * @param  {Object} data
 * @param  {String} data.emailId - Email address for which subscription should be fetched
 * @param  {String} data.subId - Subscription ID for which subscription should be fetched
 * @param  {Object} config
 * @param  {Object} config.endpoints - Endpoints object
 * @param  {String} config.endpoints.getSubscriptionsURI - Endpoint for getting subscriptions
 * @return {Promise} A promise which resolves after the subscriptions have been fetched and parsed
 */
export function getSubscriptionsList(data, config) {
    const urlParams = serialize({
        country: config.country,
        subscriberId: data.subId,
        emailId: data.emailId,
        site: config.site
    }, true);
    const url = `${config.endpoints.getSubscriptionsURI}&${urlParams}`;

    return fetch(url, {
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(subscriptionsList)
        .then((response) => {
            if (!response.successful) {
                throw Error('Get Subscriptions Not Successful');
            }

            return response;
        });
}

/**
 * @method verifyEmail
 * @description Gets the list of email subscriptions for a given email ID and/or subscriptionID
 * @param  {Object} emailData
 * @param  {string[]} defaultSubscriptions The user will be subscribed to these lists by default
 * @param  {Object} config
 * @return {Promise} A promise which resolves after the subscriptions have been fetched and parsed
 */
export function verifyEmail(emailData, defaultSubscriptions, config) {
    const urlParams = serialize({
        country: config.country,
        subscriberId: emailData.subId,
        emailId: emailData.emailId,
        site: config.site
    }, true);
    const subscriptions = [];
    defaultSubscriptions.forEach((subId) => {
        subscriptions.push({
            subscriptionId: subId,
            optIn: 'True',
        });
    });
    const url = `${config.endpoints.updateSubscriptionsURI}&${urlParams}`;

    return fetch(url, {
        body: JSON.stringify({ subscriptions }),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST'
    })
    .then((response) => response.json())
    .then(successfulUpdate)
    .then((response) => {
        if (!response.successful) {
            throw Error('Subscriptions Update Not Successful');
        } else {
            return {
                subscriptions
            };
        }
    });
}

// expose functions for external use
export default {
    submitUpdate,
    submitUnsubscribe,
    getSubscriptionsList,
    verifyEmail
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { renderer, Tabbables } from 'utils';

import MoreInfoBar from 'partials/more-info-bar';

import {
    YouTubeModal,
    YouTubeModalContent,
    YouTubeTriggerElement
} from 'partials/youtube';
import youTubeTriggerElementTemplate from 'partials/youtube/templates/youTubeTriggerElementTemplate';
import { attachThreeSixty } from 'partials/three-sixty';

// Local dependencies
import YouTubeVideoModalContent from './YouTubeVideoModalContent';
import ImageTemplate from './../templates/imageTemplate';
import galleryItemTemplate from './../templates/galleryItemTemplate';
import youtubeVideoModalTemplate from './../templates/youtubeVideoModalTemplate';
import GalleryItemTypes from './../constants/galleryItemTypes';
import ThreeSixtyTemplate from './../templates/threeSixtyTemplate';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {YOUTUBE_CONTAINER: string, YOUTUBE_TRIGGER: string}
 */
const CLASSES = {
    YOUTUBE_CONTAINER: 'youtube-modal__outer-container',
    YOUTUBE_TRIGGER: 'youtube-trigger',
    GALLERY_THREE_SIXTY_CONTAINER: 'gallery__three-sixty-container',
    GALLERY_THREE_SIXTY_VIEW: 'gallery__three-sixty-view',
    MORE_INFO_BAR_CONTENT: 'more-info-bar__content',
    MORE_INFO_BAR_TOGGLE: 'more-info-bar__toggle',
    MORE_INFO_BAR: 'more-info-bar'
};

/**
 * @const defaultLocalization
 * @description Default localization labels for "More Info" button
 * moreInfo: The label for the "More Info" cta.
 * @type {{moreInfo: String}}
 */
const defaultLocalization = {
    moreInfo: 'More Info'
};

/**
 * @const defaultVariantInfo
 * @description Default Variant Info for webview
 * @type {{isDDTVariant: Bool}}
 */
const defaultVariantInfo = {
    isDDTVariant: false
};

// Deconstruct localization pagedata
const {
    moreInfo,
} = window.mbVans.ns('pageData').localization || defaultLocalization;

// Deconstruct variantInfo pagedata
const {
    isDDTVariant
} = window.mbVans.ns('pageData').variantInfo || defaultVariantInfo;

/**
 * @function renderCarouselItem
 * @description given a galleryItem, it checks the type and returns the associated element with
 * rendered template using properties from data
 * @returns {Element} A rendered element with the template
 */
function renderCarouselItem(media, details, inModal) {
    // Deconstruct media
    const {
        heading,
        subheading,
        imgAltText,
        imgSmall,
        imgLarge,
        overlayImage,
        xmlFileLocation,
        youtube: youtubeId,
        youtubeTranscript,
    } = media;

    const {
        modelTitle,
        modelPath,
        captionHeading,
        captionDescription,
        CTAs,
    } = details;

    const getAnalyticsMediaContainerInfo = ({ bodystyleId, classId, isAMG } = window.mbVans.ns('pageData', 'vehicle')) => (
        { media: { body: bodystyleId, class: classId, isAMG } }
    );

    // Node which contains each gallery item
    const renderedItem = renderer.fromTemplate('<div class="carousel-gallery-item"></div>');
    renderedItem.dataset.analyticsContainer = JSON.stringify(getAnalyticsMediaContainerInfo()).replace(/"/g, '\'');

    if (youtubeId && inModal) {
        renderer.insert(youtubeVideoModalTemplate({
            videoId: youtubeId,
            videoTranscript: youtubeTranscript,
            videoTriggerImage: overlayImage.replace(/\s/g, '%20'),
            modelTitle,
            modelPath,
            captionHeading,
            captionDescription,
            CTAs
        })({ getNode: true }), renderedItem);
    } else if (youtubeId) {
        renderer.insert(youTubeTriggerElementTemplate(
            imgSmall.replace(/\s/g, '%20'),
            overlayImage.replace(/\s/g, '%20'),
            imgAltText,
            'modal__image'
        )({ getNode: true }), renderedItem);
    } else if (xmlFileLocation) {
        renderer.insert(
            ThreeSixtyTemplate(
                imgSmall.replace(/\s/g, '%20'),
                imgLarge.replace(/\s/g, '%20'),
                heading,
                subheading,
            )({ getNode: true }),
            renderedItem
        );
        attachThreeSixty(
            renderedItem.querySelector(`.${CLASSES.GALLERY_THREE_SIXTY_CONTAINER}`),
            xmlFileLocation,
            CLASSES.GALLERY_THREE_SIXTY_VIEW
        );
    } else {
        renderer.insert(ImageTemplate(imgSmall.replace(/\s/g, '%20'), overlayImage.replace(/\s/g, '%20'), { cssClass: 'modal__image' })({ getNode: true }), renderedItem);
    }

    const hasMoreInforBar = !youtubeId && !isDDTVariant && !!(
        CTAs.length ||
        modelTitle ||
        captionHeading ||
        captionDescription
    );

    if (hasMoreInforBar) {
        const moreInfoBar = new MoreInfoBar(
            null,
            {
                buttonContent: moreInfo,
                title: modelTitle,
                endpoint: modelPath,
                captionHeading,
                captionDescription
            },
            CTAs
        );

        renderedItem.appendChild(moreInfoBar.render());
    }

    return renderedItem;
}

/**
 * @class GalleryItem
 * @description A view component for displaying a gallery items image
 * and applies a callback when clicked
 * A gallery item can be one of the following:
 * 1. A Tile (current desktop gallery, ref: http://imgur.com/RY7gial)
 * 2. A Thumbnail (current thumbnails underneath GalleryCarousel, ref: http://imgur.com/4sdp0mO)
 * 3. A carousel item (current carousel in the modal of gallery detail, ref: http://imgur.com/slfM3iU)
 */
export default class GalleryItem {
    /**
     * @constructor
     * @description On instantiation sets data and callback, then creates a
     * gallery item node and attaches its events
     * @param {String} type - Value from GalleryItemType enum
     * @param {Object} media - Media object from GalleryItem object
     * @param {Object} [details] - Details object from GalleryItem object
     * @param {Function} [callback=noop] - Callback for onClick event
     */
    constructor(type, media, { details, callback, inModal } = {}) {
        this.galleryItemType = type;
        this.media = media;
        this.details = details;
        this.callback = callback;
        this.inModal = inModal;
        this.element = null;
        this.youTubeModal = null;
        this.hasMoreInforBar = false;

        // method aliases
        this.onClick = this.onClick.bind(this);
        this.onYouTubeTriggerClick = this.onYouTubeTriggerClick.bind(this);
        this.destroy = this.destroy.bind(this);
        this.onBeforeModalClose = this.onBeforeModalClose.bind(this);

        // initialize
        this.createItem();
        this.attachEvents();
    }

    /**
     * @method createItem
     * @description Creates a gallery item element from the media data
     */
    createItem() {
        const {
            imgLarge,
            imgSmall,
            thumbnailImage,
            imgAltText,
            isYouTubeTrigger,
            mediaType,
            heading,
            subheading,
            overlayImage,
            youtube,
            youtubeTranscript
        } = this.media;

        const moreInfoBarElem = this.element && this.element.querySelector(`.${CLASSES.MORE_INFO_BAR_CONTENT}`);

        switch (this.galleryItemType) {
        case GalleryItemTypes.TILE: // intentional fall-through
        case GalleryItemTypes.THUMBNAIL:
            this.element = renderer.fromTemplate(
                galleryItemTemplate(
                    imgLarge.replace(/\s/g, '%20'),
                    imgSmall.replace(/\s/g, '%20'),
                    thumbnailImage.replace(/\s/g, '%20'),
                    imgAltText,
                    this.galleryItemType,
                    mediaType,
                    heading,
                    subheading,
                    isYouTubeTrigger
                )
            );
            break;
        case GalleryItemTypes.CAROUSEL_ITEM:
            this.element = renderCarouselItem(
                this.media,
                this.details,
                this.inModal
            );
            this.hasMoreInforBar = !!(this.details.CTAs.length ||
                this.details.modelTitle ||
                this.details.captionHeading ||
                this.details.captionDescription) &&
                !(this.media.youtube && this.media.youtube.youtubeId) && !isDDTVariant;

            this.tabbables = new Tabbables(this.element);
            if (this.hasMoreInforBar && moreInfoBarElem) {
                this.tabbables.addIgnoreElems(moreInfoBarElem);
            }

            if (youtube && !this.inModal) {
                const triggerElem = this.element.querySelector(`.${CLASSES.YOUTUBE_TRIGGER}`);
                this.youtubeTrigger = new YouTubeTriggerElement(
                    triggerElem,
                    youtube,
                    this.onYouTubeTriggerClick
                );
            }

            if (youtube && this.inModal) {
                const youTubeElement = this.element.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);
                this.youtube = new YouTubeModalContent({
                    videoId: youtube,
                    videoTranscript: youtubeTranscript,
                    triggerImageLarge: overlayImage.replace(/\s/g, '%20'),
                    youTubeElement
                });
            }
            break;
        default:
            console.error('GalleryItemType not found');
        }
    }

    /**
     * @method afterModalOpen
     * @description callback method for after modal opens
     */
    afterModalOpen() {
        if (this.youtube) {
            this.youtube.initialize();
        }
        if (this.tabbables) {
            this.tabbables.enableTabbables();
        }
    }

    /**
     * @method enable
     * @description Calls YouTubeModalContent.enable()
     */
    enable() {
        if (this.youtube) {
            this.youtube.enable();
        }
        if (this.tabbables) {
            this.tabbables.enableTabbables();
        }
    }

    /**
     * @method disable
     * @description Calls YouTubeModalContent.disable()
     */
    disable() {
        if (this.youtube) {
            this.youtube.disable();
        }
        if (this.tabbables) {
            this.tabbables.disableTabbables();
        }
        const moreInfoBarElem = this.element.querySelector(`.${CLASSES.MORE_INFO_BAR}`);
        if (this.hasMoreInforBar && moreInfoBarElem && moreInfoBarElem.classList.contains('active')) {
            this.element.querySelector(`.${CLASSES.MORE_INFO_BAR_TOGGLE}`).click();
        }
    }

    /**
     * @method destroy
     * @description Detaches events and removes the reference to the gallery element
     */
    destroy() {
        if (this.youtube) {
            this.youtube.beforeYouTubeModalClose();
        }
        if (this.youtubeTrigger) {
            this.youtubeTrigger.destroy();
        }
        this.detachEvents();
        this.element.remove();
        this.element = null;
    }

    /**
     * @method attachEvents
     * @description Adds a click event listener and callback to the gallery element
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, this.onClick);
    }

    /**
     * @method detachEvents
     * @description Removes the click event listener and callback from the gallery element
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.CLICK, this.onClick);
    }

    /**
     * @method onClick
     * @description Event handler for when a gallery item is clicked to
     * prevent the default and apply the callback property
     * @param event {Event}
     */
    onClick(event) {
        if (typeof this.callback === 'function') {
            event.preventDefault();
            this.callback(this.element, {
                media: this.media,
                details: this.details
            });
        }
    }

    onYouTubeTriggerClick() {
        this.youTubeVideoModalContent = new YouTubeVideoModalContent(
            {
                videoId: this.media.youtube,
                videoTranscript: this.media.youtubeTranscript,
                details: this.details
            }
        );
        this.youTubeModal = new YouTubeModal(
            this.media.youtube,
            this.youTubeVideoModalContent.getElement(),
            {
                afterModalOpen: this.youTubeVideoModalContent.initialize,
                beforeModalClose: this.onBeforeModalClose
            }
        );
    }

    /**
     * @method onBeforeModalClose
     * @description Set focus back to triggered element when modal close
     */
    onBeforeModalClose() {
        this.youTubeVideoModalContent.beforeYouTubeModalClose();
        this.youtubeTrigger.focus();
    }

    /**
     * @method render
     * @description Retrieves the gallery item element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

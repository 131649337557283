/**
 * @description id values for steps and sub-steps
 */
export default {
    APPLICANT_INFO: 'applicantInfo',
    APPLICATION_TYPE: 'applicationType',
    CO_PERSONAL_INFO: 'coPersonalInfo',
    CO_RESIDENCE: 'coResidence',
    CO_EMPLOYMENT: 'coEmployment',
    CONFIRMATION: 'confirmation',
    CUSTOMER_AGREEMENTS: 'customerAgreements',
    DEALER_INFO: 'dealerInfo',
    ELECTRONIC_RECORDS_CONSENT: 'electronicRecordsConsent',
    EMPLOYMENT: 'employment',
    LEASING_FINANCING: 'leasingFinancing',
    PAYMENT_ESTIMATOR: 'paymentEstimator',
    PERSONAL_INFO: 'personalInfo',
    PRIVACY_NOTICE: 'privacyNotice',
    RESIDENCE: 'residence',
    REVIEW: 'reviewAppInfo',
    REVIEW_AND_SUBMIT: 'reviewReservation',
    STATE_DISCLOSURES: 'stateDisclosures',
    VEHICLE_INFO: 'vehicleInfo'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

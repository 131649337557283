/**
 * Template for an drawer button for authentication small
 */
import { htmlNode } from 'utils';

export default (link, isSmall) => htmlNode`
    <li class="mbs-auth-nav ${isSmall ? 'mbs-auth-nav--small-link top-nav__item' : 'mbs-auth-nav--large-link'}">
        <button class="mbs-auth-nav__button ${isSmall ? 'top-nav__link' : 'link link_in-text'}" data-analytics-trigger="cta">${link.linkText}</button>
    </li>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import ToggleDrawer from 'partials/ToggleDrawer';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {{DETAIL_VIEW_CTA: string}}
 */
const CLASSES = {
    DETAIL_VIEW_CTA: 'toggle-drawer-cta'
};

/**
 * @class OfferList
 * @description
 */
export default class OfferList {
    /**
     * @constructor
     * @param element {Object}
     */
    constructor(element) {
        this.element = element;
        this.cacheDOM();
        this.createToggleDrawers();
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.detailViewCtas = this.element.querySelectorAll(`.${CLASSES.DETAIL_VIEW_CTA}`);
    }

    /**
     * @method createToggleDrawers
     * @description Initializes ToggleDrawers for each Detail View cta
     */
    createToggleDrawers() {
        [].slice.call(this.detailViewCtas).map((cta) => new ToggleDrawer(cta));
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

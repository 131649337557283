/**
 * Template for the media asset carousel navigation
 * Uses ES6 template literal to composite the markup for a navigation component
 * for the media asset carousel
 */
import { html } from 'utils';

export default (labels = {}, analyticsLabels = {}) => html`
    <div data-carousel-navigation
         class="carousel-nav">
         <div class="carousel-nav__pages">
            <span data-carousel-page-current></span>/<span data-carousel-page-total></span>
        </div>
        <div class="carousel-nav__buttons">
            <button data-navigation-button="prev" tabindex="-1"
                class="carousel-nav__button carousel-nav__button--prev" data-analytics-trigger="${analyticsLabels.prevAnalytics}">
                <span class="offscreen">${labels.prev}</span>
            </button><!--
         --><button data-navigation-button="next" tabindex="-1"
                class="carousel-nav__button carousel-nav__button--next" data-analytics-trigger="${analyticsLabels.nextAnalytics}">
                <span class="offscreen">${labels.next}</span>
            </button>
        </div>
     </div>
`.trim();
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { renderer } from 'utils';
import { Modal } from 'partials/modal';

// Module dependencies
import ViewMore from 'partials/view-more';

// Local dependencies
import GalleryItem from './GalleryItem';
import CarouselGallery from './CarouselGallery';
import GalleryItemTypes from './../constants/galleryItemTypes';
import tileGalleryTemplate from './../templates/tileGalleryTemplate';
import tileSectionTemplate from './../templates/tileSectionTemplate';
import MediaTypes from './../constants/mediaTypes';

/**
 * @const SECTION_TYPES
 * @description Collection of constant values for targeting specific gallery section types
 * @type {{ONE_TWO: string, TWO_ONE: string}}
 */
const SECTION_TYPES = {
    ONE_TWO: 'gallery-1-2',
    TWO_ONE: 'gallery-2-1'
};

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{COLUMN_ONE: string, COLUMN_TWO: string}}
 */
const CLASSES = {
    ACTIVE: 'carousel__slide--active',
    COLUMN_ONE: 'gallery__column--1',
    COLUMN_TWO: 'gallery__column--2',
    THREE_SIXTY: 'gallery__three-sixty-container',
};

/**
 * @const ATTRIBUTES
 * @description Attributes that can be used for selecting elements from the gallery
 * @type {{ITEMS: string, SECTION_MEDIA: string}}
 */
const ATTRIBUTES = {
    ITEMS: 'data-gallery-items',
    SECTION_MEDIA: 'data-section-media',
    VIEW_CTA: 'data-view-cta'
};

/**
 * @const defaultLocalization
 * @description Default localization labels for gallery tiles
 * display: The label for the "Display" Cta
 * @type {{display: String}}
 */
const defaultLocalization = {
    display: 'Display'
};

const { display } = window.mbVans.ns('pageData').localization || defaultLocalization;

/**
 * @class TileGallery
 * @description View component for displaying a gallery items
 * in a tiled view and managing its state
 */
export default class TileGallery {
    /**
     * @constructor
     * @description On instantiation sets the properties of the class
     * @param galleryData {Array} Collection of grouped gallery items
     */
    constructor(galleryData) {
        this.galleryData = galleryData; // array of raw gallery objects
        this.gallery = null; // reference to the gallery node element
        this.gallerySections = null;
        this.gallerySectionsElm = null; // reference to element the gallerySections will append to
        this.mediaElms = []; // collection of GalleryItem dom nodes
        this.galleryItemObjects = []; // collection of GalleryItem objects

        // method aliases
        this.createGallery = this.createGallery.bind(this);
        this.createGallerySection = this.createGallerySection.bind(this);
        this.createGalleryItem = this.createGalleryItem.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);

        // initialize
        this.createGallery();
    }

    /**
     * @method createGallery
     * @description Creates a gallery element and a collection of galleryItems elements
     * from the galleryData, then renders the galleryItems to the gallery "items" element,
     * finally creates a View More cta (a component for displaying a number of
     * gallery sections (rows) at a time)
     */
    createGallery() {
        this.gallery = renderer.fromTemplate(tileGalleryTemplate());
        this.gallerySectionsElm = this.gallery.querySelector(`[${ATTRIBUTES.ITEMS}]`);
        this.setData(this.galleryData);
    }

    /**
     * @method setData
     * @description Sets the gallery data, instantiates a ViewMore component,
     * and renders ViewMore to the gallery
     * @param data
     */
    setData(data) {
        this.galleryData = data;
        this.gallerySections = this.galleryData.map(this.createGallerySection);
        this.viewMore = new ViewMore(this.gallerySections);
        renderer.append(this.viewMore.render(), this.gallerySectionsElm);
    }

    /**
     * @method updateData
     * @description Destroys previous data and elements and sets the new data
     * @param data
     */
    updateData(data) {
        this.destroy(false);
        this.setData(data);
    }

    /**
     * @method createGallerySection
     * @description Creates an gallery section element based on the galleryGroup's type,
     * then creates a collection of GalleryItems for each item in the group and appends
     * the items to the section created
     * @param galleryGroup {Object} Grouped item from a gallery collection
     * @return {Node} A galleryGroup element
     */
    createGallerySection(galleryGroup) {
        const { type, galleryItems } = galleryGroup;
        const items = galleryItems.map(this.createGalleryItem);
        const sectionElm = renderer.fromTemplate(tileSectionTemplate(type));
        const mediaElm = sectionElm.querySelector(`[${ATTRIBUTES.SECTION_MEDIA}]`);

        this.populateGallerySection(type, items, mediaElm);

        return sectionElm;
    }

    /**
     * @method populateGallerySection
     * @description Renders galleryItems into gallerySection
     * @param type {String} Type of gallery section
     * @param items {Array} Array of galleryItems
     * @param mediaElm {Node} DOM element
     */
    populateGallerySection(type, items, mediaElm) {
        const oneElm = mediaElm.querySelector(`.${CLASSES.COLUMN_ONE}`);
        const twoElm = mediaElm.querySelector(`.${CLASSES.COLUMN_TWO}`);
        switch (type) {
        case SECTION_TYPES.ONE_TWO: {
            items.forEach((item, idx) => {
                renderer.append(item.render(), idx === 0 ? oneElm : twoElm);
            });
            break;
        }
        case SECTION_TYPES.TWO_ONE: {
            items.forEach((item, idx) => {
                renderer.append(item.render(), idx === 2 ? oneElm : twoElm);
            });
            break;
        }
        default:
            items.forEach((item) => {
                renderer.append(item.render(), mediaElm);
            });
        }
    }

    /**
     * @method createGalleryItem
     * @description Creates a GalleryItem from an group item's data
     * @param galleryItem {Object} galleryItem item from a gallery group's galleryItems collection
     * @return {GalleryItem}
     */
    createGalleryItem(galleryItem) {
        const {
            heading,
            subheading,
            imgAltText,
            imgLarge,
            imgSmall,
            thumbnailImage,
            youtube,
        } = galleryItem.media;

        const {
            captionHeading,
        } = galleryItem.details;

        const imgAlt = `${display} ${captionHeading || imgAltText}`;
        const mediaType = galleryItem.mediaType;

        const item = new GalleryItem(
            GalleryItemTypes.TILE,
            {
                heading,
                subheading,
                imgLarge,
                imgSmall,
                thumbnailImage,
                mediaType,
                imgAltText: imgAlt,
                isYouTubeTrigger: !!youtube && mediaType !== MediaTypes.THREE_SIXTY,
            },
            {
                callback: this.onSelectItem.bind(this, galleryItem),
            }
        );

        this.galleryItemObjects.push(item);
        this.mediaElms.push(item.render());

        this.carousel = null;

        return item;
    }

    /**
     * @method onSelectedItem
     * @description Callback handler for when a gallery item is selected
     * @param item {Object} Gallery item selected
     * @param element {Object} Gallery dom element selected
     * @return {TileGallery}
     */
    onSelectItem(item, element) {
        this.carousel = new CarouselGallery(
            this.galleryData,
            {
                inModal: true,
                selectedGalleryItem: item,
                theme: CarouselGallery.THEMES.DARK
            }
        );

        let modal;

        modal = new Modal(undefined, {
            modalContent: this.carousel.render(),
            sizeLarge: Modal.SIZES.FULL_OVERLAY,
            callbacks: {
                afterOpen: this.carousel.afterModalOpen,
                beforeClose: () => {
                    // clean up and remove the modal on close
                    modal.destroy();
                    modal = null;
                    if (this.carousel) {
                        this.carousel.destroy();
                    }
                }
            }
        });

        modal.open({ callingContainer: element });

        if (item.mediaType === MediaTypes.THREE_SIXTY) {
            // Activate selected item
            modal.modalContent
            .querySelector(`.${CLASSES.ACTIVE} .${CLASSES.THREE_SIXTY}`)
            .dispatchEvent(new MouseEvent('click'));
        }
    }

    /**
     * @method destroy
     * @description Calls the destroy method for each galleryItem, viewMore and gallery elements
     * @param removeGallery {boolean} Indicator to remove the gallery node
     */
    destroy(removeGallery = true) {
        if (removeGallery) {
            this.gallery.remove();
        }

        this.galleryItemObjects.forEach((item) => {
            item.destroy();
        });

        this.galleryItemObjects = [];

        if (this.viewMore) {
            this.viewMore.destroy();
        }
    }

    /**
     * @method render
     * @description Retrieves the gallery element
     * @return {Node}
     */
    render() {
        return this.gallery;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import BaseChapter from './BaseChapter';

export {
    BaseChapter
};

export default {
    BaseChapter
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

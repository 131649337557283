


/**
 * @function loadDoc
 * Crreates the document inside the 360 iframe
 * @param {*} doc reference to the iframe document
 * @param {string} xmlFile path to the xml file that describes the 360 view
 * @returns {void}
 */
function loadDoc(doc, xmlFile) {
    const threeSixtyRoot = xmlFile.substring(0, xmlFile.lastIndexOf('/'));
    doc.open();
    doc.write(
        '<html><head><title></title>' +
        '<link href="/etc/designs/mb-vans/css/mb/main-new.css" rel="stylesheet">' +
        '</head><body style="margin:0;padding:0;">' +
        '<div id="pano" style="height:100%"></div>' +
        `<script src="${threeSixtyRoot}/tour.js"></script>` +
        '<script>' +
        'embedpano({' +
        `  swf: "${threeSixtyRoot}/tour.swf",` +
        `  xml: "${xmlFile}",` +
        '  target: "pano",' +
        '  html5: "auto",' +
        '  mobilescale: 0.5,' +
        '  passQueryParameters: true' +
        '});' +
      '</script></body></html>'
      );
    doc.close();
}

/**
 * @function loadThreeSixty
 * Loads the 360 player into an element. This will fail if element is not currently in the DOM
 * @param {Node} element this element will be replaced by the 360 view
 * @param {string} xmlFile path to file which descibes the 360 view
 * @param {string} [iframeClass='three-sixty-view'] class to apply to the 360 iframe
 * @returns {boolean} true if the view was succesfully loaded.
 */
function loadThreeSixty(element, xmlFile, iframeClass = 'three-sixty-view') {
    if (!xmlFile) {
        console.error('Missing threeSixty data attribute on 360 container');
        return false;
    }
    if (document.body.contains(element)) {
        element.innerHTML = (`<iframe allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" class="${iframeClass}"></iframe>`);
        const iframe = element.querySelector('iframe');
        loadDoc(iframe.contentWindow.document, xmlFile);
        return true;
    }
    return false;
}


/**
 * @function attachThreeSixty
 * Attaches a 360 viewer to an element. A click event is required to actually load the view
 * @param {*} element Will listen for clicks on this element, afterwards it will be replaced by the 360 view
 * @param {string} xmlFile path to file which descibes the 360 view
 * @param {string} [iframeClass='three-sixty-view'] class to apply to the iframe which hosts the 360 view
 */
function attachThreeSixty(element, xmlFile, iframeClass = 'three-sixty-view') {
    const cb = () => {
        const started = loadThreeSixty(element, xmlFile, iframeClass);
        if (started) {
            element.removeEventListener('click', cb);
        }
    };
    element.addEventListener('click', cb);
}

export default attachThreeSixty;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for AMG Specialty Page loading message
 * Uses ES6 template literal to composite the markup
 */
import { htmlNode } from 'utils';

export default (localization) => htmlNode`
    <div class="specialty-chapter__loading-message">
        ${localization.loading}: <span class="specialty-chapter__loading-pct">0</span>%
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { html } from 'utils';

// Local dependencies
import MediaTypes from '../constants/mediaTypes';

/**
 * method will form tag <img> or tag <picture> based on param galleryItemType
 * @param largeImage
 * @param smallImage
 * @param thumbnailImage
 * @param altText
 * @param galleryItemType
 * @param mediaType
 * @param heading
 * @param subheading
 * @param isYouTubeTrigger
 * @returns tag <img> or tag <picture>
 */
function renderImage(
    largeImage,
    smallImage,
    thumbnailImage,
    altText,
    galleryItemType,
    mediaType,
    heading,
    subheading,
    isYouTubeTrigger
) {
    if (galleryItemType === 'thumbnail') {
        return `<img src="${thumbnailImage}" alt="${altText}" class="gallery__image gallery__image--${galleryItemType}">`;
    }

    return `
        <picture class="gallery__picture">
            <source
                media="(max-width: 767px)"
                srcset="${smallImage || largeImage}"
            />
            <img
                src="${largeImage}"
                alt="${altText}"
                class="gallery__image gallery__image--${galleryItemType}"
                role="${altText ? false : 'presentation'}"
            />
        </picture>
        ${isYouTubeTrigger ? `
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                xml:space="preserve" viewBox="0 0 62 62" class="youtube-trigger__icon-play">
                <circle class="youtube-trigger__circle" cx="31" cy="31" r="31"></circle>
                <polygon class="youtube-trigger__triangle" points="24,17.6 24,43.4 41.3,30.4 "></polygon>
            </svg>
        ` : ''}
        ${mediaType === MediaTypes.THREE_SIXTY ? '<div class="gallery__three-sixty-cta"></div>' : ''}
    `;
}

/**
 * @method renderDetails
 * @description markup for 360 tile details (heading and sub-heading)
 * @param {String} mediaType - '360', 'youtube', 'html5video' or 'image'
 * @param {String} heading - heading text
 * @param {String} subheading - subheading text
 */
function renderDetails(mediaType, heading, subheading) {
    if (mediaType !== MediaTypes.THREE_SIXTY || (!heading && !subheading)) {
        return '';
    }

    return `    
        <div class="gallery__info-layer">    
            <div class="gallery__info-heading">${heading}</div>    
            <div class="gallery__info-subheading">${subheading}</div>    
        </div>    
    `;
}

/**
 * @description Template for galleryItem
 * Uses ES6 template literal to composite the markup for a gallery item
 * @param {String} largeImage - Image used for large screen
 * @param {String} smallImage - Image used for small screen
 * @param {String} thumbnailImage
 * @param {String} altText - Image alt text
 * @param {String} galleryItemType - Type of gallery item ('tile' or 'thumbnail') to be used as class modifier
 * @param {String} mediaType
 * @param {String} heading
 * @param {String} subheading
 * @param {Boolean} isYouTubeTrigger - Indicates ofr galleryItem includes a YouTube video
 */
export default (largeImage, smallImage, thumbnailImage, altText, galleryItemType, mediaType, heading, subheading, isYouTubeTrigger = false) => html`
    <a href="#" class="gallery__item gallery__item--${galleryItemType}" data-analytics-trigger="gallery-expand">
        ${renderImage(largeImage, smallImage, thumbnailImage, altText, galleryItemType, mediaType, heading, subheading, isYouTubeTrigger)}
        ${renderDetails(mediaType, heading, subheading)}
    </a>
`.trim();

// do not delete 9fbef606107a605d69c0edbcd8029e5d

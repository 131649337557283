
// util dependencies
import { formatNumber, formatDate } from 'utils';

// partial dependencies
import AnalyticsApi from 'partials/analytics';

import { paymentDriverRatesApi } from 'partials/payment-driver-estimator/react';

// local dependencies
import PAYMENT_TYPES from '../constants/paymentTypes';
import CREDIT_SCORE from '../constants/creditScores';
import paymentDriverEstimatorUtils from './paymentDriverEstimatorUtils';
import PaymentDriverEstimatorCache from './PaymentDriverEstimatorCache';

/**
 * @property paymentDriverEstimatorCache
 * @description Instance of the PaymentDriverEstimatorCache that is used for caching
 * successful Payment Driver requests
 * @type {PaymentDriverEstimatorCache}
 */
const paymentDriverEstimatorCache = new PaymentDriverEstimatorCache();

/**
 * @property getEstimateController
 * @description Reference to the getItemizedPayments fetch AbortControl
 */
let getEstimateController;

/**
 * @property analyticsApi
 * @description Instance of the AnalyticsApi that is used for log events
 * @type {AnalyticsApi}
 */
const analyticsApi = new AnalyticsApi('satellite');

/**
 * @const ANALYTIC_EVENTS
 * @description Collection of custom analytic event names
 */
const ANALYTIC_EVENTS = {
    PAYMENT_DRIVER_SERVICE_CALL: 'paymentDriverServiceCallTracking'
};

/**
 * @method buildUserData
 * @description creates an object of user entered data
 * @param {Object} data user entered data
 * @param {String} data.creditScore user entered credit score
 * @param {String} data.downPayment user entered down payment amount
 * @param {String} data.firstName user entered first name
 * @param {String} data.lastName user entered last name
 * @param {Array} data.serviceContracts user selected service contracts (eg sum of PPM & ELW products)
 * @param {String} data.terms user entered terms of financing agreement
 * @param {String} data.tradeIn user entered trade in amount
 * @param {String} data.zipCode user entered zip code
 * @param {Array} data.excludedFees the list of fees which should be excluded from the calculations
 * @param {String} paymentType - Payment type to estimate (finance | lease)
 * @param {string} country
 * @param {String} language
 * @param {Number} rateData
 */
function buildUserData(data, paymentType, country, language, rateData) {
    const {
        annualMileage,
        creditScore,
        downPayment,
        firstName,
        lastName,
        paymentFrequency,
        serviceContracts,
        state,
        terms,
        township,
        tradeIn,
        zipCode,
        excludedFees
    } = data;

    return {
        annualMiles: annualMileage ? parseInt(annualMileage, 10) : 0,
        creditScore: creditScore ? CREDIT_SCORE[creditScore].score : 750,
        downPayment: downPayment ? formatNumber.toNumber(downPayment, country, language) : 0,
        financeType: paymentType === PAYMENT_TYPES.FINANCE ? 'loan' : 'lease',
        firstName,
        lastName,
        locationID: {
            code: township && township.value ? township.value : '',
            description: township && township.label ? township.label : ''
        },
        paymentFrequency: paymentFrequency || '',
        rate: typeof rateData === 'number' ? rateData : '',
        serviceContracts: serviceContracts || [],
        term: terms || 24,
        tradeInAmount: tradeIn && !window.hasPendingTradeInSkip ? formatNumber.toNumber(tradeIn, country, language) : 0,
        zipCode: zipCode || '',
        province: state || '',
        excludedFees: excludedFees || []
    };
}

/**
 * @method buildVehicleData
 * @description builds object of necessary vehicle information for PE request
 * @param {Object} vehicle vehicle object
 */
function buildVehicleData(vehicle) {
    const {
        adminFee,
        certified,
        classId,
        dealerFees,
        dealerId,
        modelId,
        reservable,
        reservePrice,
        type,
        vin,
        year
    } = vehicle;
    const isUsedVehicle = vehicle.type === 'PRE';
    let dealerFeesArr = [];

    if (dealerFees) {
        dealerFeesArr = dealerFees;
    } else if (adminFee) {
        dealerFeesArr = [
            {
                type: 'ADMIN_FEE',
                value: adminFee
            }
        ];
    }

    return {
        dealerFees: dealerFeesArr,
        dealerId,
        modelCode: modelId || vehicle.model,
        modelClass: classId || vehicle.class,
        retailCost: isUsedVehicle ? reservePrice.price : (vehicle.msrp.price || vehicle.msrp),
        inventoryMSRP: !isUsedVehicle ? (vehicle.msrp.price || vehicle.msrp) : reservePrice.price, // VOW-6205
        reservable: !!reservable,
        vehicleCondition: paymentDriverEstimatorUtils.setVehicleCondition(type, certified),
        vin,
        year
    };
}

/**
 * @method getCurrencyValues
 * @description turns a string value into a number value and formatted currency value
 * @param {string} responseValue string value to be converted
 * @param {string} country
 * @param {string} language
 * @param {string} currency
 */
function getCurrencyValues(responseValue, country, language, currency, currencyDecimalDigits) {
    const value = formatNumber.toNumber(responseValue, country, language);
    let minDecimalValue;

    if (!currencyDecimalDigits || currencyDecimalDigits === 0) {
        minDecimalValue = Number.isInteger(value) ? 0 : 2;
    } else {
        minDecimalValue = currencyDecimalDigits;
    }

    return {
        value,
        formattedValue: formatNumber.toCurrency(value, country, language, currency, 2, minDecimalValue)
    };
}

/**
 * @method getPercentageValues
 * @description turns a string value into a number value and formatted percent value
 * @param {string} responseValue string value to be converted
 * @param {string} country
 * @param {string} language
 */
function getPercentageValues(responseValue, country, language) {
    const value = formatNumber.toNumber(responseValue, country, language);
    return {
        value,
        formattedValue: formatNumber.toPercentage((parseFloat(value) / 100), country, language)
    };
}

/**
 * @function getTaxAndFeeKey
 * @description create camelCase keys out of tax and fee description values
 * @param {string} description tax or fee description value
 */
function getTaxAndFeeKey(description) {
    return description
        .replace(/\//g, ' ')
        .replace(/\b\w/g, (match, index) => (index === 0 ? match.toLowerCase() : match.toUpperCase()))
        .replace(/\s/g, '');
}

/**
 * @method constructTaxesAndFees
 * @description constructs an array of data object that represent taxes and fees
 * @param {array} taxesFees taxes/fees array to iterate and construct the returned objects
 * @param {object} labels i18n content for taxes and fees
 * @param {object} config data for country, currency and language
 * @return {Array} Collection of taxes/fees objects
 */
function constructTaxesAndFees(taxesFees, labels, config) {
    const {
        country,
        currency,
        language,
        currencyDecimalDigits,
        province
    } = config;
    const arr = [];

    taxesFees.forEach((data) => {
        if (data.amount && !isNaN(data.amount) && data.amount !== 0) {
            let description = '';

            if (typeof labels[data.id] === 'object') {
                description = labels[data.id].provinces && labels[data.id].provinces[province] ?
                    labels[data.id].provinces[province] :
                    labels[data.id].default;
            } else if (typeof labels[data.id] === 'string') {
                description = labels[data.id];
            } else {
                description = data.description || '';
            }

            arr.push({
                description,
                key: data.description ? getTaxAndFeeKey(data.description) : data.id,
                id: data.id,
                tooltip: labels.tooltips[data.id] || '',
                ...getCurrencyValues(data.amount, country, language, currency, currencyDecimalDigits)
            });
        }
    });

    return arr;
}

/**
 * @method parseTaxesAndFees
 * @description parse all taxes and fees that are returned from the api and return individual and total values
 * @param {Object} data response data from itemized payment api
 * @param {Object} labels i18n content for taxes and fees
 * @param {String} country
 * @param {String} language
 * @param {String} currency
 */
function parseTaxesAndFees(data, labels, country, language, currency, currencyDecimalDigits, province) {
    const paymentData = data;
    const dealerFeesList = paymentData.dealerFees && paymentData.dealerFees.fees ? paymentData.dealerFees.fees : [];
    const taxesList = paymentData.taxes && paymentData.taxes.tax ? paymentData.taxes.tax : [];
    const dmvFeeList = data.dmvFees && data.dmvFees.fees ? data.dmvFees.fees : [];
    const otherFeesList = data.otherFees && data.otherFees.fees ? data.otherFees.fees : [];
    const fees = {
        dealerFees: {
            description: labels.dealerFees,
            tooltip: labels.tooltips.dealerFees,
            fees: constructTaxesAndFees(dealerFeesList, labels,
                { country, language, currency, currencyDecimalDigits, province })
        },
        dmvFees: {
            description: labels.dmvFees,
            tooltip: labels.tooltips.dmvFees,
            fees: constructTaxesAndFees(dmvFeeList, labels, { country, language, currency, currencyDecimalDigits })
        },
        otherFees: constructTaxesAndFees(otherFeesList, labels, { country, language, currency, currencyDecimalDigits })
    };
    const taxes = {
        description: labels.taxes,
        tooltip: labels.tooltips.taxes,
        items: constructTaxesAndFees(taxesList, labels, { country, language, currency, currencyDecimalDigits })
    };

    const feeTotal = getCurrencyValues(
        paymentData.feeTotal,
        country,
        language,
        currency
    );

    const taxTotal = getCurrencyValues(
        paymentData.taxTotal,
        country,
        language,
        currency
    );

    const total = taxTotal.value + feeTotal.value;

    return {
        fees,
        feeTotal,
        taxes,
        taxTotal,
        total: getCurrencyValues(
            total,
            country,
            language,
            currency,
            currencyDecimalDigits
        )
    };
}

/**
 * @method parseEstimatedPaymentData
 * @description parse the response object to get apr, monthly payment, and amount financed information
 * @param {Object} data response data from PE monthly estimate API
 * @param {Object} taxAndFeeContent i18n content for taxes and fees
 * @param {String} country
 * @param {String} language
 * @param {String} currency
 * @param {Object} reqObject object with user's data and vehicle's data
 * @param {Number} currencyDecimalDigits Number of digits to display in the currency decimals
 * @param {Object} featureManagement object with configurations to enable/disable features
 */
function parseEstimatedPaymentData(data, taxAndFeeContent, country, language,
                                   currency, reqObject, currencyDecimalDigits, featureManagement) {
    const paymentData = data.payment;
    const monthlyPayment = paymentData.monthlyPayment;
    const amountFinanced = paymentData.amountFinanced;
    const province = reqObject.province;
    const taxesAndFees =
        parseTaxesAndFees(paymentData, taxAndFeeContent, country, language, currency, currencyDecimalDigits, province);
    const serviceContractsTotalValue = reqObject.serviceContracts && reqObject.serviceContracts.length > 0 ?
        reqObject.serviceContracts.map((serviceItem) => serviceItem.retailRate).reduce((a, b) => a + b) : 0;
    const totalCost = (reqObject.retailCost + taxesAndFees.total.value + serviceContractsTotalValue);
    const totalPrice = (totalCost
        - reqObject.downPayment
        - (window.hasPendingTradeInSkip ? 0 : parseFloat(paymentData.tradeInAmount))
    );
    let validThruDate;
    if (paymentData.programDetails.programEndDate) {
        validThruDate = formatDate.toShortDate(paymentData.programDetails.programEndDate.replace(/T/g, ' '));
    } else if (paymentData.expiryDate) {
        validThruDate = paymentData.expiryDate;
    } else {
        validThruDate = '';
    }

    return {
        apr: getPercentageValues(paymentData.apr, country, language),
        acquisitionFees: paymentData.acquisitionFees ?
            getCurrencyValues(paymentData.acquisitionFees, country, language, currency) : null,
        advanceSalesTax: paymentData.advanceSalesTax ?
            getCurrencyValues(paymentData.advanceSalesTax, country, language, currency) : null,
        amountFinanced: getCurrencyValues(amountFinanced, country, language, currency, currencyDecimalDigits),
        capCost: paymentData.capCost ? getCurrencyValues(paymentData.capCost, country, language, currency) : null,
        costOfBorrowing: paymentData.costOfBorrowing ?
            getCurrencyValues(paymentData.costOfBorrowing, country, language, currency) : null,
        downPaymentAmount:
            getCurrencyValues(paymentData.downPaymentAmount, country, language, currency, currencyDecimalDigits),
        dueAtSigning: getCurrencyValues(paymentData.dueAtSigning, country, language, currency),
        estimatedMonthlyPayment: getCurrencyValues(monthlyPayment, country, language, currency, currencyDecimalDigits),
        firstMonthPayment: featureManagement.enableFirstMonthPayment ?
            getCurrencyValues(monthlyPayment, country, language, currency, currencyDecimalDigits) :
            null,
        gapInsurance: getCurrencyValues(paymentData.gapInsurance, country, language, currency),
        securityDeposit: paymentData.securityDeposit ?
            getCurrencyValues(paymentData.securityDeposit, country, language, currency, currencyDecimalDigits) : null,
        specialOffers: paymentData.programDetails.specialOffer ?
            { ...paymentData.programDetails, ...getCurrencyValues(paymentData.programDetails.standardMonthlyPayment, country, language, currency) } : '',
        taxesAndFees,
        totalObligationAmount: paymentData.totalObligationAmount ?
            getCurrencyValues(paymentData.totalObligationAmount, country, language, currency) : null,
        totalCost: getCurrencyValues(totalCost, country, language, currency, currencyDecimalDigits),
        totalPrice: getCurrencyValues(totalPrice, country, language, currency, currencyDecimalDigits),
        tradeInAmount: getCurrencyValues(paymentData.tradeInAmount, country, language, currency, currencyDecimalDigits),
        term: paymentData.term,
        rebate: getCurrencyValues(paymentData.rebate, country, language, currency),
        residualValue: paymentData.residualValue ?
            getCurrencyValues(paymentData.residualValue, country, language, currency, currencyDecimalDigits) : null,
        validDate: validThruDate
    };
}

/**
 * @function isMonthlyEstimateApiSuccessful
 * @description Checks if the response code is a 2xx
 * @returns {Boolean} True if the response code is 2xx
 */
function isMonthlyEstimateApiSuccessful(response) {
    return /^2\d\d$/.test(response.status.code);
}


/**
 * @function isProgramDateExpired
 * @description Check if program end date is expired or not
 * @returns {Boolean}
 */
function isProgramDateExpired(response) {
    let isDateExpired = false;
    const todaysDate = new Date();
    const programEndDate = new Date(response.payment?.programDetails?.programEndDate);

    if (programEndDate < todaysDate) {
        isDateExpired = true;
    }
    return isDateExpired;
}


/**
 * @function logErrorEvent
 * @description trigger a satellite event for service errors
 * @param {string} eventName name of analtyics event
 * @param {string} description description of error
 * @param {string} type error status code
 */
function logErrorEvent(eventName, description, type) {
    analyticsApi.logEvent(eventName, {
        name: description,
        type
    });
}

/**
 * @function ExceptionError
 * @description Constructor function that creates a custom error object
 * @param {number} code status code from response
 * @param {string} message error message
 */
function ExceptionError(code, message) {
    this.code = code;
    this.message = message;
    return this;
}

/**
 * @method getRates
 * @description make a request to get rates and return parsed data
 * @param {Object} data necessary data to create the request object
 * @param {String} data.endpoints.vehicleRatesLookup endpoint to make request to
 * @param {String} data.userData.state user's state
 * @param {Object} data.vehicle all vehicle related data
 * @param {Object} paymentType type of the payment
 */
function getRates(data, paymentType) {
    const {
        endpoints: {
            vehicleRatesLookup,
            vehicleRatesLookupLease
        },
        userData: {
            state
        },
        vehicle
    } = data;

    const vehicleData = {
        modelId: vehicle.modelId,
        year: vehicle.year,
        type: vehicle.type,
        dealer: {
            state
        },
        vin: vehicle.vin,
        isNew: vehicle.isNew
    };

    if (paymentType === PAYMENT_TYPES.LEASE) {
        return paymentDriverRatesApi.getRates(vehicleRatesLookupLease, vehicleData);
    }
    return paymentDriverRatesApi.getRates(vehicleRatesLookup, vehicleData);
}

/**
 * @method getItemizedPayments
 * @description make a request to the monthly estimate PE endpoint and return parsed data
 * @param {Object} data necessary data to create the request object
 * @param {Object} data.config necessary data for configuring the request/response object ei country, language
 * @param {String} data.endpoints.getPayments PE endpoint to make request to
 * @param {Object} data.userData all user entered data
 * @param {Object} data.vehicle all vehicle related data
 * @param {String} paymentType - Payment type to estimate (finance | lease)
 * @param {Array} rates - rates from getRates request
 * @param {Boolean} checkProgramEndDate - conditional check if you want to throw error on program end date
 */
function getItemizedPayments(data, paymentType, rates, checkProgramEndDate) {
    getEstimateController = new AbortController();
    const {
        config: {
            country,
            currency,
            currencyDecimalDigits,
            featureManagement,
            language,
            taxAndFeeContent
        },
        endpoints: {
            getPayments
        },
        source,
        userData,
        vehicle
    } = data;
    const rate =  rates ? rates[0].rate : userData.rate;

    const requestObj = {
        ...buildUserData(userData, paymentType, country, language, rate),
        ...buildVehicleData(vehicle),
        source
    };

    return paymentDriverEstimatorCache.fetch(getPayments, {
        body: JSON.stringify(requestObj),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST',
        signal: getEstimateController.signal
    })
    .then((response) => response.json())
    .then((response) => {
        if (!isMonthlyEstimateApiSuccessful(response)) {
            throw new ExceptionError(
                response.status.code,
                response.status.code === 499 ? 'ChromeStyleId Error' : 'Monthly Estimate Not Successful'
            );
        } else if (isMonthlyEstimateApiSuccessful(response) && response.status.code === 490) {
            throw new ExceptionError(
                490,
                'No Payments Found'
            );
        } else if (isProgramDateExpired(response) && checkProgramEndDate) {
            throw new ExceptionError(
                4910,
                'ProgramEndDate is expired'
            );
        }

        analyticsApi.logEvent(ANALYTIC_EVENTS.PAYMENT_DRIVER_SERVICE_CALL,
            { type: paymentType, value: response.payment.monthlyPayment });

        return parseEstimatedPaymentData(
            response, taxAndFeeContent, country, language, currency,
            requestObj, currencyDecimalDigits, featureManagement
        );
    })
    .catch((error) => {
        const code = error.code;
        if (error instanceof TypeError && !navigator.onLine) {
            throw new ExceptionError(
                600,
                'Connectivity Error'
            );
        }

        logErrorEvent(`error${code || ''}`, `Payment Driver Monthly Estimate Api - ${error.message || error}`, code || '');

        throw error;
    });
}

/**
 * @method getEstimatedPayment
 * @description make a request to the monthly estimate PE endpoint and request to get rates, return parsed data
 * @param {Object} data necessary data to create the request object
 * @param {Object} data.config.featureManagement there is a necessary flag to know whether we need to perform getRates
 * @param {String} paymentType - Payment type to estimate (finance | lease)
 * @param {Boolean} checkProgramEndDate - conditional check if you want to throw error on program end date
 */
function getEstimatedPayment(data, paymentType, checkProgramEndDate) {
    const {
        config: {
            featureManagement
        }
    } = data;

    if (featureManagement.getRates) {
        return getRates(data, paymentType)
            .then((rates) => getItemizedPayments(data, paymentType, rates, checkProgramEndDate));
    } else {
        return getItemizedPayments(data, paymentType, null, checkProgramEndDate);
    }
}

/**
 * @function abortGetItemizedPayments
 * @description Callback to cancel a getItemizedPayments request
 * This is helpful to cancel a stream of service requests to reduce multiple
 * responses from being returned in an inaccurate order
 */
export function abortGetItemizedPayments() {
    if (getEstimateController) {
        getEstimateController.abort();
    }
}

export default {
    abortGetItemizedPayments,
    getEstimatedPayment
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

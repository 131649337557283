// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for tool tip
 * Uses ES6 template literal to composite the markup for a tool tip view
 */
export default (analyticsTrigger, label, id, size) => htmlNode`
    <div data-tool-tip="" 
         class="tool-tip ${size ? `tool-tip--${size}` : ''}">
        <div data-tool-tip-icon="" 
                class="tool-tip__icon"
                aria-describedby="tool-tip-flyout-${id}"
                type="button"
                role="button"
                data-analytics-trigger=${analyticsTrigger}
                tabindex="0">
            <span class="offscreen">${label}</span>
        </div>
        <span class="tool-tip__caret"></span>
        <div id="tool-tip-flyout-${id}"
             data-tool-tip-flyout="" 
             class="tool-tip__flyout"></div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { InputTextControl, InputRadioGroupControl } from 'partials/input-control';

// Local dependencies
import dealerFormContactInfoTemplate from '../templates/dealerFormContactInfoTemplate';

/**
 * @const ATTRIBUTES
 * @description DOM attributes for view
 * @type {{
 *  FIRST_NAME: string,
 *  LAST_NAME: string,
 *  EMAIL: string,
 *  PHONE: string,
 *  PREFERENCE: string
 * }}
 */
const ATTRIBUTES = {
    FIRST_NAME: 'dealer-form-first-name',
    LAST_NAME: 'dealer-form-last-name',
    EMAIL: 'dealer-form-email',
    PHONE: 'dealer-form-phone',
    PREFERENCE: 'dealer-form-preferred-contact'
};

/**
 * @class DealerFormContactInfo
 * @description View responsible for creating a Dealer Form's contact information
 * form fields and managing their state
 */
export default class DealerFormContactInfo {
    /**
     * @constructor
     * @description On instantiation, sets the initial properties,
     * caches the dom and creates the its inputs
     * @param content {Object} Object contains content key/values
     * @param userInfo {Object} Object containing user information
     */
    constructor(content = {}, userInfo = {}) {
        this.content = content;
        this.userInfo = { ...userInfo };
        this.element = dealerFormContactInfoTemplate(this.content)({ getNode: true });
        this.inputs = null;

        // method aliases
        this.setContactPreferenceInputs = this.setContactPreferenceInputs.bind(this);

        // initialize view
        this.cacheDOM();
        this.createInputs();
    }

    /**
     * @method populateInputs
     * @description Populates contact form with user information if it exists
     * @param userInfo {Object} Data to pre-populate the form with
     */
    populateInputs(userInfo) {
        if (!userInfo) {
            return;
        }

        // iterate the formInputs and pre-populate the input if there is a value
        Object.keys(userInfo).forEach((field) => {
            if (this.inputs[field]) {
                this.inputs[field].setValue(userInfo[field]);
            }
        });
    }

    /**
     * @method destroy
     * @description method that destroys all form elements
     */
    destroy() {
        Object.values(this.inputs).forEach((input) => {
            if (input.destroy) {
                input.destroy();
            }
        });
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements from the view
     */
    cacheDOM() {
        this.firstName = this.element.querySelector(`[${ATTRIBUTES.FIRST_NAME}]`);
        this.lastName = this.element.querySelector(`[${ATTRIBUTES.LAST_NAME}]`);
        this.email = this.element.querySelector(`[${ATTRIBUTES.EMAIL}]`);
        this.phone = this.element.querySelector(`[${ATTRIBUTES.PHONE}]`);
        this.preferredContact = this.element.querySelector(`[${ATTRIBUTES.PREFERENCE}]`);
    }

    /**
     * @method createInputs
     * @description Creates the views input controls and appends them to the view
     */
    createInputs() {
        const hasUserInfo = this.userInfo;
        this.inputs = {
            firstName: new InputTextControl({
                type: 'text',
                id: 'df-first-name',
                required: true,
                labelText: this.content.firstName,
                maxLength: 50,
                validateOnBlur: true,
                valueText: hasUserInfo ? this.userInfo.firstName : '',
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: this.content.pleaseEnterFirstName
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: this.content.pleaseEnterFirstName
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
                errorMessage: this.content.pleaseEnterFirstName
            }),
            lastName: new InputTextControl({
                type: 'text',
                id: 'df-last-name',
                required: true,
                labelText: this.content.lastName,
                maxLength: 50,
                validateOnBlur: true,
                valueText: hasUserInfo ? this.userInfo.lastName : '',
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: this.content.pleaseEnterLastName
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: this.content.pleaseEnterLastName
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
                errorMessage: this.content.pleaseEnterLastName
            }),
            email: new InputTextControl({
                type: 'text',
                id: 'dl-email',
                required: true,
                labelText: this.content.email,
                maxLength: 50,
                validateOnBlur: true,
                validation: [],
                valueText: hasUserInfo ? this.userInfo.email : '',
                restrictions: [InputTextControl.RESTRICTION_TYPE.EMAIL_SPECIAL_CHARS],
            }),
            phone: new InputTextControl({
                type: 'tel',
                id: 'dl-phone',
                required: false,
                labelText: this.content.phoneNumber,
                maxLength: 14,
                validateOnBlur: true,
                validation: [],
                valueText: hasUserInfo ? this.userInfo.phone : '',
                formatting: [
                    {
                        type: InputTextControl.FORMAT_TYPE.PHONE
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.PHONE_NUMBER]
            }),
            preferredContact: new InputRadioGroupControl({
                name: 'dl-contact-preference',
                inputClassNames: ['dealer-form__radio-options', 'dealer-form__contact-preference'],
                onChangeCallback: this.setContactPreferenceInputs,
                options: [
                    {
                        config: {
                            checked: true,
                            valueText: 'email',
                            labelText: this.content.email,
                            inputClassNames: ['dealer-form__radio-option']
                        }
                    },
                    {
                        config: {
                            valueText: 'phone',
                            labelText: this.content.phone,
                            inputClassNames: ['dealer-form__radio-option']
                        }
                    }
                ]
            })
        };

        this.setContactPreferenceInputs();

        this.firstName.parentNode.replaceChild(this.inputs.firstName.render(), this.firstName);
        this.lastName.parentNode.replaceChild(this.inputs.lastName.render(), this.lastName);
        this.email.parentNode.replaceChild(this.inputs.email.render(), this.email);
        this.phone.parentNode.replaceChild(this.inputs.phone.render(), this.phone);
        this.preferredContact.parentNode.replaceChild(
            this.inputs.preferredContact.render(),
            this.preferredContact
        );
    }

    /**
     * @method setContactPreferenceInputs
     * @description Sets the contact preference input configurations
     * based on the preferredContact value
     */
    setContactPreferenceInputs() {
        const isEmailSelected = this.inputs.preferredContact.getValue() === 'email';
        const emailValidation = [
            {
                type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                errorMessage: this.content.invalidEmail
            },
            {
                type: InputTextControl.VALIDATION_TYPE.EMAIL,
                errorMessage: this.content.invalidEmail
            }
        ];
        const phoneValidation = [
            {
                type: InputTextControl.VALIDATION_TYPE.PHONE_FORMATTED,
                errorMessage: this.content.invalidPhoneNumber
            }
        ];

        if (isEmailSelected) {
            emailValidation.unshift({
                type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                errorMessage: this.content.invalidEmail
            });
        } else {
            phoneValidation.push({
                type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                errorMessage: this.content.invalidPhoneNumber
            });
        }

        this.inputs.email.setRequired(isEmailSelected);
        this.inputs.phone.setRequired(!isEmailSelected);
        this.inputs.email.setValidation(emailValidation);
        this.inputs.phone.setValidation(phoneValidation);
    }

    /**
     * @method getInputs
     * @description gets all inputs for selects form sections
     * @returns inputs
     */
    getInputs() {
        return this.inputs;
    }

    /**
     * @methdo render
     * @description  return the HTML Node with the element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

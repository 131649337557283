import { renderer, scrollTo, storage } from 'utils';

import { STORAGE_KEYS } from 'Constants';

import { accordionParser } from 'partials/accordion';

import RecallsUrgentSearch from './RecallsUrgentSearch';
import UrgentRecallsAirbag from './UrgentRecallsAirbag';
import UrgentRecallsParts from './UrgentRecallsParts';

import urgentRecallsLandingTemplate from './../templates/urgentRecallsLandingTemplate';

/**
 * @const CLASSES
 * @description CSS selectors for caching elements
 * @type {{SEARCH_MODULE: string, AIRBAG_MODULE: string, ACCORDION_MODULE: string}}
 */
const CLASSES = {
    SEARCH_MODULE: 'recalls-hero__search-module',
    AIRBAG_MODULE: 'urgent-recalls__airbag-container',
    PARTS_MODULE: 'urgent-recalls__parts-availability',
    ACCORDION_MODULE: 'urgent-recalls__faq-accordion'
};

/**
 * View that renders Urgent Recalls page
 */
export default class UrgentRecallsLanding {
    /**
     * @constructor
     * @param {object} content - Content object
     * @param {object} config - Config object
     * @param {object} statesData - List of states and their zones
     * @param {object} modelsData - List of models and their recall info
     */
    constructor(content, config, statesData, modelsData) {
        this.content = content;
        this.config = config;
        this.element = urgentRecallsLandingTemplate(content, config)({ getNode: true });
        this.renderAirbagModule = this.renderAirbagModule.bind(this);
        this.renderPartsModule = this.renderPartsModule.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);

        this.cacheDOM();
        this.renderUrgentSearch(content, statesData, modelsData);
        this.renderFAQAccordion();
        storage.sessionStorage.create(STORAGE_KEYS.TAKATA_PAGE_VISIT, true);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.searchModuleElm = this.element.querySelector(`.${CLASSES.SEARCH_MODULE}`);
        this.airbagModuleElm = this.element.querySelector(`.${CLASSES.AIRBAG_MODULE}`);
        this.partsModuleElm = this.element.querySelector(`.${CLASSES.PARTS_MODULE}`);
        this.accordionElm = this.element.querySelector(`.${CLASSES.ACCORDION_MODULE}`);
    }

    /**
     * @method renderUrgentSearch
     * @description Renders the search module for urgent recalls
     * @param {Object} content Localization object
     * @param {Array} statesData Data from recall-state.json
     * @param {Array} modelsData Data from recall-model.json
     */
    renderUrgentSearch(content, statesData, modelsData) {
        this.urgentSearch = new RecallsUrgentSearch(
            content, statesData, modelsData, this.onSubmitCallback);
        renderer.insert(this.urgentSearch.render(), this.searchModuleElm);
    }

    /**
     * @method renderAirbagModule
     * @description Renders the airbag module
     * @param {Array} chosenModel Array of vehicle models
     */
    renderAirbagModule(chosenModel) {
        this.airbagModule = new UrgentRecallsAirbag(this.content, chosenModel);
        renderer.insert(this.airbagModule.render(), this.airbagModuleElm);
    }

    /**
     * @method renderPartsModule
     * @description Renders the parts availability module
     * @param {Array} chosenModel Array of vehicle models
     * @param {Object} currentDate Current Date object
     */
    renderPartsModule(chosenModel) {
        this.partsModule = new UrgentRecallsParts(this.content, this.config, chosenModel);
        renderer.insert(this.partsModule.render(), this.partsModuleElm);
    }

    /**
     * @method onSubmitCallback
     * @description Callback function for when the user submits the search module
     * @param {Array} chosenModel Array of vehicle models
     */
    onSubmitCallback(chosenModel) {
        this.renderAirbagModule(chosenModel);
        this.renderPartsModule(chosenModel);
        scrollTo(this.airbagModuleElm.offsetTop);
    }

    /**
     * @method renderFAQAccordion
     * @description Parses the markup from template and generates an accordion
     */
    renderFAQAccordion() {
        accordionParser.createAccordion({ element: this.accordionElm });
    }

    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/* global mbVans */

// Constant dependencies
import { EVENTS } from 'Constants';

// Partial dependencies
import {
    InventoryInterceptorApi,
} from 'partials/inventory-interceptor';
import { UserProfile } from 'partials/profile';

/**
 * @constant CLASSES
 * @description Class list for ShoppingTools
 * @type {{INVENTORY_ITEM: string}}
 */
const CLASSES = {
    INVENTORY_ITEM: 'shopping-tools--inventory'
};

/**
 * @class ShoppingTools
 * @description Class responsible for intercepting click event on inventory links
 * within shopping tools and directing the click handlers to a InventoryInterceptor
 */
export default class ShoppingTools {
    /**
     * @constructor
     * @description On instantiation sets the instance properties, method aliases
     * caches its DOM element and attaches its events
     * @param element {Element} Header navigation element to intercept inventory links from
     */
    constructor(element) {
        this.element = element;
        this.profile = new UserProfile();
        this.inventoryConfig = window.mbVans.applicationProperties.inventoryConfig;

        this.inventoryInterceptor = new InventoryInterceptorApi(
            this.inventoryConfig
        );

        // method aliases
        this.onInventoryClick = this.onInventoryClick.bind(this);

        // initialize
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description Caches references to the inventory links in the element
     */
    cacheDOM() {
        this.inventoryLink = this.element.querySelector(`.${CLASSES.INVENTORY_ITEM} a`);
    }

    /**
     * @method attachEvents
     * @description Attaches click events and callbacks to the inventory links
     */
    attachEvents() {
        if (this.inventoryLink) {
            this.inventoryLink.addEventListener(EVENTS.CLICK, this.onInventoryClick);
        }
    }

    /**
     * @method onInventoryClick
     * @description Callback handler for inventoryLink element that applies
     * a method from the InventoryInterceptor to route the user to inventory
     * @param e {Event} Event object
     */
    onInventoryClick(e) {
        if (!(mbVans.ns('applicationProperties').inventoryConfig || {}).isClassicInventory) {
            e.preventDefault();
            this.inventoryInterceptor.goTo(true);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @function hasDealersChanged
 * @description Checks if the two array of dealers are the same
 * @param {Array} prevDealers - First set of dealers
 * @param {Array} nextDealers - Second set of dealers
 * @returns {Boolean} If the array of dealers are the same returns true; else false
 */
export function hasDealersChanged(prevDealers, nextDealers) {
    if (!prevDealers && nextDealers) {
        return true;
    }

    const prevDealersIDs = prevDealers ? prevDealers.map((dealer) => dealer.id).join('') : '';
    const nextDealersIDs = nextDealers ? nextDealers.map((dealer) => dealer.id).join('') : '';

    return prevDealersIDs !== nextDealersIDs;
}

export default {
    hasDealersChanged
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

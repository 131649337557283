import { screen, TabOutside } from 'utils';

import PanelList from './views/panels/PanelList';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    GLOBAL_HEADER_MENU_L3_CONTENT: 'global-header__menu-l3-content',
    GLOBAL_HEADER_MENU_L3_CONTENT_OPEN: 'global-header__menu-l3-content--open',
    GLOBAL_HEADER_MENU_L3_CONTENT_ACTIVE: 'global-header__menu-l3-content--active'
};

export default class LevelThreeMenu {
    constructor(element, levelOneMenuButtonElm) {
        this.element = element;
        this.levelOneMenuButtonElm = levelOneMenuButtonElm;
        this.focusableElms = [];
        this.init();
        this.afterInit();
    }

    init() {
        this.setBindings();
        this.cacheDOMElements();
        this.attachEvents();
        this.addPanelList();
        this.addTabOutsideHandler();
        this.state = {
            currentScreenState: screen.getCurrentState()
        };
    }

    /**
     * @method afterInit
     * @description to called after init method
     */
    afterInit() {
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onLevelThreeChildElementBlur = this.onLevelThreeChildElementBlur.bind(this);
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.buildFocusableElms();
    }

    /**
     * @method attachEvents
     * @description Attaches event listener
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
    }

    /**
     * @method detachEvents
     * @description detach events
     */
    detachEvents() {
        screen.addResizeListener(this.onScreenResize);
    }

    /**
     * @method addPanelList
     * @description creates a PanelList
     */
    addPanelList() {
        this.panelList = new PanelList(this.element);
    }

    /**
     * @method addTabOutsideHandler
     * @description creates a TabOutside Handler
     */
    addTabOutsideHandler() {
        this.tabOutsideHandler = new TabOutside(
            this.element,
            {
                onBlur: this.onLevelThreeChildElementBlur,
                onKeyDown: this.onKeyDown
            }
        );
    }

    /**
     * @method buildFocusableElms
     * @description gets all of the focusable elms within the level menu
     */
    buildFocusableElms() {
        this.focusableElms = this.element.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
    }

    /**
     * @method onLevelThreeChildElementBlur
     * @description handler for when  the first or last element in the level three menu
     *              blurs-- closes the menu; used for TabOutside
     */
    onLevelThreeChildElementBlur() {
        if (screen.gte(screen.SIZES.XLARGE) && this.tabOutsideHandler) {
            this.levelOneMenuButtonElm.click();
            this.levelOneMenuButtonElm.focus();
        }
    }

    /**
     * @method onKeyDown
     * @description handler to add the TabOutside Handler back
     */
    onKeyDown() {
        if (screen.gte(screen.SIZES.XLARGE)) {
            this.tabOutsideHandler.activate();
        }
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        const newScreenState = screen.getCurrentState();
        const { currentScreenState } = this.getState();
        if (currentScreenState !== newScreenState) {
            this.reset();
            this.updateState({ currentScreenState: newScreenState });
        }
    }

    /**
     * @method activateContent
     * @description activate corresponding content
     * @param index {int} index of corresponding content
     */
    activateContent(index) {
        this.element.classList.add(CLASSES.GLOBAL_HEADER_MENU_L3_CONTENT_OPEN);
        if (this.panelList) {
            this.panelList.activate(index || 0);
        }
    }

    /**
     * @method deactivateContent
     * @description deactivate corresponding content
     */
    deactivateContent() {
        this.element.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L3_CONTENT_OPEN);
        if (this.panelList) {
            this.panelList.deactivate();
        }
    }

    /**
     * @method activate
     * @description activate menu level three
     */
    activate() {
        this.element.classList.add(CLASSES.GLOBAL_HEADER_MENU_L3_CONTENT_ACTIVE);
    }

    /**
     * @method deactivate
     * @description deactivate menu level three
     */
    deactivate() {
        this.element.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L3_CONTENT_ACTIVE);
        this.deactivateContent();
    }

    /**
     * @method focusFirstElement
     * @description activate corresponding content
     */
    focusFirstElement() {
        if (screen.gte(screen.SIZES.XLARGE) && this.focusableElms.length) {
            this.focusableElms[0].focus();
        }
    }

    /**
     * @method reset
     * @description reset level three menu on screen resize
     */
    reset() {
        this.deactivate();
        this.deactivateContent();
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {object} new state value
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return this.state;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

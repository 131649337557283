// Partials
import { Modal } from 'partials/modal';

import { renderer } from 'utils';

// Local dependencies
import DealerForm from './DealerForm';

// Default Configuration
const defaultConfig = {
    callingContainer: null,
    dataAnalyticContainer: "{'blade': 'dealer-form-modal'}"
};

/**
 * @const MODAL_CONTAINER
 * @description Attribute of the Modal content container DOM element
 * @type {string}
 */
const MODAL_CONTAINER = 'data-modal-content';

/**
 * @class  DealerFormModal
 * @description  Display a modal that contains the DealerForm View
 * @param {Object} content
 * @param {Object} config
 */
export default class DealerFormModal {
    static FORM_TYPE = {
        ...DealerForm.FORM_TYPE
    };

    static SOURCE_TYPE = {
        ...DealerForm.SOURCE_TYPE
    };

    /**
     * @method constructor
     * @description  setup the initial state for the view
     */
    constructor({ modalConfig = defaultConfig, dealerFormConfig, content }) {
        this.config = modalConfig;
        this.modal = null;
        this.modalElm = null;
        this.dealerForm = new DealerForm(dealerFormConfig, content);
        this.onClose = this.onClose.bind(this);
    }

    /**
     * @method destroy
     * @description Destroys the Modal and DealerForm instances
     */
    destroy() {
        if (this.modal) {
            this.modal.destroy();
        }
        if (this.dealerForm) {
            this.dealerForm.destroy();
        }
    }

    /**
     * @method createModal
     * @description Creates a Modal instance and applies its properties
     */
    createModal() {
        this.modalElm = renderer.fromTemplate('<div class="dealer-form-modal"></div>');
        this.modalElm.appendChild(this.dealerForm.render());

        this.modal = new Modal(undefined, {
            modalContent: this.modalElm,
            modalTitle: this.dealerForm.getFormTitle(),
            callbacks: {
                beforeClose: this.onClose
            },
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.INSET,
            dataAnalyticContainer: this.config.dataAnalyticContainer
        });
    }

     /**
     * @method getModalContainer
     * @description Retrieves the modal container DOM node
     * @return {Element}
     */
    getModalContainer() {
        return document.querySelector(`[${MODAL_CONTAINER}]`);
    }

    /**
     * @method open
     * @description Calls a method to open the modal instance
     */
    open() {
        this.createModal();
        this.modal.open({ callingContainer: this.config.callingContainer });
    }

    /**
     * @method close
     * @description Calls a method to close the modal instance
     */
    close() {
        this.modal.close();
    }

    /**
     * @method onClose
     * @description Callback method applied when the modal closes that
     * destroys the dealerForm and modal, and applies the config.onClose callback
     */
    onClose() {
        this.destroy();

        if (this.config.onClose) {
            this.config.onClose();
        }
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { renderer } from 'utils';
import SimpleKeyboard from 'simple-keyboard';

// Local dependencies
import virtualKeyboardTemplate from './../templates/virtualKeyboardTemplate';
import { defaultKeyboardConfig } from './../config/defaultKeyboardLayout';


/**
 * @class SimpleForm
 * @description View component for displaying a SimpleForm
 */
export default class VirtualKeyboard {
    /**
     * @constructor
     * @description Creates a VirtualKeyboard
     * @param container
     * @param callbacks {Object} contains callback function
     * @param keyboardConfig {Object} contains the keyboard configurations -
     *        if no config is passed in, revert to defaultKeyboardConfig
     */
    constructor(container, callbacks, keyboardConfig = {}) {
        this.container = container;
        this.keyboardConfig = {
            ...defaultKeyboardConfig,
            ...{
                onChange: (input) => this.onChange(input),
                onKeyPress: (button) => this.onKeyPress(button)
            },
            ...keyboardConfig
        };
        this.availableLayouts = this.keyboardConfig.availableLayouts;
        this.callbacks = callbacks;
        this.isLocked = false; // caps lock
        this.init();
    }

    /**
     * @method init
     */
    init() {
        if (this.element === null || this.element === undefined) {
            this.createView();
            this.addKeyboard();
        }
    }

    /**
     * @method createView
     * @description Create view
     */
    createView() {
        this.element = virtualKeyboardTemplate({})({ getNode: true });
        renderer.append(this.element, this.container);
    }

    /**
     * @method addKeyboard
     * @description Create and add the Simple Keyboard instance
     */
    addKeyboard() {
        this.keyboard = new SimpleKeyboard(this.keyboardConfig);
    }

    /**
     * @method onChange
     * @description calls the onChange within the callback object when
     *              input is updated in the InputTextControl
     * @param input {String} the String input from InputTextControl
     */
    onChange(input) {
        if (this.callbacks &&
            this.callbacks.onChange &&
            typeof this.callbacks.onChange === 'function'
        ) {
            this.callbacks.onChange(input);
        }
    }

    /**
     * @method onKeyPress
     * @description updates the keyboard (or input) if a button is pressed
     *              on the virtual keyboard. If the key is shift/lock, it switched
     *              to/from ALL CAPS, otherwise call onKeyPress from the callback object
     * @param button {String} the button code in string format
     */
    onKeyPress(button) {
        switch (button.toLowerCase()) {
        case '{shift}':
            this.isLocked = false; // if caps locked, allow return to default
            this.setKeyboardLayout(this.availableLayouts.SHIFT);
            break;
        case '{lock}':
            this.isLocked = !this.isLocked; // set to prevent return to lowercase
            this.setKeyboardLayout(this.availableLayouts.SHIFT);
            break;
        case '{numbers}':
            this.setKeyboardLayout(this.availableLayouts.NUMBERS, true);
            break;
        case '{symbols}':
            this.setKeyboardLayout(this.availableLayouts.SYMBOLS, true);
            break;
        case '{abc}':
            this.setKeyboardLayout(this.availableLayouts.DEFAULT);
            break;
        default:
            this.onKeyPressAction(button);
        }
    }

    /**
     * @method onKeyPressAction
     * @description appends the key pressed to input if it is an actual key
     *              not a special key such as: "{space}", "{lock}"
     * @param button {String} button value
     */
    onKeyPressAction(button) {
        if (this.callbacks &&
            this.callbacks.onKeyPress &&
            typeof this.callbacks.onKeyPress === 'function'
        ) {
            this.callbacks.onKeyPress(button);
            // return the keys to default layout after a single keypress
            if (
                !this.isLocked &&
                this.keyboard.options.layoutName === this.availableLayouts.SHIFT
            ) {
                this.setKeyboardLayout(this.availableLayouts.DEFAULT);
            }
        }
    }

    /**
     * @method setKeyBoardLayout
     * @description allows the keyboard to be toggled between two keyboard layouts
     * @param newLayoutName {String} the new layout to switch the keyboard to
     * @param isSpecialCharacterLayout {Boolean} allows the layout to be
     *        toggled between two special character layouts
     */
    setKeyboardLayout(newLayoutName, isSpecialCharacterLayout = false) {
        let defaultLayout = this.availableLayouts.DEFAULT;

        // toggle special character keyboards
        if (isSpecialCharacterLayout &&
            this.keyboard.options.layoutName !== defaultLayout) {
            defaultLayout = this.availableLayouts.NUMBERS;
        }

        const layoutName =
            this.keyboard.options.layoutName === defaultLayout ? newLayoutName : defaultLayout;

        this.keyboard.setOptions({ layoutName });
    }

    /**
     * @method clearInput
     * @description clears the input in the InputTextControl when called
     */
    clearInput() {
        this.keyboard.clearInput();
    }

    /**
     * @method render
     * @description Return the element containing the simple form
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

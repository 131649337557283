// Util dependencies
import { htmlNode } from 'utils';

/**
 * @method renderOptions
 * @description renders radio button group and error message
 * @param {string} id - identifier of radio group
 */
function renderOptions(id, analyticsTrigger, alignHorizontal) {
    return `
        <div>
            <div class="form__radio-control-group ${alignHorizontal ? 'form__horizontal-radio' : ''}" ${analyticsTrigger ? `data-analytics-trigger="${analyticsTrigger}"` : ''}></div>
            <span class="form__input-error" id="error-${id}"></span>
        </div>
    `;
}

/**
 * @method renderLabelOptions
 * @description renders optional label and wraps radio group in a fieldset
 * @param {string} label - text of the label
 * @param {boolean} required - whether the field is required or not
 * @param {string} id - identifier of radio group
 */
function renderLabelOptions(label, required, id, analyticsTrigger, alignHorizontal, labelOffscreen) {
    return `
        <fieldset>
            <legend class="form__radio-control-group-label ${labelOffscreen ? 'offscreen' : ''}" ${required ? 'data-required' : ''}>
                <span class="form__radio-control-group-label-text">${label}</span>
            </legend>
            ${renderOptions(id, analyticsTrigger, alignHorizontal)}
        </fieldset>
    `;
}

/**
 * @description Template for custom radio button group control
 * Uses ES6 template literal to composite the markup for a group of custom radio button controls
 */
export default ({
    inputClassNames = '',
    label = '',
    required = false,
    id = '',
    analyticsTrigger = '',
    alignHorizontal,
    labelOffscreen = false
}) => htmlNode`
    <div class="form__radio-control-group-container ${inputClassNames ? `${inputClassNames}` : ''}">
        ${label ? renderLabelOptions(label, required, id, analyticsTrigger, alignHorizontal, labelOffscreen) : renderOptions(id, analyticsTrigger, alignHorizontal)}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

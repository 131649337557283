/**
 * @constant GLOBALHEADER
 * @description Collection of constants related to the Global Header
 */
export default {
    HEIGHT_COLLAPSED: {
        SMALL: 60,
        LARGE: 60,
        XLARGE: 80,
        XXLARGE: 80
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

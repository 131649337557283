import { htmlNode } from 'utils';

/**
 * @description Default template for SimpleFormSuccess view
 * @param   {String} headerText Text for the header
 * @param   {String} subHeader Text for the sub header
 * @param   {String} bodyText Text for the body
 * @param   {String} continueLabel Label for the continue button
 */
export default ({ header = '', subHeader = 'Thank you!', body = '', continueLabel = 'Continue', parentClass = 'simple-form-success', hasCta = true }) => htmlNode `
    <div class="${parentClass}">
        <div class="${parentClass}__header">
            ${header}
        </div>
        <div class="${parentClass}__body-container">
            <div class="${parentClass}__sub-header">${subHeader}</div>
            <p class="${parentClass}__body">${body}</p>
            ${hasCta ? `<div class="${parentClass}__continue-container">
                <button class="button button_primary ${parentClass}__continue-button">${continueLabel}</button>
            </div>` : ''}
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

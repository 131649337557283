import { htmlNode } from 'utils';

export default (content) => htmlNode`
    <div class="urgent-recalls-search ${content.isMerkley ? 'urgent-recalls-search--split' : ''}" data-analytics-container="{'blade': 'takata-search-module'}">
        <div class="urgent-recalls-search__form">
            <div class="${content.isMerkley && content.searchModule.merkleyHeader ? 'urgent-recalls-search__merkley-header' : ''}">${content.isMerkley && content.searchModule.merkleyHeader ? content.searchModule.merkleyHeader : ''}</div> 
            <div class="urgent-recalls-search__input">
                <div class="urgent-recalls-search--states-container">
                    <div class="urgent-recalls-search__states"></div>
                    <div class="urgent-recalls-search__note">${content.searchModule.note}</div>
                </div>
                <div class="urgent-recalls-search--model-container">
                    <div class="urgent-recalls-search__model-years"></div>
                    <div class="urgent-recalls-search__models"></div>
                </div>
            </div>
            <button class="urgent-recalls-search__cta button button_primary ${content.isMerkley ? 'button--wide' : 'button--full-width'}" data-analytics-trigger="cta-check-recall-status-submit">${content.searchModule.cta}</button>
            <div class="urgent-recalls-search__error"></div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for Tabs
 */
export default () => htmlNode`
    <div class="tabs">
        <div class="tabs__tab-list-container"></div>
        <div class="tabs__tab-panel-container"></div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Collection of FinanceDriver Credit App status strings
 */
export default {
    APPROVED: 'Approved',
    COUNTER_OFFER: 'CounterOffer',
    DECLINED: 'Declined',
    NONE: 'None',
    PENDING: 'Pending',
    SUBMITTED: 'App Submitted',
    UNAVAILABLE: 'unavailable'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Local dependencies
import DealerResult from './DealerResult';
import dealerResultsListTemplate from './../templates/dealerResultsListTemplate';

/**
 * @const CLASSES
 * Stores class names for use in the DOM
 */
const CLASSES = {
    RESULTS_CONTAINER: 'dealer-results-list__results-container',
    LOAD_MORE_CTA: 'dealer-results-list__load-more',
    HIDE: 'hide',
    BROWSE_ALL_CTA: 'dealer-locator-module__browse-all',
    END_RESULTS: 'dealer-results-list__results-end',
    NO_RESULTS: 'dealer-results-list__no-results',
    CTA_CONTAINER: 'dealer-results-list__cta-container',
    SERVICE_DOWN: 'dealer-results-list__service-down',
    SERVICE_DOWN_TWO: 'dealer-results-list__service-down-two',
    RESULT_NAME: 'dealer-result__dealer-name',
};

/**
 * @class DealerResultsList
 * @description Dealer results list view. This component accepts a list of dealers and
 * renders DealerResult component for each dealer.
 */
export default class DealerResultsList {
    /**
     * @constructor
     * @param {Array} dealers - Array of dealer objects
     * @param {Object} config - Config object passed from root component
     * @param {Object} content - Localization object
     * @param {Function} loadMoreCallback - Callback function for Load More CTA
     * @param {Boolean} serviceStatus - Boolean for whether the service is up
     */
    constructor(
        results,
        config,
        content,
        loadMoreCallback,
        browseAllCallback,
        dealerSelectCallback,
        serviceStatus = 'NO_ERROR') {
        this.dealers = [];
        this.config = config;
        this.content = content;
        this.dealerResults = [];
        this.loadMoreCallback = loadMoreCallback;
        this.dealerSelectCallback = dealerSelectCallback;
        this.element = dealerResultsListTemplate(content.nearbyLocations,
            config.featureFlags.showListHeading,
            config.featureFlags.showLoadMore,
            config.showResultsListCTA,
            content.showMoreLocations,
            content.serviceDownFirst,
            content.serviceDownSecond)({ getNode: true });

        this.cacheDOM();
        this.attachEvents();
        this.renderDealers(results, serviceStatus);
    }

    /**
     * @method destroy
     * @description Destroys instances of DealerResult, removes the element and empties the dealer results array
     */
    destroy() {
        this.dealerResults.forEach((dealerResult) => dealerResult.destroy());
        this.dealerResults = [];
        this.element.remove();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM element
     */
    cacheDOM() {
        this.loadMoreElm = this.element.querySelector(`.${CLASSES.LOAD_MORE_CTA}`);
        this.browseAllCTA = this.element.querySelector(`.${CLASSES.BROWSE_ALL_CTA}`);
        this.resultContainerElm = this.element.querySelector(`.${CLASSES.RESULTS_CONTAINER}`);
        this.endOfResultsElm = this.element.querySelector(`.${CLASSES.END_RESULTS}`);
        this.noResultsMsg = this.element.querySelector(`.${CLASSES.NO_RESULTS}`);
        this.ctaContainer = this.element.querySelector(`.${CLASSES.CTA_CONTAINER}`);
        this.serviceDownFirst = this.element.querySelector(`.${CLASSES.SERVICE_DOWN}`);
        this.serviceDownSecond = this.element.querySelector(`.${CLASSES.SERVICE_DOWN_TWO}`);
    }

    /**
     * @method attachEvents
     * @description Attaches an event listener for the Load More CTA to use their respective
     * callbacks
     */
    attachEvents() {
        if (this.config.featureFlags.showLoadMore && this.loadMoreElm) {
            this.loadMoreElm.addEventListener(EVENTS.CLICK, this.loadMoreCallback);
        }
    }

    /**
     * @method renderDealers
     * @param {Array} dealers - An array of dealer objects
     * @param {Boolean} serviceStatus - Boolean to determine if service is down
     * @description Renders a list of DealerResult for the dealers passed into the component
     */
    renderDealers(dealers, serviceStatus) {
        if (serviceStatus === 'SERVER_ERROR') {
            if (this.loadMoreElm) {
                this.loadMoreElm.classList.add(CLASSES.HIDE);
            }
            if (this.ctaContainer) {
                this.ctaContainer.classList.add(CLASSES.HIDE);
            }
            if (this.noResultsMsg) {
                this.noResultsMsg.classList.remove(CLASSES.HIDE);
            }

            // The informational text for when the service down has to be separated into different paragraphs
            this.serviceDownFirst.classList.remove(CLASSES.HIDE);
            this.serviceDownSecond.classList.remove(CLASSES.HIDE);
        } else if (serviceStatus === 'CLIENT_ERROR') {
            this.ctaContainer.classList.add(CLASSES.HIDE);
            this.noResultsMsg.classList.remove(CLASSES.HIDE);
            this.noResultsMsg.innerHTML = `<p class='dealer-results-list__no-results-copy'>${this.content.noResultsInformational || this.content.noResultsToView}</p>`;
        } else {
            dealers.forEach((dealer) => {
                const dealerResult = new DealerResult(
                    dealer,
                    {
                        showDistance: this.config.featureFlags.showDistance,
                        showHoursOfOp: this.config.featureFlags.showDealerHours,
                        showSecondaryCTA: this.config.featureFlags.showSecondaryCTA && dealer.isDealership,
                        showPhoneNumbers: this.config.featureFlags.showPhoneNumbers,
                        showArrowIndicator: this.config.featureFlags.showArrowIndicator,
                        showFullAddress: this.config.featureFlags.showFullAddress,
                        showVanCare: this.config.featureFlags.showVanCare,
                        displayLayout: this.config.displayLayout,
                        useScheduleServiceURL: this.config.useServiceURL,
                        inlinePreferredDealer: this.config.featureFlags.inlinePreferredDealer,
                        isPreferredHidden: dealer.isPreferred && !this.config.featureFlags.inlinePreferredDealer,
                    },
                    this.content,
                    this.dealerSelectCallback);

                this.resultContainerElm.appendChild(dealerResult.render());
                this.dealerResults.push(dealerResult);
                this.dealers.push({ ...dealer });
            });
        }
    }

    /**
     * @method endOfResultsMsg
     * @description Hides the load more CTA and adds end of results message
     */
    endOfResultsMsg() {
        this.loadMoreElm.classList.add(CLASSES.HIDE);
        this.endOfResultsElm.innerHTML = this.content.endOfResults;
    }

    /**
     * @method focusOnResult
     * @description Focus on first appended result address element for accessiblity
     * @param index {Number} number of the element index we need to target
     */
    focusOnResult(index) {
        this.dealerResults[index].element.querySelector(`.${CLASSES.RESULT_NAME}`).focus();
    }

    /**
     * @method setPreferredDealer
     * @description Sets the dealer with ID as preferred dealer
     * @param {string} dealerID - ID of dealer to set preferred dealer
     */
    setPreferredDealer(dealerID) {
        const filteredDealerResult = this.dealerResults.find(
            (dealerResult) => dealerResult.dealer.dealerId === dealerID
        );
        const filteredDealer = this.dealers.find((dealer) => dealer.dealerId === dealerID);

        if (filteredDealerResult) {
            filteredDealerResult.setPreferred(true);
            filteredDealer.isPreferred = true;
        }
    }

    /**
     * @method unsetPreferredDealer
     * @description Unsets the dealer with ID as preferred dealer
     * @param {string} dealerID - ID of dealer to set preferred dealer
     */
    unsetPreferredDealer(dealerID) {
        const filteredDealerResult = this.dealerResults.find(
            (dealerResult) => dealerResult.dealer.dealerId === dealerID
        );
        const filteredDealer = this.dealers.find((dealer) => dealer.dealerId === dealerID);

        if (filteredDealerResult) {
            filteredDealerResult.setPreferred(false);
            filteredDealer.isPreferred = false;
        }
    }

    /**
     * @method render
     * @description Renders the element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

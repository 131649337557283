// Constant dependencies
import { EVENTS } from 'Constants';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{ACTIVE: string, SLIDE_OVERLAY: string}}
 */
const CLASSES = {
    ACTIVE: 'carousel__slide--active',
    SLIDE_OVERLAY: 'carousel__slide-overlay'
};

/**
 * @class CarouselSlideClone
 * @description View responsible for cloning and managing the state a CarouselSlide
 */
export default class CarouselSlideClone {
    /**
     * @constructor
     * @description On instantiation, sets properties and attaches its events
     * @param carouselSlide {CarouselSlide} Instance of a CarouselSlide to clone
     */
    constructor(carouselSlide) {
        this.carouselSlide = carouselSlide;
        this.clonedSlide = this.carouselSlide.slide.cloneNode(true);
        this.clonedSlideOverlay = this.clonedSlide.querySelector(`.${CLASSES.SLIDE_OVERLAY}`);
        this.onFocus = this.onFocus.bind(this);

        this.cleanClonedSlide();
        this.attachEvents();
    }

    /**
     * @method attachEvents
     * @description Attaches events and callbacks to the clonedSlide
     */
    attachEvents() {
        this.clonedSlide.addEventListener(EVENTS.FOCUS, this.onFocus);
        this.clonedSlideOverlay.addEventListener(EVENTS.CLICK, this.onFocus);
    }

    /**
     * @method detachEvents
     * @description Removes events and callbacks to the slide
     */
    detachEvents() {
        this.clonedSlide.removeEventListener(EVENTS.FOCUS, this.onFocus);
        this.clonedSlideOverlay.removeEventListener(EVENTS.CLICK, this.onFocus);
    }

    /**
     * @method onFocus
     * @description triggers the `carouselSlide.onFocus` callback for when slide is focused on
     */
    onFocus() {
        this.carouselSlide.onFocus();
    }

    /**
     * @method destroy
     * @description Removes the cloned slide and the element
     */
    destroy() {
        this.detachEvents();
        this.clonedSlide.remove();
        this.clonedSlide = null;
    }

    /**
     * @method cleanClonedSlide
     * @description Cleans up any unneeded classes and sets tabIndex and aria-hidden attributes to avoid
     * a user from getting trapped in an infinite carousel via keyboard navigation
     */
    cleanClonedSlide() {
        this.clonedSlide.classList.remove(CLASSES.ACTIVE);
        this.clonedSlide.setAttribute('tabIndex', '-1');
        this.clonedSlide.setAttribute('aria-hidden', 'true');
    }

    /**
     * @method updateClonedSlide
     * @description Re-clones the `carouselSlide.slide` and replaces the
     * new clone in the DOM
     */
    updateClonedSlide() {
        const updatedSlide = this.carouselSlide.slide.cloneNode(true);

        this.detachEvents();
        this.clonedSlide.parentNode.replaceChild(updatedSlide, this.clonedSlide);
        this.clonedSlide = updatedSlide;
        this.cleanClonedSlide();
        this.attachEvents();
    }

    /**
     * @method render
     * @description Retrieves the clonedSlide DOM element
     * @return {Element|null} The slide DOM element
     */
    render() {
        return this.clonedSlide;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

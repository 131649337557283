import { htmlNode, tokenReplace } from 'utils';

/**
 * @function recallDetailInfoContainer
 * @description Recall Detail info container
 */
export const recallDetailInfoContainer = (content, recallInfo) => htmlNode`
    <div class="recalls-info" data-analytics-container="{'blade': 'recall-details'}">
        <header class="recalls-info__header">
            <h2 class="recalls-info__title">${recallInfo.year} ${recallInfo.model}</h2>
            <strong class="recalls-info__subhead">${content.heading.vin}: ${recallInfo.vin}</strong>
            <p class="recalls-info__heading-copy">${content.heading.copy}</p>
        </header>
        <div class="recalls-info__container"></div>
        <div class="recalls-info__takata-container"></div>
    </div>
`;

/**
 * @function recallInfoAccordion
 * @description Accordion component for each tab
 */
export const recallInfoAccordion = (content, recallList, brandCode, config) => htmlNode`
    <div data-accordion>
        ${recallList.map((recall, index) => `
            <div data-accordion-item data-expand-accordion-pane="${index === 0}">
                <h4 data-accordion-title>
                    <button data-accordion-button class="accordion__button">
                        ${content.title}: ${recall.mbusaId}
                    </button>
                </h4>
                <div data-accordion-pane>
                    <div class="recalls-detail" data-analytics-container="{'blade': 'recall-details-content'}">
                        <div class="recalls-detail__meta-info-list">
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.vehicleStatus}</dt>
                                    <dd>${recall.startDate ? recall.mfrRecallStatusDesc : content.recallPending}</dd>
                                </dl>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.campaignNumber}</dt>
                                    <dd>${recall.mbusaId}</dd>
                                </dl>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.recallNumber}</dt>
                                    <dd>${recall.nhtsaRecallNumber}</dd>
                                </dl>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.recallStartDate}</dt>
                                    <dd>${recall.startDate ? recall.startDate : content.recallPending}</dd>
                                </dl>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.recallReportedDate}</dt>
                                    <dd>${recall.recallDate}</dd>
                                </dl>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.component}</dt>
                                    <dd>${recall.component}</dd>
                                </dl>
                            </div>
                        </div>
                        <div class="recalls-detail__description">
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.summary}</dt>
                                    <dd>${recall.recallDescription}</dd>
                                </dl>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.consequence}</dt>
                                    <dd>${recall.safetyRiskDescription}</dd>
                                </dl>
                                <a class="recalls-detail__customer-letter link link_plain-link" href="${tokenReplace(config.customerLettersPathPrefix, [brandCode])}${recall.mbusaId}.pdf">${content.customerLetter}</a>
                            </div>
                            <div class="recalls-detail__meta-info">
                                <dl>
                                    <dt class="recalls-detail__meta-info-title">${content.remedy}</dt>
                                    <dd>${recall.remedyDescription}</dd>
                                </dl>
                            </div>
                            <a href="${config.dealerLocatorPage}" class="recalls-detail__cta button button_primary button--wide" data-analytics-trigger="cta">${content.findDealer}</a>
                        </div>
                    </div>
                </div>
            </div>
        `).join('')}
    </div>
`;

/**
 * @function recallTakataInfo
 * @description Takata info tout
 */
export const recallTakataInfo = (content, config) => htmlNode`
    <div class="recalls-info__takata">
        <div class="recalls-info__takata-info">
            <div class="recalls-info__takata-header">${content.takata.takataHeader}</div>
            <div class="recalls-info__takata-desc">${content.takata.takataDesc}</div>
            <a href="${config.takataPage}" class="recalls-detail__cta button button_primary button--wide" data-analytics-trigger="cta-recall-takata-more-info">${content.takata.learnMore.label}</a>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable import/prefer-default-export */

/**
 * @method getStructuredReservationContent
 * @description This function is for converting the default reserve content data which we get
 * from AEM to an object oriented content with status as keys (object with content data)
 * @param {Array} statusContentArr gets the list of array objects with the reserve vehicle
 * statuses
 * @returns {Object} reserve status content is modified object as per our need
 */
const getStructuredReservationContent = (statusContentArr) => {
    if (statusContentArr?.length > 0) {
        const statusObj = {};
        statusContentArr.forEach((item) => {
            if (item.pageType) {
                statusObj[item.status] = { ...statusObj[item.status] };
                statusObj[item.status][item.pageType] = item;
            } else {
                statusObj[item.status] = item;
            }
        });
        return statusObj;
    }
    return {};
};

export {
    getStructuredReservationContent
};

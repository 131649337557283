import { renderer } from 'utils';
import { EVENTS } from 'Constants';
import { SelectControl } from 'partials/select-control';
import recallsUrgentSearchTemplate from './../templates/recallsUrgentSearchTemplate';

/**
 * @const CLASSES
 * @description The defined classes to cache the dom
 * @type {Object}
 */
const CLASSES = {
    SEARCH_INPUT: 'urgent-recalls-search__input',
    STATES_INPUT: 'urgent-recalls-search__states',
    YEARS_INPUT: 'urgent-recalls-search__model-years',
    MODELS_INPUT: 'urgent-recalls-search__models',
    SUBMIT_CTA: 'urgent-recalls-search__cta',
    FORM_ERROR: 'urgent-recalls-search__error'
};

export default class RecallsUrgentSearch {
    constructor(content, statesData, modelsData, onSubmitCallback) {
        this.content = content;
        this.modelsData = modelsData;
        this.statesData = statesData;
        this.onSubmitCallback = onSubmitCallback;
        this.onSelectState = this.onSelectState.bind(this);
        this.onSelectYear = this.onSelectYear.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // A map with key as state, value as isZoneA
        this.statesDataMap = statesData.reduce((states, state) => {
            states[state.State] = state.isZoneA;
            return states;
        }, {});
        this.element = recallsUrgentSearchTemplate(content)({ getNode: true });

        this.cacheDOM();
        this.attachEvents();
        this.renderStateSelect(statesData);
        this.renderModelYear();
        this.renderModels();
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.searchInputElm = this.element.querySelector(`.${CLASSES.SEARCH_INPUT}`);
        this.serverErrorElm = this.element.querySelector(`.${CLASSES.FORM_ERROR}`);
        this.stateInputElm = this.element.querySelector(`.${CLASSES.STATES_INPUT}`);
        this.yearsInputElm = this.element.querySelector(`.${CLASSES.YEARS_INPUT}`);
        this.modelsInputElm = this.element.querySelector(`.${CLASSES.MODELS_INPUT}`);
        this.submitCtaElm = this.element.querySelector(`.${CLASSES.SUBMIT_CTA}`);
    }

    /**
     * @method attachEvents
     * @description Attach event handlers
     */
    attachEvents() {
        this.submitCtaElm.addEventListener(EVENTS.CLICK, this.onSubmit);
    }

    /**
     * @method renderStateSelect
     * @description Render the state input
     * @param {Object} statesData Data from recall-state.json
     */
    renderStateSelect(statesData) {
        const states = statesData.map((data) => ({ label: data.State, value: data.State }));

        this.vehicleState = new SelectControl(
            states,
            {
                id: 'vehicle-state',
                selectionCallback: this.onSelectState,
                labelText: this.content.searchModule.vehicleRegistration,
                labelSuppressed: true
            }
        );

        renderer.insert(this.vehicleState.render(), this.stateInputElm);
    }

    /**
     * @method onSelectState
     * @description Callback for when the user selects a state
     * @param {String} option Value of input selected
     */
    onSelectState(option) {
        const uniqueYears = this.modelsData
            .filter((data) => data.isZoneA === this.statesDataMap[option])
            .map((data) => data.MY)
            .unique();

        const mappedYears = uniqueYears.map((data) => ({ label: data, value: data }));
        this.renderModelYear(mappedYears);
        this.renderModels();
    }

    /**
     * @method renderModelYear
     * @description Render the model year input
     * @param {Object} modelYears Dropdown data for SelectControl
     */
    renderModelYear(modelYears) {
        this.modelYear = new SelectControl(
            modelYears,
            {
                id: 'model-year',
                selectionCallback: this.onSelectYear,
                labelText: this.content.searchModule.modelYear,
                labelSuppressed: true
            }
        );

        renderer.insert(this.modelYear.render(), this.yearsInputElm);
    }

    /**
     * @method onSelectYear
     * @description Callback for when the user selects a model year
     * @param {String} option Value of input selected
     */
    onSelectYear(option) {
        const uniqueModels = this.modelsData
            .filter((data) => data.MY === option && data.isZoneA === this.statesDataMap[this.vehicleState.getValue()])
            .map((data) => data.Model)
            .unique();

        const mappedModels = uniqueModels.map((model) => ({ label: model, value: model }));
        this.renderModels(mappedModels);
    }

    /**
     * @method renderModels
     * @description Render the model input
     * @param {Object} models Dropdown data for SelectControl
     */
    renderModels(models) {
        this.models = new SelectControl(
            models,
            {
                id: 'models',
                labelText: this.content.searchModule.model,
                labelSuppressed: true
            }
        );

        renderer.insert(this.models.render(), this.modelsInputElm);
    }

    /**
     * @method onSubmit
     * @description Callback for when the cta is clicked to render the airbag module
     */
    onSubmit() {
        const inputs = {
            state: this.vehicleState.getValue(),
            year: this.modelYear.getValue(),
            model: this.models.getValue()
        };

        const chosenModel = this.modelsData.filter((data) =>
            data.isZoneA === this.statesDataMap[inputs.state] &&
                data.MY === inputs.year && data.Model === inputs.model);

        chosenModel.forEach((model) => {
            model.state = inputs.state;
        });

        this.onSubmitCallback(chosenModel);
    }

    /**
     * @method render
     * @description Renders the search module
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

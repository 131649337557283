// Partial dependencies
import { ReCAPTCHA } from 'partials/recaptcha';
import {
    FormControl,
    FormError
} from 'partials/form-control';
import { AuthenticationApi } from 'partials/authentication';

// Util dependencies
import {
    formatNumber,
    cookie,
    noop,
    renderer,
    tokenReplace
} from 'utils';

// Local dependencies
import dealerFormTemplate from './../templates/dealerFormTemplate';
import DealerFormContactInfo from './DealerFormContactInfo';
import DealerFormComments from './DealerFormComments';
import DealerFormConfirmation from './DealerFormConfirmation';
import DealerFormTestDrive from './DealerFormTestDrive';
import formParser from './../api/formParser';
import leadsApi from './../api/leadsApi';
import SOURCE_TYPES from './../constants/sourceTypes';
import PREFILLABLE_FIELDS from './../constants/prefillableFormFields';
import defaultContent from './../mocks/dealer-form.json';

/**
 * @const defaultConfig
 * @type {{ userInfo: Object, Expected values firstName: string, lastName: string, email: string, phoneNumber: string }}
 * @type {{ country: string, country code }}
 * @type {{ currency: string, currency code }}
 * @type {{ dealer: Object, contains dealer information }}
 * @type {{ formType: string, type of dealer form }}
 * @type {{ isNewInventory: boolean, indicator if vehicle is new or cpo }}
 * @type {{ language: string, language code }}
 * @type {{ leadsEndpoint: string, value of form endpoint }}
 * @type {{ onSubmitCallback: *, function for callback when form is submitted }}
 * @type {{ vehicle: object, contains info about the vehicle }}
 */
const defaultConfig = {
    userInfo: null,
    country: 'us',
    currency: 'USD',
    dealer: null,
    formType: 'contact',
    sourceType: 'default',
    isNewInventory: true,
    language: 'en',
    leadsEndpoint: null,
    onSubmitCallback: noop,
    vehicle: null
};

/**
 * @const CLASSES
 * @description has the defined classes to cache the dom
 * @type {Object}
 */
const CLASSES = {
    ERROR_CONTAINER: 'dealer-form__error-message',
    FORM: 'dealer-form__form',
    SECTION_ONE: 'dealer-form__section-one',
    SECTION_TWO: 'dealer-form__section-two',
    RECAPTCHA: 'dealer-form__recaptcha',
    SUBMIT: 'dealer-form__submit',
};

/**
 * @const LOCATIONS
 * @description list of available location options for home test drive
 * @type {{HOME: string, DEALERSHIP: string}}
 */
const LOCATIONS = {
    HOME: 'home',
    DEALERSHIP: 'dealership'
};

/**
 * @class DealerForm
 * @description View for the dealer for scaffolding
 */
export default class DealerForm {
    /**
     * @static FORM_TYPE
     * @description Collection of formType properties that the view can be configure to
     * Note: these values are also used to associate the form source type to the provider keys
     * and the values match the keys from the SOURCE_TYPES constant
     * @type {{
     *   CONTACT: string,
     *   E_PRICE: string
     *   TEST_DRIVE: string
     *   REQUEST_QUOTE: string
     *   IM_INTERESTED: string
     * }}
     */
    static FORM_TYPE = {
        CONTACT: 'CONTACT_DEALER',
        E_PRICE: 'GET_E_PRICE',
        TEST_DRIVE: 'SCH_TEST_DRIVE',
        REQUEST_QUOTE: 'REQUEST_QUOTE',
        IM_INTERESTED: 'IM_INTERESTED'
    };

    /**
     * @static SOURCE_TYPE
     * @description Collection of sourceType properties that the view can be configure to.
     * Note: these values are used to associate the form source type to the provider keys
     * from the SOURCE_TYPES constant
     * IMPORTANT: ALL VALUES SHOULD BE IN LOWERCASE
     * @type {{
     *   BYO: string,
     *   CPO: string,
     *   DEFAULT: string,
     *   INVENTORY: string,
     *   PO_VEHICLE_RESERVATION: string,
     *   PROFILE: string,
     *   PROFILE_CPO: string,
     *   PROFILE_CREDIT: string,
     *   PROFILE_PRICE_SOON: string,
     *   SPECIAL_OFFERS: string,
     *   VEHICLE_RESERVATION: string,
     * }}
     */
    static SOURCE_TYPE = {
        BYO: 'byo',
        CPO: 'cpo',
        CPO_HOME: 'cpoHome',
        DEFAULT: 'default',
        INVENTORY: 'inventory',
        PO: 'po',
        PO_VEHICLE_RESERVATION: 'poReserve',
        PROFILE: 'profile',
        PROFILE_CPO: 'profileCpo',
        PROFILE_CREDIT: 'profileCreditApp',
        PROFILE_PRICE_SOON: 'profilepricesoon',
        SPECIAL_OFFERS: 'so',
        TRIM: 'trim',
        VEHICLE_RESERVATION: 'reserve'
    };

    /**
     * @method constructor
     * @description  set the state for the  dealer form
     * @param config: object
     * @param content: object
     */
    constructor(config, content) {
        this.content = { ...defaultContent, ...content };
        this.config = { ...defaultConfig, ...config };
        this.element = null;
        this.errorContainerElement = null;
        this.formInputs = {};
        this.hasHomeTestDrive = !!this.config.vehicle && this.config.vehicle.hasHomeTestDrive;
        this.isPosting = false;
        this.sectionOne = null;
        this.sectionOneElement = null;
        this.sectionTwo = null;
        this.sectionTwoElement = null;
        this.sectionRecaptcha = null;
        this.serverError = null;
        this.submitElememt = null;
        this.userInfo = config.userInfo ? { ...config.userInfo } : null;
        this.validationError = null;

        // method aliases
        this.setSubmitState = this.setSubmitState.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onInvalid = this.onInvalid.bind(this);
        this.toggleHomeTestDriveInputsRegistration = this.toggleHomeTestDriveInputsRegistration.bind(this);
        this.getProviderId = this.getProviderId.bind(this);

        // initialize view
        this.createElement();
        this.cacheDOM();
        this.createForm();
    }

    /**
     * @method destroy
     * @description  method to destroy the instance
     */
    destroy() {
        this.destroyFormSections();
        this.element.remove();
    }

    /**
     * @method destroyFormSections
     * @description Destroys the form section instances
     */
    destroyFormSections() {
        if (this.sectionOne) {
            this.sectionOne.destroy();
        }

        if (this.sectionTwo) {
            this.sectionTwo.destroy();
        }
    }

    /**
     * @method createElement
     * @description Creates the element for the view from a dealerFormTemplate
     */
    createElement() {
        const {
            footnote,
            submit: submitButtonLabelText
        } = this.content;
        const { dealer } = this.config;
        const title = this.getFormTitle();

        this.element = dealerFormTemplate({
            title,
            submitButtonLabelText,
            footnote: tokenReplace(footnote, { dealer: dealer.name }),
            disclaimer: {
                reference: this.content.homeTestDriveDisclaimerReference,
                description: this.content.homeTestDriveDisclaimerText
            },
            hasHomeTestDrive: this.hasHomeTestDrive
        })({ getNode: true });
    }

    /**
     * @method cacheDOM
     * @description  in charge of caching the elements from the DOM
     */
    cacheDOM() {
        this.errorElement = this.element.querySelector(`.${CLASSES.ERROR_CONTAINER}`);
        this.formElement = this.element.querySelector(`.${CLASSES.FORM}`);
        this.sectionOneElement = this.element.querySelector(`.${CLASSES.SECTION_ONE}`);
        this.sectionTwoElement = this.element.querySelector(`.${CLASSES.SECTION_TWO}`);
        this.sectionRecaptcha = this.element.querySelector(`.${CLASSES.RECAPTCHA}`);
        this.submitElememt = this.element.querySelector(`.${CLASSES.SUBMIT}`);
    }

    /**
     * @method getFormTitle
     * @description Checks form type to determine title from i18n content
     * @return {String} Form title
     */
    getFormTitle() {
        const {
            contactDealer,
            unlockEPrice,
            imInterested,
            scheduleTestDrive,
            requestAQuote,
        } = this.content;

        if (this.config.formType === DealerForm.FORM_TYPE.TEST_DRIVE) {
            return scheduleTestDrive;
        }

        if (this.config.formType === DealerForm.FORM_TYPE.E_PRICE) {
            return unlockEPrice;
        }

        if (this.config.formType === DealerForm.FORM_TYPE.REQUEST_QUOTE) {
            return requestAQuote;
        }

        if (this.config.formType === DealerForm.FORM_TYPE.IM_INTERESTED) {
            return imInterested;
        }

        return contactDealer;
    }

    /**
     * @method createForm
     * @description creating the main dealer form and appends to modal
     */
    createForm() {
        const formConfig = {
            element: this.formElement,
            onSubmit: this.onSubmit,
            onInvalid: this.onInvalid
        };

        this.form = new FormControl(formConfig);

        this.createContactForm();

        if (this.config.formType === DealerForm.FORM_TYPE.CONTACT) {
            this.createCommentsForm();
        }

        if (this.config.formType === DealerForm.FORM_TYPE.TEST_DRIVE ||
            this.config.formType === DealerForm.FORM_TYPE.E_PRICE ||
            this.config.formType === DealerForm.FORM_TYPE.REQUEST_QUOTE ||
            this.config.formType === DealerForm.FORM_TYPE.IM_INTERESTED) {
            this.createTestDriveForm();
        }

        this.registerInputs();
        if (this.hasHomeTestDrive && this.formInputs.locationPreference) {
            this.toggleHomeTestDriveInputsRegistration();
        }
        // render the form control to the dom
        this.element.appendChild(this.form.render());
    }

    /**
     * @method createContactForm
     * @description Creates an instance of DealerFormContactInfo,
     * appends it to section one of the form, and adds the DealerFormContactInfo
     * inputs to the formInputs
     */
    createContactForm() {
        this.sectionOne = new DealerFormContactInfo(this.content, this.userInfo);
        this.sectionOneElement.appendChild(this.sectionOne.render());
        this.formInputs = Object.assign(this.formInputs, this.sectionOne.getInputs());

        if (!this.userInfo) {
            this.autoPopulateAuthInfo();
        }
    }

    /**
     * @method getAuthInfo
     * @description Gets authenticated user's information from profile
     * returns {Object}
     */
    getAuthInfo() {
        const authApi = new AuthenticationApi();
        const authState = authApi.getAuthState();
        const isAuthenticated = authState.loggedState || authState.partialLoggedState;

        if (!isAuthenticated) {
            return Promise.resolve(null);
        }

        return authApi.getAuthProfile().then((authenticatedProfile) => {
            const info = {};
            const profileInfo = {
                ...authenticatedProfile
            };
            profileInfo.phone = authenticatedProfile.mobilePhoneNumber ||
                authenticatedProfile.landlinePhone ||
                authenticatedProfile.primaryPhoneNumber ||
                authenticatedProfile.secondaryPhoneNumber;

            PREFILLABLE_FIELDS.forEach((field) => {
                info[field] = profileInfo[field];
            });

            return info;
        });
    }

    /**
     * @method autoPopulateAuthInfo
     * @description Auto-populates the form with the auth profile info if available
     */
    autoPopulateAuthInfo() {
        this.getAuthInfo().then((profile) => {
            if (profile) {
                this.populateInputs(profile);
            }
        });
    }

    /**
     * @method populateInputs
     * @description Populates the form input with the userInfo
     * @param userInfo {Object} User information to populate form with
     */
    populateInputs(userInfo) {
        if (!userInfo) {
            return;
        }

        this.sectionOne.populateInputs(userInfo);
    }

    /**
     * @method createCommentsForm
     * @description Creates an instance of DealerFormComments,
     * appends it to section two of the form, and adds the DealerFormComments
     * inputs to the formInputs
     */
    createCommentsForm() {
        this.sectionTwo = new DealerFormComments(this.content);
        this.sectionTwoElement.appendChild(this.sectionTwo.render());
        this.formInputs = Object.assign(this.formInputs, this.sectionTwo.getInputs());
    }

    /**
     * @method createTestDriveForm
     * @description Creates an instance of DealerFormTestDrive,
     * including if home test drive is available and a callback to unregister the inputs of home test drive,
     * then appends it to section two of the form, and adds the DealerFormComments
     * inputs to the formInputs,
     */
    createTestDriveForm() {
        const scheduledFormTypes = [
            DealerForm.FORM_TYPE.E_PRICE,
            DealerForm.FORM_TYPE.REQUEST_QUOTE,
            DealerForm.FORM_TYPE.IM_INTERESTED
        ];
        const title = (scheduledFormTypes.includes(this.config.formType)) ?
            this.content.scheduleTestDrive : this.content.preferredDateAndTime;

        this.sectionTwo = new DealerFormTestDrive(
            this.content,
            this.config.language,
            title,
            this.hasHomeTestDrive,
            {
                onTestDriveToggle: this.toggleHomeTestDriveInputsRegistration
            }
        );
        this.sectionTwoElement.appendChild(this.sectionTwo.render());
        this.formInputs = Object.assign(this.formInputs, this.sectionTwo.getInputs());
    }

    /**
     * @method createRecaptcha
     * @description Creates an instance of ReCAPTCHA, appends it to the
     * ReCAPTCHA section of the form, and adds it to the formInputs
     */
    createRecaptcha() {
        const recaptcha = new ReCAPTCHA({
            errorMessage: this.content.recaptchaEmptyError,
            onSuccess: this.setSubmitState

        });

        Object.assign(this.formInputs, { recaptcha });
        this.sectionRecaptcha.appendChild(recaptcha.render());
    }

    /**
     * @method createSuccess
     * @description Creates an instance of confirmation success
     * and replaces form in dealer form modal
     */
    createSuccess() {
        const title = this.getFormTitle();

        this.destroyFormSections();

        this.success = new DealerFormConfirmation({
            body: this.content.successBody,
            disclaimer: this.content.successFootnote,
            title
        }, this.config.dealer);

        renderer.insert(this.success.render(), this.element);
    }

    /**
     * @method getProviderId
     * @description sets the provider ID for the lead submission
     */
    getProviderId() {
        const {
            config,
            formInputs,
            } = this;

        // this condition sets the provider ID when home delivery is available and has been selected
        // within CPO SATD form, same logic can be applied if more locations are added
        const sourceType = (
            formInputs.locationPreference &&
            formInputs.locationPreference.getValue() === 'home' &&
            config.formType === DealerForm.FORM_TYPE.TEST_DRIVE &&
            config.sourceType === DealerForm.SOURCE_TYPE.CPO
        ) ? DealerForm.SOURCE_TYPE.CPO_HOME : config.sourceType;

        return config.providers[SOURCE_TYPES[config.formType][sourceType]];
    }

    /**
     * @method toggleHomeTestDriveInputsRegistration
     * @description callback to toggle registration the home test drive address inputs
     */
    toggleHomeTestDriveInputsRegistration() {
        const isHomeTestDrive = this.formInputs.locationPreference.getValue() === 'home';
        let formAction = null;

        if (isHomeTestDrive && this.hasHomeTestDrive) {
            formAction = 'register';
        } else if (this.hasHomeTestDrive) {
            formAction = 'unregister';
        }

        if (formAction) {
            this.form[formAction](this.formInputs.homeTestDriveAddress);
            this.form[formAction](this.formInputs.homeTestDriveCity);
            this.form[formAction](this.formInputs.homeTestDriveState);
            this.form[formAction](this.formInputs.homeTestDriveZip);
        }
    }

    /**
     * @method registerInputs
     * @description loops through all inputs and registers them to the form
     */
    registerInputs() {
        Object.values(this.formInputs).forEach((formInput) => {
            this.form.register(formInput);
        });
    }

    /**
     * @method setSubmitState
     * @description If reCAPTCHA checkbox is checked, enable the submit button,
     * otherwise disable the submit button
     */
    setSubmitState() {
        const enableSubmit = this.formInputs.recaptcha.isValid();
        this.submitElememt.classList[enableSubmit ? 'remove' : 'add']('disabled');
    }

    /**
     * @method onSubmit
     * @description Callback for form submission after passing validation
     */
    onSubmit() {
        const {
            config
        } = this;

        // Destroying the instance of validationError if the form valid upon submit
        if (this.validationError) {
            this.hideValidationError();
        }

        const requestObj = this.getRequestObject();

        if (!this.isPosting) {
            this.isPosting = true;

            leadsApi.submitLead(config.leadsEndpoint, config.country, config.brand, requestObj)
                .then(this.onSubmitSuccess.bind(this))
                .catch((e) => {
                    console.error(e);
                    this.onSubmitError();
                    this.isPosting = false;
                });
        }
    }

    /**
     * @method getRequestObject
     * @description Parses the formInputs and constructs a leads request object
     * based on the config.formType
     * @return {{prospect}|*}
     */
    getRequestObject() {
        const {
            config,
            formInputs,
            content
        } = this;
        const formObject = {};

        // base request object properties
        formObject.dealerInfo = this.config.dealer;
        formObject.email = formInputs.email.getValue();
        formObject.firstName = formInputs.firstName.getValue();
        formObject.isContactByPhone = formInputs.preferredContact.getValue() === 'phone';
        formObject.isHomeDelivery = formInputs.locationPreference ? formInputs.locationPreference.getValue() === 'home' : null;
        formObject.lastName = formInputs.lastName.getValue();
        formObject.options = {
            isCPO: !config.isNewInventory,
            currency: config.currency,
            language: config.language,
            webId: cookie.getCookie('MBUSA_WEB_ID'),
            provider: this.getProviderId(),
            content,
        };
        formObject.phoneNumber = formatNumber.toNumber(formInputs.phone.getValue()).toString();

        // add formType specific fields
        if (config.formType === DealerForm.FORM_TYPE.CONTACT) {
            formObject.comments = formInputs.comments.getValue();
        }

        if (config.formType === DealerForm.FORM_TYPE.TEST_DRIVE ||
            config.formType === DealerForm.FORM_TYPE.E_PRICE) {
            formObject.testDriveDate = formInputs.testDriveDate.getValue();
            formObject.testDriveTime = formInputs.testDriveTime.getValue();
        }

        if (this.formInputs.locationPreference && this.formInputs.locationPreference.getValue() === LOCATIONS.HOME) {
            formObject.testDriveMethod = this.formInputs.locationPreference.getValue();
            formObject.testDriveLocation = `${formInputs.homeTestDriveAddress.getValue()}, ${formInputs.homeTestDriveCity.getValue()}, ${formInputs.homeTestDriveState.getValue()}, ${formInputs.homeTestDriveZip.getValue()}`;
        } else if (this.formInputs.locationPreference) {
            formObject.testDriveMethod = this.formInputs.locationPreference.getValue();
        }

        if (config.vehicle) {
            const { vehicle } = this.config;

            formObject.chosenVehicle = {
                available: vehicle.available,
                model: {
                    code: vehicle.modelId,
                    class: vehicle.classId,
                    exteriorColor: vehicle.exteriorMetaColor,
                    interiorColor: vehicle.interiorMetaColor,
                    msrp: vehicle.msrp.price,
                    vin: vehicle.vin,
                    year: vehicle.year,
                    stockId: vehicle.stockId
                }
            };
        }

        return formParser.createRequestObject(formObject);
    }

    /**
     * @method onInvalid
     * @description callback for when form validation fails
     */
    onInvalid() {
        this.showValidationError();
    }

    /**
     * @method onSubmitSuccess
     * @description When a form has successfully submitted, renders a DealerFormConfirmation view
     */
    onSubmitSuccess() {
        this.createSuccess();
    }

    /**
     * @method onSubmitError
     * @description Callback method applied when a lead form unsuccessfully submits
     */
    onSubmitError() {
        this.showServerError();
    }

    /**
     * @method showValidationError
     * @description Instantiates FormError message field and renders it
     */
    showValidationError() {
        if (!this.validationError) {
            this.validationError = new FormError({
                description: this.content.validationError
            });
        }

        if (this.serverError) {
            this.hideServerError();
        }

        this.validationError.show(this.errorElement);
    }

    /**
     * @method hideValidationError
     * @description Removes the validationError message field
     */
    hideValidationError() {
        this.validationError.hide();
    }

    /**
     * @method showServerError
     * @description show message when is an error on the server side
     */
    showServerError() {
        if (!this.serverError) {
            this.serverError = new FormError({
                title: this.content.serverError.title,
                description: this.content.serverError.description
            });
        }

        if (this.validationError) {
            this.hideValidationError();
        }

        this.serverError.show(this.errorElement);
    }

    /**
     * @method hideServerError
     * @description Remove the serverError
     */
    hideServerError() {
        this.serverError.hide();
    }

    /**
     * @method render
     * @description  return the DOM element
     * @return {XML}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { html, generateUniqueID } from 'utils';

/**
 * @description Template for tile gallery
 * Uses ES6 template literal to composite the markup for a gallery made of tiles
 */
export default () => html`
    <div class="gallery" id="gallery_${generateUniqueID()}">
        <div class="gallery__container"
             data-gallery-items>
        </div>
    </div>
`.trim();

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// used modules
import { YouTubeModalContent } from 'partials/youtube';

// Local templates
import youtubeVideoModalTemplate from './../templates/youtubeVideoModalTemplate';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {YOUTUBE_CONTAINER: string}
 */
const CLASSES = {
    YOUTUBE_CONTAINER: 'youtube-modal__outer-container'
};

/**
 * @class  YouTubeVideoModalContent
 * @description  class to manage the YouTube video modal view
 */
export default class YouTubeVideoModalContent {
    /**
     * @method  constructor
     * @description  create the YouTubeVideoModalContent view based on template
     * @param {String} videoId
     * @param {String} videoTranscript
     * @param {Object} details
     */
    constructor({
        videoId,
        videoTranscript,
        details
    }) {
        this.videoId = videoId;
        this.videoTranscript = videoTranscript;
        this.details = details;
        this.init();
    }

    /**
     * @method  init
     * @description initilize the class
     */
    init() {
        this.setBindings();
        this.createTemplate();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.initialize = this.initialize.bind(this);
        this.beforeYouTubeModalClose = this.beforeYouTubeModalClose.bind(this);
    }

    /**
     * @method  createTemplate
     * @description  checks for disclaimers and Initializes it
     */
    createTemplate() {
        this.element = youtubeVideoModalTemplate({
            videoId: this.videoId,
            videoTranscript: this.videoTranscript,
            ...this.details
        })({ getNode: true });

        const youTubeElement = this.element.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);
        this.youtube = new YouTubeModalContent({
            videoId: this.videoId,
            videoTranscript: this.videoTranscript,
            youTubeElement
        });
    }

    /**
     * @method getElement
     * @returns {*} DOM element
     */
    getElement() {
        return this.element;
    }

    /**
     * @method initialize
     * @description Calls YouTubeModalContent.initialize()
     */
    initialize() {
        this.youtube.initialize();
    }

    /**
     * @method beforeYouTubeModalClose
     * @description callback method for when modal will close
     */
    beforeYouTubeModalClose() {
        this.youtube.beforeYouTubeModalClose();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

import { renderer, isScrolledIntoView } from 'utils';

// Local dependencies
import formErrorTemplate from './../templates/formErrorTemplate';
import formErrorsTemplate from './../templates/formErrorsTemplate';

/**
 * @const CLASSES
 * @description classes to use for DOM parsing
 * @type {{ CTA: string }}
 */
const CLASSES = {
    CTA: 'form-error-message__cta-link'
};

/**
 * @const defaultConfig
 * @description Default configuration options for an error message
 * @type defaultConfig.ctaLabel {string} Option CTA label
 * @type defaultConfig.description {string} Text for description section
 * @type defaultConfig.errors {Array} Option list of error message objects with description and title properties
 * @type defaultConfig.title {string} Text for title section
 * @type {{
 *   ctaLabel: string,
 *   title: text,
 *   description: text}}
 */
const defaultConfig = {
    ctaLabel: '',
    description: '',
    errors: null,
    title: ''
};

/**
 * @class FormError
 * @description View component for displaying an FormError
 */
export default class FormError {
    /**
     * Create a FormError
     * @param config {Object} Configuration data
     */
    constructor(config = defaultConfig) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.init();
    }

    /**
     * @method init
     */
    init() {
        if (this.element === null || this.element === undefined) {
            this.createView();
            this.cacheDOM();
            this.attachEvents();
        }
    }

    /**
     * @method createView
     * @description Create view from a template based on the error types (single or multiple errors)
     */
    createView() {
        if (this.config.errors) {
            this.element = formErrorsTemplate({
                errors: this.config.errors
            })({ getNode: true });
        } else {
            this.element = formErrorTemplate({
                ctaLabel: this.config.ctaLabel || '',
                title: this.config.title,
                description: this.config.description
            })({ getNode: true });
        }
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.ctaButton = this.element.querySelector(`.${CLASSES.CTA}`);
    }

    /**
     * @method attachEvents
     * @description Adds a click event listener and callback to the View
     */
    attachEvents() {
        if (this.ctaButton) {
            this.ctaButton.addEventListener(EVENTS.CLICK, this.config.ctaCallback);
        }
    }

    /**
     * @method detachEvents
     * @description Removes the click event listener and callback from the View
     */
    detachEvents() {
        if (this.ctaButton) {
            this.ctaButton.removeEventListener(EVENTS.CLICK, this.config.ctaCallback);
        }
    }

    /**
     * @method destroy
     */
    destroy() {
        if (this.element) {
            this.element.remove();
            this.element = null;
            this.detachEvents();
        }
    }

    /**
     * @method show
     * @description Renders the ErrorMessage
     */
    show(elementVal) {
        renderer.append(this.render(), elementVal);
        // if the element is not already in view, scroll so that it is.
        if (!isScrolledIntoView(this.element)) {
            this.element.scrollIntoView(false);
        }
    }

    /**
     * @method hide
     * @description Removes the ErrorMessage from DOM
     */
    hide() {
        this.element.remove();
    }

    /**
     * @method render
     * @description Return the element containing the error message
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import {
    htmlNode,
    tokenReplace
} from 'utils';

// Local dependencies
import inventoryRecentlyViewedApi from './../api/inventoryRecentlyViewedApi';

export default ({
    continueText = '',
    continuePath = '',
    dismiss = '',
    header = '',
    vehicle = {}
}) => htmlNode`
    <div class="irv-banner" data-analytics-container='${inventoryRecentlyViewedApi.getAnalyticsContainer(
        'inventory-recently-viewed-banner',
        vehicle,
        vehicle.vin,
        vehicle.dealer
    )}'>
        <div class="irv-banner__content wrapper">
            <div class="irv-banner__header">
                <h2 class="irv-banner__header-text">${tokenReplace(header, { headerText: vehicle.headerText })}</h2>
                <a href="${continuePath}" class="button button_call-out irv-banner__button irv-banner__button--continue">
                    ${continueText}
                </a>
            </div>
            <div>
                <button class="button irv-banner__button irv-banner__button--dismiss">
                    ${dismiss}<span class='icon-mb icon-exit irv-banner__button--icon'></span>
                </button>
            </div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

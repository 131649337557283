// Partial dependencies
import { DealerSearchBar } from 'partials/dealer-search-bar';

// Util dependencies
import { renderer } from 'utils';

// Local dependencies
import dealerSelectorLocationSearchTemplate from './../templates/dealerSelectorLocationSearchTemplate';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{DEALER_SEARCH_BAR: string}}
 */
const ATTRIBUTES = {
    DEALER_SEARCH_BAR: 'data-dealer-search-bar'
};

/**
 * View responsible for rendering the DealerSelectorLocationSearch module
 */
export default class DealerSelectorLocationSearch {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     * @param content {object} Object with key/value pairs containing authorable content fields
     * @param config {object} Configurable options containing:
     *        country {string} Page data country code
     * @param onLocationSelect {function} Callback when a location is selected
     */
    constructor({ content = {}, config = {}, onLocationSelect }) {
        this.content = content;
        this.config = config;
        this.onLocationSelect = onLocationSelect;
        this.element = dealerSelectorLocationSearchTemplate(
                this.content
            )({ getNode: true }); // DOM element to render
        this.cacheDOM();
        this.renderLocationSearchBar();
    }

    /**
     * @method cacheDOM
     * @description Caches reference to DOM elements from the view
     */
    cacheDOM() {
        this.dealerSearchBarElm = this.element.querySelector(`[${ATTRIBUTES.DEALER_SEARCH_BAR}]`);
    }

    /**
     * @method renderLocationSearchBar
     * @description Creates the Search Bar instance and renders it to DOM element
     */
    renderLocationSearchBar() {
        this.searchBar = new DealerSearchBar({
            autoSubmitLocation: false,
            buttonStyle: 'button button_primary button--wide dealer-selector-location-search__button',
            country: this.config.country,
            ctaLabel: this.content.findDealer,
            dealerSearchErrorMessage: this.content.locationSearchErrorMessage,
            defaultLocation: this.config.defaultLocation,
            onSubmit: this.onSubmit.bind(this),
            searchInputLabel: this.content.searchInputLabel
        });

        renderer.insert(this.searchBar.render(), this.dealerSearchBarElm);
    }

    /**
     * @method onSubmit
     * @description Callback when a location is selected
     * @param location {Object} Location object which includes the search type and location
     */
    onSubmit(location) {
        this.onLocationSelect(location);
    }

    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.searchBar.destroy();
        this.element.remove();
    }

    /**
     * @method render
     * @description Renders the element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

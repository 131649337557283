import { YouTube } from 'partials/youtube';

class VideoPromo {
    constructor(element) {
        this.element = element;
        this.videoId = this.element.dataset.videoId;
        this.cacheDOMElements.bind(this)();
        this.init.bind(this)();
    }

    init() {
        const player = new YouTube(this.videoId, this.videoContainer, (isEnded) => {
            // 'playing' css class controls fading the video and preivew images in/out.
            // Internet Explorer 11 and below do not support a second argument to `toggle`
            // videoModule.classList.toggle('playing', !isEnded);
            this.onStageChange(isEnded);
            this.element.classList[isEnded ? 'remove' : 'add']('playing');
        });

        return player;
    }

    cacheDOMElements() {
        this.videoContainer = this.element.querySelector('.video-promo__youtube-container');
        this.subTitle = this.element.querySelector('.video-promo__subtitle');
        return this;
    }

    onStageChange(isEnded) {
        if (this.subTitle) {
            if (isEnded) {
                this.subTitle.style.zIndex = 10;
                return;
            }
            this.subTitle.style.zIndex = 0;
        }
    }
}
export default VideoPromo;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

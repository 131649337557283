/**
 * @const
 * @description Collection of events for a form control
 */
export default {
    REGISTER: 'FORM_CONTROL:REGISTER',
    UNREGISTER: 'FORM_CONTROL:UNREGISTER',
    INPUT_CHANGE: 'FORM_CONTROL:INPUT_CHANGE'
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Utils
import { findAncestor } from 'utils';

// Constant dependencies
import { EVENTS, KEYBOARD } from 'Constants';

// Local dependencies
import appConfig from './../config';
import dealerResultTemplate from './../templates/dealerResultTemplate';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    HEADER_LINK: 'dealer-result__address-heading',
    SLIDER: 'dealer-result__slider',
    SECONDARY_CTA: 'dealer-result__secondaryCTA'
};

export default class DealerResult {
    /**
     * @constructor
     * @description Creates new DealerSearchMap
     * @param element {Node} Element to render the map to
     * @param config {Object}
     * @param content {Object} - Localization object
     * @param callback {Function} on click event for dealer detail view on address click
     */
    constructor(dealer, config, content, dealerSelectCallback) {
        this.dealer = dealer;
        this.config = config;
        this.content = content;
        this.dealerSelectCallback = dealerSelectCallback;

        this.dealerResultEventHandler = this.dealerResultEventHandler.bind(this);
        this.dealerResultKeyEventHandler = this.dealerResultKeyEventHandler.bind(this);
        this.element = dealerResultTemplate(dealer, config, content, this.getCTALink(),
            this.getSecondaryCTALink())({ getNode: true });
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method destroy
     * @description Destroys the instance. This should also remove any event listeners
     */
    destroy() {
        this.element.remove();
        this.detachEvents();
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.headerLink = this.element.querySelector(`.${CLASSES.HEADER_LINK}`);
        this.slider = this.element.querySelector(`.${CLASSES.SLIDER}`);
        this.secondaryCTA = this.element.querySelector(`.${CLASSES.SECONDARY_CTA}`);
    }


    /**
     * @method attachEvents
     * @description Attaches an event listener on the dealer result element
     */
    attachEvents() {
        if (this.dealerSelectCallback) {
            this.element.addEventListener(EVENTS.CLICK, (event) => {
                if (!findAncestor(event.target, `.${CLASSES.SLIDER}`) && event.target !== this.secondaryCTA) {
                    this.dealerSelectCallback(this.dealer);
                }
            });
            this.element.addEventListener(EVENTS.KEYDOWN, (event) => {
                if (event.keyCode === KEYBOARD.ENTER || event.keyCode === KEYBOARD.SPACE) {
                    this.dealerSelectCallback(this.dealer);
                }
            });
        }
    }

    /**
     * @method detachEvents
     * @description Removes the event listener on the dealer result element
     */
    detachEvents() {
        if (this.dealerSelectCallback) {
            this.element.removeEventListener(EVENTS.CLICK, this.dealerResultEventHandler);
            this.element.removeEventListener(EVENTS.KEYDOWN, this.dealerResultKeyEventHandler);
        }
    }

    /**
     * @method dealerResultEventHandler
     * @param {*} event
     * @description Click handler for the dealer result module not including the badges and secondary CTA
     */
    dealerResultEventHandler(event) {
        if (!findAncestor(event.target, `.${CLASSES.SLIDER}`) && event.target !== this.secondaryCTA) {
            this.dealerSelectCallback(this.dealer);
        }
    }

    /**
     * @method dealerResultKeyEventHandler
     * @param {*} event
     * @description Click handler for the dealer when focused and enter is keyed
     */
    dealerResultKeyEventHandler(event) {
        if (event.keyCode === KEYBOARD.ENTER || event.keyCode === KEYBOARD.SPACE) {
            this.dealerSelectCallback(this.dealer);
        }
    }

    /**
     * @method getCTALink
     * @description Function returns url to pass into result template
     * @returns {Object} An object with text & href as properties.
     * For schedule service get the schedule service URL, if dealer has inventory, use inventory URL.
     * By default use dealer website.
     */
    getCTALink() {
        const linkObject = {
            text: this.content.viewWebsite,
            href: this.dealer.url
        };

        if (this.config.useScheduleServiceURL) {
            linkObject.href = this.dealer.scheduleServiceUrl !== 'NO_SCHEDULE_SERVICE_URL' ? this.dealer.scheduleServiceUrl : null;
            linkObject.text = this.content.dealerMainCTA;
        } else if (this.dealer.hasInventory) {
            linkObject.href = this.dealer.inventoryUrl;
            linkObject.text = this.content.dealerMainCTA;
        }

        return linkObject;
    }

    /**
     * @method getSecondaryCTALink
     * @description Gets the secondary CTA URL
     * @returns {String} For schedule service return no URL, otherwise return Schedule Service URL
     */
    getSecondaryCTALink() {
        if (this.config.useScheduleServiceURL) {
            return null;
        }

        const {
            leads: {
                cta: {
                    sourceType,
                    uri,
                    text,
                } = {}
            } = {}
        } = appConfig;

        const {
            dealerId
        } = this.dealer;

        const scheduleTestDriveUrl = uri;
        const scheduleTestDriveUrlHash = [
            `dealerId=${dealerId}`,
            `src=${sourceType}`,
        ];

        return {
            href: `${scheduleTestDriveUrl}?${scheduleTestDriveUrlHash.join('&')}`,
            text
        };
    }

    /**
     * @method setPreferred
     * @description Sets preferred flag for the dealer
     * @param {boolean} isPreferred - Flag to indicate if the current instance should be preferred
     */
    setPreferred(isPreferred) {
        this.dealer.isPreferred = isPreferred;
        this.element.classList[isPreferred ? 'add' : 'remove']('hide');
    }

    /**
     * @method render
     * @description Renders the dealer result module
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

import { screen } from 'utils';

/**
 * @const ATTRIBUTES
 * @description DOM element attribute references
 * @type {{DATA_SRC: string, SRC: string, PLAY_STATE: string}}
 */
const ATTRIBUTES = {
    DATA_SRC: 'data-src-',
    SRC: 'src',
    PLAY_STATE: 'data-play-state'
};

/**
 * @const ELEMENTS
 * @description DOM element type references
 * @type {{SOURCE: string}}
 */
const ELEMENTS = {
    SOURCE: 'source'
};

/**
 * @const CONSTANTS
 * @type {{LARGE: string, SMALL: string}}
 */
const CONSTANTS = {
    LARGE: 'large',
    SMALL: 'small'
};

/**
 * @const CLASSES
 * @description Class names associated with the component
 * @type {{
 *      VIDEO: string,
 *      BUTTON: string,
 *      BUTTON_PLAY: string,
 *      LABEL_PLAY: string,
 *      LABEL_PAUSE: string,
 *      HIDE: string
 * }}
 */
const CLASSES = {
    VIDEO: 'html5-video-control__video',
    BUTTON: 'html5-video-control__button',
    BUTTON_PLAY: 'html5-video-control__button--play',
    LABEL_PLAY: 'html5-video-control__button-label--play',
    LABEL_PAUSE: 'html5-video-control__button-label--pause',
    HIDE: 'hide'
};

/**
 * @const STATES
 * @description Collection of play state values
 * @type {{PLAYING: string, PAUSED: string}}
 */
const STATES = {
    PLAYING: 'playing',
    PAUSED: 'paused'
};

/**
 * @const ACTIONS
 * @type {{
 *      PLAY: string,
 *      PAUSE: string,
 *      ADD: string,
 *      REMOVE: string
 * }}
 */
const ACTIONS = {
    PLAY: 'play',
    PAUSE: 'pause',
    ADD: 'add',
    REMOVE: 'remove'
};

/**
 * @class HTML5VideoControl
 * @description Component for HTML video control
 */
export default class HTML5VideoControl {
    constructor(element) {
        this.element = element;
        this.state = {
            currentScreenState: null,
            currentScreenSize: null,
            playing: true
        };

        this.init();
    }

    /**
     * @method init
     * @description initialize the component
     */
    init() {
        this.setBindings();
        this.cacheDOMElements();
        this.attachEvents();
        if (this.videoElm && this.videoElm.hasAttribute(`${ATTRIBUTES.DATA_SRC}${CONSTANTS.SMALL}`)) {
            this.onScreenResize();
        }
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onControlClick = this.onControlClick.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onAbort = this.onAbort.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }

    /**
     * @method cacheDOMElements
     * @description Caches references to DOM elements of the component
     */
    cacheDOMElements() {
        this.videoElm = this.element.querySelector(`.${CLASSES.VIDEO}`);
        this.playPauseButtonElm = this.element.querySelector(`.${CLASSES.BUTTON}`);
        this.playLabelElm = this.element.querySelector(`.${CLASSES.LABEL_PLAY}`);
        this.pauseLabelElm = this.element.querySelector(`.${CLASSES.LABEL_PAUSE}`);
        if (this.videoElm) {
            this.sourceElm = this.videoElm.querySelector(ELEMENTS.SOURCE);
        }
    }

    /**
     * @method attachEvents
     * @description Adds event listeners
     */
    attachEvents() {
        if (this.playPauseButtonElm) {
            this.playPauseButtonElm.addEventListener(EVENTS.CLICK, this.onControlClick);
        }
        if (this.videoElm) {
            this.videoElm.addEventListener(EVENTS.ABORT, this.onAbort);
            this.videoElm.addEventListener(EVENTS.PLAY, this.onPlay);
            this.videoElm.addEventListener(EVENTS.ENDED, this.onEnded);
            if (this.videoElm.hasAttribute(`${ATTRIBUTES.DATA_SRC}${CONSTANTS.SMALL}`)) {
                screen.addResizeListener(this.onScreenResize);
            }
        }
    }

    /**
     * @method detachEvents
     * @description Removes event listeners
     */
    detachEvents() {
        if (this.playPauseButtonElm) {
            this.playPauseButtonElm.removeEventListener(EVENTS.CLICK, this.onControlClick);
        }
        if (this.videoElm) {
            this.videoElm.removeEventListener(EVENTS.ABORT, this.onAbort);
            this.videoElm.removeEventListener(EVENTS.PLAY, this.onPlay);
            this.videoElm.removeEventListener(EVENTS.ENDED, this.onEnded);
        }
        screen.removeResizeListener(this.onScreenResize);
    }

    /**
     * @method onControlClick
     * @description Click event handler for play/pause button
     */
    onControlClick() {
        const { playing } = this.getState();

        if (this.videoElm) {
            this.videoElm[playing ? ACTIONS.PAUSE : ACTIONS.PLAY]();
        }
        this.setPlayingState(!playing);
    }

    /**
     * @method onAbort
     * @description video stream abort event handler
     * @param e {Event} video stream abort event object
     */
    onAbort(e) {
        console.log('HTML5VideoControl.onAbort', e);
    }

    /**
     * @method onPlay
     * @description video stream play event handler
     */
    onPlay() {
        this.setPlayingState(true);
    }

    /**
     * @method onEnded
     * @description video stream ended event handler
     */
    onEnded() {
        this.setPlayingState(false);
    }

    /**
     * @method onScreenResize
     * @description screen resize event handler
     */
    onScreenResize() {
        const { currentScreenState, currentScreenSize } = this.getState();
        const newScreenState = screen.getCurrentState();
        const newScreenSize = screen.gte(screen.SIZES.XLARGE) ? CONSTANTS.LARGE : CONSTANTS.SMALL;
        this.updateState({
            currentScreenState: newScreenState,
            currentScreenSize: newScreenSize
        });
        if (this.videoElm &&
            this.sourceElm &&
            currentScreenState !== newScreenState &&
            currentScreenSize !== newScreenSize) {
            this.setSource();
        }
    }

    /**
     * @method setPlayingState
     * @description sets components playing state (true/false)
     * @param newPlayingState {Boolean} new playing state
     */
    setPlayingState(newPlayingState) {
        this.playPauseButtonElm.classList[newPlayingState ? ACTIONS.REMOVE : ACTIONS.ADD](CLASSES.BUTTON_PLAY);
        this.pauseLabelElm.classList[newPlayingState ? ACTIONS.REMOVE : ACTIONS.ADD](CLASSES.HIDE);
        this.playLabelElm.classList[!newPlayingState ? ACTIONS.REMOVE : ACTIONS.ADD](CLASSES.HIDE);
        this.element.setAttribute(ATTRIBUTES.PLAY_STATE, newPlayingState ? STATES.PLAYING : STATES.PAUSED);

        this.updateState({
            playing: newPlayingState
        });
    }

    /**
     * @method setSource
     * @description sets src attribute value on html5 video element <source> tag
     */
    setSource() {
        const { currentScreenSize } = this.getState();
        this.sourceElm.setAttribute(
            ATTRIBUTES.SRC,
            this.videoElm.getAttribute(`${ATTRIBUTES.DATA_SRC}${currentScreenSize}`)
        );
        this.videoElm.load();
        this.videoElm.play();
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {Object} object of new values for state
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description get State of the component
     * @return {object} state of the component
     */
    getState() {
        return { ...this.state };
    }

    /**
     * @method deactivate
     * @description Detaches all events
     */
    deactivate() {
        this.detachEvents();
    }

    /**
     * @method destroy
     * @description Removes references and detaches all events when destroyed
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
        this.element = null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

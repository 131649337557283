import { htmlNode } from 'utils';

/**
 * Template for a QuickRegistration view.
 * Uses ES6 template literal to composite the markup for a QuickRegistrationLogin view
 */
export default (message) => htmlNode `
    <div class="mbs-quick-registration__login">
        ${message}
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

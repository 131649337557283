// Partial dependencies
import { InputTextAreaControl } from 'partials/input-control';

// Local dependencies
import dealerFormCommentsTemplate from '../templates/dealerFormCommentsTemplate';

/**
 * @const ATTRIBUTES
 * @description DOM attributes for view
 * @type {{COMMENTS: string}}
 */
const ATTRIBUTES = {
    COMMENTS: 'dealer-form-comments'
};

/**
 * @class DealerFormComments
 * @description View responsible for creating a Dealer Form's comments
 * form fields and managing their state
 */
export default class DealerFormComments {
    /**
     * @constructor
     * @description On instantiation, sets the initial properties,
     * caches the dom and creates the its inputs
     * @param content {Object} Object contains content key/values
     */
    constructor(content = {}) {
        this.content = content;
        this.element = dealerFormCommentsTemplate(this.content)({ getNode: true });
        this.inputs = null;

        this.cacheDOM();
        this.createInputs();
    }

    /**
     * @method destroy
     * @description method that destroys all form elements
     */
    destroy() {
        Object.values(this.inputs).forEach((input) => {
            if (input.destroy) {
                input.destroy();
            }
        });
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements from the view
     */
    cacheDOM() {
        this.comments = this.element.querySelector(`[${ATTRIBUTES.COMMENTS}]`);
    }

    /**
     * @method createInputs
     * @description Creates the views input controls and appends them to the view
     */
    createInputs() {
        this.inputs = {
            comments: new InputTextAreaControl({
                maxLength: 2500,
                labelText: this.content.commentsLabel
            })
        };

        this.comments.parentNode.replaceChild(this.inputs.comments.render(), this.comments);
    }

    /**
     * @method getInputs
     * @description gets all inputs for selects form sections
     * @returns inputs
     */
    getInputs() {
        return this.inputs;
    }

    /**
     * @methdo render
     * @description  return the HTML Node with the element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

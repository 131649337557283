// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    domState,
    noop
} from 'utils';

// local dependencies
import authNavLinkTemplate from './../templates/authNavLinkTemplate';
import authNavButtonTemplate from './../templates/authNavButtonTemplate';
import DEFAULT_AUTHENTICATION_INFO from './../config/authenticationInfo';

/**
 * @const CLASSES
 * @description helper for catch the dom elements
 * @type {Object}
 */
const CLASSES = {
    LINKS: '.mbs-auth-nav--small-link',
    BUTTON: 'mbs-auth-nav__button'
};

/**
 * @const DEFAULT_CONFIG
 * @description helper to map out config object
 * @type {Object}
 */
const DEFAULT_CONFIG = {
    links: {},
    authentication: {
        ...DEFAULT_AUTHENTICATION_INFO
    },
    onLogout: noop
};

/**
 * @class  AuthNavDrawer
 * @description  this class paint the link element for the profile
 * menu on samll view
 * @param {Node} container
 * @param {Array} links
 */
export default class AuthNavDrawer {
    /**
     * @method constructor
     * @description  manage the default values for the inheritance
     */
    constructor(container, config = DEFAULT_CONFIG) {
        this.element = container;
        this.config = {
            ...DEFAULT_CONFIG,
            ...config
        };

        this.init();
    }

    /**
     * @method init
     * @description initialize the class
     */
    init() {
        this.render();
        this.cacheDOM();
        domState.onReady(this.attachEvents.bind(this));
    }

    /**
     * @method cacheDOM
     * @description  cache the elements required for the class
     */
    cacheDOM() {
        this.linksElm = document.querySelectorAll(CLASSES.LINKS);
    }

    /**
     * @method attachEvents
     * @description Attaches event listener to the logOutCta to apply the logOutClickHandler on click
     */
    attachEvents() {
        this.logOutCta.addEventListener(EVENTS.CLICK, this.config.onLogout);
    }

    /**
     * @method detachEvents
     * @description Removes the event listener to the logOutCta
     */
    detachEvents() {
        this.logOutCta.removeEventListener(EVENTS.CLICK, this.config.onLogout);
    }

    /**
     * @method  render
     * @description  add the class to the DOM
     */
    render() {
        this.config.links.forEach((link) => {
            let target;
            if (link.linkText.toLowerCase().indexOf('mercedes me') !== -1) {
                target = '_blank';
            } else {
                target = '_self';
            }

            if (link.linkText.toLowerCase().indexOf('log out') !== -1) {
                const BUTTON_TEMPLATE = authNavButtonTemplate(link, true)({ getNode: true });
                this.element.appendChild(BUTTON_TEMPLATE);
                this.logOutCta = BUTTON_TEMPLATE.querySelector(`.${CLASSES.BUTTON}`);
            } else {
                const LINK_TEMPLATE = authNavLinkTemplate(link, true, target)({ getNode: true });
                this.element.appendChild(LINK_TEMPLATE);
            }
        });
    }

    /**
     * @method destroy
     * @description  remove all reference from the class on the DOM
     */
    destroy() {
        this.linksElm.forEach((linkElm) => {
            linkElm.parentNode.removeChild(linkElm);
        });

        this.detachEvents();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import { renderer, noop, customEventDispatcher } from 'utils';

// Partials
import { Modal } from 'partials/modal';
import { VideoRelated } from 'partials/video-hub-related';
import { VideoHubVideo } from 'partials/video-hub-video';
import { VideoHubText } from 'partials/video-hub-text';
import { YouTube } from 'partials/youtube';
import ToggleDrawer from 'partials/ToggleDrawer';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {
 *     OVERLAY_VIDEO_GRID_CONTAINER: String,
 *     MULTIMEDIA_TILE: String,
 *     MULTIMEDIA_TILE_ENABLED: String,
 *     TILES_CONTAINER: String,
 *     TILE_LINK: String,
 *     TRANSCRIPT_VIEW_CTA: String,
 *     YOUTUBE_CONTAINER: String,
 *     RELATED_VIDEOS_CONTAINER: String
 * }
 */
const CLASSES = {
    OVERLAY_VIDEO_GRID_CONTAINER: 'overlay-video-grid__copy-container',
    MULTIMEDIA_TILE: 'overlay-video-grid__videos-topic',
    MULTIMEDIA_TILE_ENABLED: 'overlay-video-grid__videos-link--enabled',
    TILES_CONTAINER: 'overlay-video-grid__videos-topics',
    TILE_LINK: 'overlay-video-grid__videos-link',
    TRANSCRIPT_VIEW_CTA: 'toggle-drawer-cta',
    TRANSCRIPT_CONTAINER: 'overlay-video-grid__modal-transcript',
    YOUTUBE_CONTAINER: 'overlay-video-grid__youtube-container',
    RELATED_VIDEOS_CONTAINER: 'overlay-video-grid__modal-related',
    PLAYING: 'playing'
};

/**
 * @const ARIA_ATTRIBUTES
 * @description constant for the attribute used for accessibility purposes
 */
const ARIA_ATTRIBUTES = {
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_CONTROLS: 'aria-controls',
    ARIA_EXPANDED: 'aria-expanded'
};

/**
 * @const ATTRIBUTES
 * @description constant for the attribute used to find grid container element
 */
const ATTRIBUTES = {
    VIDEO_GRID_VIDEO: 'data-overlay-video-grid-video',
    VIDEO_GRID_TEXT: 'data-overlay-video-grid-text',
    VIDEO_ID: 'data-video-id',
    VIDEO_PATH: 'data-video-path',
    VIDEO_CATEGORY: 'data-video-category',
    TRANSCRIPT_OPEN: 'cta-transcript-open',
    TRANSCRIPT_CLOSE: 'cta-transcript-close',
    ANALYTICS_TRIGGER: 'data-analytics-trigger',
    ID: 'id'
};

/**
 * @class OverlayVideoGrid
 * @description Interactive part of the OverlayVideoGrid AEM component
 */
export default class OverlayVideoGrid {
    /**
     * @method constructor
     * @description Instantiates the OverlayVideoGrid Module
     * @param element {Object} DOM representation of search results
     * @param sections {Object} contains complete set of search results for use in getting related videos
     */
    constructor(element, sections) {
        this.element = element;
        this.sections = sections;

        this.modalOpenersElm = [];
        this.targetModelElm = null;
        this.openModal = this.openModal.bind(this);

        this.init();
    }

    /**
     * @method init
     * @description  this method executed exactly after all the properties
     * have been setup by default.
     */
    init() {
        this.cacheDOM();
        this.attachEvents();
        this.initModal = this.initModal.bind(this);
        this.initModalVideos = this.initModalVideos.bind(this);
        this.getRelatedVideos = this.getRelatedVideos.bind(this);
        this.detachModalEvents = this.detachModalEvents.bind(this);
        this.createToggleDrawer = this.createToggleDrawer.bind(this);
        this.onRelatedVideoSelected = this.onRelatedVideoSelected.bind(this);
        this.transcriptAnalyticsToggle = this.transcriptAnalyticsToggle.bind(this);
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.modalOpenersElm = this.element.querySelectorAll(`.${CLASSES.TILE_LINK}`);
        this.tilesContainer = this.element.querySelector(`.${CLASSES.TILES_CONTAINER}`);
        this.overlayTileGridContainer = this.element.querySelector(`.${CLASSES.OVERLAY_VIDEO_GRID_CONTAINER}`);
    }

    /**
     * @method attachEvents
     * @description Applies click event listeners to the modal openers
     */
    attachEvents() {
        [].slice.call(this.modalOpenersElm).forEach((opener) => {
            opener.addEventListener(EVENTS.CLICK, this.openModal);
            opener.classList.add(CLASSES.MULTIMEDIA_TILE_ENABLED);
        });

        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.RELATED_VIDEO_SELECTED,
            this.onRelatedVideoSelected.bind(this)
        );
    }

    /**
     * @method detachEvents
     * @description Iterates over the modal opener elements and removes the event listener
     * for when a multimedia tile is clicked
     */
    detachEvents() {
        [].slice.call(this.modalOpenersElm).forEach((opener) => {
            opener.removeEventListener(
                EVENTS.CLICK, this.openModal
            );
        });

        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.RELATED_VIDEO_SELECTED,
            this.onRelatedVideoSelected
        );
    }

    /**
     * @method detachModalEvents
     * @description callback function executed before closing modal to remove event listener on transcript toggler
     */
    detachModalEvents() {
        if (this.transcriptToggler) {
            this.transcriptToggler.removeEventListener(EVENTS.CLICK, this.transcriptAnalyticsToggle);
        }
    }

    /**
     * @method destroy
     * @description Detaches all events and removes the element node
     */
    destroy() {
        this.detachEvents();
        this.modalOpenersElm = [];
        this.tilesSections = null;
        this.element.remove();
    }

    /**
     * @method openModal
     * @description Open the Modal with the respective options by package
     * @param {Object} currentTarget the clicked opener Element
     */
    openModal({ currentTarget }) {
        const targetModalId = currentTarget.getAttribute(ARIA_ATTRIBUTES.ARIA_CONTROLS);
        this.targetModalElm = document.getElementById(targetModalId).cloneNode(true);
        const newModalId = `${targetModalId}-in-modal`;
        this.targetModalElm.setAttribute(ARIA_ATTRIBUTES.ARIA_HIDDEN, false);
        this.targetModalElm.setAttribute(ATTRIBUTES.ID, newModalId);

        const modal = new Modal(undefined, {
            modalContent: this.targetModalElm,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.DEFAULT,
            callbacks: {
                afterOpen: this.initModal,
                beforeClose: this.detachModalEvents
            }
        });

        modal.open({ callingContainer: currentTarget });
    }

    /**
     * @method initModal
     * @description Kickstarts the process of populating the modal with a video and related information
     */
    initModal() {
        const videoHolder = this.targetModalElm.querySelector(`[${ATTRIBUTES.VIDEO_GRID_VIDEO}]`);
        const videoPath = videoHolder.dataset.videoPath;
        const videoCategory = videoHolder.dataset.videoCategory || null;

        this.initModalVideos(false); // Just opening the modal, so the user should click before playing
        this.getRelatedVideos(videoPath, videoCategory);
    }

    /**
     * @method initModalVideos
     * @description create the YouTube iframe using the specified videoId
     * @param play {Boolean} play the video on initialization or not
     * @returns {YouTube}
     */
    initModalVideos(play) {
        const videoHolder = this.targetModalElm.querySelector(`[${ATTRIBUTES.VIDEO_GRID_VIDEO}]`);
        const videoContainer = this.targetModalElm.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);
        const videoId = videoHolder.dataset.videoId;
        this.transcriptToggler = this.targetModalElm.querySelector(`.${CLASSES.TRANSCRIPT_VIEW_CTA}`);

        if (this.transcriptToggler) {
            const transcriptDrawerId = this.transcriptToggler.getAttribute(ARIA_ATTRIBUTES.ARIA_CONTROLS);
            const transcriptDrawer = this.targetModalElm.querySelector(`#${transcriptDrawerId}`);
            // reset ID of transcript drawer so it does not duplicate the element it was cloned from
            this.transcriptToggler.setAttribute(ARIA_ATTRIBUTES.ARIA_CONTROLS, `${transcriptDrawerId}-in-modal`);
            transcriptDrawer.setAttribute(ATTRIBUTES.ID, `${transcriptDrawerId}-in-modal`);

            this.transcriptToggler.addEventListener(EVENTS.CLICK, this.transcriptAnalyticsToggle);

            this.createToggleDrawer(this.transcriptToggler, {
                toggleDrawer: transcriptDrawer
            });
        }

        const player = new YouTube(videoId, videoContainer, (isEnded) => {
            // 'playing' css class controls fading the video and preview images in/out.
            // Internet Explorer 11 and below do not support a second argument to `toggle`
            this.targetModalElm.classList[isEnded ? 'remove' : 'add'](CLASSES.PLAYING);
        }, {
            events: {
                onReady: play ? this.onVideoReady : noop
            }
        });

        return player;
    }

    /**
     * @method getRelatedVideos
     * @description Kickstarts getting the related videos for the modal
     * @param videoPath {String} path to the video, used by the related videos service to search for related videos
     * @param videoCategory {String} machine-readable video category, used to search for related videos
     */
    getRelatedVideos(videoPath, videoCategory) {
        const relatedVideosContainer = this.targetModalElm.querySelector(`.${CLASSES.RELATED_VIDEOS_CONTAINER}`);

        if (relatedVideosContainer) {
            /* eslint-disable-next-line no-new */
            new VideoRelated(relatedVideosContainer, videoPath, videoCategory, this.sections);
        }
    }

    /**
     * @method onVideoReady
     * @description When clicking on a related video, the assumption is that the user wants to play the video without
     * having to click again. This starts the video once it has replaced a previous video in the modal's video space
     * @param event {Event} The YouTube onReady event, containing a reference to the player that is ready.
     */
    onVideoReady(event) {
        event.target.playVideo();
    }

    /**
     * @method onRelatedVideoSelected
     * @description Triggered by CUSTOM_EVENTS.RELATED_VIDEO_SELECTED, uses information from that event to
     * instantiate a new video player replacing the existing one.
     * @param videoObj
     */
    onRelatedVideoSelected(videoObj) {
        const newVideo = videoObj.detail.video;
        const tag = videoObj.detail.tag;

        const videoContainer = this.targetModalElm.querySelector(`[${ATTRIBUTES.VIDEO_GRID_VIDEO}]`);
        const textContainer = this.targetModalElm.querySelector(`[${ATTRIBUTES.VIDEO_GRID_TEXT}]`);

        const videoRendered = new VideoHubVideo(newVideo).render();
        const textRendered = new VideoHubText(newVideo, tag).render();

        videoContainer.setAttribute(ATTRIBUTES.VIDEO_ID, newVideo.video);
        videoContainer.setAttribute(ATTRIBUTES.VIDEO_PATH, newVideo.path);
        videoContainer.setAttribute(ATTRIBUTES.VIDEO_CATEGORY, tag.name);

        renderer.insert(videoRendered, videoContainer);
        renderer.insert(textRendered, textContainer);

        this.targetModalElm.classList.remove(CLASSES.PLAYING);

        // User clicked on an item showing intention to play, so play the video without another click
        this.initModalVideos(true);
        this.getRelatedVideos(newVideo.path, tag.name);
    }

    /**
     * @method createToggleDrawers
     * @description Initializes ToggleDrawers for each Transcript View cta
     * @param toggler {Object} CTA element that triggers toggling
     * @param options {Object} Configuration option that specifies the specific drawer the toggle acts upon
     */
    createToggleDrawer(toggler, options) {
        const toggle = new ToggleDrawer(toggler, options);
        return toggle;
    }

    /**
     * @method transcriptAnalyticsToggle
     * @description Flip data-analytics-trigger when opening and closing transcript to reflect the value needed for the
     * next click
     * @param event {Event} Click event generated by the transcript toggle button
     */
    transcriptAnalyticsToggle(event) {
        event.preventDefault();
        const transcriptExpanded = this.transcriptToggler.getAttribute(ARIA_ATTRIBUTES.ARIA_EXPANDED);
        this.transcriptToggler.setAttribute(ATTRIBUTES.ANALYTICS_TRIGGER, transcriptExpanded === 'true' ?
            ATTRIBUTES.TRANSCRIPT_OPEN : ATTRIBUTES.TRANSCRIPT_CLOSE);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

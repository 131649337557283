// Partials dependencies
import { Modal } from 'partials/modal';

// Local dependencies
import inventoryRedirectModalTemplate from '../templates/inventoryRedirectModalTemplate';

/**
 * @const ATTRIBUTES
 * @description Attribute references for the Inventory Redirect Modal
 * @type {{SEARCH_FORM: string, SEARCH_INPUT: string}}
 */
const ATTRIBUTES = {
    SEARCH_FORM: 'data-search-form',
    SEARCH_INPUT: 'data-search-input'
};

/**
 * @const defaultLocalization
 * @description localization object for i18n content
 * @type {Object}
 */
const defaultLocalization = {
    moduleHeading: 'Find Local Inventory',
    moduleDescription: 'Enter your Zip Code or city and state to locate vans at your nearest dealership.',
    searchInputLabel: 'Enter Zip Code or city, state',
    searchCTA: 'Find Dealer',
};

/**
 * @const localization
 * @description constant for the localization content created in footlibs.html
 */
const localization = window.mbVans.ns('pageData', 'dealerLocatorPlugin').localization;

/**
 * View responsible for rendering a Modal with a text input and button to redirect to Inventory search url
 */
export default class InventoryRedirectModal {
    constructor(inventoryUrl = '') {
        this.element = null;
        this.inventoryUrl = inventoryUrl;
        const content = { ...defaultLocalization, ...localization };
        this.element = inventoryRedirectModalTemplate(content)({ getNode: true });

        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.form = this.element.querySelector(`[${ATTRIBUTES.SEARCH_FORM}]`);
        this.searchInput = this.element.querySelector(`[${ATTRIBUTES.SEARCH_INPUT}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches an event listener for window resize events
     * and applies a callback to set the height when triggered
     */
    attachEvents() {
        this.form.addEventListener('submit', this.goToInventory.bind(this), false);
    }

    /**
     * @method goToInventory
     * @description Redirect to inventory page with entered search text
     */
    goToInventory(e) {
        e.preventDefault();
        const url = `${this.inventoryUrl}?searchText=${this.searchInput.value}`;
        window.location.href = url;
    }

    /**
     * @method openModal
     * @description Creates an instance of a modal and opens it
     */
    openModal() {
        const modal = new Modal(undefined, {
            modalContent: this.element,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.INSET
        });

        modal.open();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

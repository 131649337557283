import Pikaday from 'pikaday';
import merge from 'lodash.merge';
import { EVENTS } from 'Constants';
import { renderer, screen, Touch, generateUniqueID } from 'utils';
import FEATURE_DETECTION from 'utils/featureDetection';

import { inputDate, inputText, inputLabel, template } from './../templates/datePickerTemplates';

/**
 * @const DEFAULT_OPTIONS
 * @description Collection of default options for the view
 */
const DEFAULT_OPTIONS = {
    defaultDate: '',
    minDate: (new Date().toJSON().split('T')[0]),
    i18n: {
        previousMonth: 'Previous Month',
        nextMonth: 'Next Month',
        preferredDate: 'Preferred Date',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
    }
};

/**
 * @const ATTRIBUTES
 * @description Collection of data attributes for querying
 * @type {{INPUT_CONTAINER: string, DATE_TRIGGER: string, DATE_CONTAINER: string}}
 */
const ATTRIBUTES = {
    INPUT_CONTAINER: 'data-input-container',
    DATE_TRIGGER: 'data-date-trigger',
    DATE_CONTAINER: 'data-date-container'
};

/**
 * @const CLASSES
 * @description Collection of classes
 * @type {{INPUT_HAS_VALUE: string, DATEPICKER_THEME: string}}
 */
const CLASSES = {
    INPUT_HAS_VALUE: 'form__input-field--has-value',
    DATEPICKER_THEME: 'form-input__date-picker',
    ERROR: 'error'
};

let SCREEN_STATE = screen.getState();

/**
 * This view will render a input field with a date picker. If the screen size is small, the input
 * with type=date is rendered. For larger screens, the DatePicker view will be rendered as a sub
 * view.
 */
export default class DatePickerInput {
    /**
     * @constructor
     * @description Constructor of the DatePickerInput
     * @param {Object} options - Options for the date picker
     * @param {string} options.defaultDate - Default Date string in the format YYYY-MM-DD
     * @param {string} options.minDate - Min Date string in the format YYYY-MM-DD
     */
    constructor(options) {
        this.options = merge(DEFAULT_OPTIONS, options);
        this.id = `datepicker${generateUniqueID()}`;
        this.element = template({
            errorMessage: this.options.errorMessage,
            id: this.id
        })({ getNode: true });
        // We can afford to use same ID for both input[type="text"] & input[type="date"] because
        // only one of the input is rendered per instance of this module
        this.inputText = inputText({ id: this.id })({ getNode: true });
        this.inputDate = inputDate({
            id: this.id,
            minDate: this.options.minDate
        })({ getNode: true });
        this.inputLabel = inputLabel({
            labelCopy: this.options.i18n.preferredDate,
            forID: this.id
        })({ getNode: true });

        this.inputContainer = null;

        this.renderInput = this.renderInput.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
        this.onChange = this.onChange.bind(this);
        this.pickerInstance = null;

        this.cacheDOM();
        this.renderInput();
        this.attachEvents();
    }

    /**
     * @method destroy
     * @description Destroys the instance by detaching events and removing element.
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
        if (this.pickerInstance) {
            this.pickerInstance.destroy();
            this.pickerInstance = null;
        }
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.inputContainer = this.element.querySelector(`[${ATTRIBUTES.INPUT_CONTAINER}]`);
        this.dateTrigger = this.element.querySelector(`[${ATTRIBUTES.DATE_TRIGGER}]`);
        this.calendarContainer = this.element.querySelector(`[${ATTRIBUTES.DATE_CONTAINER}]`);
    }

    /**
     * @method getValue
     * @description Gets the value of the input that holds the date
     * @returns {String} If this.inputDate has value else the value of inputText
     */
    getValue() {
        return this.inputDate.value || this.inputText.value;
    }

    /**
     * @method renderInput
     * @description Renders the appropriate input based on the screen resolution
     */
    renderInput() {
        if (screen.getState().small && FEATURE_DETECTION.IS_DATE_SUPPORTED && Touch.isTouch()) {
            if (this.pickerInstance) {
                this.pickerInstance.destroy();
                this.pickerInstance = null;
            }

            renderer.insert(this.inputDate, this.inputContainer);
            // Label requires to be next to input to have transform styles work
            // Insert label after input is inserted into container
            renderer.append(this.inputLabel, this.inputContainer);
        } else {
            renderer.insert(this.inputText, this.inputContainer);
            renderer.append(this.inputLabel, this.inputContainer);

            this.pickerInstance = new Pikaday({
                field: this.inputText,
                trigger: this.dateTrigger,
                firstDay: 0,
                minDate: this.options.tomorrow,
                defaultDate: this.options.tomorrow,
                position: 'bottom right',
                // Theme CSS class
                theme: CLASSES.DATEPICKER_THEME,
                // Override default behavior to display custom format
                toString: (date) => {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    return `${month}/${day}/${year}`;
                },
                // The input has disabled and readonly attributes. This prevents the constraint
                // validation from happening, ref: https://html.spec.whatwg.org/multipage/input.html#the-readonly-attribute
                // Add class to input when a date is selected. This class will be used to control
                // the transform of the label next to input.
                onSelect: () => {
                    this.inputText.classList.add(CLASSES.INPUT_HAS_VALUE);
                },
                i18n: this.options.i18n
            });
        }
    }

    /**
     * @method resizeHandler
     * @description Window resize event handler. Calls the #renderInput method if the screenstate
     * has changed.
     */
    resizeHandler(state) {
        // Render change in input only if screen state has changed
        if (state.small !== SCREEN_STATE.small) {
            this.renderInput();
            SCREEN_STATE = state;
        }
    }

    /**
     * @method setErrorStatus
     * @description Sets the error classes
     * @param {Boolean} error Error flag to show/hide error
     */
    setErrorStatus(error) {
        if (error) {
            this.element.classList.add(CLASSES.ERROR);
        } else {
            this.element.classList.remove(CLASSES.ERROR);
        }
    }

    /**
     * @method validate
     * @description Checks if the date selected is valid and sets error status accordingly
     */
    validate() {
        let valid = true;

        if (this.pickerInstance && this.inputText && this.inputText.value) {
            valid = new Date(this.inputText.value) > new Date(this.options.today);
        } else if (this.inputDate && this.inputDate.value) {
            valid = new Date(this.inputDate.value) > new Date(this.options.today);
        }

        this.valid = valid;
        this.setErrorStatus(!this.valid);
    }

    /**
     * @method isValid
     * @description If pickerInstace is available, make sure the inputText has value is greater
     * than minDate. Else check if inputDate is available and it has value and compare value
     * to minDate
     * @returns {Boolean} True if valid
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method onChange
     * @description Change handler of input[type=date]. Checks if valid and sets error state. Also
     * add INPUT_HAS_VALUE class if a value was chosen.
     */
    onChange(event) {
        // When user picks a date that is invalid (because of the minDate attribute), html5
        // validator removes the :valid selector which causes the label to not transform.
        // Explicitly add the INPUT_HAS_VALUE class when user selects a value.
        if (event.target.value) {
            this.inputDate.classList.add(CLASSES.INPUT_HAS_VALUE);
        } else {
            this.inputDate.classList.remove(CLASSES.INPUT_HAS_VALUE);
        }

        if (!this.isValid()) {
            this.setErrorStatus(false);
        }
    }

    /**
     * @method attachEvents
     * @description Attachs events
     */
    attachEvents() {
        screen.addResizeListener(this.resizeHandler);
        this.inputDate.addEventListener(EVENTS.CHANGE, this.onChange);
    }

    /**
     * @method detachEvents
     * @description Detaches events
     */
    detachEvents() {
        screen.removeResizeListener(this.resizeHandler);
    }

    /**
     * @method render
     * @description Returns the element of the class
     * @return {element} Element of class
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * API responsible for loading reCaptcha V3 API
 */

let reCAPTCHAV3APILoader;

/**
 * @property siteKey
 * @description Recaptcha V3 Site Key defined in the applicationProperties
 * @type {string}
 */
const {
    applicationProperties: {
        recaptchaV3: {
            siteKey = ''
        } = {}
    } = {}
} = window.mbVans || {};

/**
 * @property defaultConfig
 * @description Default configuration for the api
 *
 * @type {{siteKey: string}}
 */
const defaultConfig = {
    siteKey
};

/**
 * @function loadRecaptchaV3
 * @description Loads Recaptcha V3 library into the DOM
 * @param {Object} config Configuration for recaptcha
 * @param {String} config.siteKey Recaptcha site key
 * @return {Promise<any>} Resolve on success
 */
export function loadRecaptchaV3(config = {}) {
    const configObj = {
        ...defaultConfig,
        ...config
    };

    if (!reCAPTCHAV3APILoader) {
        reCAPTCHAV3APILoader = new Promise((resolve, reject) => {
            const urlPath = `https://www.google.com/recaptcha/api.js?render=${configObj.siteKey}`;
            const headElm = document.querySelector('head');
            const script = document.createElement('script');

            script.src = urlPath;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                window.grecaptcha.ready(() => {
                    resolve();
                });
            };
            script.onerror = () => reject();
            headElm.appendChild(script);
        });
    }

    return reCAPTCHAV3APILoader;
}

/**
 * @function getToken
 * @description Executes a recaptcha to get the challenge token
 * @param {Object} config Configuration for recaptcha
 * @param {String} config.siteKey Recaptcha site key
 * @return {Promise<unknown>} Resolves with the token on success
 */
export function getToken(config = {}) {
    const configObj = {
        ...defaultConfig,
        ...config
    };

    return new Promise((resolve, reject) => {
        loadRecaptchaV3().then(() => {
            grecaptcha.execute(`${configObj.siteKey}`, { action: 'submit' })
                .then((token) => {
                    resolve(token);
                });
        }).catch(reject);
    });
}

/**
 * export api method
 */
export default {
    loadRecaptchaV3,
    getToken
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization labels for Dealer Locator App
 * @type {{label: String}}
 */
const defaultLocalization = {
    find: 'Find',
    searchInputLabel: 'Postal Code or City, Province',
    dealerSearchErrorMessage: 'Invalid Postal Code or City, Province',
    dealerValidationError: 'The dealer is currently unavailable.',
    emptyDealerErrorMessage: 'Please select a dealer to continue.',
    dealerSearchHeading: 'Search for your preferred dealer.',
    dealerResultHeading: 'Select one of the dealers near {0}.',
    closestDealersHeading: 'These are the closest dealers based on your search.',
    selectedDealerHeading: 'This is your selected dealer.',
    changeDealer: 'Change Dealer',
    changeLocation: 'Change Location',
    viewInventory: 'View Dealer Inventory',
    amgPerformanceCenter: 'AMG Performance Center',
    amgPerformanceCenterElite: 'AMG Performance Center Elite',
    serverErrorDescription: 'We were unable to process your request. Please try again.',
    serverErrorTitle: 'We\'re sorry, an error has occurred.',
    suggestedDealer: 'Suggested'
};

// Deconstruct localization object
const {
    find,
    searchInputLabel,
    dealerSearchErrorMessage,
    dealerValidationError,
    emptyDealerErrorMessage,
    dealerSearchHeading,
    dealerResultHeading,
    dealerResultsSubHeading,
    closestDealersHeading,
    selectedDealerHeading,
    changeDealer,
    changeLocation,
    viewInventory,
    amgPerformanceCenter,
    amgPerformanceCenterElite,
    serverErrorDescription,
    serverErrorTitle,
    suggestedDealer
} = Object.assign(defaultLocalization, window.mbVans.ns('pageData').localization);

export default {
    find,
    searchInputLabel,
    dealerSearchErrorMessage,
    dealerValidationError,
    emptyDealerErrorMessage,
    dealerSearchHeading,
    dealerResultHeading,
    dealerResultsSubHeading,
    closestDealersHeading,
    selectedDealerHeading,
    changeDealer,
    changeLocation,
    viewInventory,
    amgPerformanceCenter,
    amgPerformanceCenterElite,
    serverErrorDescription,
    serverErrorTitle,
    suggestedDealer
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

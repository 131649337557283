import { htmlNode } from 'utils';

/**
 * @description template for Tab Panel
 * @param {string} panelID - ID for the panel/tab
 */
export default (paneID) => htmlNode`
    <div class="tab-panel" aria-labelledby="tab-panel${paneID}" id="tab-panel${paneID}" tabindex="0"></div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

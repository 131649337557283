import { renderer, generateUniqueID as ID } from 'utils';

import TabList from './TabList';
import TabPanel from './TabPanel';

import tabsTemplate from './../templates/tabsTemplate';

/**
 * @const defaultConfig
 * @type {{
 *   defaultTabIndex: number
 * }}
 */
const defaultConfig = {
    defaultTabIndex: 0,
    tabListLabel: 'Tabs',
    isStaticLinks: false
};

/**
 * @const CLASSES
 * @description CSS classes for Tabs
 * @type {{
 *   TAB_LIST: string
 *   TAB_PANEL: string
 * }}
 */
const CLASSES = {
    TAB_LIST: 'tabs__tab-list-container',
    TAB_PANEL: 'tabs__tab-panel-container'
};

/**
 * @class Tabs
 * @description Tabs component for OneWeb
 */
export default class Tabs {
    /**
     * @constructor
     * @description Constructs the Tabs component with Tab and Panel views
     * @param {Object[]} items - Array of Tab items
     * @param {string} items[].tabLabel - Label for the tab
     * @param {HTMLElement} items[].tabContent - Content for the tab
     * @param {Object} config - Configuration
     */
    constructor(items, config) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.element = tabsTemplate()({ getNode: true });
        this.tabList = null;
        this.tabPanels = [];
        this.swapPanels = this.swapPanels.bind(this);

        this.cacheDOM();
        this.constructTabItems(items);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.tabListContainerElm = this.element.querySelector(`.${CLASSES.TAB_LIST}`);
        this.tabPanelContainerElm = this.element.querySelector(`.${CLASSES.TAB_PANEL}`);
    }

    /**
     * @method constructTabItems
     * @param {Object[]} items - Array of Tab items, passed down from constructor
     */
    constructTabItems(items) {
        items = items.map((item) => {
            const id = ID();

            return {
                tabLabel: {
                    id,
                    name: item.tabLabel,
                    url: item.tabAnchor
                },
                tabPanel: {
                    id,
                    content: item.tabPanel
                }
            };
        });
        this.renderTabList(items.map((item) => item.tabLabel));
        if (!this.config.isStaticLinks) {
            this.renderTabPanels(items.map((item) => item.tabPanel));
        }
        this.activate(this.config.defaultTabIndex);
    }

    /**
     * @method renderTabs
     * @description Renders the TabList component
     * @param {string[]} tabLabels - Array of strings for the tab labels
     */
    renderTabList(tabLabels) {
        this.tabList = new TabList(
            tabLabels,
            {
                tabListLabel: this.config.tabListLabel,
                onChangePanel: this.swapPanels,
                isStaticLinks: this.config.isStaticLinks,
                onTabClickUrl: this.config.onTabClickUrl
            }
        );
        renderer.insert(this.tabList.render(), this.tabListContainerElm);
    }

    /**
     * @method renderTabPanels
     * @description Renders the TabPanel component
     * @param {HTMLElement[]} tabPanels - Array of DOM elements to be rendered for each tabpanel
     */
    renderTabPanels(tabPanels) {
        tabPanels.forEach((tabPanelContent) => {
            const tabPanel = new TabPanel(tabPanelContent);
            this.tabPanels.push(tabPanel);
            renderer.append(tabPanel.render(), this.tabPanelContainerElm);
        });
    }

    /**
     * @method activate
     * @description Activates the tab based on tabIndex
     * @param {Number} tabIndex - Index of the tab that needs to be activated
     */
    activate(tabIndex) {
        this.tabList.activate(tabIndex);
        if (!this.config.isStaticLinks) {
            this.swapPanels(tabIndex);
        }
    }

    /**
     * @method swapPanels
     * @description Swaps to the indicated index
     * @param {Number} tabIndex - Index of the tab panel that needs to be activated
     */
    swapPanels(tabIndex) {
        this.tabPanels.forEach((tabPanel) => tabPanel.deactivate());
        this.tabPanels[tabIndex].activate();
    }

    /**
     * @method render
     * @description Returns element for this module
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @description Default configuration for window.mbVans.ns('pageData').authenticationInfo
 */
export default {
    login: {
        title: 'Log In',
        url: null,
        target: '_self'
    },
    register: {
        title: 'Register',
        url: null,
        target: '_self'
    },
    logout: {
        url: null
    },
    localization: {
        hi: 'Hi'
    },
    account: {
        title: 'My Account'
    }
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a LoadingSpinner view.
 * Uses ES6 template literal to composite the markup for a LoadingSpinner module
 * @param isInline {Boolean} Indicator to add inline class
 * @param isLarge {Boolean} Indicator to add large class
 */
export default ({ isInline, isLarge }) => htmlNode `
    <div class="loading-spinner ${isInline ? 'loading-spinner--inline' : ''} ${isLarge ? 'loading-spinner--large' : ''}">
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

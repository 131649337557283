// Utils
import { findAncestor } from 'utils';

const CLASSES = {
    GLOBAL_HEADER_MENU_L3_PANEL_ITEM: 'global-header__menu-l3-panel-item',
    GLOBAL_HEADER_MENU_L3_PANEL_ITEM_ACTIVE: 'global-header__menu-l3-panel-item--active',
    GLOBAL_HEADER_MENU_L3_CONTAINER: 'global-header__menu-l3__container-background',
    GLOBAL_HEADER_MENU_L3_CONTAINER_ACTIVE: 'global-header__menu-l3__container-background--active'
};

export default class Panel {
    constructor(element) {
        this.element = element;
        this.elmWrapper = null;
        this.init();
    }

    init() {
        this.setBindings();
        this.cacheDOMElements();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {

    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.elmWrapper = findAncestor(this.element, `.${CLASSES.GLOBAL_HEADER_MENU_L3_CONTAINER}`);
    }

    /**
     * @method activate
     * @description activate the panel
     */
    activate() {
        this.element.classList.add(CLASSES.GLOBAL_HEADER_MENU_L3_PANEL_ITEM_ACTIVE);

        if (this.elmWrapper) {
            this.elmWrapper.classList.add(CLASSES.GLOBAL_HEADER_MENU_L3_CONTAINER_ACTIVE);
        }
    }

    /**
     * @method deactivate
     * @description deactivate the panel
     */
    deactivate() {
        this.element.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L3_PANEL_ITEM_ACTIVE);

        if (this.elmWrapper) {
            this.elmWrapper.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L3_CONTAINER_ACTIVE);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization labels for Dealer Selector
 * @type {{label: String}}
 */
const defaultLocalization = {
    changeLocation: 'Change Location',
    closestDealers: 'These are the closest dealers based on your search.',
    continueLabel: 'Continue',
    dealerSelectorFindTitle: 'Find Local Inventory',
    enterYourLocation: 'Enter your location to view vehicles at a dealer near you.',
    findDealer: 'Find Dealer',
    locationSearchErrorMessage: 'Invalid ZIP Code or City, State',
    searchInputLabel: 'Enter ZIP Code, City, or State',
    selectDealer: 'Select the dealer whose inventory you\'d like to see.',
    theseAreDealersNear: 'These are the dealers near {searchTerms}.',
    viewAtDealer: 'View Inventory at {dealerName}.'
};

// Deconstruct localization object
const {
    changeLocation,
    closestDealers,
    continueLabel,
    dealerSelectorFindTitle,
    enterYourLocation,
    findDealer,
    locationSearchErrorMessage,
    searchInputLabel,
    selectDealer,
    theseAreDealersNear,
    viewAtDealer
} = Object.assign(defaultLocalization, window.mbVans.ns('pageData').dealerSelectorLocalization);

export default {
    changeLocation,
    closestDealers,
    continueLabel,
    dealerSelectorFindTitle,
    enterYourLocation,
    findDealer,
    locationSearchErrorMessage,
    searchInputLabel,
    selectDealer,
    theseAreDealersNear,
    viewAtDealer
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

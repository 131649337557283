/**
 * An api for creating an inputCheckboxControl component from specially tagged DOM element
 *
 * Example markup for creating an checkbox from html tags:
 *
 * <div data-input-checkbox-control
 *      data-id="[String]"
 *      data-data-attributes="[String]"
 *      data-input-class-names="[String]"
 *      data-label-class-names="[String]"
 *      data-required="[Boolean]"
 *      data-disabled="[Boolean]"
 *      data-checked="[Boolean]"
 *      data-value-text="[String]"
 *      data-label-text="[String]">
 *     ...
 * </div>
 */

// Local dependencies
import InputCheckboxControl from './../views/InputCheckboxControl';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{INPUT_CHECKBOX_CONTROL: string}}
 */
const ATTRIBUTES = {
    INPUT_CHECKBOX_CONTROL: 'data-input-checkbox-control'
};

/**
 * @method findInputCheckboxControls
 * @description Parses a DOM element for inputCheckboxControl tags and returns array of elements
 * @param element {Element} The element to parse for inputCheckboxControl tags
 * @returns {NodeList} List of elements with ATTRIBUTES.INPUT_CHECKBOX_CONTROL tag
 */
export function findInputCheckboxControls(element) {
    return element.querySelectorAll(`[${ATTRIBUTES.INPUT_CHECKBOX_CONTROL}]`);
}

/**
 * @method createInputCheckboxControl
 * @description Parses a DOM element for inputCheckboxControl data attributes
 * and renders an inputCheckboxControl for each instance
 * @param element {Element} The element with ATTRIBUTES.INPUT_CHECKBOX_CONTROL tag
 */
export function createInputCheckboxControl(element) {
    if (!element) {
        throw new TypeError('createInputCheckboxControl: "element" is null or not defined');
    }

    const checkboxData = element.dataset;

    const config = {};

    if (checkboxData.id) {
        config.id = checkboxData.id;
    }

    if (checkboxData.dataAttributes) {
        config.dataAttributes = checkboxData.dataAttributes.split(',');
    }

    if (checkboxData.inputClassNames) {
        config.inputClassNames = checkboxData.inputClassNames.split(',');
    }

    if (checkboxData.labelClassNames) {
        config.labelClassNames = checkboxData.labelClassNames.split(',');
    }

    if (checkboxData.required) {
        config.required = checkboxData.required;
    }

    if (checkboxData.disabled) {
        config.disabled = checkboxData.disabled;
    }

    if (checkboxData.checked) {
        config.checked = checkboxData.checked;
    }


    if (checkboxData.valueText) {
        config.valueText = checkboxData.valueText;
    }

    if (checkboxData.labelText) {
        config.labelText = checkboxData.labelText;
    }

    if (checkboxData.theme) {
        config.theme = checkboxData.theme;
    }

    const checkbox = new InputCheckboxControl(config);

    element.parentNode.replaceChild(checkbox.render(), element);
}

/**
 * export public api
 */
export default {
    findInputCheckboxControls,
    createInputCheckboxControl
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

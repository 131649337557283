// Util dependencies
import { hasInputBlacklistCharacters } from '@mbusa/now-ui-utils.validate';

// Local dependencies
import InputControl from './InputControl';
import inputTextAreaControlTemplate from './../templates/inputTextAreaControlTemplate';
import { RESTRICTION_TYPE, restrict } from './../api/inputRestrictor';
import { VALIDATION_TYPE, verify } from './../api/inputValidator';


/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    FORM_TEXTAREA: 'form__textarea',
    ERROR_ELEMENT: 'form__input-error',
    ERROR: 'error',
    HAS_VALUE: 'form__input-field--has-value'
};

/**
 * Class that will set up an InputTextAreaControl
 */
export default class InputTextAreaControl extends InputControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputTextAreaControl
     * @type {Object}
     */
    static DEFAULT_CONFIG = {
        ...InputControl.DEFAULT_CONFIG,
        restrictBlacklistCharacters: true
    };

    /**
     * @static RESTRICTION_TYPE
     * @description Default restriction types available for an InputTextAreaControl
     * @type {Object}
     */
    static RESTRICTION_TYPE = {
        ...RESTRICTION_TYPE
    };

    /**
     * @static VALIDATION_TYPE
     * @description Default validation types available for an InputTextAreaControl
     * @type {Object}
     */
    static VALIDATION_TYPE = {
        ...VALIDATION_TYPE
    };

    /**
     * @constructor Create an InputTextAreaControl
     */
    constructor(config = InputTextAreaControl.DEFAULT_CONFIG) {
        super({
            ...InputTextAreaControl.DEFAULT_CONFIG,
            ...config
        });
        this.valid = true; // stores the validity of the input
        this.errorMessage = ''; // stores the error message for and invalid input
        this.inputElementHeight = 110;
        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.createView(inputTextAreaControlTemplate({
            type: this.getConfig('type'),
            id: this.getConfig('id'),
            dataAttributes: this.getConfig('dataAttributes').join(' '),
            inputClassNames: this.getConfig('inputClassNames').join(' '),
            required: this.getConfig('required'),
            maxLength: this.getConfig('maxLength'),
            labelText: this.getConfig('labelText'),
            placeholder: this.getConfig('placeholder')
        })({ getNode: true }));
        super.init();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        super.cacheDOM();
        this.errorElement = this.getInput().querySelector(`.${CLASSES.ERROR_ELEMENT}`);
        this.setInputElement(this.getInput().querySelector(`.${CLASSES.FORM_TEXTAREA}`));
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if input value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method validate
     * @description Check validation and sets the `valid` and `errorMessage` state based
     * on the input values validity
     */
    validate(displayError = true) {
        let valid = true;
        this.errorMessage = '';

        // loop through all validation rules
        [].slice.call(this.getConfig('validation')).map((validationType) => {
            if (valid && !verify(
                this.getInputElement().value,
                validationType.type,
                this.config
            )) {
                valid = false;
                this.errorMessage = validationType.errorMessage;
            }
            return validationType;
        });

        // If black list restriction config is true and the input field has one of the restricted
        // characters
        if (this.getConfig('restrictBlacklistCharacters') && hasInputBlacklistCharacters(this.getInputElement().value)) {
            valid = false;
            this.errorMessage = this.formLocalization.blacklistErrorMessage;
        }

        this.valid = valid;

        if (displayError) {
            this.setErrorStatus(!this.valid, this.errorMessage);
        }

        return this.isValid();
    }

    /**
     * @method toggleHasValueClass
     * @description Toggles the has value class if the input has a value
     * @memberof InputTextControl
     */
    toggleHasValueClass() {
        const hasValue = !!this.getInputElement().value;
        this.getInputElement().classList[hasValue ? 'add' : 'remove'](CLASSES.HAS_VALUE);
    }

    /**
     * @method onKeydown
     * @description Limiting accepted characters in input,
     * if an input has text entered after error is triggered, clear error
     * @param e {Event} Event object from keydown event
     */
    onKeydown(e) {
        [].slice.call(this.getConfig('restrictions')).map((restriction) => {
            if (restrict(e.key, restriction)) {
                e.preventDefault();
            }
            return restriction;
        });

        if (!this.isValid()) {
            this.setErrorStatus(false);
        }

        super.onKeydown(e);
    }

    onKeyup(event) {
        this.toggleHasValueClass();

        super.onKeyup(event);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a comments section view.
 * Uses ES6 template literal to composite the markup for
 * comment section within Dealer Form view
 */
export default ({ commentsTitle = '' }) => htmlNode `
    <div class="dealer-form__comments">
        <div class="dealer-form__header dealer-form__header--section-label">
            ${commentsTitle}
        </div>
        <div dealer-form-comments=""></div>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom checkbox control
 * Uses ES6 template literal to composite the markup for a custom checkbox control
 */
export default ({
    id = '',
    name = '',
    dataAttributes = '',
    inputClassNames = '',
    labelClassNames = '',
    required = false,
    disabled = false,
    checked = false,
    valueText = '',
    labelText = '',
    analyticsTrigger = '',
    moreInfoText = '',
    theme = '',
    ariaLabelledBy = '',
}) => htmlNode`
    <div class="form__input ${inputClassNames} ${theme ? `form__checkbox--${theme}` : ''} ${disabled ? ' disabled' : ''}">
        <input class="form__checkbox"
            type="checkbox"
            ${disabled ? ' disabled' : ''}
            ${checked ? ' checked' : ''}
            ${id ? `id="${id}"` : ''}
            ${name !== '' ? `name="${name}"` : ''}
            ${dataAttributes ? ` ${dataAttributes}` : ''}
            ${valueText ? ` value="${valueText}"` : ''}
            ${required ? ' data-required required' : ''}
            ${analyticsTrigger ? ` data-analytics-trigger="${analyticsTrigger}"` : ''}
            ${ariaLabelledBy ? ` aria-labelledby="${ariaLabelledBy}"` : ''}
            aria-required="${required}"
            />
        ${theme !== 'toggle' ? `<div class=${`form__checkbox-indicator ${disabled ? 'disabled' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5.817 13.048 2 8.308l1.29-1.036 2.606 3.24 6.88-7.552L14 4.074z"></path></svg>
        </div>` : ''}
        <label ${id ? `for="${id}"` : ''} class="form__checkbox-label ${labelClassNames}">
            <span class="form__checkbox-label-text">
                ${labelText}
            </span>
        </label>
        <div class="form__input-error" id="error${id}"></div>
        ${moreInfoText !== '' ? `<span class="form__input-more-info-text">${moreInfoText}</span>` : ''}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

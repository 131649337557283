// Constant dependencies
import {
    EVENTS
} from 'Constants';

// Util dependencies
import { customEventDispatcher } from 'utils';

// Local Dependencies
import inventoryRecentlyViewedApi from '../api/inventoryRecentlyViewedApi';
import recentlyViewedBannerTemplate from '../templates/inventoryRecentlyViewedBannerTemplate';
import INVENTORY_RECENTLY_VIEWED_EVENTS from './../constants/events';

const CLASSES = {
    CONTINUE_BUTTON: 'irv-banner__button--continue',
    DISMISS_BUTTON: 'irv-banner__button--dismiss'
};

/**
 * @class RecentlyViewedBannerControl
 * @description Create and render the recently viewed inventory banner that displays
 * on the home page if the user has started the commerce flow
 */
export default class RecentlyViewedBannerControl {
    constructor(config = {}) {
        this.config = { ...config };
        this.continueButton = null;
        this.dismissButton = null;
        this.recentlyViewedBanner = null;
        this.recentlyViewedVehicle = null;
        this.hasRecentlyViewed = false;

        this.dismissBanner = this.dismissBanner.bind(this);
    }

    /**
     * @method getVehicle
     * @description creates a Promise that gets the necessary data for the component to render.
     * If that data exists, it initializes the banner element and resolves with `true`. If the
     * data does not exist, it resolves with `false`.
     * @return {Promise} Promise that resolves with the recently viewed vehicle if available
     */
    getVehicle() {
        const {
            country,
            currency,
            vehicleApiConfig,
            vehicleApiContent,
            language
        } = this.config;

        return new Promise((resolve) => {
            inventoryRecentlyViewedApi.getRecentlyViewedVehicle({
                endpoint: vehicleApiConfig.endpoints.vehicleLookup,
                country,
                currency,
                language
            }, vehicleApiContent, {
                fallBackImage: {
                    default: vehicleApiConfig.vehicleFallbackImagePattern
                }
            })
                .then((vehicle) => {
                    if (vehicle) {
                        this.setVehicleData(vehicle);
                        this.createBanner();
                        this.cacheDOM();
                        this.attachEvents();
                        this.dispatchChangeEvent(true);
                    }

                    resolve(!!vehicle);
                })
                .catch((err) => {
                    console.info(err);
                });
        });
    }

    /**
     * @method setVehicleData
     * @description set the `recentlyViewedVehicle` property to the data returned from the vehicle
     * inventory request
     * @param {Object} vehicle recently viewed vehicle data
     */
    setVehicleData(vehicle) {
        this.recentlyViewedVehicle = vehicle;
        this.hasRecentlyViewed = true;
    }

    /**
     * @method getReservePath
     * @description Contructs the reserve path based on the recently viewed vehicle info
     */
    getReservePath() {
        const {
            bodyStyleId,
            dealer,
            dealerId,
            classId,
            modelId,
            vin,
        } = this.recentlyViewedVehicle;
        const zip = inventoryRecentlyViewedApi.getRecentlyViewedVehicleCookie().zip;
        return inventoryRecentlyViewedApi.getReservePath(
            {
                path: this.config.routerConfig.endpoint,
                suffixPath: this.config.routerConfig.suffixPath
            },
            {
                bodyStyle: bodyStyleId,
                class: classId,
                dealerId,
                dealerName: dealer.name,
                model: modelId,
                vin
            },
            {
                type: 'zip',
                value: zip
            }
        );
    }

    /**
     * @method createBanner
     * @description initalize the banner template if there is vehicle data
     */
    createBanner() {
        const {
            vehicleApiContent: content
        } = this.config;

        if (this.recentlyViewedVehicle) {
            this.recentlyViewedBanner = recentlyViewedBannerTemplate({
                continueText: content.continue,
                continuePath: this.getReservePath(),
                dismiss: content.dismiss,
                header: content.heading,
                vehicle: this.recentlyViewedVehicle
            })({ getNode: true });
        }
    }

    /**
     * @method cacheDOM
     * @description cache important dom elements
     */
    cacheDOM() {
        this.dismissButton = this.recentlyViewedBanner.querySelector(`.${CLASSES.DISMISS_BUTTON}`);
    }

    /**
     * @method attachEvents
     * @description register click events for continue and dismiss buttons
     */
    attachEvents() {
        if (this.dismissButton) {
            this.dismissButton.addEventListener(EVENTS.CLICK, this.dismissBanner);
        }
    }

    /**
     * @method detachEvents
     * @description remove click events
     */
    detachEvents() {
        this.dismissButton.removeEventListener(EVENTS.CLICK, this.dismissBanner);
    }

    /**
     * @method destroy
     * @description detach events and remove element from the dom
     */
    destroy() {
        this.detachEvents();
        this.dispatchChangeEvent(false);
        this.recentlyViewedBanner.remove();
    }

    /**
     * @method dismissBanner
     * @description delete cookie and remove banner from the dom
     */
    dismissBanner() {
        inventoryRecentlyViewedApi.deleteRecentlyViewedVehicle();
        this.destroy();
    }

    /**
     * @method dispatchChangeEvent
     * @description dispatch a custom event to let others know that the recently viewed banner has changed
     * @param {boolean} hasRecentlyViewed whether the recently viewed banner is showing or not
     */
    dispatchChangeEvent(hasRecentlyViewed) {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                INVENTORY_RECENTLY_VIEWED_EVENTS.BANNER_TRANSITION,
                {
                    detail: {
                        hasRecentlyViewed
                    }
                }
            )
        );
    }

    /**
     * @method render
     * @description render the Banner
     */
    render() {
        return this.recentlyViewedBanner;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

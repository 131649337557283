/**
 * Angled Image Module
 * Includes the ImageToCanvas when there is a rollover for the angled image
 */
import ImageToCanvas from 'partials/ImageToCanvas';
import { EVENTS } from 'Constants';

const CLASSES = {
    ACTIVE: 'active',
    CANVAS: 'content-toggle__rollover'
};

/**
 * @const ATTRIBUTES
 * @description Collection of data attributes for querying
 * @type {{DATA_IMAGE_CANVAS: string}}
 */
const ATTRIBUTES = {
    IMAGE_ORIGINAL: 'data-image-to-canvas-original',
    DATA_IMAGE_CANVAS: 'data-image-to-canvas'
};

export default class AngledImage {
    constructor(element) {
        this.element = element;
        this.init();
    }

    /**
     * Attaches mousemove event to canvas for "hover" effect on angled image
     * @param canvas
     */
    static mouseOverCallback(canvas) {
        canvas.parentNode.addEventListener(EVENTS.MOUSEMOVE, (e) => {
            const context = canvas.getContext('2d');
            const pixelData = context.getImageData(e.offsetX, e.offsetY, 1, 1).data;

            if (pixelData[3] > 0) {
                canvas.classList.add(CLASSES.ACTIVE);
            } else {
                canvas.classList.remove(CLASSES.ACTIVE);
            }
        });
    }

    /**
     * Init method
     * Inits Image toggle links, Inits Image to canvas
     */
    init() {
        this.initImageToCanvas();
    }

    /**
     * If there is a data-image-to-canvas attribute, init the ImageToCanvas partial
     */
    initImageToCanvas() {
        this.originalImage = this.element.querySelector(`[${ATTRIBUTES.IMAGE_ORIGINAL}]`);
        this.imageWrapper = this.element.hasAttribute(ATTRIBUTES.DATA_IMAGE_CANVAS) ?
            this.element : null;

        if (this.imageWrapper) {
            const config = {};
            config.canvasClass = CLASSES.CANVAS;
            config.width = this.originalImage.width || 515;
            config.height = this.originalImage.height || 480;

            this.imageToCanvas =
                new ImageToCanvas(this.imageWrapper, config, AngledImage.mouseOverCallback);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS } from 'Constants';

// Module dependencies
import { renderer, noop } from 'utils';

// Local dependencies
import carouselSlideTemplate from './../templates/carouselSlideTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{ACTIVE: string SLIDE: string, SLIDE_OVERLAY: string}}
 */
const CLASSES = {
    ACTIVE: 'carousel__slide--active',
    SLIDE: 'carousel__slide',
    SLIDE_OVERLAY: 'carousel__slide-overlay'
};

/**
 * @class CarouselSlide
 * @description View component for displaying a Carousel slide and managing its state
 */
export default class CarouselSlide {
    /**
     * @constructor On instantiation, sets the properties and creates a slide element
     * @param element {Element} The element to create a slide from
     * @param isActive {boolean} Indicator for whether to set the slide as active
     */
    constructor(element, isActive = false, analyticsKey,
                    hasMoreThanOneSlide, slideIndex, onFocusCallback = noop) {
        // properties
        this.slide = null; // reference to the slide element
        this.slideOverlay = null; // reference to the slide overlay element
        this.isActive = isActive;
        this.analyticsKey = analyticsKey;
        this.carouselSlideIndex = slideIndex;
        this.hasMoreThanOneSlide = hasMoreThanOneSlide;
        this.onFocusCallback = onFocusCallback;
        this.onFocus = this.onFocus.bind(this);
        // initialize
        this.createSlide(element);
        this.attachEvents();
    }

    /**
     * @method createSlide
     * @description Creates a slide elements and sets its
     * visual state based on if the item is active
     * @param element
     */
    createSlide(element) {
        const template = renderer.fromTemplate(
            carouselSlideTemplate(
                this.hasMoreThanOneSlide,
                this.analyticsKey,
                this.carouselSlideIndex
            )
        );

        this.slide = renderer.append(element, template);
        this.slideOverlay = this.slide.querySelector(`.${CLASSES.SLIDE_OVERLAY}`);
        if (this.isActive) {
            this.enable();
        } else {
            this.disable();
        }
    }

    /**
     * @method attachEvents
     * @description Attaches events and callbacks to the slide
     */
    attachEvents() {
        this.slide.addEventListener(EVENTS.FOCUS, this.onFocus);
        this.slideOverlay.addEventListener(EVENTS.FOCUS, this.onFocus);
    }

    /**
     * @method detachEvents
     * @description Removes events and callbacks to the slide
     */
    detachEvents() {
        this.slide.removeEventListener(EVENTS.FOCUS, this.onFocus);
        this.slideOverlay.removeEventListener(EVENTS.CLICK, this.onFocus);
    }

    /**
     * @method onFocus
     * @description triggers callback for when slide is focused on
     */
    onFocus() {
        this.onFocusCallback(this);
    }

    /**
     * @method destroy
     * @description Removes the slide element reference
     */
    destroy() {
        this.detachEvents();
        this.slide = null;
    }

    /**
     * @method enable
     * @description Sets the isActive state to true and applies
     * the active class to the slide element
     */
    enable() {
        this.isActive = true;
        this.slide.classList.add(CLASSES.ACTIVE);
    }

    /**
     * @method disable
     * @description Sets the isActive state to false and removes
     * the active class from the slide element
     */
    disable() {
        this.isActive = false;
        this.slide.classList.remove(CLASSES.ACTIVE);
    }

    /**
     * @method render
     * @description Retrieves the slide DOM element
     * @return {Element|null} The slide DOM element
     */
    render() {
        return this.slide;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

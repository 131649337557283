import ERRORS from './creditAppErrors';

export default {
    APPLICANT_INFO: 'ApplicantInfo',
    APPLICATION_TYPE: {
        CO: 'CoApplicant',
        PRIMARY: 'PrimaryApplicant',
        SPOUSE: 'SpouseInfo'
    },
    ADDRESS_TYPE: {
        CURRENT: 'CurrentAddress',
        PREVIOUS: 'PreviousAddress',
        SPOUSE: 'SpouseAddress'
    },
    EMPLOYMENT_TYPE: {
        CURRENT: 'CurrentEmploymentDetails',
        PREVIOUS: 'PreviousEmploymentDetails'
    },
    ERRORS: {
        1005: ERRORS.ADDRESS_APARTMENT,
        1063: ERRORS.ADDRESS_LINE,
        1087: ERRORS.BUSINESS_PHONE,
        1088: ERRORS.BUSINESS_PHONE,
        1102: ERRORS.BIRTHDAY,
        1119: ERRORS.HOME_PHONE,
        1165: ERRORS.ADDRESS_LINE,
        1167: ERRORS.BUSINESS_PHONE,
        1179: ERRORS.SSN,
        1180: ERRORS.SSN,
        1225: ERRORS.BUSINESS_PHONE,
        1267: ERRORS.ADDRESS_PO_BOX,
        1273: ERRORS.ADDRESS_APARTMENT_SUITE,
        1275: ERRORS.ADDRESS_PO_BOX,
        1276: ERRORS.ADDRESS_RURAL_ROUTE_LENGTH,
        1277: ERRORS.ADDRESS_STREET_NAME_LENGTH,
        1278: ERRORS.ADDRESS_STREET_NUMBER_LENGTH,
        1292: ERRORS.ADDRESS_RURAL_ROUTE_LENGTH,
        1296: ERRORS.ADDRESS_STREET_NAME_LENGTH,
        1297: ERRORS.ADDRESS_STREET_NUMBER_LENGTH,
        1334: ERRORS.ZIP_CODE_STATE,
        1337: ERRORS.ADDRESS_STREET_NUMBER_EMPTY,
        1342: ERRORS.ADDRESS_LINE,
        1343: ERRORS.HOME_PHONE,
        1344: ERRORS.ADDRESS_STREET_NUMBER_LENGTH,
        1345: ERRORS.ADDRESS_STREET_NAME_LENGTH,
        1348: ERRORS.ADDRESS_RURAL_ROUTE_REQUIRED,
        1349: ERRORS.ZIP_CODE_STATE,
        1350: ERRORS.ADDRESS_STREET_NAME_EMPTY,
        1351: ERRORS.ADDRESS_LINE,
        1352: ERRORS.ADDRESS_SUITE,
        1357: ERRORS.ADDRESS_RURAL_ROUTE_REQUIRED,
        1358: ERRORS.ADDRESS_PO_BOX_STREET_ADDRESS
    },
    SECTIONS: {
        CO_EMPLOYMENT_CURRENT: 'jointApplicantEmployment',
        CO_EMPLOYMENT_PREVIOUS: 'jointApplicantPreviousEmployment',
        CO_PERSONAL_INFO: 'jointApplicant',
        CO_RESIDENCE_CURRENT: 'jointApplicantResidence',
        CO_RESIDENCE_PREVIOUS: 'jointApplicantPreviousResidence',
        CO_SPOUSE_INFORMATION: 'jointApplicantSpouseInformation',
        PRIMARY_EMPLOYMENT_CURRENT: 'employment',
        PRIMARY_EMPLOYMENT_PREVIOUS: 'previousEmployment',
        PRIMARY_RESIDENCE_CURRENT: 'residence',
        PRIMARY_PERSONAL_INFO: 'personalInfo',
        PRIMARY_RESIDENCE_PREVIOUS: 'previousResidence',
        PRIMARY_SPOUSE_INFORMATION: 'spouseInformation'
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

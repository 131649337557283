import { EVENTS, CUSTOM_EVENTS } from 'Constants';

// Util dependencies
import { renderer, customEventDispatcher } from 'utils';
import { Carousel } from 'partials/carousel';

import videoRelatedTemplate from '../templates/videoRelatedTemplate';
import videoRelatedItemTemplate from '../templates/videoRelatedItemTemplate';

/**
 * @const ATTRIBUTES
 * @description has the defined attributes used to set behaviors
 * @type {Object}
 */
const ATTRIBUTES = {
    VIDEO_TARGET: 'data-overlay-related-video-items',
    VIDEO_BUTTON: 'data-video-id-replacement',
    ANALYTICS_NEXT: 'cta-scroll-next',
    ANALYTICS_PREVIOUS: 'cta-scroll-previous'
};

/**
 * @const defaultLocalization
 * @description  localization object for i18n content
 * @type {Object}
 */
const defaultLocalization = {
    videoHub: {
        moreInThisTopic: 'More In This Topic'
    }
};

/**
 * @const localization
 * @description constant for the localization content created
 * in src/main/content/jcr_root/apps/mb-vans/pages/base/partials/footlibs.html
 */
const localization = window.mbVans.ns('pageData').localization;

/**
 * @const carouselConfig
 * @description Configuration for the carousel used to display related videos
 * @type {{startIndex: number, infinite: boolean, indicators: boolean, transitionType: string}}
 */
const carouselConfig = {
    infinite: false,
    startIndex: 0,
    transitionType: 'sliding',
    indicators: false,
    analyticsLabels: {
        prevAnalytics: ATTRIBUTES.ANALYTICS_PREVIOUS,
        nextAnalytics: ATTRIBUTES.ANALYTICS_NEXT
    }
};

/**
 * @class VideoRelated
 * @description View component for displaying a set of videos related to the current video in an
 * Overlay Video Grid modal
 */

export default class VideoRelated {
    /**
     * @constructor Create a VideoRelated
     * @param resultsHolder {Element} DOM element that contains search results
     * @param videoPath {String} the AEM path to the video
     * @param videoCategory {String} machine-readable category used to search for related videos
     * @param sections {Object} represents all videos returned by the video search service
     */
    constructor(resultsHolder, videoPath, videoCategory, sections) {
        this.resultsElm = resultsHolder;
        this.videoPath = videoPath;
        this.videoCategory = videoCategory;
        this.sections = sections;
        this.content = { ...defaultLocalization, ...localization };

        this.createRelatedVideosGrid = this.createRelatedVideosGrid.bind(this);
        this.render = this.render.bind(this);
        this.appendResults = this.appendResults.bind(this);
        this.replaceVideo = this.replaceVideo.bind(this);

        this.carouselConfig = {
            ...carouselConfig
        };

        this.init();
    }

    /**
     * @method init
     * @description Fires off the process of getting a list of related videos.
     */
    init() {
        this.getSectionVideos();
    }

    /**
     * @method getSectionVideos
     * @description Filters the sections to select the one associated with the current video, passes the results on
     */
    getSectionVideos() {
        const selected = this.sections.filter((sectionToFilter) => sectionToFilter.tag.name === this.videoCategory);

        this.createRelatedVideosGrid(selected);
    }

    /**
     * @method getRelatedVideos
     * @description Filters the videos in a section, returns those that are not the current video
     * @param videos {Array} Array of objects representing all videos in a section
     * @returns {Array} Array of objects representing the videos that are not the current video
     */
    getRelatedVideos(videos) {
        const related = videos.filter((video) => video.path !== this.videoPath);
        return related;
    }

    /**
     * @method searchError
     * @description Placeholder for error handling
     * @returns {null}
     */

    searchError() {
        return null;
        // error handling?
    }

    /**
     * @method createRelatedVideosGrid
     * @description Orchestrates the rendering of the related videos based on data passed to it
     * @param response {Array} Array of Objects containing information about related videos as returned by service
     */
    createRelatedVideosGrid(response) {
        this.sectionVideos = response[0];

        if (!this.sectionVideos) {
            return null;
        }

        const renderedVideos = this.render(this.content);
        const videoTarget = renderedVideos.querySelector(`[${ATTRIBUTES.VIDEO_TARGET}]`);
        const videoArray = this.getRelatedVideos(this.sectionVideos.videos);
        const videosRendered = [];

        if (videoArray.length > 0) {
            videoArray.forEach((video) => {
                const videoRendered = this.renderVideo(video);
                const target = videoRendered.querySelector(`[${ATTRIBUTES.VIDEO_BUTTON}]`);
                target.addEventListener(EVENTS.CLICK, this.replaceVideo);
                videosRendered.push(videoRendered);
            });
        } else {
            return true;
        }

        const carouselVideos = new Carousel(videosRendered, this.carouselConfig);

        const carouselVideosRendered = carouselVideos.render();

        renderer.insert(carouselVideosRendered, videoTarget);

        this.appendResults(renderedVideos);

        return true;
    }

    /**
     * @method render
     * @description Renders the surrounding HTML for the related video
     * @param content {Object} Object containing internationalized static text
     * @returns {Element}
     */
    render(content) {
        return videoRelatedTemplate(content)({ getNode: true });
    }

    /**
     * @method renderVideo
     * @description Renders the HTML for a particular video
     * @param item {Object} Contains information about the video to be rendered
     * @returns {Element}
     */
    renderVideo(item) {
        return videoRelatedItemTemplate(item)({ getNode: true });
    }

    /**
     * @method appendResults
     * @description Places the related video search results HTML in the DOM
     * @param results {Element} Rendered version of the results
     */
    appendResults(results) {
        renderer.insert(results, this.resultsElm);
    }

    /**
     * @method replaceVideo
     * @description Handler for clicks on related videos, sends off information about the video
     * @param e {Event} Click event for related video
     */
    replaceVideo(e) {
        e.preventDefault();

        let theVideo;
        const videoID = e.currentTarget.dataset.videoIdReplacement;

        this.sectionVideos.videos.forEach((video) => {
            if (video.video === videoID) {
                theVideo = video;
            }
        });

        const videoObject = {
            video: theVideo,
            tag: this.sectionVideos.tag
        };

        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.RELATED_VIDEO_SELECTED,
                { detail: videoObject }
            )
        );
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { noop } from 'utils';

// Local dependencies
import QuickRegistrationForm from './QuickRegistrationForm';
import QuickRegistrationSuccess from './QuickRegistrationSuccess';
import quickRegistrationTemplate from './../templates/quickRegistrationTemplate';

/**
 * @const CLASSES
 * @description object to get the element for the dom.
 * @type {Object}
 */
const CLASSES = {
    CONTENT: '.mbs-quick-registration__content'
};

/**
 * @const defaultConfig
 * @type {Object} {
 *   analyticsTrigger: string,
 *   analyticsTriggerLogin: string,
 *   continueText: string,
 *   disableLogin: boolean,
 *   disableLoginSuccessView: boolean,
 *   disableRegistrationSuccessView: boolean,
 *   onCompleteCallback: function,
 *   onLoginCallback: function,
 *   onRegistrationCallback: function,
 *   onUpdateTitleCallback: function,
 *   redirectURL: string,
 *   userInfo: object
 * }
 */
const defaultConfig = {
    analyticsTrigger: 'account-register-quick',
    analyticsTriggerLogin: 'account-login',
    continueText: null,
    disableLogin: false,
    disableLoginSuccessView: false,
    disableRegistrationSuccessView: false,
    onCompleteCallback: noop,
    onLoginCallback: noop,
    onRegistrationCallback: noop,
    onUpdateTitleCallback: noop,
    redirectURL: null,
    userInfo: null
};

/**
 * @const defaultLocalization
 * @description  localization object for i18n content
 * @type {Object}
 */
const defaultLocalization = {
    accountCreated: 'Your Mercedes-Benz account has been created.',
    emailSent: 'We\'ve sent an email to {0}. Follow the instructions to set your password and {1}.',
    logIn: 'log in',
    continue: 'Continue',
    logInURI: '#'
};

/**
 * @const data
 * @description constant for the localization content related to quick registration created
 * on src/main/content/jcr_root/apps/mb-vans/pages/base/partials/footlibs.html
 */
const data = window.mbVans.ns('pageData').registration;

/**
 * @class  QuickRegistration
 * @description  class to manage the quick registration flows and views
 */
export default class QuickRegistration {
    /**
     * @method  constructor
     * @description  set default state for the heritance
     *  @param {Object} config
     *  @param {Object} content
     */
    constructor(config, content = {}) {
        this.content = { ...defaultLocalization, ...data, ...content  };
        this.config = { ...defaultConfig, ...config };
        this.element = quickRegistrationTemplate()({ getNode: true });
        this.onLoginCallback = this.config.onLoginCallback;
        this.onCompleteCallback = this.config.onCompleteCallback;
        this.onRegistrationCallback = this.config.onRegistrationCallback;
        this.onUpdateTitleCallback = this.config.onUpdateTitleCallback;
        this.onSuccess = this.onSuccess.bind(this);

        this.cacheDOM();
        this.addForm();
    }

    /**
     * @method cacheDOM
     * @description get the elements from the dom for the local use.
     */
    cacheDOM() {
        this.formContainer = this.element.querySelector(CLASSES.CONTENT);
    }

    /**
     * @method  addForm
     * @description  generated and append the instance of the QuickRegistrationForm
     */
    addForm() {
        const formConfig = {
            analyticsTrigger: this.config.analyticsTrigger,
            analyticsTriggerLogin: this.config.analyticsTriggerLogin,
            disableLogin: this.config.disableLogin,
            onLoginCallback: this.onLoginCallback,
            onSubmitCallback: this.onSuccess,
            redirectURL: this.config.redirectURL,
            userInfo: this.config.userInfo
        };

        this.form = new QuickRegistrationForm(formConfig, this.content);
        this.formContainer.appendChild(this.form.render());
    }

    /**
     * @method onSuccess
     * @description  with the successful submission for the quick registration form
     * create the success view.
     * @param  {Object} response
     */
    onSuccess(response) {
        this.form.destroy();
        this.onRegistrationCallback(response);

        if (!this.config.disableRegistrationSuccessView) {
            const succcessConfig = {
                benefits: this.content.benefits,
                confirmationDescription: this.content.confirmationDescription,
                continueText: this.config.continueText ? this.config.continueText : this.content.continue,
                disableLogin: this.config.disableLogin,
                disableLoginSuccessView: this.config.disableLoginSuccessView,
                email: response.email,
                firstName: response.firstName,
                confirmationHeader: this.content.confirmationHeader,
                loginText: this.content.confirmationLogIn,
                loginURL: this.content.logInURI,
                onCompleteCallback: this.onCompleteCallback,
                savedBuilds: this.content.savedBuilds,
                savedInventory: this.content.savedInventory,
                recommendations: this.content.recommendations
            };

            this.success = new QuickRegistrationSuccess(succcessConfig);
            this.element.appendChild(this.success.render());
            this.onUpdateTitleCallback(this.content.confirmationHeader);
        }
    }

    /**
     * @method destroy
     * @description destroys the form and removes all reference for the class
     */
    destroy() {
        if (this.form) {
            this.form.destroy();
        }
        this.element.remove();
    }

    /**
     * @method getTitle
     * @description returns the heading to use in the modal for accessibility
     */
    getTitle() {
        return this.content.heading;
    }

    /**
     * @methdo render
     * @description  return the HTML Node with the element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

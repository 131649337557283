// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for overlay video grid related video
 * Uses ES6 template literal to composite the markup for an overlay video grid related video
 */
export default (data = {}) => htmlNode`
    <div class="overlay-video-grid__modal-related-video">
        <button class="overlay-video-grid__modal-related-video-button" data-video-id-replacement="${data.video}">
            <div class="overlay-video-grid__videos-container overlay-video-grid__modal-related-video-container">
                <img class="overlay-video-grid__videos-container-placeholder" src="${data.thumbnail}" alt="${data.title}" />
                <span class="overlay-video-grid__videos-container-timestamp"><span>${data.timestamp}</span></span>
            </div>
            <div class="overlay-video-grid__modal-related-video-copy">
                <h4 class="overlay-video-grid__modal-related-video-heading">${data.heading}</h4>
                <h5 class="overlay-video-grid__modal-related-video-subheading">${data.subHeading}</h5>
            </div>
        </button>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

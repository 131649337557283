/**
 * @static SUBMIT_TYPES
 * @description values that determine what api the credit app is submitted to
 * @type SUBMIT_TYPE.DEFAULT {string} submits to mbfs api
 * @type SUBMIT_TYPE.FINANCE {string} submits to finance driver api
 */
export default {
    MBFS: 'mbfs',
    FINANCE: 'financeDriver'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

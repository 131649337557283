/**
 * @description Collection of option values for sorting filters
 * @type {{
 *   MOST_RECENT: string,
 *   MODEL: string,
 *   PRICE_LOW_TO_HIGH: string,
 *   PRICE_HIGH_TO_LOW: string
 * }}
 */
export default {
    MOST_RECENT: 'recent',
    MODEL: 'modelid_asc',
    PRICE_LOW_TO_HIGH: 'price_asc',
    PRICE_HIGH_TO_LOW: 'price_desc',
    YEAR_OLD_TO_NEW: 'year_asc',
    YEAR_NEW_TO_OLD: 'year_desc'
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { tokenReplace, htmlNode } from 'utils';

const createDescriptionText = (content, cookieInfo) => {
    const desc = content.confirmationDescription;
    const email = `<span class='mbs-quick-registration__email' data-analytics-trigger='cta-login'>${cookieInfo.email}</span>`;
    return tokenReplace(desc, [email]);
};

// default template for QuickRegistrationSuccess view
// @param   {String} headerText
// @param   {String} bodyText
// @param   {String} continueText
export default (content, cookieInfo) => htmlNode `
    <div class="mbs-quick-registration">
        <div class="mbs-quick-registration__content">
            <div class="mbs-quick-registration__reminder">
                <div class="mbs-quick-registration__header mbs-quick-registration__header--bottom-border">
                    ${content.confirmationHeader}
                </div>
                <div class="mbs-quick-registration__reminder-container">
                    <div class="mbs-quick-registration__reminder-description">${createDescriptionText(content, cookieInfo)}</div>
                </div>
                <div class="mbs-quick-registration__benefits">
                    <div class="mbs-quick-registration__benefits-heading">${content.benefits}</div>
                    <div class="mbs-quick-registration__benefits-icons">
                        <div class="mbs-quick-registration__confirmation-icon mbs-quick-registration__builds-icon">${content.savedBuilds}</div>
                        <div class="mbs-quick-registration__confirmation-icon mbs-quick-registration__inventory-icon">${content.savedInventory}</div>
                        <div class="mbs-quick-registration__confirmation-icon mbs-quick-registration__recommendations-icon">${content.recommendations}</div>
                    </div>
                </div>
            </div>
            <button class="mbs-quick-registration__continue button button_primary button--full-width" data-analytics-trigger="cta-continue">${content.continueText}</button>
        </div>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for the carousel navigation indicators or dots
 * Uses ES6 template literal to composite the markup for a navigation component
 * for the hero carousel
 */
 import { html } from 'utils';

 /**
  * @method Function that returns a template literal for the Carousel Indicator
  * @param isStatic {Boolean} Flag for whether the indicators are static or transition
  * @param indicatorPositionBottom {Boolean} Flag for whether the indicators should be beneath the images
  */
 export default ({ isStatic, indicatorPositionBottom }) => html`
     <div class="carousel-nav__indicator ${isStatic ? 'carousel-nav__indicator--static' : ''} ${indicatorPositionBottom ? 'carousel-nav__indicator--bottom' : ''}" aria-hidden="true">
         <ul class="carousel-nav__indicator-list"></ul>
     </div>
 `.trim();

 // do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { EVENTS } from 'Constants';
import { noop } from 'utils';

// Local dependencies
import slimDealerTemplate from './../templates/slimDealerTemplate';

/**
 * @property defaultConfig
 * @description Default configuration for SlimDealer
 * @type {{
 *     dealer: object,
 *     isPreferred: boolean,
 *     onSelectDealer: function,
 *     onHoverDealer: function
 * }}
 */
const defaultConfig = {
    dealer: null,
    isPreferred: false,
    onSelectDealer: noop,
    onHoverDealer: noop
};

/**
 * @class SlimDealer
 * @description View for displaying dealer object info slimmer than the Dealer view,
 * only showing dealer name, address and AMG icons
 */
export default class SlimDealer {
    /**
     * @constructor
     * @description On instantiation sets the config, initials data
     * and renders template
     * @param config {Object} Configuration properties
     * @param config.dealer {Object} Dealer object to display
     * @param config.isPreferred {Boolean} Is dealer the preferred dealer
     * @param config.onSelectDealer {Function} Callback method applied on click dealer
     * @param config.onHoverDealer {Function} Callback method applied on mouseenter dealer
     */
    constructor(config = {}) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.element = null;

        // method alias
        this.onSelectDealer = this.onSelectDealer.bind(this);
        this.onHoverDealer = this.onHoverDealer.bind(this);

        // initialize
        this.renderDealerTemplate();
    }

    /**
     * @method renderDealerTemplate
     * @description Renders the slimDealerTemplate
     */
    renderDealerTemplate() {
        this.element = slimDealerTemplate(
            this.config.dealer,
            this.config.isPreferred
        )({ getNode: true });

        this.attachEvents();
    }

    /**
     * @method attachEvents
     * @description Attaches event handlers from element
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.MOUSEENTER, this.onHoverDealer);
        this.element.addEventListener(EVENTS.KEYUP, this.onHoverDealer);
        this.element.addEventListener(EVENTS.CLICK, this.onSelectDealer);
        this.element.addEventListener(EVENTS.KEYDOWN, this.onSelectDealer);
    }

    /**
     * @method detachEvents
     * @description Detaches event handlers from element
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.MOUSEENTER, this.onHoverDealer);
        this.element.removeEventListener(EVENTS.KEYUP, this.onHoverDealer);
        this.element.removeEventListener(EVENTS.CLICK, this.onSelectDealer);
        this.element.removeEventListener(EVENTS.KEYDOWN, this.onSelectDealer);
    }

    /**
     * @method onSelectDealer
     * @description Handler to apply the config callback `onSelectDealer`
     */
    onSelectDealer(event) {
        const { keyCode } = event;

        if (event.type === EVENTS.CLICK || keyCode === 13) {
            this.config.onSelectDealer(this.config.dealer);
        }
    }

    /**
     * @method onHoverDealer
     * @description Handler to apply the config callback `onHoverDealer`
     */
    onHoverDealer(event) {
        const { keyCode, type } = event;

        if (type === EVENTS.MOUSEENTER || keyCode === 9) {
            this.config.onHoverDealer(this.config.dealer);
        }
    }

    /**
     * @method destroy
     * @description Destroys the view from the DOM and removes all events
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method render
     * @description Returns the view element node
     * @return {Element|*}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { generateUniqueID, noop, screen } from 'utils';

// Local dependencies
import menuSelectorTemplate from './../templates/menuSelectorTemplate';

const CLASSES = {
    MENU_ITEM: 'menu-selector__item',
    MENU_ITEM_LINK: 'menu-selector__item-link',
    MENU_ITEMS_LIST: 'menu-selector__item-list',
    MENU_OPEN: 'menu-selector--open',
    MENU_TOGGLE: 'menu-selector__selected-item',
    SELECTED: 'menu-selector__item--selected',
    MENU_ITEM_RADIO: 'menu-selector__item-radio'
};

const SELECTORS = {
    BUTTON: 'button',
};

const ARIA_ATTRIBUTES = {
    HIDDEN: 'aria-hidden',
    ARIA_SELECTED: 'aria-selected',
};

/**
 * Class represeting a MenuSelector.
 */
export default class MenuSelector {
    /**
     * @const THEMES
     * @description Available themes
     * @type {Object}
     */
    static THEMES = {
        FULL_WIDTH: 'full-width',
        FLEXIBLE_TABS: 'flexible-tabs'
    };
    /**
     * Create a MenuSelector
     * @param {Array} items - An array of items objects. Each object is represented as follows:
     * {
     *   label: 'All',
     *.  value: 'all'
     * }
     * @param {Object} [element] - The DOM element for MenuSelector
     * @param {Number} [defaultSelection=0] - The index of the default selected item
     * @param {Function} [selectionCallback=noop] - Callback when an item is selected
     */
    constructor(items, {
        element,
        defaultSelection = 0,
        hasMenuToggle = true,
        legend = '',
        a11yTip = '',
        selectionCallback = noop,
        theme = null,
        useRadioButtons = false,
        loadModule = ''
    }) {
        Object.assign(this, {
            element,
            items,
            defaultSelection,
            selectionCallback,
            hasMenuToggle,
            legend,
            a11yTip,
            theme,
            useRadioButtons
        });
        this.isMenuOpen = false;
        this.onMenuToggleClickHandler = this.onMenuToggleClick.bind(this);
        this.onSelectItemHandler = this.onSelectItem.bind(this);
        this.selectionIndex = defaultSelection;
        this.loadModule = loadModule;

        this.init();
    }

    /**
     * @method createView
     * @description Create view
     */
    createView() {
        this.element = menuSelectorTemplate({
            items: this.items,
            defaultSelection: this.defaultSelection,
            group: generateUniqueID(),
            legend: this.legend,
            a11yTip: this.a11yTip,
            theme: this.theme,
            useRadioButtons: this.useRadioButtons,
            loadModule: this.loadModule,
        })({ getNode: true });
    }

    /**
     * @method init
     */
    init() {
        if (this.element === null || this.element === undefined) {
            this.createView();
        }
        this.cacheDOM();
        this.attachEvents();
        this.updateListAriaAttribute();
    }

    /**
     * @method destroy
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
        this.element = null;
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.menuToggle = this.element.querySelector(`.${CLASSES.MENU_TOGGLE}`);
        this.menuItemContainer = this.element.querySelector(`.${CLASSES.MENU_ITEMS_LIST}`);
        this.menuItems = [].slice.call(this.element.querySelectorAll(`.${CLASSES.MENU_ITEM}`));
        this.radioButtons = [].slice.call(this.element.querySelectorAll(`.${CLASSES.MENU_ITEM_RADIO}`));
    }

    /**
     * @method attachEvents
     * @description Attaches click event to menu toggle, attaches click event to each item
     */
    attachEvents() {
        this.menuToggle.addEventListener(EVENTS.CLICK, this.onMenuToggleClickHandler);
        if (!this.useRadioButtons) {
            this.menuItems.forEach((item) =>
                item.addEventListener(EVENTS.CLICK, this.onSelectItemHandler)
            );
        } else {
            this.radioButtons.forEach((radioButton) => {
                radioButton.addEventListener(EVENTS.CHANGE, this.onSelectItemHandler);
            });
        }
        screen.addResizeListener(this.onResizeHandler.bind(this));
    }

    /**
     * @method detachEvents
     */
    detachEvents() {
        this.menuToggle.removeEventListener(EVENTS.CLICK, this.onMenuToggleClickHandler);
        if (!this.useRadioButtons) {
            this.menuItems.forEach((item) =>
                item.removeEventListener(EVENTS.CLICK, this.onSelectItemHandler)
            );
        } else {
            this.radioButtons.forEach((radioButton) => {
                radioButton.removeEventListener(EVENTS.CHANGE, this.onSelectItemHandler);
            });
        }
    }

    /**
     * @method onMenuToggleClick
     * @description Toggles the menu open/close
     */
    onMenuToggleClick() {
        if (this.isMenuOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    /**
     * @method onSelectItem
     * @description Click (Buttons) / Change (Radio Buttons) event handler for each item
     */
    onSelectItem(event) {
        // Find the index of the item selected
        const currentIndex = !this.useRadioButtons ? event.currentTarget.dataset.index : event.currentTarget.value;
        const selectedItem = this.items[currentIndex];
        // Update the radio button's checked attribute
        if (this.useRadioButtons) {
            this.radioButtons[this.selectionIndex].removeAttribute('checked');
            event.currentTarget.setAttribute('checked', 'checked');
        }
        // Remove / Add CSS classes on the previous / current element respectively.
        // We use forEach here as classList multiple add / remove is not supported on IE11
        const selectedClassNames = [CLASSES.SELECTED, `${CLASSES.SELECTED}${this.theme ? `--${this.theme}` : ''}`];
        selectedClassNames.forEach((className) => this.menuItems[this.selectionIndex].classList.remove(className));
        selectedClassNames.forEach((className) => { this.menuItems[currentIndex].classList.add(className); });
        // Update the selected label on the toggle
        if (this.hasMenuToggle) {
            this.closeMenu();
            this.updateMenuToggleLabel(selectedItem.label.trim());
        }

        if (this.loadModule === 'Gallery') {
            this.menuItems.forEach((item, i) => {
                item.querySelector(SELECTORS.BUTTON).setAttribute(
                    ARIA_ATTRIBUTES.ARIA_SELECTED,
                    Number(currentIndex) === i
                );
            });
        }

        // Update the selected index and trigger callback
        this.selectionIndex = currentIndex;
        this.selectionCallback(selectedItem.value, this.selectionIndex);
    }

    /**
     * @method render
     * @description will return the element containing list of gallery options
     * eg. Exterior, Interior
     */
    render() {
        return this.element;
    }

    /**
     * @method openMenu
     * @description add the class specific to open menu,
     * update the flag isMenuOpen and aria attribute added on element ul
     */
    openMenu() {
        this.element.classList.add(CLASSES.MENU_OPEN);
        this.isMenuOpen = true;
        this.updateListAriaAttribute();
    }

    /**
     * @method closeMenu
     * @description remove the class specific to open menu,
     * update the flag isMenuOpen and aria attribute added on element ul
     */
    closeMenu() {
        this.element.classList.remove(CLASSES.MENU_OPEN);
        this.isMenuOpen = false;
        this.updateListAriaAttribute();
    }

    /**
     * @method updateListAriaAttribute
     * @description update the aria attribute present on element ul based on screen size and,
     * visible state of element ul.
     */
    updateListAriaAttribute() {
        if (screen.getState().small) {
            this.menuItemContainer.setAttribute(ARIA_ATTRIBUTES.HIDDEN, `${!this.isMenuOpen}`);
        } else {
            this.menuItemContainer.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'false');
        }
    }

    /**
     * @method updateToggleAriaAttribute
     * @description will set the toggle button aria-hidden to false for small screen and true for large screen
     */
    updateToggleAriaAttribute() {
        if (screen.getState().small) {
            this.menuToggle.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'false');
        } else {
            this.menuToggle.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'true');
        }
    }

    /**
     * @method onResizeHandler
     * @description on resize update aria attribute and resets open state based on screen size
     */
    onResizeHandler() {
        // if the menu is open and we transitioned to a large device, close the menu
        if (!screen.getState().small && this.isMenuOpen) {
            this.closeMenu();
        } else {
            this.updateListAriaAttribute();
        }
    }

    updateMenuToggleLabel(label) {
        this.menuToggle.innerHTML = label;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

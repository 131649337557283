/**
 * Template for an logged in state for the authentication
 */
import { htmlNode } from 'utils';

export default (account) => htmlNode`
    <a href="#" class="top-nav__link top-nav__link_parent top-nav__link_account"
       data-menu-item="account">
        <span class="top-nav__label">
            ${account.title}
        </span>
    </a>
    <div class="top-nav__account-links-container top-nav__sub-nav top-nav__level"
         data-analytics-container="{ 'blade': 'header-vehicles' }">
        <a href="#" class="top-nav__link_back-btn">
            ${account.title}
        </a>
        <ul class="top-nav__account-links-container--account-links-active">
            <li class="top-nav__item mbs-auth-nav mbs-auth-nav--greeting">

            </li>
        </ul>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

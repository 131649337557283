// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    renderer,
    noop
} from 'utils';

// Partial dependencies
import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { LoadingSpinner } from 'partials/loading-spinner';

// Local dependencies
import DealerSelectorDealerList from './DealerSelectorDealerList';
import DealerSelectorMap from './DealerSelectorMap';

import { dealerSelectorSearchContent, dealerSelectorSearchContainer } from './../templates/dealerSelectorDealerSearchTemplate';
import descriptionTemplate from './../templates/dealerSelectorResultsDescriptionTemplate';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{RESULTS_LIST: string}}
 */
const ATTRIBUTES = {
    CHANGE_LOCATION_CTA: 'data-change-location-cta',
    MAP: 'data-dealer-list-map',
    RESULTS_LIST: 'data-results-list',
    RESULTS_DESC: 'data-dealer-results-desc',
    SEARCH_CONTENT: 'data-dealer-search-content'
};

export default class DealerSelectorDealerSearch {
    /**
     * @static FILTER_TYPES
     * @description FilterBy key values
     * @type {{INVENTORY: string}}
     */
    static FILTER_TYPES = dealerLocatorApi.FILTER_TYPES;

    /**
     * @constructor
     * @description Initializes DealerSelectorDealerSearch view and sets initial state
     * @param {object} config - Configuration object
     * @param {string} config.country - Country ISO
     * @param {string} config.language - Language ISO
     * @param {string} config.filterBy - FILTER_TYPES value
     * @param {boolean} config.isNewInventory - flag for inventory type
     * @param {object} location - Location object from DealerSearchBar
     * @param {content} content - Content object
     * @param {object} callbacks - Callback when location is changed
     * @param {function} callbacks.onLocationChange - Callback when location is changed
     * @param {function} callbacks.onDealerSelect - Callback when a dealer is selected
     */
    constructor(
        config = {},
        location,
        content,
        {
            onLocationChange = noop,
            onDealerSelect = noop
        } = {}) {
        this.config = config;
        this.location = location;
        this.onLocationChange = onLocationChange;
        this.onDealerSelect = onDealerSelect;
        this.content = content;

        this.element = dealerSelectorSearchContainer({ content: this.content })({ getNode: true });
        this.dealerSearchContentElm = this.element.querySelector(`[${ATTRIBUTES.SEARCH_CONTENT}]`);

        this.init();
    }

    /**
     * @method init
     * @description Searches for dealers, renders container template, caches DOM, attaches event,
     * renders results. The container is rendered only after searching to be able to render spinner
     * before dealers are rendered.
     *
     */
    init() {
        this.setLoadingSpinner();
        this.getDealers()
            .then((dealers) => {
                renderer.insert(
                    dealerSelectorSearchContent({ content: this.content })({ getNode: true }),
                    this.dealerSearchContentElm);
                this.cacheDOM();
                this.attachEvents();
                this.renderResultsDescription(dealers);
                this.renderDealerList(dealers);
                this.renderDealerMap(dealers);
            });
    }

    /**
     * @method cacheDOM
     * @description Caches reference to DOM elements from the view
     */
    cacheDOM() {
        this.changeLocationCta = this.element.querySelector(`[${ATTRIBUTES.CHANGE_LOCATION_CTA}]`);
        this.resultsListElement = this.element.querySelector(`[${ATTRIBUTES.RESULTS_LIST}]`);
        this.mapElement = this.element.querySelector(`[${ATTRIBUTES.MAP}]`);
        this.resultsDescriptionElm = this.element.querySelector(`[${ATTRIBUTES.RESULTS_DESC}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches an event listener for click of CTAs
     */
    attachEvents() {
        this.changeLocationCta.addEventListener(EVENTS.CLICK, this.onLocationChange);
    }

    /**
     * @method detachEvents
     * @description Detaches events for click ofCTA
     */
    detachEvents() {
        this.changeLocationCta.removeEventListener(EVENTS.CLICK, this.onLocationChange);
    }

    /**
     * @method setLoadingSpinner
     * @description Renders a loading spinner in the dealerResultsList container
     */
    setLoadingSpinner() {
        const loader = new LoadingSpinner();
        renderer.insert(loader.render(), this.dealerSearchContentElm);
    }

    /**
     * @method getDealers
     * @description Gets the dealers for the location searched by using the API
     * @returns {Promise} - Promise which resolves with the list of dealers
     */
    getDealers() {
        const opts = {
            radius: 'all',
            searchType: dealerLocatorApi.TYPES.dealers,
            country: this.config.country,
            language: this.config.language,
            limit: 'step',
            searchByType: this.location.searchByType,
            searchLocation: this.location.searchLocation,
            filterBy: this.config.filterBy,
            isNew: this.config.isNewInventory
        };

        return dealerLocatorApi.getDealers(opts);
    }

    /**
     * @method hoverDealer
     * @description When hovering a dealer in the Dealer List call method in map to
     * highlight its dealer pin
     */
    hoverDealer(dealer) {
        this.map.hoverDealer(dealer);
    }

    /**
     * @method renderResultsDescription
     * @description Checks first item in array is greater than minimum search distance (25KM/MI)
     * and inserts template for the results description with appropriate params to render
     * label depending if there are dealers within 25km/mi or not
     */
    renderResultsDescription(dealers) {
        if (dealers && dealers.length > 0) {
            const firstDealerDistance = parseFloat((dealers[0].distance).split(' '));
            const hasNearbyDealer = firstDealerDistance <= 25;

            const descriptionData = {
                dealerResultHeading: this.content.theseAreDealersNear,
                closestDealersHeading: this.content.closestDealers,
                searchString: this.location.searchString,
                hasNearbyDistance: hasNearbyDealer
            };

            renderer.insert(
                descriptionTemplate(descriptionData)({ getNode: true }),
                this.resultsDescriptionElm);
        }
    }

    /**
     * @method renderDealerList
     * @description Renders the DealerSelectorDealerList view
     * @param dealers {array} - An array of dealers from the API
     */
    renderDealerList(dealers) {
        this.dealerSelectorDealerList = new DealerSelectorDealerList(
            dealers,
            {
                onDealerSelect: this.onDealerSelect.bind(this),
                onDealerHover: this.hoverDealer.bind(this)
            }
        );
        this.resultsListElement.appendChild(this.dealerSelectorDealerList.render());
    }

    /**
     * @method renderDealerMap
     * @description Renders the DealerSelectorDealerMap view
     * @param dealers {array} - An array of dealers from the API
     */
    renderDealerMap(dealers) {
        this.map = new DealerSelectorMap(
            this.config,
            this.mapElement,
            dealers,
            this.onDealerSelect.bind(this)
        );
    }

    /**
     * @method destroy
     * @description Destroys the current instance by detaching events and removing element
     */
    destroy() {
        this.detachEvents();
        this.dealerSelectorDealerList.destroy();
        this.element.remove();
    }

    /**
     * @method render
     * @description Returns the element attribute
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

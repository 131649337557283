// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    domState,
    ClickOutside,
    noop
} from 'utils';

// Local dependencies
import authNavDropdownTemplate from './../templates/authNavDropdownTemplate';
import authNavLinkTemplate from './../templates/authNavLinkTemplate';
import authNavButtonTemplate from './../templates/authNavButtonTemplate';
import DEFAULT_AUTHENTICATION_INFO from './../config/authenticationInfo';

/**
 * @const CLASSES
 * @description  helper for catch the dom elements
 * @type {Object}
 */
const CLASSES = {
    DROP_DOWN: 'mbs-auth-nav__dropdown',
    LINK_LIST: 'mbs-auth-nav__links-container',
    ACTIVE: 'mbs-auth-nav__dropdown--active',
    CTA: 'mbs-auth-nav__cta',
    CTA_ACTIVE: 'mbs-auth-nav__cta--active',
    NAME: 'mbs-auth-nav__name',
    BUTTON: 'mbs-auth-nav__button'
};

/**
 * @const DEFAULT_CONFIG
 * @description helper to map out config object
 * @type {Object}
 */
const DEFAULT_CONFIG = {
    links: {},
    profile: {},
    authentication: {
        ...DEFAULT_AUTHENTICATION_INFO
    },
    onLogout: noop
};

/**
 * @class AuthNavDropdown
 * @description this class paint the dropdown element for the profile
 * menu on large view
 * @param {Node} container
 * @param {Array} links
 */
export default class AuthNavDropdown {
    /**
     * @method constructor
     * @description  manage the default values for the inheritance
     */
    constructor(container, config = DEFAULT_CONFIG) {
        this.container = container;
        this.config = {
            ...DEFAULT_CONFIG,
            ...config
        };
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.dropdownOpen = false;

        this.init();
    }

    /**
     * @method init
     * @description initialize the class
     */
    init() {
        this.render();
        this.cacheDOM();
        this.renderLinks();
        this.showGreeting();
        // this required the DOM ready to attach the events
        // to properly working with the click outside utility
        domState.onReady(this.attachEvents.bind(this));
    }

    /**
     * @method showGreeting
     * @description show the name for the authenticated user
     */
    showGreeting() {
        let name = this.config.profile.name;
        if (name.length > 15) {
            name = `${name.substring(0, 15)}...`;
        }
        this.nameElm.innerHTML = `${this.config.authentication.localization.hi} ${name}`;
    }

    /**
     * @method cacheDOM
     * @description  cache the elements required for the class
     */
    cacheDOM() {
        this.element = this.container.querySelector(`.${CLASSES.DROP_DOWN}`);
        this.linksElm = this.container.querySelector(`.${CLASSES.LINK_LIST}`);
        this.nameElm = this.container.querySelector(`.${CLASSES.NAME}`);
        this.cta = this.container.querySelector(`.${CLASSES.CTA}`);
    }

    /**
     * @method attachEvents
     * @description Attaches a ClickOutside to this.cta to toggle the dropdown and
     * an event listener to the logOutCta to apply the logOutClickHandler on click
     */
    attachEvents() {
        this.clickHandler = new ClickOutside(
            this.cta,
            this.toggleDropdown,
            this.close,
            this.element);

        this.logOutCta.addEventListener(EVENTS.CLICK, this.config.onLogout);
    }

    /**
     * @method detachEvents
     * @description Destroys cta ClickOutside instance and removes the event listener
     * to the logOutCta
     */
    detachEvents() {
        this.clickHandler.destroy();
        this.clickHandler = null;
        this.logOutCta.removeEventListener(EVENTS.CLICK, this.config.onLogout);
    }

    /**
     * @method toggleDropdown
     * @description  manage the open/close state for the dropdown
     */
    toggleDropdown() {
        if (this.dropdownOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * @method open
     * @description  open the dropdown
     */
    open() {
        this.element.classList.add(CLASSES.ACTIVE);
        this.cta.classList.add(CLASSES.CTA_ACTIVE);
        this.element.setAttribute('aria-expanded', 'true');
        this.dropdownOpen = true;
    }

    /**
     * @method close
     * @description  close the dropdown
     */
    close() {
        this.element.classList.remove(CLASSES.ACTIVE);
        this.cta.classList.remove(CLASSES.CTA_ACTIVE);
        this.element.setAttribute('aria-expanded', 'false');
        this.dropdownOpen = false;
    }

    /**
     * @method  render
     * @description  add the class to the DOM
     */
    render() {
        this.container.appendChild(authNavDropdownTemplate()({ getNode: true }));
    }

    /**
     * @method renderLinks
     * @description render the links on the template
     */
    renderLinks() {
        this.config.links.forEach((link) => {
            let target;
            if (link.linkText.toLowerCase().indexOf('mercedes me') !== -1) {
                target = '_blank';
            } else {
                target = '_self';
            }
            if (link.linkText.toLowerCase().indexOf('log out') !== -1) {
                const BUTTON_TEMPLATE = authNavButtonTemplate(link, false)({ getNode: true });
                this.linksElm.appendChild(BUTTON_TEMPLATE);
                this.logOutCta = BUTTON_TEMPLATE.querySelector(`.${CLASSES.BUTTON}`);
            } else {
                const LINK_TEMPLATE = authNavLinkTemplate(link, false, target)({ getNode: true });
                this.linksElm.appendChild(LINK_TEMPLATE);
            }
        });
    }

    /**
     * @method destroy
     * @description  remove all reference from the class on the DOM
     */
    destroy() {
        this.detachEvents();
        this.cta.parentNode.removeChild(this.cta);
        this.element.parentNode.removeChild(this.element);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

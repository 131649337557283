import { EVENTS } from 'Constants';
import { cookie } from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {{DISMISS_BUTTON: string}}
 */
const CLASSES = {
    BANNER_INNER: 'alert-banner__inner',
    HIDE: 'hide--height',
    TRANSITION: 'alert-banner--transition',
    DISMISS_BUTTON: 'alert-banner__dismiss-button'
};

/**
 * @const LOCAL_CONSTANTS
 * @description Stores a collection of local constants for use into the component
 * @type {{ALERT_DISMISS_COOKIE: string}}
 */
const LOCAL_CONSTANTS = {
    ALERT_DISMISS_COOKIE: 'mbusa_alerts_dismissed'
};

/**
 * @class AlertBanner
 * @description component for the AlertBanner partial
 */
export default class AlertBanner {
    /**
     * @constructor
     * @param element {Object}
     */
    constructor(element) {
        this.element = element;

        // method alias
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.dismissAlert = this.dismissAlert.bind(this);

        this.init();
    }

    /**
     * @method init
     * @description Caches DOM, Attaches events
     */
    init() {
        if (cookie.getCookie(LOCAL_CONSTANTS.ALERT_DISMISS_COOKIE) === '') {
            this.cacheDOM();
            this.attachEvents();
            this.show();
        } else {
            this.destroy();
        }
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.bannerInner = this.element.querySelector(`.${CLASSES.BANNER_INNER}`);
        this.dismissBtn = this.element.querySelector(`.${CLASSES.DISMISS_BUTTON}`);
    }

    /**
     * @method attachEvents
     * @description adds event listeners for our click events
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.TRANSITION_END, this.onTransitionEnd);
        this.dismissBtn.addEventListener(EVENTS.CLICK, this.dismissAlert);
    }

    /**
     * @method show
     * @description shows the alert banner
     */
    show() {
        this.element.style.height = `${this.bannerInner.offsetHeight}px`;
        this.element.classList.remove(CLASSES.HIDE);
    }

    /**
     * @method detachEvents
     * @description removes event listeners for our click events
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.TRANSITION_END, this.onTransitionEnd);
        if (this.dismissBtn) {
            this.dismissBtn.removeEventListener(EVENTS.CLICK, this.dismissAlert);
        }
    }

    /**
     * @method destroy
     * @description destroy the DOM element
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
        delete this.element;
    }

    /**
     * @method onTransitionEnd
     * @description handler for this.element 'transitionend' event
     */
    onTransitionEnd() {
        this.element.classList.remove(CLASSES.TRANSITION);
        this.element.style.height = '';
    }

    /**
     * @method dismissAlert
     * @description dismiss the alert banner and set a cookie for persistent dismiss
     */
    dismissAlert() {
        cookie.setCookie({
            name: LOCAL_CONSTANTS.ALERT_DISMISS_COOKIE,
            value: 'true',
            isSession: true
        });
        this.destroy();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { serializeObject } from 'utils';

const mapByoParamsCache = {};

/**
 * @function cacheBYOParams
 * @description Caches BYO params response based on vehicle params
 * @params {Object} vehicleParams - | delimited value of BYO params request
 * @returns {Object} response from BYO Params service
 */
function cacheBYOParams(vehicleParams, response) {
    mapByoParamsCache[vehicleParams] = response;

    return response;
}

/**
 * @function getCategoryOptionIds
 * @description Gets the option IDs of the of selected options under the category passed in
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @param {String} categoryName - Category for which options should be retrieved
 * @returns {String} A concatenated string of all option ID values required by Map BYO Params API
 */
function getCategoryOptionIds(vehicleBuild, categoryName) {
    return Object.values(vehicleBuild.options)
        .map((category) => category[categoryName] || [])
        .flatten()
        .filter((p) => p.category.toLowerCase() !== 'service')
        .map((p) => p.id.split(':')[1])
        .join()
        .trim();
}

/**
 * @function generateInventoryUri
 * @description Generates Inventory URI based on BYO Info object from BYO Params service
 * @params {String} viewInventoryUri - View Inventory base URI
 * @params {Object} byoInfo - Parsed object based on BYO params API
 * @returns {String} Inventory URI
 */
function generateInventoryUri(viewInventoryUri, byoInfo) {
    let inventoryUri = viewInventoryUri;

    if (byoInfo.year) {
        inventoryUri += `/year-${byoInfo.year}`;
    }

    if (byoInfo.model) {
        inventoryUri += `/model-${byoInfo.model}`;
    }

    if (byoInfo.exteriorColor) {
        inventoryUri += `/exteriorColor-${byoInfo.exteriorColor}`;
    }

    if (byoInfo.interiorColor) {
        inventoryUri += `/interiorColor-${byoInfo.interiorColor}`;
    }

    if (byoInfo.mappedFeatures) {
        inventoryUri += `/option-${byoInfo.mappedFeatures}`;
    }

    if (byoInfo.notMappedFeatures) {
        inventoryUri += `/byoOption-${byoInfo.notMappedFeatures.replace(/-/g, '__').replace(/\//g, '..').replace(/ /g, '+')}`;
    }

    return inventoryUri;
}

/**
 * @function parseBYOParams
 * @description Generates BYO Info Object based on BYO Params response
 * @params {Object} vehicleBuild - Vehicle Build Object
 * @params {Object} response - BYO Params response
 * @returns {Object} BYO Info Object
 */
function parseBYOParams(vehicleBuild, response) {
    const exteriorColor = Object.keys(response.result['ext-color']).join(',');
    const interiorColor = Object.values(response.result['int-color']).join(',');
    const mappedFeatures = Object.keys(response.result['mapped-features']).join(',');
    const notMappedObj = response.result['not-mapped-features'];
    const notMappedFeatures = Object.keys(notMappedObj).map((key) => `${key}|${notMappedObj[key]}`).join(',');

    return {
        exteriorColor,
        interiorColor,
        mappedFeatures,
        notMappedFeatures,
        year: vehicleBuild.year,
        model: vehicleBuild.model,
        bodyStyle: vehicleBuild.bodyStyle,
        class: vehicleBuild.class,
        cylinder: vehicleBuild.cylinder,
        wheelbase: vehicleBuild.wheelbase,
    };
}

/**
 * @function getVehicleBuildParams
 * @description
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @param {Object} mapParamsUri - map service uri
 * @returns {Object} vehicle build params
 */
export function getVehicleBuildParams(vehicleBuild, mapParamsUri) {
    const optionIds = getCategoryOptionIds(vehicleBuild, 'STAND_ALONE_OPTIONS');
    const packageIds = getCategoryOptionIds(vehicleBuild, 'PACKAGES');
    const brand = window.mbVans.ns('pageData', 'brand');

    const vehicleParams = {
        class: vehicleBuild.class,
        model: vehicleBuild.model,
        year: vehicleBuild.year,
        extColorId: vehicleBuild.options.color.PAINT[0].id.split(':')[1],
        intColorId: vehicleBuild.options.color.UPHOLSTERY[0].id.split(':')[1],
        optionIds,
        packageIds,
        brand
    };

    const url = `${mapParamsUri}${serializeObject(vehicleParams)}`;
    const cacheKey = Object.values(vehicleParams).join('|');

    if (mapByoParamsCache[cacheKey]) {
        return Promise.resolve(mapByoParamsCache[cacheKey])
            .then(parseBYOParams.bind(null, vehicleBuild));
    }

    return fetch(url)
        .then((response) => response.json())
        .then(cacheBYOParams.bind(null, cacheKey))
        .then(parseBYOParams.bind(null, vehicleBuild));
}

/**
 * @method constructInventoryUri
 * @description Constructs the "View Inventory" URI for Classic inventory with the required config
 * @param {String} serviceURL - Map BYO Params Service URI
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @param {String} viewInventoryUri - View Inventory URI
 * @return {String} Constructed URI
 */
export function constructInventoryUri(serviceURL, vehicleBuild, viewInventoryUri) {
    return getVehicleBuildParams(vehicleBuild, serviceURL)
        .then(generateInventoryUri.bind(null, viewInventoryUri));
}

export default {};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Local dependencies
import videoHubVideoTemplate from '../templates/videoHubVideoTemplate';

/**
 * @class VideoHubVideo
 * @description View component for displaying a Overlay Video Grid video
 */

export default class VideoHubVideo {
    /**
     * @constructor Create a VideoHubVideo
     * @param data {Object} Object containing information about the video to be shown
     */
    constructor(data) {
        this.data = data;

        this.render = this.render.bind(this);
    }

    /**
     * @method render
     * @description given a VideoHubVideo, it returns the associated element with
     * rendered template using properties from data
     * @returns {Element} A rendered element with the template
     */
    render() {
        return videoHubVideoTemplate(this.data)({ getNode: true });
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

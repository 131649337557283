// Util dependencies
import { htmlNode } from 'utils';

/**
 *
 */
export default ({
    accept,
    analyticsTrigger,
    ctaLabel,
    id,
    inputClassNames,
    labelText,
    required }) => htmlNode`
    <div class="form__input form__file-input-container ${inputClassNames}">
        <p class="form__file-input-label">${labelText}</p>

        <div class="form__file-input-list"></div>

        <label class="form__file-input-cta button button_secondary button--wide" for="${id}" data-analytics-trigger="cta-upload">${ctaLabel}</label>

        <input
            type="file"
            id="${id}"
            class="form__file-input"
            ${name !== '' && `name="${name}"`}
            accept="${accept}"
            ${analyticsTrigger ? ` data-analytics-trigger="${analyticsTrigger}"` : ''}
            ${required ? ' required' : ''}
            aria-required="${required}"
            ${required ? ' data-required ' : ''}
            />

        <span class="form__input-error" id="error${id}"></span>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

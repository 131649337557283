import { htmlNode } from 'utils';

export default (content, config) => htmlNode`
    <div class="recalls-landing">
        <div class="recalls-hero">
            <div class="recalls-hero__wrapper wrapper">
                <div class="recalls-hero__content" data-analytics-container="{'blade': 'recalls-heading'}">
                    <div class="recalls-hero__heading">
                        <h2 class="recalls-hero__title">${content.hero.title}</h2>
                        <div class="recalls-hero__body rte">${content.hero.body}</div>
                        <a href="${content.hero.nhtsaCTA.link}" class="recalls-hero__link link link_plain-link" data-analytics-trigger="cta">${content.hero.nhtsaCTA.label}</a>
                        ${config.alternateLanguagePage ?
                            `<a href="${config.alternateLanguagePage}" class="recalls-hero__link link link_plain-link" data-analytics-trigger="cta">${config.alternateLanguagePageLabel}</a>` : ''}
                    </div>
                    <div class="recalls-hero__search-module">
                    </div>
                </div>
            </div>
        </div>
        <div class="recalls-body">
            <div class="recalls-body__wrapper wrapper">
                <div class="recalls-body__content">
                ${config.pageType === 'warranty' ?
                `<div class="warranty">
                </div>` :
                `<div class="recalls">
                    ${
                        content.urgentRecallSection.map((urgentContent) => `
                            <div class="recalls-body__urgent-content" data-analytics-container="{'blade': 'takata-link'}">
                                <h3 class="recalls-body__title">${urgentContent.title}</h3>
                                <div class="recalls-body__body">${urgentContent.body}</div>
                                <a href="${config.takataPage}" class="recalls-body__link link link_secondary" data-analytics-trigger="cta">${urgentContent.cta.label}</a>
                            </div>
                        `).join('')
                    }
                </div>`}
                </div>
            </div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import {
    customEventDispatcher,
    noop,
    generateUniqueID
} from 'utils';

// Local dependencies
import { FORM_CONTROL_EVENTS } from 'partials/form-control';
import InputRadioControl from './InputRadioControl';
import inputRadioGroupControlTemplate from './../templates/inputRadioGroupControlTemplate';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 */
const ATTRIBUTES = {
    ARIA_DESCRIBEDBY: 'aria-describedby'
};

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    RADIO_GROUP: 'form__radio-control-group',
    ERROR_ELEMENT: 'form__input-error',
    ERROR: 'error'
};

/**
 * Class that will set up an InputRadioGroupControl
 */
export default class InputRadioGroupControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputRadioGroupControl
     * @type {Object}
     * @const name {String} name for each radio button
     * @const required {Boolean} True if a radio button has to be checked
     * @const options {Array} Array of objects with info for each radio control. Each option
     * must include a 'config' object with the required configuration for a InputRadioControl
     * like valueText, labelText, checked, etc.
     * @const inputClassNames {Array} Collection of class names for radio group wrapper
     * @const onChangeCallback {Function} Callback function for change event
     * @const errorMessage {String} Error message to display when invalid
     * @const labelOffscreen {Boolean} Indicator to hide the label offscreen
     */
    static DEFAULT_CONFIG = {
        analyticsTrigger: '',
        formId: '',
        name: '',
        required: false,
        options: [],
        id: '',
        inputClassNames: [],
        alignHorizontal: false,
        onChangeCallback: noop,
        errorMessage: '',
        labelOffscreen: false
    };

    /**
     * @constructor Create an InputRadioGroupControl
     */
    constructor(config = InputRadioGroupControl.DEFAULT_CONFIG) {
        this.config = {
            ...InputRadioGroupControl.DEFAULT_CONFIG,
            ...config
        };
        this.analyticsTrigger = config.analyticsTrigger;
        this.name = config.name;
        this.id = config.id;
        this.valid = true; // stores the validity of the radio group
        this.errorElement = null; // stores the error DOM element
        this.radioGroupContainer = null; // stores the container DOM element
        this.radioGroupElement = null; // stores the radio group DOM element
        this.radios = []; // stores an array of InputRadioControl
        this.init();
    }

    /**
     * @method init
     * @description Creates the radio group DOM element and appends the radio buttons to it
     */
    init() {
        this.radioGroupContainer = inputRadioGroupControlTemplate({
            analyticsTrigger: this.config.analyticsTrigger,
            id: this.id,
            inputClassNames: this.config.inputClassNames.join(' '),
            label: this.config.label,
            required: this.config.required,
            alignHorizontal: this.config.alignHorizontal,
            labelOffscreen: this.config.labelOffscreen
        })({ getNode: true });

        this.cacheDOM();
        this.createRadioOptions();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.radioGroupElement = this.radioGroupContainer.querySelector(`.${CLASSES.RADIO_GROUP}`);
        this.errorElement = this.radioGroupContainer.querySelector(`.${CLASSES.ERROR_ELEMENT}`);
    }

    /**
     * @method createRadioOptions
     * @description Creates one InputRadioControl for each option in list
     * assigning same 'name' to each of them
     */
    createRadioOptions() {
        this.config.options.forEach((option) => {
            const radio = new InputRadioControl({
                ...option.config,
                formId: this.config.formId,
                id: generateUniqueID(),
                name: this.config.name,
                onChangeCallback: this.onChange.bind(this)
            });
            this.radios.push(radio);
            this.radioGroupElement.appendChild(radio.render());
        });
    }

    /**
     * @method register
     * @description Dispatches an event to notify a form that an input should be registered
     */
    register() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.REGISTER,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method unregister
     * @description Dispatches an event to notify a form that an input should be unregistered
     */
    unregister() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.UNREGISTER,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method destroy
     * @description Destroys the instance by destroying the radio options and removing
     * the element from the DOM
     */
    destroy() {
        this.radios.forEach((radio) => {
            radio.destroy();
        });
        this.radioGroupContainer.remove();
    }

    /**
     * @method validate
     * @description Check validation
     */
    validate(displayError = true) {
        if (this.config.required) {
            this.valid = this.radios.some((radio) => radio.getInputElement().checked);

            if (displayError) {
                this.setErrorStatus(!this.valid, this.config.errorMessage);
            }
        }
        return this.isValid();
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if input value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method getName
     * @description Gets the name of this group
     * @returns {String} Name of the radio group which is propagated to the individual radio buttons
     */
    getName() {
        return this.name;
    }

    /**
     * @method getValue
     * @description Gets the value of the radio group
     * @returns {String} - value of checked radio control
     */
    getValue() {
        const checkedRadio = this.getCheckedRadio();
        if (checkedRadio) {
            return checkedRadio.getValue();
        }

        return null;
    }

    /**
     * @method getCheckedRadio
     * @description filters radios to get selected option
     * @returns {Object} - selected radio control
     */
    getCheckedRadio() {
        const checkedRadio = this.radios.filter((radio) => radio.getInputElement().checked);
        return checkedRadio && checkedRadio[0];
    }

    /**
     * @method selectOption
     * @description toggles the checked value for the given option if the option is not checked
     * @param optionIndex {Number} - index of radio control to toggle checked value
     */
    selectOption(optionIndex) {
        if (this.radios[optionIndex] && !this.radios[optionIndex].isChecked()) {
            this.radios[optionIndex].toggleChecked();
        }
    }

    /**
     * @method unselect
     * @description removes the selection from the radio group
     */
    unselect() {
        const checkedRadio = this.getCheckedRadio();
        if (checkedRadio) {
            checkedRadio.toggleChecked();
        }
    }

    /**
     * @method toggleInputErrorStatus
     * @description Toggles the radio inputs' ARIA_DESCRIBEDBY values based on the error status
     * so that an inputs error message is read properly by screen readers
     * @param error
     */
    toggleInputErrorStatus(error) {
        this.radios.forEach((radio) => {
            if (error) {
                radio.inputElement.setAttribute(ATTRIBUTES.ARIA_DESCRIBEDBY, `error-${this.id}`);
            } else {
                radio.inputElement.removeAttribute(ATTRIBUTES.ARIA_DESCRIBEDBY);
            }
        });
    }

    /**
     * @method setErrorStatus
     * @description Toggles error class
     * @param error {Boolean} whether there is an error
     * @param message {String} Error message to display
     */
    setErrorStatus(error, message) {
        if (error) {
            this.radioGroupContainer.classList.add(CLASSES.ERROR);
            this.errorElement.innerHTML = message;
        } else {
            this.radioGroupContainer.classList.remove(CLASSES.ERROR);

            this.errorElement.innerHTML = '';
        }

        this.toggleInputErrorStatus(error);
    }

    /**
     * @method dispatchChange
     * @description Dispatches an event to notify a form that an input has changed its value
     */
    dispatchChange() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.INPUT_CHANGE,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method onChange
     * @description onChange callback to validate the group
     */
    onChange(e) {
        if (this.config.validateOnChange) {
            this.validate();
        }
        this.config.onChangeCallback(e.currentTarget.value);
        this.dispatchChange();
    }

    /**
     * @method render
     * @description Return the radio group container element
     * @returns {Element} Radio group template element
     */
    render() {
        return this.radioGroupContainer;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for forms vehicle selector
 * Uses ES6 template literal to composite the markup for a vehicle selector
 */
export default ({ heading }) => htmlNode`
    <div class="vehicle-selector-modal wrapper">
        <h2 class="vehicle-selector-modal__overlay-heading">
            ${heading}
        </h2>
        <div class="vehicle-selector-modal__dropdowns-container"></div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

const defaultConfig = {
    country: window.mbVans.ns('pageData').country,
    dealerSearchBar: {
        buttonStyle: 'button button_secondary_alt'
    },
    distanceUnit: window.mbVans.ns('pageData', 'applicationProperties', 'distance').distanceUnit || 'Mile',
    distanceUnitAbbr: window.mbVans.ns('pageData', 'applicationProperties', 'distance').distanceUnitAbbr || 'mi',
    language: window.mbVans.ns('pageData').language,
    presetDealerEnableChange: false,
    presetDealerId: null,
    showMap: true,
    showInventory: true,
};

const {
    country,
    dealerSearchBar: {
        buttonStyle
    },
    distanceUnit,
    distanceUnitAbbr,
    language,
    presetDealerId,
    presetDealerEnableChange,
    showMap,
    showInventory
} = Object.assign(defaultConfig, window.mbVans.ns('pageData', 'chooseADealerPlugin').config);
// Configuration options
export default {
    country,
    dealerSearchBar: {
        buttonStyle
    },
    distanceUnit,
    distanceUnitAbbr,
    language,
    presetDealerEnableChange,
    presetDealerId,
    showMap,
    showInventory
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for waypoint nav
 * Uses ES6 template literal to composite the markup for a waypoint nav
 * @param waypoints {Array} the array of waypoint nav items
 */
export default (waypoints = []) => `
    ${waypoints.map((waypoint) => `
        <li>
            <a class="waypoint-nav__link" href="#${waypoint.waypointKey}"
               data-analytics-trigger="${waypoint.dataAnalyticsTrigger ? waypoint.dataAnalyticsTrigger : ''}">
                <span class="waypoint-nav__label">${(waypoint.waypointNumber) ? `${waypoint.waypointNumber}` : ''}
                    <span class="waypoint-nav__label-inner">${waypoint.waypointLabel}</span>
                </span>
                <span class="waypoint-nav__dot"></span>
            </a>
        </li>
    `).join('')}`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

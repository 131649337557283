import { htmlNode } from 'utils';

export default (content, pageType) => htmlNode`
    <div class="recalls-vin">
        <div class="recalls-vin__form">
            <div class="recalls-vin__input"></div>
            ${pageType !== 'warranty' ? `
                <div class="recalls-vin__disclaimer">${content.disclaimer}</div>
            ` : ''}
            <button class="recalls-vin__cta button button_primary button--full-width" data-analytics-trigger="cta-recall-landing-vin-submit">${content.cta}</button>
            <div class="recalls-vin__error"></div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

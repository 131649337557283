// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom input control
 * Uses ES6 template literal to composite the markup for a custom input control
 */
export default ({ type = '', id = '', name = '', dataAttributes = '', disablePaste = false, inputClassNames = '', labelClassNames = '', labelIconClass = '', required = false,
                  allowReset = true, focused = false, valueText = '', labelText = '', ariaLabel = '', pattern = '', minLength = '', maxLength = '', moreInfoText = '',
                  placeholder = '', disabled = false, readOnly = false, analyticsTrigger = '', formatMask = '', inputMode = '' }) => htmlNode`
    <div class="form__input ${inputClassNames} ${focused ? 'focused' : ''} ${readOnly ? 'readonly' : ''} ${type === 'hidden' ? 'hidden' : ''}">
        <input class="form__input-field form__input-field--can-reset ${formatMask ? 'form__input-field--has-mask' : ''}"
            type="${type}"
            id="${id}"
            ${name !== '' && `name="${name}"`}
            ${required ? ' required' : ''}
            ${dataAttributes ? ` ${dataAttributes}` : ''}
            ${disablePaste ? ' onpaste="return false;"' : ''}
            ${valueText !== undefined && valueText !== null ? ` value="${valueText}"` : ''}
            ${pattern ? ` pattern="${pattern}"` : ''}
            ${placeholder ? ` placeholder="${placeholder}"` : ''}
            ${minLength ? ` minlength="${minLength}"` : ''}
            ${maxLength ? ` maxlength="${maxLength}"` : ''}
            ${required ? ' data-required ' : ''}
            ${disabled ? ' disabled="disabled"' : ''}
            ${readOnly ? ' readonly="readonly"' : ''}
            ${analyticsTrigger ? ` data-analytics-trigger="${analyticsTrigger}"` : ''}
            aria-required="${required}"
            aria-labelledby="label-${id}"
            ${inputMode ? ` inputmode="${inputMode}"` : ''}
            />
        <label class="form__input-label ${placeholder ? 'has-placeholder' : ''} ${labelClassNames} ${valueText ? 'focused' : ''}" id="label-${id}" for="${id}">
            <span class="form__input-label-text" aria-hidden="true" ${required ? ' data-required ' : ''}>${labelIconClass ? `<span class="mb-icon ${labelIconClass} form__input-icon"></span> ` : ''}${labelText}</span>
            <span class="offscreen">${ariaLabel !== '' ? ariaLabel : labelText}</span>
        </label>
       ${allowReset ? '<button class="form__input-reset" tabindex="-1" aria-label="Delete" type="button"><span class="offscreen">Delete</span></button>' : ''}

        ${formatMask ? `<div id="mask-${id}" class="form__input-field form__input-masked-element"></div>` : ''}
       <span class="form__input-error" id="error${id}"></span>
       ${moreInfoText !== '' ? `<span class="form__input-more-info-text">${moreInfoText}</span>` : ''}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

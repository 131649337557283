// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for overlay video grid related videos scaffolding
 * Uses ES6 template literal to composite the markup for overlay video grid section for related videos
 */
export default (content = {}) => htmlNode`
    <div class="overlay-video-grid__modal-related-template-inner">
        <section>
            <h3 class="overlay-video-grid__modal-related-header">${content.videoHub.moreInThisTopic}</h3>
            <div class="overlay-video-grid__modal-related-carousel" data-overlay-related-video-items></div>
        </section>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Utils imports
import { noop } from 'utils';

// Local imports
import simpleFormSuccessTemplate from './../templates/simpleFormSuccessTemplate';

/**
 * @const CLASSES
 * @description selecto for classes
 * @type {Object}
 */
const CLASSES = {
    CONTINUE_BUTTON: '__continue-button'
};

/**
 * @constant defaultConfig
 * @type {Object}
 */
const defaultConfig = {
    header: '',
    body: '',
    continueLabel: '',
    onContinue: noop,
    hasCta: true,
    parentClass: 'simple-form-success'
};

/**
 * @class  SimpleFormSuccess
 * @description  show the success state after quick registration
 * @param {Object} config
 */
export default class SimpleFormSuccess {
    /**
     * @method constructor
     * @description setup the default state for the view
     * @param  {Object} config
     */
    constructor(config) {
        this.config = { ...defaultConfig, ...config };
        this.onContinue = this.config.onContinue;

        this.init();
    }

    /**
     * @method init
     * @description  init the view
     */
    init() {
        this.createView();
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method createSuccess
     * @description  get the node from the template to be render
     */
    createView() {
        this.element = simpleFormSuccessTemplate({
            ...this.config
        })({ getNode: true });
    }

    /**
     * @method cacheDOM
     * @description  this function get the elements for the dom
     */
    cacheDOM() {
        this.continueButton = this.element.querySelector(`.${this.config.parentClass}${CLASSES.CONTINUE_BUTTON}`);
    }

    /**
     * @method attachEvents
     * @description  attach events to the DOM
     */
    attachEvents() {
        if (this.onContinue) {
            this.continueButton.addEventListener('click', this.onContinue);
        }
    }

    /**
     * @method render
     * @description return the dom element to be rendered
     * @return {XML}
     */
    render() {
        return this.element;
    }

    /**
     * @method destroy
     * @description  remove all reference for the class
     */
    destroy() {
        this.element.remove();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

export default {
    SAVED_INVENTORY: {
        key: 'SAVED_INVENTORY',
        subscriptions: [
            'saved_inventory_price_change',
            'saved_inventory_veh_sold',
            'saved_inventory_incentive',
            'saved_inventory_res_status'
        ]
    },
    SAVED_BUILD: {
        key: 'SAVED_BUILD',
        subscriptions: [
            'saved_build_view_similar'
        ]
    },
    SAVED_DEALS: {
        key: 'SAVED_DEALS',
        subscriptions: [
            'res_req_price_change',
            'res_req_veh_sold',
            'res_req_incentive'
        ]
    }
};

/**
 * @static CREDIT_APP_IDS
 * @description Unique Id for mapping actions to state for primary vs co-applicant instances
 */
export default {
    APPLICATION: 'creditApp',
    STEPS: {
        PERSONAL_INFO: 'primaryPersonalInfo',
        CO_PERSONAL_INFO: 'coApplicantPersonalInfo',
        EMPLOYMENT_INFO: 'primaryEmployment',
        CO_EMPLOYMENT_INFO: 'coApplicantEmployment',
        RESIDENCE_INFO: 'primaryResidence',
        CO_RESIDENCE_INFO: 'coApplicantResidence'
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

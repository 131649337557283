// Local dependencies
import SelectOptions from './SelectOptions';
import selectCustomOptionControlTemplate from './../templates/selectCustomOptionControlTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @memberof SelectCustomOptionControl
 * @type {{
 *      HIDE: string,
 *      ERROR: string,
 *      ERROR_ELEMENT: string,
 *      LABEL_ELEMENT: string,
 *      LABEL_ELEMENT_LABELED: string,
 *      OPEN: string,
 *      SELECT_HAS_VALUE: string,
 *      SELECT_TOGGLE: string,
 *      SELECT_TOGGLE_LABEL: string,
 *      SELECT_OPTION_LIST: string,
 *      SELECT_OPTION: string,
 *      SELECTED: string,
 *      TOOL_TIP: string
 * }}
 */
const CLASSES = {
    HIDE: 'hide',
    ERROR: 'select-custom-option-control--error',
    ERROR_ELEMENT: 'select-custom-option-control__error-message',
    LABEL_ELEMENT: 'select-custom-option-control__input-label',
    LABEL_ELEMENT_LABELED: 'select-custom-option-control__input-label--labeled',
    OPEN: 'select-custom-option-control--open',
    SELECT_HAS_VALUE: 'select-control--has-value',
    SELECT_TOGGLE: 'select-custom-option-control__combobox-container',
    SELECT_TOGGLE_LABEL: 'select-custom-option-control__value',
    SELECT_OPTION_LIST: 'select-custom-option-control__list',
    SELECT_OPTION: 'select-custom-option-control__option',
    SELECTED: 'select-custom-option-control__option--selected',
    TOOL_TIP: 'tool-tip'
};

/**
 * @class SelectCustomOptionControl
 * @description View component for displaying a SelectCustomOptionControl
 */
export default class SelectCustomOptionControl extends SelectOptions {
    /**
     * @description Create a SelectCustomOptionControl
     * @memberof SelectCustomOptionControl
     * @param {Array} items An array of items objects. Each object is represented as follows:
     * {
     *   label: '<div>some elements inside</div>',
     *   value: 'all'
     * }
     * @param {Object} config Configuration data
     */
    constructor(items, config) {
        super(items, { ...config, CLASSES });
    }

    /**
     * @method createView
     * @description Create view
     * @memberof SelectCustomOptionControl
     */
    createView() {
        this.element = selectCustomOptionControlTemplate({
            analyticsTrigger: this.config.analyticsTrigger,
            ariaLabel: this.config.ariaLabel,
            cssClass: this.config.cssClass,
            defaultSelection: this.config.defaultSelection,
            disabled: this.config.disabled,
            errorMessage: this.config.errorMessage,
            icon: this.config.icon,
            id: this.id,
            items: this.items,
            labelText: this.config.labelText,
            moreInfoText: this.config.moreInfoText,
            name: this.config.name,
            required: this.config.required,
            ariaLabelText: this.config.ariaLabelText
        })({ getNode: true });
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

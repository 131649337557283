/**
 * @class ServiceRequestCache
 * @description A wrapper utility to cache successful fetch requests that have the same request payload
 *
 * Notes: there is potential to abstract this Class to a reusable utility. Some things to keep in mind:
 *  1. Is there already an npm module out there that would meet our needs to caches XHR request in flexible way
 *  2. Optimize the requestCache keys to a hash vs a long string
 *  3. Enhance with a mechanism to expire a request
 *  4. Enhance with a mechanism to burst/invalidate a cached request
 *  5. Enhance with a mechanism to configure where the cached requests are stored (memory, localStorage, sessionStorage)
 *  6. Revisit how a response is considered successful. The current implementation uses the NOW service response
 *     structure that expects the response payload to include a `status` object with a `code` value to indicate
 *     that the request was successful. If we were to abstract this, we may want to remove that application specific
 *     logic and only rely on the header response code
 */
export default class ServiceRequestCache {
    constructor() {
        // stores the cached responses via a key/value pair
        this.requestCache = {};
    }

    /**
     * @method isSuccessResponse
     * @description Validates that the response code is a successful 2XX value
     * @param response {Object} Request response body
     * @return {boolean} Indicator for whether the request was successful
     */
    isSuccessResponse = (response) => (
        !!response.status && /^2\d\d$/.test(response.status.code)
    );

    /**
     * @method getRequestKey
     * @description Parses the request values from the payload and creates an array of strings that can be used
     * as the unique cache key identified
     * @param request
     */
    getRequestKey = (request) => {
        let requestValue;
        const requestKeys = [];

        try {
            requestValue = JSON.parse(request);
        } catch (e) {
            requestValue = request;
        }

        if (typeof requestValue === 'string' || typeof requestValue === 'number' || typeof requestValue === 'boolean') {
            requestKeys.push(encodeURI(requestValue.toString().trim()));
        } else if (Array.isArray(requestValue)) {
            requestValue.forEach(((value) => {
                requestKeys.push(this.getRequestKey(value));
            }));
        } else if (typeof requestValue === 'object') {
            Object.values(requestValue).forEach((value) => {
                requestKeys.push(this.getRequestKey(value));
            });
        }

        return requestKeys.flatten();
    };

    /**
     * @method fetch
     * @description Proxy to Window.fetch that caches all successful requests with the status code  of 200 in memory
     * @param endpoint {String} Url endpoint to fetch
     * @param options {Object} Request options
     * @return {Promise<void>|*} Cached or fresh response
     */
    fetch(endpoint, options) {
        const requestKeys = this.getRequestKey(options.body).join('|');
        const cacheKey = `${endpoint}|${requestKeys}`;
        const cachedResponse = this.requestCache[cacheKey];

        // Try to return the cached response if defined in the requestCache
        // otherwise proceed with calling a new fetch
        if (cachedResponse) {
            try {
                const response = new Response(new Blob([cachedResponse]));
                return Promise.resolve(response);
            } catch (e) {
                console.warn('There was an error returning the cached response, continuing with a new request', e);
            }
        }

        return fetch(endpoint, options).then((response) => {
            // Clone the response so that it is not consumed before being returned
            // so that response interface is the same between the cached and fetch response
            const responseCopy = response.clone();

            // Store the response in a cache key if the response code is 200
            responseCopy.json().then((content) => {
                if (this.isSuccessResponse(content)) {
                    this.requestCache[cacheKey] = JSON.stringify(content);
                }
            });

            return response;
        });
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

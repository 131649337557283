// Partial dependencies
import { DatePickerInput } from 'partials/datepicker';
import { SelectControl } from 'partials/select-control';
import {
    InputTextControl,
    InputRadioGroupControl
} from 'partials/input-control';

// Util dependencies
import {
    renderer,
    tokenReplace
} from 'utils';

// Local dependencies
import dealerFormTestDriveTemplate from '../templates/dealerFormTestDriveTemplate';

/**
 * @const ATTRIBUTES
 * @description DOM attributes for view
 * @type {{
 *  DATE: string,
 *  TIME: string,
 *  PREFERRED_DELIVERY: string,
 *  HOME_TEST_DRIVE_ADDRESS: string,
 *  HOME_TEST_DRIVE_CITY: string,
 *  HOME_TEST_DRIVE_STATE: string,
 *  HOME_TEST_DRIVE_ZIP: string,
 *  HOME_TEST_DRIVE_CONTAINER: string
 * }}
 */
const ATTRIBUTES = {
    DATE: 'dealer-form-preferred-date',
    TIME: 'dealer-form-preferred-time',
    PREFERRED_DELIVERY: 'dealer-form-preferred-delivery',
    HOME_TEST_DRIVE_ADDRESS: 'dealer-form-home-test-drive-address',
    HOME_TEST_DRIVE_CITY: 'dealer-form-home-test-drive-city',
    HOME_TEST_DRIVE_STATE: 'dealer-form-home-test-drive-state',
    HOME_TEST_DRIVE_ZIP: 'dealer-form-home-test-drive-zip',
    HOME_TEST_DRIVE_CONTAINER: 'dealer-form__home-test-drive-address-container'
};

/**
 * @class DealerFormTestDrive
 * @description View responsible for creating a Dealer Form's Test Drive
 * form fields and managing their state
 */
export default class DealerFormTestDrive {
    /**
     * @constructor
     * @description On instantiation, sets the initial properties,
     * caches the dom and creates the its inputs
     * @param content {Object} Object contains content key/values
     * @param language {String} Language code
     * @param title {String} title of form section
     * @param homeTestDriveAvailable {boolean} value if vehicle is available for home test drive
     * @param callbacks {Object} contains callback functions
     */
    constructor(content = {}, language = 'en', title = '', homeTestDriveAvailable = false, callbacks = {}) {
        this.content = content;
        this.language = language;
        this.inputs = {};
        this.element = dealerFormTestDriveTemplate(
            title,
            content,
            homeTestDriveAvailable
        )({ getNode: true });
        this.homeTestDriveAvailable = homeTestDriveAvailable;
        this.onTestDriveToggleCallback = callbacks.onTestDriveToggle;

        // bound method aliases
        this.onToggleTestDriveLocation = this.onToggleTestDriveLocation.bind(this);

        // initialize view
        this.cacheDOM();
        this.createInputs();
    }

    /**
     * @method destroy
     * @description method that destroys all form elements
     */
    destroy() {
        Object.values(this.inputs).forEach((input) => {
            if (input.destroy) {
                input.destroy();
            }
        });
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements from the view
     */
    cacheDOM() {
        this.dateElement = this.element.querySelector(`[${ATTRIBUTES.DATE}]`);
        this.timeElement = this.element.querySelector(`[${ATTRIBUTES.TIME}]`);
        this.preferredDeliveryElement = this.element.querySelector(`[${ATTRIBUTES.PREFERRED_DELIVERY}]`);
        this.homeTestDriveAddressElement = this.element.querySelector(`[${ATTRIBUTES.HOME_TEST_DRIVE_ADDRESS}]`);
        this.homeTestDriveCityElement = this.element.querySelector(`[${ATTRIBUTES.HOME_TEST_DRIVE_CITY}]`);
        this.homeTestDriveStateElement = this.element.querySelector(`[${ATTRIBUTES.HOME_TEST_DRIVE_STATE}]`);
        this.homeTestDriveZipElement = this.element.querySelector(`[${ATTRIBUTES.HOME_TEST_DRIVE_ZIP}]`);
        this.homeTestDriveContainer = this.element.querySelector(`.${ATTRIBUTES.HOME_TEST_DRIVE_CONTAINER}`);
    }

    /**
     * @method createInputs
     * @description Creates the views input controls and appends them to the view
     */
    createInputs() {
        this.createTestDriveDatePicker();
        this.createTestDriveTimePreference();

        if (this.homeTestDriveAvailable) {
            this.createLocationPreference();
            this.createHomeTestDriveAddressInput();
            this.toggleTestDriveLocationForm();
        }
    }

    /**
     * @method createTestDriveDatePicker
     * @description Instantiates DatePickerInput and renders it
     */
    createTestDriveDatePicker() {
        const tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        this.inputs.testDriveDate = new DatePickerInput({
            i18n: this.content.calendar[this.language],
            today: (new Date().toJSON().split('T')[0]),
            tomorrow,
            minDate: (tomorrow.toJSON().split('T')[0]),
            errorMessage: this.content.appointmentDateError
        });

        renderer.insert(
            this.inputs.testDriveDate.render(),
            this.dateElement
        );
    }

    /**
     * @method createTestDriveTimePreference
     * @description Instantiates SelectControl for time preference and renders it
     */
    createTestDriveTimePreference() {
        this.inputs.testDriveTime = new SelectControl(
            [
                { label: this.content.anytime, value: 'anytime' },
                { label: this.content.daytime, value: 'daytime' },
                { label: this.content.evening, value: 'evening' }
            ],
            {
                labelText: this.content.timePreference,
                analyticsTrigger: 'schedule-time',
                required: false
            }
        );

        renderer.insert(
            this.inputs.testDriveTime.render(),
            this.timeElement
        );
    }

    /**
     * @method createLocationPreference
     * @description Creates RadioControl for Home Test Drive preference and renders it
     */
    createLocationPreference() {
        this.inputs.locationPreference = new InputRadioGroupControl({
            name: 'df-home-test-drive-preference',
            onChangeCallback: this.onToggleTestDriveLocation,
            options: [
                {
                    config: {
                        checked: true,
                        valueText: 'dealership',
                        labelText: this.content.atDealership
                    }
                },
                {
                    config: {
                        valueText: 'home',
                        labelText: this.content.atHome,
                        disclaimer: {
                            legend: this.content.homeTestDriveDisclaimerReference,
                            label: tokenReplace(this.content.homeTestDriveDisclaimerLabel,
                                { number: this.content.homeTestDriveDisclaimerReference })
                        }
                    },
                }
            ]
        });

        renderer.insert(
            this.inputs.locationPreference.render(),
            this.preferredDeliveryElement
        );
    }

    /**
     * @method toggleTestDriveLocationForm
     * @description toggles Delivery Form based on user's selection of preferred test drive location,
     * registers and unregisters location form based on user's selection
     */
    toggleTestDriveLocationForm() {
        const isHomeTestDrive = this.inputs.locationPreference.getValue() === 'home';
        const classListAction = isHomeTestDrive ? 'remove' : 'add';

        this.homeTestDriveContainer.classList[classListAction]('hide');
    }

    /**
     * @method onToggleTestDriveLocation
     * @description callback for test drive location changes and applies the on delivery toggle callback
     */
    onToggleTestDriveLocation() {
        this.toggleTestDriveLocationForm();
        if (this.onTestDriveToggleCallback && (this.onTestDriveToggleCallback instanceof Function)) {
            this.onTestDriveToggleCallback();
        }
    }

    /**
     * @method createHomeTestDriveAddressInput
     * @description creates input boxes and select for user's address to test drive vehicle,
     * then inserts them into the dealer test drive form
     */
    createHomeTestDriveAddressInput() {
        this.inputs.homeTestDriveAddress = new InputTextControl({
            type: 'text',
            id: 'df-test-drive-address',
            required: true,
            labelText: this.content.homeTestDriveLabel.address,
            maxLength: 50,
            validateOnBlur: true,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.pleaseEnterHomeTestDriveAddress
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.pleaseEnterHomeTestDriveAddress
                }
            ],
            errorMessage: this.content.pleaseEnterHomeTestDriveAddress
        });
        this.inputs.homeTestDriveCity = new InputTextControl({
            type: 'text',
            id: 'df-test-drive-city',
            required: true,
            labelText: this.content.homeTestDriveLabel.city,
            maxLength: 25,
            validateOnBlur: true,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.pleaseEnterHomeTestDriveCity
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.pleaseEnterHomeTestDriveCity
                }
            ],
            restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
            errorMessage: this.content.pleaseEnterHomeTestDriveCity
        });
        this.inputs.homeTestDriveState = new SelectControl(
            this.content.stateList.map((state) => ({ label: state, value: state })),
            {
                labelText: this.content.homeTestDriveLabel.state,
                labelSuppressed: true,
                cssClass: 'dealer-form__home-test-drive-select',
                errorMessage: this.content.pleaseEnterHomeTestDriveState,
                required: true
            }
        );
        this.inputs.homeTestDriveZip = new InputTextControl({
            type: 'text',
            id: 'df-test-drive-zip-code',
            required: true,
            labelText: this.content.homeTestDriveLabel.zip,
            maxLength: 5,
            validateOnBlur: true,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.pleaseEnterHomeTestDriveZip
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.pleaseEnterHomeTestDriveZip
                }
            ],
            errorMessage: this.content.pleaseEnterHomeTestDriveZip
        });

        renderer.insert(this.inputs.homeTestDriveAddress.render(), this.homeTestDriveAddressElement);
        renderer.insert(this.inputs.homeTestDriveCity.render(), this.homeTestDriveCityElement);
        renderer.insert(this.inputs.homeTestDriveZip.render(), this.homeTestDriveZipElement);
        renderer.insert(this.inputs.homeTestDriveState.render(), this.homeTestDriveStateElement);
    }


    /**
     * @method getInputs
     * @description gets all inputs for selects form sections
     * @returns inputs
     */
    getInputs() {
        return this.inputs;
    }

    /**
     * @method render
     * @description  return the HTML Node with the element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

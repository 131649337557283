// Util dependencies
import { getLocationSuffix, serializeObject } from 'utils';
import { constructInventoryUri } from './../modules/inventory/api/inventoryUtils';
import { getRoofHeightOption } from './optionsUtils';

/**
 * @method constructApplyForCreditUri
 * @description Constructs the "Apply for Credit" URI with the needed query string params
 * @param applyForCreditUri {String}
 * @param vehicleBuild {Object}
 * @param creditAppOneWebEnabled {Boolean}
 * @return {String}
 */
export function constructApplyForCreditUri(applyForCreditUri, vehicleBuild, creditAppOneWebEnabled) {
    if (creditAppOneWebEnabled) {
        return `${applyForCreditUri}${serializeObject(
            {
                year: vehicleBuild.year,
                class: vehicleBuild.class.toUpperCase(),
                model: vehicleBuild.model.toUpperCase(),
                msrp: vehicleBuild.pricing.total.price
            })}`;
    } else {
        return `${applyForCreditUri}/` +
            `year-${vehicleBuild.year}/` +
            `class-${vehicleBuild.class.toUpperCase()}/` +
            `model-${vehicleBuild.model.toUpperCase()}/` +
            `msrp-${vehicleBuild.pricing.total.price}`;
    }
}

/**
 * @method constructScheduleTestDriveExternalUri
 * @description Constructs the external schedule test drive
 * @param {String} scheduleTestDriveUri - Schedule Test Drive URI
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @return {String} Constructed URI
 */
function constructScheduleTestDriveExternalUri(scheduleTestDriveUri, vehicleBuild) {
    return `${scheduleTestDriveUri}/` +
           `class-${vehicleBuild.class.toUpperCase()}/` +
           `model-${vehicleBuild.model.toUpperCase()}/` +
           'actyp-BYO_TD';
}

/**
 * @method constructScheduleTestDriveOWUri
 * @description Constructs the OW schedule test drive
 * @param {String} scheduleTestDriveUri - Schedule Test Drive URI
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @return {String} Constructed URI
 */
function constructScheduleTestDriveOWUri(scheduleTestDriveUri, vehicleBuild) {
    const roofHeightVariant = getRoofHeightOption(vehicleBuild);
    return `${scheduleTestDriveUri}${getLocationSuffix()}?` +
        `class=${vehicleBuild.class.toUpperCase()}&` +
        `model=${vehicleBuild.model.toUpperCase()}&` +
        'src=byo&' +
        `variant=${roofHeightVariant}`;
}

/**
 * @method constructRequestQuoteExternalUri
 * @description Constructs the external request quote
 * @param {String} requestQuoteUri - Request a Quote URI
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @return {String} Constructed URI
 */
function constructRequestQuoteExternalUri(requestQuoteUri, vehicleBuild) {
    return `${requestQuoteUri}/` +
           `class-${vehicleBuild.class.toUpperCase()}/` +
           `model-${vehicleBuild.model.toUpperCase()}/` +
           'actyp-BYO_CD';
}

/**
 * @method constructRequestQuoteOWUri
 * @description Constructs the OW request quote
 * @param {String} requestQuoteUri - Request a Quote URI
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @return {String} Constructed URI
 */
export function constructRequestQuoteOWUri(requestQuoteUri, vehicleBuild) {
    const roofHeightVariant = getRoofHeightOption(vehicleBuild);
    return `${requestQuoteUri}${getLocationSuffix()}?` +
        `class=${vehicleBuild.class.toUpperCase()}&` +
        `model=${vehicleBuild.model.toUpperCase()}&` +
        'src=byo&' +
        `variant=${roofHeightVariant}`;
}

/**
 * @method constructLinkOutUri
 * @description Constructs the link outs URI with the required config
 * @param {Object} linkOuts - Link out object from BYO content
 * @param {Object} vehicleBuild - Vehicle Build object
 * @param {Object} featureManagement - Feature management from BYO props
 * @return {String} Link outs object with updated path
 */
export function constructLinkOutUri(linkOuts, vehicleBuild, featureManagement, featureConfig) {
    // This map contains keys with linkOuts and an associated function that will reduce the object
    // with a new path based on feature management config
    const reducerMap = {
        'view-inventory': (linkOutObject) => {
            if (featureManagement.inventory.isPage) {
                let linkOutCopy = { ...linkOutObject };
                return constructInventoryUri(
                            featureConfig.inventoryFiltersMapUri,
                            vehicleBuild,
                            linkOutObject.basePath || linkOutObject.path)
                    .then((inventoryUri) => {
                        linkOutCopy = {
                            ...linkOutObject,
                            path: inventoryUri
                        };
                    })
                    .catch((e) => console.log('Error constructing inventory URI with BYO Params, defaulting to authored value', e))
                    .then(() => linkOutCopy);
            }

            return linkOutObject;
        },
        'schedule-test-drive': (linkOutObject) => {
            if (featureManagement.scheduleTestDrive.isExternal) {
                const path = constructScheduleTestDriveExternalUri(
                            linkOutObject.basePath || linkOutObject.path,
                            vehicleBuild);
                return { ...linkOutObject, path };
            }
            if (featureManagement.scheduleTestDrive.isOW) {
                const path = constructScheduleTestDriveOWUri(
                            linkOutObject.basePath || linkOutObject.path,
                            vehicleBuild);
                return { ...linkOutObject, path };
            }

            return linkOutObject;
        },
        'request-quote': (linkOutObject) => {
            if (featureManagement.requestQuote.isExternal) {
                const path = constructRequestQuoteExternalUri(
                            linkOutObject.basePath || linkOutObject.path,
                            vehicleBuild);
                return { ...linkOutObject, path };
            }

            if (featureManagement.requestQuote.isOW) {
                const path = constructRequestQuoteOWUri(
                            linkOutObject.basePath || linkOutObject.path,
                            vehicleBuild);
                return { ...linkOutObject, path };
            }

            return linkOutObject;
        },
        'special-offers': (linkOutObject) => ({
            ...linkOutObject,
            path: `${linkOutObject.basePath}${getLocationSuffix()}?bodyStyleClass=${vehicleBuild.class}:${vehicleBuild.bodyStyle}&year=${vehicleBuild.year}`,
        }),
    };

    // Call available reducer on the link out objects
    const linkOutPromises = Object.keys(linkOuts).map((linkOut) => {
        const linkOutObject = { ...linkOuts[linkOut] };
        const linkOutReducer = reducerMap[linkOut];

        if (typeof linkOutReducer === 'function') {
            return linkOutReducer(linkOutObject);
        }

        return linkOutObject;
    });

    // Once all reducers have been resolved, transform them into a map
    return Promise.all(linkOutPromises)
        .then((values) =>
            values.reduce((aggregator, linkOut) => {
                aggregator[linkOut.type] = linkOut;
                return aggregator;
            }, {})
        );
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

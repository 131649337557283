// Constant dependencies
import { EVENTS } from 'Constants';

// Local dependencies
import DISCLAIMER_EVENTS from './../constants/EVENTS';
import GLOBAL_DISCLAIMER_CODES from './../constants/GLOBAL_DISCLAIMER_CODES';
import disclaimerLegendTemplate from './../templates/disclaimerLegendTemplate';

const defaultConfig = {
    analyticsTrigger: '',
    autoNum: false,
    ctaText: '',
    globalDisclaimerCode: '',
    hideLegend: false,
    legend: ''
};

/**
 * View responsible for rendering a single instance of a disclaimer legend
 * based on config passed in
 */
export default class DisclaimerLegend {
    /**
     * @constant GLOBAL_DISCLAIMER_CODES
     * @description Global disclaimer codes
     */
    static GLOBAL_DISCLAIMER_CODES = GLOBAL_DISCLAIMER_CODES

    /**
     * @constructor
     * @description Constructs an instance of DisclaimerLegend
     */
    constructor(config) {
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.config.legend = this.getDisclaimerLegend(this.config);
        this.element = disclaimerLegendTemplate(this.config)({ getNode: true });

        this.onClick = this.onClick.bind(this);

        this.attachEvents();
    }

    /**
     * @method destroy
     * @description Destroys the instance of disclaimer legend
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method attachEvents
     * @description Attaches the events for the disclaimer
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, this.onClick);
    }

    /**
     * @method detachEvents
     * @description Detachs events for the disclaimer
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.CLICK, this.onClick);
    }

    /**
     * @method getDisclaimerLegend
     * @description Gets the disclaimer legend
     * @param {object} config - Disclaimer legend config
     * @returns {string} Legend based on global disclaimer
     */
    getDisclaimerLegend({ legend, globalDisclaimerCode }) {
        return !legend && globalDisclaimerCode ? this.getGlobalDisclaimerLegend(globalDisclaimerCode) : legend;
    }

    /**
     * @method getGlobalDisclaimerLegend
     * @description Get disclaimer legend from window.mbVans.pageData.globalDisclaimerLegends
     * @param {string} disclaimerCode - Disclaimer code for which the global disclaimer info should
     * be retrieved
     */
    getGlobalDisclaimerLegend(disclaimerCode) {
        const { globalDisclaimerLegends } = window.mbVans.pageData;

        return globalDisclaimerLegends[disclaimerCode];
    }

    /**
     * @method onClick
     * @description Click handler for the disclaimer legend, which dispatches a global event
     * @param {MouseEvent} event - Click event
     */
    onClick(event) {
        window.dispatchEvent(
            new CustomEvent(
                DISCLAIMER_EVENTS.OPEN_DISCLAIMER, {
                    detail: {
                        currentTarget: event.currentTarget,
                        legend: event.currentTarget.dataset.legend
                    }
                }
            )
        );
    }

    /**
     * @method render
     * @description Renders the legend
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * @method createTestDriveLocationInputs
 * @description creates and returns the html for home delivery test drive portion of form
 * @param title {string} title header
 * @returns {string}
 */
function createTestDriveLocationInputs(title) {
    return (
        `<div>
            <fieldset>
                <legend class="dealer-form__field-heading dealer-form__header--input-heading">
                    ${title}
                </legend>
                <div class="dealer-form__input-group">
                    <div dealer-form-preferred-delivery=""
                         class="dealer-form__input-container dealer-form__input-container--radio"></div>
                </div>
            </fieldset>
            <div class="dealer-form__home-test-drive-address-container">
                <div dealer-form-home-test-drive-address=""></div>
                <div dealer-form-home-test-drive-city=""></div>
                <div dealer-form-home-test-drive-state=""></div>
                <div dealer-form-home-test-drive-zip=""></div>
            </div>
         </div>`
    );
}

/**
 * Template for a Test Drive section view.
 * Uses ES6 template literal to composite the markup for
 * Test Drive section within Dealer Form view
 */
export default (title = '', content = {}, homeTestDriveAvailable = '') => htmlNode `
    <div class="dealer-form__test-drive">
        <div class="dealer-form__header dealer-form__header--section-label">
            ${title}
        </div>
        <div class="dealer-form__input-group">
            <div dealer-form-preferred-date="" class="dealer-form__input-container"></div>
            <div dealer-form-preferred-time="" class="dealer-form__input-container dealer-form__input-container--select"></div>
        </div>
        ${homeTestDriveAvailable ? createTestDriveLocationInputs(`${content.chooseLocation}`) : ''}
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

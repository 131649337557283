/**
 * Template for a Dealer Search Header.
 * Uses ES6 template literal to composite the markup for a Dealer Search Header
 */
import { htmlNode, tokenReplace } from 'utils';

/**
 * @function
 * @name renderNearbyHeading
 * @description render subheading text if dealer is inside defined radius of 25KM.
 * @param {String} dealerResultHeading
 * @param {String} searchLocation
 * @return {String} template string
 */
const renderNearbyHeading = (dealerResultHeading, searchLocation) => (
    `<h3 class="dealer-results__sub-heading">${tokenReplace(dealerResultHeading, [searchLocation])}</h3>`
);

/**
 * @function
 * @name renderClosestHeading
 * @description render subheading text if dealer is outside defined radius of 25KM.
 * @param {String} closestDealersHeading
 * @return {String} template string
 */
const renderClosestHeading = (dealerResultHeading, closestDealersHeading) => `<h3 class="dealer-results__sub-heading">${closestDealersHeading}</h3>`;

export default ({ dealerResultHeading, closestDealersHeading, searchLocation, hasNearbyDistance }) => htmlNode `
    ${hasNearbyDistance ? renderNearbyHeading(dealerResultHeading, searchLocation) : renderClosestHeading(dealerResultHeading, closestDealersHeading)}
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @constant MAX_OPTIONS
 * @description Max options to display in the included/not included lists
 * @type {Number}
 */
export const MAX_OPTIONS = 8;

/**
 * @method getFeatureValueList
 * @description Takes object of features and returns a list with only the string values, removing empty ones
 * @return {Array} Array of strings
 */
export function getFeatureValueList(features) {
    return features.slice(0, MAX_OPTIONS)
        .filter((item) => !!item.value)
        .map((item) => item.value);
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import {
    customEventDispatcher,
    noop
} from 'utils';
import { hasInputBlacklistCharacters } from '@mbusa/now-ui-utils.validate';

// Local dependencies
import InputControl from './InputControl';
import inputTextControlTemplate from './../templates/inputTextControlTemplate';
import { FORMAT_TYPE, format } from './../api/inputFormatter';
import { RESTRICTION_TYPE, restrict } from './../api/inputRestrictor';
import {
    VALIDATION_EVENT,
    VALIDATION_TYPE,
    verify
} from './../api/inputValidator';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    INPUT_ELEMENT: 'form__input-field',
    LABEL_ELEMENT: 'form__input-label',
    RESET_BUTTON: 'form__input-reset',
    ERROR_ELEMENT: 'form__input-error',
    ERROR: 'error',
    HAS_VALUE: 'form__input-field--has-value',
    MASK_ELEMENT: 'form__input-masked-element',
    RESET_BUTTON_VISIBLE: 'form__input-reset--visible'
};

const IDS = {
    FINANCE_DOWN_PAYMENT: 'financeDownPayment',
    LEASE_DOWN_PAYMENT: 'leaseDownPayment',
};

/**
 * Class that will set up an InputTextControl
 */
export default class InputTextControl extends InputControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputTextControl
     * @type {Object}
     * @const allowReset {Boolean} Whether the reset button should display when focused
     * @const onResetCallback {Function} Callback function for when the input is reset
     */
    static DEFAULT_CONFIG = {
        ...InputControl.DEFAULT_CONFIG,
        allowReset: true,
        onResetCallback: noop,
        formatEvents: { blur: false, keyup: true },
        restrictBlacklistCharacters: true,
        validationIconEnabled: true,
        inputPrefix: '',
        inputPrefixLabel: '',
        role: null,
    };

    /**
     * @static RESTRICTION_TYPE
     * @description Default restriction types available for an InputTextControl
     * @type {Object}
     */
    static RESTRICTION_TYPE = {
        ...RESTRICTION_TYPE
    };

    /**
     * @static FORMAT_TYPE
     * @description Default validation types available for an InputTextControl
     * @type {Object}
     */
    static FORMAT_TYPE = {
        ...FORMAT_TYPE
    };

    /**
     * @static VALIDATION_TYPE
     * @description Default validation types available for an InputTextControl
     * @type {Object}
     */
    static VALIDATION_TYPE = {
        ...VALIDATION_TYPE
    };

    /**
     * @constructor Create an InputTextControl
     */
    constructor(config = InputTextControl.DEFAULT_CONFIG) {
        super({
            ...InputTextControl.DEFAULT_CONFIG,
            ...config
        });
        this.errorMessage = ''; // stores the error message for and invalid input
        this.hasFormattedValue = false; // indicator for whether an input value has been formatted via `format`
        this.valid = true; // stores the validity of the input
        this.onMatchValidate = this.onMatchValidate.bind(this);
        this.resetInputValue = this.resetInputValue.bind(this);
        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.createView(this.getConfig('renderedInput') || inputTextControlTemplate({
            type: this.getConfig('type'),
            id: this.getConfig('id'),
            name: this.getConfig('name'),
            pattern: this.getConfig('pattern'),
            placeholder: this.getConfig('placeholder'),
            preserveBlank: this.getConfig('preserveBlank'),
            minLength: this.getConfig('minLength'),
            maxLength: this.getConfig('maxLength'),
            dataAttributes: this.getConfig('dataAttributes').join(' '),
            disablePaste: this.getConfig('disablePaste'),
            inputClassNames: this.getConfig('inputClassNames').join(' '),
            labelClassNames: this.getConfig('labelClassNames').join(' '),
            labelIconClass: this.getConfig('labelIconClass'),
            required: this.getConfig('required'),
            allowReset: this.getConfig('allowReset'),
            focused: this.getConfig('focused'),
            valueText: this.getConfig('valueText'),
            ariaLabel: this.getConfig('ariaLabel'),
            labelText: this.getConfig('labelText'),
            maxValue: this.getConfig('maxValue'),
            moreInfoText: this.getConfig('moreInfoText'),
            disabled: this.getConfig('disabled'),
            readOnly: this.getConfig('readOnly'),
            formatMask: this.getConfig('formatMask'),
            analyticsTrigger: this.getConfig('analyticsTrigger'),
            inputMode: this.getConfig('inputMode')
        })({ getNode: true }));

        super.init();
    }

    /**
     * @method attachEvents
     * @description Adds a click event listener and callback to the View
     */
    attachEvents() {
        super.attachEvents();
        if (this.resetButton) {
            this.resetButton.addEventListener(EVENTS.CLICK, this.resetInputValue);
        }

        customEventDispatcher.addEventListener(VALIDATION_EVENT.MATCH, this.onMatchValidate);
    }

    /**
     * @method detachEvents
     * @description Removes the click event listener and callback from the View
     */
    detachEvents() {
        super.detachEvents();
        if (this.resetButton) {
            this.resetButton.removeEventListener(EVENTS.CLICK, this.resetInputValue);
        }
        customEventDispatcher.removeEventListener(VALIDATION_EVENT.MATCH, this.onMatchValidate);
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        super.cacheDOM();
        this.labelElement = this.getInput().querySelector(`.${CLASSES.LABEL_ELEMENT}`);
        this.resetButton = this.getInput().querySelector(`.${CLASSES.RESET_BUTTON}`);
        this.errorElement = this.getInput().querySelector(`.${CLASSES.ERROR_ELEMENT}`);
        this.maskElement = this.getInput().querySelector(`.${CLASSES.MASK_ELEMENT}`);
        this.setInputElement(this.getInput().querySelector(`.${CLASSES.INPUT_ELEMENT}`));
    }

    /**
     * @method setValue
     * @description Sets the input element value
     * @param value {String|Number} Value to set to the input element
     */
    setValue(value) {
        this.getInputElement().value = value;
        this.toggleHasValueClass();

        if (this.getConfig('formatting').length > 0) {
            this.format();
        }

        this.simulateChange();
    }

    /**
     * @method simulateChange
     * @description Simulate a change event
     */
    simulateChange() {
        let event;

        if (typeof (Event) === 'function') {
            event = new Event(EVENTS.CHANGE, { bubbles: true });
        } else {
            event = document.createEvent('Event');
            event.initEvent(EVENTS.CHANGE, true, true);
        }

        this.getInputElement().dispatchEvent(event);
    }

    /**
     * @method resetInputValue
     * @description Clears and resets the input value
     */
    resetInputValue(e) {
        e.preventDefault();
        this.getInputElement().value = '';
        if (this.config.formatMask && this.maskElement) {
            this.maskElement.innerHTML = '';
        }
        this.onReset(e);
        this.getInputElement().focus();
        this.getInputElement().select();
    }

    /**
     * @method format
     * @description Iterates a list of `formatting` configurations and formats
     * the input value if defined.
     */
    format() {
        const inputElement = this.getInputElement();
        const inputValue = inputElement.value;
        let formattedValue = inputValue;

        if (inputElement.id === 'apr' && isNaN(parseFloat(inputValue.trim()))) {
            return true;
        }

        [].slice.call(this.getConfig('formatting')).forEach((formatType) => {
            formattedValue = format(
                inputValue,
                formatType.type,
                formatType.config
            );
        });

        if (inputValue !== formattedValue) {
            inputElement.value = formattedValue;
            this.hasFormattedValue = true;
        } else {
            this.hasFormattedValue = false;
        }

        if (this.config.formatMask) {
            this.formatMask();
        }

        return inputValue !== formattedValue;
    }

    /**
     * @method formatMask
     * @desc Formats input value using the formatMask configuration
     * and sets the value to maskElement
     */
    formatMask() {
        const inputValue = this.getInputElement().value;
        const value = format(
            inputValue,
            this.config.formatMask[0].type,
            this.config.formatMask[0].config
        );
        let wrappedValue = '';
        for (let i = 0; i < value.length; i += 1) {
            wrappedValue += `<span>${value.charAt(i)}</span>`;
        }

        if (this.maskElement) {
            this.maskElement.innerHTML = wrappedValue;
        }
    }

    /**
     * @method validate
     * @description Check validation and sets the `valid` and `errorMessage` state based
     * on the input values validity
     */
    validate(displayError = true) {
        let valid = true;
        let matchValidation = null;
        const prevValidity = this.valid;
        this.errorMessage = '';

        // loop through all validation rules
        [].slice.call(this.getConfig('validation')).map((validationType) => {
            const inputElement = this.getInputElement();
            const isDownPayment = (inputElement.id === IDS.FINANCE_DOWN_PAYMENT ||
                inputElement.id === IDS.LEASE_DOWN_PAYMENT) &&
                validationType.type === VALIDATION_TYPE.MAX_VALUE_FORMATTED;

            if (!isDownPayment && valid && !verify(
                inputElement.value.trim(),
                validationType.type,
                this.config,
                validationType.config
            )) {
                valid = false;
                this.errorMessage = validationType.errorMessage;
            }

            if (validationType.type === VALIDATION_TYPE.MATCH) {
                matchValidation = validationType;
            }

            return validationType;
        });

        // If black list restriction config is true and the input field has one of the restricted
        // characters
        if (this.getConfig('restrictBlacklistCharacters') && hasInputBlacklistCharacters(this.getInputElement().value)) {
            valid = false;
            this.errorMessage = this.formLocalization.blacklistErrorMessage;
        }

        this.valid = valid;

        if (displayError || (!displayError && this.valid)) {
            this.setErrorStatus(!this.valid, this.errorMessage);
        }

        // if the validity changed from false to true and this input has a match validation type
        // dispatch an event to notify the related "matching" input of the change
        if (!prevValidity && valid && matchValidation) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    VALIDATION_EVENT.MATCH,
                    {
                        detail: {
                            compareId: matchValidation.config.compareId
                        }
                    }
                )
            );
        }

        return this.isValid();
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if input value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method onBlur
     * @description Callback method applied on blur of an input that applies
     * any formatting required to the input value, and calls the super.onBlur method
     * @param e {Event} Event object from blur event
     */
    onBlur(e) {
        const isResetEvent = e.relatedTarget &&
            e.relatedTarget.classList.contains(CLASSES.RESET_BUTTON);

        if (this.resetButton) {
            this.resetButton.classList.remove(CLASSES.RESET_BUTTON_VISIBLE);

            // if the reset button was clicked on `blur` reset the input value
            if (isResetEvent) {
                this.resetInputValue(e);
            }
        }

        if (this.config.formatEvents.blur && e.target.value) {
            this.format();
        }

        // If the value has been formatted. simulate a change event
        // This is to fix an issue with ios and Edge when setting values
        // of an input with JS, the change event does not fire onblur
        if (this.hasFormattedValue) {
            this.simulateChange();
        }

        this.toggleHasValueClass();
        if (!this.getInputElement().value) {
            this.labelElement.classList.remove('focused');
        }
        super.onBlur(e);
    }

    /**
     * @method onChange
     * @description Callback handler for when the input change event is fire
     * that resets the hasFormattedValue boolean and calls the super onChange
     * @param e
     */
    onChange(e) {
        // reset the hasFormattedValue when `onChange` successfully fires
        if (this.hasFormattedValue) {
            this.hasFormattedValue = false;
        }

        super.onChange(e);
    }

    /**
     * @method onKeydown
     * @description Limiting accepted characters in input,
     * if an input has text entered after error is triggered, clear error
     * @param e {Event} Event object from keydown event
     */
    onKeydown(e) {
        [].slice.call(this.getConfig('restrictions')).map((restriction) => {
            if (restrict(e, restriction)) {
                e.preventDefault();
            }
            return restriction;
        });

        if (!this.isValid()) {
            this.setErrorStatus(false);
        }

        this.toggleHasValueClass();

        super.onKeydown(e);
    }

    /**
     * @method onKeyup
     * @description calling toggleResetButton method
     * to toggle the state of resetButton
     * @param e {Event} Event object from keyup event
     */
    onKeyup(e) {
        this.toggleResetButton();
        if (this.config.formatEvents.keyup && e.target.value !== undefined) {
            this.format();
        }
        super.onKeyup(e);
    }

    /**
     * @method onFocus
     * @description Callback method applied on focus of an input that applies
     * any formatting required to the input value, and calls the super.onFocus method
     * @param e {Event} Event object from focus event
     */
    onFocus(e) {
        this.toggleResetButton();
        this.labelElement.classList.add('focused');
        super.onFocus(e);
        if (/android/i.test(navigator.userAgent)) {
            window.setTimeout(() => {
                e.target.scrollIntoViewIfNeeded(true);
            }, 500);
        }
    }

    /**
     * @method onMatchValidate
     * @description Callback method applied when a related "matching" input changes
     * to check if the event.detail.id matches its own id and if the input is currently
     * invalid to revalidate itself
     * @param e
     */
    onMatchValidate(e) {
        const { detail } = e;

        if (detail && detail.compareId === this.getConfig('id') && !this.valid && this.getValue()) {
            this.validate();
        }
    }

    /**
     * @method toggleClearButton
     * @description toggle the Clear Button. Method is used because IE 11 has issues
     * styling the focus pseudo selector.
     */
    toggleResetButton() {
        if (this.resetButton) {
            const inputValue = this.getInputElement().value;
            if (inputValue) {
                this.resetButton.classList.add(CLASSES.RESET_BUTTON_VISIBLE);
            } else {
                this.resetButton.classList.remove(CLASSES.RESET_BUTTON_VISIBLE);
            }
        }
    }

    /**
     * @method toggleHasValueClass
     * @description Toggles the has value class if the input has a value
     */
    toggleHasValueClass() {
        const hasValue = !!this.getInputElement().value;
        this.getInputElement().classList[hasValue ? 'add' : 'remove'](CLASSES.HAS_VALUE);
        if (document.activeElement !== this.getInputElement()) {
            this.labelElement.classList[hasValue ? 'add' : 'remove']('focused');
        }
    }

    /**
     * @method onReset
     * @description Calls this.config.onResetCallback and passes the event and instance
     * @param e {Event} Event object from blur event
     */
    onReset(e) {
        if (this.getConfig('validateOnBlur')) {
            this.validate();
        }

        this.config.onResetCallback(e, this);
        this.dispatchChange();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { EVENTS } from 'Constants';
import { noop } from 'utils';
import { UserProfile } from 'partials/profile';
import { PictureLoader } from 'partials/image';


// Local dependencies
import dealerInfoCardTemplate from './../templates/dealerInfoCardTemplate';

/**
 * @property defaultConfig
 * @description Default configuration for DealerInfo
 * @type {{
 *     id: null,
 *     dealer: null,
 *     country: string,
 *     language: string,
 *     onChangeDealer: function,
 *     onContactDealer: function,
 *     onDealerLoaded: function,
 *     content: {
 *         contactDealer: string,
 *         change: string,
 *         getDirections: string,
 *         viewDealerWebsite: string,
 *         viewMoreCTA: string
 *     },
 *     image: {
 *         dealer: null,
 *         default: null
 *     },
 *     disableCtas: boolean
 *     disableImage: boolean
 *  }}
 */
const defaultConfig = {
    id: null,
    dealer: null,
    country: 'us',
    language: 'en',
    onChangeDealer: noop,
    onContactDealer: noop,
    onDealerLoaded: noop,
    content: {
        contactDealer: 'ContactDealer',
        change: 'Change',
        getDirections: 'Get Directions',
        viewDealerWebsite: 'View Dealer Website',
        viewMoreCTA: 'View Dealer Website'
    },
    image: {
        dealer: null,
        default: null
    },
    disableCtas: false,
    disableImage: false,
    viewMoreUrl: {
        url: null,
        newTab: false
    }
};

const ATTRIBUTES = {
    CHANGE_DEALER: 'data-change-dealer',
    CONTACT_DEALER: 'data-contact-dealer',
    DEALER_IMAGE: 'data-dealer-image'
};

/**
 * @class DealerInfoCard
 * @description View for displaying a dealer based on a dealer id. This
 * view is responsible for fetching a dealer based on ID, or optionally
 * accepting a dealer object and displaying its information
 */
export default class DealerInfoCard {
    /**
     * @constructor
     * @description On instantiation sets the config, initials data
     * and conditionally fetches the dealer information if on
     * @param config {Object} Configuration properties
     * @param config.id {String} Dealer Id to retrieve
     * @param config.dealer [{Object}] Optional dealer object to display
     * @param config.country {String} Country code
     * @param config.language {String} Language code
     * @param config.content {Object} Localized content object
     * @param config.onChangeDealer {Function} Callback method applied on change dealer
     * @param config.onContactDealer {Function} Callback method applied on contact dealer
     * @param config.image {Object} Dealer image object
     */
    constructor(config = {}) {
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.dealer = this.config.dealer;
        this.dealerInfoElm = null;
        this.element = document.createElement('div');
        this.changeDealerCta = null;
        this.contactDealerCta = null;
        this.dealerImageElm = null;

        // method alias
        this.onChangeDealer = this.onChangeDealer.bind(this);
        this.onContactDealer = this.onContactDealer.bind(this);

        // initialize
        if (this.dealer && this.dealer.dealerId === this.config.id) {
            this.renderDealerTemplate();
        } else if (this.config.id) {
            this.getDealer();
        } else {
            console.warn('DealerInfo Warning: An id or dealer property is missing');
        }
    }

    /**
     * @method cacheDom
     * @description Caches the view's DOM elements
     */
    cacheDom() {
        this.changeDealerCta = this.dealerInfoElm.querySelector(`[${ATTRIBUTES.CHANGE_DEALER}]`);
        this.contactDealerCta = this.dealerInfoElm.querySelector(`[${ATTRIBUTES.CONTACT_DEALER}]`);
        this.dealerImageElm = this.dealerInfoElm.querySelector(`[${ATTRIBUTES.DEALER_IMAGE}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches event handlers from DOM elements
     */
    attachEvents() {
        if (this.changeDealerCta) {
            this.changeDealerCta.addEventListener(EVENTS.CLICK, this.onChangeDealer);
        }

        if (this.contactDealerCta) {
            this.contactDealerCta.addEventListener(EVENTS.CLICK, this.onContactDealer);
        }
    }

    /**
     * @method detachEvents
     * @description Detaches event handlers from DOM elements
     */
    detachEvents() {
        if (this.changeDealerCta) {
            this.changeDealerCta.removeEventListener(EVENTS.CLICK, this.onChangeDealer);
        }

        if (this.contactDealerCta) {
            this.contactDealerCta.removeEventListener(EVENTS.CLICK, this.onContactDealer);
        }
    }

    /**
     * @method destroy
     * @description Destroys the view from the DOM and removes all events
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method getDealer
     * @description Fetches a dealer based in ID from the dealerLocatorApi
     * and on success sets the dealer and renders the template
     */
    getDealer() {
        dealerLocatorApi.getDealer({
            country: this.config.country,
            id: this.config.id,
            language: this.config.language,
            type: 'dealers'
        }).then((dealer) => {
            this.dealer = dealer;
            this.config.onDealerLoaded(dealer);
            this.renderDealerTemplate();
        }).catch((err) => {
            console.error('DealerInfo', err);
        });
    }

    /**
     * @method onChangeDealer
     * @description Handler to apply the config callback `onChangeDealer`
     */
    onChangeDealer() {
        this.config.onChangeDealer(this.changeDealerCta);
    }

    /**
     * @method onContactDealer
     * @description Handler to apply the config callback `onContactDealer`
     */
    onContactDealer() {
        this.config.onContactDealer(this.dealer, this.contactDealerCta);
    }

    /**
     * @method renderDealerImage
     * @description Creates an instance of `PictureLoader` and appends it
     * to the dealer template
     */
    renderDealerImage() {
        const image = new PictureLoader({
            media: {
                imgL: this.config.image.dealer,
                alt: ''
            },
            fallbackMedia: {
                imgL: this.config.image.default,
                alt: ''
            },
            itemProp: 'photo'
        });
        if (this.dealerImageElm) {
            this.dealerImageElm.appendChild(image.render());
        }
    }

    /**
     * @method renderDealerTemplate
     * @description Renders the dealerInfoCardTemplate to the
     * element node
     */
    renderDealerTemplate() {
        const preferredDealer = new UserProfile().getPreferredDealer();
        const isPreferred
            = !!(preferredDealer && preferredDealer.dealerId === this.dealer.dealerId);

        this.dealerInfoElm = dealerInfoCardTemplate(
            this.dealer,
            this.config.content,
            isPreferred,
            this.config.disableCtas,
            this.config.disableImage,
            this.config.viewMoreUrl,
            this.config.variantInfo
        )({ getNode: true });

        // if the element has already rendered to the DOM, replace it;
        // otherwise set the element to the dealerInfoElm
        if (this.element.parentNode) {
            this.element.parentNode.replaceChild(this.dealerInfoElm, this.element);
        } else {
            this.element = this.dealerInfoElm;
        }

        this.cacheDom();

        if (this.config.disableImage === false) {
            this.renderDealerImage();
        }
        this.attachEvents();
    }

    /**
     * @method render
     * @description Returns the view element node
     * @return {Element|*}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode, tokenReplace } from 'utils';

/**
 * @function
 * @name renderSearchTermsLabel
 * @description render label replacing search terms
 * @param {String} dealerResultHeading
 * @param {String} searchString
 * @return {String} search terms label
 */
const renderSearchTermsLabel = (dealerResultHeading, searchString) =>
    tokenReplace(dealerResultHeading, { searchTerms: searchString });

/**
 * Template for a DealerSelector Dealer List subheading
 * Uses ES6 template literal to composite the markup
 */
export default ({ dealerResultHeading, closestDealersHeading, searchString, hasNearbyDistance }) => htmlNode `
    ${hasNearbyDistance ? renderSearchTermsLabel(dealerResultHeading, searchString) : closestDealersHeading}
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

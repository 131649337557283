import { htmlNode, tokenReplace } from 'utils';

const createDescriptionText = (content, cookieInfo) => {
    const desc = content.reminderDescription;
    const email = `<span class='mbs-quick-registration__email'>${cookieInfo.email}</span>`;
    const link = `<button class='link link_plain-link mbs-quick-registration__login-link' data-analytics-trigger='cta-login'>${content.loginHereLC}</button>`;
    return tokenReplace(desc, [email, link]);
};

/**
 * Template for a QuickRegistrationReminder view.
 * Uses ES6 template literal to composite the markup for a QuickRegistration view
 */
export default (content, cookieInfo) => htmlNode `
    <div class="mbs-quick-registration">
        <div class="mbs-quick-registration__content">
            <div class="mbs-quick-registration__reminder">
                <div class="mbs-quick-registration__header mbs-quick-registration__header--bottom-border">
                    ${content.reminderHeader}
                </div>
                <div class="mbs-quick-registration__reminder-container">
                    <div class="mbs-quick-registration__reminder-description">${createDescriptionText(content, cookieInfo)}</div>
                </div>
                <div class="mbs-quick-registration__benefits">
                    <div class="mbs-quick-registration__benefits-heading">${content.benefits}</div>
                    <div class="mbs-quick-registration__benefits-icons">
                        <div class="mbs-quick-registration__icon mbs-quick-registration__builds-icon">${content.savedBuilds}</div>
                        <div class="mbs-quick-registration__icon mbs-quick-registration__inventory-icon">${content.savedInventory}</div>
                        <div class="mbs-quick-registration__icon mbs-quick-registration__recommendations-icon">${content.recommendations}</div>
                    </div>
                </div>
            </div>
            <button class="mbs-quick-registration__reminder-cta button button_primary button--full-width" data-analytics-trigger="cta-continue">${content.continue}</button>
        </div>
    </div>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// used modules
import { YouTubeModalContent } from 'partials/youtube';

// Partial dependencies
import ToggleDrawer from 'partials/ToggleDrawer';
import { noop } from 'utils';

// Local templates
import ModalCarouselItemTemplate from './../templates/ModalCarouselItemTemplate';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {MULTIMEDIA_YOUTUBE: string}
 */
const CLASSES = {
    DISCLAIMER_DRAWER: 'disclaimer__drawer',
    DISCLAIMER_TOGGLE: 'disclaimer__toggle',
    DISCLAIMER_MARKER: 'disclaimer__marker',
    YOUTUBE_CONTAINER: 'youtube-modal__outer-container'
};

/**
 * @const DEFAULT_CONFIG
 * @description Default configuration options for view
 * @type {{onDisclaimerOpen: *}}
 */
const DEFAULT_CONFIG = {
    onDisclaimerOpen: noop
};

/**
 * @class  OptionDetails
 * @description  class to manage the ModalCarouselItem view
 * @param {Object} content
 */
export default class ModalCarouselItem {
    /**
     * @method  constructor
     * @description  create the ModalCarouselItem view based on template
     * @param {Object} config
     * @param {Object} localized content
     */
    constructor(itemData, content, config = {}) {
        this.config = { ...DEFAULT_CONFIG, ...config };
        this.content = content;
        this.disclaimerMarkers = null;
        this.itemData = itemData;
        this.itemData.disclaimerCopy = content.viewDisclaimer;
        this.hasDisclaimers = this.itemData.disclaimers && this.itemData.disclaimers.length > 0;
        this.youtube = null;
        this.toggleDrawer = null;

        // method aliases
        this.onDisclaimerMarkerClick = this.onDisclaimerMarkerClick.bind(this);
        this.afterModalOpen = this.afterModalOpen.bind(this);

        this.init();
    }

    /**
     * @method  init
     * @description  initilize the class
     */
    init() {
        this.createTemplate();
        this.createDisclaimerDrawer();
        this.attachEvents();
    }

    /**
     * @method afterModalOpen
     * @description callback method for after modal opens
     */
    afterModalOpen() {
        if (this.youtube) {
            this.youtube.initialize();
        }
    }

    /**
     * @method  createTemplate
     * @description  checks for disclaimers and Initializes it
     */
    createTemplate() {
        this.element = ModalCarouselItemTemplate(this.itemData)({ getNode: true });

        if (this.itemData.videoId && !this.itemData.html5video) {
            const youTubeElement = this.element.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);
            this.youtube = new YouTubeModalContent({
                videoId: this.itemData.videoId,
                videoTranscript: this.itemData.videoTranscript,
                triggerImageLarge: this.itemData.imageUrl,
                youTubeElement
            });
        }
    }

    /**
     * @method createDisclaimerDrawer
     * @description Creates a ToogleDrawer instance and applies its properties
     */
    createDisclaimerDrawer() {
        if (this.hasDisclaimers) {
            const drawerButton = this.element.querySelector(`.${CLASSES.DISCLAIMER_TOGGLE}`);
            const drawerContainer = this.element.querySelector(`.${CLASSES.DISCLAIMER_DRAWER}`);

            this.disclaimerMarkers = Array.prototype.slice.call(
                this.element.querySelectorAll(`.${CLASSES.DISCLAIMER_MARKER}`)
            );

            this.toggleDrawer = new ToggleDrawer(drawerButton, {
                onToggle: this.onDrawerButtonClick.bind(this),
                closedText: this.content.closeDisclaimer,
                openedText: this.content.viewDisclaimer,
                toggleDrawer: drawerContainer
            });
        }
    }

    /**
     * @method attachEvents
     * @description Attached event listeners to the disclaimer markers and applies callback handlers
     */
    attachEvents() {
        if (this.hasDisclaimers && this.disclaimerMarkers) {
            Object.values(this.disclaimerMarkers).forEach((disclaimerMarker) => {
                disclaimerMarker.addEventListener(EVENTS.CLICK, this.onDisclaimerMarkerClick);
            });
        }
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners from the disclaimer markers and removes callback handlers
     */
    detachEvents() {
        if (this.hasDisclaimers && this.disclaimerMarkers) {
            Object.values(this.disclaimerMarkers).forEach((disclaimerMarker) => {
                disclaimerMarker.removeEventListener(EVENTS.CLICK, this.onDisclaimerMarkerClick);
            });
        }
    }

    /**
     * @method onDrawerButtonClick
     * @description Click event handler for disclaimer drawer buttons that scrolls to the
     * dislaimer drawer when a button is clicked or a specific disclaimer if a `legend` is defined
     * @param event {Object}
     */
    onDrawerButtonClick(event) {
        event.stopPropagation();

        if (this.toggleDrawer.isExpanded && !event.detail.legend) {
            this.config.onDisclaimerOpen(this.toggleDrawer.drawer);
        } else if (event.detail.legend) {
            this.scrollToDisclaimers(event.detail.legend);
        }
    }

    /**
     * @method onDisclaimerMarkerClick
     * @description Click event handler for disclaimer legends - prevents
     *              scrolling to page disclaimer drawer in footer and opens
     *              toggle drawer if not already open
     * @param event {Event}
     */
    onDisclaimerMarkerClick(event) {
        event.preventDefault();
        const legend = event.currentTarget.attributes.getNamedItem('data-legend').nodeValue;

        if (this.toggleDrawer && !this.toggleDrawer.isExpanded) {
            this.toggleDrawer.onClickCallback(event);
        } else {
            this.scrollToDisclaimers(legend);
        }
    }

    /**
     * @method scrollToDisclaimers
     * @description Scrolls to a specific disclaimer based on the legend code
     */
    scrollToDisclaimers(legend) {
        const legendDetailContainer = this.toggleDrawer.drawer.querySelector(`[data-legend="${legend}"]`);

        if (legendDetailContainer) {
            this.config.onDisclaimerOpen(legendDetailContainer);
        }
    }

    /**
     * @method enable
     * @description Calls YouTubeModalContent.enable()
     */
    enable() {
        if (this.youtube) {
            this.youtube.enable();
        }
    }

    /**
     * @method disable
     * @description Calls YouTubeModalContent.disable()
     */
    disable() {
        if (this.youtube) {
            this.youtube.disable();
        }
    }

    /**
     * @method destroy
     * @description  remove all reference for the class
     */
    destroy() {
        this.detachEvents();

        if (this.toggleDrawer) {
            this.toggleDrawer.destroy();
        }
        this.element.remove();
    }

    /**
     * @methdo render
     * @description  return the HTML Node with the element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a DealerSelector view.
 * Uses ES6 template literal to composite the markup for a DealerSelector module
 */
export default () => htmlNode`
    <div data-dealer-selector class="dealer-selector dealer-locator-container dealer-locator_in-page" data-analytics-container="{'blade': 'dealer-selector'}" >
        <div class="content-loader">
            <div class="content-loader__spinner">
                <div class="loading-spinner loading-spinner--large"></div>
            </div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

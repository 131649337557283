// Library dependencies
import html from '@mbusa/now-ui-utils.html-node';

/**
 * @description Template for custom select custom option control
 * Uses ES6 template literal to composite the markup for a custom select control
 * @param {Object} props The template properties
 * @param {string} props.id='' ID for the control
 * @param {string} props.name='' Name for the control
 * @param {Array} props.items=[] The option items list
 * @param {string} props.errorMessage='' Message to display on error
 * @param {string} props.cssClass='' The cssClass for the outer element
 * @param {number} props.defaultSelection The default item index
 * @param {string} props.icon='' Optional icon to display in the select box
 * @param {string} props.prefix='' Optional prefix to display in the select box
 * @param {string} props.moreInfoText='' Optional additional information
 * @param {string} props.analyticsTrigger='' The analytics trigger for the control
 * @param {string} props.labelText='Select' The label for the control
 * @param {boolean} props.disabled=false Indicates if the control is disabled
 * @param {string} props.ariaLabelText='' Aria label for the control
 * @param {boolean} props.required=false Indicates if the control is a required field
 * @returns {function} A function that returns the HTML string or Element
 */
export default ({
    id = '',
    name = '',
    items = [],
    errorMessage = '',
    cssClass = '',
    defaultSelection,
    icon = '',
    moreInfoText = '',
    analyticsTrigger = '',
    labelText = 'Select',
    disabled = false,
    ariaLabelText = '',
    required = false
} = {}) => html`
    <div class="select-custom-option-control${cssClass ? ` ${cssClass}` : ''}" ${name !== '' && `data-name="${name}"`}>
        <div class="select-custom-option-control__input-container">
            <div class="select-custom-option-control__combobox-container">
                <button class="select-custom-option-control__combobox"
                        aria-haspopup="listbox"
                        ${analyticsTrigger ? ` data-analytics-trigger="${analyticsTrigger}"` : ''}
                        type="button"
                        ${disabled ? ' disabled="disabled "' : ''}
                        ${required ? 'data-required' : ''}
                        aria-describedby="error${id}"
                >
                    ${icon && `<span class="icon-mb ${icon}"></span>`}
                    <label id="label-${id}" class="select-custom-option-control__input-label">
                        <span class="select-custom-option-control__input-label-text">
                            <span class="select-custom-option-control__input-label-value">${labelText}</span>
                            <span class="offscreen">${ariaLabelText}</span>
                        </span>
                    </label>
                    <span class="select-custom-option-control__value" id="select-label-${id}">
                    </span>
                    <span class="select-custom-option-control__caret-icon" />
                </button>
            </div>
        </div>
        <ul class="select-custom-option-control__list"
            tabindex="-1"
            ${items.length > 0 ? 'role="listbox"' : ''}
        >
            ${items.map((item, index) => `
                <li class="select-custom-option-control__option ${index === defaultSelection ? 'select-custom-option-control__option--selected' : ''}" 
                    aria-selected="${index === defaultSelection}"
                    aria-label="${item.ariaLabel || ''}"
                    role="option"
                    tabindex="-1"
                    data-value="${item.value}"
                >
                   ${item.label}
                </li>
            `).join('')}
        </ul>
        <p id="error${id}" class="select-custom-option-control__error-message">${errorMessage}</p>
        ${moreInfoText ? `<span class="select-custom-option-control__more-info-text">${moreInfoText}</span>` : ''}
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { CUSTOM_EVENTS } from 'Constants';
import { customEventDispatcher } from 'utils';

import chapterClasses from './../views/chapters';

let classes = {
    ...chapterClasses
};

class ChapterProxy {
    constructor(className, chapterData) {
        try {
            return new classes[className](chapterData);
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    static registerClasses(localClasses) {
        classes = {
            ...classes,
            ...localClasses
        };
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.DATA_LOADED,
                {
                    detail: {
                        registeredClasses: classes
                    }
                }
            )
        );
    }

    static classIsRegistered(className) {
        return !!classes[className];
    }

    static get classes() {
        return classes;
    }
}

export default ChapterProxy;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Iframe Module
 */
import { screen } from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {{IFRAME: string}}
 */
const CLASSES = {
    IFRAME: 'iframe-module__iframe'
};

export default class IframeModule {
    constructor(element) {
        this.element = element;
        this.init();
    }

    /**
     * @method init
     * @description initializes the IframeModule app
     */
    init() {
        this.cacheDOM();
        if (this.iframe && this.iframe.dataset.maintainAr === 'true') {
            this.onScreenResize = this.onScreenResize.bind(this);
            this.attachEvents();
            this.setAspectRatio();
            this.onScreenResize();
        }
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.iframe = this.element.querySelector(`.${CLASSES.IFRAME}`);
    }

    /**
     * @method attachEvents
     * @description attaches event listeners
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
    }

    /**
     * @method setAspectRatio
     * @description Sets aspect ratio of iframe, for screen resize
     */
    setAspectRatio() {
        const w = parseInt(this.iframe.dataset.arWidth, 10);
        const h = parseInt(this.iframe.dataset.arHeight, 10);
        if (w && h) {
            this.aspectRatio = h / w;
        }
    }

    /**
     * @method onScreenResize
     * @description Handles screen resize events
     */
    onScreenResize() {
        this.iframe.style.height = `${this.iframe.offsetWidth * this.aspectRatio}px`;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

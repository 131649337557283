import template from './../templates/preferredDealerTemplate';

import Dealer from './Dealer';

export default class PreferredDealer extends Dealer {
    /**
     * @constructor
     * @description PreferredDealer partial
     * @param {Object} dealer - Dealer object with which the preferred dealer must be created
     * @param {Object} content - content object which should have the i18n content
     * @param {Function} removePreferredDealerCallback - Callback when preferred dealer is clicked
     */
    constructor(dealer, content, model) {
        super(dealer, content, 0, model);

        this.expand();
        this.setPreferred();
    }

    /**
     * @method generateElement
     * @description Creates the element attribute from the preferred dealer template.
     * This method is the overriden method of the parent.
     */
    generateElement(dealer, content) {
        this.element = template(dealer, content)({ getNode: true });
    }

    /**
     * @method onUpdate
     * @description handle state changes
     * @param prevState {Object} Previous state before change
     * @param nextState {Object} Next state after change
     */
    onUpdate(prevState, nextState) {
        // When a retailer is selected
        if (nextState.selectedRetailer) {
            // was it preferredDealer
            if (nextState.selectedRetailer.dealerId === this.dealer.dealerId) {
                // sanity check
                if (nextState.selectedRetailer.zip === this.dealer.zip) {
                    // expand the preferredDealerInfo
                    this.expand();
                }
            } else {
                // collapse the preferredDealerInfo
                this.collapse();
            }
        }

        // When prefererd dealer is removed
        if (prevState.preferredDealer && nextState.preferredDealer === null) {
            if (prevState.preferredDealer.dealerId === this.dealer.dealerId) {
                // additional check for dealers with same Id
                if (prevState.preferredDealer.zip === this.dealer.zip) {
                    this.removePreferredDealer();
                }
            }
        }
    }

    /**
     * @method selectDealer
     * @description Expand PreferredDealerInfo on click
     * This method is the overriden method of the parent.
     */
    selectDealer(dealer) {
        this.expand();
        this.model.setSelectedRetailer(dealer);
    }

    /**
     * @method setPreferred
     * @description Sets data-is-preferred="true"
     * This method is the overriden method of the parent.
     */
    setPreferred() {
        this.element.dataset.isPreferred = true;
    }

    /**
     * @method unsetPreferred
     * @description Sets the data-is-preferred="false"
     * This method is the overriden method of the parent.
     */
    unsetPreferred() {
        this.element.dataset.isPreferred = false;
    }

    /**
     * @method removePreferredDealer
     * @description remove the preferredDealer
     */
    removePreferredDealer() {
        this.model.setPreferredDealer(null);
        this.destroy();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom range control
 * Uses ES6 template literal to composite the markup for a custom range control
 * with handles to select min and max values
 */
export default ({ id = '', cssClass = '', labelStart = '', labelEnd = '', title = '', analyticsTrigger = 'cta' }) => htmlNode`
    <div id="${id}" class="range-control ${title} ${cssClass}" data-analytics-trigger="${analyticsTrigger}">
        <span id="min-${id}" class="range-control__label range-control__label--min">${labelStart}</span>
        <div class="range-control__container">
            <div class="range-control__track">
                <div class="range-control__track-background"></div>
                <div class="range-control__fill"></div>
            </div>
            <div class="range-control__handles">
                <button class="range-control__handle range-control__handle--min"
                        data-handle-position="min"
                        aria-label="${title} min"
                        aria-orientation="horizontal"
                        aria-valuemin=""
                        aria-valuenow=""
                        aria-valuemax=""
                        aria-valuetext=""
                        role="slider"></button>
                <button class="range-control__handle range-control__handle--max"
                        data-handle-position="max"
                        aria-label="${title} max"
                        aria-orientation="horizontal"
                        aria-valuemin=""
                        aria-valuenow=""
                        aria-valuemax=""
                        aria-valuetext=""
                        role="slider"></button>
            </div>
        </div>
        <span id="max-${id}" class="range-control__label range-control__label--max">${labelEnd}</span>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import ViewMoreDrawer from 'partials/view-more-drawer';
import { renderer, screen } from 'utils';

import dealerNotesTemplate from './../templates/dealerNotesTemplate';

/**
 * @const ClASSES
 * @type {Object}
 * @description CSS Classes used to query DOM for ViewMoreDrawer
 */
const CLASSES = {
    DEALER_NOTES_CONTENT: 'dealer-info-map__dealer-notes'
};

/**
 * @const DEFAULT_NOTES_HEIGHT
 * @type {number}
 * @default
 * @description Default option for the notes height
 */
const DEFAULT_NOTES_HEIGHT = 180;

/**
 * @class DealerNotes
 * @description Class representing a module to display the notes from a dealer
 */
export default class DealerNotes {
    /**
     * @constructor
     * @description Creates an instance of DealerNotes
     * @param {Object} content - i18n content object
     * @param {string} notes - Dealer notes
     */
    constructor(content, notes) {
        this.content = content;
        this.notes = notes;
        this.viewMoreDrawer = null;

        this.onResizeHandler = this.onResizeHandler.bind(this);
        this.createViewMore = this.createViewMore.bind(this);

        this.createDealerNotes();
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method destroy
     * @description Destroys the DealerNotes instance
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
        this.viewMoreDrawer.destroy();
        this.viewMoreDrawer = null;
    }

    /**
     * @method cacheDOM
     * @description Caches DOM element
     */
    cacheDOM() {
        this.dealerNotesContentElm = this.element.querySelector(`.${CLASSES.DEALER_NOTES_CONTENT}`);
    }

    /**
     * @method attachEvents
     * @description Attaches event handlers:
     * 1. Screen resize handler
     * 2. DOM mutation handler
     */
    attachEvents() {
        screen.addResizeListener(this.onResizeHandler);

        // Create instance of MutationObserver with callback that creates a ViewMoreDrawer
        this.mutationObserver = new window.MutationObserver(() => {
            this.mutationObserver.disconnect();
            this.createViewMore();
        });

        // Listen for DOM mutations on parent element
        this.mutationObserver.observe(this.element.parentElement, {
            childList: true
        });
    }

    /**
     * @method detachEvents
     * @description Detaches all events associated with DealerNotes
     */
    detachEvents() {
        screen.removeResizeListener(this.onResizeHandler);

        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
            this.mutationObserver = null;
        }
    }

    /**
     * @method onResizeHandler
     * @description Screen resize handler. This calls the method to create instance of ViewMoreHandler per rules.
     */
    onResizeHandler() {
        this.createViewMore();
    }

    /**
     * @method createDealerNotes
     * @description Adds the heading and notes to the DOM element that will be the dealer notes
     */
    createDealerNotes() {
        this.element = dealerNotesTemplate(this.content.heading, this.notes)({ getNode: true });
    }

    /**
     * @method createViewMore
     * @description Renders the ViewMoreDrawer component if height of notes container is higher than 180
     * for small screens, else destroy instance and set innerHTML of notes element to passed in notes
     */
    createViewMore() {
        const contentHeight = this.dealerNotesContentElm.offsetHeight;
        const isSmallScreen = screen.getState().small;

        if (contentHeight > DEFAULT_NOTES_HEIGHT && isSmallScreen && this.viewMoreDrawer === null) {
            this.viewMoreDrawer = new ViewMoreDrawer({
                parentInitialHeight: DEFAULT_NOTES_HEIGHT,
                viewMoreCopy: this.content.viewMore,
                viewLessCopy: this.content.viewLess
            }, this.notes);
            renderer.insert(this.viewMoreDrawer.render(), this.dealerNotesContentElm);
        } else if (this.viewMoreDrawer && !isSmallScreen) {
            this.viewMoreDrawer.destroy();
            this.viewMoreDrawer = null;
            this.dealerNotesContentElm.innerHTML = this.notes;
        }
    }

    /**
     * @method render
     * @description Returns the view element node
     * @return {Element|*}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization labels for model finder light module
 */
const defaultLocalization = {
    categorySelectorLabelText: 'Discover'
};

const {
    categorySelectorLabelText
} = (window.mbVans && window.mbVans.ns('moduleData', 'modelFinderLight').localization) || defaultLocalization;

export default {
    categorySelectorLabelText
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { renderer } from 'utils';

import config from './config';

import RecallsLanding from './views/RecallsLanding';
import UrgentRecallsLanding from './views/UrgentRecallsLanding';

/**
 * Recall module class. Loads the config and renders the landing/urgent view
 */
export default class Recall {
    /**
     * Constructs the Recall module
     * @param {Element} element - Node element
     */
    constructor(element) {
        this.element = element;
        this.recallsLanding = null;
        this.urgentRecall = null;

        this.init();
    }

    /**
     * @method init
     * @description Initializes the module and loads a different view based on the config
     */
    async init() {
        const content = await this.loadContent();

        if (content.isLanding) {
            this.recallsLanding = new RecallsLanding(content, config);
            renderer.insert(this.recallsLanding.render(), this.element);
        } else if (content.isUrgent) {
            const statesData = await this.loadStatesData();
            const modelsData = await this.loadModelsData();
            this.urgentRecallsLanding = new UrgentRecallsLanding(content, config, statesData, modelsData);
            renderer.insert(this.urgentRecallsLanding.render(), this.element);
        }
    }

    /**
     * @method loadContent
     * @description Fetches the config and converts the response into a JSON object
     */
    async loadContent() {
        const response = await fetch(config.contentPath);
        const content = response.json();

        return content;
    }

    /**
     * @method loadStatesData
     * @description Fetches the states data for urgent recalls and converts the response into a JSON object
     */
    async loadStatesData() {
        const response = await fetch(config.statesData);
        const statesData = response.json();

        return statesData;
    }

    /**
     * @method loadContent
     * @description Fetches the models data for urgent recalls and converts the response into a JSON object
     */
    async loadModelsData() {
        const response = await fetch(config.modelsData);
        const modelsData = response.json();

        return modelsData;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { html, htmlNode } from 'utils';

/**
 * @description Template for custom select control
 * Uses ES6 template literal to composite the markup for a custom select control
 */
export default ({
    id = '',
    name = '',
    items = [],
    errorMessage = '',
    cssClass = '',
    defaultSelection,
    icon = '',
    prefix = '',
    moreInfoText = '',
    analyticsTrigger = '',
    ariaLabelText = '',
    labelText = 'Select',
    disabled = false,
    theme = 'default',
    isCustomLabel = false,
    required = false,
    // hasTooltip = false,
    }) => {
    const label = (
        `<label id="label-${id}" class="select-control__input-label">
            <span class="select-control__input-label-text">
                <span class="select-control__input-label-value" ${required ? 'data-is-required' : ''}>${labelText}</span>
                <span class="offscreen">${ariaLabelText}</span>
            </span>
        </label>`
    );
    return htmlNode`
    <div class="select-control select-control--${theme} ${cssClass}" ${name !== '' && `data-name="${name}"`}>
        <div class="select-control__input-container">
            <div class="select-control__combobox-container">
                <button class="select-control__combobox"
                        aria-haspopup="listbox"
                        type="button"
                        ${analyticsTrigger ? ` data-analytics-trigger="${analyticsTrigger}"` : ''}
                        ${disabled ? ' disabled="disabled "' : ''}
                        ${required ? ' data-required' : ''}
                >
                    ${icon && `<span class="icon-mb ${icon}"/>`}
                    ${prefix && `<span class="select-control__prefix">${prefix}</span>`}
                    ${label}
                    <span class="select-control__value" id="select-label-${id}"></span>
                    <span class="select-control__caret-icon"></span>
                </button>
            </div>
        </div>
        <ul class="select-control__list"
            tabindex="-1"
            ${items.length > 0 ? 'role="listbox"' : ''}
            aria-label="${ariaLabelText}"
        >
            ${items.map((item, index) => html`
                <li class="select-control__option ${index === defaultSelection ? 'select-control__option--selected' : ''}"
                    aria-selected="${index === defaultSelection}"
                    aria-label="${item.ariaLabel || (isCustomLabel ? '' : item.label)}"
                    role="option"
                    tabindex="-1"
                    data-value="${item.value}"
                >
                    ${item.label}
                </li>
            `).join('')}
        </ul>
         <p class="select-control__error-message" id="error-${id}">${errorMessage}</p>
        ${moreInfoText ? `<span class="select-control__more-info-text">${moreInfoText}</span>` : ''}
    </div>`;
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

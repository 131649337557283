// Util dependencies
import { noop, renderer } from 'utils';

/**
 * @const defaultConfig
 * @description Default configuration options
 * @type {{initialSections: number, moreSections: number, firstSectionTiles: number}}
 */
const defaultConfig = {
    firstSectionTiles: 4,
    tileSectionClass: 'tiles-sections__section'
};

export default class TilesSections {
    /**
     * @method constructor
     * @description Instantiates the TilesSection partial
     * @param {Object} element
     * @param {Array} tiles
     * @param {Function} callback
     */
    constructor(element, tiles = [], config = defaultConfig, callback = noop) {
        this.element = element;
        this.tiles = tiles;
        this.config = Object.assign(defaultConfig, config);
        this.callback = callback;
        this.sections = null;
        this.setTilesCallback = this.setTilesCallback.bind(this);
        this.init();
    }

    /**
    * @method init
    * @description  this method exuted exactly after all the properties
    * has been setup by default.
    */
    init() {
        this.setTilesSections();
    }

    /**
     * @method getTilesSections
     * @description Return the section elements rendered by the template
     * @return {Object} The section elements
     */
    getSections() {
        return this.sections;
    }

    /**
     * @method setTilesCallback
     * @description Callback method for when the tiles are set and you
     * need to handle the DOM. Apply the `callback` with the sections
     * @param element {Object} The section element containers
     */
    setTilesCallback(element) {
        this.callback(element);
    }

    /**
     * @method setTilesSections
     * @description Re-render the tiles divided in sections
     */
    setTilesSections() {
        const { firstSectionTiles, tileSectionClass } = this.config;
        // create an array of sections
        const sections = [this.tiles.slice(0, firstSectionTiles)];

        // if there are additional tiles beyond the first section, add them to the sections
        if (this.tiles.length >= firstSectionTiles) {
            sections.push(
                this.tiles.slice(firstSectionTiles)
            );
        }

        // empty out the the original element, as we will are going to append new items to it
        renderer.empty(this.element);

        // iterate through each section and create a TileSection template for each
        // and append each sections items to the template element
        sections.forEach((section) => {
            // renderer.fromTemplate fails when trying to appendChild, so doing it the old fashioned way
            const sectionTemplate = document.createElement('div');
            sectionTemplate.setAttribute('class', tileSectionClass);

            // iterate through the section items and append each element to the sectionTemplate
            section.forEach((sectionItem) => {
                sectionTemplate.appendChild(sectionItem);
            });

            // once all of the section items are appended to the sectionTemplate
            // append the template to this.element.
            this.element.appendChild(sectionTemplate);
        });

        this.sections = this.element.querySelectorAll(`.${tileSectionClass}`);
        this.setTilesCallback(this.element);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { html } from 'utils';

/**
 * @description Template for the thumbnail navigation
 * Uses ES6 template literal to composite the markup for the thumbnail navigation
 */
export default () => html`
    <div class="gallery__thumbnail-nav"></div>
`.trim();

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * API responsible for loading Google API's
 */

/**
 * @property
 * @description Stores the maps api loader promise that resolves once the api has loaded
 */
let mapsAPILoader;

function loadScript(path) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = path;
    script.async = true;
    document.head.appendChild(script);
}

/**
 * @method loadGoogleMaps
 * @description Loads Google Maps API asynchronously
 * @returns {Promise} Promise that resolves when api has loaded
 */
function loadGoogleMaps(lang = 'en') {
    const apiKey = window.mbVans.ns('applicationProperties', 'googleApiKey');
    const apiChannel = window.mbVans.ns('applicationProperties', 'googleMapChannel');
    const language = window.mbVans.ns('pageData', 'dealerLocator', 'config').language || lang;

    if (!mapsAPILoader) {
        mapsAPILoader = new Promise((resolve) => {
            window.mapApiReady = () => resolve();
            const path = `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&v=3&key=${apiKey}&channel=${apiChannel}&language=${language}&callback=mapApiReady`;
            loadScript(path);
        });
    }

    return mapsAPILoader;
}

/**
 * Export public api methods
 */
export default {
    loadGoogleMaps
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Constant list of residence address form types
 */
export default {
    CURRENT: 'current',
    PREVIOUS: 'previous',
    SPOUSE: 'spouse'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization labels for vehicle-selector partial
 */
const defaultLocalization = {
    changeVehicleSelection: '{0} to change your selection',
    clickHere: 'Click here',
    displayName: '{tonnage} {wheelbase} {roof} {engine}',
    displayNameMetris: '{wheelbase} {roof} {engine}',
    displayNameCabChassis: '{tonnage} {wheelbase} {engine}',
    displayNameWithYear: '{year} {tonnage} {wheelbase} {roof} {engine}',
    displayNameMetrisWithYear: '{year} {wheelbase} {roof} {engine}',
    displayNameCabChassisWithYear: '{year} {tonnage} {wheelbase} {engine}',
    priceComingSoon: 'Price Coming Soon',
    selectClass: 'Select Class',
    selectBodystyle: 'Select Vehicle',
    selectClassErrorMessage: 'Please select a class',
    selectBodystyleErrorMessage: 'Please select a vehicle',
    selectModel: 'Select Trim',
    selectModelErrorMessage: 'Please select a trim',
    selectVehicleDetails: 'Select your vehicle details below.',
    selectYear: 'Select Year',
    selectYearErrorMessage: 'Please select a year',
    startingAt: 'Starting at',
    vehicleSelected: 'This is the vehicle you have selected.',
    vehicleSelectorError: 'Click to confirm your vehicle selection.',
};

const {
    changeVehicleSelection,
    clickHere,
    displayName,
    displayNameMetris,
    displayNameCabChassis,
    displayNameWithYear,
    displayNameMetrisWithYear,
    displayNameCabChassisWithYear,
    priceComingSoon,
    selectClass,
    selectBodystyle,
    selectClassErrorMessage,
    selectBodystyleErrorMessage,
    selectModel,
    selectYear,
    selectYearErrorMessage,
    selectModelErrorMessage,
    selectVehicleDetails,
    startingAt,
    vehicleSelected,
    vehicleSelectorError
} = (window.mbVans && window.mbVans.ns('pageData').localization) || defaultLocalization;

export default {
    changeVehicleSelection,
    clickHere,
    displayName,
    displayNameMetris,
    displayNameCabChassis,
    displayNameWithYear,
    displayNameMetrisWithYear,
    displayNameCabChassisWithYear,
    priceComingSoon,
    selectClass,
    selectBodystyle,
    selectClassErrorMessage,
    selectBodystyleErrorMessage,
    selectModel,
    selectYear,
    selectYearErrorMessage,
    selectModelErrorMessage,
    selectVehicleDetails,
    startingAt,
    vehicleSelected,
    vehicleSelectorError
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

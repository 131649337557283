// Util dependencies
import { html } from 'utils';

/**
 * @description Template for tile section
 * Uses ES6 template literal to composite the markup for a gallery section
 * and insert the theme class to corresponding elements
 * @param {String} theme - class modifier for the different types of
 * gallery sections (groups)
 */
export default (theme) => html`
    <div class="gallery__section gallery__section--${theme}">
        <div class="gallery__items" data-section-media>
            ${theme === 'gallery-1-2' ? `
                <div class="gallery__column gallery__column--1"></div>
                <div class="gallery__column gallery__column--2"></div>
            ` : ''}
            ${theme === 'gallery-2-1' ? `
                <div class="gallery__column gallery__column--2"></div>
                <div class="gallery__column gallery__column--1"></div>
            ` : ''}
        </div>
    </div>
`.trim();

// do not delete 9fbef606107a605d69c0edbcd8029e5d

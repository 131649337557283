/**
 * @const defaultLocalization
 * @description Default localization labels for "More Info" window
 * buildThisVehicle: The label for the "Build" cta.
 * download: The label for the "Download" cta.
 * @type {{buildThisVehicle: String, downlod: String}}
 */
const defaultLocalization = {
    buildThisVehicle: 'Build This Vehicle',
    download: 'Download',
    galleryFilters: {
        360: '360',
        all: 'All',
        exterior: 'Exterior',
        interior: 'Interior',
        video: 'Videos'
    }
};

// Deconstruct localization object
const {
    buildThisVehicle,
    download,
    galleryFilters
} = window.mbVans.ns('pageData').localization || defaultLocalization;

/**
 * @const MEDIA_TYPES
 * @description Enum with constant strings for media types of gallery
 */
const MEDIA_TYPES = {
    ALL: 'all',
    HTML_VIDEO: 'html5video',
    IMAGE: 'image',
    MEDIA: 'media',
    THREE_SIXTY: '360',
    YOU_TUBE: 'youtube'
};

/**
 * @function composeCTAs
 * @description Based on the gallery item data build the available CTAs
 * @param {Object} galleryItem
 * @return {Array} An array of objects with the following structure:
 * {
 *   type: 'link',
 *   endpoint: byoCTA,
 *   target: '_self',
 *   rel: 'noopener',
 *   className: '',
 *   label: buildThisVehicle
 * }
 */
function composeCTAs(galleryItem) {
    // Empty array to build CTAs into
    const CTAs = [];
    const defaultCTA = {
        type: 'link',
        endpoint: '',
        target: '_self',
        rel: 'noopener',
        className: '',
        label: ''
    };

    // Deconstruct gallery object
    const {
        media: {
            byoCTA,
            wallpaperImage
        } = {}
    } = galleryItem;

    // Add BYO CTA
    if (byoCTA) {
        CTAs.push({
            ...defaultCTA,
            ...{ endpoint: byoCTA, label: buildThisVehicle, dataAnalyticsTrigger: 'cta-build' }
        });
    }

    // Add wallpaper CTA
    if (wallpaperImage) {
        CTAs.push({
            ...defaultCTA,
            ...{ endpoint: wallpaperImage, target: '_blank', rel: 'noopener', className: 'download', label: download, type: 'download' }
        });
    }

    return CTAs;
}

/**
 * @function composeMediaObject
 * @description Based on the gallery item data build the media property
 * @param {Object} galleryItem
 * @return {Object} Media property of new gallery item with following structure:
 * {
 *   thumbnailImage: "",
 *   imgAltText: "",
 *   imgLarge: "",
 *   imgSmall: null,
 *   overlayImage: "",
 *   html5video: null,
 *   youtube: ""
 * }
 */
function composeMediaObject(galleryItem) {
    const mergedObj = {
        media: { thumbnailImage: '', overlayImage: '' },
        multimediaAsset: { imgAltText: '', imgLarge: '', imgSmall: '', youtube: '', heading: '', subheading: '' },
        ...galleryItem
    };
    // IIFE which accepts galleryItem as param
    // The param is deconstructed and the function returns
    // an object with just the necessary values
    return (({
        media: { thumbnailImage = '', overlayImage = '' },
        multimediaAsset: { imgAltText = '', imgLarge = '', imgSmall = '', youtube = '', xmlFileLocation = '', heading = '', subheading = '' }
    }) => (
        {
            heading,
            imgAltText,
            imgLarge,
            imgSmall,
            overlayImage,
            subheading,
            thumbnailImage,
            xmlFileLocation,
            youtube,
        }
    ))(mergedObj);
}

/**
 * @function composeDetailsObject
 * @description Based on the gallery item data build the details property
 * @param {Object} galleryItem
 * @return {Object} Details property of new gallery item with following structure:
 * {
 *   CTAs: [],
 *   modelTitle: "",
 *   modelPath: "",
 *   captionHeading: "",
 *   captionDescription: ""
 * }
 */
function composeDetailsObject(galleryItem) {
    const mergedObj = {
        media: { modelTitle: '', modelPath: '', captionHeading: '', captionDescription: '' },
        ...galleryItem
    };
    // IIFE which accepts galleryItem as param
    // The param is deconstructed and the function returns
    // an object with just the necessary values
    return (({
        media: { modelTitle, modelPath, captionHeading, captionDescription }
    }) => (
        {
            modelTitle,
            modelPath,
            captionHeading,
            captionDescription,
            CTAs: composeCTAs(galleryItem)
        }
    ))(mergedObj);
}

/**
 * @function composeGalleryItem
 * @description Transforms galleryItem into an object with media and details sub-objects
 * @param {Object} galleryItem
 * @return {Object} Object with following structure
 * {
 *    galleryCategory: "exterior",
 *    mediaType: "youtube",
 *    media: {
 *        thumbnailImage: "",
 *        imgAltText: "",
 *        imgLarge: "",
 *        imgSmall: null,
 *        overlayImage: "",
 *        html5video: null,
 *        youtube: ""
 *    },
 *    details: {
 *        modelTitle: "",
 *        modelPath: "",
 *        captionHeading: "",
 *        captionDescription: "",
 *        CTAs: []
 *    }
 * }
 */
function composeGalleryItem(galleryItem) {
    return {
        galleryCategory: galleryItem.galleryCategory,
        mediaType: galleryItem.mediaType,
        media: composeMediaObject(galleryItem),
        details: composeDetailsObject(galleryItem)
    };
}

/**
 * @method filterTypeCondition
 * Handy utility to check the media type of the gallery item against the
 * filter type selected by the user.
 * @param {string} itemMediaType Media type of the gallery item
 * @param {string} type Media type to check condition against
 */
function filterTypeCondition(itemMediaType, type) {
    if (type === MEDIA_TYPES.MEDIA) {
        return itemMediaType !== MEDIA_TYPES.IMAGE &&
            itemMediaType !== MEDIA_TYPES.HTML_VIDEO &&
            itemMediaType !== MEDIA_TYPES.YOU_TUBE;
    }

    if (type === MEDIA_TYPES.THREE_SIXTY) {
        return itemMediaType !== MEDIA_TYPES.IMAGE &&
            itemMediaType !== MEDIA_TYPES.THREE_SIXTY;
    }

    return false;
}

/**
 * @function getFilters
 * @description Gets the list of filters applicable for the gallery data
 * @param {Objet} galleryData Galleyr Data
 * @return {Array} An array of objects, each object { label: "All", value: "all" }
 */
function getFilters(galleryData) {
    const getMediaFilter =
        galleryData
            .map((e) => e.galleryItems)
            .flatten()
            .filter((item) => item.mediaType && filterTypeCondition(item.mediaType, MEDIA_TYPES.THREE_SIXTY))
            .map(() => MEDIA_TYPES.MEDIA)
            .unique();

    const getThreeSixtyFilter =
        galleryData
            .map((e) => e.galleryItems)
            .flatten()
            .filter((item) => item.mediaType && filterTypeCondition(item.mediaType, MEDIA_TYPES.MEDIA))
            .map(() => MEDIA_TYPES.THREE_SIXTY)
            .unique();

    return []
         // Gets unique category for each gallery item
        .concat(getMediaFilter)
        .concat(galleryData.map((item) => item.galleryCategory).unique())
        .filter((item) => item !== undefined)
        .concat(getThreeSixtyFilter)
        .unique()
         // Gets translations of filter for current site
        .map((filter) => ({
            label: galleryFilters[filter],
            value: filter
        }));
}

/**
 * @function normalizeData
 * @description Normalizes data for each gallery item object. It currently does the following:
 * 1. Modifies the galleryItem to an object with media and details sub-objects
 * 2. Adds more info CTAs to gallery items
 */
function normalizeData(galleryData) {
    const filters = getFilters(galleryData);
    const data = galleryData.map((galleryRow) => {
        galleryRow.galleryItems = galleryRow.galleryItems.map(composeGalleryItem);

        return galleryRow;
    });

    return {
        filters,
        data
    };
}

/**
 * @method getData
 * @description Fetches a collection of gallery data from an object on the window
 * @return {Promise}
 */
function getData({ endpoint = '' } = {}) {
    if (endpoint) {
        return fetch(endpoint, { credentials: 'same-origin' })
            .then((response) => response.json())
            .then(normalizeData);
    }
    return Promise.reject('Gallery endpoint not defined');
}

/**
 * @method parseNonImage
 * @description takes an array of gallery groups and parses into a new one
 * that only contains galleryItems of media type different than image
 * @param {Array} data Data to filter from
 * @param {string} filterType The filter type selected by the user.
 * @returns {Array} array of parsed gallery groups
 */
function parseNonImage(data, filterType = MEDIA_TYPES.THREE_SIXTY) {
    const defaultMediaGroup = {
        type: 'gallery-2-up',
        galleryCategory: '',
        galleryItems: []
    };

    const getAllNonImageMediaItems = (items, group) => (
        items.concat(
            group.galleryItems.filter((item) => item.mediaType && filterTypeCondition(item.mediaType, filterType))
        )
    );

    const convertMediaItemsToMediaGroups = (groups, mediaItem, index) => {
        if (index % 2 === 0) {
            const mediaGroup = { ...defaultMediaGroup, galleryItems: [] };
            mediaGroup.galleryItems.push(mediaItem);
            groups.push(mediaGroup);
        } else {
            groups[groups.length - 1].galleryItems.push(mediaItem);
        }
        return groups;
    };

    return data
        .reduce(getAllNonImageMediaItems, [])
        .reduce(convertMediaItemsToMediaGroups, []);
}

/**
 * @method filterData
 * @description Filters down the gallery groups by category or media
 * @param data {Array} data to filter from
 * @param category {string} category value
 * @param media {boolean} true if should filter by media type (return the
 * items that are not images)
 * @returns {Array} array of filtered gallery groups
 */
function filterData(data, category = 'all') {
    if (category === MEDIA_TYPES.ALL) {
        return data;
    }

    if (category === MEDIA_TYPES.MEDIA) {
        return parseNonImage(data);
    }

    if (category === MEDIA_TYPES.THREE_SIXTY) {
        return parseNonImage(data, MEDIA_TYPES.MEDIA);
    }

    const filterByCategory = (galleryGroup) => (
        galleryGroup.galleryCategory === category
    );

    return data.filter(filterByCategory);
}


// export public api
export default {
    getData,
    filterData
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

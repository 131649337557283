/**
 * @const DEFAULT_DEALER_LOCATOR_CONFIG
 * @description Fallback Dealer Locator Config
 * @type {{
    *    LEADS: {
    *        SCHEDULE_TEST_DRIVE: {
    *            URI: string,
    *            SOURCE_TYPE: string
    *        }
    *    }
    * }}
 */
const DEFAULT_DEALER_LOCATOR_CONFIG = {
    LEADS: {
        SCHEDULE_TEST_DRIVE: {
            URI: '/content/mb-vans/en_us/contact-us/schedule-test-drive.html',
            SOURCE_TYPE: 'dl'
        }
    }
};

const {
    leads: LEADS
} = window.mbVans.ns('pageData', 'dealerLocator', 'config') || DEFAULT_DEALER_LOCATOR_CONFIG;

export default {
    leads: LEADS
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

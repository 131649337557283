// Util dependencies
import { noop } from 'utils';

/**
 * @class NonResponsivePicture
 * @description Responsible for show the images from the media object
 */
export default class NonResponsivePicture {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration for a Picture
     * @type {Object}
     * @param imagePath {String} Image path to load
     * @param alt {String} Image alt attribute value
     * @const fallbackMedia {Object} fallback media object - imgS: String, imgL: String, alt: String
     * @const className {String} class name for the `picture` element
     * @param onError {Function} callback method for when a `picture` element has an error loading
     * @const onLoad {Function} callback method for when a `picture` element has completed loading
     * @const itemProp {String} Value of schema `itemProp` markup
     */
    static DEFAULT_CONFIG = {
        imagePath: '',
        alt: '',
        fallbackMedia: null,
        className: '',
        onError: noop,
        onLoad: noop,
        itemProp: null
    };

    /**
     * @constructor Create a Picture
     * @param config {Object} Configuration data
     */
    constructor(config = NonResponsivePicture.DEFAULT_CONFIG) {
        this.config = {
            ...NonResponsivePicture.DEFAULT_CONFIG,
            ...config
        };
        this.element = null;
        this.imageElem = null;

        this.createPicture();
    }

    /**
     * @method createPicture
     * @description Creates a `picture` element
     */
    createPicture() {
        this.element = document.createElement('picture');

        if (this.config.className) {
            this.element.classList.add(this.config.className);
        }

        if (this.config.itemProp) {
            this.element.setAttribute('itemprop', this.config.itemProp);
        }

        this.createImageElem();

        this.attachEvents();

        this.element.appendChild(this.imageElem);

        this.setPictureSource(this.config.imagePath);
    }

    /**
     * @method createImageElem
     * @description Creates the `picture` `image` element
     */
    createImageElem() {
        this.imageElem = document.createElement('img');
        this.imageElem.alt = this.config.alt;
        this.imageElem.classList.add('responsive-image');
    }

    /**
     * @method setPictureSource
     * @description Sets the image sources for the `picture` element
     * @param imagePath {String} Image path
     */
    setPictureSource(imagePath) {
        this.imageElem.src = imagePath;
    }

    /**
     * @method attachEvents
     * @description Attach events to the `picture` element
     */
    attachEvents() {
        this.element.onload = this.config.onLoad.bind(this);
        this.imageElem.onload = this.config.onLoad.bind(this);
        this.element.onerror = this.onError.bind(this);
        this.imageElem.onerror = this.onError.bind(this);
    }

    /**
     * @method onError
     * @description Update image source to fallback media
     */
    onError() {
        const { fallbackMedia } = this.config;
        const { imageElem } = this;

        if (fallbackMedia) {
            if (imageElem.src !== fallbackMedia.imgL) {
                this.element.onerror = null;
                this.imageElem.onerror = null;
            }

            this.setPictureSource(this.config.fallbackMedia.imgL);
            this.imageElem.classList.add('fallback-image');
        }

        this.config.onError();
    }

    /**
     * @method removeEvents
     * @description Removes events attached to the `picture` element
     */
    removeEvents() {
        this.element.onload = null;
        this.imageElem.onload = null;
        this.element.onerror = null;
        this.imageElem.onerror = null;
    }

    /**
     * @method destroy
     * @description Destroys the element by deleting it from the DOM
     */
    destroy() {
        this.removeEvents();
        this.element.remove();
        this.element = null;
        this.imageElem = null;
    }

    /**
     * @method render
     * @description Return the `picture` element
     * @returns {Element} Picture template element
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { noop } from 'utils';
import { EVENTS } from 'Constants';

import buttonControlTemplate from './../templates/buttonControlTemplate';

/**
 * @const CLASSES
 * @description Collection of CSS classes
 * @type {{DISABLED: string, VALUE_TEXT: string}}
 */
const CLASSES = {
    DISABLED: 'disabled',
    VALUE_TEXT: 'button__value-text'
};

/**
 * @const DEFAULT_CONFIG
 * @description Collection of default config values
 * @type {{analyticsTrigger: string, ariaLabel:string, type: string, modifier: string, disabled: boolean,
 * valueText: string, cssClasses: string, onClick: function}}
 */
const DEFAULT_CONFIG = {
    analyticsTrigger: null,
    ariaLabel: null,
    type: 'primary',
    modifier: '',
    disabled: false,
    valueText: '',
    cssClasses: '',
    onClick: noop,
    renderedControl: null
};

/**
 * @class ButtonControl
 * @description View for displaying a button with corresponding config, that is responsible for
 * applying a callback method when clicked
 */
export default class ButtonControl {
    /**
     * @static
     * @description Available types of button
     * @type {{PRIMARY: string, SECONDARY: string, SECONDARY_ALT: string, AMG: string}}
     */
    static TYPES = {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        SECONDARY_ALT: 'secondary_alt',
        AMG: 'amg',
        TOGGLE: 'toggle'
    };

    /**
     * @static
     * @description Available modifier that can be applied to button
     * @type {{WIDE: string, FULL_WIDTH: string, FULL_WIDTH_SMALL: string}}
     */
    static MODIFIERS = {
        WIDE: 'wide',
        FULL_WIDTH: 'full-width',
        FULL_WIDTH_SMALL: 'full-width-small-only'
    };

    /**
     * @constructor
     * @param {Object} config - Config for ButtonControl
     * @param {String} config.analyticsTrigger - Optional value to set as the button's data-analytics-trigger
     * @param {String} config.ariaLabel - Optional value to set as the button's aria-label
     * @param {String} config.type - Type of button. Values are set through ButtonControl#TYPES
     * @param {String} config.modifier - Modifier applied to button. Values are set through
     * ButtonControl#MODIFIER
     * @param {Boolean} config.disabled - Disabled flag
     * @param {String} config.valueText - Copy for button
     * @param {String} config.cssClasses - CSS Classes
     * @param {Function} config.onClick - Click handler function for the button
     * @param {NodeElement} renderedControl Existing input DOM element
     */
    constructor(config) {
        const {
            analyticsTrigger,
            ariaLabel,
            type,
            modifier,
            disabled,
            valueText,
            cssClasses,
            onClick,
            renderedControl
        } = { ...DEFAULT_CONFIG, ...config };

        this.element = renderedControl || this.getElement({
            type,
            modifier,
            disabled,
            cssClasses,
            valueText,
            analyticsTrigger,
            ariaLabel
        });
        this.onClickCallback = onClick;
        this.onClick = this.onClick.bind(this);

        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method getElement
     * @description Calls the template with necessary values
     * @returns {Node} Loading Button Control Template Node
     */
    getElement({ type, modifier, disabled, cssClasses, valueText }) {
        return buttonControlTemplate({
            type,
            modifier,
            disabled,
            cssClasses,
            valueText
        })({ getNode: true });
    }

    /**
     * @method destroy
     * @description Destroys the instance
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method attachEvents
     * @description Attach events
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, this.onClick);
    }

    /**
     * @method detachEvents
     * @description Detachs click events
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.CLICK, this.onClick);
    }

    /**
     * @method cacheDOM
     * @description Caches DOM element
     */
    cacheDOM() {
        this.valueTextElement = this.element.querySelector(`.${CLASSES.VALUE_TEXT}`);
    }

    /**
     * @method disable
     * @description Add disable CSS class to button
     */
    disable() {
        this.element.classList.add(CLASSES.DISABLED);
    }

    /**
     * @method enable
     * @description Remove disabled CSS class from button
     */
    enable() {
        this.element.classList.remove(CLASSES.DISABLED);
    }

    /**
     * @method onClick
     * @description Calls the callback function passed in through the config
     */
    onClick(event) {
        this.onClickCallback(event);
    }

    /**
     * @method render
     * @description Returns the element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS } from 'Constants';
import {
    renderer,
    generateUniqueID
} from 'utils';

import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { LoadingSpinner } from 'partials/loading-spinner';

import dealerResultsTemplate from './../templates/dealerResultsTemplate';
import dealerHeadingTemplate from './../templates/dealerHeadingTemplate';
import DealerResultsList from './../views/DealerResultsList';

const ATTRIBUTES = {
    DEALER_RESULTS_CONTAINER: 'data-dealer-results-list',
    CHANGE_SEARCH: 'data-change-search-cta',
    DEALER_RESULT_HEADING: 'data-dealer-heading',
    ERROR_MESSAGE: 'data-error-message'
};

/**
 * @constant CLASSES
 * @description Class references for the DealerResults view
 * @type {{ERROR: string}}
 */
const CLASSES = {
    ERROR: 'error',
    LOADING_LIST: 'dealer-results-list__loading-list'
};

/**
 * Class representing the results view for preferred dealer plugin
 */
export default class DealerResults {
    /**
     * @constructor
     * @param model {Object} Observable model of the main plugin appto set data to and observe
     * @param country {String} The country to retrieve locations from
     * @param language {String} Language from config
     * @param onDealerSelectCallback {Function}
     * @param content {Object} Localized content
     */
    constructor({ model, country, language, onDealerSelectCallback, content } = {}) {
        this.model = model;
        this.content = content;
        this.country = country;
        this.language = language;
        this.onDealerSelectCallback = onDealerSelectCallback;

        this.errorMessageId = generateUniqueID();
        this.element = dealerResultsTemplate({
            changeLocationLabel: this.content.changeLocation,
            dealerResultsSubHeading: this.content.dealerResultsSubHeading,
            id: this.errorMessageId
        })({ getNode: true });

        this.dealerResultsListContainer = null;
        this.changeSearchElem = null;

        this.changeSearchHandler = this.changeSearchHandler.bind(this);
        this.createDealerResultsList = this.createDealerResultsList.bind(this);
        this.renderDealerResultsList = this.renderDealerResultsList.bind(this);

        this.cacheDOM();
        this.attachEvents();
        this.searchDealers();
    }

    /**
     * @method destroy
     * @description Detaches events and resets class variables
     */
    destroy() {
        this.detachEvents();
        this.dealerResultsListContainer = null;
        this.changeSearchElem = null;
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.dealerResultsListContainer = this.element.querySelector(
            `[${ATTRIBUTES.DEALER_RESULTS_CONTAINER}]`);
        this.changeSearchElem = this.element.querySelector(`[${ATTRIBUTES.CHANGE_SEARCH}]`);
        this.dealerResultHeading = this.element.querySelector(`[${ATTRIBUTES.DEALER_RESULT_HEADING}]`);
        this.errorMessageElm = this.element.querySelector(`[${ATTRIBUTES.ERROR_MESSAGE}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches click event to the change search label element
     */
    attachEvents() {
        this.changeSearchElem.addEventListener(EVENTS.CLICK, this.changeSearchHandler);
    }

    /**
     * @method detachEvents
     * @description Detaches the associated events
     */
    detachEvents() {
        if (this.changeSearchElem) {
            this.changeSearchElem.removeEventListener(EVENTS.CLICK, this.changeSearchHandler);
        }
    }

    /**
     * @method changeSearchHandler
     * @description Click handler for the change search element. Sets the current step back to 1 on
     * the model
     */
    changeSearchHandler() {
        this.model.setCurrentStep(1);
    }

    /**
     * @method setLoadingSpinner
     * @description Renders a loading spinner in the dealerResultsList container
     */
    setLoadingSpinner() {
        const loader = new LoadingSpinner({ isInline: true });
        const loadingElm = document.createElement('div');
        loadingElm.classList.add(CLASSES.LOADING_LIST);

        loadingElm.appendChild(loader.render());
        renderer.insert(loadingElm, this.dealerResultsListContainer);
    }

    /**
     * @method getSearchFilter
     * @description Gets filter criteria for result filtering
     */
    getSearchFilter() {
        if (this.country === 'mx') {
            return 'sales';
        }

        return 'mbDealer';
    }

    getSearchResultCount() {
        if (this.country === 'mx') {
            return 15;
        }

        return 9;
    }

    /**
     * @method searchDealers
     * @description Gets the list of dealers and create DealerResultsList view
     */
    searchDealers() {
        this.setLoadingSpinner();

        // Radius = all, limit = step for API
        const opts = {
            country: this.country,
            language: this.language,
            searchByType: this.model.state.searchByType,
            searchLocation: this.model.state.searchLocation,
            start: 0,
            count: this.getSearchResultCount(),
            filter: this.getSearchFilter()
        };

        return dealerLocatorApi.getDealersAlt(opts)
            .then((data) => (
                data.results.map((dealer) => ({
                    dealer,
                    onSelectCallback: this.onDealerSelectCallback
                }))
            ))
            .catch((error) => {
                console.log(error);
                this.showServerErrorMessage();
            })
            .then(this.createDealerResultsList)
            .then(this.renderDealerResultsList);
    }

    /**
     * @method createDealerResultsList
     * @description Creates Dealer Results List view
     */
    createDealerResultsList(data) {
        this.renderHeader(data);
        this.dealerResultsList = new DealerResultsList(
            data,
            this.content,
            this.errorMessageId
        );
    }

    /**
     * @method renderDealerResultsList
     * @description Render Dealer Results List view
     */
    renderDealerResultsList() {
        renderer.insert(this.dealerResultsList.render(), this.dealerResultsListContainer);
    }

    /**
     * @method checkDealerDistance
     * @description Checks first item in array is greater than minimums search distance(25KM).
     */
    renderHeader(dealersData) {
        if (dealersData && dealersData.length > 0) {
            const firstDealerDistance = parseFloat((dealersData[0].dealer.distance).split(' '));
            const hasNearbyDealer = firstDealerDistance <= 25;
            const headerElement = {
                hasNearbyDistance: hasNearbyDealer,
                dealerResultHeading: this.content.dealerResultHeading,
                closestDealersHeading: this.content.closestDealersHeading,
                searchLocation: this.model.state.searchString
            };
            renderer.insert(dealerHeadingTemplate(headerElement)({ getNode: true }),
                this.dealerResultHeading);
        }
    }

    /**
    * @method set focus
    * @description gives DOM element focus
    */
    set focus(focus = true) {
        if (focus && this.dealerResultsList) {
            this.dealerResultsList.focus = true;
        }
    }

    /**
     * @method showErrorMessage
     * @description display the error message
     */
    showErrorMessage() {
        this.element.classList.add(`${CLASSES.ERROR}`);
        this.errorMessageElm.innerText = this.content.emptyDealerErrorMessage;
    }

    /**
     * @method showServerErrorMessage
     * @description display the server error message
     */
    showServerErrorMessage() {
        this.element.classList.add(`${CLASSES.ERROR}`);
        this.errorMessageElm.innerHTML =
            `<div>${this.content.serverErrorTitle}</div><div>${this.content.serverErrorDescription}</div>`;
    }

    /**
     * @method showErrorMessageOnDealer
     * @description display the error message on specific dealer result
     */
    showErrorMessageOnDealer(dealerId) {
        this.dealerResultsList.showErrorMessageOnDealer(dealerId);
    }

    /**
     * @method render
     * @description Returns the element associated
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Partial dependencies
import { AuthenticationApi } from 'partials/authentication';

// Local dependencies
import quickRegistrationReminderTemplate from '../templates/quickRegistrationReminderTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    CTA: 'mbs-quick-registration__reminder-cta',
    LOGIN: 'mbs-quick-registration__login-link'
};

/**
 * @const data
 * @description constant for the localization content related to quick registration created
 * on src/main/content/jcr_root/apps/mb-vans/pages/base/partials/footlibs.html
 */
const data = window.mbVans.ns('pageData').registration;

/**
 * @constant defaultConfig
 * @type {Object}
 */
const defaultConfig = {
    benefits: 'Benefits include',
    continue: 'Continue',
    loginHereLC: 'log in here',
    recommendations: 'Recommendations',
    reminderDescription: 'Use the link in your confirmation email, sent to {0}. If you can\'t find it, {1} and a new email will be sent with a secure verification code.',
    reminderHeader: 'Finish setting up your Mercedes me ID.',
    savedBuilds: 'Saved Builds',
    savedInventory: 'Saved Inventory'
};

/**
 * @class QuickRegistrationReminder
 * @description Class to display the half registered state of quick registration in inventory
 */
export default class QuickRegistrationReminder {
    /**
     * @method  constructor
     * @description  set default state for the inheritance
     */
    constructor() {
        this.content = { ...defaultConfig, ...data };
        this.authAPI = new AuthenticationApi();
        this.cookieInfo = this.authAPI.parseQuickRegisterCookies();
        this.element = null;
        this.loginLinkClickHandler = this.loginLinkClickHandler.bind(this);

        this.init();
    }

    /**
     * @method  init
     * @description  init method
     */
    init() {
        this.element = quickRegistrationReminderTemplate(this.content, this.cookieInfo)({ getNode: true });
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method  cacheDOM
     * @description  caches elements from template
     */
    cacheDOM() {
        this.continueCta = this.element.querySelector(`.${CLASSES.CTA}`);
        this.loginLinkElm = this.element.querySelector(`.${CLASSES.LOGIN}`);
    }

    /**
     * @method  attachEvents
     * @description  attaches event to login link
     */
    attachEvents() {
        this.continueCta.addEventListener(EVENTS.CLICK, this.loginLinkClickHandler);
        this.loginLinkElm.addEventListener(EVENTS.CLICK, this.loginLinkClickHandler);
    }

    /**
     * @method  detachEvents
     * @description  detaches event to login link
     */
    detachEvents() {
        this.continueCta.removeEventListener(EVENTS.CLICK, this.loginLinkClickHandler);
        this.loginLinkElm.removeEventListener(EVENTS.CLICK, this.loginLinkClickHandler);
    }

    /**
     * @method authLinkClickHandler
     * @description Callback to apply when the login link is clicked to
     * prevent the default and apply the this.authAPI.authRedirect method to
     * log the user out
     * @param e {Event} Click event
     */
    loginLinkClickHandler(e) {
        e.preventDefault();
        this.authAPI.authRedirect(AuthenticationApi.AUTH_PAGES.LOGIN);
    }

    /**
     * @method destroy
     * @description Destroys the view by detaching its events and removing its element from the DOM
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
        this.element = null;
    }

    /**
     * @method getTitle
     * @description returns the header to use in the modal for accessibility
     */
    getTitle() {
        return this.content.reminderHeader;
    }

    /**
     * @methdo render
     * @description  return the HTML Node with the element
     * @return {Node}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @description Constants for the payment estimator forms
 * @type {{
    *  FINANCE: string,
    *  LEASE: string,
    *  COOKIE: string,
    *  CURRENT_STATE_FIELD_NAME: string,
    *  IMAGE_HOST_BBD: string,
    *  IMAGE_HOST_BBD_TEST: RegExp,
    *  IMAGE_HOST_MS: string
    * }}
    */

export default {
    FINANCE: 'finance',
    LEASE: 'lease',
    RETAIL: 'retail',
    COOKIE: 'MBVANS_PAYMENT_ESTIMATOR',
    CURRENT_STATE_FIELD_NAME: '_currentState',
    IMAGE_HOST_BBD: 'bbd',
    IMAGE_HOST_BBD_TEST: /iris\/iris.png\?/,
    IMAGE_HOST_MS: 'ms',
    COUNTRY: {
        US: 'us'
    },
    INPUT_SOURCE: {
        SPECIAL_OFFER: 'specialOffer',
        CUSTOM: 'custom',
        DEFAULT: undefined,
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Multi Media Asset Module
 * This module shows the carousel controls if there is more than one asset
 * and initializes the MultiMediaCarousel module
 */

// Partial dependencies
import { carouselParser } from 'partials/carousel';

class MultiMediaAsset {
    /**
     * Instantiates the MultiMediaAsset class
     * @param {Object} element
     */
    constructor(element) {
        this.element = element;
        this.carousels = null;
        this.init.bind(this)();
    }

    /**
     * init()
     */
    init() {
        this.carousels = carouselParser.createCarousel(this.element);
    }
}

export default MultiMediaAsset;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

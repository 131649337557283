import { screen, Touch } from 'utils';
import { EVENTS } from 'Constants';

import tabListTemplate from './../templates/tabListTemplate';

/**
 * @const ATTRIBUTES
 * @description Attribute references for the TabList Module
 * @type {{ARIA_SELECTED: string}}
 */
const ATTRIBUTES = {
    ARIA_SELECTED: 'aria-selected'
};

/**
 * @const CLASSES
 * @description Collection of constant values for CSS classes
 */
const CLASSES = {
    TAB_LIST_CONTAINER: 'tab-list-container',
    TAB_LIST: 'tab-list',
    TAB_LIST_ITEM: 'tab-list__item',
    TAB_LIST_ITEM_ACTIVE: 'tab-list__item--active'
};

/**
 * @const defaultConfig
 * @description Default configuration options
 */
const defaultConfig = {
    swipeMove: 0.8, // % of moving when swiping
    isStaticLinks: false
};

/**
 * @class TabList
 * @description TabList class
 */
export default class TabList {
    /**
     * @constructor
     * @description Constructs TabList component
     * @param {object[]} tabLabels - Array of tab label objects
     * @param {string} tabLabels[].panelID - ID of the panel associated for the tab
     * @param {string} tabLabels[].name - Label of the tab
     * @param {object} config - Configuration object
     * @param {number} config.swipeMove - %s of moving when swiping
     * @param {function} config.onChangePanel - Callback which gets executed tab is clicked
     * @param {string} config.tabListLabel - Label for the container
     * @param {boolean} config.isStaticLinks - Checks if tabs are static links
     */
    constructor(tabLabels, config) {
        this.tabLabels = tabLabels;
        this.element = tabListTemplate(tabLabels, config.tabListLabel, config.isStaticLinks)({ getNode: true });
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.init();
    }

    /**
     * @method init
     * @description Initialization method
     */
    init() {
        this.setBindings();
        this.cacheDOM();
        this.attachEvents();
        this.move = 0;
        this.state = {
            selectedTabIndex: null,
            currentScreenState: screen.getCurrentState()
        };
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.onTabFocus = this.onTabFocus.bind(this);
        this.onSwipeRight = this.onSwipeRight.bind(this);
        this.onSwipeLeft = this.onSwipeLeft.bind(this);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements for this module
     */
    cacheDOM() {
        this.tabsElm = this.element.querySelector(`.${CLASSES.TAB_LIST}`);
        this.tabButtonElms = [].slice.call(this.element.querySelectorAll(`.${CLASSES.TAB_LIST_ITEM}`));
        this.swipeContainer = new Touch(this.element);
    }

    /**
     * @method attachEvents
     * @description Attaches event listener for tabs buttons and swipe moves
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
        this.tabButtonElms.forEach((tabButton) => {
            tabButton.addEventListener(EVENTS.CLICK, this.onTabClick);
            tabButton.addEventListener(EVENTS.FOCUS, this.onTabFocus);
        });
        this.swipeContainer.on(Touch.EVENTS.SWIPE_RIGHT, this.onSwipeRight);
        this.swipeContainer.on(Touch.EVENTS.SWIPE_LEFT, this.onSwipeLeft);
    }

    /**
     * @method detachEvents
     * @description detach events
     */
    detachEvents() {
        screen.addResizeListener(this.onScreenResize);
        this.tabButtonElms.forEach((tabButton) => {
            tabButton.removeEventListener(EVENTS.CLICK, this.onTabClick);
            tabButton.removeEventListener(EVENTS.FOCUS, this.onTabFocus);
        });
        this.swipeContainer.off(Touch.EVENTS.SWIPE_RIGHT, this.onSwipeRight);
        this.swipeContainer.off(Touch.EVENTS.SWIPE_LEFT, this.onSwipeLeft);
    }

    /**
     * @method onTabClick
     * @description when clicking on a tab
     */
    onTabClick(e) {
        const clickedTabIndex = this.getTabIndex(e.target);
        this.deactivate();
        this.activate(clickedTabIndex);
        this.config.onChangePanel(clickedTabIndex);
        if (this.config.onTabClickUrl) {
            this.config.onTabClickUrl(this.tabLabels[clickedTabIndex].name);
        }
    }

    /**
     * @method onTabFocus
     * @description when a tab gets focus check if it is in view
     */
    onTabFocus(e) {
        if (this.canSlide()) {
            const clickedTabIndex = this.getTabIndex(e.target);
            this.updatePosition(clickedTabIndex);
        }
    }

    /**
     * @method onSwipeRight
     * @description handler method for swipe right
     */
    onSwipeRight() {
        if (this.canSlide()) {
            this.move -= this.config.swipeMove * this.element.offsetWidth;
            this.moveTabs();
        }
    }

    /**
     * @method onSwipeLeft
     * @description handler method for swipe left
     */
    onSwipeLeft() {
        if (this.canSlide()) {
            this.move += this.config.swipeMove * this.element.offsetWidth;
            this.moveTabs();
        }
    }

    /**
     * @method getTabIndex
     * @description get index of given target button
     * @return clickedTabIndex {int} index of selected button
     */
    getTabIndex(targetElm) {
        const clickedTabIndex = this.tabButtonElms.findIndex((button) => targetElm === button);
        return clickedTabIndex !== -1 ? clickedTabIndex : null;
    }

    /**
     * @method activate
     * @description activate tab according with index
     * @param tabIndex {int} index of tab to be activated
     */
    activate(tabIndex) {
        if (this.tabButtonElms[tabIndex]) {
            // add active class to clicked tab
            this.tabButtonElms[tabIndex].classList.add(CLASSES.TAB_LIST_ITEM_ACTIVE);
            this.tabButtonElms[tabIndex].setAttribute(ATTRIBUTES.ARIA_SELECTED, 'true');
            this.updateState({
                selectedTabIndex: tabIndex
            });
            if (this.canSlide()) {
                this.updatePosition();
            }
        }
    }

    /**
     * @method deactivateTab
     * @description deactivate tab according with index
     */
    deactivate() {
        // remove active class on previous selected tab
        const { selectedTabIndex } = this.getState();
        if (selectedTabIndex !== null) {
            this.tabButtonElms[selectedTabIndex].classList
                .remove(CLASSES.TAB_LIST_ITEM_ACTIVE);
            this.tabButtonElms[selectedTabIndex].setAttribute(ATTRIBUTES.ARIA_SELECTED, 'false');
            this.reset();
        }
    }

    /**
     * @method updatePosition
     * @description calculate positioning of tabs for small and large
     */
    updatePosition(index) {
        const tabIndex = index || this.getState().selectedTabIndex;
        const selectedItem = this.tabButtonElms[tabIndex];

        const buttonWidth = selectedItem.offsetWidth / 2;
        const containerWidth = this.element.offsetWidth / 2;
        this.move = -selectedItem.offsetLeft - buttonWidth + containerWidth;

        if (tabIndex === 0) {
            this.move = 0;
        }
        this.moveTabs();
    }

    /**
     * @method move
     * @description move tabs list when swiping on mobile
     */
    moveTabs() {
        const maxRight = 0 - (this.tabsElm.scrollWidth - this.element.offsetWidth);
        if (this.move > 0) {
            this.move = 0;
        }
        if (this.move < maxRight) {
            this.move = maxRight;
        }
        this.setTransform();
    }

    /**
     * @method setTransforme
     * @description set transform style based on this.move
     */
    setTransform() {
        const newTrans = `translate3d(${this.move}px, 0, 0)`;
        this.tabsElm.style.transform = newTrans;
    }

    /**
     * @method reset
     * @description reset tabs to initial state
     */
    reset() {
        this.move = 0;
        this.setTransform();
    }

    /**
     * @method canSlide
     * @description check if tabs is bigger than the container
     */
    canSlide() {
        return this.tabsElm.scrollWidth > this.element.offsetWidth;
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        const newScreenState = screen.getCurrentState();
        const { currentScreenState, selectedTabIndex } = this.getState();
        if (currentScreenState !== newScreenState) {
            this.updateState({ currentScreenState: newScreenState });
            this.reset();
        }
        if (this.canSlide() && selectedTabIndex !== null) {
            this.updatePosition();
        }
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {object} new state value
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return this.state;
    }

    /**
     * @method render
     * @description Returns the element of the module
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Image To Canvas Module
 * This partial module creates a canvas element and draws in it an image
 * based on a <img> with class "image-to-canvas-rollover" and also applies
 * a globalAlpha value of the shape of a <img> of class "image-to-canvas-original",
 * which can be used for hover effects. The module accepts a callback which will
 * take care of attaching the needed events.
 */

/**
 * @const ATTRIBUTES
 * @description Collection of data attributes for querying
 * @type {{IMAGE_ORIGINAL: string, IMAGE_ROLLOVER: string}}
 */
const ATTRIBUTES = {
    IMAGE_ORIGINAL: 'data-image-to-canvas-original',
    IMAGE_ROLLOVER: 'data-image-to-canvas-rollover'
};

/**
 * @const defaultConfig
 * @description Default configuration options
 */
const defaultConfig = {
    canvasClass: '',
    width: 515,
    height: 480,
    globalAlpha: {
        almostTransparent: 0.005,
        opaque: 1
    }
};

const defaultCallback = () => {};

export default class ImageToCanvas {
    constructor(element, config = defaultConfig, callback = defaultCallback) {
        this.element = element;
        this.callback = callback;
        this.originalImage = null;
        this.rolloverImage = null;
        this.canvas = null;
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.init();
    }

    /**
     * Cache DOM
     */
    cacheDOM() {
        this.originalImage = this.element.querySelector(`[${ATTRIBUTES.IMAGE_ORIGINAL}]`);
        this.rolloverImage = this.element.querySelector(`[${ATTRIBUTES.IMAGE_ROLLOVER}]`);
    }

    /**
     * Init method
     */
    init() {
        this.cacheDOM();

        if (this.originalImage && this.rolloverImage) {
            this.createCanvas();
        }
    }

    /**
     * Creates a canvas element with the specified configuration
     * and adds it to the current element node
     */
    createCanvas() {
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.config.width;
        this.canvas.height = this.config.height;
        this.canvas.className = this.config.canvasClass;
        this.canvas.setAttribute('aria-hidden', 'true');
        this.element.appendChild(this.canvas);

        setTimeout(() => { this.drawImage(); }, 100);
    }

    /**
     * Draws the rollover image into the canvas and adds a non transparent
     * globalAlpha for the shape of the original image
     */
    drawImage() {
        const context = this.canvas.getContext('2d');
        context.globalAlpha = this.config.globalAlpha.almostTransparent;
        context.drawImage(this.originalImage, 0, 0, this.canvas.width, this.canvas.height);
        context.globalAlpha = this.config.globalAlpha.opaque;
        context.drawImage(this.rolloverImage, 0, 0, this.canvas.width, this.canvas.height);

        this.removeRolloverImg();
        this.callback(this.canvas);
    }

    /**
     * Removes the rollover image from the DOM since it has been replaced with the canvas
     */
    removeRolloverImg() {
        this.rolloverImage.parentNode.removeChild(this.rolloverImage);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { dimensions } from 'utils';

// Constant dependencies
import { GLOBAL_HEADER } from 'Constants';

/**
 * @const FOCUS_OFFSET
 * @description Offset value for clearing global header on focus
 */
const FOCUS_OFFSET = 100;

/**
 * @method clearGlobalHeader
 * @description Checks if select control is potentially occluded by peek-a-boo global header
 * and scrolls it into view if necessary.
 * NOTE: If select control is out of the viewport, the browser will scroll it into view.
 */
export default function clearGlobalHeader(element) {
    const { top } = element.getBoundingClientRect();
    if (top >= 0 && top <= GLOBAL_HEADER.HEIGHT_COLLAPSED.XLARGE) {
        scrollTo(
            0,
            dimensions.getOffset(element).top - GLOBAL_HEADER.HEIGHT_COLLAPSED.XLARGE - FOCUS_OFFSET
        );
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

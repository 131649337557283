import { serializeObject as serialize, tokenReplace } from 'utils';

import {
    VEHICLE_CLASSES_ENDPOINT,
    VEHICLE_MODELS_ENDPOINT,
    VEHICLE_MODEL_ENDPOINT,
    VEHICLE_BY_YEAR,
    VEHICLE_BODYSTYLES_ENDPOINT,
    VEHICLE_CONFIG_ENDPOINT,
} from './../config/endpointDetails';

import vehicleSelectorContent from './../config/content';

// cache the models by year vehicles data
let modelsByYearCache = null;

let vehicleDataCache = null;


/**
 * @function cacheModelsByYear
 * @description Caches data response from endpoint
 * @params {Object} response - object containing models by year
 * @returns {Object} response from models endpoint
 */
function cacheModelsByYear(response) {
    modelsByYearCache = response;
    return response;
}

/**
 * @method getClasses will fetch the data from the endpoint specified.
 * @param country {String} the country code from config
 * @param lang {String} the language code from config
 * @param brand {String} the brand code from config
 * @returns the vehicle classes data.
 */
function getClasses(country, lang, brand) {
    const endpoint = `${VEHICLE_CLASSES_ENDPOINT}${serialize({ country, lang, brand })}`;

    return fetch(endpoint, { credentials: 'same-origin' })
        .then((response) => response.json())
        .then((data) => (Object.values(data.result.vehicleClasses)))
        .then((data) => (data.map(({ code, name }) => ({ label: name, value: code }))));
}

/**
 * @method parseAcsLists creates maps from raw acs list data
 * @param data {object} data from service
 * @returns acs lists as a map
 */
function parseAcsLists(data) {
    const acsLists = {};
    const rawData = data.result.modelConfiguration;
    const keys = Object.keys(rawData);
    keys.forEach((k) => {
        const items = rawData[k].items;
        acsLists[k] = {};
        items.forEach(({ title, value }) => {
            acsLists[k][value] = title;
        });
    });
    return acsLists;
}

/**
 * @method getModelDisplayName generates a display name for models
 * @param {object} modelData model object
 * @param {object} acsLists acs lists dictionary
 * @param {boolean} includeYear if true include the year in the display name
 * @returns the display name for the model
 */
function getModelDisplayName(modelData, acsLists, includeYear = false) {
    const model = modelData.model;
    const year = modelData.year;
    const wheelbase = acsLists.wheelBase[model.wheelbase];
    const isMetris = model.vehicleClass.code === 'METRIS';
    const isCabChassis = model.vehicleBodystyle.code === 'CC';
    const tonnage = (isMetris || model.gvwr.toLowerCase() === 'none') ? '' : acsLists.tonnage[model.gvwr];
    const engine = acsLists.cylinder[model.cylinder];
    const roof = isCabChassis ? '' : acsLists.variant[model.defaultVariant];

    let pattern;

    if (isCabChassis) {
        pattern = includeYear ? vehicleSelectorContent.displayNameCabChassisWithYear :
        vehicleSelectorContent.displayNameCabChassis;
    } else if (isMetris) {
        pattern = includeYear ? vehicleSelectorContent.displayNameMetrisWithYear :
        vehicleSelectorContent.displayNameMetris;
    } else {
        pattern = includeYear ? vehicleSelectorContent.displayNameWithYear :
        vehicleSelectorContent.displayName;
    }


    const fullName = tokenReplace(pattern, {
        year, tonnage, wheelbase, roof, engine
    });

    return fullName[0].toUpperCase() + fullName.slice(1);
}

/**
 * @method getVehicleName generates a word-order specific name
 * @param {Object} model - the vehicle model
 * @param {String} lang - the site language
 * @param {boolean} includeYear - include the year in the name
 * @returns {String}
 */
function getVehicleName(model, lang = '', includeYear = false) {
    const isCrewVan = model.model.vehicleBodystyle.code === 'CV';
    const names = [
        model.model.vehicleClass.name,
        model.model.vehicleBodystyle.name,
    ];

    if (lang === 'fr') {
        if (!isCrewVan) {
            names.reverse();
        }
        return names.concat([
            includeYear ? model.displayNameWithYear : model.displayName
        ]).join(' ');
    } else {
        const name = names.concat([model.displayName]).join(' ');
        if (includeYear) {
            return `${model.year} ${name}`;
        }
        return name;
    }
}

/**
 * @method loadVehicleData loads all model data from api with cache
 * @param country {String} the country code from config
 * @param lang {String} the language code from config
 * @param brand {String} the brand code from config
 * @returns the models data for all models as a promise.
 */
function loadVehicleData(country, lang, brand) {
    if (vehicleDataCache) {
        return Promise.resolve(vehicleDataCache);
    }
    const modelsEndpoint = `${VEHICLE_MODELS_ENDPOINT}${serialize({ country, lang, brand })}`;
    const acsEndpoint = `${VEHICLE_CONFIG_ENDPOINT}${serialize({ country, lang, brand })}`;

    const vehicleDataReq = fetch(modelsEndpoint, { credentials: 'same-origin' })
    .then((response) => response.json());

    const acsListReq = fetch(acsEndpoint, { credentials: 'same-origin' })
    .then((response) => response.json()).then(parseAcsLists);

    return Promise.all([vehicleDataReq, acsListReq])
    .then(([data, acsLists]) => {
        const activeModels = Object.values(data.result.activeModels);
        const models = [];
        activeModels.forEach((model) => {
            models.push(model);
            // if there are any supportedVariants, create model entries for them.
            (model.model.supportedVariants || []).forEach((modelVariant) => {
                const modelClone = JSON.parse(JSON.stringify(model));
                // set this variant as the default
                modelClone.model.defaultVariant = modelVariant.variant;
                // override other properties as needed
                modelClone.model.name = modelVariant.variantTitle;
                modelClone.model.modelTileImage = modelVariant.variantModelTileImage;
                modelClone.model.msrp = +modelVariant.variantPrice;
                modelClone.modelName = modelClone.model.name;
                modelClone.msrp = modelClone.model.msrp;
                models.push(modelClone);
            });
        });
        models.forEach((model) => {
            model.displayName = getModelDisplayName(model, acsLists);
            model.displayNameWithYear = getModelDisplayName(model, acsLists, true);
            model.bodystyleId = `${model.model.vehicleClass.code}:${model.model.vehicleBodystyle.code}`;
            model.vehicleName = getVehicleName(model, lang);
            model.vehicleNameWithYear = getVehicleName(model, lang, true);
            model.powertrain = acsLists.cylinder[model.model.cylinder] || '';
            model.defaultVariantName = acsLists.variant[model.model.defaultVariant] || '';
            model.payload = acsLists.tonnage[model.model.gvwr] || '';
        });
        vehicleDataCache = models;
        return models;
    });
}

/**
 * @method getModels will fetch the data from the endpoint specified.
 * @param country {String} the country code from config
 * @param lang {String} the language code from config
 * @param brand {String} the brand code from config
 * @param bodystyle {String} bodystyle to get models from
 * @returns the models data for the specific class.
 */
function getModels(country, lang, brand, bodystyle) {
    return loadVehicleData(country, lang, brand)
    .then((models) => models.filter((model) => model.bodystyleId === bodystyle));
}

/**
 * @method getBodystyles will fetch the data from the endpoint specified.
 * @param country {String} the country code from config
 * @param lang {String} the language code from config
 * @param brand {String} the brand code from config
 * @returns the models data for the specific class.
 */
function getBodystyles(country, lang, brand) {
    const endpoint = `${VEHICLE_BODYSTYLES_ENDPOINT}${serialize({ country, lang, brand })}`;

    return fetch(endpoint, { credentials: 'same-origin' })
        .then((response) => response.json())
        .then((data) => (Object.values(data.result.vehicleBodyStyleClasses)))
        .then((data) => (data
            .filter(({ models }) => models.length)
            .map(
            ({ vehicleClass, vehicleBodystyle, name }) =>
            ({ label: name, value: `${vehicleClass.code}:${vehicleBodystyle.code}` })
            ))
        );
}

/**
 * @method getModel will fetch the data from the endpoint specified.
 * @param country {String} the country code from config
 * @param lang {String} the language code from config
 * @param brand {String} the brand code from config
 * @param modelCode {String} modelCode to look for
 * @param variant {String} variant value (STANDARDROOF or HIGHROOF)
 * @returns the model data for the specific modelCode.
 */
function getModel(country, lang, brand, modelCode, variant) {
    const endpoint = `${VEHICLE_MODEL_ENDPOINT}${serialize({ country, lang, brand, modelCode })}`;
    const acsEndpoint = `${VEHICLE_CONFIG_ENDPOINT}${serialize({ country, lang, brand })}`;

    const vehicleDataReq = fetch(endpoint, { credentials: 'same-origin' })
        .then((response) => response.json());

    const acsListReq = fetch(acsEndpoint, { credentials: 'same-origin' })
        .then((response) => response.json()).then(parseAcsLists);

    return Promise.all([vehicleDataReq, acsListReq])
        .then(([data, acsLists]) => {
            const model = data.result.activeModel;
            model.displayName = getModelDisplayName(model, acsLists);
            model.displayNameWithYear = getModelDisplayName(model, acsLists, true);
            model.vehicleName = getVehicleName(model, lang);
            model.vehicleNameWithYear = getVehicleName(model, lang, true);

            if (variant) {
                const modelVariant = model.model.supportedVariants.find((v) => v.variant === variant);
                // if there is a supportedVariant override properties as needed
                if (modelVariant) {
                    const modelClone = JSON.parse(JSON.stringify(model));
                    // set this variant as the default
                    modelClone.model.defaultVariant = modelVariant.variant;
                    // override other properties as needed
                    modelClone.model.name = modelVariant.variantTitle;
                    modelClone.model.modelTileImage = modelVariant.variantModelTileImage;
                    modelClone.model.msrp = +modelVariant.variantPrice;
                    modelClone.displayName = getModelDisplayName(modelClone, acsLists);
                    modelClone.displayNameWithYear = getModelDisplayName(modelClone, acsLists, true);
                    modelClone.vehicleName = getVehicleName(modelClone, lang);
                    modelClone.vehicleNameWithYear = getVehicleName(modelClone, lang, true);
                    return modelClone;
                }
            }

            return model;
        });
}

/**
 * @method getModelsByYear will fetch the data from the endpoint specified.
 * @returns the vehicle models by year
 */
function getModelsByYear() {
    const endpoint = `${VEHICLE_BY_YEAR}/new/models`;

    if (modelsByYearCache) {
        return Promise.resolve(modelsByYearCache)
            .then((data) => data);
    }

    return fetch(endpoint,
        {
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'origin'
            },
            method: 'GET'
        })
        .then((response) => response.json())
        .then((data) => cacheModelsByYear(data.result));
}

export default {
    getClasses,
    getModels,
    getModel,
    getModelsByYear,
    getBodystyles,
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

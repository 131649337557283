// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { noop } from 'utils';

// Local dependencies
import template from './../templates/toggleButtonsTemplate';

/**
 * @const ATTRIBUTES
 * @description Attribute references for the ToggleButtons view
 * @type {{BUTTON_ID: string}}
 */
const ATTRIBUTES = {
    BUTTON_ID: 'data-button-id'
};

/**
 * @const CLASSES
 * @description Class references for the ToggleButtons view
 * @type {{ACTIVE_BUTTON: string}}
 */
const CLASSES = {
    ACTIVE_BUTTON: 'toggle-buttons__button--active'
};

/**
 * View responsible for rendering toggle buttons managing the active state
 * and applying a callback on click
 */
export default class ToggleButtons {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     * @param buttons {Array} Array of objects with the following props
     * { label, id, isActive, and dataAnalyticsTrigger }
     * @param onClick {Function} Method to apply when a button is selected
     */
    constructor({ buttons = [], onClick = noop }) {
        this.element = template(buttons)({ getNode: true }); // DOM element to be rendered
        this.buttons = null; // Collection of buttons
        this.onClickCallback = onClick; // Callback to call on button clicked
        // method aliases
        this.clickButton = this.clickButton.bind(this);

        this.cacheDOM();
        this.attachEvents();
        this.setActiveButton(this.getActiveButton());
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the view
     */
    cacheDOM() {
        this.buttons = Array.prototype.slice.call(
            this.element.querySelectorAll(`[${ATTRIBUTES.BUTTON_ID}]`)
        );
    }

    /**
     * @method attachEvents
     * @description Iterates the button elements and applies a callback for
     * each one when clicked
     */
    attachEvents() {
        this.buttons.forEach((button) => {
            button.addEventListener(EVENTS.CHANGE, this.clickButton);
            button.addEventListener(EVENTS.CLICK, this.clickButton);
        });
    }

    /**
     * @method removeEvents
     * @description Iterates the buttons and removes the click handler
     */
    removeEvents() {
        this.buttons.forEach((button) => {
            button.removeEventListener(EVENTS.CHANGE, this.clickButton);
            button.removeEventListener(EVENTS.ClICK, this.clickButton);
        });
    }

    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.removeEvents();
        this.element.remove();
    }

    /**
     * @method getActiveButton
     * @description Retrieves the active button based on the
     * isActive property on the button
     */
    getActiveButton() {
        return this.buttons.find((button) => button.dataset.isActive === 'true');
    }

    /**
     * @method setActiveButton
     * @description Sets the activeButton and the state of the DOM element
     * @param activeButton
     */
    setActiveButton(activeButton) {
        this.buttons.forEach((button) => {
            const { buttonId } = activeButton.dataset;

            if (button.dataset.buttonId === buttonId) {
                button.classList.add(CLASSES.ACTIVE_BUTTON);
                button.dataset.isActive = true;
            } else {
                button.classList.remove(CLASSES.ACTIVE_BUTTON);
                button.dataset.isActive = false;
            }
        });
    }

    /**
     * @method clickButton
     * @description Callback handler for when a button is clicked to
     * retrieve the element from the event and call the setActiveButton
     * and onClickCallback with the current activeButton value
     * @param event
     */
    clickButton(event) {
        const { target } = event;
        const { buttonId } = target.dataset;
        const activeButton = this.getActiveButton();

        if (buttonId !== activeButton.dataset.buttonId) {
            this.setActiveButton(target);
            this.onClickCallback(buttonId);
        }
    }

    /**
     * @method render
     * @description Renders the ToggleButtons element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

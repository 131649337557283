import { EVENTS } from 'Constants';

// Local dependencies
import dealerChangeLocationTemplate from './../templates/dealerChangeLocationTemplate';

/**
 * CLASSES{}
 * Stores class names for use in the DOM
 */
const CLASSES = {
    CHANGE_CTA: 'dealer-change__cta'
};

export default class DealerChangeLocation {
     /**
     * @constructor
     * @description Creates new DealerChangeLocation
     * @param subtext {String} Updated search phrase with searchTerm included
     * @param changeLocationCTA {String} Copy to be put into cta
     */
    constructor(subtext, changeLocationCTA, changeLocationCallback) {
        this.element = dealerChangeLocationTemplate(subtext,
                                changeLocationCTA)({ getNode: true });
        this.changeLocationCallback = changeLocationCallback;
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM Elements
     */
    cacheDOM() {
        this.changeLink = this.element.querySelector(`.${CLASSES.CHANGE_CTA}`);
    }

    /**
     * @method destroy
     * @description Destroys the instance. This should also remove any event listeners
     */
    destroy() {
        this.element.remove();
    }

    /**
     * @method attachEvents
     * @description Attaches an event listener on addres heading for dealer on click
     */
    attachEvents() {
        if (this.changeLocationCallback) {
            this.changeLink.addEventListener(EVENTS.CLICK, () => {
                this.changeLocationCallback();
            });
        }
    }

    /**
     * @method focusChangeLocationCTA
     * @description Sets focus to dealer change link cta
     */
    focusChangeLocationCTA() {
        this.changeLink.focus();
    }

    /**
     * @method render
     * @description Renders the dealer result module
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

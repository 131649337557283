// Local dependencies
import SelectOptions from './SelectOptions';
import selectControlTemplate from './../templates/selectControlTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @memberof SelectControl
 * @type {{
 *      SELECT_TOGGLE: string,
 *      SELECT_TOGGLE_LABEL: string,
 *      SELECT_OPTION_LIST: string,
 *      SELECT_OPTION: string,
 *      OPEN: string,
 *      SELECT_HAS_VALUE: string,
 *      ERROR: string,
 *      ERROR_ELEMENT: string,
 *      SELECTED: string,
 *      LABEL_ELEMENT: string,
 *      LABEL_ELEMENT_LABELED: string,
 *      TOOL_TIP: string
 * }}
 */
const CLASSES = {
    SELECT_TOGGLE: 'select-control__combobox',
    SELECT_TOGGLE_LABEL: 'select-control__value',
    SELECT_OPTION_LIST: 'select-control__list',
    SELECT_OPTION: 'select-control__option',
    OPEN: 'select-control--open',
    SELECT_HAS_VALUE: 'select-control--has-value',
    ERROR: 'select-control--error',
    ERROR_ELEMENT: 'select-control__error-message',
    SELECTED: 'select-control__option--selected',
    LABEL_ELEMENT: 'select-control__input-label',
    LABEL_ELEMENT_LABELED: 'select-control__input-label--labeled',
    TOOL_TIP: 'tool-tip'
};

/**
 * @const defaultConfig
 * @description Default configuration options for a select control
 * @memberof SelectControl
 * @type {Object}
 * @const {string} defaultConfig.prefix='' Prepended copy that is displayed before selected content
 * @const {string} defaultConfig.theme='' Theme to apply to the select control styling
 */
const defaultConfig = {
    prefix: '',
    theme: ''
};

/**
 * @class SelectControl
 * @description View component for displaying a SelectControl
 */
export default class SelectControl extends SelectOptions {
    /**
     * @static
     * @description Optional theme configuration types
     * @memberof SelectControl
     * @type {{DEFAULT: string, DROPDOWN: string, INLINE: string}}
     */
    static THEMES = {
        DEFAULT: 'default',
        DROPDOWN: 'dropdown',
        INLINE: 'inline',
    };

    /**
     * @constructor
     * @description Create a SelectControl
     * @memberof SelectControl
     * @param {Array} items An array of items objects. Each object is represented as follows:
     * {
     *   label: 'All',
     *   value: 'all'
     * }
     * @param {Object} config Configuration data
     */
    constructor(items, config) {
        super(items, { ...config, defaultConfig, CLASSES });
    }

    /**
     * @method createView
     * @description Create view
     * @memberof SelectControl
     */
    createView() {
        this.element = selectControlTemplate({
            analyticsTrigger: this.config.analyticsTrigger,
            ariaLabel: this.config.ariaLabel,
            cssClass: this.config.cssClass,
            defaultSelection: this.config.defaultSelection,
            disabled: this.config.disabled,
            errorMessage: this.config.errorMessage,
            icon: this.config.icon,
            id: this.id,
            items: this.items,
            labelText: this.config.labelText,
            moreInfoText: this.config.moreInfoText,
            name: this.config.name,
            prefix: this.config.prefix,
            required: this.config.required,
            theme: this.config.theme,
            isCustomLabel: this.config.isCustomLabel,
            ariaLabelText: this.config.ariaLabelText
        })({ getNode: true });
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

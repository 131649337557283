import { htmlNode, stringReplace } from 'utils';

/**
 * @function
 * @name renderHeading
 * @description render heading text containing dealer name
 * @param {String} viewAtDealerLabel
 * @param {String} dealerName
 * @return {String} heading string
 */
const renderHeading = (viewAtDealerLabel, dealerName) =>
    stringReplace(viewAtDealerLabel, /\{dealerName\}/ig, [dealerName]);

/**
 * Template for a DealerSelectorActiveDealer view.
 * Uses ES6 template literal to composite the markup for a DealerSelectorActiveDealer module
 */
export default ({ dealer = {}, isPreferred = false, content = {} }) => htmlNode`
    <div class="dealer-selector-active-dealer" data-analytics-container="{ dealer-card: '${dealer.name}', preferred: ${isPreferred}}">
        <h1 class="dealer-selector__title">${renderHeading(content.viewAtDealer, dealer.name)}</h1>
        <address class="dealer-selector-active-dealer__address">
            ${dealer.address ? `<div class="dealer-selector-active-dealer__address-street">${dealer.address}</div>` : ''}
            <div class="dealer-selector-active-dealer__address-main">${dealer.city}, ${dealer.state}, ${dealer.zip}</div>
            <div>
                ${dealer.phoneFormatted ? `<a href="tel:${dealer.phone}" class="dealer-selector-active-dealer__phone-number">${dealer.phoneFormatted}</a>` : ''}
            </div>
        </address>
        <button class="dealer-selector-active-dealer__button button button_primary button--wide" data-analytics-trigger="cta-inventory" data-continue-cta>${content.continueLabel}</button>
        <div class="dealer-selector-active-dealer__cta">
            <button class="link link_secondary" data-analytics-trigger="cta" data-change-location-cta>${content.changeLocation}</button>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

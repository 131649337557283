/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import {
    htmlNode,
    generateUniqueID
} from 'utils';

export default ({ changeLocationLabel, dealerResultsSubHeading, id = generateUniqueID() }) => htmlNode`
    <div class="dealer-results">
        ${dealerResultsSubHeading ? `<h3 class="leads-form__sub-heading">${dealerResultsSubHeading}</h3>` : ''}
        <div data-dealer-heading></div>
        <p class="dealer-results__sub-heading-copy dealer-results__sub-heading-copy">
            <button class="link link_plain-link" data-change-search-cta role="presentation"
               data-analytics-trigger="change-zip">
                ${changeLocationLabel}
            </button>
        </p>
        <div data-dealer-results-list></div>
        <span id="error${id}" class="dealer-results__error-message" role="alert" aria-live="polite" data-error-message></span>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * @function renderTabLabel
 * @description Renders each tab label
 * @returns {string} The DOM string for each tab
 */
function renderTabLabel(isStaticLinks, tabLabel) {
    if (isStaticLinks) {
        return `
            <li class="tab-list__item-container" role="none" data-analytics-trigger=${`page-nav-${encodeURIComponent(tabLabel.name)}`}>
                <a class="tab-list__item" href="${tabLabel.url}">${tabLabel.name}</a>
            </li>
        `;
    } else {
        return `
            <li class="tab-list__item-container" role="none" data-analytics-trigger=${`page-nav-${encodeURIComponent(tabLabel.name)}`}>
                <button class="tab-list__item" role="tab" aria-controls="tab-panel${tabLabel.id}">${tabLabel.name}</button>
            </li>
        `;
    }
}

/**
 * @description Tab List Template
 * @param {object[]} tabLabels - Array of tab label objects
 * @param {string} tabLabels[].id - ID of the panel associated for the tab
 * @param {string} tabLabels[].name - Label of the tab
 * @param {string} tabListLabel - Label for the container
 * @param {boolean} isStaticLinks - Checks if tabs are anchor tags
 */
export default (tabLabels, tabListLabel, isStaticLinks) => htmlNode`
    <div class="tab-list-container" data-analytics-container="{'blade': 'tab-list'}">
        <ul role="tablist" class="tab-list" aria-label="${tabListLabel}">
            ${tabLabels.map(renderTabLabel.bind(null, isStaticLinks)).join('')}
        </ul>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

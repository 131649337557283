export default {
    CONTACT_DEALER: {
        default: 'SP_CONTACT_DEALER',
        byo: 'SP_BYO_CONTACT_DEALER',
        inventory: 'SP_INV_NEW_CONTACT_DEALER',
        recall: 'SP_RECALL_CONTACT_DEALER',
        so: 'SP_SO_CONTACT_DEALER',
        tco: 'SP_TCO_CONTACT_DEALER',
        cpo: 'SP_INV_CPO_CONTACT_DEALER',
        po: 'SP_INV_PRE_CONTACT_DEALER',
        event: 'SP_EVENT_CONTACT_DEALER',
        poReserve: 'SP_DSE_PRE_CONTACT_DEALER',
        reserve: 'SP_DSE_CPO_CONTACT_DEALER',
        newReserve: 'SP_DSE_NEW_CONTACT_DEALER'
    },
    SCH_TEST_DRIVE: {
        default: 'SP_SCH_TEST_DRIVE',
        byo: 'SP_BYO_SCH_TEST_DRIVE',
        dl: 'SP_DL_SCH_TEST_DRIVE',
        event: 'SP_EVENT_SCH_TEST_DRIVE',
        inventory: 'SP_INV_NEW_SCH_TEST_DRIVE',
        pe: 'SP_PE_SCH_TEST_DRIVE',
        shoppingtools: 'SP_SHOP_SCH_TEST_DRIVE',
        tco: 'SP_TCO_SCH_TEST_DRIVE',
        upfit: 'SP_UPFIT_SCH_TEST_DRIVE',
        cpo: 'SP_INV_CPO_SCH_TEST_DRIVE',
        po: 'SP_INV_PRE_SCH_TEST_DRIVE',
        poReserve: 'SP_DSE_PRE_TEST_DRIVE',
        reserve: 'SP_DSE_CPO_TEST_DRIVE',
        newReserve: 'SP_DSE_NEW_TEST_DRIVE',
        trim: 'SP_TRIM_SCH_TEST_DRIVE'
    },
    REQUEST_QUOTE: {
        default: 'SP_REQUEST_QUOTE',
        inventory: 'SP_INV_REQUEST_QUOTE',
        so: 'SP_SO_REQUEST_QUOTE',
        dl: 'SP_DL_REQUEST_QUOTE',
        fleet: 'SP_FL_REQUEST_QUOTE',
        shoppingtools: 'SP_SHOP_REQUEST_QUOTE',
        byo: 'SP_BYO_REQUEST_QUOTE',
        tco: 'SP_TCO_REQUEST_QUOTE',
        pe: 'SP_PE_REQUEST_QUOTE',
        event: 'SP_EVENT_REQUEST_QUOTE',
        cpo: 'SP_INV_PRE_REQUEST_QUOTE',
        po: 'SP_INV_PRE_REQUEST_QUOTE',
        trim: 'SP_TRIM_REQUEST_QUOTE'
    },
    IM_INTERESTED: {
        inventory: 'SP_DSE_NEW_INTERESTED_CUSTOMER',
        cpo: 'SP_DSE_PRE_INTERESTED_CUSTOMER',
        po: 'SP_DSE_PRE_INTERESTED_CUSTOMER',
    },
    GLOBAL_CONTACT_DEALER: {
        default: 'SP_GLOBAl_NAV_CONTACT_DEALER'
    }
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

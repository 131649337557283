import { EVENTS } from 'Constants';
import { ClickOutside, screen, TabOutside, noop } from 'utils';

import { StickyNav } from 'partials/sticky-nav';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {
 *     MENU_TRIGGER: String,
 *     MENU_TRIGGER_TARGET: String,
 *     STICKY_NAV_CONTAINER: String,
 *     STICKY_ELEMENT: String,
 *     STICKY_TITLE_WRAPPER: String,
 *     STICKY_NAV_TARGET: String,
 *     STICKY_NAV_TOGGLE: String,
 *     STICKY_NAV_WRAPPER: String
 *     OPEN: String
 * }
 */

const CLASSES = {
    MENU_TRIGGER: 'themes-sticky-nav__section--button',
    MENU_TRIGGER_TARGET: 'themes-sticky-nav__pages',
    STICKY_NAV_LINK: 'themes-sticky-nav__link',
    STICKY_NAV_CONTAINER: 'themes-sticky-nav__container',
    STICKY_ELEMENT: 'themes-sticky-nav__container-inner',
    STICKY_TITLE_WRAPPER: 'themes-sticky-nav__section-title-wrapper',
    STICKY_NAV_TARGET: 'sticky-nav',
    STICKY_NAV_TOGGLE: 'sticky-nav__nav-toggle',
    STICKY_NAV_WRAPPER: 'sticky-nav__wrapper',
    OPEN: 'open'
};

/**
 * @const ATTRIBUTES
 * @description Stores a collection of attributes for use in the DOM
 * @type {
 *     STICKY_NAV_STATE: String
 * }
 */
const ATTRIBUTES = {
    STICKY_NAV_STATE: 'pageLevelStickyNavState',
    EXPANDED: 'aria-expanded',
    HIDDEN: 'aria-hidden'
};

export default class ThemeStickyNav {
    /**
     * @method constructor
     * @description Instantiates the ThemeStickyNav Module
     * @param element {Object} DOM representation of Theme Sticky Nav
     */
    constructor(element) {
        this.element = element;

        this.init();
    }

    /**
     * @method init
     * @description  this method executed exactly after all the properties
     * have been setup by default.
     */
    init() {
        this.cacheDOM();
        this.setBindings();
        this.attachEvents();
        this.initializeStickyNav();
        this.remediateMenuOpener();
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.menuOpenerElm = this.element.querySelector(`.${CLASSES.MENU_TRIGGER}`);
        this.menu = this.element.querySelector(`.${CLASSES.MENU_TRIGGER_TARGET}`);
        this.menuLinksElms = this.menu.querySelectorAll(`.${CLASSES.STICKY_NAV_LINK}`);
        this.stickyNavContainer = this.element.querySelector(`.${CLASSES.STICKY_NAV_CONTAINER}`);
        this.stickyElement = this.element.querySelector(`.${CLASSES.STICKY_ELEMENT}`);
        this.stickyNavTitleWrapper = this.element.querySelector(`.${CLASSES.STICKY_TITLE_WRAPPER}`);
    }

    /**
     * @method setBindings
     * @description Binds event handlers to the instance
     */
    setBindings() {
        this.toggleMenu = this.toggleMenu.bind(this);
        this.observeDOMChanges = this.observeDOMChanges.bind(this);
        this.stickyNavToggleHandler = this.stickyNavToggleHandler.bind(this);
    }

    /**
     * @method attachEvents
     * @description Applies click event listeners to the modal openers
     */
    attachEvents() {
        this.menuOpenerElm.addEventListener(EVENTS.CLICK, this.toggleMenu);
        this.clickOutsideHandler = new ClickOutside(
            this.menuOpenerElm,
            noop,
            this.toggleMenu,
            this.menu
        );
        if (screen.gte(screen.SIZES.LARGE)) {
            this.tabOutsideHandler = new TabOutside(
                this.menu,
                {
                    onBlur: this.toggleMenu
                }
            );
        }
        this.domObserver = new MutationObserver(this.observeDOMChanges.bind(this));
        this.domObserver.observe(document.body, {
            childList: true,
            attributes: true,
            subtree: true
        });
    }

    /**
     * @method initializeStickyNav
     * @description starts up the StickyNav partial that forms part of the functionality of Theme Sticky Nav
     */
    initializeStickyNav() {
        const stickyNavBottom = window.mbVans.pageData.themesPages.stickyNavBottom || false;

        const options = {
            maxItemsDesktop: 6,
            stickyElementClass: CLASSES.STICKY_ELEMENT,
            stickyNavTargetElementClass: CLASSES.STICKY_NAV_TARGET,
            stickyNavTheme: true,
            pageTitle: window.mbVans.pageData.themesPages.pageTitle,
            stickyNavBottom,
            offset: this.element.clientHeight + 15 // added to work around not reflecting currently active link
        };
        this.stickyNav = new StickyNav(this.element, options);
    }

    /**
     * @method remediateMenuOpener
     * @description Set role=presentation when in mobile view so screen readers only see
     * one button that does the same thing.
     */
    remediateMenuOpener() {
        if (!screen.gte(screen.SIZES.LARGE)) {
            this.menuOpenerElm.setAttribute(ATTRIBUTES.HIDDEN, true);
        }
    }

    /**
     * @method toggleMenu
     * @description toggles the classes that open and close the menu
     */
    toggleMenu() {
        const isLarge = screen.gte(screen.SIZES.LARGE);

        if (isLarge) {
            if (this.menu.classList.contains(CLASSES.OPEN)) {
                this.menu.classList.remove(CLASSES.OPEN);
                this.menuOpenerElm.classList.remove(CLASSES.OPEN);
                this.menuOpenerElm.setAttribute(ATTRIBUTES.EXPANDED, false);
                this.clickOutsideHandler.detachEvents();
                this.clickOutsideHandler.attachEvents();
                this.menuOpenerElm.focus();
            } else {
                this.menu.classList.add(CLASSES.OPEN);
                this.menuLinksElms[0].focus();
                this.menuOpenerElm.classList.add(CLASSES.OPEN);
                this.menuOpenerElm.setAttribute(ATTRIBUTES.EXPANDED, true);
            }
        } else {
            this.stickyNav.stickyNavToggleHandler(event);
            this.stickyNav.clickOutsideEventHandler.detachEvents();
            this.stickyNav.clickOutsideEventHandler.attachEvents();
            this.clickOutsideHandler.detachEvents();
            this.clickOutsideHandler.attachEvents();
        }
    }

    /**
     * @method observeDOMChanges
     * @description Watches for DOM changes that occur when sticky nav is added
     * Used for copying button to header
     */
    observeDOMChanges(mutations) {
        const CONTROL_MUTATIONS = (mutation) => (
            mutation.target.className === CLASSES.STICKY_NAV_CONTAINER &&
            [].slice.call(mutation.addedNodes).filter((node) => node.className === CLASSES.STICKY_NAV_TOGGLE)
        );

        const STICKY_NAV_ADDED_MUTATIONS = [].slice.call(mutations).filter(CONTROL_MUTATIONS);

        if (STICKY_NAV_ADDED_MUTATIONS) {
            setTimeout(() => {
                this.stickyNavToggle = this.stickyElement.querySelector(`.${CLASSES.STICKY_NAV_TOGGLE}`);
                this.stickyNavWrapper = this.stickyElement.querySelector(`.${CLASSES.STICKY_NAV_WRAPPER}`);
            }, 5);
        }

        const FILTER_MUTATIONS = (mutation) => (
            mutation.target.className === CLASSES.STICKY_ELEMENT &&
            [].slice.call(mutation.attributeName).filter((node) => node.dataset &&
                node.dataset[ATTRIBUTES.STICKY_NAV_STATE] &&
                (node.dataset[ATTRIBUTES.STICKY_NAV_STATE] === 'collapsed' ||
                    node.dataset[ATTRIBUTES.STICKY_NAV_STATE] === 'expanded'))
        );

        const EXPANDED_NAV_IN_MOBILE = [].slice.call(mutations).filter(FILTER_MUTATIONS);

        if (EXPANDED_NAV_IN_MOBILE) {
            setTimeout(() => {
                const toggleInHeader = this.stickyNavTitleWrapper.querySelector(`.${CLASSES.STICKY_NAV_TOGGLE}`);
                const toggleInNav = this.stickyNavWrapper.querySelector(`.${CLASSES.STICKY_NAV_TOGGLE}`);
                const isLarge = screen.gte(screen.SIZES.LARGE);
                const expanded = this.stickyElement.dataset[ATTRIBUTES.STICKY_NAV_STATE] === 'expanded';
                if (!isLarge && !toggleInHeader && this.stickyNavToggle && expanded) {
                    this.stickyElement.setAttribute(ATTRIBUTES.EXPANDED, true);
                    this.stickyNavTitleWrapper.insertAdjacentElement('beforeend', this.stickyNavToggle);
                    this.stickyNavToggle.removeEventListener(EVENTS.CLICK, this.stickyNavToggleHandler);
                    this.stickyNavToggle.addEventListener(EVENTS.CLICK, this.stickyNavToggleHandler);
                } else if (!isLarge && !toggleInNav && this.stickyNavToggle && !expanded) {
                    this.stickyElement.setAttribute(ATTRIBUTES.EXPANDED, false);
                    this.stickyNavWrapper.insertAdjacentElement('afterbegin', this.stickyNavToggle);
                    this.stickyNavToggle.removeEventListener(EVENTS.CLICK, this.stickyNavToggleHandler);
                }
            }, 50);
        }
    }

    /**
     * @method stickyNavToggleHandler
     * @description handles clicks on the StickyNav partial's button when it's copied outside the sticky nav, passes
     * the event to the StickyNav partial.
     * @param event
     */
    stickyNavToggleHandler(event) {
        this.stickyNav.stickyNavToggleHandler(event);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

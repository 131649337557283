import { renderer } from 'utils';
import { accordionParser } from 'partials/accordion';
import { LoadingSpinner } from 'partials/loading-spinner';

import DealerResult from './DealerResult';

import browseAllTemplate from './../templates/browseAllTemplate';

/**
 * @const CLASSES
 */
const CLASSES = {
    IS_LOADING: 'is-loading'
};

/**
 * @const ATTRIBUTES
 */
const ATTRIBUTES = {
    ACCORDION: 'data-accordion',
    LOCATION_GROUP: 'data-location-group',
    LOCATION_RESULTS: 'data-location-results'
};

export default class BrowseByState {
    /**
     * @constructor
     * @description Creates new BrowseByState
     * @param {Object[]} states - Array of state objects
     * @param {string} states[].code - ISO 3166-2 code of state
     * @param {string} states[].name - Name of state
     * @param {Object} config - Configuration of Dealer Locator Plugin
     * @param {Object} content - i18n Content
     * @param {Function} getDealersForStateCallback - Callback to be called when each accordion is opened
     * @param {Function} dealerSelectCallback - Callback when dealer is selected
     */
    constructor(states, config, content, getDealersForStateCallback, dealerSelectCallback) {
        this.states = states;
        this.config = config;
        this.content = content;
        this.getDealersForStateCallback = getDealersForStateCallback;

        this.dealers = [];
        this.dealerResults = [];

        this.openStateCallback = this.openStateCallback.bind(this);
        this.dealerSelectCallback = dealerSelectCallback;
        this.element = browseAllTemplate(states, content)({ getNode: true });

        this.createAccordion();
    }

    /**
     * @method createAccordion
     * @description Creates the accordion component
     */
    createAccordion() {
        accordionParser.createAccordion({
            element: this.element.querySelector(`[${ATTRIBUTES.ACCORDION}]`),
            onBeforeOpen: this.openStateCallback
        });
    }

    /**
     * @method openStateCallback
     * @description Callback method for before a state is open
     * This gets the list of dealers for each state, then renders DealerResult for each dealer
     * @param {Number} accordionIndex - Param passed in from callback for onBeforeOpen
     */
    openStateCallback(accordionIndex) {
        const selectedState = this.states[accordionIndex];
        const loadingSpinner = new LoadingSpinner({
            isInline: true
        });
        const resultContainer = this.element.querySelector(
            `[${ATTRIBUTES.LOCATION_GROUP}=${selectedState.code}] [${ATTRIBUTES.LOCATION_RESULTS}]`
        );
        this.dealers = [];
        this.dealerResults = [];

        resultContainer.classList.add(CLASSES.IS_LOADING);
        renderer.insert(loadingSpinner.render(), resultContainer);

        this.getDealersForStateCallback(selectedState.code)
            .then((dealers) => {
                const containerElm = document.createElement('div');

                if (dealers.length) {
                    dealers.forEach((dealer) => {
                        const dealerResult = new DealerResult(
                            dealer,
                            {
                                showDistance: this.config.featureFlags.showDistance,
                                showHoursOfOp: this.config.featureFlags.showDealerHours,
                                showSecondaryCTA: this.config.featureFlags.showSecondaryCTA,
                                displayLayout: this.config.displayLayout,
                                useScheduleServiceURL: this.config.useServiceURL,
                                isPreferredHidden: dealer.isPreferred &&
                                    !this.config.featureFlags.inlinePreferredDealer,
                                showPhoneNumbers: true,
                                showArrowIndicator: true,
                                showFullAddress: true,
                                showVanCare: this.config.featureFlags.showVanCare,
                            },
                            this.content,
                            this.dealerSelectCallback);

                        resultContainer.classList.remove(CLASSES.IS_LOADING);
                        containerElm.appendChild(dealerResult.render());
                        this.dealerResults.push(dealerResult);
                        this.dealers.push({ ...dealer });
                    });
                } else {
                    containerElm.innerHTML = `<p>${this.content.noResultsFound}</p>`;
                }

                renderer.insert(containerElm, resultContainer);
            })
            .catch((error) => {
                if (error && error.message && error.message === 'CLIENT_ERROR') {
                    resultContainer.innerHTML = (
                        `<p class="browse-by-state__no-results">${this.content.noResultsFound}</p>`
                    );
                }
            });
    }

    /**
     * @method setPreferredDealer
     * @description Sets the dealer with ID as preferred dealer
     * @param {string} dealerID - ID of dealer to set preferred dealer
     */
    setPreferredDealer(dealerID) {
        const filteredDealerResult = this.dealerResults.find(
            (dealerResult) => dealerResult.dealer.dealerId === dealerID
        );
        const filteredDealer = this.dealers.find((dealer) => dealer.dealerId === dealerID);

        if (filteredDealerResult) {
            filteredDealerResult.setPreferred(true);
            filteredDealer.isPreferred = true;
        }
    }

    /**
     * @method unsetPreferredDealer
     * @description Unsets the dealer with ID as preferred dealer
     * @param {string} dealerID - ID of dealer to set preferred dealer
     */
    unsetPreferredDealer(dealerID) {
        const filteredDealerResult = this.dealerResults.find(
            (dealerResult) => dealerResult.dealer.dealerId === dealerID
        );
        const filteredDealer = this.dealers.find((dealer) => dealer.dealerId === dealerID);

        if (filteredDealerResult) {
            filteredDealerResult.setPreferred(false);
            filteredDealer.isPreferred = false;
        }
    }

    /**
     * @method render
     * @description Returns the element associated with the view
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Library dependencies
import isEqual from 'lodash.isequal';

// Constant dependencies
import {
    EVENTS,
    KEYBOARD
} from 'Constants';

// Partial dependencies
import { Observer } from 'partials/observer';
import { googleLocationsApi } from 'partials/google-maps';

// Utils dependencies
import {
    debounce,
    noop,
    renderer,
    findAncestor,
    generateUniqueID,
    screen,
    htmlNode
} from 'utils';
import { isValidZip, isPlace, isValidZipStart } from '@mbusa/now-ui-utils.validate';

// Local dependencies
import LocationSearchModel from './../models/LocationSearchModel';
import LocationList from './../views/LocationList';
import locationSearchTemplate from './../templates/locationSearchTemplate';

const ICON_TYPES = {
    ERROR: 'error'
};

/**
 * @constant CLASSES
 * @description Class references for the LocationSearch view
 * @type {{ACTIVE: string}}
 */
const CLASSES = {
    GLOBALNAV: 'dealer-locator-nav__input',
    BASE: 'location-search',
    ACTIVE: 'location-search--active',
    ACTIVE_ELEMENT: 'location-search__location--active',
    ERROR: 'error',
    FORM: 'form__input',
    DROPDOWN: 'form__input-dropdown',
    LOCATION_LIST: 'location-search__list',
    LOCATION_LIST_ELEMENT: 'location-search__location',
    CLEAR_BUTTON_VISIBLE: 'form__input-reset--visible'
};

/**
 * @constant ATTRIBUTES
 * @description Attribute references for the LocationSearch view
 * @type {{SEARCH_INPUT: string, SEARCH_LOCATIONS: string}}
 */
const ATTRIBUTES = {
    SEARCH_INPUT: 'data-search-input',
    SEARCH_INPUT_CONTAINER: 'data-search-input-container',
    SEARCH_LOCATIONS: 'data-search-locations',
    CLEAR_BUTTON: 'data-clear-button',
    BROWSE_ALL_BUTTON: 'data-browse-button',
    ERROR_MESSAGE: 'data-error-message',
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_INVALID: 'aria-invalid',
    ARIA_DESCRIBEDBY: 'aria-describedby',
};

/**
 * @function transformToLocationSearchModelPlace
 * @description Transforms the place from places API into structure of
 * an autocomplete prediction place.
 * @param place {google.maps.places.PlaceResult} Place from places API
 * @return {google.maps.places.AutocompletePrediction}
 */
const transformToLocationSearchModelPlace = (place) => (
    {
        terms: [
            {
                value: place.address_components[0].long_name
            },
            {
                value: place.address_components.find((component) => component.types.indexOf('administrative_area_level_1') !== -1).short_name
            }
        ],
        place_id: place.place_id
    }
);


/**
 * View responsible for searching for a list of locations via as a
 * as a user types a value into an input and provides a callback
 * for when a location is selected
 */
export default class LocationSearch extends Observer {
    /**
     * @static THEMES
     * @description CSS class themes that can be applied to the LocationSearch
     * @type {{DARK: string}}
     */
    static THEMES = {
        GLOBALNAV: 'globalNav'
    };

    /**
     * @constructor
     * @description On instantiation, sets property values, method aliases and
     * initializes the view by caching the dom elements and attaching events
     * @param searchLength {Number} The input length to buffer before initializing a search
     * @param searchInputLabel {String} Search label value to display
     * @param errorMessage {String} Error message to display on invalid search
     * @param theme {String} Optional theme to apply
     * @param onSubmit {Function} Callback to apply when a location is selected or submitted
     * @param onSubmitBtn {Element} Optional element to attach a click handler to submit the form
     * @param disableReset {Boolean} Flag to disable the functionality of reseting the input value
     * @param country {String} The country context of the search
     * @param defaultLocation {Object} Location object to set as default on load
     * @param autoSubmitLocation {Boolean=true} Flag to disable executing callback when
     * @param onInputChangeCallback {Function} Callback to apply when input changes
     * @param onAutoSubmitErrorCallback {Function} Callback for autoSubmit error handling
     * @param onErrorMessageCallback {Function} Callback to apply after Error message is displayed on invalid search
     * pre-requisite data is available
     */
    constructor({
        searchLength = 3,
        searchInputLabel = '',
        errorMessage = '',
        theme = '',
        onSubmit = noop,
        onSubmitBtn = null,
        disableReset = false,
        country,
        defaultLocation,
        autoSubmitLocation = true,
        onInputChangeCallback = noop,
        onAutoSubmitErrorCallback = noop,
        onErrorMessageCallback = noop
    } = {}) {
        super();
        const locationSearchId = generateUniqueID();
        const listBoxId = `listbox${locationSearchId}`;
        this.element = locationSearchTemplate(
            locationSearchId, listBoxId, { searchInputLabel, disableReset })({ getNode: true }
        );
        this.formElm = null;
        this.searchInputElm = null; // Search input reference
        this.searchInputContainerElm = null;
        this.onSubmitBtn = onSubmitBtn;
        this.clearButtonElm = null;
        this.errorMessageElm = null;
        this.disableReset = disableReset;
        this.locationListElm = null;
        this.locationList = null;
        this.searchLengthBuffer = searchLength;
        this.onSubmitCallback = onSubmit;
        this.country = country;
        this.errorMessage = errorMessage;
        this.errored = false;
        this.errorId = `error-${locationSearchId}`;
        this.theme = theme;
        this.model = new LocationSearchModel();
        this.defaultLocation = defaultLocation;
        this.onInputChangeCallback = onInputChangeCallback;
        this.autoSubmitLocation = autoSubmitLocation;
        this.onAutoSubmitErrorCallback = onAutoSubmitErrorCallback;
        this.onErrorMessageCallback = onErrorMessageCallback;

        // method aliases
        this.onKeydown = this.onKeydown.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.checkInputChangeKey = this.checkInputChangeKey.bind(this);
        this.onLocationSelection = this.onLocationSelection.bind(this);
        this.searchPlaces = this.searchPlaces.bind(this);
        this.searchPlacesDebounce = debounce(this.searchPlaces, 100).bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.submitLocation = this.submitLocation.bind(this);
        this.onSubmitBtnClick = this.onSubmitBtnClick.bind(this);
        this.clearInputValue = this.clearInputValue.bind(this);
        this.toggleClearButton = this.toggleClearButton.bind(this);
        this.onLocationListFocus = this.onLocationListFocus.bind(this);
        this.onlocationListChange = this.onlocationListChange.bind(this);

        this.cacheDOM();
        this.attachEvents();


        if (this.disableReset) {
            this.disableClearButton();
        }

        if (this.defaultLocation) {
            this.setDefaultLocation();
        }

        this.locationList = new LocationList(this.onLocationSelection, listBoxId);
        renderer.insert(this.locationList.render(), this.locationListElm);
    }

    /**
     * @method setDefaultLocation
     * @description Reads the defaultLocation and updates the current state
     */
    setDefaultLocation() {
        if (this.defaultLocation.searchLocation) {
            switch (this.defaultLocation.searchByType) {
            case 'place':
                googleLocationsApi.getPlaceInfo(this.defaultLocation.searchLocation)
                    .then(transformToLocationSearchModelPlace)
                    .then((location) => this.onLocationSelection(location, this.autoSubmitLocation))
                    .catch((error) => {
                        console.log(error);
                        this.onAutoSubmitErrorCallback();
                    });
                break;
            case 'zip':
            case 'string':
                this.setLocation(this.defaultLocation.searchLocation);
                this.setInputValue(this.defaultLocation.searchLocation);
                // if autoSubmitLocation is true, call submitLocation
                // Note: this needs to be asynchronous. LocationSearch will be undefined before this is called if
                // the logic is called synchronous vs only the defaultLocation
                if (this.autoSubmitLocation) {
                    window.setTimeout(this.submitLocation.bind(this));
                }
                break;
            default:
            }
        }
    }

    /**
     * @method cacheDOM
     * @description Caches reference to DOM elements from the view
     */
    cacheDOM() {
        this.formElm = this.element.querySelector(`.${CLASSES.FORM}`);
        this.searchInputElm = this.element.querySelector(`[${ATTRIBUTES.SEARCH_INPUT}]`);
        this.searchInputContainerElm = this.element.querySelector(`[${ATTRIBUTES.SEARCH_INPUT_CONTAINER}]`);
        this.locationListElm = this.element.querySelector(`[${ATTRIBUTES.SEARCH_LOCATIONS}]`);
        this.clearButtonElm = this.element.querySelector(`[${ATTRIBUTES.CLEAR_BUTTON}]`);
        this.errorMessageElm = this.element.querySelector(`[${ATTRIBUTES.ERROR_MESSAGE}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    attachEvents() {
        if (this.onSubmitBtn) {
            this.onSubmitBtn.addEventListener(EVENTS.CLICK, this.onSubmitBtnClick);
        }
        this.searchInputElm.addEventListener(EVENTS.KEYDOWN, this.onKeydown);
        this.searchInputElm.addEventListener(EVENTS.FOCUS, this.onInputFocus);
        this.searchInputElm.addEventListener(EVENTS.BLUR, this.onInputBlur);
        this.clearButtonElm.addEventListener(EVENTS.CLICK, this.clearInputValue);
        this.locationListElm.addEventListener(EVENTS.FOCUS, this.onLocationListFocus);
        this.locationListElm.addEventListener(EVENTS.KEYDOWN, this.onlocationListChange);
        this.model.attach(this);
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callbacks from the view
     */
    detachEvents() {
        if (this.onSubmitBtn) {
            this.onSubmitBtn.removeEventListener(EVENTS.CLICK, this.onSubmitBtnClick);
        }
        this.searchInputElm.removeEventListener(EVENTS.KEYDOWN, this.onKeydown);
        this.searchInputElm.removeEventListener(EVENTS.KEYUP, this.onInputChange);
        this.searchInputElm.removeEventListener(EVENTS.FOCUS, this.onInputFocus);
        this.searchInputElm.removeEventListener(EVENTS.BLUR, this.onInputBlur);
        this.clearButtonElm.removeEventListener(EVENTS.CLICK, this.clearInputValue);
        this.locationListElm.removeEventListener(EVENTS.FOCUS, this.onLocationListFocus);
        this.locationListElm.removeEventListener(EVENTS.KEYDOWN, this.onlocationListChange);
        this.model.detach(this);
    }

    /**
     * @method destroy
     * @description Destroys the view by detaching its events and
     * removing the element from the DOM
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method onUpdate
     * @description Callback handler for when the model's state has changed
     * @param prevState {Object} State before change
     * @param newState {Object} State after change
     */
    onUpdate(prevState, newState) {
        if (!isEqual(prevState.locations, newState.locations)) {
            this.onLocationsChange();
        }
    }

    /**
     * @method toggleClearButton
     * @description toggle the Clear Button. Method is used because IE 11 has issues
     * styling the focus pseudo selector.
     * @param value of input
     */
    toggleClearButton(value) {
        if (!this.clearButtonElm) {
            return;
        }

        if (value !== '') {
            this.clearButtonElm.classList.add(CLASSES.CLEAR_BUTTON_VISIBLE);
        } else {
            this.clearButtonElm.classList.remove(CLASSES.CLEAR_BUTTON_VISIBLE);
        }
    }

    /**
     * @method onLocationListFocus
     * @description will highlight the first element of the list
     */
    onLocationListFocus() {
        const firstLocationElm = this.element.querySelector(`.${CLASSES.LOCATION_LIST_ELEMENT}`);
        if (firstLocationElm) {
            firstLocationElm.focus();
        }
    }

    /**
     * @method getLocationListDOMElements
     * @description returns list of li elements containg locations
     */
    getLocationListDOMElements() {
        return this.element.querySelector(`.${CLASSES.LOCATION_LIST}`);
    }

    /**
     * @method onlocationListChange
     * @description
     * 1) will highlight the next/previous element depending on the pressed key.
     * 2) will focus browse all button and clear the list on tab key press
     * 3) will submit the selected location
     */
    onlocationListChange(event) {
        const currentSelectedElement = document.activeElement;
        const {
            which
        } = event;

        switch (which) {
        case KEYBOARD.UP:
            this.handleOptionUpArrow(event, currentSelectedElement);
            break;
        case KEYBOARD.DOWN:
            this.handleOptionDownArrow(event, currentSelectedElement);
            break;
        case KEYBOARD.ENTER:
            this.processKeyEnterOnLocationList(event);
            break;
        default:
        }
    }

    /**
     * @method handleOptionUpArrow
     * @description Up arrow handler for the location list.
     * Finds previous element sibling, and focuses if found. If not focuses on the input field
     */
    handleOptionUpArrow(event, element) {
        event.preventDefault();
        if (!element) return;
        const sibling = element.previousElementSibling;

        if (sibling) {
            sibling.focus();
        } else {
            this.searchInputElm.focus();
        }
    }

    /**
     * @method handleOptionDownArrow
     * @description Down arrow handler for the location list.
     * Finds next element sibling, and focuses if found.
     */
    handleOptionDownArrow(event, element) {
        event.preventDefault();
        if (!element) return;
        const sibling = element.nextElementSibling;

        if (sibling) {
            sibling.focus();
        }
    }

    /**
     * @method processKeyEnterOnLocationList
     * @description will set the current active location in list and submit the location for
     * google map rendering processing
     */
    processKeyEnterOnLocationList(event) {
        event.preventDefault();
        const selectedElement = document.activeElement;
        if (selectedElement) {
            this.setLocation(selectedElement.innerHTML);
            this.submitLocation();
        }
    }

    /**
     * @method disableClearButton
     * @description Remove Clear button
     */
    disableClearButton() {
        this.clearButtonElm.remove();
        this.clearButtonElm = null;
    }

    /**
     * @method onKeydown
     * @description Event handler for keydown events; adds listener for keyup event
     */
    onKeydown() {
        this.searchInputElm.addEventListener(EVENTS.KEYUP, this.onInputChange);
    }

    /**
     * @method onInputChange
     * @description Callback handler for changes events from the search input to test
     * if the value has changed, and if so calls methods to set the new value and
     * make a search for places
     * @param event
     */
    onInputChange(event) {
        this.searchInputElm.removeEventListener(EVENTS.KEYUP, this.onInputChange);
        const {
            target: { value },
            which
        } = event;

        this.toggleClearButton(value);

        if (this.theme === LocationSearch.THEMES.GLOBALNAV) {
            if (this.errored) {
                this.model.state.inputValue = '';
                this.model.state.locationValue.value = '';
                this.hideErrorMessage();
            } else {
                this.checkInputChangeKey(which, value);
            }
        } else {
            if (this.errored) {
                this.hideErrorMessage();
            }

            this.checkInputChangeKey(which, value);
        }

        if (typeof this.onInputChangeCallback === 'function') {
            this.onInputChangeCallback(value);
        }
    }

    /**
     * @method checkInputChangeKey
     * @description tests which key was pressed, if the value has changed calls methods to set
     * the new value and make a search for places
     * @param key {String}
     * @param value {String}
     */
    checkInputChangeKey(key, value) {
        if (key === KEYBOARD.ENTER) {
            this.submitLocation();
        } else if (key === KEYBOARD.DOWN) {
            this.locationListElm.focus();
        } else if (value !== this.model.state.inputValue) {
            this.setInputValue(value);
            this.setLocation(value);
            this.searchPlacesDebounce();
        }
    }

    /**
     * @method onSubmitBtnClick
     * @description Callback handler for top-nav search submit btn
     */
    onSubmitBtnClick() {
        this.submitLocation();
    }

    /**
     * @method onInputFocus
     * @description Callback handler for when the LocationSearch input
     * is focused to add the active class to the view
     */
    onInputFocus() {
        const inputValue = this.searchInputElm.value;
        if (this.theme === LocationSearch.THEMES.GLOBALNAV) {
            this.hideErrorMessage();
        }

        this.element.classList.add(CLASSES.ACTIVE);
        const dropDown = this.element.querySelector(`.${CLASSES.DROPDOWN}`);
        if (dropDown) {
            dropDown.style.display = 'block';
        }
        this.toggleClearButton(inputValue);
    }

    /**
     * @method onInputBlur
     * @description Callback handler for when the LocationSearch input
     * is blurred to remove the active class to the view and remove
     * the location list
     *
     * Note: a timeout is required here as the blur event is at
     * a higher scope when called and create a race condition for
     * the click event of a LocationList item
     */
    onInputBlur() {
        window.setTimeout(() => {
            this.element.classList.remove(CLASSES.ACTIVE);
            if (this.clearButtonElm) {
                this.clearButtonElm.classList.remove(CLASSES.CLEAR_BUTTON_VISIBLE);
            }
            const dropDown = this.element.querySelector(`.${CLASSES.DROPDOWN}`);
            if (dropDown) {
                dropDown.style.display = 'none';
            }
            if (!findAncestor(document.activeElement, `[${ATTRIBUTES.SEARCH_LOCATIONS}]`)) {
                this.removeLocationList();
            }
        }, 500);
    }

    /**
     * @method onLocationsChange
     * @description Callback handler for when the locations state has changed to remove the
     * current list of locations and display a new list if locations are available
     */
    onLocationsChange() {
        const { locations } = this.model.state;

        if (this.locationList) {
            this.removeLocationList();
        }

        if (locations && locations.length > 0) {
            this.displayLocationList();
        }
    }

    /**
     * @method onLocationSelection
     * @description Callback for when a location is selected to set the input value,
     * set the selected location and remove the location list
     * @param location
     * @param autoSubmitLocation {Boolean}
     */
    onLocationSelection(location, autoSubmitLocation = true) {
        const formattedLocation = `${location.terms[0].value}, ${location.terms[1].value}`;
        this.setInputValue(formattedLocation);
        if (screen.gte(screen.SIZES.LARGE)) {
            this.searchInputElm.focus();
            this.searchInputElm.blur();
        }
        this.setLocation(location);
        if (autoSubmitLocation) {
            this.submitLocation();
        }
        this.removeLocationList();
    }

    /**
     * @method setInputValue
     * @description Sets the inputValue property
     * @param value {String} Value to set
     */
    setInputValue(value) {
        this.searchInputElm.value = value;
    }

    /**
     * @method clearInputValue
     * @description Clears and resets the searchInputElm value
     */
    clearInputValue() {
        this.setInputValue('');
        this.toggleClearButton('');
        this.setLocation('');
        this.onInputChangeCallback('');
        this.removeLocationList();
    }

    /**
     * @method setLocation
     * @description Sets a location to the model based on a value
     * @param value {String|Object} A string value of Google Place location object
     */
    setLocation(value) {
        this.model.setLocation(value);
    }

    /**
     * @method autoSelectLocation
     * @description Gets the list of locations based on current location,
     * call the onLocationSelection with the first location from the result
     */
    autoSelectLocation() {
        const { locationValue } = this.model.state;
        return this.model.getLocations(locationValue.value, this.country).then(() => {
            if (this.model.state.locations) {
                this.onLocationSelection(this.model.state.locations[0]);
            }
        }).catch((error) => {
            throw (error);
        });
    }

    /**
     * @method shouldAutoPick
     * @description checks if location should be auto selected if it's type 'string' and long enough
     * @return {Boolean}
     */
    shouldAutoPick() {
        const { locationValue } = this.model.state;
        const isTypeString = (value) => (value.type === 'string');
        const isLongEnough = (value) => (value.length >= this.searchLengthBuffer);

        return locationValue
            && isTypeString(locationValue)
            && isLongEnough(locationValue.value);
    }

    /**
     * @method submitLocation
     * @description Callback handler for when an location is submitted to apply the
     * `onSubmitCallback` with the `locationValue` object from the model
     */
    submitLocation() {
        const { locationValue } = this.model.state;
        if (!isPlace(locationValue.value) && !isValidZip(locationValue.value)) {
            this.showErrorMessage();
        } else {
            this.errored = false;
            this.hideErrorMessage();
            if (this.shouldAutoPick()) {
                this.autoSelectLocation();
            } else if (locationValue.type === 'place' || locationValue.type === 'zip') {
                this.onSubmitCallback(locationValue);
                this.removeLocationList();
            }
        }
    }

    /**
     * @method searchPlaces
     * @description Calls the model to fetch a list of locations
     */
    searchPlaces() {
        const { locationValue } = this.model.state;

        if (locationValue && locationValue.value.length >= this.searchLengthBuffer &&
            (this.country !== 'us' || locationValue.value.length > 5 ||
            !isValidZipStart(locationValue.value))) {
            this.model.getLocations(locationValue.value, this.country);
        } else {
            this.model.clearLocations();
        }
    }

    /**
     * @method appendIcon
     * @description Appends error icon based on the iconType param
     * @memberof InputControl
     * @param {string} iconType will give us the type of icon to append
     */
    appendIcon(iconType) {
        console.log(this.searchInputElm);
        if (iconType === ICON_TYPES.ERROR && this.formElm.querySelector('.error-icon') === null) {
            const ERROR_ICON = htmlNode`
            <svg class="form__input--icon error-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12" r="12" fill="currentColor"/>
                <path d="M11.5 6.01196H13.5V13.012H11.5V6.01196Z" fill="white"/>
                <path d="M12.5 17.512C13.3284 17.512 14 16.8404 14 16.012C14 15.1835 13.3284 14.512 12.5 14.512C11.6716 14.512 11 15.1835 11 16.012C11 16.8404 11.6716 17.512 12.5 17.512Z" fill="white"/>
            </svg>
            `;
            this.formElm.appendChild(ERROR_ICON({ getNode: true }));
        }
    }

    /**
     * @method showErrorMessage
     * @description display the error message
     * @param message {String} optional error message to display
     * @param setFocus {Boolean} indicates whether to give errored input focus
     */
    showErrorMessage(message = null, setFocus = true) {
        this.errored = true;
        const errorMessage = message || this.errorMessage;

        if (this.theme === LocationSearch.THEMES.GLOBALNAV) {
            this.searchInputElm.value = errorMessage;
            this.searchInputElm.classList.add(`${CLASSES.ERROR}`);
        } else {
            this.formElm.classList.add(`${CLASSES.ERROR}`);
            this.appendIcon(ICON_TYPES.ERROR);
            this.errorMessageElm.innerText = errorMessage;
            if (setFocus) {
                this.searchInputElm.focus();
            }
        }

        this.searchInputElm.setAttribute(ATTRIBUTES.ARIA_INVALID, 'true');
        this.searchInputElm.setAttribute(ATTRIBUTES.ARIA_DESCRIBEDBY, this.errorId);

        // add focus on text field after showing error message
        this.onErrorMessageCallback();
    }

    /**
     * @method set focus
     * @description gives DOM element focus
     */
    set focus(focus) {
        if (focus) {
            this.searchInputElm.focus();
        }
    }

    /**
     * @method hideErrorMessage
     * @description hide the error message
     */
    hideErrorMessage() {
        this.errored = false;
        if (this.theme === LocationSearch.THEMES.GLOBALNAV) {
            this.clearInputValue();
            this.searchInputElm.classList.remove(`${CLASSES.ERROR}`);
        } else {
            this.formElm.classList.remove(`${CLASSES.ERROR}`);
            this.formElm.querySelector('.error-icon')?.remove();
            this.formElm.removeAttribute('data-error');
        }

        this.searchInputElm.setAttribute(ATTRIBUTES.ARIA_INVALID, 'false');
        this.searchInputElm.removeAttribute(ATTRIBUTES.ARIA_DESCRIBEDBY);
    }

    /**
     * @method displayLocationList
     * @description Calls LocationList to render the list of locations
     */
    displayLocationList() {
        this.locationList.displayLocations(this.model.state.locations);
        this.searchInputContainerElm.setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'true');
    }

    /**
     * @method removeLocationList
     * @description Calls LocationList to hide the locations
     */
    removeLocationList() {
        if (this.locationList) {
            this.locationList.hideLocations();
            this.searchInputContainerElm.setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'false');
        }
    }

    /**
     * @method validate
     * @description validate the location value and display/hide error messaging
     * @return {Boolean} whether location value is valid
     */
    validate() {
        const location = this.model.state.locationValue && this.model.state.locationValue.value;
        const isValid = !!location && (isPlace(location) || isValidZip(location));
        if (isValid) {
            this.hideErrorMessage();
        } else {
            this.showErrorMessage();
        }

        return isValid;
    }

    /**
     * @method getLocation
     * @description Promise that resolves to the location value, if the location is 'string' calls
     * method to auto select location
     * @return {Promise} current location object from the view
     */
    getLocation() {
        return new Promise((resolve) => {
            if (this.shouldAutoPick()) {
                this.autoSelectLocation().then(() => resolve(this.model.state.locationValue));
            } else {
                resolve(this.model.state.locationValue);
            }
        });
    }

    /**
     * @method render
     * @description Returns the LocationSearch DOM element
     * @return {Node} LocationSearch element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

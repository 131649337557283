/**
 * API responsible for parsing and constructing the inventory favorites data
 */

// Util dependencies
import {
    formatNumber,
    time
} from 'utils';

// Partial dependencies
import { authenticationFetchApi } from 'partials/authentication';

/**
 * @const defaultOptions
 * @description Default fetch options
 */
const defaultOptions = {
    body: null,
    headers: {
        'Content-type': 'application/json',
        'Referrer-Policy': 'origin',
        Referer: window.location.href
    },
    method: 'POST'
};

/**
 * @function parseInventoryFavoritesInfo
 * @description Makes needed formats to inventory favorites
 * @param {Object} inventoryFavorites
 * @param {Object} content
 * @returns {Object} An object with inventory favorites properties
 */
function parseInventoryFavoritesInfo(inventoryFavorites, content, data) {
    return inventoryFavorites.map((inventoryFavoritesItem) => ({
        vehicle: {
            ...inventoryFavoritesItem.vehicle,
            msrp: {
                price: inventoryFavoritesItem.vehicle.msrp,
                priceCurrency: formatNumber.toCurrency(inventoryFavoritesItem.vehicle.msrp)
            },
            dealerInfo: {
                dealerId: inventoryFavoritesItem.vehicle.dealer.id,
                name: inventoryFavoritesItem.vehicle.dealer.name
            }
        },
        timestampFormatted:
            time.toRelativeTime((new Date().getTime() - inventoryFavoritesItem.saveDate),
            content.timeDistance),
        hasMSRP: inventoryFavoritesItem.vehicle.msrp !== 999996,
        vdpURL: {
            new: data.newInventoryVdpUri,
            cpo: data.cpoInventoryVdpUri,
        },
        fallbackImage: {
            vehicle: data.vehicleDetailPageFallbackImagePattern,
            suv: data.suvDetailPageFallbackImagePattern,
        },
    }));
}

/**
 * @function getInventoryFavoritesInfo
 * @description Get the response data from fetching the Inventory Favorites on a given profile
 * and returns the `inventoryFavorites` object
 * @param {String} endpoint - Service endpoint
 * @param {Object} content - Object containing content key/value props
 * @param {Object} data - Data object
 * @param {String} sortByValue - String for the sortBy on inventoryFavorites
 * @param {Boolean} isVariant - (optional) is variant page
 * @param {Object} options - (optional) Overwrites fetch parameters
 * @returns {Object} An object with inventoryFavorites properties
 */
function getInventoryFavoritesInfo(endpoint, content, data, sortByValue, isVariant = false, options = {}) {
    if (isVariant) {
        const fetchOptions = { ...defaultOptions, ...options };

        return fetch(`${endpoint}${sortByValue.data}`, {
            headers: {
                ...fetchOptions.headers,
            },
            body: fetchOptions.body ? JSON.stringify(fetchOptions.body) : null,
            method: fetchOptions.method
        })
            .then((response) => response.json())
            .then((inventoryFavoritesData) =>
                parseInventoryFavoritesInfo(inventoryFavoritesData.result.inventoryFavorites, content, data)
            )
            .catch((err) => {
                console.warn(err);
                throw err;
            });
    } else {
        return authenticationFetchApi.authenticationFetch(`${endpoint}${sortByValue.data}`)
        .then((inventoryFavoritesData) =>
            parseInventoryFavoritesInfo(inventoryFavoritesData.result.inventoryFavorites, content, data)
        );
    }
}

/**
 * @function removeInventoryFavorite
 * @description Get the response data from removing the Inventory Favorites on a given profile
 * @param {String} endpoint - Service endpoint
 * @returns {Object} An object with inventoryFavorites properties
 */
function removeInventoryFavorite(endpoint) {
    return authenticationFetchApi.authenticationFetch(endpoint);
}

// Exports public api methods
export default {
    getInventoryFavoritesInfo,
    removeInventoryFavorite
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Local dependencies
import Picture from './Picture';

/**
 * @class PictureLoader
 * @description Responsible for loading a Picture asset and displaying
 * a loader spinner while the asset loads
 */
export default class PictureLoader {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration for a PictureLoader
     * @type {Object}
     * @const media {Object} media object - imgS: String, imgL: String, alt: String
     * @const fallbackMedia {Object} fallback media object - imgS: String, imgL: String, alt: String
     * @const className {String} class name for the picture loader element
     * @const itemProp {String} Value of schema `itemProp` markup
     * @const onPictureLoaded {Function} Callback to be executed when a picture's onLoad event is fired
     */
    static DEFAULT_CONFIG = {
        media: {},
        fallbackMedia: null,
        className: [],
        itemProp: null,
        onPictureLoaded: null
    };

    /**
     * @constructor Create a PictureLoader
     * @param config {Object} Configuration data
     */
    constructor(config = PictureLoader.DEFAULT_CONFIG) {
        this.config = {
            ...PictureLoader.DEFAULT_CONFIG,
            ...config
        };
        this.element = null;
        this.loader = null;
        this.picture = null;

        this.onLoad = this.onLoad.bind(this);

        this.createPictureLoader();
    }

    /**
     * @method createPictureLoader
     * @description Creates a PictureLoader
     */
    createPictureLoader() {
        this.element = document.createElement('div');
        this.element.classList.add('content-loader');

        if (this.config.className && this.config.className.length > 0) {
            const classNameArray = this.config.className.split(' ');
            for (let i = 0, length = classNameArray.length; i < length; i += 1) {
                this.element.classList.add(classNameArray[i]);
            }
        }

        this.createLoader();
        this.createPicture();
    }

    /**
     * @method createLoader
     * @description Creates a loading-spinner element and appends it to the pictureLoader
     */
    createLoader() {
        this.loader = document.createElement('div');
        this.loader.classList.add('content-loader__spinner');
        const spinner = document.createElement('div');
        spinner.classList.add('loading-spinner', 'loading-spinner--large');
        this.loader.appendChild(spinner);
        this.element.appendChild(this.loader);
    }

    /**
     * @method createPicture
     * @description Creates a `picture` element and appends it to the pictureLoader
     */
    createPicture() {
        const pictureContainer = document.createElement('div');
        pictureContainer.classList.add('content-loader__asset');

        this.picture = new Picture({
            media: this.config.media,
            fallbackMedia: this.config.fallbackMedia,
            onError: this.onLoad,
            onLoad: this.onLoad,
            itemProp: this.config.itemProp
        });

        pictureContainer.appendChild(this.picture.render());

        this.element.appendChild(pictureContainer);
    }

    /**
     * @method setPictureSource
     * @description Sets the image sources for the `picture` element
     * @param media {Object} media object
     */
    setPictureSource(media) {
        this.destroyLoader();
        this.createLoader();
        this.picture.setPictureSource(media);
    }

    /**
     * @method onLoad
     * @description On successful load of the picture,
     * remove the loading-spinner and execute any specified callback
     */
    onLoad() {
        this.destroyLoader();
        if (this.config.onPictureLoaded) {
            this.config.onPictureLoaded();
        }
    }

    /**
     * @method destroyLoader
     * @description Destroys the loading-spinner element by deleting it from the DOM
     */
    destroyLoader() {
        if (this.loader) {
            this.loader.remove();
            this.loader = null;
        }
    }

    /**
     * @method destroy
     * @description Destroys the element by deleting it from the DOM
     */
    destroy() {
        this.element.remove();
        this.element = null;
        this.loader = null;
        this.picture = null;
    }

    /**
     * @method render
     * @description Return the PictureLoader  element
     * @returns {Element} PictureLoader template element
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

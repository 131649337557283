// Import utils
import {
    formatDate,
    formatNumber
} from 'utils';

/**
 * @const FORMAT_TYPE
 * @description Collection of constant values for format types
 */
export const FORMAT_TYPE = {
    CURRENCY: 'currency',
    CURRENCY_INTEGER: 'currency-integer',
    PHONE: 'phone',
    PERCENTAGE: 'percentage',
    DATE: 'date',
    NUMERIC_COMMA_SEPARATED: 'numeric-comma-separated',
    NUMERIC_LOCALE_SEPARATED: 'numeric-locale-separated',
    SSN: 'ssn',
    CA_SSN: 'ca-ssn',
    SSN_MASK: 'ssn-mask',
    SSN_FULL_MASK: 'ssn-full-mask',
    CA_SSN_FULL_MASK: 'ca-ssn-full-mask',
    TAX_ID: 'tax-id',
    POSITIVE_VALUE: 'positive-value',
    CA_ZIP_CODE: 'ca-zip-code',
};

/**
 * @method formatCurrency
 * @description Formats a value to a currency format based on locale
 * @memberof inputFormatter
 * @param {number} value Number string to convert to a currency string
 * @param {Object} config Configuration options for formatting
 * @param {string} config.country Country code of currency
 * @param {string} config.language Language code of currency
 * @param {string} config.currencyCode Currency code of currency
 * @param {number} config.maxDecimalDigits Max decimal length to display for currency
 * @param {number} config.minDecimalDigits Min decimal length to display for currency
 * @return {string|*} The formatted currency value
 */
function formatCurrency(value, config) {
    const n = formatNumber.toNumber(value, config.country, config.language);
    const minDecimals = Math.min(Number.isInteger(n) ? 0 : 2, config.maxDecimalDigits);
    return formatNumber.toCurrency(
        n,
        config.country,
        config.language,
        config.currencyCode,
        config.maxDecimalDigits,
        minDecimals
    );
}

/**
 * @function formatCurrencyInteger
 * @description Formats a value to a currency format integer format without rounding the number based on locale
 * @memberof inputFormatter
 * @param {number} value Number string to convert to a currency string
 * @param {Object} config Configuration options for formatting
 * @param {string} config.country Country code of currency
 * @param {string} config.language Language code of currency
 * @param {string} config.currencyCode Currency code of currency
 * @return {*} The currency-integer-formatted value
 */
function formatCurrencyInteger(value, config) {
    const num = parseInt(formatNumber.toNumber(value, config.country, config.language), 10);

    if (isNaN(num)) {
        return value;
    }

    return formatNumber.toCurrency(
        num,
        config.country,
        config.language,
        config.currencyCode,
        0,
        0
    );
}

/**
 * @method formatCommaSeparated
 * @description Formats a value of numbers to a comma separated string of numbers
 * @memberof inputFormatter
 * @param {string} value The value to format
 * @return {string} The comma-separated string of numbers
 */
function formatCommaSeparated(value) {
    return formatNumber.toCommaSeparated(
        formatNumber.toNumber(value)
    );
}

/**
 * @method formatLocaleSeparated
 * @description Formats a value of numbers to a locale separated number
 * @memberof inputFormatter
 * @param {string} value The value to format
 * @param {Object} config Configuration options for formatting
 * @return {string} The locale separated string of numbers
 */
function formatLocaleSeparated(value, config) {
    return formatNumber.toStringNumber(
        formatNumber.toNumber(value, config.country, config.language),
        config.country,
        config.language,
    );
}

/**
 * @method formatPercentage
 * @description Formats a value to a percentage format based on locale
 * @memberof inputFormatter
 * @param {number} value Number string to convert to a percentage string
 * @param {Object} config Configuration options for formatting
 * @param {string} config.country Country code of percentage
 * @param {string} config.language Language code of percentage
 * @param {number} config.minimumFractionDigits specifies minimum fraction digits.
 * @return {string|*} The formatted percentage
 */
function formatPercentage(value, config) {
    const asNumber = formatNumber.toNumber(
        value,
        config.country,
        config.language
    );
    return formatNumber.toPercentage(
        asNumber / 100,
        config.country,
        config.language,
        config.minimumFractionDigits
    );
}

/**
 * @method formatToPositiveValue
 * @description If negative, changes the passed value argument to a positve number.
 * If config toPercentage is present, the returned value will be formatted to a
 * percentage, otherwise it will be formatted to currency.
 * @param {String} value the number to be formatted
 * @param {config} config options required by the formatNumber utility
 * @returns {String}
 */
function formatToPositiveValue(value, config) {
    let positiveValue = value.replace('-', '');

    positiveValue = formatNumber.toNumber(
        positiveValue,
        config.country,
        config.language
    );

    if (config.toPercentage) {
        return formatNumber.toPercentage(
            positiveValue / 100,
            config.country,
            config.language,
            config.minimumFractionDigits
        );
    }

    return formatCurrency(positiveValue, config);
}

/**
 * @method format
 * @description Formats a value based on formatType
 * @memberof inputFormatter
 * @param {string} value Value of input
 * @param {string} formatType FORMAT_TYPE to format value to
 * @param {Object} formatConfig Configuration options for formatting value
 * @return {string} The formatted value
 */
export function format(value, formatType, formatConfig) {
    let formattedValue = value;

    if (formatConfig && formatConfig.preserveBlank && isNaN(parseFloat(value.trim(), 10))) {
        return value;
    }

    if (formatType === FORMAT_TYPE.CURRENCY) {
        formattedValue = formattedValue.replace('$', '');
        formattedValue = formatCurrency(formattedValue, formatConfig);
    } else if (formatType === FORMAT_TYPE.CURRENCY_INTEGER) {
        formattedValue = formatCurrencyInteger(formattedValue, formatConfig);
    } else if (formatType === FORMAT_TYPE.PHONE) {
        formattedValue = formatNumber.toPhone(
            formatNumber.toNumber(formattedValue)
        );
    } else if (formatType === FORMAT_TYPE.PERCENTAGE) {
        formattedValue = formatPercentage(formattedValue, formatConfig);
    } else if (formatType === FORMAT_TYPE.DATE) {
        formattedValue = formatDate.toFormattedDate(formattedValue);
    } else if (formatType === FORMAT_TYPE.SSN) {
        formattedValue = formatNumber.toSSN(formattedValue);
    } else if (formatType === FORMAT_TYPE.CA_SSN) {
        formattedValue = formatNumber.toCASSN(formattedValue);
    } else if (formatType === FORMAT_TYPE.SSN_MASK) {
        formattedValue = formatNumber.toSSNMask(formattedValue);
    } else if (formatType === FORMAT_TYPE.SSN_FULL_MASK) {
        formattedValue = formatNumber.toSSNMask(formattedValue, true);
    } else if (formatType === FORMAT_TYPE.CA_SSN_FULL_MASK) {
        formattedValue = formatNumber.toCASSNMask(formattedValue, true);
    } else if (formatType === FORMAT_TYPE.TAX_ID) {
        formattedValue = formatNumber.toTaxID(formattedValue);
    } else if (formatType === FORMAT_TYPE.NUMERIC_COMMA_SEPARATED) {
        formattedValue = formatCommaSeparated(formattedValue);
    } else if (formatType === FORMAT_TYPE.NUMERIC_LOCALE_SEPARATED) {
        formattedValue = formatLocaleSeparated(formattedValue, formatConfig);
    } else if (formatType === FORMAT_TYPE.CA_ZIP_CODE) {
        formattedValue = formatNumber.toCAZipCode(formattedValue);
    } else if (formatType === FORMAT_TYPE.POSITIVE_VALUE) {
        formattedValue = formatToPositiveValue(formattedValue, formatConfig);
    }

    return formattedValue;
}

/**
 * export inputFormatter public api
 */
export default {
    FORMAT_TYPE,
    format
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

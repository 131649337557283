/**
 * Input validation methods that are specific to the Credit Application
 */

/**
 * @function mergeValidation
 * @description
 * Merges validations for input when conditional is needed to determine what validations an input should have
 * @param {Array} defaultValidation Array of validations that are default for given input
 * @param {Object} conditionalValidation Validation object that is pushed to array dependent on conditional
 * @param {boolean} mergeConditional Indicator for whether conditionalValidation should be pushed to defaultValidation
 * @return {Array} Array of validations for form input
 */
function mergeValidation(defaultValidation, conditionalValidation, mergeConditional) {
    const mergedValidations = [...defaultValidation];
    if (mergeConditional) {
        mergedValidations.push(conditionalValidation);
    }

    return mergedValidations;
}

export default {
    mergeValidation,
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

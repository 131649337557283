/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 * @todo Add data-analytics-container to dealer results container
 * @todo Add data-preferred-dealer to preferred dealer
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying dealer locator search module
 * @param moduleHeading {String} Heading for dealer search
 * @param {String} showMapCTA - Show Map CTA for small screens
 * @param browseAllCTA {String} Secondary CTA for browse all
 * @param flags {Object} Config flags to display elements
 */
export default (subtext, changeCTA) => htmlNode`
    <div class="dealer-change">
        <p class="dealer-change__copy">${subtext}</p>
        <button class="link link_plain-link dealer-change__cta">${changeCTA}</button>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

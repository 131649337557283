// Util dependencies
import {
    storage,
    validate
} from 'utils';

// Partial dependencies
import { UserProfile } from 'partials/profile';

// Local dependencies
import { CONSTANTS } from '../config';

/**
 * Symbols for Private Methods and Properties
 * @global
 */
const legacyUserProfile = Symbol('legacyUserProfile');
const dataObj = Symbol('dataObj');
const config = Symbol('config');
const createDataObj = Symbol('createDataObj');
const setPriorityList = Symbol('setPriorityList');
const getPreferredDealer = Symbol('getPreferredDealer');
const setPreferredDealer = Symbol('setPreferredDealer');
const getLastSearchedLocation = Symbol('getLastSearchedLocation');
const setLastSearchedLocation = Symbol('setLastSearchedLocation');
const getLastSelectedDealer = Symbol('getLastSelectedDealer');
const setLastSelectedDealer = Symbol('setLastSelectedDealer');
const getLegacyProperty = Symbol('getLegacyProperty');
const setLegacyProperty = Symbol('setLegacyProperty');
const removeDataItem = Symbol('removeDataItem');

let instance = null;
const singletonEnforcer = Symbol('singletonEnforcer');

/**
 * @constant emptyContract
 * @description empty contract object, serves as fallback
 * @type {Object}
 */
const emptyContract = {
    data: null,
    receievedFrom: '',
    type: ''
};

/**
 * @constant defaultConfig
 * @description default prioritization config, serves as fallback
 * @type {Object}
 */
const defaultConfig = {};

export function clearDealerLocationPrioritization() {
    instance = null;
}

export default class DealerLocationPrioritization {
    /**
     * @method getInstance
     * @description static function to get Singleton instance of DealerLocationPrioritization class
     * @returns {Object} DealerLocationPrioritization instance
     * @static
     */
    static getInstance() {
        if (!instance) {
            instance = new DealerLocationPrioritization(singletonEnforcer);
        }
        return instance;
    }

    static DATA_TYPES = CONSTANTS.PRIORITIZED_DATA_TYPES;

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error('Singleton; do not instantiate');
        }

        this[legacyUserProfile] = new UserProfile();
        this[dataObj] = this[createDataObj]();
        this[config] = window.mbVans.ns('applicationProperties').dealerLocationPrioritizationConfig || defaultConfig;

        if (validate.isEmpty(this[config])) {
            console.info('Dealers & Locations Prioritization JSON not found');
        }
    }

    /**
     * @method createDataObj
     * @description Private function that creates the priority data types object
     * @returns {Object} Each priority data type is set to null
     * @private
     */
    [createDataObj]() {
        return Object.keys(CONSTANTS.PRIORITIZED_DATA_TYPES)
        .reduce((object, key) => ({ ...object, [CONSTANTS.PRIORITIZED_DATA_TYPES[key]]: null }), {});
    }

    /**
     * @method setPriorityList
     * @description Private function that gets the priority list of the feature and specificity from the config
     * @returns {Array} Prioritized data types
     * @private
     */
    [setPriorityList](feature, specificity = 'default') {
        if (this[config] && this[config][feature]) {
            return this[config][feature][specificity] || [];
        }

        // incase error with config, or `feature` isn't passed
        return [];
    }

    /**
     * @method getPreferredDealer
     * @description Private function that gets the preferred dealer localstorage item
     * or the legacy item for preferred dealer and formats it to an object
     * @returns {Object} Preferred Dealer data, type, and received from
     * @private
     */
    [getPreferredDealer]() {
        const {
            PRIORITIZED_DATA_TYPES: { PREFERRED_DEALER },
            STORAGE: { PREFERRED_DEALER_LOCALSTORAGE }
        } = CONSTANTS;

        const lsItem = storage.localStorage.read(PREFERRED_DEALER_LOCALSTORAGE) || null;
        const userProfileLSItem = this[getLegacyProperty](PREFERRED_DEALER);
        let PDData = null;

        if (lsItem) {
            PDData = {
                type: 'dealer',
                data: lsItem,
                receievedFrom: PREFERRED_DEALER
            };
        } else if (userProfileLSItem) {
            // set to new LS item
            this[setPreferredDealer](userProfileLSItem);

            PDData = {
                type: 'dealer',
                data: userProfileLSItem,
                receievedFrom: PREFERRED_DEALER
            };
        }

        this[dataObj][PREFERRED_DEALER] = PDData;

        return PDData;
    }

    /**
     * @method setPreferredDealer
     * @description Private function that sets or updates the preferred dealer
     * to localstorage and formats it to an object
     * @param {String} newDealer Dealer data to be set
     * @param {Boolean} setLegacy Property that tells us to set legacy options as well
     * @returns {Object} Preferred Dealer data, type, and received from
     * @private
     */
    [setPreferredDealer](newDealer, setLegacy) {
        const {
            PRIORITIZED_DATA_TYPES: { PREFERRED_DEALER },
            STORAGE: { PREFERRED_DEALER_LOCALSTORAGE }
        } = CONSTANTS;

        const lsItem = storage.localStorage.read(PREFERRED_DEALER_LOCALSTORAGE) || null;

        if (setLegacy) {
            this[setLegacyProperty](PREFERRED_DEALER, newDealer);
        }

        if (!lsItem) {
            // create from scratch if no item exists
            storage.localStorage.create(
                PREFERRED_DEALER_LOCALSTORAGE,
                newDealer
            );
        } else {
            // update item
            storage.localStorage.update(
                PREFERRED_DEALER_LOCALSTORAGE,
                newDealer
            );
        }

        this[dataObj][PREFERRED_DEALER] = {
            type: 'dealer',
            data: newDealer,
            receievedFrom: PREFERRED_DEALER
        };

        return this[dataObj][PREFERRED_DEALER];
    }

    /**
     * @method getLastSearchedLocation
     * @description Private function that gets the last searched location localstorage
     * item or the legacy item for last searched location and formats it to an object
     * @returns {Object} Last searched location data, type, and received from
     * @private
     */
    [getLastSearchedLocation]() {
        const {
            PRIORITIZED_DATA_TYPES: { LAST_SEARCHED_LOCATION },
            STORAGE: {
                LAST_SEARCHED_LOCATION_LOCALSTORAGE,
                LAST_LOCATION_PROFILE_LS
            }
        } = CONSTANTS;

        const lsItem = storage.localStorage.read(LAST_SEARCHED_LOCATION_LOCALSTORAGE) || null;
        const userProfileLSItem = this[getLegacyProperty](LAST_LOCATION_PROFILE_LS);
        let LSLData = null;

        if (lsItem && lsItem.searchLocation) {
            LSLData = {
                type: lsItem.searchByType,
                data: lsItem.searchLocation,
                receievedFrom: LAST_SEARCHED_LOCATION
            };
        } else if (userProfileLSItem && userProfileLSItem.searchLocation) {
            // set to new LS item
            this[setLastSearchedLocation](userProfileLSItem.searchLocation, userProfileLSItem.searchByType);

            LSLData = {
                type: userProfileLSItem.searchByType,
                data: userProfileLSItem.searchLocation,
                receievedFrom: LAST_SEARCHED_LOCATION
            };
        }

        this[dataObj][LAST_SEARCHED_LOCATION] = LSLData;

        return LSLData;
    }

    /**
     * @method setLastSearchedLocation
     * @description Private function that sets or updates the last searched
     * location to localstorage and formats it to an object
     * @param {String} newLocation Location data to be set
     * @param {String} searchByType Location data type to be set
     * @param {Boolean} setLegacy Property that tells us to set legacy options as well
     * @returns {Object} Last searched location data, type, and received from
     * @private
     */
    [setLastSearchedLocation](newLocation, searchByType, setLegacy) {
        const {
            PRIORITIZED_DATA_TYPES: { LAST_SEARCHED_LOCATION },
            STORAGE: { LAST_SEARCHED_LOCATION_LOCALSTORAGE }
        } = CONSTANTS;

        const lsItem = storage.localStorage.read(LAST_SEARCHED_LOCATION_LOCALSTORAGE) || null;

        const now = new Date();
        const oneWeek = new Date(now.setDate(now.getDate() + 7));
        const newLocationData = {
            searchLocation: newLocation,
            searchByType
        };

        if (setLegacy) {
            this[setLegacyProperty](LAST_SEARCHED_LOCATION, newLocationData);
        }

        // create from scratch if no item exists
        if (!lsItem) {
            storage.localStorage.create(
                LAST_SEARCHED_LOCATION_LOCALSTORAGE,
                newLocationData,
                oneWeek
            );
        } else {
            // update item
            storage.localStorage.update(
                LAST_SEARCHED_LOCATION_LOCALSTORAGE,
                newLocationData
            );
        }

        this[dataObj][LAST_SEARCHED_LOCATION] = {
            type: searchByType,
            data: newLocation,
            receievedFrom: LAST_SEARCHED_LOCATION
        };

        return this[dataObj][LAST_SEARCHED_LOCATION];
    }

    /**
     * @method getLastSelectedDealer
     * @description Private function that gets the last selected dealer localstorage
     * item or the legacy item for last selected dealer and formats it to an object
     * @returns {Object} Last selected dealer data, type, and received from
     * @private
     */
    [getLastSelectedDealer]() {
        const {
            PRIORITIZED_DATA_TYPES: { LAST_SELECTED_DEALER },
            STORAGE: { LAST_SELECTED_DEALER_LOCALSTORAGE }
        } = CONSTANTS;

        const lsItem = storage.localStorage.read(LAST_SELECTED_DEALER_LOCALSTORAGE) || null;

        if (lsItem && lsItem.dealerId) {
            const LSDData = {
                type: 'dealer',
                data: lsItem,
                receievedFrom: LAST_SELECTED_DEALER
            };

            this[dataObj][LAST_SELECTED_DEALER] = LSDData;

            return LSDData;
        }

        return null;
    }

    /**
     * @method setLastSelectedDealer
     * @description Private function that sets or updates the last selected
     * dealer to localstorage and formats it to an object
     * @param {String} newDealer Dealer data to be set
     * @returns {Object} Last selected dealer data, type, and received from
     * @private
     */
    [setLastSelectedDealer](newDealer) {
        const {
            STORAGE: { LAST_SELECTED_DEALER_LOCALSTORAGE },
            PRIORITIZED_DATA_TYPES: { LAST_SELECTED_DEALER }
        } = CONSTANTS;

        const lsItem = storage.localStorage.read(LAST_SELECTED_DEALER_LOCALSTORAGE) || null;

        const now = new Date();
        const oneWeek = new Date(now.setDate(now.getDate() + 7));

        // create from scratch if no item exists
        if (!lsItem) {
            storage.localStorage.create(
                LAST_SELECTED_DEALER_LOCALSTORAGE,
                newDealer,
                oneWeek
            );
        } else {
            // update item
            storage.localStorage.update(
                LAST_SELECTED_DEALER_LOCALSTORAGE,
                newDealer
            );
        }

        this[dataObj][LAST_SELECTED_DEALER] = {
            type: 'dealer',
            data: newDealer,
            receievedFrom: LAST_SELECTED_DEALER
        };

        return this[dataObj][LAST_SELECTED_DEALER];
    }

    /**
     * @method removeDataItem
     * @description Private function that removes the specified item out of localstorage
     * @param {String} tyoe Property to be removes
     * @param {String} setLegacy Property to be tell if there are legacy options to remove
     * @private
     */
    [removeDataItem](type, setLegacy) {
        const {
            STORAGE,
            PRIORITIZED_DATA_TYPES
        } = CONSTANTS;

        if (setLegacy) {
            this[setLegacyProperty](type, null);
        }

        switch (type) {
        case PRIORITIZED_DATA_TYPES.LAST_SELECTED_DEALER:
            storage.localStorage.delete(STORAGE.LAST_SELECTED_DEALER_LOCALSTORAGE);
            break;
        case PRIORITIZED_DATA_TYPES.LAST_SEARCHED_LOCATION:
            storage.localStorage.delete(STORAGE.LAST_SEARCHED_LOCATION_LOCALSTORAGE);
            break;
        case PRIORITIZED_DATA_TYPES.PREFERRED_DEALER:
            storage.localStorage.delete(STORAGE.PREFERRED_DEALER_LOCALSTORAGE);
            break;
        default:
            break;
        }
    }

    /**
     * @method getLegacyProperty
     * @description Retrieve a property via legacy means
     * @param {String} prop Property to be retrieved
     * @param {String} src Source of property to be retrieved
     * @param {String} type Type of storage from which to retrieve property
     * @private
     */
    [getLegacyProperty](prop, src = CONSTANTS.STORAGE.USER_PROFILE_LOCALSTORAGE, type = CONSTANTS.TYPE.LOCAL_STORAGE) {
        let item;

        switch (type) {
        case CONSTANTS.TYPE.LOCAL_STORAGE:
            item = storage.localStorage.read(src) || null;
            if (item && item[prop]) {
                return item[prop];
            }
            break;
        default:
            break;
        }
        return null;
    }

    /**
     * @method setLegacyProperty
     * @description Store a property via legacy means
     * @param {String} type Type of legacy storage to perform
     * @param {String} args Legacy data to be stored
     * @private
     */
    [setLegacyProperty](type, args) {
        const {
            PRIORITIZED_DATA_TYPES
        } = CONSTANTS;

        switch (type) {
        case PRIORITIZED_DATA_TYPES.LAST_SEARCHED_LOCATION:
            this[legacyUserProfile].setLastLocation(args);
            break;
        case PRIORITIZED_DATA_TYPES.PREFERRED_DEALER:
            this[legacyUserProfile].setPreferredDealer(args);
            break;
        default:
            break;
        }
    }

    /**
     * @method get
     * @description Get the first dealer or location data available from the priority list
     * @param {String} feature Property of the feature in the config
     * @param {String} specificity Property of the specificity in the config or 'default'
     * @returns {Object|null} Priority dealer or location data, data type and received from or null
     */
    get(feature, specificity = 'default') {
        const { PRIORITIZED_DATA_TYPES } = CONSTANTS;
        let priorityData;
        const priorityList = this[setPriorityList](feature, specificity);

        priorityList.find(((priority) => {
            switch (priority) {
            case PRIORITIZED_DATA_TYPES.PREFERRED_DEALER:
                priorityData = this[getPreferredDealer]();
                break;
            case PRIORITIZED_DATA_TYPES.LAST_SEARCHED_LOCATION:
                priorityData = this[getLastSearchedLocation]();
                break;
            case PRIORITIZED_DATA_TYPES.LAST_SELECTED_DEALER:
                priorityData = this[getLastSelectedDealer]();
                break;
            default:
                break;
            }
            return priorityData;
        }));

        return priorityData || emptyContract;
    }

    /**
     * @method set
     * @description Set the given dealer or location data to localstorage
     * @param {String} dataType Type of data to be set
     * @param {String|Object} args Data necessary to set an item in localstorage
     * @param {Boolean} setLegacy Should the data be set in legacy option
     * @returns {Object} Priority dealer or location data, data type and received from
     * @private
     */
    set(dataType, args, setLegacy = false) {
        if (!Array.isArray(args)) {
            args = [args];
        }
        if (args[0] === null) {
            this[removeDataItem](dataType, setLegacy);
            return;
        }
        const { PRIORITIZED_DATA_TYPES } = CONSTANTS;
        switch (dataType) {
        case PRIORITIZED_DATA_TYPES.PREFERRED_DEALER:
            this[setPreferredDealer](...args, setLegacy);
            break;
        case PRIORITIZED_DATA_TYPES.LAST_SELECTED_DEALER:
            this[setLastSelectedDealer](...args);
            break;
        case PRIORITIZED_DATA_TYPES.LAST_SEARCHED_LOCATION:
            this[setLastSearchedLocation](...args, setLegacy);
            break;
        default:
            break;
        }
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constants
import { EVENTS } from 'Constants';

// Module dependencies
import { renderer } from 'utils';

// Local dependencies
import indicatorNavigationTemplate from '../templates/navigationIndicatorTemplate';
import indicatorNavigationItemTemplate from '../templates/navigationIndicatorItemTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{SLIDE: string, ACTIVE: string}}
 */
const CLASSES = {
    INDICATOR: 'carousel-nav__indicator-dot',
    INDICATOR_HIDDEN: 'carousel-nav__indicator-list-item--hidden',
    INDICATOR_SELECTED: 'carousel-nav__indicator-dot--selected',
    INDICATOR_SIZE_SMALL: 'carousel-nav__indicator-dot--small',
    INDICATOR_SIZE_MEDIUM: 'carousel-nav__indicator-dot--medium',
};

/**
 * @class indicators
 * @description View component for displaying carousel  'dots' or indicators
 */
export default class Indicators {
    /**
     * @constructor
     * @description On instantiation, sets properties, creates nav elements, and attached events
     * @param config {Object} configuration object with reference to parent nav element
     */
    constructor(config) {
        this.currentIndex = config.currentIndex;
        this.prevIndex = this.currentIndex;

        this.setActiveSlide = config.setActiveSlide;
        this.totalCount = config.totalCount;
        this.lastVisibleIndex = null;

        this.maxIndicators = 7;
        this.minIndex = 0;
        this.maxIndex = 0;

        this.toggleSelectedDot = this.toggleSelectedDot.bind(this);

        this.indicatorNav = renderer.fromTemplate(indicatorNavigationTemplate({
            isStatic: this.totalCount <= this.maxIndicators,
            indicatorPositionBottom: config.indicatorPositionBottom,
        }));
        this.indicatorNavList = this.indicatorNav.childNodes[1];

        this.createNavigationIndicators();
        this.attachEvents();
    }

    /**
     * @method createNavigationIndicators
     * @description Create the navigation indicators element and set the initial position and size
     */
    createNavigationIndicators() {
        for (let i = 0; i < this.totalCount; i += 1) {
            const li = renderer.fromTemplate(indicatorNavigationItemTemplate(i));
            if (i === this.currentIndex) {
                li.children[0].classList.add(CLASSES.INDICATOR_SELECTED);
            }
            this.indicatorNavList.appendChild(li);
        }

        this.transitionIndicators();
    }


    /**
     * @method attachEvents
     * @description Iterates each of the button elements and applies click event
     * listeners and a callback for when clicked
     */
    attachEvents() {
        [...this.indicatorNavList.children].forEach((li) => {
            li.addEventListener(EVENTS.CLICK, this.onIndicatorClick.bind(this));
        });
    }

    /**
     * @method detachEvents
     * @description Iterates each of the button elements and removes all events
     * listeners and a callbacks
     */
    detachEvents() {
        [...this.indicatorNavList.children].forEach((li) => {
            li.removeEventListener(EVENTS.CLICK, this.onIndicatorClick);
        });
    }

    /**
     * @method onIndicatorClick
     * @description Event handler for the indicator dots
     * @param event
     */
    onIndicatorClick(event) {
        const liIndex = event.target.dataset.index !== '' ? Number(event.target.dataset.index) : 0;
        // console.log(`liIndex:${liIndex} currentIndex:${this.currentIndex}`);
        if (this.currentIndex !== liIndex && !Number.isNaN(liIndex)) {
            this.setActiveSlide(liIndex, this.currentIndex);
        }
    }

    /**
     * @method toggleSelectedDot
     * @description toggle selected class indicator dot
     * @param selectedIndex {Number} current index
     */
    toggleSelectedDot(selectedIndex) {
        const dots = this.indicatorNavList.children;
        const prevIndex = this.currentIndex;

        // remove class from dots
        [...dots].forEach((dot) => dot.children[0].classList.remove(CLASSES.INDICATOR_SELECTED));
        // add class to selected dot
        dots[selectedIndex].children[0].classList.add(CLASSES.INDICATOR_SELECTED);

        // update to correct this.currentIndex
        this.prevIndex = prevIndex;
        this.currentIndex = selectedIndex;

        this.transitionIndicators();
    }

    /**
     * @method getLastVisibleIndex
     * @description gets the last visible index for indicators. used to determine
     *              when to disable the next button
     * @returns {Number}
     */
    getLastVisibleIndex() {
        return this.lastVisibleIndex;
    }

    /**
     * @method suppressLastDots
     * @description allows for a dynamic indicator nav under the carousel, hides any extra dots
     * @param numberDots {Number} the number of dots to suppress
     */
    suppressLastDots(numberDots) {
        const dots = this.indicatorNavList.children;

        if (dots.length > 3) { // don't suppress any dots if less than 3 available
            this.lastVisibleIndex = dots.length - numberDots;

            [...dots].forEach((dot, index) => {
                const dotElm = dot.children[0];
                /**
                 * if the last selected item is a dot that exists beyond the last available index, select
                 * the last available index instead by applying the relevant class
                 */
                if (dotElm.classList.contains(CLASSES.INDICATOR_SELECTED) && index >= this.lastVisibleIndex) {
                    dotElm.classList.remove(CLASSES.INDICATOR_SELECTED);
                    dots[this.lastVisibleIndex - 1].children[0].classList.add(CLASSES.INDICATOR_SELECTED);
                    this.setActiveSlide(this.lastVisibleIndex - 1, index);
                }

                // hide dots according to the lastVisibleIndex, ignore hiding first 3 tiles
                if (index >= this.lastVisibleIndex && this.lastVisibleIndex > 3) {
                    dot.classList.add(CLASSES.INDICATOR_HIDDEN);
                } else {
                    dot.classList.remove(CLASSES.INDICATOR_HIDDEN);
                }
            });
        }
    }

    /**
     * @method transitionIndicators
     * @description Calculates and transitions the indicator dots into view based on the
     * currentIndex, the max number of indicators to display and what the mid point is.
     */
    transitionIndicators() {
        const dots = [...this.indicatorNavList.children];
        const dotWidth = dots[0].getBoundingClientRect().width;
        const isForwardDirection = this.currentIndex > this.prevIndex &&
            !(this.prevIndex === 0 && this.currentIndex === (dots.length - 1));

        if (this.totalCount <= this.maxIndicators) {
            return;
        }

        if (this.minIndex === 0 && this.maxIndex === 0) {
            // special case - handle if index from config.currentIndex !== 0 to set the min and max indexes
            if (this.currentIndex === dots.length - 1) {
                this.maxIndex = this.currentIndex;
                this.minIndex = this.maxIndex - (this.maxIndicators - 1);
            } else {
                this.minIndex = this.currentIndex - 1 < 0 ? 0 : this.currentIndex - 1;
                this.maxIndex = (this.minIndex + (this.maxIndicators - 1)) > (dots.length - 1) ?
                    dots.length - 1 :
                    this.minIndex + (this.maxIndicators - 1);
            }
        }

        let midIndex = (this.minIndex + this.maxIndex) / 2;

        if (isForwardDirection) {
            if (this.currentIndex > midIndex && this.currentIndex < this.totalCount - 2) {
                // if the current index is past the mid index and less than the 2nd to last indicator
                // shift the the indicators left and set the current index as the third to last position
                this.minIndex = this.currentIndex - (this.maxIndicators - 3);
                this.maxIndex = this.currentIndex + 2;
            } else if (this.currentIndex === this.maxIndex && this.currentIndex !== dots.length - 1) {
                // if the current index equal to the max index and not the last indicator
                // shift the the indicators left and set the current index as the second to last position
                this.minIndex = this.currentIndex - (this.maxIndicators - 2);
                this.maxIndex = this.currentIndex + 1;
            }
        } else if (!isForwardDirection && (this.currentIndex > 1 && this.currentIndex < midIndex)) {
            // if the current index the 3rd indicator or greater and is before the mid index
            // shift the the indicators right and set the current index as the third position
            this.minIndex = this.currentIndex - 2;
            this.maxIndex = this.minIndex + (this.maxIndicators - 1);
        } else if (!isForwardDirection && (this.currentIndex === this.minIndex && this.currentIndex !== 0)) {
            // if the current index equal to the min index and not the first indicator
            // shift the the indicators right and set the current index as the second position
            this.minIndex = this.currentIndex - 1;
            this.maxIndex = this.minIndex + (this.maxIndicators - 1);
        } else if (!isForwardDirection && this.currentIndex === 0) {
            // if the current index is the first indicator
            // shift to the first indicator
            this.minIndex = 0;
            this.maxIndex = this.maxIndicators - 1;
        } else if (!isForwardDirection && (this.currentIndex === dots.length - 1)) {
            // if the current index is the last indicator
            // shift to the last indicator
            this.minIndex = this.currentIndex - (this.maxIndicators - 1);
            this.maxIndex = this.currentIndex;
        }

        // update the midIndex based on the new min and max indexes
        midIndex = (this.minIndex + this.maxIndex) / 2;

        // set the position based on the midIndex
        const position = (((dotWidth * this.maxIndicators) - dotWidth) / 2) - (midIndex * dotWidth);

        this.indicatorNavList.style.transform = `translateX(${position}px)`;

        this.setSize();
    }

    /**
     * @method setSize
     * @description Sets the size of the indicator dots based on the current minIndex and maxIndex
     */
    setSize() {
        // if the total number of indicators is less than the max indicator
        // leave the dots at the default size
        if (this.totalCount < this.maxIndicators) {
            return;
        }

        const dots = [...this.indicatorNavList.children];

        dots.forEach((dot, index) => {
            const dotButton = dot.querySelector('button');
            const isActive = index === this.currentIndex;

            dotButton.classList.remove(CLASSES.INDICATOR_SIZE_SMALL);
            dotButton.classList.remove(CLASSES.INDICATOR_SIZE_MEDIUM);

            if (!isActive &&
                (
                    (this.minIndex > 1 && index === this.minIndex + 1) ||
                    (this.minIndex === 1 && index === this.minIndex) ||
                    (this.maxIndex < this.totalCount - 2 && index === this.maxIndex - 1) ||
                    (this.maxIndex === this.totalCount - 2 && index === this.maxIndex)
                )
            ) {
                // if only first dot is out of visibility - set the first visible dot to medium
                // if second dot is out of visibility - set the second visible dot to medium
                // if last dot is out of visibility - set the last visible dot to medium
                // if second to last dot is out of visibility - set the second to last visible dot to medium
                dotButton.classList.add(CLASSES.INDICATOR_SIZE_MEDIUM);
            } else if (!isActive &&
                (
                    (this.minIndex > 1 && index <= this.minIndex) ||
                    (this.maxIndex < this.totalCount - 1 && index >= this.maxIndex)
                )
            ) {
                // if second dot is out of visibility - set the first visible dot to small
                // if second to last dot is out of visibility - set the last visible dot to small
                dotButton.classList.add(CLASSES.INDICATOR_SIZE_SMALL);
            }
        });
    }

    /**
     * @method render
     * @description Retrieves the indicatorNav DOM element
     * @return {Element|null} The indicatorNav DOM element
     */
    render() {
        return this.indicatorNav;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import VirtualKeyboard from './views/VirtualKeyboard';
import { emailKeyboardConfig } from './config/emailKeyboardLayout';
import { defaultKeyboardConfig } from './config/defaultKeyboardLayout';

export {
    VirtualKeyboard,
    emailKeyboardConfig,
    defaultKeyboardConfig,
};

export default {
    VirtualKeyboard,
    emailKeyboardConfig,
    defaultKeyboardConfig,
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @static AGREEMENT_TYPES
 * @description agreement status values
 */
export default {
    PRIVACY_NOTICE: 'privacyNotice',
    STATE_DISCLOSURES: 'stateDisclosure'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for accordion expand all / collapse all
 * Uses ES6 template literal to composite the markup for a accordion expand all / collapse all
 */
import { html } from 'utils';

export default (labels) => html`
    <ul class="accordion__expand-collapse-all-container">
        <li class="accordion__expand-collapse-all">
            <button data-expand-all class="link link_bold">
                ${labels.expandAll}
            </button>
        </li>
        
        <li class="accordion__expand-collapse-all">
            <button data-collapse-all class="link link_bold">
                ${labels.collapseAll}
            </button>
        </li>
    </ul>
`.trim();
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

export default (content, config) => htmlNode`
    <div class="urgent-recalls-parts">
        <div class="urgent-recalls-parts__check hide"></div>
        <div class="urgent-recalls-parts__parts-status"></div>
        <div class="urgent-recalls-parts__contact">${content.partsAvailability.contact}</div>
        <div class="urgent-recalls-parts__address">${content.partsAvailability.address}</div>
        <a href="${config.dealerLocatorPage}" class="urgent-recalls-parts__cta button button_primary button--wide">${content.partsAvailability.cta}</a>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * TextBlock
 * Has methods related to composition of the TextBlock module
 */

import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import { customEventDispatcher } from 'utils';

const SELECTORS = {
    ANCHOR: 'a',
    SPAN: 'span'
};

const ATTRIBUTES = {
    ID: 'id'
};

const {
    isOnDisplay
} = window.mbVans.ns('pageData').variantInfo;

export default class TextBlock {
    constructor(element) {
        this.element = element;
        this.inlineAnchorLinks = [];
        this.init();
    }

    /**
     * init()
     * calls initial functions
     */
    init() {
        this.setBindings();
        this.cacheDOM();
        if (this.inlineAnchor) this.groomAnchor();
        if (this.inlineAnchorLinks.length > 0) this.groomLinks();
    }

    setBindings() {
        this.onClick = this.onClick.bind(this);
    }

    cacheDOM() {
        this.inlineAnchorLinks = [...this.element.querySelectorAll(SELECTORS.ANCHOR)];
        this.inlineAnchor = this.element.querySelector(`${SELECTORS.ANCHOR}[${ATTRIBUTES.ID}]`);
    }

    groomAnchor() {
        this.element.setAttribute(ATTRIBUTES.ID, this.inlineAnchor.getAttribute(ATTRIBUTES.ID));
        this.inlineAnchor.remove();
        this.inlineAnchor = null;
    }

    groomLinks() {
        if (isOnDisplay) {
            this.removeLinks();
        } else {
            this.attachEvents();
        }
    }

    attachEvents() {
        this.inlineAnchorLinks.forEach((inlineAnchorLink) => {
            if (inlineAnchorLink.hash &&
                inlineAnchorLink.attributes.href.value &&
                inlineAnchorLink.hash === inlineAnchorLink.attributes.href.value &&
                inlineAnchorLink.attributes.href.value !== '#disclaimer-drawer') {
                inlineAnchorLink.addEventListener(EVENTS.CLICK, this.onClick);
            }
        });
    }

    removeLinks() {
        this.inlineAnchorLinks.forEach((inlineAnchorLink) => {
            const spanText = document.createElement(SELECTORS.SPAN);
            spanText.innerHTML = `${inlineAnchorLink.innerHTML}`;
            inlineAnchorLink.parentNode.replaceChild(spanText, inlineAnchorLink);
        });
    }

    onClick(e) {
        e.preventDefault();
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.STICKY_NAV_TRIGGER,
                { detail: { hash: e.target.hash }
                }
            )
        );
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @static EMPLOYMENT_TYPES
 * @description employment status values
 */
export default {
    SELECT: '',
    ACTIVE_MILITARY: 'ActiveMilitary',
    EMPLOYED: 'Employed',
    UNEMPLOYED: 'Unemployed',
    RETIRED: 'Retired',
    RETIRED_MILITARY: 'RetiredMilitary',
    OTHER: 'Other',
    STUDENT: 'Student',
    SELF_EMPLOYED: 'SelfEmployed'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * @function renderAddress
 * @description Renders each list item for the badges associated with the dealer.
 * This is built by using the the badge content and filtering out the filter objects.
 * @param dealer {Object} dealer object with content info needed for rendering within address
 */
function renderAddress(dealer) {
    return  `<address>
        <div class="dealer-detail__address">
            ${dealer.address} <br />
            ${dealer.city}, ${dealer.state}, ${dealer.zip}
        </div>
    </address>`;
}

/**
 * @function renderBadges
 * @description Renders each list item for the badges associated with the dealer.
 * This is built by using the the badge content and filtering out the filter objects.
 */
function renderBadges(filtersContent, badges) {
    return filtersContent
        .filter((badge) => badges.includes(badge.value))
        .map((badge) => `
            <li class="dealer-detail__tag">
                <span class="dealer-result__badge">${badge.label}</span>
            </li>
        `)
        .join('');
}

/**
 * @function renderPhoneNumbers
 * @description Renders the markup necessary for phone numbers
 */
function renderPhoneNumbers(contact, content, isDealership) {
    // const phoneSet = ['phone', 'servicePhone', 'partsPhone'];
    const phoneSet = ['phone'];
    return contact.reduce((markup, c) => {
        if (phoneSet.indexOf(c.type) !== -1 && c.value) {
            markup += `
                <div>
                    <span class="dealer-detail__phone-number-data__name">
                        ${isDealership ? content.phoneLabels[c.type] : content.collisionPhoneLabels[c.type]}:
                    </span>
                    <span>
                        <a href="tel:${c.value}" class="link link_plain-link" data-analytics-trigger="phone">${c.value}</a>
                    </span>
                </div>
            `;
        }
        return markup;
    }, '');
}

/**
 * @description Template for dealer detail
 * @param {Object} dealer - Dealer object
 * @param {Object} content - Content object
 * @param {String} mainCTALink - Main CTA URL
 * @param {String} secondaryCTALink - Secondary CTA URL
 */
export default (dealer, content, mainCTALink, secondaryCTALink, showDealerUrl) => htmlNode`
    <div class="wrapper dealer-detail ${dealer.isDealership ? 'dealer-detail--is-dealership' : ''} ${dealer.isPreferred ? 'dealer-detail--is-preferred' : ''}" tabindex="0" data-analytics-container="{ 'dealer-details': '${dealer.name}', preferred: '${dealer.isPreferred}' }">
        <button class="link link_plain-link dealer-detail__back-cta" data-analytics="cta">${content.backToResults}</button>
        <div class="dealer-detail__heading">
            <span class="dealer-detail__dealer-name">
                <span class="dealer-detail__dealer-name-in">${dealer.name}</span>
                ${dealer.hasVanCare ? `<span class="dealer-detail__van-care" aria-label="${content.vanCareAriaLabel}" title="${content.vanCareTitleLabel}"></span>` : ''}
                ${dealer.hasVanCareMobile ? `<span class="dealer-detail__van-care dealer-detail__van-care--mobile" aria-label="${content.vanCareMobileAriaLabel}" title="${content.vanCareMobileTitleLabel}"></span>` : ''}
            </span>
            <span class="dealer-detail__dealer-distance">${dealer.distance}</span>
        </div>
        <div class="dealer-detail__address-container">
            ${renderAddress(dealer)}
            <a class="link link_plain-link" href="${dealer.directionsLink}" target="_blank" data-analytics-trigger="cta">
                ${content.getDirections}
            </a>
        </div>
        ${dealer.isDealership ?
            `<div class="dealer-detail__cta-container">
                ${mainCTALink.href ? `<a class="button button_primary button--full-width" href="${mainCTALink.href}" data-analytics-trigger="cta">${mainCTALink.text}</a>` : ''}
                ${secondaryCTALink ? `<a class="button button_call-out button--full-width" href="${secondaryCTALink.href}" data-analytics-trigger="cta">${secondaryCTALink.text}</a>` : ''}
            </div>` : ''
        }
        <div class="dealer-detail__tags-container" aria-label="List of services provided">
            <h4 class="dealer-detail__tags-heading">${content.servicesOffered}</h4>
            <ul class="dealer-detail__tags-list">
                ${renderBadges(content.badges, dealer.badges)}
            </ul>
        </div>
        <div class="dealer-detail__phone-number-container">
            <h4 class="dealer-detail__phone-number-heading">${content.phone}</h4>
            <div class="dealer-detail__phone-number-data">
                ${renderPhoneNumbers(dealer.contact, content, dealer.isDealership)}
            </div>
        </div>
        ${showDealerUrl && dealer.url ? `<div class="dealer-detail__website-container">
            <a href="${dealer.url}" target="_blank" class="link link_plain-link" data-analytics-trigger="cta">
                ${content.visitWebsite}
            </a>
        </div>` : ''}
        ${dealer.isDealership ?
            `<div class="dealer-detail__preferred-cta-container">
                <button class="link link_plain-link dealer-detail__preferred-cta" data-analytics-trigger="cta">
                    <span class="dealer-detail__make-preferred-cta">${content.makePreferDealer}</span>
                    <span class="dealer-detail__remove-preferred-cta">${content.removePreferDealer}</span>
                </button>
            </div>` : ''
        }
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

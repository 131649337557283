/**
 * API responsible for retrieving locations from Google Places api
 */

// Local dependencies
import googleLoaderApi from './googleLoaderApi';

/**
 * @method getGeoInfo
 * @description Retrieves geo locational data based on the location type
 * and data from the Google Maps api.
 *
 * See Google Map Geocode API for additional information on type and data
 * values, and response values:
 * https://developers.google.com/maps/documentation/javascript/geocoding
 * @param geoData {Object} Geolocation object
 * @returns {Promise}
 */
function getGeoInfo(geoData) {
    return new Promise((resolve, reject) => {
        googleLoaderApi.loadGoogleMaps()
            .then(() => {
                const { google: { maps } } = window;
                const geocoderService = new maps.Geocoder();

                geocoderService.geocode(geoData, resolve, reject);
            })
            .catch(reject);
    });
}

/**
 * @method getCityNames
 * @description Retrieves a collection of Google Places based on `city, state` string value
 * @param {string} searchValue Value to retrieve places by
 * @param {string} country The country code to retrieve query from
 * @returns {Promise} Promise that resolves with collection of Google Places objects
 */
function getCityNames(searchValue, country) {
    return new Promise((resolve, reject) => {
        if (searchValue && country) {
            googleLoaderApi.loadGoogleMaps()
                .then(() => {
                    const autocompleteService = new google.maps.places.AutocompleteService();

                    autocompleteService.getPlacePredictions({
                        input: searchValue,
                        types: ['geocode'],
                        componentRestrictions: { country }
                    }, resolve, reject);
                });
        } else {
            reject('Search value or Country not provided');
        }
    });
}

/**
 * @method getPlaceCoordinate
 * @description Retrieves meta data for location from Google Place Id
 * @param placeId {string} Google Place Id
 * @returns {Promise} Promise that resolves with Google Place geocoder object
 */
function getPlaceCoordinate(placeId) {
    return getGeoInfo({ placeId });
}

/**
 * @method getPlaceCoordinates
 * @description Gets the lat/lng of a provided Google Place ID
 * @param {String} placeId Google Place ID
 * @return {Promise} Promise that resolves with an object { lat, lng }
 */
function getPlaceCoordinates(placeId) {
    return getGeoInfo({ placeId })
        .then((places) => places[0])
        .then((place) => (
            {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
        ));
}

/**
 * @method getClosestPlaceFullInfo
 * @description Tries to get a full address that is close to the provided placeId. Following steps
 * are executed:
 * º Gets more info about place by using geoCoding service
 * º Use lat/lng from response to get list of places associated in that location
 * º Response first place in the response
 * @param placeId {string} Google Place Id
 * @return {Promise} Promise that resolves with Google Place object
 */
function getClosestPlaceFullInfo(placeId) {
    return new Promise((resolve, reject) => {
        getPlaceCoordinate(placeId)
            .then((places) => {
                if (places) {
                    const place = places[0];

                    getGeoInfo({ latLng: place.geometry.location })
                        .then((data) => {
                            if (data && data[0]) {
                                resolve(data[0]);
                            } else {
                                reject(null);
                            }
                        })
                        .catch(reject);
                } else {
                    reject(null);
                }
            })
            .catch(reject);
    });
}

/**
 * @method getPlaceInfo
 * @description Retrieves info about a place by using geocoding service.
 * @param placeId {string} Google Place Id
 * @return {Promise} Promise that resolves with Google Place object
 */
function getPlaceInfo(placeId) {
    return new Promise((resolve, reject) => {
        getPlaceCoordinate(placeId)
            .then((places) => {
                if (places) {
                    resolve(places[0]);
                } else {
                    reject(null);
                }
            })
            .catch(reject);
    });
}

/**
 * @method getCityStateForPlace
 * @description Retrieves city, region for a place by using geocoding service.
 * @param placeId {string} Google Place Id
 * @return {Promise} Promise that resolves with an object { city, region }
 */
function getCityStateForPlace(placeId) {
    return getPlaceInfo(placeId)
        .then((place) => ({
            city: place.address_components[0].long_name,
            region: place.address_components.find((component) =>
                component.types.indexOf('administrative_area_level_1') !== -1).short_name
        }));
}

/**
 * @method getPlacePostalCode
 * @description Retrieves placeInfo from a placeID and parses the postal code from
 * the address components if available
 * @param placeId {String} Id of place to retrieve postal code for
 * @return {String} Postal code for place
 */
function getPlacePostalCode(placeId) {
    return getClosestPlaceFullInfo(placeId)
        .then((place) => {
            const postalCodes = place.address_components.filter((address) =>
                address.types.indexOf('postal_code') !== -1
            );

            if (postalCodes && postalCodes[0]) {
                return postalCodes[0].long_name;
            }
            return null;
        });
}

/**
 * @method getAddressCoordinates
 * @decription Retrieves lat/lng coordinates for an address from the Google Maps api
 * @param address {string} Address string value to retrieve coordinate for
 * @param country {string} Country code of address
 * @returns {Promise} Promise object the resolve with coordinates data object
 */
function getAddressCoordinates(address, country) {
    return new Promise((resolve, reject) => {
        getGeoInfo({
            address,
            componentRestrictions: {
                country
            }
        })
            .then((results) => {
                if (results && results.length) {
                    const location = results[0].geometry.location;
                    const coords = {
                        lat: location.lat(),
                        lng: location.lng()
                    };

                    resolve(coords);
                }
            })
            .catch(reject);
    });
}

// Export public API methods
export default {
    getGeoInfo,
    getCityNames,
    getPlaceCoordinate,
    getPlaceCoordinates,
    getClosestPlaceFullInfo,
    getPlaceInfo,
    getCityStateForPlace,
    getPlacePostalCode,
    getAddressCoordinates
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

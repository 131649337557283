// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for virtual keyboard
 * Uses ES6 template literal to composite the markup for a virtual keyboard
 */
export default ({ cssClass = '', id = '' }) => htmlNode`
    <section class="virtual-keyboard module-separator ${cssClass}" id="${id}">
        <div class="simple-keyboard"></div>
    </section>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Module dependencies
import {
    noop,
    renderer,
    screen,
    Touch,
    customEventDispatcher
} from 'utils';

import { CUSTOM_EVENTS, EVENTS } from 'Constants';

// Local dependencies
import CarouselNavigation from './CarouselNavigation';
import CarouselSlide from './CarouselSlide';
import CarouselSlideClone from './CarouselSlideClone';
import carouselTemplate from './../templates/carouselTemplate';
import CarouselTypes from './../constants/carouselTypes';
import NavButtonArrangementTypes from './../constants/navButtonArrangementTypes';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{BUTTONS: string}}
 */
const ATTRIBUTES = {
    NAVIGATION: 'data-carousel-navigation',
    SLIDES: 'data-carousel-slides',
    SWIPE_CONTAINER: 'data-carousel-swipe-container'
};

/**
 * @const CLASSES
 * @description Classes that can be used for selecting interactive elements
 */
const CLASSES = {
    THREE_SIXTY: 'gallery__three-sixty-container',
};

/**
 * @cosnt VIDEO_TAG
 * @description Video tag, used to select HTML5 video
 */
const VIDEO_TAG = 'video';

/**
 * @const TRANSITION_DIRECTION
 * @description Collection of constant values for carousel transition directions
 * @type {string}
 */
const TRANSITION_DIRECTION = {
    NEXT: 'next',
    PREV: 'prev'
};

/**
 * @const DURATION_TIMES
 * @description Collection of constant values for carousel animation duration times
 * @type {{number}}
 */
const DURATION_TIMES = {
    NONE: 0,
    SHORT: 350
};

/**
 * @const defaultConfig
 * @description Default configuration options for a Carousel
 * @type {{startIndex: number, type: string, infinite: boolean,
 * indicators: boolean, indicatorPositionBottom: boolean, labels: {next: string, prev: string},
 * theme: string, onSlideCallback: function, navEnabledSmall: boolean,
 * transitionType: string}}
 */
const defaultConfig = {
    startIndex: 0,
    type: 'overlay',
    infinite: false,
    indicators: false,
    indicatorPositionBottom: false,
    navButtonArrangement: 'normal',
    navEnabledSmall: false,
    labels: {
        prev: 'Previous',
        next: 'Next'
    },
    analyticsLabels: {
        prevAnalytics: 'image rotate',
        nextAnalytics: 'image rotate'
    },
    suppressLastIndicatorsDots: false,
    theme: '',
    transitionType: 'opacity',
    onSlideCallback: noop, // function to call when active slide changes
    onSlidesClonedCallback: noop,
    onAnimationFinishCallback: noop
};

/**
 * @class Carousel
 * @description View component for displaying a Carousel and managing its state
 */
export default class Carousel {
    /**
     * @static CAROUSEL_TYPES
     * @description Collection of constant values for the different types of carousels
     * @type {{SLIDING, BOTTOM, OVERLAY}}
     */
    static CAROUSEL_TYPES = {
        ...CarouselTypes
    };

    /**
     * @static NAVIGATION_BUTTON_PLACEMENT
     * @description Collection of constant values for the different types of nav button arrangements
     * @type {{string}}
     */
    static NAVIGATION_BUTTON_PLACEMENT = {
        ...NavButtonArrangementTypes
    };

    /**
     * @static TRANSITION_TYPES
     * @description Collection of constant values for the different types of carousel transitions
     * @type {string}
     */
    static TRANSITION_TYPES = {
        BOTTOM_SLIDING: 'bottom-sliding',
        OPACITY: 'opacity',
        SLIDING: 'sliding'
    };

    constructor(items, config = defaultConfig) {
        // properties
        this.carousel = null; // ref to the carousel element
        this.clonedSlide = null; // stores a reference to cloned slide element used for transitioning
        this.currentIndex = 0; // current index of the active slide item
        this.hasMoreThanOneSlide = items.length > 1;
        this.mutationObserver = null; // reference to a MutationObserver instance that observes `this.carousel`
        this.navigation = null; // ref to the navigation object
        this.navigationContainer = null; // ref to navigation element
        this.previousIndex = 0; // previous index of the active slide item
        this.slideAnimation = null; // stores a reference to the slide animation
        this.slideItems = null; // ref to the collection of slide items objects
        this.slidesContainer = null; // ref to the slides wrapper element
        this.swipeContainer = null; // ref to the swipeable slides container
        this.totalSlides = 0; // total number of slide items
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.tabbables = null;
        // alias methods
        this.onNext = this.onNext.bind(this);
        this.onPrev = this.onPrev.bind(this);
        this.dotNavHelper = this.dotNavHelper.bind(this);
        this.setActiveSlide = this.setActiveSlide.bind(this);
        this.onSlideFocus = this.onSlideFocus.bind(this);
        this.onResize = this.onResize.bind(this);
        this.realignCurrentSlide = this.realignCurrentSlide.bind(this);
        this.setActiveSlideFocus = this.setActiveSlideFocus.bind(this);

        // init carousel
        this.createCarousel();
        this.createSlides(items);
        this.createNavigation();
        this.attachEvents();
        this.render();
    }

    /**
     * @method createCarousel
     * @description Creates a carousel element, sets references to the navigation
     * and slides elements, and creates attaches a Touch utility to the slidesContainer
     */
    createCarousel() {
        this.carousel = renderer.fromTemplate(carouselTemplate(
            this.config.transitionType,
            this.config.href,
            this.config.linkLabel
        ));
        this.slidesContainer = this.carousel.querySelector(`[${ATTRIBUTES.SLIDES}]`);
        this.navigationContainer = this.carousel.querySelector(`[${ATTRIBUTES.NAVIGATION}]`);
        this.swipeContainer = new Touch(this.carousel.querySelector(`[${ATTRIBUTES.SWIPE_CONTAINER}]`));
        this.setTheme();
    }

    /**
     * @method setTheme
     * @description Sets the css theme of the navigation
     */
    setTheme() {
        if (this.config.theme) {
            this.carousel.classList.add(`carousel--${this.config.theme}`);
        }
    }

    /**
     * @method createSlides
     * @description Iterates a collection of elements to create a CarouselSlide for each,
     * and sets their initial state based on the currentIndex.
     * @param items {Array} Collection of elements to create CarouselSlide from
     */
    createSlides(items) {
        this.currentIndex = this.config.startIndex;
        this.slideItems = items.map(
            (item, index) => new CarouselSlide(item, index === this.currentIndex,
                this.config.analyticsKey, this.hasMoreThanOneSlide, index,
                this.onSlideFocus, this.config.overrideTabbables));

        this.totalSlides = this.slideItems.length;
        this.slideMedia = this.slideItems.map(
            (slide) => ({
                video: slide.slide.querySelector(`${VIDEO_TAG}`),
                threeSixty: slide.slide.querySelector(`.${CLASSES.THREE_SIXTY}`)
            })
        );
    }

    /**
     * @method createNavigation
     * @description Creates a CarouselNavigation and sets callback for next and previous events
     */
    createNavigation() {
        this.navigation = new CarouselNavigation({
            analyticsLabels: this.config.analyticsLabels,
            currentIndex: this.currentIndex,
            indicatorPositionBottom: this.config.indicatorPositionBottom,
            indicators: this.config.indicators,
            infinite: this.config.infinite,
            labels: this.config.labels,
            navButtonArrangement: this.config.navButtonArrangement,
            navEnabledSmall: this.config.navEnabledSmall,
            onNext: this.onNext,
            onPrev: this.onPrev,
            setActiveSlide: this.setActiveSlide.bind(this),
            suppressLastIndicatorsDots: this.config.suppressLastIndicatorsDots,
            theme: this.config.theme,
            totalCount: this.totalSlides,
            transitionType: this.config.transitionType,
            type: this.config.type
        });
    }

    /**
     * @method destroy
     * @description Destroys the carousel by removing all references, detaching events
     * and clearing the carousel element
     */
    destroy() {
        this.detachEvents();
        this.carousel.remove();
        this.slideItems = null;
        this.navigation = null;
        this.carousel = null;
    }

    /**
     * @method attachEvents
     * @description Attaches swipe events and callbacks to the swipeContainer
     */
    attachEvents() {
        this.swipeContainer.on(Touch.EVENTS.SWIPE_RIGHT, this.onNext);
        this.swipeContainer.on(Touch.EVENTS.SWIPE_LEFT, this.onPrev);
        this.carousel.querySelector(`[${ATTRIBUTES.SWIPE_CONTAINER}]`)
            .addEventListener(EVENTS.CLICK, this.config.onImageClick);
        screen.addResizeListener(this.onResize);

        if (
            (
                this.config.transitionType === Carousel.TRANSITION_TYPES.SLIDING ||
                this.config.transitionType === Carousel.TRANSITION_TYPES.BOTTOM_SLIDING
            ) && this.config.infinite
        ) {
            // Create instance of MutationObserver with callback that clones and
            // realigns to the current slide after rendering to the DOM
            this.mutationObserver = new window.MutationObserver(() => {
                this.mutationObserver.disconnect();
                this.createClonedSlides();
                this.realignCurrentSlide();
            });

            // Listen for DOM mutations on parent element
            this.mutationObserver.observe(this.carousel.parentElement, {
                childList: true
            });
        }
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.CLOSE_MODAL_BLUR,
            this.setActiveSlideFocus
        );
    }

    /**
     * @method detachEvents
     * @description Removes swipe events and callbacks from the swipeContainer
     */
    detachEvents() {
        this.swipeContainer.off(Touch.EVENTS.SWIPE_RIGHT, this.onNext);
        this.swipeContainer.off(Touch.EVENTS.SWIPE_LEFT, this.onPrev);
        screen.removeResizeListener(this.onResize);

        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
            this.mutationObserver = null;
        }
        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.CLOSE_MODAL_BLUR,
            this.setActiveSlideFocus
        );
    }

    /**
     * @method onNext
     * @description Determines which slide is next based on the currentIndex then
     * increments and sets the new current slide
     */
    onNext() {
        let nextSlide = this.currentIndex;

        if (this.config.transitionType === Carousel.TRANSITION_TYPES.BOTTOM_SLIDING) {
            nextSlide = this.getBottomSlidingNextSlideIndex();
        } else if (this.currentIndex + 1 < this.totalSlides) {
            nextSlide = this.currentIndex + 1;
        } else if (this.config.infinite) {
            nextSlide = 0;
        }

        if (nextSlide !== this.currentIndex) {
            this.setActiveSlide(nextSlide, this.currentIndex);
        }
    }
    /**
     * @method getBottomSlidingNextSlideIndex
     * @description Determines which slide is next based on the currentIndex then
     *              increments and sets the new current slide.
     *              Logic is specfically for the BOTTOM_SLIDING carousel type
     */
    getBottomSlidingNextSlideIndex() {
        let nextSlide = this.currentIndex;
        let totalSlides = this.totalSlides;

        if (screen.gte(screen.SIZES.XXLARGE)) {
            totalSlides = this.totalSlides - 2;
        } else if (screen.gte(screen.SIZES.LARGE)) {
            totalSlides = this.totalSlides - 1;
        }

        const currentIndex = this.currentIndex + 1;
        if (currentIndex < totalSlides) {
            nextSlide = this.currentIndex + 1;
        } else if ((currentIndex > totalSlides) && (currentIndex < this.totalSlides)) {
            nextSlide = totalSlides;
        } else if (this.config.infinite) {
            nextSlide = 0;
        }

        return nextSlide;
    }

    /**
     * @method onPrev
     * @description Determines which slide is previous based on the currentIndex then
     * decrements and sets the new current slide
     */
    onPrev() {
        let prevSlide = this.currentIndex;

        if (this.currentIndex - 1 > -1) {
            prevSlide = this.currentIndex - 1;
        } else if (this.config.infinite) {
            prevSlide = this.totalSlides - 1;
        }

        if (prevSlide !== this.currentIndex) {
            this.setActiveSlide(prevSlide, this.currentIndex);
        }
    }

    /**
     * @method onResize
     * @description Calls methods needed to update carousel on resizing the browser
     */
    onResize() {
        if (
            this.config.transitionType === Carousel.TRANSITION_TYPES.SLIDING ||
            this.config.transitionType === Carousel.TRANSITION_TYPES.BOTTOM_SLIDING
        ) {
            this.realignCurrentSlide();
        }
    }

    /**
     * @method createClonedSlides
     * @description Creates a set collection of CarouselSlideClone views for the first two
     * and last two CarouselSlide items and prepend/appends them to the `slidesContainer`.
     * These cloned elements will be used to when transitioning an infinite sliding carousel
     */
    createClonedSlides() {
        if (this.slideItems.length < 2) {
            return;
        }
        this.clonedSlides = {
            startPrimary: new CarouselSlideClone(
                this.slideItems[0],
                this.onSlideFocus
            ),
            startSecondary: new CarouselSlideClone(
                this.slideItems[1]
            ),
            endPrimary: new CarouselSlideClone(
                this.slideItems[this.totalSlides - 1]
            ),
            endSecondary: new CarouselSlideClone(
                this.slideItems[this.totalSlides - 2]
            )
        };

        // prepend cloned ending slides to the beginning
        this.slidesContainer.insertBefore(
            this.clonedSlides.endPrimary.clonedSlide,
            this.slideItems[0].slide
        );
        this.slidesContainer.insertBefore(
            this.clonedSlides.endSecondary.clonedSlide,
            this.clonedSlides.endPrimary.clonedSlide
        );

        // append cloned starting slides to the end
        this.slidesContainer.appendChild(this.clonedSlides.startPrimary.clonedSlide);
        this.slidesContainer.appendChild(this.clonedSlides.startSecondary.clonedSlide);

        this.config.onSlidesClonedCallback(this.clonedSlides);
    }

    /**
     * @method updateCloneSlides
     * @description Iterates the clonedSlides collection and applies the `updateClonedSlide`
     * method so that each of the cloned slide is up to date with its slide element in the DOM
     */
    updateCloneSlides() {
        Object.values(this.clonedSlides).forEach((clonedSlide) => {
            clonedSlide.updateClonedSlide();
        });
    }

    /**
     * @method animateSliding
     * @description slide animation
     * @param prevOffset {Number} offsetLeft of the previously visible slide item
     * @param newOffset {Number} offsetLeft of the current/active slide item
     * @param duration {Number} duration time of the animation's transition
     */
    animateSliding(prevOffset, newOffset, duration) {
        if (this.slideAnimation) {
            this.slideAnimation.cancel();
        }

        // tabbing/focusing will scroll the slide into view need to set it to 0
        const carouselElm = this.slidesContainer.parentElement;
        carouselElm.scrollLeft = 0;

        this.slideAnimation = this.slidesContainer.animate(
            [
                { transform: `translateX(-${prevOffset}px)` },
                { transform: `translateX(-${newOffset}px)` }
            ], {
                easing: 'ease-in-out',
                fill: 'both',
                duration,
                iterations: 1
            });
        this.slideAnimation.onfinish = this.onAnimationFinishCallback();
        this.slideAnimation.play();
    }

    /**
     * @method animateBottomSliding
     * @param direction {String}
     * @param prevOffset {Number} offsetLeft of the previously visible slide item
     * @param newOffset {Number} offsetLeft of the current/active slide item
     */
    animateBottomSliding(direction, prevOffset, newOffset) {
        const carouselElm = this.slidesContainer.parentElement;
        const visibleWidth = carouselElm.offsetWidth;
        const scrollLeft = carouselElm.scrollLeft;

        // Subtract one from the scrollWidth to resolve any issues with fractional pixel values
        const scrollWidth = carouselElm.scrollWidth - 1;
        const tileWidth =
            this.slideItems[this.currentIndex].slide.offsetWidth * (direction === TRANSITION_DIRECTION.NEXT ? 1 : -1);

        if (((scrollLeft + visibleWidth + tileWidth) < scrollWidth) || !screen.gte(screen.SIZES.XLARGE)) {
            this.animateSliding(prevOffset, newOffset, DURATION_TIMES.SHORT);
        }
    }

    /**
     * @method transitionSliding
     * @description transitions the slide based on direction and position in the carousel list,
     * adds clone of first/last slide item, using clone as placeholder, calls animation method,
     * initiates animation complete method to remove clone
     * @param direction {string} prev || next
     */
    transitionSliding(direction) {
        let prevOffset = 0;
        let nextOffset = 0;
        let applyOnFinish = false;

        if (this.currentIndex === 0 && direction === TRANSITION_DIRECTION.NEXT) {
            // last slide moving to first slide
            // update the clones before they come into view
            this.updateCloneSlides();
            prevOffset = this.slideItems[this.totalSlides - 1].slide.offsetLeft;
            nextOffset = this.slidesContainer.children[this.slidesContainer.children.length - 2].offsetLeft;
            applyOnFinish = true;
        } else if (this.currentIndex === this.totalSlides - 1 && direction === TRANSITION_DIRECTION.PREV) {
            // first slide moving to last slide
            // update the clones before they come into view
            this.updateCloneSlides();
            prevOffset = this.slideItems[0].slide.offsetLeft;
            nextOffset = this.slidesContainer.children[1].offsetLeft;
            applyOnFinish = true;
        } else {
            // any previous or next slides
            prevOffset = this.slideItems[this.previousIndex].slide.offsetLeft;
            nextOffset = this.slideItems[this.currentIndex].slide.offsetLeft;
        }

        if (this.config.transitionType === Carousel.TRANSITION_TYPES.BOTTOM_SLIDING) {
            this.animateBottomSliding(direction, prevOffset, nextOffset);
        } else {
            this.animateSliding(prevOffset, nextOffset, DURATION_TIMES.SHORT);
        }

        // if we transition to the start or end from an infinite loop apply a callback to realign slides
        if (applyOnFinish) {
            this.slideAnimation.onfinish = this.realignCurrentSlide;
        }
    }

    /**
     * @method realignCurrentSlide
     * @description cancels the previous animation, resets the slide back to
     * its current offsetLeft immediately without transitioning
     */
    realignCurrentSlide() {
        if (this.slideAnimation) {
            this.slideAnimation.cancel();
        }

        this.animateSliding(0, this.slideItems[this.currentIndex].slide.offsetLeft, DURATION_TIMES.NONE);
    }

    /**
     * @method dotNavHelper
     * @description call toggleSelectedDot if indicators are enabled
     * @param activeIndex index, next slide index
     */
    dotNavHelper(activeIndex) {
        if (this.config.indicators) {
            this.navigation.toggleDots(activeIndex);
        }
    }

    /**
     * @method setActiveSlide
     * @description Sets the current slide and disables all slide that are inactive
     * also sets the direction and calls transition slide method if transition type is sliding
     * @param activeIndex {Number} Index of the slide to set as the active current slide
     * @param prevIndex {Number} Index of the previous active slide to pause youtube video if needed
     */
    setActiveSlide(activeIndex, prevIndex) {
        let direction;
        this.slideItems.forEach((slide, index) => {
            if (index === activeIndex) {
                slide.enable();
            } else {
                slide.disable();
            }
        });

        this.onSlideCallback(activeIndex, prevIndex);
        this.pauseVideo(prevIndex);
        this.playVideo(activeIndex);

        this.navigation.setCurrentPage(activeIndex);
        this.previousIndex = this.currentIndex;
        this.currentIndex = activeIndex;
        this.dotNavHelper(this.currentIndex);

        // if the transitionType is sliding, determine the direction
        // of the transition based on the previous and current index
        if (
            this.config.transitionType === Carousel.TRANSITION_TYPES.SLIDING  ||
            this.config.transitionType === Carousel.TRANSITION_TYPES.BOTTOM_SLIDING
        ) {
            if (activeIndex === 0 && (prevIndex === this.totalSlides - 1) && this.config.infinite) {
                direction = TRANSITION_DIRECTION.NEXT;
            } else if ((activeIndex === this.totalSlides - 1) && prevIndex === 0 && this.config.infinite) {
                direction = TRANSITION_DIRECTION.PREV;
            } else if (activeIndex > prevIndex) {
                direction = TRANSITION_DIRECTION.NEXT;
            } else {
                direction = TRANSITION_DIRECTION.PREV;
            }
            this.transitionSliding(direction);
        }
    }

    /**
     * @method pauseVideo
     * @description Checks if a slideItem contains a youtube video and if so
     * it is dispatches an event to pause the video
     * @param slideIndex {Number} Index of the slide to pause
     */
    pauseVideo(slideIndex) {
        const video = this.slideItems[slideIndex].slide.querySelector(`${VIDEO_TAG}`);

        if (video) {
            video.pause();
        }
    }

    /**
     * @method playVideo
     * @description Checks if a slideItem contains a video and has an autoplay attribute;
     * if so calls the play method of the video
     * @param slideIndex {Number} Index of the slide to pause
     */
    playVideo(slideIndex) {
        const threeSixty = this.slideMedia[slideIndex].threeSixty;
        const video = this.slideItems[slideIndex].slide.querySelector(`${VIDEO_TAG}`);
        const isAutoPlay = video && video.hasAttribute('autoplay');

        if (video && isAutoPlay) {
            video.play();
        }

        if (threeSixty && screen.gte(screen.SIZES.LARGE)) {
            threeSixty.dispatchEvent(new MouseEvent('click'));
        }
    }

    /**
     * @method onSlideCallback
     * @description Calls a function to notify the active slide has changed
     * @param activeIndex {Number} Index of the active slide
     * @param prevIndex {Number} Index of the previously active slide
     */
    onSlideCallback(activeIndex, prevIndex) {
        this.config.onSlideCallback(activeIndex, prevIndex);
    }

    /**
     * @method onSlideFocus
     * @description Calls a function to slide has been focused on
     * @param slide {object}
     */
    onSlideFocus(slide) {
        if (!slide.isActive) {
            this.setActiveSlide(slide.carouselSlideIndex, this.currentIndex);
        }
    }

    /**
     * @method onAnimationFinishCallback
     * @description Calls a function once the carousel slide's animation finishes
     */
    onAnimationFinishCallback() {
        if (this.config.onAnimationFinishCallback && typeof this.config.onAnimationFinishCallback === 'function') {
            this.config.onAnimationFinishCallback(this.slideItems);
        }
    }

    /**
     * @method setActiveSlideFocus
     * @description Set focus on the current slide
     */
    setActiveSlideFocus() {
        this.slideItems[this.currentIndex].setFocus();
    }

    /**
     * @method render
     * @description Renders the slideItems and navigation to the carousel, then renders
     * the carousel to the element reference
     */
    render() {
        [...this.slideItems].forEach(
            (slide) => this.slidesContainer.appendChild(slide.render())
        );

        this.navigationContainer.appendChild(this.navigation.render());

        // If a carousel's first item has an autoplaying video, the video stops
        // playing once added to DOM because of changes in parent structure while
        // adding. To force the video to continue playing, call playVideo method
        // with currentIndex.
        this.playVideo(this.currentIndex);

        return this.carousel;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import {
    generateUniqueID as ID,
    renderer,
    screen,
    viewportObserver
} from 'utils';

// Local dependencies
import cssLazyLoaderTemplate from './templates/cssLazyLoaderTemplate';

/**
 * @const CLASSES
 * @description Class names associated with LazyLoader
 * @type {{LOADER: string, LOADED: string}}
 */
const CLASSES = {
    LOADER: 'css-lazy-loader',
    LOADED: 'css-lazy-loader--loaded'
};

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{SMALL_IMAGE: string, LARGE_IMAGE: string, XLARGE_IMAGE: string, XXLARGE_IMAGE: string}}
 */
const ATTRIBUTES = {
    SMALL_IMAGE: 'cssLazyLoaderImageSmall',
    LARGE_IMAGE: 'cssLazyLoaderImageLarge',
    XLARGE_IMAGE: 'cssLazyLoaderImageXlarge',
    XXLARGE_IMAGE: 'cssLazyLoaderImageXxlarge',
};

/**
 * @const WINDOW_THRESHOLD
 * @description Window threshold buffer to load a CSSLazyLoader asset
 *
 * @type {number}
 */
const WINDOW_THRESHOLD = 200;

/**
 * @class CSSLazyLoader
 * @description Module for lazy loading a CSS background image
 *
 * Example Usage:
 *
 * <div class="element-with-bkg-img css-lazy-loader"
 *      data-css-lazy-loader-selector=".element-with-bkg-img"
 *      data-css-lazy-loader-image-small="/content/dam/mb-vans/path/to/small-image.jpg"
 *      data-css-lazy-loader-image-large="/content/dam/mb-vans/path/to/large-image.jpg"
 *      data-css-lazy-loader-image-xlarge="/content/dam/mb-vans/path/to/xlarge-image.jpg"
 *      data-css-lazy-loader-image-xxlarge="/content/dam/mb-vans/path/to/xxlarge-image.jpg"
 *      data-load-module="CSSLazyLoader">
 * </div>
 */
export default class CSSLazyLoader {
    /**
     * @constructor
     * @param element {Node} The DOM element to apply CSS background image on
     */
    constructor(element) {
        this.element = element;
        this.isLoaded = false;
        this.selector = null;
        this.imageSources = {};
        if (this.element) {
            this.init();
        }
    }

    /**
     * @method init
     * @description Initialize the CSSLazyLoader Module
     */
    init() {
        const { isOnDisplay } = window.mbVans.ns('pageData', 'variantInfo');
        this.setBindings();
        this.saveSources();
        this.addClasses();
        if (isOnDisplay) {
            // Turn off lazy loading for DDT OnDisplay variant because it has a caching mechanism
            this.createStyleTag();
        } else {
            this.attachEvents();
        }
    }

    /**
     * @method setBindings
     * @description Sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onObserver = this.onObserver.bind(this);
        this.createStyleTag = this.createStyleTag.bind(this);
    }

    /**
     * @method saveSources
     * @description Saves image source paths in an object, for later use
     */
    saveSources() {
        Object.keys(this.element.dataset).forEach((attr) => {
            switch (attr) {
            case ATTRIBUTES.SMALL_IMAGE:
                this.imageSources.small = this.element.dataset[attr];
                this.imageSources.large = this.element.dataset[attr];
                this.imageSources.xlarge = this.element.dataset[attr];
                this.imageSources.xxlarge = this.element.dataset[attr];
                break;
            case ATTRIBUTES.LARGE_IMAGE:
                this.imageSources.large = this.element.dataset[attr];
                this.imageSources.xlarge = this.element.dataset[attr];
                this.imageSources.xxlarge = this.element.dataset[attr];
                break;
            case ATTRIBUTES.XLARGE_IMAGE:
                this.imageSources.xlarge = this.element.dataset[attr];
                this.imageSources.xxlarge = this.element.dataset[attr];
                break;
            case ATTRIBUTES.XXLARGE_IMAGE:
                this.imageSources.xxlarge = this.element.dataset[attr];
                break;
            default:
                break;
            }
        });
    }

    /**
     * @method addClasses
     * @description Adds CSS Lazy Loader-related classes, as needed
     */
    addClasses() {
        this.element.classList.add(CLASSES.LOADER);

        /* If no selector is passed to the CSSLazyLoader,
         * a unique class is created via utils/generateUniqueID.js.
         * Unique class is added to element's classList and assigned to this.selector.
         */
        if (!this.element.dataset.cssLazyLoaderSelector) {
            const uid = ID();
            this.element.classList.add(CLASSES.LOADER + uid);
            this.selector = `.${CLASSES.LOADER + uid}`;
        } else {
            this.selector = this.element.dataset.cssLazyLoaderSelector;
        }
    }

    /**
     * @method attachEvents
     * @description Adds event listeners
     */
    attachEvents() {
        this.vObserver = viewportObserver.addObserver(
            this.element,
            this.onObserver,
            {
                threshold: 0,
                rootMargin: `${WINDOW_THRESHOLD}px 0px`
            }
        );
    }

    /**
     * @method detachEvents
     * @description Removes event listeners
     */
    detachEvents() {
        viewportObserver.removeObserver(this.vObserver);
    }

    /**
     * @method onObserver
     * @description Event handler for IntersectionObserver
     */
    onObserver(entries) {
        if (entries[0].isIntersecting && !this.isLoaded) {
            this.loadAsset();
            this.detachEvents();
        }
    }

    /**
     * @method loadAsset
     * @description Preloads image asset, before creation of <style> tag
     */
    loadAsset() {
        const img = new Image();
        img.onload = this.createStyleTag;
        img.src = this.imageSources[screen.getCurrentState()];
    }

    /**
     * @method createStyleTag
     * @description Creates <style> tag to apply CSS background image
     */
    createStyleTag() {
        this.isLoaded = true;
        this.element.classList.add(CLASSES.LOADED);
        const tag = cssLazyLoaderTemplate({
            selector: this.selector,
            imageSmall: this.element.dataset[ATTRIBUTES.SMALL_IMAGE],
            imageLarge: this.element.dataset[ATTRIBUTES.LARGE_IMAGE],
            imageXlarge: this.element.dataset[ATTRIBUTES.XLARGE_IMAGE],
            imageXxlarge: this.element.dataset[ATTRIBUTES.XXLARGE_IMAGE]
        })({ getNode: true });
        renderer.insertBefore(tag, this.element.parentElement, this.element);
    }

    /**
     * @method destroy
     * @description Detaches event listeners
     */
    destroy() {
        this.detachEvents();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a DealerForm view scaffolding .
 * Uses ES6 template literal to composite the markup for a Dealer Form scaffolding view
 */
export default ({ title = '', submitButtonLabelText = '', footnote = '', disclaimer = '', hasHomeTestDrive = '' }) => htmlNode `
    <div class="dealer-form">
        <div class="dealer-form__form-container" data-analytics-container="{'blade': 'dealer-form'}">
            <div class="dealer-form__header">
                ${title}
            </div>
            <div class="dealer-form__form">
                <div class="dealer-form__section-one"></div>
                <div class="dealer-form__section-two"></div>
                <div class="dealer-form__recaptcha"></div>
                <div class="dealer-form__footer">
                    <dl class="dealer-form__footnote">
                        <dd class="disclaimer__text">${footnote}</dd>
                        ${disclaimer && hasHomeTestDrive ?
                            `<div>
                                <dt class="disclaimer__legend">[${disclaimer.reference}]</dt>
                                <dd class="disclaimer__text">${disclaimer.description}</dd>
                            </div>` : ''
                        }
                    </dl>
                    <div class="dealer-form__submit-container">
                        <button class="dealer-form__submit button button_primary button--wide"
                                type="submit"
                                data-analytics-trigger="cta-form-submit">
                            ${submitButtonLabelText}
                        </button>
                    </div>
                </div>
                <div id="error-message-display-info" class="dealer-form__error-message"></div>
            </div>
        </div>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

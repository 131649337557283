/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

/**
 * @method renderDistance
 * @description Checks to see whether the distance copy should be displayed
 * @param distance {String} Distance value to be displayed
 * @param config {Object} Config containing condition to show distance
 * @return {HTML, string} Distance element is returned if condition is true, else blank string
 */
const renderDistance = (distance, config) => {
    if (config.showDistance) {
        return `<span class="dealer-result__dealer-distance">${distance}</span>`;
    }
    return '';
};

/**
 * @method renderEyebrow
 * @description Checks to see whether suggested dealer / preferred dealer eyebrow should be displayed
 * @param isSuggested {Boolean} Boolean for suggested eyebrow
 * @param suggestedCopy {String} Copy for suggested eyebrow
 * @return {HTML, string} Returns suggested eyebrow if condition is true, else blank string
 */
const renderEyebrow = (isSuggested, isPreferred, { preferredDealer }, inlinePreferredDealer) => {
    if (isPreferred && inlinePreferredDealer) {
        return `<span class="dealer-result__eyebrow is-preferred">${preferredDealer}</span>`;
    }
    return '';
};

/**
 * @function renderPhoneNumbers
 * @description Renders the markup necessary for phone numbers
 */
function renderPhoneNumbers(contact, content, isDealership) {
    // const phoneSet = ['phone', 'servicePhone', 'partsPhone'];
    const phoneSet = ['phone'];
    return contact.reduce((markup, c) => {
        if (phoneSet.indexOf(c.type) !== -1 && c.value) {
            markup += `<div>
                <span class="dealer-result__phone-number-data__name">${isDealership ? content.phoneLabels[c.type] : content.collisionPhoneLabels[c.type]}:</span>
                <span>
                    <a href="tel:${c.value}" class="link link_plain-link" data-analytics-trigger="phone">${c.value}</a>
                </span>
            </div>`;
        }
        return markup;
    }, '');
}

/**
 * Template for displaying individual dealer result modules
 * @param dealer {Object} Dealer object containing dealer information
 * @param dealer.name {String} Name of the dealer
 * @param dealer.distance {String} Distance of dealer from search area
 * @param dealer.city {String} Dealer city
 * @param dealer.state {String} Dealer state or province
 * @param dealer.zip {String} Dealer zip code
 * @param dealer.isAOIDealer {Boolean} Check to see if AOI dealer
 * @param dealer.hasVanCare {Boolean} Dealer has VanCARE Express badge
 * @param dealer.hasVanCareMobile {Boolean} Dealer has VanCARE Mobile badge
 * @param config {Object} Config object
 * @param content {Object} Localization object
 */
export default ({ name, address, distance, city, state, zip, contact, isAOIDealer, isDealership, isPreferred = false, hasVanCare, hasVanCareMobile }, config, content, ctaLink, leadsLink) => htmlNode`
    <div class="dealer-result ${config.isPreferredHidden ? 'hide' : ''}"  data-analytics-container="{ 'dealer-info': '${name}', preferred: '${isPreferred}' }" data-analytics-trigger="cta" >        
        <div class="wrapper dealer-result__wrapper ${isAOIDealer || (isPreferred && config.inlinePreferredDealer) ? 'dealer-result__wrapper--has-eyebrow' : ''}">
            <div class="dealer-result__consecutive"></div>
            <div class="dealer-result__info-container">
                <address class="dealer-result__address-container">
                    <div class="dealer-result__address-inner">
                        <div class="dealer-result__address-heading">
                            <button type="button" class="dealer-result__dealer-name">
                                ${isPreferred && !config.inlinePreferredDealer ? `<span class="offscreen">${content.preferredDealer}</span>` : ''}
                                ${renderEyebrow(isAOIDealer, isPreferred, content, config.inlinePreferredDealer)}
                                <span class="dealer-result__dealer-name-in">${name}</span>
                                ${hasVanCare && config.showVanCare ? `<span class="dealer-result__van-care" aria-label="${content.vanCareAriaLabel}" title="${content.vanCareTitleLabel}"></span>` : ''}
                                ${hasVanCareMobile && config.showVanCare ? `<span class="dealer-result__van-care dealer-result__van-care--mobile" aria-label="${content.vanCareMobileAriaLabel}" title="${content.vanCareMobileTitleLabel}"></span>` : ''}
                            </button>
                            ${renderDistance(distance, config)}
                        </div>
                        <div class="dealer-result__address">
                            ${config.showFullAddress ? `${address} <br />` : ''}
                            ${city}, ${state}, ${zip}
                        </div>
                    </div>
                </address>
                ${config.showPhoneNumbers ?
                    `<div class="dealer-result__phone-number-data">
                        ${renderPhoneNumbers(contact, content, isDealership)}
                    </div>` : ''
    }
                ${config.showSecondaryCTA ?
                    `<div class="dealer-result__cta-container">
                        ${ctaLink.href ? `<a href="${ctaLink.href}" class="dealer-result__secondaryCTA link link_plain-link" data-analytics-trigger="cta">${ctaLink.text}</a>` : ''}
                        ${leadsLink.href ? `<a href="${leadsLink.href}" class="dealer-result__secondaryCTA link link_plain-link" data-analytics-trigger="cta">${leadsLink.text}</a>` : ''}
                    </div>` : ''
    }
            </div>
            ${config.showArrowIndicator ? '<div class="dealer-result__arrow-indicator"></div>' : ''}
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

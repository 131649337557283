import {
    GoogleMap,
    googleLocationsApi
} from 'partials/google-maps';
import { renderer, noop } from 'utils';

/**
 * CLASSES{}
 * Stores class names for use in the DOM
 */
const CLASSES = {
    MAP: 'dealer-locator-module__map',
    HIDE_BTN: 'dealer-locator-module__map--hidemap-cta'
};

/**
 * @function getCityStateFromAddressComponent
 * @description Gets the city, state from the address_components of a GeocoderResult
 * @param {google.maps.GeocoderResult.address_components} address - Address components
 */
function getCityStateFromAddressComponent(address) {
    return {
        city: address[0].long_name,
        state: address.find((component) => component.types.indexOf('administrative_area_level_1') !== -1).short_name
    };
}

/**
 * View responsible for rendering the dealer search map and managing the view state
 * of displaying dealers on the map
 */
export default class DealerSearchMap {
    /**
     * @constructor
     * @description Creates new DealerSearchMap
     * @param element {Node} Element to render the map to
     * @param config {Object} Google Maps configuration object
     * (see configurable values from https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapOptions)
     * @param {Object} content - Localization object
     * @param {Object} variantOptions - App Variant Options
     * @param {Function} markerClickCallback - Click callback for markers
     */
    constructor(element, config, content, toggleFn, variantOptions, markerClickCallback) {
        this.config = config;
        this.element = element;
        this.content = content;
        this.toggle = toggleFn;
        this.variantOptions = variantOptions;
        this.markerClickCallback = markerClickCallback;
        this.searchTerm = null;
        this.searchByType = null;
        this.searchLocation = null;
        this.map = null;
        this.preferredDealer = null;
        this.isMapLoadedCallback = noop;
        this.markerCount = 0;

        this.setIcons();
        this.createHideButton = this.createHideButton.bind(this);
        this.markerClick = this.markerClick.bind(this);
        this.isMapLoaded = this.isMapLoaded.bind(this);

        this.createMap();
    }

    /**
     * @method destroy
     * @description Destroys the map module
     */
    destroy() {
        this.map.destroyMap();
        this.element.remove();
    }

    /**
     * @method setIcons
     * @description Sets icon urls according to brand
     */
    setIcons() {
        const brand = this.config.brand && this.config.brand.toLowerCase() || 'mb';

        this.icons = {
            default: {
                url: `/etc/designs/mb-vans/images/icon-${brand}-map-pin.svg`
            },
            dealerDetail: {
                url: `/etc/designs/mb-vans/images/icon-${brand}-dealer-logo.svg`
            },
            preferred: {
                url: `/etc/designs/mb-vans/images/icon-${brand}-dealer-locator-preferred.svg`
            },
        };
    }

    /**
     * @method setPreferredDealer
     * @description Sets the preferred dealer
     */
    setPreferredDealer(dealer) {
        this.preferredDealer = dealer;
        if (this.preferredDealer) {
            this.preferredDealer.isPreferred = true;
        }
    }

    /**
     * @method setSearchByType
     * @description Sets the search by type
     */
    setSearchByType(searchByType) {
        this.searchByType = searchByType;
    }

    /**
     * @method setSearchTerm
     * @description Sets the search term
     */
    setSearchTerm(searchString) {
        this.searchTerm = searchString;
    }

    /**
     * @method setSearchLocation
     * @description Sets the search location
     */
    setSearchLocation(searchLocation) {
        this.searchLocation = searchLocation;
    }

    /**
     * @method addDealers
     * @description Adds markers to map for:
     * 1. Current search query
     * 2. Preferred Dealer Pin
     * 3. Dealer Pins
     * @param {Array} dealers - Array of dealer objects
     * @param {Boolean} resetMarkers - Checks if a list is being created or appended
     */
    addDealers(dealers, resetMarkers = true) {
        if (resetMarkers) {
            this.markerCount = 0;
        }

        // Since dealers has the preferred dealer included
        // ignore it when generating markers
        let index = 0;
        const markers = dealers.reduce((dealerList, dealer) => {
            if (!dealer.isPreferred || this.config.includePreferredMarker) {
                dealerList.push({
                    ...dealer,
                    icon: this.icons.default,
                    label: this.markerCount + index + 1,
                    type: 'DEALER'
                });
                index += 1;
            }

            return dealerList;
        }, []);

        this.addCurrentSearchToMarkers(markers)
            .then(this.addPreferredDealerToMarkers.bind(this, markers, true))
            .then(() => {
                if (resetMarkers) {
                    this.map.destroyMarkers();
                }
            })
            .then(this.map.addMarkers.bind(this.map, markers, false))
            .then(this.markerCount += dealers.length)
            .then(this.isMapLoaded)
            .catch((e) => {
                console.error('Could not add dealers to map', e);
            });
    }

    /**
     * @method addDealerDetail
     * @description Add dealer associated with dealer detail.
     * @param {Object} dealer - Dealer object
     * @param {Boolean} isPreferred - Preferred Flag
     */
    addDealerDetail(dealer, isPreferred = false) {
        this.map.addMarkers([{
            ...dealer,
            label: null,
            icon: isPreferred ? this.icons.preferred : this.icons.dealerDetail,
            type: 'DEALER'
        }], false);
    }

    /**
     * @method destroyMarker
     * @description Destroys the marker associated with the dealer ID passed in
     * @param {string} dealerId - ID of the dealer
     */
    destroyMarker(dealerId) {
        this.map.destroyMarker(dealerId);
    }

    /**
     * @method addCurrentSearchToMarkers
     * @description Adds an object with location and name of current search term to markers
     * @param {Array} markers - Array of markers
     * @returns {Promise} Resolves when current search term's properties are obtained
     */
    addCurrentSearchToMarkers(markers) {
        return new Promise((resolve) => {
            if (!markers.filter((marker) => marker.type === 'CURRENT_SEARCH_MARKER').length) {
                this.getCurrentSearchProperties()
                    .then((data) => {
                        if (data) {
                            markers.push({
                                location: data.location,
                                name: `${data.name}`,
                                type: 'CURRENT_SEARCH_MARKER'
                            });
                        }
                    })
                    .then(resolve);
            }
        });
    }

    /**
     * @method getCurrentSearchProperties
     * @description Gets the current search location's coordinates and name.
     * This coordinates will be used to plot a pin with search location.
     * When searchByType = place, use google api to get associated city, state for the hover state
     * of the pin.
     * @return {Promise} A promise which resolves to { location: { lat, lng }, name: search string }
     */
    getCurrentSearchProperties() {
        return new Promise((resolve) => {
            // When searching by place the associated placeID is saved in DealerLocatorModel. Since
            // the city, state is required to be shown on the pin, use google geocode API to get
            // lat/lng and address_components which will be compiled into city, state.
            if (this.searchByType === 'place') {
                googleLocationsApi
                    .getPlaceInfo(this.searchLocation)
                    .then((place) => ({
                        location: {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        },
                        name: (({ city, state }) => (`${city}, ${state}`))(getCityStateFromAddressComponent(place.address_components))
                    }))
                    .then(resolve);
            }

            // When searching by ZIP, the pin only has to show the ZIP, which is readily available
            // in the DealerLocatorModel. Use google geo code API get the associated lat/lng.
            if (this.searchByType === 'zip') {
                googleLocationsApi
                    .getAddressCoordinates(this.searchLocation, this.config.country)
                    .then((location) => ({
                        location,
                        name: this.searchLocation
                    }))
                    .then(resolve);
            }

            if (this.searchByType === 'lcp') {
                resolve();
            }

            // When in deafult search, searchByType is null. This search view does not need a
            // location pin, hence resolve with undefined
            if (this.searchByType === null) {
                resolve();
            }
        });
    }

    /**
     * @method addPreferredDealerToMarkers
     * @description If the model has preferredDealer, add the preferred dealer to the array passed
     * in
     * @param {array} dealers - List of dealers into which the preferred dealer marker should be pushed
     */
    addPreferredDealerToMarkers(dealers = []) {
        if (this.preferredDealer) {
            dealers.push({
                ...this.preferredDealer,
                icon: this.icons.preferred,
                type: 'PREFERRED_DEALER',
                label: null
            });
        }
    }

    /**
     * @method createMap
     * @description Instantiates a Google Map object to render to the map element
     */
    createMap() {
        const mapProperties = {
            element: this.element,
            config: {
                streetViewControl: false,
                fullscreenControl: false,
                enableHoverInteractions: false,
                leftOffset: 510,
                enabledLarge: this.config.enabledLarge
            },
            content: this.content,
            variantOptions: this.variantOptions,
            markerClickCallback: this.markerClick,
            onLoadCallback: () => {
                this.isMapLoadedCallback();
            }
        };

        if (this.config.includeHideCustomControl) {
            mapProperties.customControls = [{
                position: GoogleMap.CUSTOM_CONTROL_POSITIONS.TOP_LEFT,
                label: this.createHideButton(),
                onClick: this.toggle
            }];
        }

        this.map = new GoogleMap(mapProperties);
    }

    /**
     * @method isMapLoaded
     * @description Checks if the map is loaded by returning a promise.
     * This promise is resolved if the map is loaded. In the event of
     * map not being loaded already, the promise resolve method is set
     * to an instance variable, which gets executed when the map is
     * loaded in the `onLoadCallback` within the GoogleMap instance.
     * @ref: GoogleMap#onLoadCallback
     */
    isMapLoaded() {
        return new Promise((resolve) => {
            this.isMapLoadedCallback = resolve;
            if (this.map.isTilesLoaded) {
                resolve();
            }
        });
    }

    /**
     * @method resetMap
     * @description Resets the map by destroying the markers and centering the map
     */
    resetMap() {
        if (this.map.maps) {
            this.map.destroyMarkers();
            this.map.centerMap(null, true);
        }
    }

    /**
     * @method fitBounds
     * @description Calls the fit bounds API method of google maps
     */
    fitBounds() {
        this.map.fitBounds();
    }

    /**
     * @method markerClick
     * @description Click handler for markers. Calls the markerClickCallback
     */
    markerClick(markerInfo) {
        if (this.markerClickCallback) {
            this.markerClickCallback(markerInfo);
        }
    }

    /**
     * @method createHideButton
     * @description Function that creates and returns a new div element with "hide" copy
     */
    createHideButton() {
        return renderer.fromTemplate(`<span class="${CLASSES.HIDE_BTN}">${this.content.hideMapCTA}</span>`);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import {
    isCharacterPresent,
    isEmail,
    isEmpty,
    isFormattedPhoneNumber,
    isInDateRange,
    isMatchValue,
    isMaxLength,
    isMaxValue,
    isMinLength,
    isMinValue,
    isPhoneAllSameCharacter,
    isPhoneNumber,
    isValidAge,
    isValidDate,
    isValidFileSize,
    isValidFileType,
    isValidSSN,
    isValidZip
} from '@mbusa/now-ui-utils.validate';

import { formatNumber } from 'utils';

/**
 * @const VALIDATION_TYPE
 * @description Collection of constant values for validation types
 */
export const VALIDATION_TYPE = {
    ACCEPTED_FILE_TYPES: 'acceptedFileTypes',
    AGE_RESTRICTION: 'ageRestriction',
    CHARACTER_PRESENT: 'characterPresent',
    CUSTOM: 'custom',
    DATE: 'date',
    DATE_RANGE: 'dateRange',
    EMAIL: 'email',
    MATCH: 'match',
    MAX_FILE_SIZE: 'maxFileSize',
    MAX_LENGTH: 'maxLength',
    MAX_VALUE: 'maxValue',
    MAX_VALUE_FORMATTED: 'maxValueFormatted',
    MIN_LENGTH: 'minLength',
    MIN_VALUE: 'minValue',
    MIN_VALUE_FORMATTED: 'minValueFormatted',
    PHONE: 'phone',
    PHONE_ALL_SAME_CHARACTER: 'phoneAllSameCharacter',
    PHONE_FORMATTED: 'phoneFormatted',
    REQUIRED: 'required',
    SSN: 'ssn',
    ZIP_CODE: 'zipcode'
};

/**
 * @const VALIDATION_EVENT
 * @description Collection of constant values for validation events
 */
export const VALIDATION_EVENT = {
    MATCH: 'input-validator-match'
};

/**
 * @method verify
 * @description Get value of restricted characters for validation type
 * @param value {String} Value of input
 * @param validationType {String} Validation type
 * @param inputConfig {object} list of minLength and maxLength for element
 * @param {Object} validationConfig - Config for validation
 * @returns {Boolean} True if value passes validation
 */
export function verify(value, validationType, inputConfig, validationConfig) {
    const numberValue = formatNumber.toNumber(value, validationConfig?.country, validationConfig?.language);

    let pass = true;

    if (validationType === VALIDATION_TYPE.CUSTOM) {
        if (typeof validationConfig.callback === 'function') {
            pass = validationConfig.callback(value);
        } else {
            console.error('No callback function available to validate');
        }
    } else if (validationType === VALIDATION_TYPE.EMAIL) {
        pass = isEmail(value);
    } else if (validationType === VALIDATION_TYPE.MAX_LENGTH && inputConfig.maxLength) {
        pass = isMaxLength(inputConfig.maxLength, value);
    } else if (validationType === VALIDATION_TYPE.MAX_VALUE) {
        pass = isMaxValue(inputConfig.maxValue, value);
    } else if (validationType === VALIDATION_TYPE.MAX_VALUE_FORMATTED) {
        pass = !value ? true : !isNaN(parseFloat(numberValue)) && isMaxValue(inputConfig.maxValue, numberValue);
    } else if (validationType === VALIDATION_TYPE.MIN_LENGTH && inputConfig.minLength) {
        pass = isMinLength(inputConfig.minLength, value);
    } else if (validationType === VALIDATION_TYPE.MIN_VALUE) {
        pass = isMinValue(inputConfig.minValue, value);
    } else if (validationType === VALIDATION_TYPE.MIN_VALUE_FORMATTED) {
        pass = !value ? true : !isNaN(parseFloat(numberValue)) && isMinValue(inputConfig.minValue, numberValue);
    } else if (validationType === VALIDATION_TYPE.PHONE) {
        pass = isPhoneNumber(value);
    } else if (validationType === VALIDATION_TYPE.PHONE_FORMATTED) {
        pass = !isEmpty(value) ? isFormattedPhoneNumber(value) : true;
    } else if (validationType === VALIDATION_TYPE.REQUIRED) {
        pass = !isEmpty(value);
    } else if (validationType === VALIDATION_TYPE.ZIP_CODE) {
        pass = isValidZip(value);
    } else if (validationType === VALIDATION_TYPE.DATE) {
        pass = !value ? true : isValidDate(value);
    } else if (validationType === VALIDATION_TYPE.DATE_RANGE) {
        pass = !value ? true : isInDateRange(value, validationConfig);
    } else if (validationType === VALIDATION_TYPE.MATCH) {
        pass = !isEmpty(value) && !isEmpty(validationConfig.compareValue) ?
            isMatchValue(validationConfig.compareValue, value) :
            true;
    } else if (validationType === VALIDATION_TYPE.SSN) {
        pass = isValidSSN(value);
    } else if (validationType === VALIDATION_TYPE.AGE_RESTRICTION) {
        pass = isValidAge(value, validationConfig.ageRestriction);
    } else if (validationType === VALIDATION_TYPE.ACCEPTED_FILE_TYPES) {
        pass = isValidFileType(value, inputConfig.acceptedFileTypes);
    } else if (validationType === VALIDATION_TYPE.MAX_FILE_SIZE) {
        pass = isValidFileSize(value, validationConfig.maxFileSize);
    } else if (validationType === VALIDATION_TYPE.PHONE_ALL_SAME_CHARACTER) {
        pass = !isPhoneAllSameCharacter(value);
    } else if (validationType === VALIDATION_TYPE.CHARACTER_PRESENT) {
        pass = !isCharacterPresent(validationConfig.character, value);
    }

    return pass;
}

/**
 * export public api
 */
export default {
    VALIDATION_TYPE,
    verify
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * An api for creating ToolTip view from specially tagged DOM elements
 *
 * Example markup for creating a ToolTip from html tags:
 *
 * <div data-tool-tip=""
 *      data-position="[string (left, center, right)]"
 *      data-size="[string (default, custom)]"
 *      data-label="[string]">
 *      <div data-tool-tip-content="">
 *          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, repellat!</p>
 *      </div>
 * </div>
 */
// Local dependencies
import ToolTip from './../views/ToolTip';

/**
 * @const ATTRIBUTES
 * @description Collection of data attributes for querying
 * @type {{TOOL_TIP: string, POSITION: string, SIZE: string, LABEL: string}}
 */
const ATTRIBUTES = {
    TOOL_TIP: 'data-tool-tip',
    POSITION: 'data-position',
    SIZE: 'data-size',
    LABEL: 'data-label',
    CONTENT: 'data-tool-tip-content'
};

/**
 * @method createToolTip
 * @description Parses a DOM element and instantiates a ToolTip from it
 * @param toolTipElement {Node} Element to create tool-tip from
 * Note: this element should match the markup in the example in
 * the documentation above
 * @return {ToolTip} ToolTip instance
 */
function createToolTip(toolTipElement) {
    const toolTipData = toolTipElement.dataset;
    const content = toolTipElement.querySelector(`[${ATTRIBUTES.CONTENT}]`);

    const toolTip = new ToolTip(content, {
        position: toolTipData.position,
        label: toolTipData.label,
        size: toolTipData.size,
        analyticsTrigger: toolTipData.analyticsTrigger
    });

    toolTipElement.parentNode.replaceChild(toolTip.render(), toolTipElement);

    return toolTip;
}

/**
 * export public api
 */
export default {
    createToolTip
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import PropTypes from 'prop-types';

/**
 * @description Property types for the aftermarket product property
 * @type propTypes.description {string} Description of product
 * @type propTypes.disclaimer {object} Disclaimer object
 * @type propTypes.disclaimer.data {object} Disclaimer data object
 * @type propTypes.disclaimer.label {string} Disclaimer label content
 * @type propTypes.moreInfo {string} Content for more info
 * @type propTypes.moreInfoLinks {array} More info array containing link objects
 * @type propTypes.moreInfoLinks.link {object} more info link object
 * @type propTypes.moreInfoLinks.link.linkText {string} content for link
 * @type propTypes.moreInfoLinks.link.linkUrl {string} URI for link
 * @type propTypes.moreInfoLinks.link.videoId {string} Identifier for video key
 * @type propTypes.moreInfoLinks.isDownload {boolean} Indicator if link is downloadable
 * @type propTypes.products {object} Product options object
 * @type propTypes.products.ctaAdd {string} Add cta content
 * @type propTypes.products.ctaAddLabel {string} Add label content
 * @type propTypes.products.ctaRemove {string} Remove cta content
 * @type propTypes.products.ctaRemoveLabel {string} Remove label content
 * @type propTypes.products.description {string} Description content
 * @type propTypes.products.id {string} product identifier
 * @type propTypes.products.image {object} Image object
 * @type propTypes.products.image.fileReference {string} URI path for image
 * @type propTypes.products.isSuggested {boolean} Boolean indicator flag if option if preferred
 * @type propTypes.products.isSuggestedDesc {string} Content for preferred indicator
 * @type propTypes.products.name {string} Product name
 * @type propTypes.products.packageDisclaimer {object} Disclaimer object
 * @type propTypes.products.packageDisclaimer.data {object} Disclaimer object data
 * @type propTypes.products.packageDisclaimer.label {string} Disclaimer label
 * @type propTypes.products.packageTypeDescription {string} Description of package
 * @type propTypes.products.price {object} Price object
 * @type propTypes.products.price.formattedValue {string} Price formatted
 * @type propTypes.products.price.value {number} Price value as number
 * @type propTypes.products.priceMonthly {string} Content per month
 * @type propTypes.products.pricingNoteMonthly {string} Content for monthly pricing
 * @type propTypes.products.pricingNoteTotal {string} Content for pricing note
 * @type propTypes.products.productDisclaimer {object} Disclaimer object
 * @type propTypes.products.productDisclaimer.data {object} Disclaimer content
 * @type propTypes.products.productDisclaimer.label {string} Disclaimer label
 * @type propTypes.products.productType {string} Type of product option
 * @type propTypes.products.serviceLength {number} Service length of product
 * @type propTypes.products.title {string} Title of product option
 * @type propTypes.title {string} Title of product
 * @type propTypes.type {string} Type of product
 * @type propTypes.vehicleCondition {string} Condition of vehicle
 */
export default PropTypes.objectOf(PropTypes.shape({
    description: PropTypes.string,
    disclaimer: PropTypes.shape({
        data: PropTypes.object,
        label: PropTypes.string
    }),
    moreInfo: PropTypes.string,
    moreInfoLinks: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                link: PropTypes.shape({
                    linkText: PropTypes.string,
                    linkUrl: PropTypes.string
                }),
                isDownload: PropTypes.bool
            }),
            PropTypes.shape({
                link: {
                    linkText: PropTypes.string,
                    videoId: PropTypes.string
                }
            })
        ])
    ),
    products: PropTypes.arrayOf(PropTypes.shape({
        ctaAdd: PropTypes.string,
        ctaAddLabel: PropTypes.string,
        ctaRemove: PropTypes.string,
        ctaRemoveLabel: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string,
        image: PropTypes.shape({
            fileReference: PropTypes.string
        }),
        isSuggested: PropTypes.bool,
        isSuggestedDesc: PropTypes.string,
        name: PropTypes.string,
        packageDisclaimer: PropTypes.shape({
            data: PropTypes.object,
            label: PropTypes.string
        }),
        packageTypeDescription: PropTypes.string,
        price: PropTypes.shape({
            formattedValue: PropTypes.string,
            value: PropTypes.number
        }),
        priceMonthly: PropTypes.string,
        pricingNoteMonthly: PropTypes.string,
        pricingNoteTotal: PropTypes.string,
        productDisclaimer: PropTypes.shape({
            data: PropTypes.object,
            label: PropTypes.string
        }),
        productType: PropTypes.string,
        serviceLength: PropTypes.number,
        title: PropTypes.string
    })),
    title: PropTypes.string,
    type: PropTypes.string,
    vehicleCondition: PropTypes.string
})).isRequired;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

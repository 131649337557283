import { renderer } from 'utils';
import dealerResultsListTemplate from './../templates/dealerResultsListTemplate';
import Dealer from './Dealer';

/**
 * Container view for the list of dealers
 */
export default class DealerResultsList {
    /**
     * @constructor
     * @param dealers {Array} List of dealers from API
     */
    constructor(dealers = [], content, errorMessageId) {
        this.content = content;
        this.dealers = [];
        this.errorMessageId = errorMessageId;
        this.element = dealerResultsListTemplate()({ getNode: true });

        this.createDealers(dealers, content);
    }

    /**
     * @method createDealers
     * @param dealers {Array} List of dealers
     */
    createDealers(dealers, content) {
        dealers.forEach((dealer) => {
            const dealerView = new Dealer(dealer, content, this.errorMessageId);
            renderer.append(dealerView.render(), this.element);
            this.dealers.push(dealerView);
        });
    }

    /**
     * @method showErrorMessageOnDealer
     * @description display the error message on specific dealer result
     */
    showErrorMessageOnDealer(dealerId) {
        const dealerErrorContainer = this.element.querySelector(`#dealerError-${dealerId}`);
        if (dealerErrorContainer) {
            dealerErrorContainer.innerHTML = `<div class="error">${this.content.dealerValidationError}</div>`;
        }
    }

    /**
    * @method set focus
    * @description gives DOM element focus
    */
    set focus(focus = true) {
        if (focus && this.dealers.length) {
            this.dealers[0].setErrorState(true);
        }
    }

    /**
     * @method render
     * @description Returns the element associated
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @class SpecialtyPageModel
 * @description Data model for SpecialtyPage app
 */
export default class SpecialtyPageModel {
    /**
     * @method set allChapters
     * @description sets  model data and adds the index to each chapter
     * @param chapters {Array} array of chapter data objects
     */
    static set allChapters(chapters) {
        this.chapters = chapters.map((chapter, index) => {
            chapter.index = index;
            chapter.chapterNumber = `0${index + 1}.`;
//            chapter.deeplink = chapter.deeplink || chapter.chapterNumber;
            return chapter;
        });
    }

    /**
     * @method get allChapters
     * @description get data for all chapters
     * @return {Array} all chapter data objects
     */
    static get allChapters() {
        return this.chapters;
    }

    /**
     * @method getChapterByName
     * @description get a chapter from the model based on the chapter name (deepLink)
     * @param name {String} name/deepLink of chapter
     * @return {Object} chapter data object
     */
    static getChapterByName(name) {
        return this.chapters.find((chapter) => (
            chapter.deepLink === name
        )) || this.chapters[0];
    }

    /**
     * @method get chapterCount
     * @description get length of chapters array
     * @return {Number} length of chapters array
     */
    static get chapterCount() {
        return this.chapters.length;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for AMG Specialty Page size violation message
 * Uses ES6 template literal to composite the markup
 */
import { htmlNode } from 'utils';

export default (localization) => htmlNode`
    <div class="specialty-page__size-violation">
        <p class="specialty-page__size-violation-copy specialty-page__size-violation-copy--strong" tabindex="0">${localization.screenSizeAlertCopy1}</p>
        <p class="specialty-page__size-violation-copy" tabindex="0">${localization.screenSizeAlertCopy2}</p>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a contact info section view.
 * Uses ES6 template literal to composite the markup for
 * contact info section within Dealer Form view
 */
export default (content) => htmlNode `
    <div class="dealer-form__contact-info">
        <div class="dealer-form__personal-info">
            <div class="dealer-form__input-group">
                <div class="dealer-form__input-container">
                    <div dealer-form-first-name=""></div>
                </div>
                <div class="dealer-form__input-container">
                    <div dealer-form-last-name=""></div>
                </div>
            </div>
            <div class="dealer-form__input-group">
                <div class="dealer-form__input-container">
                    <div dealer-form-email=""></div>
                </div>
                <div class="dealer-form__input-container">
                    <div dealer-form-phone=""></div>
                </div>
            </div>
        </div>
        <fieldset class="dealer-form__radio-group">
            <legend class="dealer-form__field-heading">
                ${content.preferredContactMethod}
            </legend>
            <div dealer-form-preferred-contact=""></div>
        </fieldset>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

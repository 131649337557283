// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom radio button group control
 * Uses ES6 template literal to composite the markup for a group of custom radio button controls
 */
export default ({
                    analyticsTrigger = '',
                    inputClassNames = '',
                    displayInline = '',
                    id = '',
                    label = '',
                    labelOffscreen = false
}) => htmlNode`
    <div class="form__radio-control-group-container ${inputClassNames ? `${inputClassNames}` : ''}"
         data-analytics-trigger=${analyticsTrigger}>
        <fieldset>
            <legend class=${labelOffscreen ? 'offscreen' : ''}>
                ${label}
            </legend>
            <div class="form__radio-card-control-group ${displayInline ? 'form__radio-card-control-group--inline' : ''}"></div>
            <span class="form__input-error" id="error-${id}"></span>
        </fieldset>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

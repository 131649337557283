// Partial dependencies
import { screen } from 'utils';
import { Slider } from 'partials/slider';
import { SLIDER_TYPE } from '../partials/slider/views/Slider';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    ITEMS_CONTAINER: 'featured-social-tile__list',
    READY: 'featured-social--ready',
    SLIDER_LIST: 'featured-social-tile__slider'
};

const VIEW_TYPE = {
    IN_LINE: 'in line',
    SLIDER: 'slider'
};
/**
 * FeaturedSocial Module
 * This module updates the view when tile style is Slider
 */
export default class FeaturedSocial {
    constructor(element) {
        this.element = element;
        this.view = VIEW_TYPE.IN_LINE;
        this.sliderItems = [];
        this.onResize = this.onResize.bind(this);
        this.cacheDOMElements.bind(this);
        this.init();
    }

    init() {
        this.cacheDOMElements();
        this.attachEvents();
        this.onResize();
        this.element.classList.add(CLASSES.READY);
    }

    attachEvents() {
        screen.addResizeListener(this.onResize);
    }

    detachEvents() {
        screen.removeResizeListener(this.onResize);
    }

    onResize() {
        const isLarge = screen.gte(screen.SIZES.LARGE);
        if (isLarge) {
            this.renderInline();
        } else {
            this.renderSlider();
        }
    }
    /**
    * @method cacheDOMElements
    * @description Adds to the itemsContainer all of the tiles in container at the ul level, to be iterated over
    */
    cacheDOMElements() {
        this.itemsContainer = this.element.querySelector(`.${CLASSES.ITEMS_CONTAINER}`);
    }

   /**
    * @method renderInline
    * @description Switches from the slider class on mobile to the inline display of the tiles on desktop
    */
    renderInline() {
        if (this.view !== VIEW_TYPE.IN_LINE) {
            this.view = VIEW_TYPE.IN_LINE;
            this.element.removeChild(this.sliderPlugin.element);
            this.sliderItems.forEach((tileElm) => {
                this.itemsContainer.appendChild(tileElm);
            });
            this.element.appendChild(this.itemsContainer);
        }
    }
    /**
     * @method renderSlider
     * @description Creates an array of badge HTML elements and instantiates the Slider
     */
    renderSlider() {
        if (this.view !== VIEW_TYPE.SLIDER) {
            this.view = VIEW_TYPE.SLIDER;

            if (this.itemsContainer.children.length > 0 && !this.sliderItems.length) {
                [].slice.call(this.itemsContainer.children).forEach((tileElm) => {
                    this.sliderItems.push(tileElm);
                });
            }
            // Copy classes assigned from the grid container to the slider.
            const classNames = [];
            let i = 0;
            while (this.itemsContainer.classList.item(i)) {
                classNames.push(this.itemsContainer.classList.item(i));
                i += 1;
            }

            this.sliderPlugin = new Slider(this.sliderItems, { previous: '', next: '' }, false, `${CLASSES.SLIDER_LIST} ${classNames.join(' ')}`, SLIDER_TYPE.STEP, true);
            this.element.removeChild(this.itemsContainer);
            this.element.appendChild(this.sliderPlugin.render());
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

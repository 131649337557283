import { renderer } from 'utils';

import { InputTextControl, InputRadioGroupControl } from 'partials/input-control';
import { SelectControl } from 'partials/select-control';
import { FormControl, FormError } from 'partials/form-control';

import { submitTakataLead } from './../api/recallsAPI';
import {
    form as stayInformedFormTemplate,
    success as stayInformedSuccessTemplate
} from './../templates/stayInformedFormTemplate';

const CLASSES = {
    ERROR_CONTAINER: 'recalls-stay-informed__error-container',
    FIELDS_CONTAINER: 'recalls-stay-informed__fields-container',
    FORM_CONTAINER_ELEMENT: 'recalls-stay-informed__form-container',
    FORM_ELEMENT: 'recalls-stay-informed__form',
    MAIN_CONTAINER: 'recalls-stay-informed__container'
};

/**
 * View responsible for rendering the stay informed form for takata recall
 * vehicles
 */
export default class StayInformedForm {
    /**
     * @constructor
     * @param {Object} content - Content object
     * @param {Object} config - config object
     * @param {string} vin - VIN for the vehicle which the form is being rendered for
     * @param {object[]} recalls - An array of recall objects
     */
    constructor(content, config, vin, recalls) {
        this.content = content;
        this.config = config;
        this.vin = vin;
        this.recalls = recalls;

        this.element = stayInformedFormTemplate(content, vin)({ getNode: true });

        this.serverError = null;
        this.validationError = null;
        this.isPosting = false; // flag used to prevent form resubmission during last req process

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);
        this.onValidationError = this.onValidationError.bind(this);

        this.cacheDOM();
        this.createInputs();
        this.createForm();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements required for this form
     */
    cacheDOM() {
        this.errorContainerElm = this.element.querySelector(`.${CLASSES.ERROR_CONTAINER}`);
        this.formContainerElm = this.element.querySelector(`.${CLASSES.FORM_CONTAINER_ELEMENT}`);
        this.formElm = this.element.querySelector(`.${CLASSES.FORM_ELEMENT}`);
        this.fieldsContainerElm = this.element.querySelector(`.${CLASSES.FIELDS_CONTAINER}`);
        this.mainContainerElm = this.element.querySelector(`.${CLASSES.MAIN_CONTAINER}`);
    }

    /**
     * @method createForm
     * @description Instantiates FormControl responsible for managing state of form
     */
    createForm() {
        const formConfig = {
            element: this.formElm,
            onSubmit: this.onSubmit,
            onInvalid: this.onValidationError
        };

        this.form = new FormControl(formConfig);

        this.form.register(this.firstNameInput);
        this.form.register(this.lastNameInput);
        this.form.register(this.emailInput);
        this.form.register(this.mobilePhoneInput);
        this.form.register(this.addressInput);
        this.form.register(this.cityInput);
        this.form.register(this.stateInput);
        this.form.register(this.zipInput);
        this.form.register(this.preferredContactMethod);

        // render the form control to the dom
        this.formContainerElm.appendChild(this.form.render());
    }

    /**
     * @method createInputs
     * @description Creates the inputs for this form
     */
    createInputs() {
        this.firstNameInput = new InputTextControl({
            type: 'text',
            required: true,
            labelText: this.content.firstName.label,
            maxLength: 50,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.firstName.error
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.firstName.error
                }
            ],
            restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC]
        });
        this.lastNameInput = new InputTextControl({
            type: 'text',
            required: true,
            labelText: this.content.lastName.label,
            maxLength: 50,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.lastName.error
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.lastName.error
                }
            ],
            restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC]
        });
        this.emailInput = new InputTextControl({
            type: 'text',
            required: true,
            labelText: this.content.email.label,
            maxLength: 50,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.email.error
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.email.error
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.EMAIL,
                    errorMessage: this.content.email.error
                }
            ],
            restrictions: [InputTextControl.RESTRICTION_TYPE.EMAIL_SPECIAL_CHARS]
        });
        this.mobilePhoneInput = new InputTextControl({
            type: 'tel',
            required: true,
            labelText: this.content.phone.label,
            maxLength: 14,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.phone.error
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.PHONE_FORMATTED,
                    errorMessage: this.content.phone.error
                }
            ],
            formatting: [
                {
                    type: InputTextControl.FORMAT_TYPE.PHONE
                }
            ],
            restrictions: [InputTextControl.RESTRICTION_TYPE.PHONE_NUMBER]
        });
        this.addressInput = new InputTextControl({
            type: 'text',
            required: false,
            labelText: this.content.address.label,
            maxLength: 150,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.address.error
                }
            ],
            errorMessage: this.content.address.error
        });
        this.cityInput = new InputTextControl({
            type: 'text',
            required: false,
            labelText: this.content.city.label,
            maxLength: 25,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.city.error
                }
            ],
            restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
            errorMessage: this.content.city.error
        });
        this.stateInput = new SelectControl(
            this.content.states.values,
            {
                cssClass: 'recalls-stay-informed__state-select',
                errorMessage: this.content.states.error,
                labelSuppressed: true,
                labelText: this.content.states.label
            }
        );
        this.zipInput = new InputTextControl({
            type: 'text',
            inputClassNames: ['recalls-stay-informed__zip-input'],
            required: true,
            labelText: this.content.zip.label,
            maxLength: 5,
            validation: [
                {
                    type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                    errorMessage: this.content.zip.error
                },
                {
                    type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                    errorMessage: this.content.zip.error
                }
            ],
            errorMessage: this.content.zip.error
        });
        this.preferredContactMethod = new InputRadioGroupControl({
            name: 'recalls-stay-informed__preferred-contact',
            inputClassNames: ['recalls-stay-informed__preferred-contact'],
            label: this.content.preferredContact.label,
            options: [
                {
                    config: {
                        checked: true,
                        valueText: 'email',
                        labelText: this.content.preferredContact.email.label,
                        inputClassNames: ['recalls-stay-informed__radio-option']
                    }
                },
                {
                    config: {
                        valueText: 'phone',
                        labelText: this.content.preferredContact.mobile.label,
                        inputClassNames: ['recalls-stay-informed__radio-option']
                    }
                }
            ]
        });

        this.fieldsContainerElm.appendChild(this.firstNameInput.render());
        this.fieldsContainerElm.appendChild(this.lastNameInput.render());
        this.fieldsContainerElm.appendChild(this.emailInput.render());
        this.fieldsContainerElm.appendChild(this.mobilePhoneInput.render());
        this.fieldsContainerElm.appendChild(this.addressInput.render());
        this.fieldsContainerElm.appendChild(this.cityInput.render());

        const rowContainerElm = renderer.fromTemplate('<div class="recalls-stay-informed__input-row"></div>');
        rowContainerElm.appendChild(this.stateInput.render());
        rowContainerElm.appendChild(this.zipInput.render());

        this.fieldsContainerElm.appendChild(rowContainerElm);
        this.fieldsContainerElm.appendChild(this.preferredContactMethod.render());
    }

    /**
     * @method getRequestObject
     * @description Constructs the request object
     */
    getRequestObject() {
        return {
            prospect: {
                id: [
                    {
                        source: 'recall',
                        id: 'mbusa.com'
                    }
                ],
                requestdate: (new Date()).toISOString(),
                vehicle: [
                    {
                        vin: this.vin,
                        recallData: this.recalls.map((recall) => ({
                            status: recall.status,
                            recallNumber: recall.nhtsaRecallNumber,
                            recallStartDate: recall.startDate
                        }))
                    }
                ],
                customer: {
                    contact: {
                        firstName: this.firstNameInput.getValue(),
                        lastName: this.lastNameInput.getValue(),
                        email: {
                            emailAddress: this.emailInput.getValue()
                        },
                        address: {
                            street: {
                                line1: this.addressInput.getValue()
                            },
                            city: this.cityInput.getValue(),
                            state: this.stateInput.getValue(),
                            postalCode: this.zipInput.getValue()
                        },
                        phone: [
                            {
                                phoneNumber: this.mobilePhoneInput.getValue()
                            }
                        ]
                    }
                }
            }
        };
    }

    /**
     * @method onSubmit
     * @description Submit handler for the form
     */
    onSubmit() {
        // Destroying the instance of ValidationError if the form valid upon submit
        if (this.validationError) {
            this.hideValidationError();
        }

        if (!this.isPosting) {
            this.isPosting = true;

            Promise.resolve(this.getRequestObject())
                .then(submitTakataLead.bind(null, this.config.endpoints.takataContactForm))
                .then(this.onSubmitSuccess)
                .catch((error) => {
                    console.error(error);
                    this.onSubmitError();
                });
        }
    }

    /**
     * @method onSubmitSuccess
     * @description Success handler for form submission
     */
    onSubmitSuccess() {
        renderer.insert(
            stayInformedSuccessTemplate(this.content.stayInformedSuccess)({ getNode: true }),
            this.mainContainerElm);

        this.isPosting = false;
    }

    /**
     * @method onSubmitError
     * @description Error handler for the form submission
     */
    onSubmitError() {
        this.isPosting = false;
        this.showServerError();
    }

    /**
     * @method onValidationError
     * @description Callback handler for when there is form validation error
     */
    onValidationError() {
        if (this.serverError) {
            this.hideServerError();
        }

        this.showValidationError();
    }

    /**
     * @method showServerError
     * @description Shows the server error
     */
    showServerError() {
        if (!this.serverError) {
            this.serverError = new FormError({
                description: this.content.submissionError
            });
        }
        this.serverError.show(this.errorContainerElm);
    }

    /**
     * @method hideServerError
     * @description Hides the server error
     */
    hideServerError() {
        this.serverError.hide();
    }

    /**
     * @method showValidationError
     * @description Shows the server error
     */
    showValidationError() {
        if (!this.validationError) {
            this.validationError = new FormError({
                description: this.content.validationError
            });
        }
        this.validationError.show(this.errorContainerElm);
    }

    /**
     * @method hideValidationError
     * @description Hides the server error
     */
    hideValidationError() {
        this.validationError.hide();
    }

    /**
     * @method render
     * @description Returns the element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

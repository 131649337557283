let instance = null;

/**
 * @class DependencyLoader
 * @description Interface for loading dependencies
 */
export default class DependencyLoader {
    /**
     * @constructor
     */
    constructor() {
        if (instance) {
            instance = this;
        }

        this.loadedDependencies = {
            platforms: [],
            libraries: [],
            apps: [],
            plugins: []
        };
    }

    /**
     * @method loadScript
     * @description Loads a script tag from a urlPath and returns a Promise
     * that resolves onload and rejects onerror
     * @param urlPath {String} URL path of script to load
     * @return {Promise.<*>}
     */
    loadScript(urlPath) {
        return new Promise((resolve, reject) => {
            const headElm = document.querySelector('head');
            const script = document.createElement('script');
            script.src = urlPath;
            script.async = true;
            script.onload = () => resolve(urlPath);
            script.onerror = () => reject(urlPath);

            headElm.appendChild(script);
        });
    }

    /**
     * @method loadPlatforms
     * @description Iterates through the platforms array and dynamically
     * loads each matching platform
     * @param {Array} platforms - Array of platform dependencies
     * @return {Promise.<*>}
     */
    loadPlatforms(platforms = []) {
        const platformScripts = [];

        if (platforms && platforms.length > 0) {
            platforms.forEach((platform) => {
                if (this.loadedDependencies.platforms.indexOf(platform) === -1) {
                    platformScripts.push(this.loadScript(`/etc/designs/mb-vans/js/platform/${platform}.js`));
                    this.loadedDependencies.platforms.push(platform);
                }
            });
        }

        return Promise.all(platformScripts);
    }

    /**
     * @method loadLibraries
     * @description Iterates through the libraries array and dynamically
     * loads each matching library
     * @param {Array} libraries - Array of library dependencies
     * @return {Promise.<*>}
     */
    loadLibraries(libraries = []) {
        const libraryScripts = [];

        if (libraries && libraries.length > 0) {
            libraries.forEach((library) => {
                if (this.loadedDependencies.libraries.indexOf(library) === -1) {
                    libraryScripts.push(this.loadScript(`/etc/designs/mb-vans/js/libraries/${library}.js`));
                    this.loadedDependencies.libraries.push(library);
                }
            });
        }

        return Promise.all(libraryScripts);
    }

    /**
     * @method loadApps
     * @description Iterates through the apps array and dynamically
     * loads each matching app
     * @param {Array} apps - Array of apps
     * @return {Promise.<*>}
     */
    loadApps(apps = []) {
        const appScripts = [];

        if (apps && apps.length > 0) {
            apps.forEach((app) => {
                if (this.loadedDependencies.apps.indexOf(app) === -1) {
                    appScripts.push(this.loadScript(`/etc/designs/mb-vans/js/apps/${app}.js`));
                    this.loadedDependencies.apps.push(app);
                }
            });
        }

        return Promise.all(appScripts);
    }

    /**
     * @method loadPlugins
     * @description Iterates through the plugins array and dynamically
     * loads each matching plugin
     * @param {Array} plugins - List of plugins
     * @return {Promise.<*>}
     */
    loadPlugins(plugins = []) {
        const pluginScripts = [];

        if (plugins && plugins.length > 0) {
            plugins.forEach((plugin) => {
                if (this.loadedDependencies.plugins.indexOf(plugin) === -1) {
                    pluginScripts.push(this.loadScript(`/etc/designs/mb-vans/plugins/${plugin}/js/${plugin}.js`));
                    this.loadedDependencies.plugins.push(plugin);
                }
            });
        }

        return Promise.all(pluginScripts);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

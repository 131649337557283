// Constant dependencies
import {
    EVENTS,
    CUSTOM_EVENTS
} from 'Constants';

// Partials
import { FORM_CONTROL_EVENTS } from 'partials/form-control';
import { ToolTip } from 'partials/tool-tip';
import { inputValidator } from 'partials/input-control';

// Util dependencies
import {
    noop,
    ClickOutside,
    customEventDispatcher,
    findAncestor,
    htmlNode,
    generateUniqueID as ID
} from 'utils';

// Local dependencies
import selectControlTemplate from './../templates/selectControlTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @memberof SelectControl
 * @type {{
*      SELECT_TOGGLE: string,
*      SELECT_TOGGLE_LABEL: string,
*      SELECT_OPTION_LIST: string,
*      SELECT_OPTION: string,
*      OPEN: string,
*      ERROR: string,
*      SELECT_HAS_VALUE: string,
*      ERROR_ELEMENT: string,
*      SELECTED: string,
*      LABEL_ELEMENT: string,
*      LABEL_ELEMENT_LABELED: string,
*      TOOL_TIP: string
* }}
*/

const CLASSES = {
    SELECT_TOGGLE: 'select-control__combobox',
    SELECT_TOGGLE_LABEL: 'select-control__value',
    SELECT_OPTION_LIST: 'select-control__list',
    SELECT_OPTION: 'select-control__option',
    OPEN: 'select-control--open',
    ERROR: 'select-control--error',
    SELECT_HAS_VALUE: 'select-control--has-value',
    ERROR_ELEMENT: 'select-control__error-message',
    SELECTED: 'select-control__option--selected',
    LABEL_ELEMENT: 'select-control__input-label',
    LABEL_ELEMENT_LABELED: 'select-control__input-label--labeled',
    TOOL_TIP: 'tool-tip'
};

/**
 * @const ARIA_ATTRIBUTES
 * @description Collection of constant values for related aria attributes of the module
 */
const ARIA_ATTRIBUTES = {
    SELECTED: 'aria-selected',
    EXPANDED: 'aria-expanded',
    INVALID: 'aria-invalid',
    DESCRIBED_BY: 'aria-describedby',
};

/**
 * @const DATA_ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 */
const DATA_ATTRIBUTES = {
    REQUIRED: 'data-required'
};


/**
 * @const KEYS
 * @description Keyboard codes used in the module for keydown events
 */
const KEYS = {
    ESCAPE: 27,
    UP: 38,
    BOTTOM: 40,
    ENTER: 13,
    TAB: 9
};

/**
 * @const defaultConfig
 * @description Default configuration options for a select control
 * @type {Object}
 * @const analyticsTrigger {String} Analytic trigger tag
 * @const cssClass {String} Class name values to add to SelectControl element
 * @const defaultSelection {Number} Index value of item to set as default selection
 * @const errorMessage {String} Error message to display when invalid
 * @const formId {String} Value of form id that the input is associated with
 * @const icon {[String]} Icon class name to be used in filter bar input text
 * @const labelText {[String]} Optional label for SelectControl
 * @const moreInfoText {[String]} Optional string to display beneath the SelectControl
 * @const prefix {[String]} Prepended copy that is displayed before selected content
 * @const required {Boolean} Indicator for if the field is required
 * @const selectionCallback {Function} Callback method applied when an item is selected
 * @const theme {[String]} Theme to apply to the select control styling
 * @const tooltip {[Element]} Optional element to display in a tooltip next to the label
 * @const tooltipLabel {[String]} Optional label to display for tooltip
 * @const validateOnClose {Boolean} Whether the input should validate on close of select
 * @const ariaLabelText {[String]} Optional label for text to be read by screen reader
 */

const defaultConfig = {
    analyticsTrigger: '',
    cssClass: '',
    defaultSelection: -1,
    disabled: false,
    errorMessage: '',
    formId: null,
    labelText: '',
    icon: '',
    moreInfoText: '',
    ariaLabelText: '',
    prefix: '',
    required: false,
    selectionCallback: noop,
    theme: '',
    toolTip: null,
    toolTipLabel: null,
    toolTipAnalyticsTrigger: '',
    validation: [],
    validateOnClose: false,
    isCustomLabel: false,
    validationIconEnabled: true
};

const ICON_TYPES = {
    ERROR: 'error'
};

/**
 * @class SelectControl
 * @description View component for displaying a SelectControl
 */
export default class SelectControl {
    /**
     * @static THEMES
     * @description Optional theme configuration types
     * @type {{DEFAULT: string, DROPDOWN: string, INLINE: string}}
     */
    static THEMES = {
        DEFAULT: 'default',
        DROPDOWN: 'dropdown',
        INLINE: 'inline',
    };

    /**
     * @static VALIDATION_TYPE
     * @description Default validation types available for a SelectControl
     * @type {Object}
     */
    static VALIDATION_TYPE = {
        ...inputValidator.VALIDATION_TYPE
    };

    /**
     * Create a SelectControl
     * @param items {Array} An array of items objects. Each object is represented as follows:
     * {
     *   label: 'All',
     *   value: 'all'
     * }
     * @param config {Object} Configuration data
     */
    constructor(items, config = defaultConfig) {
        this.items = items || config.items;
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.currentOption = null;
        this.isExpanded = false;
        this.labelElement = null; // stores the label DOM element
        this.selectedOption = null;
        this.valid = true; // stores the validity of the select control
        this.onToggleClickHandler = this.onToggleClick.bind(this);
        this.onListboxKeydownHandler = this.onListboxKeydown.bind(this);
        this.onSelectOptionHandler = this.onSelectOption.bind(this);
        this.onOptionKeydownHandler = this.onOptionKeydown.bind(this);
        this.onCloseSelectHandler = this.onCloseSelect.bind(this);

        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.id = this.config.id || ID();

        if (this.element === null || this.element === undefined) {
            this.createView();
        }
        this.cacheDOM();
        this.attachEvents();

        if (this.config.defaultSelection !== -1) {
            this.selectDefaultOption(this.config.defaultSelection, true);
        }

        // if a toolTip element is defined, render it
        if (this.config.toolTip &&
            this.config.toolTip instanceof HTMLElement &&
            this.config.labelText) {
            this.renderToolTip();
        }
    }

    /**
     * @method destroy
     */
    destroy() {
        if (this.element) {
            this.element.remove();
            this.element = null;
            this.detachEvents();
        }
    }

    /**
     * @method createView
     * @description Create view
     */
    createView() {
        this.element = selectControlTemplate({
            analyticsTrigger: this.config.analyticsTrigger,
            ariaLabel: this.config.ariaLabel,
            ariaLabelText: this.config.ariaLabelText,
            cssClass: this.config.cssClass,
            defaultSelection: this.config.defaultSelection,
            disabled: this.config.disabled,
            errorMessage: this.config.errorMessage,
            hasTooltip: !!this.config.toolTip,
            icon: this.config.icon,
            id: this.id,
            items: this.items,
            labelText: this.config.labelText,
            moreInfoText: this.config.moreInfoText,
            name: this.config.name,
            prefix: this.config.prefix,
            required: this.config.required,
            theme: this.config.theme,
            isCustomLabel: this.config.isCustomLabel,
        })({ getNode: true });

        // assign dataset from from js to avoid problems with " on values
        const options = this.element.getElementsByClassName('select-control__option');
        this.items.forEach(
            (item, i) => { options[i].dataset.value = item.value; }
        );
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.selectToggle = this.element.querySelector(`.${CLASSES.SELECT_TOGGLE}`);
        this.selectToggleLabel = this.element.querySelector(`.${CLASSES.SELECT_TOGGLE_LABEL}`);
        this.optionList = this.element.querySelector(`.${CLASSES.SELECT_OPTION_LIST}`);
        this.options = this.element.querySelectorAll(`.${CLASSES.SELECT_OPTION}`);
        this.labelElement = this.element.querySelector(`.${CLASSES.LABEL_ELEMENT}`);
        this.errorElement = this.element.querySelector(`.${CLASSES.ERROR_ELEMENT}`);
    }

    /**
     * @method attachEvents
     * @description Attaches click and keyboard events to toggle control and to each item in list,
     * instantiates ClickOutside to close the list when clicking outside of it
     */
    attachEvents() {
        // if (this.config.toolTip) {
        //     this.labelElement.addEventListener(EVENTS.CLICK, this.onToggleClickHandler);
        // }
        this.selectToggle.addEventListener(EVENTS.CLICK, this.onToggleClickHandler);
        this.optionList.addEventListener(EVENTS.KEYDOWN, this.onListboxKeydownHandler);

        [].slice.call(this.options).forEach((item) => {
            item.addEventListener(EVENTS.CLICK, this.onSelectOptionHandler);
            item.addEventListener(EVENTS.KEYDOWN, this.onOptionKeydownHandler);
        });

        // instantiate ClickOutside to close the list when clicking outside of it
        this.clickOutside = new ClickOutside(
            this.selectToggle,
            noop,
            this.closeOptions.bind(this, false),
            this.optionList
        );

        // add event listener to close this control when another select-control is opened
        window.addEventListener(CUSTOM_EVENTS.CLOSE_SELECT_CONTROL, this.onCloseSelectHandler);
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callbacks from the view
     */
    detachEvents() {
        this.selectToggle.removeEventListener(EVENTS.CLICK, this.onToggleClickHandler);
        this.optionList.removeEventListener(EVENTS.KEYDOWN, this.onListboxKeydownHandler);

        [].slice.call(this.options).forEach((item) => {
            item.removeEventListener(EVENTS.CLICK, this.onSelectOptionHandler);
            item.removeEventListener(EVENTS.KEYDOWN, this.onOptionKeydownHandler);
        });

        this.clickOutside.destroy();
        window.removeEventListener(CUSTOM_EVENTS.CLOSE_SELECT_CONTROL, this.onCloseSelectHandler);
    }

    /**
     * @method register
     * @description Dispatches an event to notify a form that an input should be registered
     */
    register() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.REGISTER,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method unregister
     * @description Dispatches an event to notify a form that an input should be unregistered
     */
    unregister() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.UNREGISTER,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method getValue
     * @description Gets the data-value of the `this.selectedOption`
     * @returns {String} `data-value` attribute of `this.selectedOption`
     */
    getValue() {
        return this.selectedOption ? this.selectedOption.dataset.value : '';
    }

    /**
     * @method getName
     * @description Gets the name
     * @returns {String} Returns the name from the data-name attribute of the root element
     */
    getName() {
        return this.element.dataset.name;
    }

    /**
     * @method onToggleClick
     * @description Toggles open/close state
     */
    onToggleClick(e) {
        // If there are no items associated to the select-control, no need to open or close it
        if (this.items.length === 0) {
            return;
        }

        if (this.isExpanded) {
            this.closeOptions();
        } else if (!findAncestor(e.target, `.${CLASSES.TOOL_TIP}`, true)) {
            this.openOptions();
        }
    }

    /**
     * @method onListboxKeydown
     * @description Handle keyboard controls; UP/DOWN will shift focus,
     * ESCAPE closes the listbox
     * @param event
     */
    onListboxKeydown(event) {
        event.preventDefault();

        switch (event.keyCode) {
        case KEYS.ESCAPE:
        case KEYS.TAB:
        case KEYS.ENTER:
            this.closeOptions(true);
            break;

        case KEYS.UP:
            this.moveFocusUp();
            break;

        case KEYS.BOTTOM:
            this.moveFocusDown();
            break;

        default:
            break;
        }
    }

    /**
     * @method onOptionKeydown
     * @description Handle keyboard control to check for ENTER key and select
     * the option
     * @param event
     */
    onOptionKeydown(event) {
        if (event.keyCode === KEYS.ENTER) {
            this.onSelectOption(event);
        }
    }

    /**
     * @method dispatchChange
     * @description Dispatches an event to notify a form that a select control has changed its value
     */
    dispatchChange() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.INPUT_CHANGE,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method onSelectOption
     * @description Click handler for each option. Does the following:
     * Removes selected class from previously selected option
     * Adds selected class to current option
     * Sets aria-selected as true for selected option
     * Invokes the selectionCallback
     * Closes option list
     * Updates selected option label
     */
    onSelectOption(event) {
        const selectedOption = event.currentTarget.dataset.value;
        const selectedOptionIndex = this.items.findIndex((item) => item.value === selectedOption);
        this.selectDefaultOption(selectedOptionIndex);
        this.labelElement.classList.add(CLASSES.LABEL_ELEMENT_LABELED);
    }

    /**
     * @method onCloseSelect
     * @description Handler for custom event to close select-controls in page other than the
     * focused one
     */
    onCloseSelect(event) {
        if (this.id !== event.detail.id) {
            this.closeOptions(false);
        }
    }

    /**
     * @method appendIcon
     * @description Appends error icon based on the iconType param
     * @memberof SelectOptions
     * @param {string} iconType will give us the type of icon to append
     */
    appendIcon(iconType) {
        if (!this.config.validationIconEnabled) {
            return;
        }
        if (iconType === ICON_TYPES.ERROR && this.selectToggle?.querySelector('.error-icon') === null) {
            this.selectToggle.appendChild(htmlNode`<svg class="select-control--icon error-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12" r="12" fill="currentColor"/>
                <path d="M11.5 6.01196H13.5V13.012H11.5V6.01196Z" fill="white"/>
                <path d="M12.5 17.512C13.3284 17.512 14 16.8404 14 16.012C14 15.1835 13.3284 14.512 12.5 14.512C11.6716 14.512 11 15.1835 11 16.012C11 16.8404 11.6716 17.512 12.5 17.512Z" fill="white"/>
            </svg>`({ getNode: true }));
        }
    }

    /**
     * @method selectDefaultOption
     * @description Selects default option
     * @param optionIndex {Number} index of selected option
     * @param disableCallback {Boolean} Indicator to bypass the selectionCallback
     */
    selectDefaultOption(optionIndex = 0, disableCallback = false) {
        [].slice.call(this.options).forEach((item) => {
            item.classList.remove(CLASSES.SELECTED);
            item.setAttribute(ARIA_ATTRIBUTES.SELECTED, 'false');
        });

        this.selectedOption = this.options[optionIndex];
        this.currentOption = this.selectedOption;
        const selectedOption = this.getSelectedOption(this.selectedOption.dataset.value);

        this.selectedOption.classList.add(CLASSES.SELECTED);
        this.selectedOption.setAttribute(ARIA_ATTRIBUTES.SELECTED, 'true');

        if (this.config.validationIconEnabled) {
            this.selectToggle.querySelector('.select-control--icon')?.remove();
        }

        if (selectedOption.label) {
            this.labelElement.classList.add(CLASSES.LABEL_ELEMENT_LABELED);
            this.updateToggleLabel(selectedOption.label);
        } else {
            this.labelElement.classList.remove(CLASSES.LABEL_ELEMENT_LABELED);
            this.resetSelection();
            if (this.config.validationIconEnabled) {
                this.element.classList.remove(CLASSES.SELECT_HAS_VALUE);
            }
        }

        if (!disableCallback) {
            this.config.selectionCallback(selectedOption.value);
        }

        this.setErrorStatus(false);
        this.dispatchChange();
        if (!disableCallback || this.isExpanded) {
            this.closeOptions(true);
        }
    }

    /**
     * @method getSelectedOption
     * @description Gets the selected option from the list of items
     * @param {string} value - Value for which the corresponding item should be found
     * @returns {Object} Object from the list of items corresponding to the value
     */
    getSelectedOption(value) {
        return this.items.find((item) => item.value === value);
    }

    /**
     * @method moveFocusUp
     * @description Shifts the focus to the previous item in the list
     */
    moveFocusUp() {
        const currentOption = this.currentOption || this.options[0];
        const previousOption = currentOption.previousElementSibling;

        if (previousOption) {
            previousOption.focus();
            this.currentOption = previousOption;
        }
    }

    /**
     * @method moveFocusDown
     * @description Shifts the focus to the next item in the list
     */
    moveFocusDown() {
        const currentOption = this.currentOption;
        const nextOption = currentOption ? currentOption.nextElementSibling : this.options[0];

        if (nextOption) {
            nextOption.focus();
            this.currentOption = nextOption;
        }
    }

    /**
     * @method openOptions
     * @description Adds class specific to open options list and
     * updates the isExpanded flag
     */
    openOptions() {
        this.closeOtherSelects();
        this.element.classList.add(CLASSES.OPEN);
        this.selectToggle.setAttribute(ARIA_ATTRIBUTES.EXPANDED, 'true');
        this.isExpanded = true;
        this.optionList.focus();
    }

    /**
     * @method closeOptions
     * @description Removes the class specific to open options list and
     * updates the isExpanded flag
     */
    closeOptions(focus = true) {
        this.element.classList.remove(CLASSES.OPEN);
        this.selectToggle.removeAttribute(ARIA_ATTRIBUTES.EXPANDED);
        this.isExpanded = false;
        if (focus) {
            this.selectToggle.focus();
        }
        this.currentOption = this.selectedOption;
        if (this.config.validateOnClose && focus) {
            this.validate();
        }
    }

    /**
     * @method closeOtherSelects
     * @description Dispatches custom event to close other select controls in page
     */
    closeOtherSelects() {
        const event = new CustomEvent(CUSTOM_EVENTS.CLOSE_SELECT_CONTROL, {
            detail: {
                id: this.id
            }
        });

        window.dispatchEvent(event);
    }

    /**
     * @method updateToggleLabel
     * @description Assigns the selected option label to the toggle control and removes
     * modifier class used when the placeholder is shown instead of a selected option
     * @param label {String} Label to be placed in the select toggle
     */
    updateToggleLabel(label) {
        this.selectToggleLabel.innerHTML = label;
    }

    /**
     * @method setRequired
     * @description Toggles placeholder for select control
     * @param isRequired {Boolean} whether field is required
     */
    setRequired(isRequired) {
        this.config.required = isRequired;
        if (!this.selectedOption) {
            this.resetSelection();
        }
    }

    /**
     * @method setErrorStatus
     * @description Toggles error class
     * @param error {Boolean} whether there is an error
     * @param message {String} Error message to display
     */
    setErrorStatus(error, message = '') {
        if (this.config.validationIconEnabled) {
            this.selectToggle.querySelector('.select-control--icon.error-icon')?.remove();
        }
        if (error) {
            this.element.classList.add(CLASSES.ERROR);
            this.errorElement.innerHTML = message;
            this.selectToggle.setAttribute(ARIA_ATTRIBUTES.INVALID, true);
            this.selectToggle.setAttribute(ARIA_ATTRIBUTES.DESCRIBED_BY, this.errorElement.id);
            this.appendIcon(ICON_TYPES.ERROR);
            this.selectToggle.focus();
        } else {
            this.element.classList.remove(CLASSES.ERROR);
            this.errorElement.innerHTML = '';
            this.selectToggle.removeAttribute(ARIA_ATTRIBUTES.INVALID);
            this.selectToggle.removeAttribute(ARIA_ATTRIBUTES.DESCRIBED_BY);
        }
    }

    /**
     * @method setDisabled
     * @description Sets the disabled attribute of the select toggle based on the disabledFlag
     * @param {Boolean} disabledFlag - Disabled Flag
     */
    setDisabled(disabledFlag) {
        this.config.disabled = disabledFlag;

        if (disabledFlag) {
            this.selectToggle.setAttribute('disabled', 'disabled');
        } else {
            this.selectToggle.removeAttribute('disabled');
        }
    }

    /**
     * @method resetSelection
     * @description Sets the select state back to not selected, sets back the
     * placeholder text with no selection
     */
    resetSelection() {
        if (this.selectedOption) {
            this.selectedOption.classList.remove(CLASSES.SELECTED);
            this.selectedOption.setAttribute(ARIA_ATTRIBUTES.SELECTED, 'false');
        }
        this.currentOption = null;
        this.selectedOption = null;
        this.updateToggleLabel('');
        this.labelElement.classList.remove(CLASSES.LABEL_ELEMENT_LABELED);
        if (this.config.required) {
            this.selectToggle.setAttribute(DATA_ATTRIBUTES.REQUIRED, '');
        } else if (this.selectToggle.hasAttribute(DATA_ATTRIBUTES.REQUIRED)) {
            this.selectToggle.removeAttribute(DATA_ATTRIBUTES.REQUIRED);
        }
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if select value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method validate
     * @description Sets error message if no selection has been made
     */
    validate(displayError = true) {
        let valid = true;
        const value = this.selectedOption ? this.selectedOption.dataset.value !== '' : this.selectedOption !== null;
        let errorMessage = '';

        if (this.config.required && !value) {
            valid = !!value;
            errorMessage = this.config.errorMessage;
        } else if (this.config.validation.length > 0) {
            // loop through all validation rules
            this.config.validation.forEach((validationType) => {
                if (valid && !inputValidator.verify(
                    this.selectedOption && this.selectedOption.dataset.value,
                    validationType.type,
                    this.config,
                    validationType.config
                )) {
                    valid = false;
                    errorMessage = validationType.errorMessage;
                }
            });
        }

        this.valid = valid;

        if (displayError) {
            this.setErrorStatus(!this.valid, errorMessage);
        }

        return this.isValid();
    }

    /**
     * @method renderToolTip
     * @description Creates and appends a ToolTip to the label element
     */
    renderToolTip() {
        if (!this.toolTip && this.labelElement) {
            this.toolTip = new ToolTip(this.config.toolTip, {
                analyticsTrigger: this.config.toolTipAnalyticsTrigger,
                label: this.config.toolTipLabel
            });
        }

        this.labelElement.appendChild(this.toolTip.render());
    }

    /**
     * @method render
     * @description Return the element containing the custom select
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

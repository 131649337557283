// import { EVENTS } from 'Constants';
import { noop } from 'utils';

import SupportTest from './../api';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    ANIMATION_ELEMENT: 'animation-element',
    ANIMATING: 'animation-element--animating',
    SETTLED_IN: 'animation-element--settled-in',
    SETTLED_OUT: 'animation-element--settled-out'
};

/**
 * @const defaultConfig
 * @description
 */
const defaultConfig = {
    id: '',
    playinCompleteClass: CLASSES.SETTLED_IN,
    addPlayinCompleteClass: false,
    playoutCompleteClass: CLASSES.SETTLED_OUT,
    addPlayoutCompleteClass: false,
    active: true
};

let playinFinishStaticCallback = noop;
let playoutFinishStaticCallback = noop;
let animationApiSupport = null;

/**
 * @class AnimationElement
 * @description
 */
export default class AnimationElement {
    /**
     * @static FILLS
     * @description
     */
    static FILLS = {
        FORWARDS: 'forwards',
        BACKWARDS: 'backwards',
        NONE: 'none',
        BOTH: 'both'
    };

    /**
     * @static STATES
     * @description
     */
    static STATES = {
        IDLE: 'idle',
        ANIMATING: 'animating',
        SETTLED_IN: 'settledIn',
        SETTLED_OUT: 'settledIn'
    };

    /**
     * @static EASING
     * @description String constants for animation easing functions
     */
    static EASING = {
        LINEAR: 'linear',
        EASE_IN: 'ease-in',
        EASE_OUT: 'ease-out',
        EASE_IN_OUT: 'ease-in-out'
    };

    constructor(element, config, playinFinishCallback, playoutFinishCallback) {
        // properties
        if (animationApiSupport === null) {
            this.testSupport();
        }
        this.element = element;
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.active = this.config.active;
        this.state = AnimationElement.STATES.IDLE;
        this.playinFinishCallback = playinFinishCallback || playinFinishStaticCallback;
        this.playoutFinishCallback = playoutFinishCallback || playoutFinishStaticCallback;

        this.setBindings();
        this.init();
    }

    /**
     * @method setBindings
     * @description
     */
    setBindings() {
        this.onPlayinFinish = this.onPlayinFinish.bind(this);
        this.onPlayoutFinish = this.onPlayoutFinish.bind(this);
        this.onSupportUpdate = this.onSupportUpdate.bind(this);
    }

    init() {
        this.element.classList.add(CLASSES.ANIMATION_ELEMENT);
    }

    beginPlayin() {
        if (this.config.animationPlayin) {
            this.element.classList.add(CLASSES.ANIMATING);
            const animation = this.element.animate(
                this.config.animationPlayin.animationFrames,
                this.config.animationPlayin.animationTiming
            );
            animation.onfinish = this.onPlayinFinish;
            this.state = AnimationElement.STATES.ANIMATING;
        } else {
            this.state = AnimationElement.STATES.SETTLED_IN;
        }
    }

    beginPlayout() {
        if (this.config.addPlayinCompleteClass) {
            this.element.classList.remove(this.config.playinCompleteClass);
        }
        this.element.classList.add(CLASSES.ANIMATING);
        const animation = this.element.animate(
            this.config.animationPlayout.animationFrames,
            this.config.animationPlayout.animationTiming
        );
        animation.onfinish = this.onPlayoutFinish;
        this.state = AnimationElement.STATES.ANIMATING;
    }

    /**
     * @method onPlayinFinish
     * @description
     * @param e {Event} event object
     */
    onPlayinFinish() {
        this.element.classList.remove(CLASSES.ANIMATING);
        if (this.config.addPlayinCompleteClass) {
            this.element.classList.add(this.config.playinCompleteClass);
        }
        this.state = AnimationElement.STATES.SETTLED_IN;
        this.playinFinishCallback(this.config.id);
    }

    /**
     * @method onPlayoutFinish
     * @description
     * @param e {Event} event object
     */
    onPlayoutFinish() {
        this.element.classList.remove(CLASSES.ANIMATING);
        if (this.config.addPlayoutCompleteClass) {
            this.element.classList.add(this.config.playoutCompleteClass);
        }
        this.state = AnimationElement.STATES.SETTLED_OUT;
        this.playoutFinishCallback(this.config.id);
    }

    get currentState() {
        return this.state;
    }

    get isActive() {
        return this.active;
    }

    set isActive(active) {
        this.active = active;
    }

    static set playinFinish(callback) {
        playinFinishStaticCallback = callback;
    }

    static set playoutFinish(callback) {
        playoutFinishStaticCallback = callback;
    }

    testSupport() {
        this.supportTest = new SupportTest(this.onSupportUpdate);
        animationApiSupport = this.supportTest.results;
    }

    onSupportUpdate(updatedResults) {
        animationApiSupport = updatedResults;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

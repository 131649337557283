// Local dependencies
import InputControl from './InputControl';
import inputCheckboxControlTemplate from './../templates/inputCheckboxControlTemplate';
import { VALIDATION_TYPE } from './../api/inputValidator';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    ERROR_ELEMENT: 'form__input-error',
    INPUT_ELEMENT: 'form__checkbox',
    LABEL_ELEMENT: 'form__checkbox-label',
};

/**
 * Class that will set up an InputCheckboxControl
 */
export default class InputCheckboxControl extends InputControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputCheckboxControl
     * @type {Object}
     * @const disabled {Boolean} True if checkbox should display as disabled
     * @const checked {Boolean} True if checkbox should display as checked
     */
    static DEFAULT_CONFIG = {
        ...InputControl.DEFAULT_CONFIG,
        disabled: false,
        checked: false
    };

    /**
     * @static VALIDATION_TYPE
     * @description Default validation types available for an InputCheckboxControl
     * @type {Object}
     */
    static VALIDATION_TYPE = {
        REQUIRED: VALIDATION_TYPE.REQUIRED
    };

    /**
     * @static THEMES
     * @description Theme types available for an InputCheckboxControl
     * @type {Object}
     */
    static THEMES = {
        TOGGLE: 'toggle'
    };

    /**
     * @constructor Create an InputCheckboxControl
     */
    constructor(config = InputCheckboxControl.DEFAULT_CONFIG) {
        super({
            ...InputCheckboxControl.DEFAULT_CONFIG,
            ...config
        });
        this.valid = true; // stores the validity of the input
        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.createView(this.getConfig('renderedInput') || inputCheckboxControlTemplate({
            id: this.getConfig('id'),
            dataAttributes: this.getConfig('dataAttributes').join(' '),
            inputClassNames: this.getConfig('inputClassNames').join(' '),
            labelClassNames: this.getConfig('labelClassNames').join(' '),
            required: this.getConfig('required'),
            disabled: this.getConfig('disabled'),
            checked: this.getConfig('checked'),
            valueText: this.getConfig('valueText'),
            labelText: this.getConfig('labelText'),
            ariaLabelledBy: this.getConfig('ariaLabelledBy'),
            analyticsTrigger: this.getConfig('analyticsTrigger'),
            theme: this.getConfig('theme'),
        })({ getNode: true }));
        super.init();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        super.cacheDOM();
        this.errorElement = this.getInput().querySelector(`.${CLASSES.ERROR_ELEMENT}`);
        this.labelElement = this.getInput().querySelector(`.${CLASSES.LABEL_ELEMENT}`);
        this.setInputElement(this.getInput().querySelector(`.${CLASSES.INPUT_ELEMENT}`));
    }

    /**
     * @method validate
     * @description Check validation
     */
    validate(displayError = true) {
        let valid = true;
        this.errorMessage = '';

        if (this.getConfig('required')) {
            valid = this.getInputElement().checked;
        }

        this.valid = valid;

        if (displayError) {
            this.setErrorStatus(!this.valid, this.getConfig('errorMessage'));
        }

        return this.isValid();
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if input value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method setDisabledState
     * @description Toggles disabled attribute
     * @param disabled {Boolean} True if checkbox should display as disabled
     */
    setDisabledState(disabled) {
        this.getInputElement().disabled = disabled;
    }

    /**
     * @method getValue
     * @description Gets the value of the checkbox
     * @returns {Boolean} - True if checked
     */
    getValue() {
        return this.getInputElement().checked;
    }

    /**
     * @method setValue
     * @description Sets the value of the checkbox
     * @returns {Boolean} - True if checked
     */
    setValue(checked) {
        this.inputElement.checked = checked;
        this.config.onChangeCallback(this.inputElement.checked);
    }

    /**
     * @method onChange
     * @description Updates inputElement checked value and calls this.config.onChangeCallback
     * @param e {Event} Event object from change event
     */
    onChange(e) {
        if (this.config.prevalidateOnChange) {
            this.validate();
        }
        this.inputElement.checked = e.currentTarget.checked;
        this.config.onChangeCallback(this.inputElement.checked, e);
        this.dispatchChange();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { serializeObject as serialize } from 'utils';

/**
 * @function isLeadCreationSuccessful
 * @description Checks if the response was accepted
 * @returns {Boolean} True if the response code is 2xx
 */
function isLeadCreationSuccessful(response) {
    return response.leadStatus && response.leadStatus.status && response.leadStatus.status.toLowerCase() === 'accepted';
}

/**
 * @function submitLead
 * @description Makes the REST call with the provided data to endpoint. Also checks if the response
 * has the correct response code. If the response code is not 2xx, will throw an error
 * @param leadsEndpoint {String} Leads web service endpoint
 * @param country {String} Country code
 * @param brand {String} Brand code ('mb' or 'fl')
 * @param data {Object} Payload to submit to lead service
 * @returns {Promise} Promise object which resolves if the response is 2xx
 */
function submitLead(leadsEndpoint, country = 'us', brand = 'mb', data) {
    const endpoint = `${leadsEndpoint}${
        serialize({ country, brand, 'g-recaptcha-response': data.prospect.recaptcha })
    }`;
    // Explicit header added for:
    // * Referrer-Policy - IE Edge does not support fetch.referrerPolicy
    // * Referrer - IE does not support fetch.referrer
    // meta tag referrer = origin-when-cross-origin is not supported on multiple browser which
    // fallback to not sending the referrer header
    return fetch(endpoint, {
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST'
    })
    .then((response) => response.json().then((responseStatus) => {
        if (response.status === 200 && isLeadCreationSuccessful(responseStatus)) {
            return responseStatus;
        }

        throw Error('Lead Submission Not Successful');
    }));
}

export default {
    submitLead
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

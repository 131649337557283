/**
 * Disclaimer Module
 * Has methods related to show/hide drawer on toggle
 */
import {
    EVENTS,
    CUSTOM_EVENTS,
    DELAY
} from 'Constants';
import {
    dimensions,
    scrollTo,
    renderer
} from 'utils';

// Local dependencies
import DISCLAIMER_EVENTS from './../constants/EVENTS';

/**
 * CLASSES{}
 * Stores class names for use in the DOM
 */
const CLASSES = {
    DISCLAIMER_LINK: 'legal__disclaimer',
    DISCLAIMER_LINK_OPEN: 'legal__disclaimer--open',
    DISCLAIMER_MARKER: 'disclaimer__marker',
    DISCLAIMER_TEXT: 'disclaimer__text',
    DISCLAIMER_LEGEND: 'disclaimer__legend',
    STICKY_NAV_CONTAINER: 'sticky-nav__container',
    GLOBAL_NAVIGATION_STATE: 'global-navigation-state',
    HIDE: 'hide'
};

/**
 * IDS{}
 * Stores IDs for use in the DOM
 */
const IDS = {
    DISCLAIMER_DRAWER: 'disclaimer-drawer',
    MAIN_CONTENT: 'content'
};

/**
 * ATTRIBUTES{}
 * Stores class names for use in the DOM
 */
const ATTRIBUTES = {
    ARIA_EXPANDED: 'aria-expanded',
    GLOBAL_NAVIGATION_BEHAVIOR: 'data-global-navigation-behavior',
    LEGEND: 'data-legend',
    TAB_INDEX: 'tabindex'
};

/**
 * @const defaultLocalization
 * @description  localization object for i18n content
 * @type {Object}
 */
const defaultLocalization = {
    backToContent: 'Back to content',
    close: 'Close Disclaimer',
    view: 'View Disclaimer'
};

/**
 * @const localization
 * @description constant for the localization content created
 * in src/main/content/jcr_root/apps/mb-vans/pages/base/partials/footlibs.html
 */
const localization = window.mbVans.ns('pageData').disclaimerLocalization;
/**
 * @class Disclaimer
 * @description View component which handles legend markers and disclaimer drawer
 */
export default class Disclaimer {
    static legendDetailContainers = [];
    static shownDisclaimerLegends = {};

    constructor(element) {
        this.element = element;
        this.mediaQueryList = null;
        this.content = { ...defaultLocalization, ...localization };

        this.onClick = this.onClick.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onDisclaimerBackListener = this.onDisclaimerBackListener.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.toggleDrawerCollapse = this.toggleDrawer.bind(this, true);
        this.printListener = this.printListener.bind(this);

        this.cacheDOMElements();

        this.footerDockedState =
            this.globalNavState &&
            this.globalNavState.getAttribute(ATTRIBUTES.GLOBAL_NAVIGATION_BEHAVIOR) === 'docked';

        if (!this.footerDockedState) {
            this.addBackButtonElement();
        }

        this.attachEvents();
    }

    static clearShownDisclaimerLegends() {
        this.shownDisclaimerLegends = {};
    }

    static getDisclaimerLegends() {
        const legends = [];
        this.legendDetailContainers.forEach((legendDetailContainer) => {
            legends.push(legendDetailContainer.legendId);
        });
        return legends;
    }

    static filterDisclaimer(legend, hide) {
        this.legendDetailContainers.forEach((legendDetailContainer) => {
            if (legendDetailContainer.legendId === legend && !this.shownDisclaimerLegends[legend]) {
                legendDetailContainer.disclaimerLegendElm.classList[hide ? 'add' : 'remove'](`${CLASSES.HIDE}`);
                legendDetailContainer.disclaimerTextElm.classList[hide ? 'add' : 'remove'](`${CLASSES.HIDE}`);
                legendDetailContainer.disclaimerTextElm.setAttribute(`${ATTRIBUTES.TAB_INDEX}`, hide ? '-1' : '0');
            }
        });
        if (!hide) {
            this.shownDisclaimerLegends[legend] = true;
        }
    }

    /**
     * @method cacheDOMElements
     * @description Caches DOM elements
     */
    cacheDOMElements() {
        this.disclaimerLink = this.element.querySelector(`.${CLASSES.DISCLAIMER_LINK}`);
        this.disclaimerDrawer = document.getElementById(IDS.DISCLAIMER_DRAWER);
        this.disclaimerMarkers = Array.prototype.slice.call(
            document.querySelectorAll(`.${CLASSES.DISCLAIMER_MARKER}`)
        );
        this.mediaQueryList = window.matchMedia('print');
        this.globalNavState = document.querySelector(`.${CLASSES.GLOBAL_NAVIGATION_STATE}`);
        Disclaimer.legendDetailContainers =
            Array.prototype.slice.call(this.disclaimerDrawer.querySelectorAll(`.${CLASSES.DISCLAIMER_TEXT}`))
                .map((disclaimerTextElm) => ({
                    disclaimerTextElm,
                    disclaimerLegendElm: this.disclaimerDrawer.querySelector(`.${CLASSES.DISCLAIMER_LEGEND}[${ATTRIBUTES.LEGEND}="${disclaimerTextElm.getAttribute(ATTRIBUTES.LEGEND)}"]`),
                    legendId: disclaimerTextElm.getAttribute(ATTRIBUTES.LEGEND),
                    backButtonElement: null,
                    markerElement: null
                }));
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners
     */
    attachEvents() {
        // Add an on click handler to the disclaimerLink
        if (this.disclaimerLink) {
            this.disclaimerLink.addEventListener(EVENTS.CLICK, this.onClick);
        }

        // Apply an on click handler to all of the disclaimer markers
        this.disclaimerMarkers.forEach((marker) => {
            marker.addEventListener(EVENTS.CLICK, this.onMarkerClick);
        });

        // Subscribe to the `OPEN_DISCLAIMER` and call `onMarkerClick` when broadcasted
        window.addEventListener(
            DISCLAIMER_EVENTS.OPEN_DISCLAIMER,
            this.onMarkerClick
        );

        window.addEventListener(
            CUSTOM_EVENTS.DOCKED_FOOTER.OPEN_DISCLAIMER,
            this.onClick
        );

        window.addEventListener(
            CUSTOM_EVENTS.DOCKED_FOOTER.CLOSE_DISCLAIMER,
            this.toggleDrawerCollapse
        );

        // print handler for webKit
        this.mediaQueryList.addListener(this.printListener);
        // print support for IE and Firefox
        window.onbeforeprint = this.printListener;
        window.onafterprint = this.printListener;

        // Add click event handlers for all back to content buttons
        Disclaimer.legendDetailContainers.forEach((legendDetailContainer) => {
            if (legendDetailContainer.backButtonElement) {
                legendDetailContainer.backButtonElement.addEventListener(EVENTS.CLICK,
                    this.onDisclaimerBackListener.bind(this, legendDetailContainer));
            }
        });
    }

    /**
     * @method addBackButtonElement
     * @description Add back to content button CTA to each disclaimer text element in the legendDetailContainers
     */
    addBackButtonElement() {
        Disclaimer.legendDetailContainers = Disclaimer.legendDetailContainers.map((legendDetailContainer) => {
            const backButtonElement = renderer.fromTemplate(`
                    <button class="disclaimer__back-to-content-cta link link_plain-link" data-back-button>
                        <span class="offscreen">${localization.backToContent}</span>
                        <span>&#8593;</span>
                    </button>
                `.trim());

            // Some disclaimers are generated using RTE, which would generate paragraph
            // tags within the disclaimer. Appending into disclaimer text would cause
            // button to be inserted below the block element. Use childElementCount to
            // determine if it has multiple child elements to insert the CTA into last
            // element present.
            if (legendDetailContainer.disclaimerTextElm.childElementCount) {
                legendDetailContainer.disclaimerTextElm.lastElementChild.appendChild(backButtonElement);
            } else {
                legendDetailContainer.disclaimerTextElm.appendChild(backButtonElement);
            }

            return {
                ...legendDetailContainer,
                backButtonElement
            };
        });
    }

    /**
     * @method isDisclaimerDrawerVisible
     * @description Check if drawer is visible
     */
    isDisclaimerDrawerVisible() {
        return this.disclaimerDrawer.getAttribute(ATTRIBUTES.ARIA_EXPANDED) === 'true';
    }

    /**
     * @method toggleDrawer
     * @description open or close the disclaimer drawer
     * @param Boolean if disclaimer drawer is visible
     */
    toggleDrawer(isVisible) {
        this.disclaimerLink.classList[isVisible ? 'remove' : 'add'](CLASSES.DISCLAIMER_LINK_OPEN);
        this.disclaimerDrawer.setAttribute(ATTRIBUTES.ARIA_EXPANDED, `${!isVisible}`);
        this.disclaimerLink.innerHTML = this.disclaimerLink.innerHTML.replace(
            this.content[isVisible ? 'close' : 'view'],
            this.content[isVisible ? 'view' : 'close']
        );
    }

    /**
     * @method onClick
     * @description Click handler for drawer CTA
     */
    onClick(event) {
        event.preventDefault();
        const isVisible = this.isDisclaimerDrawerVisible();
        this.toggleDrawer(isVisible);
        if (!isVisible) {
            if (this.footerDockedState) {
                scrollTo(this.disclaimerDrawer.offsetTop, DELAY.DELAY_1500MS, 'vertical', this.element);
            } else {
                scrollTo(this.getOffset(), DELAY.DELAY_1500MS);
            }
        }
    }

    /**
     * @method onMarkerClick
     * @description Click handlers for markers that updates the legend detail data,
     * scrolls the user to and focuses the keyboard to the selected disclaimer footnote
     */
    onMarkerClick(event) {
        event.preventDefault();
        const isVisible = this.isDisclaimerDrawerVisible();
        if (!isVisible) {
            this.toggleDrawer(false);
        }

        const legend = (event.type === DISCLAIMER_EVENTS.OPEN_DISCLAIMER && event.detail.legend) ?
            event.detail.legend :
            event.currentTarget.attributes.getNamedItem(ATTRIBUTES.LEGEND).nodeValue;
        const legendDetailContainer = this.disclaimerDrawer.querySelector(`.${CLASSES.DISCLAIMER_TEXT}[${ATTRIBUTES.LEGEND}="${legend}"]`);

        this.updateLegendDetailMarker(legend,
            event.detail.currentTarget ? event.detail.currentTarget : event.currentTarget);

        scrollTo(this.getOffset(legendDetailContainer), DELAY.DELAY_750MS).then(() => {
            legendDetailContainer.focus();
        });
    }

    /**
     * @method printListener
     * @description Handler for Print Event
     * when printing expand disclaimers
     */
    printListener(event) {
        const wasDrawerOpen = this.disclaimerLink.className.contains(CLASSES.DISCLAIMER_LINK_OPEN);
        if (event.type === 'beforeprint' || event.matches) {
            this.disclaimerDrawer.setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'true');
        } else if (event.type === 'afterprint' || !event.matches) {
            if (!wasDrawerOpen) {
                this.disclaimerDrawer.setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'false');
            }
        }
    }

    /**
     * @method onDisclaimerBackListener
     * @description Click handler for back button in each disclaimer legend
     */
    onDisclaimerBackListener(container, event) {
        event.preventDefault();

        if (container.markerElement && dimensions.getOffset(container.markerElement).top > 0) {
            // if there is an available markerElement and it is has a visible offset, set focus
            scrollTo(this.getOffset(container.markerElement) - (window.innerHeight / 2), DELAY.DELAY_500MS);
            // If you just focus, IE 11 moves the focused element to the top of the viewport, under the sticky nav
            // Instead, this scrolls the window to place the focused element in the middle of the viewport,
            // then focuses.
            setTimeout(() => { container.markerElement.focus(); }, DELAY.DELAY_500MS);
        } else {
            const legendButton = document.querySelector(`.${CLASSES.DISCLAIMER_MARKER}[${ATTRIBUTES.LEGEND}='${container.legendId}']`);

            if (legendButton && legendButton.offsetTop > 0) {
                // if there is an available legendButton and it is has a visible offset, set focus
                legendButton.focus();
            } else {
                // otherwise, set focus to the main content and scroll the user to the top of the page
                document.getElementById(IDS.MAIN_CONTENT).focus();
                scrollTo(0, DELAY.DELAY_300MS);
            }
        }
    }

    /**
     * @method updateLegendDetailMarker
     * @description Update marker element for an associated legend
     */
    updateLegendDetailMarker(legendId, markerElement) {
        const legendIndex = Disclaimer.legendDetailContainers
            .findIndex((legendDetailContainer) => legendDetailContainer.legendId === legendId);

        Disclaimer.legendDetailContainers[legendIndex].markerElement = markerElement;
    }

    /**
     * @method getOffset
     * @description calculates and returns the scroll offset
     */
    getOffset(element) {
        const { scrollHeight } = document.body;
        const stickyNavContainer = document.querySelector(`.${CLASSES.STICKY_NAV_CONTAINER}`);
        const stickyNavOffset = stickyNavContainer ? stickyNavContainer.offsetHeight : 0;

        // if block will be executed when user will click on disclaimer link
        if (element) {
            const elementOffsetTop = element.getBoundingClientRect().top +
                                    (window.pageYOffset || document.documentElement.scrollTop);
            return (elementOffsetTop - stickyNavOffset);
        }
        const disclaimerDrawerOffset = this.disclaimerDrawer.offsetHeight;
        const bufferOffset = 20;
        return (scrollHeight - (disclaimerDrawerOffset + stickyNavOffset + bufferOffset));
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS, KEYBOARD } from 'Constants';
import { Observer } from 'partials/observer';
import config from 'apps/dealer-locator/config';
import template from './../templates/dealerTemplate';

const ATTRIBUTES = {
    PREFERRED_DEALER_LINK: 'data-preferred-dealer-link',
    REMOVE_PREFERRED_DEALER_LINK: 'data-remove-preferred-dealer-link',
    SELECTED_DEALER: 'data-selected-dealer',
    PREFERRED_DEALER: 'data-is-preferred'
};

const CLASSES = {
    YOUR_DEALER: 'dealer-info__preferred-dealer__small-heading',
    YOUR_DEALER_VISIBLE: 'dealer-info__preferred-dealer__small-heading--visible'
};

/**
 * View responsible for rendering the dealerInfo module
 */
export default class Dealer extends Observer {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     */
    constructor(
        dealer = null,
        content = null,
        index = 0,
        model
    ) {
        super();

        this.model = model;
        this.generateElement(dealer, content, index);
        this.dealer = dealer;
        this.id = dealer.id;
        this.onSelectHandler = this.onSelectHandler.bind(this);
        this.selectDealer = this.selectDealer.bind(this);
        this.selectPreferredDealer = this.selectPreferredDealer.bind(this);
        this.unsetPreferredDealer = this.unsetPreferredDealer.bind(this);
        this.preferredDealerLink = null;
        this.removePreferDealerLink = null;
        this.yourPreferredDealer = null;
        this.cacheDOM();
        this.attachEvents();
        this.checkPreferredDealer();
    }

    /**
     * @method generateElement
     * @description Sets the element using the template. This method is created so any subclasses
     * can set their element before the attachEvents are called.
     */
    generateElement(dealer, content, index) {
        this.element = template(dealer, content, index, false, config.variant)({ getNode: true });
    }

    /**
     * @method cacheDOM
     * @description caches DOM elements
     */
    cacheDOM() {
        this.preferredDealerLink = this.element.querySelector(`[${ATTRIBUTES.PREFERRED_DEALER_LINK}]`);
        this.removePreferDealerLink = this.element.querySelector(`[${ATTRIBUTES.REMOVE_PREFERRED_DEALER_LINK}]`);
        this.yourPreferredDealer = this.element.querySelector(`.${CLASSES.YOUR_DEALER}`);
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    attachEvents() {
        this.model.attach(this);
        this.element.addEventListener(EVENTS.CLICK, this.onSelectHandler);
        this.element.addEventListener(EVENTS.KEYDOWN, this.onSelectHandler);
        if (!config.variant.isOnDisplay) {
            this.preferredDealerLink.addEventListener(
                EVENTS.CLICK, this.selectPreferredDealer);
            this.removePreferDealerLink.addEventListener(
                EVENTS.CLICK, this.unsetPreferredDealer);
        }
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    detachEvents() {
        this.model.detach(this);
        this.element.removeEventListener(EVENTS.CLICK, this.onSelectHandler);
        this.element.removeEventListener(EVENTS.KEYDOWN, this.onSelectHandler);
        if (!config.variant.isOnDisplay) {
            this.preferredDealerLink.removeEventListener(
                EVENTS.CLICK, this.selectPreferredDealer);
            this.removePreferDealerLink.removeEventListener(
                EVENTS.CLICK, this.unsetPreferredDealer);
        }
    }

    /**
     * @method onSelectHandler
     * @description The click handler when dealer node is clicked
     */
    onSelectHandler(event) {
        const { which } = event;

        if (event.type === EVENTS.CLICK || which === KEYBOARD.ENTER) {
            this.selectDealer(this.dealer);
        }
    }

    selectDealer(dealer) {
        this.expand();
        this.model.setSelectedRetailer(dealer);
    }

    selectPreferredDealer() {
        this.setPreferred();
        this.model.setPreferredDealer(this.dealer);
    }

    /**
     * @method checkPreferredDealer
     * @description check if dealer is preferred and style accordingly
     */
    checkPreferredDealer() {
        if (this.model.state) {
            if (this.model.state.preferredDealer &&
                this.model.state.preferredDealer.dealerId === this.dealer.dealerId) {
                // additional check for dealers with same Id
                if (this.model.state.preferredDealer.zip === this.dealer.zip) {
                    this.setPreferred();
                }
            }
        }
    }

    /**
     * @method onUpdate
     * @description unsetPreferred on removing preferredDealer
     * @param prevState {Object} Previous state before change
     * @param nextState {Object} Next state after change
     */
    onUpdate(prevState, nextState) {
        // When preferred dealer is removed
        if (prevState.preferredDealer && nextState.preferredDealer === null) {
            if (prevState.preferredDealer.dealerId === this.dealer.dealerId) {
                // additional check for dealers with same Id
                if (prevState.preferredDealer.zip === this.dealer.zip) {
                    this.unsetPreferred();
                }
            }
        }
    }

    /**
     * @method show
     * @description Sets the data-show to true. This is used by CSS to show/hide the dealer item.
     */
    show() {
        this.element.dataset.show = true;
    }

    /**
     * @method hide
     * @description Sets the data-show to false. This is used by CSS to show/hide the dealer item.
     */
    hide() {
        this.element.dataset.show = false;
    }

    /**
     * @method expand
     * @description Sets the data-selected-dealer attribute on the dealer node to true
     * This data-attr is used by CSS to show/hide more details
     */
    expand() {
        this.element.setAttribute(ATTRIBUTES.SELECTED_DEALER, true);
    }

    /**
     * @method collapse
     * @description Sets the data-selected-dealer attribute on the dealer node to false
     */
    collapse() {
        this.element.setAttribute(ATTRIBUTES.SELECTED_DEALER, false);
    }
    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.detachEvents();
        this.element.setAttribute(ATTRIBUTES.SELECTED_DEALER, false);
        this.element.remove();
    }

    /**
     * @method setPreferred
     * @description Sets data-is-preferred="true"
     */
    setPreferred() {
        this.element.setAttribute(ATTRIBUTES.PREFERRED_DEALER, true);
        this.yourPreferredDealer.classList.add(CLASSES.YOUR_DEALER_VISIBLE);
    }

    /**
     * @method unsetPreferred
     * @description Sets the data-is-preferred="false"
     */
    unsetPreferred() {
        this.element.setAttribute(ATTRIBUTES.PREFERRED_DEALER, false);
        this.yourPreferredDealer.classList.remove(CLASSES.YOUR_DEALER_VISIBLE);
    }

    /**
     * @method unsetPreferredDealer
     * @description remove preferred dealer from the dealer in the list
     */
    unsetPreferredDealer(event) {
        event.stopPropagation();
        this.unsetPreferred();
        this.model.setPreferredDealer(null);
    }

    /**
     * @method render
     * @description Renders the dealer search types element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

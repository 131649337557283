// Platform dependencies
import { EVENTS } from 'Constants';

// Local dependencies
import viewMoreDrawerTemplate from './../templates/viewMoreDrawerTemplate';

/**
 * @const DEFAULT_OPTIONS
 * @description Default options used for configuration of ViewMoreDrawer
 */
const DEFAULT_OPTIONS = {
    parentInitialHeight: 100,
    viewMoreCopy: 'View More',
    viewLessCopy: 'View Less'
};

/**
 * @const CLASSES
 * @description CSS Classes used to query DOM for ViewMoreDrawer
 */
const CLASSES = {
    VIEW_MORE_DRAWER_CTA: 'view-more-drawer__cta',
    VIEW_MORE_DRAWER_CTA_COPY: 'view-more-drawer__cta-copy',
    VIEW_MORE_DRAWER_CONTENT: 'view-more-drawer__content',
    VIEW_MORE_DRAWER_CONTENT_CONTAINER: 'view-more-drawer__content-container',
};

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{EXPANDED: string}}
 */
const ATTRIBUTES = {
    EXPANDED: 'aria-expanded'
};

/**
 * @class ViewMoreDrawer
 * @description Class representing a module to expand the dealer notes
 */
export default class ViewMoreDrawer {
    /**
     * Creates an instance of ViewMoreDrawer
     * @param {Object} content - i18n content object
     * @param {string} options - Configurational properties
     */
    constructor(options, content) {
        this.options = (options) ? ({ ...DEFAULT_OPTIONS, ...options }) : DEFAULT_OPTIONS;

        this.element = null;
        this.viewMoreCTAClickHandler = this.viewMoreCTAClickHandler.bind(this);
        this.isExpanded = false;
        this.renderViewMoreDrawer(content, options);
        this.cacheDOM();
        this.attachEvents();
        this.setDefaultHeight();
    }

    /**
     * @method destroy
     * @description Destroys the instance by detaching its events
     */
    destroy() {
        this.element = null;
        this.detachEvents();
    }

    /**
     * @method renderViewDrawer
     * @description Returns element with viewMoreDrawerTemplate
     */
    renderViewMoreDrawer(content, options) {
        this.element = viewMoreDrawerTemplate(content, options)({ getNode: true });
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements for ViewMoreDrawer
     */
    cacheDOM() {
        this.viewMoreCTAElm = this.element.querySelector(`.${CLASSES.VIEW_MORE_DRAWER_CTA}`);
        this.viewMoreCTACopyElm = this.element.querySelector(`.${CLASSES.VIEW_MORE_DRAWER_CTA_COPY}`);
        this.viewMoreDrawerContentElm = this.element.querySelector(`.${CLASSES.VIEW_MORE_DRAWER_CONTENT}`);
        this.viewMoreDrawerContentContainerElm = this.element.querySelector(`.${CLASSES.VIEW_MORE_DRAWER_CONTENT_CONTAINER}`);
    }

    /**
     * @method attachEvents
     * @description Attaches event handlers
     * Click handler for clicking the CTA
     */
    attachEvents() {
        this.viewMoreCTAElm.addEventListener(EVENTS.CLICK, this.viewMoreCTAClickHandler);
    }

    /**
     * @method detachEvents
     * @description Detaches event handlers
     */
    detachEvents() {
        this.viewMoreCTAElm.removeEventListener(EVENTS.CLICK, this.viewMoreCTAClickHandler);
    }

    /**
     * @method setDefaultHeight
     * @description Sets the max height of the content container before being expanded
     */
    setDefaultHeight() {
        this.viewMoreDrawerContentContainerElm.style.maxHeight = `${this.options.parentInitialHeight}px`;
    }

    /**
     * @method viewMoreCTAClickHandler
     * @description Handles the CTA click event which opens and closes the drawer
     */
    viewMoreCTAClickHandler(event) {
        event.preventDefault();

        this.isExpanded = this.viewMoreCTAElm.getAttribute(ATTRIBUTES.EXPANDED) === 'false';
        this.viewMoreCTAElm.setAttribute(ATTRIBUTES.EXPANDED, `${this.isExpanded}`);

        const expandAnimation = [
            { maxHeight: `${this.viewMoreDrawerContentContainerElm.offsetHeight}px` },
            { maxHeight: `${this.viewMoreDrawerContentElm.offsetHeight}px` },
            { maxHeight: '100%' }
        ];

        const collapseAnimation = [
            { maxHeight: `${this.viewMoreDrawerContentContainerElm.offsetHeight}px` },
            { maxHeight: `${this.options.parentInitialHeight}px` }
        ];

        this.viewMoreDrawerContentContainerElm.animate(
            this.isExpanded ? expandAnimation : collapseAnimation,
            {
                easing: 'linear',
                fill: 'both',
                duration: 300,
                iterations: 1
            }
        ).play();

        this.viewMoreCTACopyElm.innerText = this.isExpanded ? `${this.options.viewLessCopy}` : `${this.options.viewMoreCopy}`;
    }

     /**
     * @method render
     * @description Returns the view element node
     * @return {Element|*}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import clearGlobalHeader from './clearGlobalHeader';

/**
 * @method onToggleFocus
 * @description Callback for when a select control toggle is focused
 * @param {Event} The focus event
 */
export default function onToggleFocus(event) {
    clearGlobalHeader(event.currentTarget);
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

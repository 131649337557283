// Utility for retrieving images asynchronously

import ImagePromise from './ImagePromise';

/**
 * @method loadImage
 * @description Loads an image resource based on its path and creates an Image object
 * @param imagePath {String} The path of the image resource to be retrieved
 * @return {ImagePromise} Promise that is resolve/reject with an Image object once the
 * image has finished loading
 */
function loadImage(imagePath) {
    return new ImagePromise(imagePath);
}

/**
 * @method loadImages
 * @description Loads a collection of images based on path name
 * @param imagePaths {Array} Collection of image paths
 * @param percentCallback {Function} Callback to set loaded percent
 */
function loadImages(imagePaths, percentCallback = null) {
    const images = imagePaths.map(loadImage);

    return ImagePromise.all(images, percentCallback);
}

/**
 * Export public methods
 */
export default {
    loadImages,
    loadImage
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

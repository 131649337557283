// partial dependencies
import AnalyticsApi from 'partials/analytics';

// local dependencies
import paymentDriverEstimatorUtils from '../api/paymentDriverEstimatorUtils';
import PaymentDriverEstimatorCache from './PaymentDriverEstimatorCache';

/**
 * @property paymentDriverEstimatorCache
 * @description Instance of the PaymentDriverEstimatorCache that is used for caching
 * successful Payment Driver requests
 * @type {PaymentDriverEstimatorCache}
 */
const paymentDriverEstimatorCache = new PaymentDriverEstimatorCache();

/**
 * @property getTownshipController
 * @description Reference to the getTownships fetch AbortControl
 */
let getTownshipsController;

/**
 * @method parseLocationObject
 * @description create an array of location objects that are compatible with select components
 * @param {Object} responseData location api response
 */
function parseLocationObject(responseData) {
    const locationObj = responseData.details && responseData.details.locationID ? responseData.details.locationID : [];

    return locationObj.map((township) => ({
        value: township.code,
        label: township.description
    }));
}

/**
 * @function isLocationApiSuccessful
 * @description Checks if the response code is a 2xx
 * @returns {Boolean} True if the response code is 2xx
 */
function isLocationApiSuccessful(response) {
    return /^2\d\d$/.test(response.status.code);
}

/**
 * @function logErrorEvent
 * @description trigger a satellite event for service errors
 * @param {string} eventName name of analtyics event
 * @param {string} description description of error
 * @param {string} type error status code
 */
function logErrorEvent(eventName, description, type) {
    const analyticsApi = new AnalyticsApi('satellite');
    analyticsApi.logEvent(eventName, {
        name: description,
        type
    });
}

/**
 * @function ExceptionError
 * @description Constructor function that creates a custom error object
 * @param {number} code status code from response
 * @param {string} message error message
 */
function ExceptionError(code, message) {
    this.code = code;
    this.message = message;
    return this;
}

/**
 * @method getTownships
 * @description get location data based on zipcode and vehicle info
 * @param {object} configData all data needed to configure request
 * @param {string} configData.endpoint payment driver location api endpoint
 * @param {object} configData.requestData data needed to create request object
 * @param {string} configData.requestData.dealerId vehicle dealer id
 * @param {string} configData.requestData.modelCode vehicle model id
 * @param {number} configData.requestData.retailCost reserve price of vehicle
 * @param {boolean} configData.requestData.reservable reservable status of vehicle
 * @param {string} configData.requestData.type type of vehicle (NEW | PRE)
 * @param {Boolean} configData.requestData.certified Indicator of certified pre-owned status
 * @param {string} configData.requestData.vin vehicle vin
 * @param {string} configData.requestData.year vehicle model year
 * @param {string} configData.requestData.zipCode user entered zip code
 */
function getTownships(configData) {
    getTownshipsController = new AbortController();

    const {
        endpoint,
        requestData,
        requestData: {
            certified,
            type
        }
    } = configData;

    const requestObj = {
        ...requestData,
        financeType: 'loan',
        vehicleCondition: paymentDriverEstimatorUtils.setVehicleCondition(type, certified)
    };

    delete requestObj.certified;
    delete requestObj.type;

    return paymentDriverEstimatorCache.fetch(endpoint, {
        body: JSON.stringify(requestObj),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST',
        signal: getTownshipsController.signal
    })
    .then((response) => response.json())
    .then((response) => {
        if (!isLocationApiSuccessful(response)) {
            throw new ExceptionError(
                response.status.code,
                response.status.description
            );
        }

        return parseLocationObject(response);
    })
    .catch((error) => {
        const code = error.code;
        if (error instanceof TypeError && !navigator.onLine) {
            throw new ExceptionError(
                600,
                'Connectivity Error'
            );
        }
        if (error.message.match('ZipCode not found in database.')) {
            throw new ExceptionError(
                601,
                'Invalid Zip Code Error'
            );
        }
        if (code === 499 || code === 500) {
            logErrorEvent(`error${code}`, `Payment Driver Location Api - ${error.message}`, code);
        } else {
            logErrorEvent('error', `Payment Driver Location Api - ${error.message || error}`, error.code || '');
        }
        throw error;
    });
}

/**
 * @function abortGetTownships
 * @description Callback to cancel a getTownships request
 * This is helpful to cancel a stream of service requests to reduce multiple
 * responses from being returned in an inaccurate order
 */
export function abortGetTownships() {
    if (getTownshipsController) {
        getTownshipsController.abort();
    }
}

export default {
    abortGetTownships,
    getTownships
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS, KEYBOARD } from 'Constants';
import dealerTemplate from './../templates/dealerTemplate';

/**
 * @constant ARIA_ATTRIBUTES
 * @description Aria attribute references for the Dealer view
 * @type {{ERROR: string}}
 */
const ARIA_ATTRIBUTES = {
    INVALID: 'aria-invalid',
    DESCRIBED_BY: 'aria-describedby'
};

/**
 * Class representing the results view for individual dealer.
 */
export default class Dealer {
    /**
     * @constructor
     * @param dealerData {Object} data of dealer from API.
     */
    constructor({ dealer, onSelectCallback } = {}, content, errorMessageId) {
        this.dealerData = dealer;
        this.onSelectCallback = onSelectCallback;
        this.errorMessageId = errorMessageId;
        this.element = dealerTemplate({
            dealer: this.dealerData
        }, content)({ getNode: true });

        this.onDealerSelect = this.onDealerSelect.bind(this);

        this.attachEvents();
    }

    /**
     * @method destroy
     * @description Detaches events and resets class variables
     */
    destroy() {
        this.detachEvents();
    }

    /**
     * @method onDealerSelect
     * @description Click handler for the selecting list item element
     */
    onDealerSelect(event) {
        const { which } = event;

        if (event.type === EVENTS.CLICK || which === KEYBOARD.ENTER) {
            this.onSelectCallback(this.dealerData);
        }
    }

    /**
     * @method attachEvents
     * @description Attaches click event to the list item element
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, this.onDealerSelect);
        this.element.addEventListener(EVENTS.KEYDOWN, this.onDealerSelect);
    }

    /**
     * @method detachEvents
     * @description Detaches the associated events
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.CLICK, this.onDealerSelect);
        this.element.addEventListener(EVENTS.KEYDOWN, this.onDealerSelect);
    }

    /**
    * @method setErrorState
    * @description sets error state for Dealer view
    */
    setErrorState(focus) {
        if (focus && this.element) {
            this.element.setAttribute(ARIA_ATTRIBUTES.INVALID, 'true');
            this.element.setAttribute(ARIA_ATTRIBUTES.DESCRIBED_BY, `error${this.errorMessageId}`);
            this.element.focus();
        } else if (this.element) {
            this.element.removeAttribute(ARIA_ATTRIBUTES.INVALID);
            this.element.removeAttribute(ARIA_ATTRIBUTES.DESCRIBED_BY);
        }
    }

    /**
     * @method render
     * @description Returns the element associated
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import ButtonControl from './ButtonControl';
import loadingButtonControlTemplate from './../templates/loadingButtonControlTemplate';

/**
 * @const ATTRIBUTES
 * @description Attribute references for the LoadingButtonControl Module
 * @type {{DATA_LOADING: string}}
 */
const ATTRIBUTES = {
    DATA_LOADING: 'data-loading'
};

/**
 * @class LoadingButtonControl
 * @description View for button with embedded loading spinner
 */
export default class LoadingButtonControl extends ButtonControl {
    /**
     * @constructor
     * @extends ButtonControl
     * @param {Object} config - Config for LoadingButtonControl
     * @param {String} config.analyticsTrigger - Optional value to set as the button's data-analytics-trigger
     * @param {String} config.ariaLabel - Optional value to set as the button's aria-label
     * @param {Boolean} config.autoToggle - Toggle by disregarding loading state
     * @param {String} config.type - Type of button. Values are set through ButtonControl#TYPES
     * @param {String} config.modifier - Modifier applied to button. Values are set through ButtonControl#MODIFIER
     * @param {Boolean} config.disabled - Disabled flag
     * @param {String} config.valueText - Copy for button
     * @param {String} config.loadingValueText - Copy for button when in loading state
     * @param {String} config.cssClasses - CSS Classes
     * @param {Function} config.onClick - Click handler function for the button
     */
    constructor(config) {
        super(config);

        this.valueText = config.valueText;
        this.loadingValueText = config.loadingValueText || '';
        this.loading = false;
        this.autoToggle = config.autoToggle === undefined ? true : config.autoToggle;
        this.setComplete = this.setComplete.bind(this);
    }

    /**
     * @method getElement
     * @description Calls the template with necessary values
     * @returns {Node} Loading Button Control Template Node
     */
    getElement({
        type,
        modifier,
        disabled,
        cssClasses,
        valueText,
        analyticsTrigger,
        ariaLabel,
        renderedElement }) {
        return renderedElement || loadingButtonControlTemplate({
            type,
            modifier,
            disabled,
            cssClasses,
            valueText,
            analyticsTrigger,
            ariaLabel
        })({ getNode: true });
    }

    /**
     * @method setLoading
     * @description Sets the CTA in loading state by:
     * a. Disabling button
     * b. Setting data-loading attribute to true
     */
    setLoading() {
        this.loading = true;
        this.valueTextElement.innerHTML = this.loadingValueText;
        this.element.setAttribute(ATTRIBUTES.DATA_LOADING, 'true');
    }

    /**
     * @method setComplete
     * @description Sets the CTA to complete/default state by:
     * a. Enabling button/removing disabled CSS class
     * b. Setting data-loading attribute to false
     */
    setComplete() {
        this.loading = false;
        this.valueTextElement.innerHTML = this.valueText;
        this.element.setAttribute(ATTRIBUTES.DATA_LOADING, 'false');
    }

    /**
     * @method toggle
     * @description Calls setComplete/setLoading based on loading state
     */
    toggle() {
        this[this.loading ? 'setComplete' : 'setLoading']();
    }

    /**
     * @method isLoading
     * @description Returns the loading state
     * @returns {Boolean} Class variable `loading`
     */
    isLoading() {
        return this.loading;
    }

    /**
     * @method onClick
     * @description Overrides the default behavior of click handler. This override does the
     * following:
     * a. Sets the loading state
     * b. Adds paddingLeft to button to make room for spinner
     * c. Calls the click callback passed in through config, and passes in MouseEvent along with
     * this.setComplete
     */
    onClick(event) {
        if (!this.isLoading() && this.autoToggle) {
            this.setLoading();
            this.onClickCallback(event, this.setComplete);
        } else if (!this.autoToggle) {
            this.onClickCallback(event, this.setComplete);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

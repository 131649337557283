// Util dependencies
import { generateUniqueID, htmlNode } from 'utils';

import content from '../config/content';

/**
 * @description Template for YouTube modal container
 * Uses ES6 template literal to composite the markup
 */
export default ({ videoId, imageUrl, videoTranscript, uid = generateUniqueID() }) => htmlNode`
    <div class="youtube-modal__outer-container" data-video-id="${videoId}">
        <div class="youtube-modal__container">
            ${imageUrl ? `
                <button class="youtube-trigger youtube-trigger__modal hide"
                        aria-hidden="true"
                        style="background-image: url('${imageUrl}');">
                    <span class="offscreen">${content.alertYouTube}</span>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         xml:space="preserve" viewBox="0 0 62 62" class="youtube-trigger__icon-play">
                        <circle class="youtube-trigger__circle" cx="31" cy="31" r="31"></circle>
                        <polygon class="youtube-trigger__triangle" points="24,17.6 24,43.4 41.3,30.4 "></polygon>
                    </svg>
                </button>`
                : ''
            }
            <div class="youtube-container-iframe"></div>
        </div>
        <div class="youtube-transcript__wrapper ${!videoTranscript ? 'hide' : ''}">
            <button class="youtube-transcript__toggle-button"
                    aria-controls="youtube-transcript${uid}"
                    aria-expanded="false"
                    data-toggle-drawer-button>${content.showVideoTranscript}</button>
            <div class="youtube-transcript__content rte" tabindex="-1"
                 aria-hidden="true"
                 id="youtube-transcript${uid}">
                 ${videoTranscript}
            </div>
        </div>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * PanelList Module
 * Has methods related to composition of the header module
 */
import { screen } from 'utils';

import Panel from './Panel';

const CLASSES = {
    GLOBAL_HEADER_MENU_L3_PANEL_ITEM: 'global-header__menu-l3-panel-item',
};

export default class PanelList {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.setBindings();
        this.cacheDOMElements();
        this.buildPanelList();
        this.attachEvents();
        this.state = {
            selectedPanelIndex: null,
            currentScreenState: screen.getCurrentState()
        };
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onScreenResize = this.onScreenResize.bind(this);
        this.switchPanels = this.switchPanels.bind(this);
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {

    }

    /**
     * @method attachEvents
     * @description Attaches event listener
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
    }

    /**
     * @method detachEvents
     * @description detach events
     */
    detachEvents() {
        screen.removeResizeListener(this.onScreenResize);
    }

    /**
     * @method buildPanelList
     * @description instantiate a list of panels
     */
    buildPanelList() {
        this.panelItemElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L3_PANEL_ITEM}`)]
            .map((panelElm) => new Panel(panelElm));
    }

    /**
     * @method switchPanels
     * @description deactivate previous active panel and activate new one
     */
    switchPanels(newPanelIndex) {
        this.deactivate();
        this.activate(newPanelIndex);
    }

    /**
     * @method activate
     * @description activate panel based on given index
     * @param panelIndex {int} index of new panel to be active
     */
    activate(panelIndex) {
        if (this.panelItemElms[panelIndex]) {
            this.panelItemElms[panelIndex].activate();
            this.updateState({
                selectedPanelIndex: panelIndex
            });
        }
    }

    /**
     * @method deactivate
     * @description deactivate panel based on selected index
     */
    deactivate() {
        const { selectedPanelIndex } = this.getState();
        if (selectedPanelIndex !== null) {
            this.panelItemElms[selectedPanelIndex].deactivate();
        }
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        const newScreenState = screen.getCurrentState();
        const { currentScreenState } = this.getState();
        if (currentScreenState !== newScreenState) {
            this.updateState({ currentScreenState: newScreenState });
        }
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {object} new state value
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return this.state;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

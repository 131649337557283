import { htmlNode } from 'utils';

/**
 * @description Template that builds out a disclaimer legend
 */
export default ({ analyticsTrigger, autoNum, ctaText, hideLegend, legend } = {}) => {
    const legendElement = autoNum ? `<sup className="disclaimer__legend-reference">[${legend}]</sup>` : legend;

    return htmlNode`<a href="#disclaimer-drawer"
       aria-controls="disclaimer-drawer"
       class="disclaimer__marker"
       data-analytics-trigger="${analyticsTrigger}"
       data-legend="${legend}">
        ${ctaText}
        ${!hideLegend ? legendElement : ''}
    </a>`;
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

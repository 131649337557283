// Local dependencies
import InputControl from './InputControl';
import inputRadioControlTemplate from './../templates/inputRadioControlTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    INPUT_ELEMENT: 'form__radio',
    LABEL_ELEMENT: 'form__radio-label',
};

/**
 * Class that will set up an InputRadioControl
 */
export default class InputRadioControl extends InputControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputRadioControl
     * @type {Object}
     * @const checked {Boolean} True if radio should display as checked
     */
    static DEFAULT_CONFIG = {
        ...InputControl.DEFAULT_CONFIG,
        onClickCallback: () => {},
        checked: false
    };

    /**
     * @constructor Create an InputRadioControl
     */
    constructor(config = InputRadioControl.DEFAULT_CONFIG) {
        super({
            ...InputRadioControl.DEFAULT_CONFIG,
            ...config
        });
        this.valid = true; // stores the validity of the input
        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.createView(inputRadioControlTemplate({
            id: this.getConfig('id'),
            name: this.getConfig('name'),
            ariaLabel: this.getConfig('ariaLabel'),
            dataAttributes: this.getConfig('dataAttributes').join(' '),
            inputClassNames: this.getConfig('inputClassNames').join(' '),
            labelClassNames: this.getConfig('labelClassNames').join(' '),
            checked: this.getConfig('checked'),
            valueText: this.getConfig('valueText'),
            labelText: this.getConfig('labelText'),
            analyticsTrigger: this.getConfig('analyticsTrigger'),
            disclaimer: this.getConfig('disclaimer'),
            groupId: this.getConfig('groupId')
        })({ getNode: true }));
        super.init();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        super.cacheDOM();
        this.labelElement = this.getInput().querySelector(`.${CLASSES.LABEL_ELEMENT}`);
        this.setInputElement(this.getInput().querySelector(`.${CLASSES.INPUT_ELEMENT}`));
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if input value passes validation
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method isChecked
     * @description Returns the checked value of the radio option
     * @return {Boolean} Checked value of radio option
     */
    isChecked() {
        return this.getInputElement().checked;
    }

    /**
     * @method getValue
     * @description Gets the value of the radio
     * @returns {String} - radio value
     */
    getValue() {
        return this.getInputElement().value;
    }

    /**
     * @method toggleChecked
     * @description Toggles the checked attribute for radio control
     */
    toggleChecked() {
        this.getInputElement().checked = !this.getInputElement().checked;
    }

    /**
     * @method onChange
     * @description Calls this.config.onChangeCallback
     * @param e {Event} Event object from change event
     */
    onChange(e) {
        this.config.onChangeCallback(e);
    }

    onClick(e) {
        this.config.onClickCallback(e);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * @description Template for overlay video grid video
 * Uses ES6 template literal to composite the markup for overlay video grid video for a topic/tag
 */
export default (data = {}) => htmlNode`
    <div class="overlay-video-grid__modal-video-inner">
        <div class="overlay-video-grid__video-preview-container">
            <picture>
                <img src="${data.thumbnail}" alt="${data.altText}" class="overlay-video-grid__preview-image">
            </picture>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 62 62" focusable="false" class="overlay-video-grid__icon-play">
                <circle class="overlay-video-grid__circle" cx="31" cy="31" r="31">
                </circle>
                <polygon class="overlay-video-grid__triangle" points="24,17.6 24,43.4 41.3,30.4 ">
                </polygon>
            </svg>
        </div>
    
        <div class="overlay-video-grid__youtube-container">
            <div class="overlay-video-grid__youtube-placeholder"></div>
        </div>
    </div>                
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

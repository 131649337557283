import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import {
    customEventDispatcher,
    screen,
    isScrolledIntoView,
    Swiper,
    debounce
} from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    SLIDESHOW: 'mb-slideshow',
    SLIDESHOW_AUTO_ADVANCE_CONTAINER: 'mb-slideshow__auto-advance-container',
    SLIDESHOW_AUTO_ADVANCE_LABEL: 'mb-slideshow__auto-advance-label',
    SLIDESHOW_AUTO_ADVANCE_BUTTON: 'mb-slideshow__auto-advance-button',
    SLIDESHOW_GALLERY_CONTAINER: 'mb-slideshow__gallery-container',
    SLIDESHOW_GALLERY_INNER_WRAPPER: 'mb-slideshow__gallery-inner-wrapper',
    SLIDESHOW_GALLERY_OUTER_WRAPPER: 'mb-slideshow__gallery-outer-wrapper',
    SLIDESHOW_GALLERY_HERO_IMAGE: 'mb-slideshow__gallery-hero-image',
    SLIDESHOW_GALLERY_HERO_IMAGE_WRAPPER: 'mb-slideshow__gallery-hero-image-wrapper',
    SLIDESHOW_GALLERY_HERO_IMAGE_ACTIVE: 'mb-slideshow__gallery-hero-image--active',
    SLIDESHOW_GALLERY_TILE: 'content-tile--vehicle-overlay',
    SLIDESHOW_GALLERY_TILE_IMAGE_CONTAINER: 'content-tile--vehicle-overlay__image-container',
    SLIDESHOW_GALLERY_TILE_BUTTON: 'content-tile--vehicle-overlay__tile--button',
    SLIDESHOW_GALLERY_TILE_CONTAINER: 'content-tile--vehicle-overlay__timeline-container',
    SLIDESHOW_GALLERY_TILE_NUMBER: 'content-tile--vehicle-overlay__timeline-number',
    SLIDESHOW_GALLERY_TILE_PROGRESS_BAR: 'content-tile--vehicle-overlay__timeline-progress-bar',
    TILE_HEADING: 'content-tile__tile-heading',
    STOP: 'stop',
    PLAY: 'play',
    HIDE: 'hide',
    ACTIVE: 'active',
    SINGLE_TILE: 'single-tile'
};

/**
 * @const ATTRIBUTES
 * @description Stores a collection of DOM element attribute names
 */
const ATTRIBUTES = {
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_DISABLED: 'aria-disabled',
    SRC: 'src',
    ALT: 'alt'
};

/**
 * @const DEFAULT_OPTIONS
 * @description Collection of default options for the view
 */
const DEFAULT_OPTIONS = {
    i18n: {
        stopSlideshow: 'Stop slideshow',
        startSlideshow: 'Start slideshow'
    },
    slideDuration: 6
};

const CONSTANTS = {
    tileWidths: {
        small: 89.5,
        large: 44.600391
    },
    tileMargins: {
        small: '10px',
        large: '1.9389323vw'
    },
    centeringOffsets: {
        small: 2,
        large: 0
    },
    outerContainerWidths: {
        small: 93.4375,
        large: 91.14583
    },
    intersectionRatio: 0.8,
    WIDTH: 'width'
};

/**
 * Slideshow Module
 * This module shows a gallery of slides that moves with a timer using the
 * Swiper util
 */
export default class MBSlideshow {
    constructor(element) {
        this.element = element;
        this.state = {
            currentScreenState: screen.getCurrentState(),
            currentTileIndex: 0,
            nextTileIndex: 0,
            inTransition: false,
            autoplayInited: false,
            autoplayDisabled: this.element.dataset.autoplayDisabled !== 'true',
            autoplayDisabledByUser: false,
            autoplayDisabledByModal: false,
            autoplayDisabledByYouTube: false,
            activeYouTubeVideoId: null,
            inView: false
        };

        const isAuthor = window.mbVans.ns('pageData').wcmmode.edit || window.mbVans.ns('pageData').wcmmode.preview;
        if (!isAuthor) {
            this.slideDuration = window.mbVans.ns('pageData', 'mbSlideshow').config.slideDuration
                || DEFAULT_OPTIONS.slideDuration;
            this.stopSlideshow = window.mbVans.ns('pageData', 'mbSlideshow').localization.stopSlideshowLabel
                || DEFAULT_OPTIONS.i18n.stopSlideshow;
            this.startSlideshow = window.mbVans.ns('pageData', 'mbSlideshow').localization.startSlideshowLabel
                || DEFAULT_OPTIONS.i18n.startSlideshow;
            this.init();
        }
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.setBindings();
        this.cacheDOMElements();
        this.groomTiles();
        if (this.galleryTiles.length > 0) {
            this.attachEvents();
            this.setContainerWidth();
            this.populateTileNumber();
            this.checkSwiper();
        }
        if (this.galleryTiles.length === 1) {
            this.setTileWidth();
            this.showTimelines();
            window.requestAnimationFrame(this.setActiveTile);
        }
        if (this.galleryTiles.length > 1) {
            this.initAutoplay();
            window.requestAnimationFrame(() => {
                this.setActiveTile();
                this.onScroll();
            });
        }
    }

    /**
     * @method checkSwiper
     * @description function check if the swiper has started and if the number
     * of tiles and screen size should start the swiper
     */
    checkSwiper() {
        if (((screen.getCurrentState() === screen.SIZES.SMALL && this.galleryTiles.length > 1) ||
            (screen.getCurrentState() === screen.SIZES.LARGE && this.galleryTiles.length > 2)) &&
            !this.swiperEnabled) {
            this.enableSwiper();
        } else if (this.swiperEnabled) {
            this.disableSwiper();
        }
    }

    /**
     * @method enableSwiper
     * @description function to create the swiper and start it
     */
    enableSwiper() {
        const { currentTileIndex } = this.getState();
        this.swiper = new Swiper(
            this.galleryOuterWrapper,
            this.galleryInnerWrapper,
            this.galleryTiles,
            this.activeTileUpdateCallback,
            CONSTANTS,
            currentTileIndex
        );
        this.swiperEnabled = true;
    }

    /**
     * @method disableSwiper
     * @description function to destroy the swiper
     */
    disableSwiper() {
        this.swiper.destroy();
        this.swiperEnabled = false;
    }

    /**
     * @method activeTileUpdateCallback
     * @description Callback function to focus on the tile button
     * @param nextTileIndex index of the tile navigating to
     */
    activeTileUpdateCallback(nextTileIndex) {
        const btnElm = this.galleryTiles[nextTileIndex].querySelector(`.${CLASSES.SLIDESHOW_GALLERY_TILE_BUTTON}`);
        if (btnElm) {
            btnElm.focus();
        }
        this.updateState({
            nextTileIndex
        });
        this.setActiveTile();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onScroll = debounce(this.onScroll.bind(this), 50);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onTileVehicleOverlayTrigger = this.onTileVehicleOverlayTrigger.bind(this);
        this.onTileVehicleOverlayButtonTrigger = this.onTileVehicleOverlayButtonTrigger.bind(this);
        this.onYouTubeVideoStateChange = this.onYouTubeVideoStateChange.bind(this);
        this.onAutoPlayClick = this.onAutoPlayClick.bind(this);
        this.onProgressBarTransitionEnd = this.onProgressBarTransitionEnd.bind(this);
        this.setActiveTile = this.setActiveTile.bind(this);
        this.activeTileUpdateCallback = this.activeTileUpdateCallback.bind(this);
    }

    /**
     * @method cacheDOMElements
     * @description Caches references to DOM elements of the module
     */
    cacheDOMElements() {
        this.heroImageContainerElms = [].slice.call(this.element.querySelectorAll(`.${CLASSES.SLIDESHOW_GALLERY_HERO_IMAGE}`));
        this.progressBarElms = [].slice.call(this.element.querySelectorAll(`.${CLASSES.SLIDESHOW_GALLERY_TILE_PROGRESS_BAR}`));
        this.slideshowAutoplayContainer = this.element.querySelector(`.${CLASSES.SLIDESHOW_AUTO_ADVANCE_CONTAINER}`);
        this.slideshowAutoplayButton = this.element.querySelector(`.${CLASSES.SLIDESHOW_AUTO_ADVANCE_BUTTON}`);
        this.slideshowAutoplayLabel = this.element.querySelector(`.${CLASSES.SLIDESHOW_AUTO_ADVANCE_LABEL}`);
        this.galleryHeroImageWrapper = this.element.querySelector(`.${CLASSES.SLIDESHOW_GALLERY_HERO_IMAGE_WRAPPER}`);
        this.galleryTiles = [].slice.call(this.element.querySelectorAll(`.${CLASSES.SLIDESHOW_GALLERY_TILE}`));
        this.galleryContainer = this.element.querySelector(`.${CLASSES.SLIDESHOW_GALLERY_CONTAINER}`);
        this.galleryOuterWrapper = this.element.querySelector(`.${CLASSES.SLIDESHOW_GALLERY_OUTER_WRAPPER}`);
        this.galleryInnerWrapper = this.element.querySelector(`.${CLASSES.SLIDESHOW_GALLERY_INNER_WRAPPER}`);
    }

    /**
     * @method groomTiles
     * @description Loops through gallery tiles, and removes any which have not been authored
     */
    groomTiles() {
        for (let i = this.galleryTiles.length - 1; i >= 0; i -= 1) {
            const tile = this.galleryTiles[i];
            if (!tile.querySelector(`.${CLASSES.TILE_HEADING}`)) {
                tile.remove();
                this.galleryTiles.splice(i, 1);
                this.heroImageContainerElms[i].remove();
                this.heroImageContainerElms.splice(i, 1);
            }
        }
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
        if (this.galleryTiles.length > 1) {
            window.addEventListener(EVENTS.SCROLL, this.onScroll);
        }
        if (this.slideshowAutoplayButton) {
            this.slideshowAutoplayButton.addEventListener(EVENTS.CLICK, this.onAutoPlayClick);
        }
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_TRIGGER,
            this.onTileVehicleOverlayTrigger
        );
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_BUTTON_TRIGGER,
            this.onTileVehicleOverlayButtonTrigger
        );
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.YOUTUBE_VIDEO_STATE_CHANGE,
            this.onYouTubeVideoStateChange
        );
    }


    /**
     * @method attachProgressBarEvents
     * @description Attaches event listeners for progress bars
     */
    attachProgressBarEvents() {
        this.progressBarElms.forEach((progressBar) => {
            progressBar.addEventListener(EVENTS.TRANSITION_END, this.onProgressBarTransitionEnd);
        });
    }

    /**
     * @method detachProgressBarEvents
     * @description Detaches event listeners for progress bars
     */
    detachProgressBarEvents() {
        this.progressBarElms.forEach((progressBar) => {
            progressBar.removeEventListener(EVENTS.TRANSITION_END, this.onProgressBarTransitionEnd);
        });
    }

    /**
     * @method detachEvents
     * @description Detaches event handlers
     */
    detachEvents() {
        screen.removeResizeListener(this.onScreenResize);
        window.removeEventListener(EVENTS.SCROLL, this.onScroll);
        if (this.slideshowAutoplayButton) {
            this.slideshowAutoplayButton.removeEventListener(EVENTS.CLICK, this.onAutoPlayClick);
        }
        this.detachProgressBarEvents();
        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_TRIGGER,
            this.onTileVehicleOverlayTrigger
        );
        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_BUTTON_TRIGGER,
            this.onTileVehicleOverlayButtonTrigger
        );
        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.YOUTUBE_VIDEO_STATE_CHANGE,
            this.onYouTubeVideoStateChange
        );
    }

    /**
     * @method onScroll
     * @description Listener for window scroll event
     */
    onScroll() {
        const { inView, autoplayInited, autoplayDisabledByUser, autoplayDisabledByYouTube } = this.getState();
        const scrolledIntoView = isScrolledIntoView(this.galleryContainer);
        if (scrolledIntoView && !inView) {
            this.enableAutoplayButton();
            if (autoplayInited && !autoplayDisabledByUser && !autoplayDisabledByYouTube) {
                this.enableAutoplay({
                    isInit: true
                });
            }
        } else if (!scrolledIntoView && inView) {
            this.disableAutoplay();
            this.disableAutoplayButton();
        }
        this.updateState({
            inView: scrolledIntoView
        });
    }

    /**
     * @method onYouTubeVideoStateChange
     * @description custom event listener
     */
    onYouTubeVideoStateChange(e) {
        const { videoId, player, currentState, states } = e.detail;
        const { autoplayDisabledByUser } = this.getState();
        const ancestor = player.closest(`.${CLASSES.SLIDESHOW_GALLERY_INNER_WRAPPER}`);
        if (!ancestor) {
            return;
        }
        if (currentState === states.UNSTARTED || currentState === states.PLAYING) {
            this.updateState({
                activeYouTubeVideoId: videoId
            });
            this.disableAutoplay({
                fromYouTube: true
            });
        } else if ((currentState === states.ENDED || currentState === states.PAUSED) &&
                   !autoplayDisabledByUser) {
            this.enableAutoplay({
                stayOnTile: true
            });
        }
    }

    /**
     * @method setTileWidth
     * @description set width of tile, when there is a single tile
     */
    setTileWidth() {
        this.galleryTiles.forEach((tile) => {
            tile.classList.add(CLASSES.SINGLE_TILE);
        });
    }

    /**
     * @method onAutoPlayClick
     * @description Handler for Autoplay Button click events
     */
    onAutoPlayClick() {
        const { autoplayDisabled } = this.getState();
        this.updateState({
            autoplayDisabled: !autoplayDisabled
        });
        if (autoplayDisabled) {
            this.enableAutoplay();
        } else {
            this.disableAutoplay({
                fromUser: true
            });
        }
    }

    /**
     * @method initAutoplay
     * @description Initialize slideshow autoplay feature
     */
    initAutoplay() {
        const { inView } = this.getState();
        this.showTimelines();
        this.updateState({
            autoplayInited: true
        });
        if (inView) {
            this.enableAutoplay({
                isInit: true
            });
        }
    }

    /**
     * @method showTimelines
     * @description Removes "hide" class from timeline elements
     */
    showTimelines() {
        this.galleryTiles.forEach((tile) => {
            const tileTimelineContainerElm = tile.querySelector(`.${CLASSES.SLIDESHOW_GALLERY_TILE_CONTAINER}`);
            tileTimelineContainerElm.classList.remove(CLASSES.HIDE);
        });
    }

    /**
     * @method enableAutoplay
     * @description turn on Slideshow autoplay feature
     * @param isInit {Boolean} Is initializing
     * @param stayOnTile {Boolean} Do not advance to next slide
     */
    enableAutoplay({ isInit = false, stayOnTile = false } = {}) {
        if (this.galleryTiles.length > 1) {
            this.slideshowAutoplayLabel.innerText = this.stopSlideshow;
            this.slideshowAutoplayButton.classList
                .remove(`${CLASSES.SLIDESHOW_AUTO_ADVANCE_BUTTON}--${CLASSES.PLAY}`);
            this.slideshowAutoplayButton.classList
                .add(`${CLASSES.SLIDESHOW_AUTO_ADVANCE_BUTTON}--${CLASSES.STOP}`);

            this.attachProgressBarEvents();

            this.updateState({
                autoplayDisabled: false,
                autoplayDisabledByUser: false,
                autoplayDisabledByModal: false,
                autoplayDisabledByYouTube: false
            });
            this.element.dataset.autoplayDisabled = false;
            if (!isInit && !stayOnTile) {
                this.goToNextTile();
            } else if (!isInit && stayOnTile) {
                this.setActiveTile();
            }
        }
    }

    /**
     * @method disableAutoplay
     * @description turn off auto advance feature
     * @param fromUser {Bool} Indicates if triggered by user interaction
     * @param fromModal {Bool} Indicates if triggered by modal event
     */
    disableAutoplay({ fromUser = false, fromModal = false, fromYouTube = false } = {}) {
        if (this.galleryTiles.length > 1) {
            const { autoplayDisabledByUser, autoplayDisabledByModal, autoplayDisabledByYouTube } = this.getState();
            this.slideshowAutoplayLabel.innerText = this.startSlideshow;
            this.slideshowAutoplayButton.classList
                .remove(`${CLASSES.SLIDESHOW_AUTO_ADVANCE_BUTTON}--${CLASSES.STOP}`);
            this.slideshowAutoplayButton.classList
                .add(`${CLASSES.SLIDESHOW_AUTO_ADVANCE_BUTTON}--${CLASSES.PLAY}`);

            this.detachProgressBarEvents();

            this.updateState({
                autoplayDisabled: true,
                autoplayDisabledByUser: fromUser ? true : autoplayDisabledByUser,
                autoplayDisabledByModal: fromModal ? true : autoplayDisabledByModal,
                autoplayDisabledByYouTube: fromYouTube ? true : autoplayDisabledByYouTube
            });
            this.element.dataset.autoplayDisabled = true;
        }
    }

    /**
     * @method enableAutoplayButton
     * @description Enable the autoplay button when module is in viewport
     */
    enableAutoplayButton() {
        this.slideshowAutoplayButton.setAttribute(ATTRIBUTES.ARIA_DISABLED, false);
    }

    /**
     * @method disableAutoplayButton
     * @description Disable the autoplay button when module is not in viewport
     */
    disableAutoplayButton() {
        this.slideshowAutoplayButton.setAttribute(ATTRIBUTES.ARIA_DISABLED, true);
    }

    /**
     * @method populateTileNumber
     * @description populate gallery tile number
     */
    populateTileNumber() {
        this.galleryTiles.forEach((tile, index) => {
            const tileNumberElm = tile.querySelector(`.${CLASSES.SLIDESHOW_GALLERY_TILE_NUMBER}`);
            if (tileNumberElm) {
                const tileIndex = index + 1;
                tileNumberElm.innerText = tileIndex > 9 ? `${tileIndex}` : `0${tileIndex}`;
            }
        });
    }

    /**
     * @method onTileVehicleOverlayTrigger
     * @description Handler for tile modal custom event
     * @param e {Event} Custom Event object
     */
    onTileVehicleOverlayTrigger(e) {
        const idx = this.galleryTiles.indexOf(e.detail.tile);
        const { currentTileIndex, autoplayDisabled, autoplayDisabledByUser } = this.getState();
        if (idx !== currentTileIndex) {
            this.swiper.goToTile(idx);
        }
        if (e.detail.modalOpen && !autoplayDisabled) {
            this.disableAutoplay({
                fromModal: true
            });
        } else if (!e.detail.modalOpen && !autoplayDisabledByUser) {
            this.enableAutoplay({
                stayOnTile: true
            });
        }
    }

    /**
     * @method onTileVehicleOverlayButtonTrigger
     * @description Handler for tile button click custom event
     * @param e {Event} Custom Event object
     */
    onTileVehicleOverlayButtonTrigger(e) {
        const idx = this.galleryTiles.indexOf(e.detail.tile);
        const { currentTileIndex, inTransition } = this.getState();
        if (idx === currentTileIndex || inTransition) {
            return;
        }
        if (idx !== -1) {
            if (this.swiperEnabled) {
                this.swiper.goToTile(idx);
            } else {
                this.updateState({
                    nextTileIndex: idx
                });
                this.setActiveTile();
            }
        }
    }

    /**
     * @method setActiveTile
     * @description Adds active class to proper tile
     */
    setActiveTile() {
        const { nextTileIndex } = this.getState();
        this.unsetActiveTile();
        this.galleryTiles[nextTileIndex].classList.add(CLASSES.ACTIVE);
        this.progressBarElms[nextTileIndex].style.transitionDuration = `${this.slideDuration}s`;
        if (screen.gte(screen.SIZES.XLARGE)) {
            this.showHeroImage();
        }
        this.updateState({
            currentTileIndex: nextTileIndex
        });
    }

    /**
     * @method unsetActiveTile
     * @description Removes active class from tile
     */
    unsetActiveTile() {
        const { currentTileIndex, activeYouTubeVideoId } = this.getState();
        this.galleryTiles[currentTileIndex].classList.remove(CLASSES.ACTIVE);
        this.progressBarElms[currentTileIndex].style.transitionDuration = '';
        if (activeYouTubeVideoId) {
            this.pauseYouTubeVideo();
        }
    }

    /**
     * @method setContainerWidth
     * @description set width of container, based on number of tiles
     */
    setContainerWidth() {
        if (this.galleryTiles.length > 1) {
            const { currentScreenState } = this.getState();
            this.galleryInnerWrapper.style.width = `calc(${CONSTANTS.tileWidths[currentScreenState]}vw * ${this.galleryTiles.length} + ${CONSTANTS.tileMargins[currentScreenState]} * ${this.galleryTiles.length - 1})`;
        }
    }

    /**
     * @method unsetContainerWidth
     * @description unset width of container
     */
    unsetContainerWidth() {
        this.galleryInnerWrapper.style.width = '';
    }

    /**
     * @method onScreenResize
     * @description handle resize events from screen util
     */
    onScreenResize() {
        const { currentScreenState, activeYouTubeVideoId } = this.getState();
        const newScreenState = screen.getCurrentState();
        if (currentScreenState !== newScreenState) {
            this.checkSwiper();
            this.updateState({
                currentScreenState: newScreenState
            });
            if (activeYouTubeVideoId) {
                this.pauseYouTubeVideo();
            }
            if (screen.gte(screen.SIZES.XLARGE)) {
                this.showHeroImage();
                this.unsetContainerWidth();
                this.unsetContainerPosition();
                if (this.swiperEnabled) {
                    this.swiper.destroy();
                }
            } else {
                if (!this.swiperEnabled) {
                    this.enableSwiper();
                }
                this.hideHeroImage();
                this.setContainerWidth();
                this.swiper.setContainerPosition();
            }
        }
    }

    /**
     * @method unsetContainerPosition
     * @description remove positioning transform from gallery inner wrapper
     */
    unsetContainerPosition() {
        this.galleryInnerWrapper.style.transform = '';
    }

    /**
     * @method pauseYouTubeVideo
     * @description Pause YouTube video playback
     */
    pauseYouTubeVideo() {
        const { activeYouTubeVideoId } = this.getState();
        window.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.PAUSE_YOUTUBE_VIDEO, {
                    detail: {
                        videoId: activeYouTubeVideoId
                    }
                }
            )
        );
        this.updateState({
            activeYouTubeVideoId: null
        });
    }

    /**
     * @method showHeroImage
     * @description For XL+, show the hero image content
     */
    showHeroImage() {
        const { currentTileIndex, nextTileIndex } = this.getState();
        if (currentTileIndex !== nextTileIndex) {
            this.hideHeroImage();
        }
        this.heroImageContainerElms[nextTileIndex].classList.add(CLASSES.SLIDESHOW_GALLERY_HERO_IMAGE_ACTIVE);
    }

    /**
     * @method hideHeroImage
     * @description For XL+, hide the hero image content
     */
    hideHeroImage() {
        const { currentTileIndex } = this.getState();
        this.heroImageContainerElms[currentTileIndex].classList.remove(CLASSES.SLIDESHOW_GALLERY_HERO_IMAGE_ACTIVE);
    }

    /**
     * @method onProgressBarTransitionEnd
     * @description Timeline progress bar transitionend event listener
     * @param e {Event} transitionend event object
     */
    onProgressBarTransitionEnd(e) {
        e.stopPropagation();
        const tile = e.target.closest(`.${CLASSES.SLIDESHOW_GALLERY_TILE}`);
        if (e.propertyName === CONSTANTS.WIDTH &&
            tile.classList.contains(CLASSES.ACTIVE)) {
            this.goToNextTile();
        }
    }

    /**
     * @method goToNextTile
     * @description Advance carousel to next tile
     */
    goToNextTile() {
        const { currentTileIndex } = this.getState();
        const nextTileIndex = (currentTileIndex < this.galleryTiles.length - 1) ? currentTileIndex + 1 : 0;
        if (this.swiperEnabled) {
            this.swiper.goToTile(nextTileIndex);
        } else {
            this.updateState({
                nextTileIndex
            });
            this.setActiveTile();
        }
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {Object} object of new values for state
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return { ...this.state };
    }

    /**
     * @method destroy
     * @description Destroys the module by detaching its events
     */
    destroy() {
        this.detachEvents();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

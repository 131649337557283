/**
 * @name GLOBAL_DISCLAIMER_CODES
 * @description Global disclaimer codes
 * @type {{
 *     MSRP: string,
 *     ACCELERATION: string,
 *     GAS_STARTING_AT: string,
 *     MPG: string,
 *     TOWING_CAPACITY: string,
 *     FINANCIAL_DISCLOSURE: string,
 *     ESTIMATED_MONTHLY_PAYMENT: string,
 * }}
 */
export default {
    MSRP: 'MSRP',
    ACCELERATION: 'ACCELERATION',
    GAS_STARTING_AT: 'GAS_STARTING_AT',
    MPG: 'MPG',
    TOWING_CAPACITY: 'TOWING_CAPACITY',
    FINANCIAL_DISCLOSURE: 'FINANCIAL_DISCLOSURE',
    OPTION_MONTHLY_PRICE: 'OPTION_MONTHLY_PRICE',
    VEHICLE_CLASS_FEE: 'VEHICLE_CLASS_FEE'
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

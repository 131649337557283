/**
 * An api for creating Carousel components from specially tagged DOM elements
 *
 * Example markup for creating a carousel from html tags:
 *
 * <div data-carousel="[type]" (bottom || overlay|| sliding)
 *      data-infinite="[boolean]"
 *      data-label-previous="[string]"
 *      data-label-next="[string]"
 *      data-theme="[string]">
 *
 *     <div data-carousel-slides>
 *         <div>slide 1</div>
 *         <div>slide 2</div>
 *         <div>slide 3</div>
 *         ...
 *     </div>
 * </div>
 */
// Module dependencies
import { renderer } from 'utils';
// Local dependencies
import Carousel from './../views/Carousel';

/**
 * @const ATTRIBUTES
 * @description Collection of data attributes for querying
 * @type {{CAROUSEL: string, SLIDES: string}}
 */
const ATTRIBUTES = {
    CAROUSEL: 'data-carousel',
    SLIDES: 'data-carousel-slides',
    CQ: 'CQ'
};

const CLASSES = {
    NEWSECTION: 'newpar new section'
};


/**
 * @method getCarouselElements
 * @description
 * Retrieves a collection of the carousel elements from a DOM element
 * @returns {NodeList}
 */
function getCarouselElements(element) {
    return element.querySelectorAll('[data-carousel]');
}

/**
 * @method filterSlides
 * @description filter slides to remove extra CQ and section
 * @returns Array
*/
function filterSlides(slides) {
    const filteredSlides = [];
    slides.forEach((slide) => {
        if (slide.className !== CLASSES.NEWSECTION
         && slide.tagName !== ATTRIBUTES.CQ) {
            filteredSlides.push(slide);
        }
    });
    return filteredSlides;
}


/**
 * @method renderCarousel
 * @description Constructs a carousel configuration based on an elements data attributes,
 * and a collection of carousel elements to create a Carousel object from
 * @param carouselElement {Element} DOM element that represents a carousel
 * @return {Carousel} An instance of a Carousel
 */
function renderCarousel(carouselElement) {
    const carouselSlides = carouselElement.querySelector(`[${ATTRIBUTES.SLIDES}]`);
    const carouselData = carouselElement.dataset;
    const carouselItems = carouselSlides.children ?
     filterSlides([...carouselSlides.children]) : null;
    const config = {};
    config.type = carouselData.carousel;
    config.infinite = carouselData.infinite === 'true';
    config.indicators = carouselData.indicators === 'true';
    config.labels = {
        next: carouselData.labelNext,
        prev: carouselData.labelPrevious
    };
    config.theme = carouselData.theme;
    config.analyticsKey = carouselData.carouselAnalyticsContainerKey;
    const carousel = new Carousel(carouselItems, config);

    renderer.insert(carousel.render(), carouselElement);

    return carousel;
}

/**
 * @method createCarousel
 * @description Parses a DOM element for carousel tags and renders a carousel for each instance
 */
export function createCarousel(element) {
    const carouselElements = getCarouselElements(element);
    const carousels = [];

    for (let i = 0; i < carouselElements.length; i += 1) {
        carousels.push(
            renderCarousel(carouselElements[i])
        );
    }

    return carousels;
}

/**
 * export public api
 */
export default {
    createCarousel
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

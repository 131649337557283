// Constant dependencies
import { CUSTOM_EVENTS } from 'Constants';

import {
    renderer,
    customEventDispatcher,
    generateUniqueID
} from 'utils';

import { Observer } from 'partials/observer';
import { DealerSearchBar } from 'partials/dealer-search-bar';
import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { googleLocationsApi } from 'partials/google-maps';
import { DealerLocationPrioritization } from 'partials/dealer-location-prioritization';

import config from './config';
import content from './config/content';
import DealerResults from './views/DealerResults';
import ChosenDealer from './views/ChosenDealer';
import DealerLocatorModel from './models/DealerLocatorModel';

const IDS = {
    DEALER_SEARCH_BAR: 'choose-a-dealer-plugin-container'
};

const CLASSES = {
    CHOSEN_DEALER_SEARCH_BAR: 'choose-a-dealer-plugin--search-bar',
    CHOSEN_DEALER_RESULTS_VIEW: 'choose-a-dealer-plugin--results-view',
    CHOSEN_DEALER_DEALER_VIEW: 'choose-a-dealer-plugin--dealer-view'
};

/**
 * App class which is responsible for choose-a-dealer plugin
 */
export default class App extends Observer {
    /**
     * @static BUTTON_STYLES
     * @description Available button styles for the dealer search button
     * e.g. EXTERIOR || INTERIOR
     * @type {{LINK: string, PRIMARY: string, SECONDARY: string}}
     */
    static BUTTON_STYLES = {
        LINK: 'link',
        PRIMARY: 'primary',
        SECONDARY: 'secondary'
    };

    /**
     * @constructor
     * @description Sets up the default values and calls initialization methods
     * @param settings {Object} Allows overwrite config parameters
     */
    constructor(settings = {}) {
        super();

        this.domElement = document.getElementById(IDS.DEALER_SEARCH_BAR);
        this.config = { ...config, ...settings };
        this.content = { ...content, ...settings.content };

        this.id = generateUniqueID();
        this.searchBar = null;
        this.chosenDealerView = null;
        this.coupledInstance = false;
        this.valid = true;

        this.onDealerSelectCallback = this.onDealerSelectCallback.bind(this);
        this.onChosenDealerValidate = this.onChosenDealerValidate.bind(this);
        this.setSearchFromLocation = this.setSearchFromLocation.bind(this);
        this.createView = this.createView.bind(this);
        this.getInstance = this.getInstance.bind(this);

        this.initialize();
    }

    /**
     * @method initialize
     * @description initialize the app
     */
    async initialize() {
        this.dealerLocationPrioritization = await DealerLocationPrioritization.getInstance();
        this.setupModel();
        this.attachEvents();
        this.dispatchInited();
        this.initView();
    }

    /**
     * @method setupModel
     * @description Sets up the Dealer locator model
     */
    setupModel() {
        const chosenDealer = this.dealerLocationPrioritization.get('CHOOSE_A_DEALER_PLUGIN');
        this.model = new DealerLocatorModel(
            Object.assign(
                this.getLastLocation(),
                {
                    chosenDealer: chosenDealer.data
                }
            )
        );
    }

    /**
     * @method getLastLocation
     * @description Returns the last searched dealer
     */
    getLastLocation() {
        const lastLocation = this.dealerLocationPrioritization.get('CHOOSE_A_DEALER_PLUGIN', 'CHANGE_DEALER');
        if (lastLocation) {
            return {
                searchLocation: lastLocation.data,
                searchByType: lastLocation.type
            };
        }
        return {};
    }

    /**
     * @method attacheEvents
     * @description Attaches events like model listeners
     */
    attachEvents() {
        this.model.attach(this);

        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.CHOSEN_DEALER_VALIDATE,
            this.onChosenDealerValidate
        );

        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.CHOSEN_DEALER_GET_INSTANCE,
            this.getInstance
        );
    }

    /**
     * @method detachEvents
     * @description Detaches events like model listeners
     */
    detachEvents() {
        this.model.detach(this);

        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.CHOSEN_DEALER_VALIDATE,
            this.onChosenDealerValidate
        );

        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.CHOSEN_DEALER_GET_INSTANCE,
            this.getInstance
        );
    }

    /**
     * @method onUpdate
     * @description Callback method when model is updated
     */
    onUpdate(prevState, nextState) {
        // This state is valid when the user searches for a location.
        // The search bar is destroyed, and a new instance of results is created
        if (prevState.searchLocation !== nextState.searchLocation &&
            nextState.searchLocation) {
            if (this.searchBar) {
                this.searchBar.destroy();
            }

            this.createResultsView();
        }

        if (prevState.currentStep !== nextState.currentStep) {
            switch (nextState.currentStep) {
            // This state is valid when the user clicks on "Change Location" to change search
            // The results view is destroyed, and a new instance of search bar is created
            case 1:
                if (this.resultsView) {
                    this.resultsView.destroy();
                }

                if (this.chosenDealerView) {
                    this.destroyChosenDealerView();
                }

                this.createSearchBar(false);
                break;
            // This state is valid when the user clicks on "Change Dealer" to change dealer
            // The chosen dealer view is destroyed, and a new instance of results is created
            case 2:
                if (this.chosenDealerView) {
                    this.destroyChosenDealerView();
                }

                this.createResultsView();
                break;
            default:
            }
        }

        // This state is valid when the user clicks on a dealer from the dealer results list
        // to select as chosen dealer. A new instance of chosen dealer view is created
        if (nextState.chosenDealer && prevState.chosenDealer !== nextState.chosenDealer) {
            this.createChosenDealerView(nextState.chosenDealer);
            this.dispatchChosenDealerUpdated(nextState.chosenDealer);
        }
    }

    /**
     * @method initView
     * @description Initializes the corresponding view based on state of model
     * Gets the city/region based on searchLocation and searchByType.
     * If the model has chosenDealer the final view is loaded. If not the search bar view is
     * loaded.
     */
    initView() {
        const { chosenDealer, searchLocation, searchByType } = this.model.state;
        const dealer = chosenDealer;

        this.setSearchFromLocation(searchLocation, searchByType)
            .then(this.createView.bind(this, dealer))
            .catch((e) => console.error(`Error init of App, ${e}`));
    }

    /**
     * @method setSearchFromLocation
     * @description Sets searchString based on searchLocation and searchType
     * @param {String} searchLocation - Value of searchLocation, potentially from model
     * @param {String} searchByType - Value of searchByType, potentially from model
     * @returns {Promise} Promise which resolves when searchString is set with
     */
    setSearchFromLocation(searchLocation, searchByType) {
        return Promise.resolve()
            .then(() => {
                // If searchLocation is available and searchByType is place, use google reverse
                // lookup to get location, and return formatted city, region
                if (searchLocation && searchByType === 'place') {
                    return googleLocationsApi.getCityStateForPlace(searchLocation)
                            .then((location) => `${location.city}, ${location.region}`);
                }

                // Any other cases, i.e. if searchLocation is not available or if searchByType is
                // zip, use searchLocation
                return searchLocation;
            })
            .then(this.model.setSearchString.bind(this.model))
            .catch((e) => console.error(`Error setting searchString, ${e}`));
    }

    /**
     * @method createView
     * @description Creates the appropriate view based on value of chosenDealer
     * @param {Object} dealer - The chosenDealer object
     */
    createView(dealer) {
        if (this.config.presetDealerId) {
            this.createPresetDealerView();
        } else if (dealer) {
            this.createChosenDealerView(dealer);
            this.dispatchChosenDealerUpdated(dealer);
        } else {
            this.createSearchBar();
        }
    }

    /**
     * @method createSearchBar
     * @description Creates the Search Bar instance and renders it to DOM element
     */
    createSearchBar(autoSubmitLocation = true) {
        this.model.setCurrentStep(1);
        this.searchBar = new DealerSearchBar({
            autoSubmitLocation,
            buttonStyle: this.getButtonStyle(this.config.dealerSearchBar.buttonStyle),
            country: this.config.country,
            ctaLabel: this.content.find,
            dealerSearchErrorMessage: this.content.dealerSearchErrorMessage,
            defaultLocation: {
                searchLocation: this.model.state.searchLocation,
                searchByType: this.model.state.searchByType,
                searchType: this.model.state.searchType,
            },
            onSubmit: this.setSearch.bind(this),
            searchInputLabel: this.content.searchInputLabel,
            sectionHeading: this.content.dealerSearchHeading,
            theme: DealerSearchBar.THEMES.CHOOSE_A_DEALER
        });

        renderer.insert(this.searchBar.render(), this.domElement);
        this.domElement.classList.add(CLASSES.CHOSEN_DEALER_SEARCH_BAR);
        this.domElement.classList.remove(CLASSES.CHOSEN_DEALER_RESULTS_VIEW);
        this.domElement.classList.remove(CLASSES.CHOSEN_DEALER_DEALER_VIEW);
    }

    /**
     * @method createResultsView
     * @description Creates Results view
     */
    createResultsView() {
        this.model.setCurrentStep(2);
        this.resultsView = new DealerResults({
            model: this.model,
            country: this.config.country,
            language: this.config.language,
            onDealerSelectCallback: this.onDealerSelectCallback,
            content: this.content
        });

        renderer.insert(this.resultsView.render(), this.domElement);
        this.domElement.classList.remove(CLASSES.CHOSEN_DEALER_SEARCH_BAR);
        this.domElement.classList.add(CLASSES.CHOSEN_DEALER_RESULTS_VIEW);
        this.domElement.classList.remove(CLASSES.CHOSEN_DEALER_DEALER_VIEW);
    }

    /**
     * @method createChosenDealerView
     * @description Creates the ChosenDealer instance and renders it to DOM element
     * @param chosenDealer {Object} Dealer object to display
     * @param disableChange {Boolean} Indicator to disable changing the dealer
     */
    createChosenDealerView(chosenDealer, disableChange) {
        this.model.setCurrentStep(3);
        this.chosenDealerView = new ChosenDealer({
            model: this.model,
            chosenDealer,
            disableChange,
            showMap: this.config.showMap,
            showInventory: this.config.showInventory,
            content: this.content
        });

        renderer.insert(this.chosenDealerView.render(), this.domElement);
        this.domElement.classList.remove(CLASSES.CHOSEN_DEALER_SEARCH_BAR);
        this.domElement.classList.remove(CLASSES.CHOSEN_DEALER_RESULTS_VIEW);
        this.domElement.classList.add(CLASSES.CHOSEN_DEALER_DEALER_VIEW);
    }

    /**
     * @method createPresetDealerView
     * @description Fetches the preset dealer by id, and on success creates
     * a chosen dealer view and dispatches the chosen dealer update event
     */
    createPresetDealerView() {
        dealerLocatorApi.getDealer({
            country: this.config.country,
            id: this.config.presetDealerId,
            language: this.config.language,
            type: 'dealers'
        }).then((dealer) => {
            this.createChosenDealerView(dealer, !this.config.presetDealerEnableChange);
            this.dispatchChosenDealerUpdated(dealer);
        }).catch(() => {
            this.createSearchBar();
        });
    }

    /**
     * @method setSearch
     * @description Sets the selected search location
     * @param location {String} Location postal code
     */
    setSearch(location) {
        const searchLocation = location.searchLocation;
        // Explicitly set model to null, so that if/when search location hasn't changed when user
        // click Find CTA, there is a change in model to cause the `onUpdate` to be called
        this.model.setSearchLocation(null);

        this.model.setSearchByType(location.searchByType);
        this.model.setSearchString(location.searchString);
        // Set model to value required.
        this.model.setSearchLocation(searchLocation);
    }

    /**
     * @method dispatchChosenDealerUpdated
     * @description dispatches custom event when chosen dealer has been set or unset
     * @param chosenDealer {Object} Dealer
     */
    dispatchChosenDealerUpdated(chosenDealer) {
        const { searchString, searchByType } = this.model.state;

        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.CHOSEN_DEALER_UPDATED,
                {
                    detail: {
                        address: chosenDealer?.address ?? '',
                        city: chosenDealer?.city ?? '',
                        phone: chosenDealer?.phone ?? '',
                        chosenDealerName: chosenDealer?.name ?? '',
                        dealerId: chosenDealer?.dealerId ?? '',
                        searchByType,
                        searchString,
                        state: chosenDealer?.state ?? '',
                        zip: chosenDealer?.zip ?? ''
                    }
                }
            ),
        );
    }

    /**
     * @method onDealerSelectCallback
     * @description Callback method when dealer is selected
     */
    onDealerSelectCallback(dealer) {
        const {
            LAST_SELECTED_DEALER,
        } = DealerLocationPrioritization.DATA_TYPES;

        if (this.config.onDealerSelectValidate) {
            this.config.onDealerSelectValidate(dealer.dealerId, dealer.country)
                .then((isValid) => {
                    if (isValid) {
                        this.dealerLocationPrioritization.set(LAST_SELECTED_DEALER, dealer);
                        this.model.setChosenDealer(dealer);
                    } else {
                        this.resultsView.showErrorMessageOnDealer(dealer.dealerId);
                    }
                });
        } else {
            this.dealerLocationPrioritization.set(LAST_SELECTED_DEALER, dealer);
            this.model.setChosenDealer(dealer);
        }
    }

    /**
     * @method destroyChosenDealerView
     * @description Destroy the chosen dealer view, emit event to update dealer as unset
     * and set model preferredDealer as null
     */
    destroyChosenDealerView() {
        this.chosenDealerView.destroy();
        this.dispatchChosenDealerUpdated(null);
        this.model.setChosenDealer(null);
    }

    /**
     * @method destroy
     * @description Destroy views
     */
    destroy() {
        this.detachEvents();

        if (this.searchBar) {
            this.searchBar.destroy();
        }

        if (this.resultsView) {
            this.resultsView.destroy();
        }

        if (this.chosenDealerView) {
            this.destroyChosenDealerView();
        }
    }

    /**
     * @method onChosenDealerValidate
     * @description Event listener for custom event
     */
    onChosenDealerValidate() {
        this.validate();
    }

    /**
     * @method validate
     * @description If the preferredDealer is not set show an error message
     */
    validate() {
        const { preferredDealer, lastSelectedDealer, currentStep } = this.model.state;
        let valid = true;

        if (!preferredDealer && !lastSelectedDealer) {
            valid = false;
            if (currentStep === 1) {
                this.searchBar.showError(
                    this.content.emptyDealerErrorMessage,
                    false
                );
            } else {
                this.resultsView.showErrorMessage();
            }
        }
        this.valid = valid;
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if preferred dealer is selected
     */
    isValid() {
        return this.valid;
    }

    /**
     * @method set focus
     * @description gives DOM element focus
     */
    set focus(focus = true) {
        const { currentStep } = this.model.state;
        if (focus) {
            if (currentStep === 1 && this.searchBar) {
                this.searchBar.focus = true;
            } else if (currentStep === 2 && this.resultsView) {
                this.resultsView.focus = true;
            }
        }
    }

    /**
     * @method getButtonStyle
     * @description Return the style of the dealer search button.
     * @param type {String} The type of the button;
     */
    getButtonStyle(type) {
        if (type === App.BUTTON_STYLES.SECONDARY) {
            return 'button button_secondary_alt';
        } else if (type === App.BUTTON_STYLES.LINK) {
            return 'link link_primary';
        }

        return 'button button_primary';
    }

    /**
     * @method dispatchInited
     * @description dispatched custom event when plugin is initialized
     */
    dispatchInited() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.CHOSEN_DEALER_INITED,
                {
                    detail: {
                        instance: this
                    }
                }
            ),
        );
    }

    /**
     * @method getInstance
     * @description Method for getting instance of ChooseADealer Class
     */
    getInstance() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.CHOSEN_DEALER_SET_INSTANCE,
                {
                    detail: {
                        instance: this
                    }
                }
            ),
        );
    }

    /**
     * @method setInstanceMode
     * @description sets instance mode for plugin
     */
    setInstanceMode(coupledInstance) {
        this.coupledInstance = coupledInstance;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partials
import { Modal } from 'partials/modal';
import { Carousel } from 'partials/carousel';

// dependencies
import ModalCarouselItem from './ModalCarouselItem';

// Default Modal Configuration
const modalDefaultConfig = {
    theme: Modal.THEMES.DEFAULT,
    themeModifier: null,
    sizeSmall: Modal.SIZES.FULLSCREEN,
    sizeLarge: Modal.SIZES.INSET,
    dataAnalyticContainer: "{'blade': 'options modal'}"
};

// Default carousel Configuration
const carouselDefaultConfig = {
    infinite: true,
    startIndex: 0
};

/**
 * @const ATTRIBUTES
 * @description MODAL_CONTAINER: Attribute of the Modal content container DOM element
 * @type {{MODAL_CONTAINER: string}}
 */
const ATTRIBUTES = {
    MODAL_CONTAINER: 'data-modal-content'
};

/**
 * @const defaultLocalization
 * @description  localization object for i18n content
 * @type {Object}
 */
const defaultLocalization = {
    closeDisclaimer: 'Close Disclaimer',
    viewDisclaimer: 'View Disclaimer'
};

/**
 * @const localization
 * @description constant for the localization content created
 * in src/main/content/jcr_root/apps/mb-vans/pages/base/partials/footlibs.html
 */
const localization = window.mbVans.ns('pageData').localization;

/**
 * @class  ModalCarousel
 * @description  Display a modal that contains the ModalCarouselItem View
 * @param {Object} content
 * @param {Object} config
 */
export default class ModalCarousel {
    static SIZES = {
        ...Modal.SIZES
    };

    /**
     * @method constructor
     * @description  setup the initial state for the view
     */
    constructor(optionsData, config = {}) {
        this.modalConfig = {
            ...modalDefaultConfig,
            ...config.modal
        };
        this.carouselConfig = {
            ...carouselDefaultConfig,
            ...config.carousel
        };
        this.optionsData = optionsData;
        this.content = {
            ...defaultLocalization,
            ...localization,
            ...window.mbVans.ns('pageData').disclaimerLocalization
        };
        this.modal = null;
        this.carousel = null;
        this.modalCarouselItems = [];
        this.onSlideCallback = this.onSlideCallback.bind(this);
        this.beforeOpen = this.beforeOpen.bind(this);
        this.afterOpen = this.afterOpen.bind(this);
        this.beforeClose = this.beforeClose.bind(this);
        this.scrollToElement = this.scrollToElement.bind(this);

        this.init();
    }

    /**
     * @method  init
     * @description initilize the class
     */
    init() {
        this.createModal();
    }

    /**
     * @method createModal
     * @description Creates a Modal instance and applies its properties
     */
    createModal() {
        this.modal = new Modal(undefined, {
            modalContent: undefined,
            callbacks: {
                beforeOpen: this.beforeOpen,
                afterOpen: this.afterOpen,
                beforeClose: this.beforeClose
            },
            ...this.modalConfig
        });
    }

    /**
     * @method createCarousel
     * @description Creates a Carousel instance and applies its properties
     */
    createCarousel() {
        const carouselItems = this.optionsData.map((option) => {
            const optionDetail =
                new ModalCarouselItem(option, this.content, { onDisclaimerOpen: this.scrollToElement });
            this.modalCarouselItems.push(optionDetail);
            return optionDetail.render();
        });

        this.carouselConfig.onSlideCallback = this.onSlideCallback;
        this.carousel = new Carousel(carouselItems, this.carouselConfig);
    }

    /**
     * @method destroyCarousel
     * @description Destroys the carousel and its modalCarouselItems instances
     */
    destroyCarousel() {
        this.modalCarouselItems.forEach((item) => {
            item.destroy();
        });
        this.modalCarouselItems = [];
        this.carousel.destroy();
    }

    /**
     * @method onSlideCallback
     * @description callback method when modal carousel slide is updated
     * @param activeIndex {int} Index of active slide
     * @param prevIndex {int} Index of previously active slide
     */
    onSlideCallback(activeIndex, prevIndex) {
        this.modalCarouselItems[activeIndex].enable();
        this.modalCarouselItems[prevIndex].disable();
    }

    /**
     * @method beforeOpen
     * @description Callback to create the carousel when the modal opens
     */
    beforeOpen() {
        this.createCarousel();
    }

    /**
     * @method afterOpen
     * @description Callback to render the children to the Modal DOM container
     * then calls the afterOpen prop callback
     */
    afterOpen(modalNode) {
        if (this.getModalContainer()) {
            this.getModalContainer().appendChild(this.carousel.render());
        }

        this.modalCarouselItems[this.carouselConfig.startIndex].afterModalOpen();

        if (this.carouselConfig.onAfterOpen) {
            this.carouselConfig.onAfterOpen(modalNode);
        }
    }

    /**
     * @method beforeClose
     * @description Callback to call the beforeClose prop callback
     * then removes the children to the Modal DOM container and destroys the carousel
     */
    beforeClose() {
        if (this.carouselConfig.onBeforeClose) {
            this.carouselConfig.onBeforeClose();
        }
        if (this.getModalContainer()) {
            this.getModalContainer().removeChild(this.carousel.render());
            this.destroyCarousel();
        }
    }

    /**
     * @method scrollToElement
     * @description Callback to scroll the modal to a specific element
     * @param element
     */
    scrollToElement(element) {
        this.modal.scrollToElement(element);
    }

    /**
     * @method getModalContainer
     * @description Retrieves the modal container DOM node
     * @return {Element}
     */
    getModalContainer() {
        return document.querySelector(`[${ATTRIBUTES.MODAL_CONTAINER}]`);
    }

    /**
     * @method open
     * @description Calls a method to open the modal instance
     * @param callingContainer {Object} Element that triggers the form, pass to the modal for accessibility
     * with following format:
     * { callingContainer: object, callingContainerId: string }
     */
    open(callingContainer) {
        this.modal.open(callingContainer);
    }

    /**
     * @method close
     * @description Calls a method to close the modal instance
     * @param skipTransition {Boolean} skip close transition
     */
    close(skipTransition = false) {
        this.modal.close(skipTransition);
    }

    /**
     * @method destroyModal
     * @description Destroys the modal instance and unmounts the modalContent
     */
    destroy() {
        this.modal.destroy();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

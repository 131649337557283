import { htmlNode } from 'utils';

import youTubeModalItemTemplate from 'partials/youtube/templates/youTubeModalItemTemplate';

import ModalCarouselItemDisclaimerTemplate from './ModalCarouselItemDisclaimerTemplate';
import ModalCarouselImageItemTemplate from './ModalCarouselImageItemTemplate';
import ModalCarouselHtml5VideoItemTemplate from './ModalCarouselHtml5VideoItemTemplate';

const { availableIn } = window.mbVans.ns('pageData').localization;
const country = window.mbVans.ns('pageData').country;
const language = window.mbVans.ns('pageData').language;

/**
 * Template for a ModalCarouselItem view.
 * Uses ES6 template literal to composite the markup for a ModalCarouselItem view
 */
export default ({ title, description, imageUrl, imageAlt, standardText, price, disclaimers, packageAvailable, videoId, videoTranscript, html5video, link, disclaimerCta, disclaimerCopy, ctaClass, descriptionContainer }) => htmlNode `
    <div class="modal-carousel">
        <div class="modal-carousel__image">
            ${html5video ? ModalCarouselHtml5VideoItemTemplate(html5video, imageUrl) : ''}
            ${videoId && !html5video ? youTubeModalItemTemplate({
                videoId,
                imageUrl,
                videoTranscript
            })({ getNode: false }) : ''}
            ${!html5video && !videoId ? ModalCarouselImageItemTemplate(imageUrl, imageAlt) : ''}
        </div>
        <div class="modal-carousel__details">
            ${descriptionContainer ? '<div class="modal-carousel__details-wrapper">' : ''}
            ${standardText ? `<div class="modal-carousel__standard">${standardText}</div>` : ''}
            ${title ? `<div class="modal-carousel__title">${title}</div>` : ''}
            ${price ? `<div class="modal-carousel__price">${price}</div>` : ''}
            ${description ? `<div class="modal-carousel__desc rte">${description}</div>` : ''}
            ${packageAvailable ? `<p class="modal-carousel__package">${availableIn}${(country === 'ca' && language === 'fr') ? '' : ' '}<strong>${packageAvailable}</strong></p>` : ''}
            ${link ? `<div class="modal-carousel__link ${ctaClass}">${link}</div>` : ''}
            ${disclaimers ? ModalCarouselItemDisclaimerTemplate(disclaimers, disclaimerCta, disclaimerCopy) : ''}
            ${descriptionContainer ? '</div>' : ''}
        </div>
    </div>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { noop } from 'utils';

// Partial dependencies
import { LocationSearch } from 'partials/location-search';

// Local dependencies
import dealerLocatorTemplate from './../templates/dealerLocatorSearchBarTemplate';
import preferredDealerPluginTemplate from './../templates/preferredDealerPluginTemplate';

/**
 * @constant ATTRIBUTES
 * @description Attribute references for the DealerSearchBar view
 * @type {{LOCATION_SEARCH: string, BROWSE_BUTTON: string}}
 */
const ATTRIBUTES = {
    LOCATION_SEARCH: 'data-location-search',
    CTA_BUTTON: 'data-cta-button'
};

/**
 * View responsible for rendering the dealer search bar and managing
 * the view state and user interaction
 */
export default class DealerSearchBar {
    /**
     * @static THEMES
     * @description Optional theme configuration types. These will be used to get the corresponding
     * template
     * @type {{DEALER_SEARCH: string, PREFERRED_DEALER: string}}
     */
    static THEMES = {
        DEALER_SEARCH: 'dealerSearch',
        PREFERRED_DEALER: 'preferredDealerPlugin'
    };

    /**
     * @constructor
     * @param onSubmit {Function} Callback to apply when a location is submitted
     * @param [onCTAClick] {Function} Optional callback which is executed when the CTA in template
     * is clicked. This callback is executed only for dealer locator.
     */
    constructor({
        analyticsTrigger = 'cta',
        autoSubmitLocation = true,
        buttonStyle = 'button button_secondary_alt',
        country,
        ctaLabel = '',
        dealerSearchErrorMessage = '',
        defaultLocation,
        onCTAClick = noop,
        onSubmit = noop,
        isCTASubmit = true, // Whether the CTA next to text input works as Submit button
        searchInputLabel = '',
        sectionHeading = '',
        theme = DealerSearchBar.THEMES.DEALER_SEARCH,
        onErrorMessage = noop
    } = {}) {
        this.element = (theme === DealerSearchBar.THEMES.PREFERRED_DEALER ?
            preferredDealerPluginTemplate : dealerLocatorTemplate)({
                ctaLabel,
                buttonStyle,
                sectionHeading,
                analyticsTrigger,
            })({ getNode: true });

        this.searchInputLabel = searchInputLabel;
        this.dealerSearchErrorMessage = dealerSearchErrorMessage;
        this.locationSearch = null;
        this.locationSearchElem = null;
        this.ctaButtonElm = null;
        this.onCTAClickCallback = onCTAClick;
        this.onSubmitCallback = onSubmit;
        this.isCTASubmit = isCTASubmit;
        this.country = country;
        this.defaultLocation = defaultLocation;
        this.theme = theme;
        this.autoSubmitLocation = autoSubmitLocation;
        this.onErrorMessageCallback = onErrorMessage;

        this.ctaClick = this.ctaClick.bind(this);
        this.submitLocation = this.submitLocation.bind(this);

        this.cacheDOM();
        this.attachEvents();
        this.createLocationSearch();
    }

    /**
     * @method cacheDOM
     * @description Caches reference to DOM elements from the view
     */
    cacheDOM() {
        this.locationSearchElem = this.element.querySelector(`[${ATTRIBUTES.LOCATION_SEARCH}]`);
        this.ctaButtonElm = this.element.querySelector(`[${ATTRIBUTES.CTA_BUTTON}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    attachEvents() {
        if (!this.isCTASubmit) {
            this.ctaButtonElm.addEventListener(EVENTS.CLICK, this.ctaClick);
        }
    }

    /**
     * @method createLocationSearch
     * @description Creates an instance of a LocationSearch and
     * renders it to the locationSearchElem element
     */
    createLocationSearch() {
        this.locationSearch = new LocationSearch({
            searchLength: 3,
            searchInputLabel: this.searchInputLabel,
            country: this.country,
            defaultLocation: this.defaultLocation,
            errorMessage: this.dealerSearchErrorMessage,
            onSubmit: this.submitLocation,
            onSubmitBtn: (this.isCTASubmit ? this.ctaButtonElm : null),
            autoSubmitLocation: this.autoSubmitLocation,
            onErrorMessageCallback: this.onErrorMessageCallback
        });

        this.locationSearchElem.appendChild(this.locationSearch.render());
    }

    /**
     * @method submitLocation
     * @description When a location is submitted, calls the `onSubmitCallback`
     * method with the submitted locations postal code
     * TODO: implement submit logic in future story to autoselect a location
     * if the location type is a string and query a zip code from a "place" location
     * @param location {Object} Location object from LocationSearch
     */
    submitLocation(location) {
        this.onSubmitCallback({
            searchString: location.value,
            searchLocation: location.type === 'place' ? location.id : location.value,
            searchByType: location.type
        });
    }

    /**
     * @method ctaClick
     * @description Executes the callback function for CTA
     */
    ctaClick(event) {
        event.preventDefault();
        this.onCTAClickCallback(this.locationSearch.searchInputElm.value);
    }

    /**
     * @method clearResultList
     * @description Clear result list
     */
    clearResultList() {
        this.locationSearch.removeLocationList();
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callbacks from the view
     */
    detachEvents() {
        if (!this.isCTASubmit) {
            this.ctaButtonElm.removeEventListener(EVENTS.CLICK, this.ctaClick);
        }
    }

    /**
     * @method destroy
     * @description Destroys the view by detaching its events and
     * removing the element from the DOM
     */
    destroy() {
        this.detachEvents();
        if (this.locationSearch) {
            this.locationSearch.destroy();
            this.locationSearch = null;
        }
        this.locationSearchElem = null;
        this.ctaButtonElm = null;
        this.element.remove();
    }

    /**
     * @method showError
     * @description Passes an error message to the LocationSearch to display
     * @param message {String} error message to display
     */
    showError(message) {
        this.locationSearch.showErrorMessage(message);
    }

    /**
     * @method render
     * @description Retrieves the DOM element from the view and returns it
     * @return {Element} DealerSearchBar element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

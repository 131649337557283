/**
 * Collection of error message types and associated application sections
 */
export default {
    ADDRESS_LINE: {
        id: 'ADDRESS_LINE',
        labelId: 'addressLine1',
        messageId: 'informationError'
    },
    ADDRESS_APARTMENT: {
        id: 'ADDRESS_APARTMENT',
        labelId: 'addressApartment',
        messageId: 'sectionError'
    },
    ADDRESS_APARTMENT_SUITE: {
        id: 'ADDRESS_APARTMENT_SUITE',
        labelId: 'addressApartmentSuite',
        messageId: 'sectionError'
    },
    ADDRESS_PO_BOX: {
        id: 'ADDRESS_PO_BOX',
        labelId: 'addressPOBox',
        messageId: 'sectionError'
    },
    ADDRESS_PO_BOX_STREET_ADDRESS: {
        id: 'ADDRESS_PO_BOX_STREET_ADDRESS',
        labelId: 'addressPOBoxStreetAddress',
        messageId: 'sectionError'
    },
    ADDRESS_RURAL_ROUTE_LENGTH: {
        id: 'ADDRESS_RURAL_ROUTE_LENGTH',
        labelId: 'addressRuralRouteLength',
        messageId: 'sectionError'
    },
    ADDRESS_RURAL_ROUTE_REQUIRED: {
        id: 'ADDRESS_RURAL_ROUTE_REQUIRED',
        labelId: 'addressRuralRouteRequired',
        messageId: 'informationError'
    },
    ADDRESS_STREET_NAME_EMPTY: {
        id: 'ADDRESS_STREET_NAME_EMPTY',
        labelId: 'addressStreetNameEmpty',
        messageId: 'informationError'
    },
    ADDRESS_STREET_NAME_LENGTH: {
        id: 'ADDRESS_STREET_NAME_LENGTH',
        labelId: 'addressStreetNameLength',
        messageId: 'sectionError'
    },
    ADDRESS_STREET_NUMBER_EMPTY: {
        id: 'ADDRESS_STREET_NUMBER_EMPTY',
        labelId: 'addressStreetNumberEmpty',
        messageId: 'informationError'
    },
    ADDRESS_STREET_NUMBER_LENGTH: {
        id: 'ADDRESS_STREET_NUMBER_LENGTH',
        labelId: 'addressStreetNumberLength',
        messageId: 'sectionError'
    },
    ADDRESS_SUITE: {
        id: 'ADDRESS_SUITE',
        labelId: 'addressSuite',
        messageId: 'sectionError'
    },
    HOME_PHONE: {
        id: 'HOME_PHONE',
        labelId: 'homePhone',
        messageId: 'informationError'
    },
    HOME_PHONE_AREA: {
        id: 'HOME_PHONE_AREA',
        labelId: 'homePhone',
        messageId: 'informationError'
    },
    BUSINESS_PHONE: {
        id: 'BUSINESS_PHONE',
        labelId: 'businessPhone',
        messageId: 'informationError'
    },
    BIRTHDAY: {
        id: 'BIRTHDAY',
        labelId: 'birthday',
        messageId: 'informationError'
    },
    SSN: {
        id: 'SSN',
        labelId: 'ssnError',
        messageId: 'informationError',
        message: 'Invalid SSN'
    },
    ZIP_CODE_STATE: {
        id: 'ZIP_CODE_STATE',
        labelId: 'zipCodeState',
        messageId: 'zipCodeState'
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a carousel slide.
 * Uses ES6 template literal to composite the markup for a carousel slide
 */
import { html } from 'utils';

/**
 * method will form li element and adds or skip data-analytics-container attribute
 * based on the passed argument
 * @param hasMoreThanOneSlide
 * @param analyticsContainerKey
 * @param analyticsContainerValue
 * @returns li element
 */

function generateTemplate(hasMoreThanOneSlide,
                            analyticsContainerKey, analyticsContainerValue) {
    if (hasMoreThanOneSlide && analyticsContainerKey) {
        return `
            <li data-carousel-slide tabindex="0" data-analytics-container="{'${analyticsContainerKey}': '${analyticsContainerValue}'}" class="carousel__slide">
                <div class="carousel__slide-overlay"></div>
            </li>`;
    }
    return `
        <li data-carousel-slide tabindex="${hasMoreThanOneSlide ? 0 : -1}" class="carousel__slide">
            <div class="carousel__slide-overlay"></div>
        </li>`;
}

export default (hasMoreThanOneSlide, analyticsContainerKey, analyticsContainerValue) => html`
    ${generateTemplate(hasMoreThanOneSlide, analyticsContainerKey, analyticsContainerValue)}
`.trim();
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const emailKeyboardConfig
 * @description a keyboard layout that is similar to the layout
 *              on an iPad (for email input)
 */
export const emailKeyboardConfig = {
    theme: 'hg-theme-default virtual-keyboard__simple-theme email-keyboard--dark-theme',
    layout: {
        default: [
            '@gmail.com @yahoo.com @outlook.com @hotmail.com @mail.com @aol.com',
            'q w e r t y u i o p {bksp}',
            'a s d f g h j k l {enter}',
            '{shift} z x c v b n m @ . {shift}',
            '{numbers} .com {space} _ - {numbers}'
        ],
        shift: [
            '@gmail.com @yahoo.com @outlook.com @hotmail.com @mail.com @aol.com',
            'Q W E R T Y U I O P {bksp}',
            'A S D F G H J K L {enter}',
            '{SHIFT} Z X C V B N M ! ? {SHIFT}',
            '{numbers} .com {space} _ - {numbers}'
        ],
        numbers: [
            '@gmail.com @yahoo.com @outlook.com @hotmail.com @mail.com @aol.com',
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            '@ # $ & * ( ) \' " {enter}',
            '{symbols} % - + = / ; : , . {symbols}',
            '{abc} .com {space} ! ? {abc}'
        ],
        /*
              * u20AC=€ (euro) u00A3=£ (pound)
              * u00A5=¥ (yen) u00A7=§ (section [for law])
              * u007B == {  u007D == } */
        symbols: [
            '@gmail.com @yahoo.com @outlook.com @hotmail.com @mail.com @aol.com',
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            '\u20AC \u00A3 \u00A5 _ ^ [ ] \u007B \u007D {enter}',
            '{numbers} \u00A7 | ~ ... \\ < > ! ? {numbers}',
            '{abc} .com {space} {abc}'
        ]
    },
    display: {
        '{bksp}': 'backspace',
        '{enter}': 'enter',
        '{shift}': 'shift',
        '{SHIFT}': 'SHIFT',
        '{space}': ' ',
        '{numbers}': '.?123',
        '{symbols}': '#+=',
        '{abc}': 'ABC'
    },
    availableLayouts: {
        DEFAULT: 'default',
        SHIFT: 'shift',
        NUMBERS: 'numbers',
        SYMBOLS: 'symbols'
    },
};

export default { emailKeyboardConfig };
// do not delete 9fbef606107a605d69c0edbcd8029e5d

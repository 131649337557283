// Module dependencies
import { tokenReplace } from 'utils';
import { AuthenticationApi } from 'partials/authentication';

// Local dependencies
import quickRegistrationLoginTemplate from './../templates/quickRegistrationLoginTemplate';

/**
 * @property CLASSES
 * @description  helper to get element from DOM
 * @type {Object}
 */
const CLASSES = {
    LOGIN_LINK: 'mbs-quick-registration__login-link'
};

/**
 * @const defaultConfig
 * @description Default configuration options
 */
const defaultConfig = {
    analyticsTriggerLogin: 'account-login'
};

/**
 * @class  QuickRegistrationLogin
 * @description  this class show a message with a login link on the QuickRegistrationForm view
 * @param {Object} content localized content
 */
export default class QuickRegistrationLogin {
    /**
     * @method  constructor
     * @description sets default values for inheritance
     * @param  {Object} content
     */
    constructor(content, config = defaultConfig) {
        this.content = content;
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.authAPI = new AuthenticationApi();
        const message = this.getLoginText();
        this.element = quickRegistrationLoginTemplate(message)({ getNode: true });
        this.loginLinkClickHandler = this.loginLinkClickHandler.bind(this);

        this.init();
    }

    /**
     * @method init
     * @description  method that initialize the view
     */
    init() {
        this.cacheDOM();
    }

    /**
     * @method getLoginText
     * @description  replace the existence text with the login link and return the message
     * @return {String} message with login link
     */
    getLoginText() {
        const regDescription = this.content.registeredDescription;
        const link = `<button
                         class='link link_plain-link mbs-quick-registration__login-link'
                         data-analytics-trigger="${this.config.analyticsTriggerLogin}">${this.content.loginHere}</button>`;
        return tokenReplace(regDescription, [link]);
    }

    /**
     * @method  cacheDOM
     * @description  caching the element from DOM
     */
    cacheDOM() {
        this.loginLink = this.element && this.element.querySelector(`.${CLASSES.LOGIN_LINK}`);
        if (this.loginLink) {
            this.attachEvents();
        }
    }

    /**
     * @method attachEvents
     * @description  adding events to specified elements
     */
    attachEvents() {
        this.loginLink.addEventListener('click', this.loginLinkClickHandler);
    }

    /**
     * @method detachEvents
     * @description remove events to specified elements
     */
    detachEvents() {
        this.loginLink.removeEventListener('click', this.loginLinkClickHandler);
    }

    /**
     * @method loginLinkClickHandler
     * @description Callback to apply when the login link is clicked to
     * prevent the default and apply the this.authAPI.authRedirect method to
     * log the user out
     * @param e {Event} Click event
     */
    loginLinkClickHandler(e) {
        e.preventDefault();
        this.config.onLoginCallback();
        this.authAPI.authRedirect(AuthenticationApi.AUTH_PAGES.LOGIN, this.config.redirectURL);
    }

    /**
     * @method  render
     * @description  return the html Node
     * @return {XML}
     */
    render() {
        return this.element;
    }

    /**
     * @method destroy
     * @description method in charge of remove the references from the view
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { html } from 'utils';
import { getClassNames } from './menuSelectorTemplate';
/**
 * @description Template for menu selector item of type button
 */
export default ({ items = [], buttonClasses, defaultSelection, group, theme, loadModule }) => {
    const isGallery = loadModule && loadModule === 'Gallery';

    return (`
        <ul id="menu-selector-list-${group}"
            class="${getClassNames(theme, '__item-list')}"
            ${isGallery ? 'role="tablist"' : ''}
            >
            ${items.map((item, index) => {
                const isDefaultSelection = index === defaultSelection;
                const ariaLabel = `aria-selected=${isDefaultSelection ? 'true' : 'false'}`;
                const analyticsTrigger = isGallery ? 'cta-gallery' : 'menu-selector-'`${item.value}`;

                return (html`
                    <li class="${getClassNames(theme, '__item')}
                        ${isDefaultSelection ? `${getClassNames(theme, '__item--selected')}` : ''}
                        data-value="${item.value}"
                        data-index=${index}
                        data-analytics-trigger="${analyticsTrigger}"
                        ${isGallery ? 'role="presentation"' : ''}
                    >
                        <button class="${buttonClasses}"
                            ${isGallery ? ariaLabel : ''}
                            ${isGallery ? 'role="tab"' : ''}
                        >
                            ${item.label}
                        </button>
                    </li>`
                );
            }).join('')}
        </ul>`
    );
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

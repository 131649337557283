/**
 * @description credit app submission results that are used to determine the UI on the
 * review page, the confirmation page, and the confirmation email
 * @type {{
 *  DECLINED: string
 *  NOT_SUBMITTED: string
 *  PENDING: string
 *  SUCCESSFUL: string,
 *  UNAVAILABLE: string,
 * }}
 */
export default {
    CONTACT: 'contact',
    NOT_SUBMITTED: 'notSubmitted',
    PENDING: 'pending',
    SUCCESSFUL: 'successful',
    UNAVAILABLE: 'unavailable',
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for reCAPTCHA V3 branding nte
 * Uses ES6 template literal to composite the markup for a reCAPTCHA
 */
import {
    htmlNode,
    tokenReplace
} from 'utils';

/**
 * @function linkElement
 * @description Constructs a link element
 * @param {String} label Value to display as the link text
 * @param {String} url Value to set as the href
 * @return {string}
 */
const linkElement = (label, url) => (
 `<a class='link link_plain-link'
     href='${url}' 
     target='_blank' 
     data-analytics-trigger='cta' 
     aria-label='${label}'>${label}</a>`
);

/**
 * @function
 * @description Constructs the recaptcha vs branding element with the privacy policy and terms of use links
 * @param {String} note Tokenized recaptcha branding note
 * @param {String} privacyPolicy Value to set as the Privacy Policy label
 * @param {String} privacyPolicyUrl Value to set as the Privacy Policy link url
 * @param {String} termsOfService Value to set as the Terms of Service label
 * @param {String} termsOfServiceUrl Value to set as the Terms of Service link url
 */
export default ({ note, privacyPolicy, privacyPolicyUrl, termsOfService, termsOfServiceUrl }) => htmlNode`
    <div class="recaptcha-v3-branding"
         data-qa-id="recaptcha-v3-branding">
        <p class="recaptcha-v3-branding__copy">
            ${tokenReplace(
                note,
                {
                    privacyPolicy: linkElement(privacyPolicy, privacyPolicyUrl),
                    termsOfUse: linkElement(termsOfService, termsOfServiceUrl)
                }
            )}
        </p>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

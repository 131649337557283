import { renderer } from 'utils';

import { supportTemplate } from './../templates';

export default class SupportClass {
    constructor(updateCallback) {
        this.updateCallback = updateCallback;
        this.testResults = {};
        this.template = supportTemplate()({ getNode: true });
        renderer.append(this.template, document.body);

        this.performBasicTest = this.performBasicTest.bind(this);
        this.handleOnFinish = this.handleOnFinish.bind(this);
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleFinished = this.handleFinished.bind(this);
        this.handleReady = this.handleReady.bind(this);

        this.startAnimation();
        const animateTest = this.template.querySelector('#test-animate');
        const animateTestAsArray = this.template.querySelector('#test-animate-kfa');
        const animateTestAsObject = this.template.querySelector('#test-animate-kfo');
        const animateTestSingle = this.template.querySelector('#test-animate-single');

        this.listenerCount = 0;

        if (animateTest.animate !== undefined) {
            this.performBasicTest(true, 'test-animate', 'Element.animate() support');
            const player = animateTestAsArray.animate([{
                opacity: 1
            }, {
                opacity: 0.5
            }, {
                opacity: 1
            }], {
                iterations: 1,
                duration: 100,
                id: 'ISetTheId'
            });
            const player3 = animateTestAsArray.animate([{
                opacity: 1
            }, {
                opacity: 0.5
            }, {
                opacity: 1
            }], {
                iterations: 1,
                duration: 100
            });
            if (player) {
                this.performBasicTest(true, 'test-animate-kfa', 'First parameter as array of keyframes like: [{opacity: 1},{opacity: 0} ]');
                this.listenerCount += 1;
                player.onfinish = this.handleOnFinish;
                if (player.finished && player.finished.then) {
                    this.listenerCount += 1;
                    player.finished.then(this.handleFinished);
                }
                if (player.ready && player.ready.then) {
                    this.listenerCount += 1;
                    player.ready.then(this.handleReady);
                }
            } else {
                this.performBasicTest(false, 'test-animate-kfa', 'First parameter as array of keyframes like: [{opacity: 1},{opacity: 0} ]');
            }
            if (player3) {
                this.listenerCount += 1;
                player3.oncancel = this.handleOnCancel;
                if (typeof player3.cancel === 'function') {
                    player3.cancel();
                }
            }
            try {
                const player2 = animateTestAsObject.animate({
                    opacity: [1, 0.5, 1]
                }, {
                    iterations: 1,
                    duration: 100
                });
                if (player2 && (typeof player2.play === 'function') && player2.currentTime >= 0 && window.getComputedStyle) {
                    player2.pause();
                    player2.currentTime = 50;
                    this.performBasicTest((parseFloat(window.getComputedStyle(animateTestAsObject).opacity) === 0.5), 'test-animate-kfo', 'First parameter as object with property arrays like: { opacity: [1, 0] }');

                    player2.play();
                    this.listenerCount += 1;
                    player2.onfinish = this.handleOnFinish;
                }
            } catch (e) {
                console.log('AnimationElement.SupportTest try/catch', e);
            }

            try {
                animateTestSingle.animate([{
                    offset: 1,
                    opacity: 1
                }], {
                    iterations: 1,
                    duration: 100
                }).onfinish = () => {
                    this.performBasicTest(true, 'test-animate-single', 'Single keyframe');
                };
            } catch (e) {
                this.performBasicTest(false, 'test-animate-single', 'Single keyframe');
            }

            this.performBasicTest((player.id === 'ISetTheId'), 'test-pid', 'id');
            this.performBasicTest((typeof player.play === 'function'), 'test-pcp', 'play()');
            this.performBasicTest((typeof player.pause === 'function'), 'test-pcs', 'pause()');
            this.performBasicTest((typeof player.reverse === 'function'), 'test-pcr', 'reverse()');
            this.performBasicTest((typeof player.cancel === 'function'), 'test-pcc', 'cancel()');
            this.performBasicTest((typeof player.finish === 'function'), 'test-pcf', 'finish()');
            this.performBasicTest((player.playState !== undefined), 'test-ps', 'playState');
            this.performBasicTest((player.playbackRate !== undefined), 'test-pbr', 'playbackRate');
            this.performBasicTest((player.updatePlaybackRate !== undefined), 'test-upbr', 'updatePlaybackRate');
            this.performBasicTest((player.pending !== undefined), 'test-pending', 'pending');
            this.performBasicTest((player.currentTime !== undefined && player.startTime !== undefined), 'test-ctst', 'currentTime/startTime');
            this.performBasicTest((player.timeline !== undefined), 'test-tl', 'timeline');
            if (this.performBasicTest((player.effect !== undefined), 'test-pe', 'effect (see also KeyframeEffect constructor)')) {
                this.performBasicTest((typeof player.effect.getTiming === 'function'), 'test-pe-gt', 'getTiming()');
                this.performBasicTest((typeof player.effect.updateTiming === 'function'), 'test-pe-ut', 'updateTiming()');
                this.performBasicTest((typeof player.effect.getComputedTiming === 'function'), 'test-pe-gct', 'getComputedTiming()');
                this.performBasicTest((typeof player.effect.getKeyframes === 'function'), 'test-pe-gkf', 'getKeyframes()');
                this.performBasicTest((typeof player.effect.setKeyframes === 'function'), 'test-pe-skf', 'setKeyframes()');
            }
        } else {
            this.performBasicTest(false, 'test-animate', 'Element.animate() support');
        }
        this.performBasicTest((typeof animateTest.getAnimations === 'function'), 'test-ega', 'Element.getAnimations()');

        const options = {
            duration: 1234,
            fill: 'backwards',
            direction: 'alternate',
            iterations: 3,
            iterationStart: 3000,
            delay: 123,
            endDelay: 123,
            easing: 'ease-in-out',
            composite: 'add',
            iterationComposite: 'accumulate'
        };

        const effects = ['duration', 'fill', 'direction', 'iterations', 'iterationStart', 'delay', 'endDelay', 'easing', 'composite', 'iterationComposite'];

        if (window.KeyframeEffect !== undefined) {
            this.performBasicTest(true, 'test-kec', 'KeyframeEffect constructor');
            const kfetest = this.template.querySelector('#test-kec');
            try {
                const kfe = new KeyframeEffect(kfetest, [{
                    opacity: 0
                }, {
                    opacity: 1
                }], options);
                if (kfe) {
                    for (let i = 0, l = effects.length; i < l; i += 1) {
                        const currentTest = effects[i];
                        if (options[currentTest] === kfe[currentTest] ||
                            (kfe.timing && options[currentTest] === kfe.timing[currentTest])) {
                            this.performBasicTest(true, `test-kec-${currentTest}`, '');
                        } else {
                            this.performBasicTest(false, `test-kec-${currentTest}`, '');
                        }
                    }
                }
            } catch (e) {
                console.log('AnimationElement.SupportTest try/catch', e);
            }
        } else {
            this.performBasicTest(false, 'test-kec', 'KeyframeEffect constructor');
        }

        this.performBasicTest((window.Animation !== undefined), 'test-ac', 'Animation constructor');

        const timeline = this.performBasicTest((document.timeline !== undefined), 'test-dt', 'Document default timeline');
        if (timeline) {
            this.performBasicTest((document.timeline.currentTime !== undefined), 'test-dtct', 'timeline.currentTime');
        }

        const getAnims = this.performBasicTest((typeof document.getAnimations === 'function'), 'test-dga', 'document.getAnimations()');
        this.performBasicTest(false, 'test-dgaca', 'document.getAnimations() includes CSS Animations*');
        this.performBasicTest(false, 'test-dgact', 'document.getAnimations() includes CSS Transitions*');
        if (getAnims) {
            const allAnimations = document.getAnimations();
            allAnimations.forEach((a) => {
                if ((a.effect && a.effect.target === this.template.querySelector('#css-anim'))) {
                    this.performBasicTest(true, 'test-dgaca', 'document.getAnimations() includes CSS Animations*');
                }
                if ((a.effect && a.effect.target === this.template.querySelector('#css-tran'))) {
                    this.performBasicTest(true, 'test-dgact', 'document.getAnimations() includes CSS Transitions*');
                }
            });
        }

        try {
            this.performBasicTest((typeof window.DocumentTimeline === 'function' && (new window.DocumentTimeline({
                originTime: 0
            }))), 'test-dtc', 'DocumentTimeline constructor');
        } catch (e) {
            console.log('AnimationElement.SupportTest try/catch', e);
        }

        if (this.listenerCount === 0) {
            this.removeFromDOM();
        }
    }

    startAnimation() {
        this.template.querySelector('#css-tran').style.opacity = 0.25;
        if (document.body.animate) {
            this.template.querySelector('#waapi-anim').animate({
                opacity: [1, 0]
            }, {
                duration: 1000,
                iterations: Infinity,
                direction: 'alternate'
            });
        }
    }

    performBasicTest(test, id, descr = '') {
        if (test) {
            if (this.template.querySelector(`#${id}`)) {
                this.template.querySelector(`#${id}`).classList.add('passed');
            }
        }
        this.testResults[id.replace('test-', '')] = {
            description: descr,
            passed: test
        };
        return test;
    }

    handleOnFinish() {
        this.performBasicTest(true, 'test-pof', 'onfinish handler');
        this.updateCallback(this.testResults);

        this.listenerCount -= 1;
        if (this.listenerCount <= 0) {
            this.removeFromDOM();
        }
    }

    handleOnCancel() {
        this.performBasicTest(true, 'test-poc', 'oncancel handler');
        this.updateCallback(this.testResults);

        this.listenerCount -= 1;
        if (this.listenerCount <= 0) {
            this.removeFromDOM();
        }
    }

    handleFinished() {
        this.performBasicTest(true, 'test-pfp', 'finished Promise');
        this.updateCallback(this.testResults);

        this.listenerCount -= 1;
        if (this.listenerCount <= 0) {
            this.removeFromDOM();
        }
    }

    handleReady() {
        this.performBasicTest(true, 'test-prp', 'ready Promise');
        this.updateCallback(this.testResults);

        this.listenerCount -= 1;
        if (this.listenerCount <= 0) {
            this.removeFromDOM();
        }
    }

    removeFromDOM() {
        this.template.remove();
    }

    get results() {
        return this.testResults;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

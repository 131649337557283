// Partials
import AnalyticsApi from 'partials/analytics';
import {
    AuthenticationApi,
    authenticationFetchApi
} from 'partials/authentication';
import { recaptchaV3Api } from 'partials/recaptcha';



// Import Utils
import {
    cookie,
    serializeObject as serialize
} from 'utils';

// Constants
import {
    COOKIES
} from 'Constants';
import creditAppRequestApi from './creditAppRequestApi';
import creditAppErrorParser from './creditAppErrorParser';

/**
 * @const defaultOptions
 * @description Default fetch options
 */
const defaultOptions = {
    body: null,
    headers: {
        'Content-type': 'application/json',
        'Referrer-Policy': 'origin',
        Referer: window.location.href
    },
    method: 'POST'
};

/**
 * @function ExceptionError
 * @description Constructor function that creates a custom error object
 * @param {number} code status code from response
 * @param {string} message error message
 */
function ExceptionError(code, message) {
    this.code = code;
    this.message = message;
    return this;
}


export function validateAddresses(data, url) {
    const addressCalls = data.map((address) => (
        this.submitAddressValidation(address, url)
            .then((response) => response)
    ));

    return Promise.allSettled(addressCalls)
        .then((responses) => {
            const failedResponses = responses.filter((e) => e.status === 'rejected');

            if (failedResponses.length > 0) {
                throw responses;
            } else {
                const errorGroup = [];
                let hasErrors = false;

                responses.forEach((response) => {
                    const error = response.value.status.error?.errors || [];

                    if (response.value.status.code !== 200) {
                        hasErrors = true;
                    }

                    if (error.length > 0) {
                        errorGroup.push([...error]);
                    }
                });

                return {
                    status: {
                        code: hasErrors ? 400 : 200,
                        error: {
                            errors: errorGroup.length > 0 ? errorGroup.flat() : []
                        }
                    }
                };
            }
        });
}

/**
 * @function submitAddressValidation
 * @description Submits an address for validation
 * @param url {string} endpoint for service
 * @param data {object} address object
 * @return {*}
 */
export function submitAddressValidation(data, url) {
    const options = {
        ...defaultOptions,
        body: JSON.stringify({
            ...data
        })
    };
    return fetch(url, options)
        .then((response) => response.json())
        .then((response) => {
            if (response.status.code === 200) {
                return response;
            } else if (response.status.code === 400) {
                const responseObj = {
                    result: {
                        code: response.status.code,
                        errorDetails: response.result.status.errorDetails
                    },
                    status: {
                        error: {
                            errors: []
                        }
                    }
                };
                return creditAppErrorParser.parseError(responseObj);
            } else {
                throw (response);
            }
        });
}

/**
 * @method submitCreditApp
 * @description Submit Credit Application Form.
 * @param {String} url - Service endpoint
 * @param {Object} data - credit application form data
 * @param {Object} recaptchaConfig - Recaptcha config object with siteKey prop/value
 * @return {Promise}
 */
export function submitCreditApp(url, data, recaptchaConfig) {
    const fetchOptions = { ...defaultOptions };
    const authApi = new AuthenticationApi();
    const isAuthenticated = authApi.isLoggedIn() || authApi.isPartiallyLoggedIn();
    const fetchRequestor = isAuthenticated ? authenticationFetchApi.authenticationFetch : fetch;
    const payloadObject = data ? creditAppRequestApi.constructRequestObject(data) : null;
    const payloadBody = !isAuthenticated && payloadObject ? JSON.stringify(payloadObject) : payloadObject;

    // window.mbVans.pageData.vehicleShopping.creditAppPersonalInfo = Object.assign({}, data.creditAppPersonalInfo);

    return recaptchaV3Api.getToken(recaptchaConfig)
        .then((recaptchaToken) => (
            fetchRequestor(url, {
                headers: {
                    ...fetchOptions.headers,
                    'X-RECAPTCHA-TOKEN': recaptchaToken
                },
                body: payloadBody,
                method: fetchOptions.method
            })
        ))
        .then((response) => (isAuthenticated ? response : response.json()))
        .then((response) => {
            if (response.status.code === 200) {
                return response;
            } else if (response.status.code === 400) {
                throw (creditAppErrorParser.parseError(response));
            }

            throw (response);
        })
        .catch((err) => {
            if (err instanceof TypeError && !navigator.onLine) {
                throw new ExceptionError(
                    600,
                    'Connectivity Error'
                );
            } else if (
                (err && !err.status) ||
                (err && err.status && err.status.code !== 400)
            ) {
                const analyticsApi = new AnalyticsApi('satellite');
                analyticsApi.logEvent('FINANCE_DRIVER_SERVICE_ERROR');
            }

            console.warn(err);
            throw err;
        });
}

/**
 * @method bindCreditAppDataToUser
 * @description method for associating quick register user to credit app
 * @param {String} endpoint - Service endpoint
 * @param {String} appId - credit application ID
 * @return {Promise}
 */
export function bindCreditAppDataToUser(endpoint, appId) {
    const url = `${endpoint}${serialize({ appId })}`;

    return authenticationFetchApi.authenticationFetch(url,
        { headers: { MBUSA_AUTH_QUICK_REGISTER_ID: cookie.getCookie(COOKIES.QUICK_REGISTER_ID) } });
}

/**
 * @method getCreditAppStatus
 * @description Fetched the status of a credit application by reference number
 * @param url {string} Finance Driver service endpoint
 * @param applicationReferenceNumber {string} Credit application reference number to get status for
 * @return {Promise<any | never>}
 */
export function getCreditAppStatus(url, applicationReferenceNumber) {
    const options = {
        ...defaultOptions,
        body: JSON.stringify({
            applicationReferenceNumber
        })
    };
    return fetch(url, options)
        .then((response) => response.json());
}


export default {
    bindCreditAppDataToUser,
    getCreditAppStatus,
    submitCreditApp,
    validateAddresses
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

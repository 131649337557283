// Partial dependencies
import { Observer } from 'partials/observer';
import { DealerSearchBar } from 'partials/dealer-search-bar';
import { SelectControl } from 'partials/select-control';
import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { LoadingSpinner } from 'partials/loading-spinner';
import { UserProfile } from 'partials/profile';
import { Modal } from 'partials/modal';
import DOMPurify from 'dompurify';

// Util dependencies
import {
    renderer,
    deserializeObject,
    dimensions,
    screen,
    tokenReplace,
    scrollTo
} from 'utils';

import { CUSTOM_EVENTS, EVENTS } from 'Constants';

// Local dependencies
import Router from './routes/Router';
import { hasDealersChanged } from './utils/dealerLocatorUtils';
import DealerLocatorModel from './models/DealerLocatorModel';
import dealerLocatorTemplate from './templates/dealerLocatorTemplate';
import DealerSearchMap from './views/DealerSearchMap';
import DealerResultsList from './views/DealerResultsList';
import DealerChangeLocation from './views/DealerChangeLocation';
import BrowseByState from './views/BrowseByState';
import PreferredDealer from './views/PreferredDealer';
import DealerDetail from './views/DealerDetail';

// external css
const brand = window.mbVans.ns('pageData').brand || 'mb';

// eslint-disable-next-line no-unused-expressions
import(
    /* webpackChunkName: "[request]" */
    // eslint-disable-next-line prefer-template
    'plugins/dealer-locator/css/' +
    brand.toLowerCase() +
    '/dealer-locator.css'
);

/**
 * CLASSES{}
 * Stores class names for use in the DOM
 */
const CLASSES = {
    SUBHEADING_CONTAINER: 'dealer-locator-module__subheading-container',
    SEARCH_BAR: 'dealer-locator-module__search-bar',
    FILTER_BAR: 'dealer-locator-module__filter-bar .wrapper',
    MAP: 'dealer-locator-module__map',
    MODAL_MAP: 'dealer-locator-module__map-in-modal',
    RESULTS: 'dealer-locator-module__results',
    HAS_RESULT: 'dealer-locator-module--has-results',
    ACTIVE_LOADER: 'dealer-locator-module--has-loader',
    HIDE_BTN: 'dealer-locator-module__map--hidemap-cta',
    SHOW_BTN: 'dealer-locator-module__showmap-cta',
    SEARCH_CONTAINER: 'dealer-locator-module__search-container',
    CHANGE_BLOCK: 'dealer-locator-module__change-block',
    BROWSE_ALL_CTA: 'dealer-locator-module__browse-all',
    SPINNER_CONTAINER: 'dealer-locator-module__spinner-container',
    PREFERRED_DEALER: 'dealer-locator-module__preferred-dealer',
    CTA_CONTAINER: 'dealer-locator-module__cta-container',
    RESET_FILTERS: 'dealer-locator-module__reset-filters',
    NO_RESULT: 'dealer-locator-module--no-results',
    DEALER_DETAIL_CONTAINER: 'dealer-locator-module__dealer-detail-container',
    SHOW_DETAIL_VIEW: 'dealer-locator-module--show-detail',
    HEADING: 'dealer-locator-module__heading'
};

/**
 * @const META_TAGS
 * @type {{DEFAULT_TITLE: string}}
 */
const META_TAGS = {
    DEFAULT_TITLE: window.document.title
};

/**
 * @const ARIA_ATTRIBUTES
 * @description constant for the attribute used for accessibility purposes
 */
const ARIA_ATTRIBUTES = {
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_CONTROLS: 'aria-controls',
    ARIA_EXPANDED: 'aria-expanded',
};

/**
 * @var {Number} currentSearchIndex
 * @description Stores the current index of the dealers for appending
 */
let currentSearchIndex = 0;

/**
 * @var {Number} totalCount
 * @description Stores the total count of dealers for appending
 */
let totalCount = 0;


/**
 * @class DealerLocator
 * @description Dealer locator highest level component. This class handles all the model update changes,
 * and associated state changes that needs to passed down to all components
 */
export default class DealerLocator extends Observer {
    /**
     * @static THEME
     * @description Themes for Dealer Locator
     * @type {{MEDIUM_SIZE: string}}
     */
    static THEME = {
        MEDIUM_SIZE: 'medium-size'
    };

    /**
     * @constructor
     * @description Initialize template, caches DOM, attaches events, and renders the element
     * @param {Element} element - Container element into which this plugin should be rendered
     * @param {Object} config - Config object which has plugin related configuration
     * @param {string} config.country - Lower cased ISO alpha 2 country code
     * @param {string} config.displayLayout - Layout type of dealer locator
     * @param {string} config.displayState - The type of experience of dealer locator
     * @param {string} config.distanceUnit - Distance unit, "Kilometer" or "Mile"
     * @param {string} config.distanceUnitAbbr - Distance unit abbreviation, "km" or "mi"
     * @param {boolean} config.isURLParamUsed - Flag to instantiate router and set URL Params
     * @param {string} config.language - Language ISO 639-1 code
     * @param {boolean} config.mapModalToggleDisabled - Whether toggle map modal in small view should be disabled
     * @param {Object} content - Localization object
     */
    constructor(element, config, content) {
        super();

        this.containerElement = element;
        this.config = config;
        this.content = content;

        // Init Elements
        this.subheading = null;
        this.searchBarElm = null;
        this.filterBarElm = null;
        this.dealerResultElm = null;
        this.hideMapBtn = null;
        this.showMapBtn = null;
        this.backToMap = false;
        this.searchMap = null;
        this.searchMapModal = null;

        this.router = new Router(this.config.isURLParamUsed ? undefined : {});
        this.profile = new UserProfile();
        this.readUrlParams();
        const isSearchTextNumeric = this.params.searchText && !isNaN(this.params.searchText);
        let searchParams = {
            searchByType: isSearchTextNumeric ? 'zip' : 'string',
            searchLocation: this.params.searchText ? this.params.searchText : null
        };
        if (this.config.defaultLocation) {
            searchParams = {
                ...this.config.defaultLocation
            };
        }
        this.model = new DealerLocatorModel(
            {
                preferredDealer: this.profile.getPreferredDealer(),
                filter: this.config.filter,
                lcp: this.config.lcpCode,
                ...searchParams,
                ...this.router.getParams()
            }
        );

        this.element = dealerLocatorTemplate(content, config)({ getNode: true });

        this.setHeight = this.setHeight.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.toggleMap = this.toggleMap.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onModalAfterOpen = this.onModalAfterOpen.bind(this);
        this.onModalBeforeClose = this.onModalBeforeClose.bind(this);
        this.createFilterBar = this.createFilterBar.bind(this);
        this.setProgramFilter = this.setProgramFilter.bind(this);
        this.loadMoreResults = this.loadMoreResults.bind(this);
        this.appendDealerResultsList = this.appendDealerResultsList.bind(this);
        this.globalNavSearchListener = this.globalNavSearchListener.bind(this);
        this.createBrowseByState = this.createBrowseByState.bind(this);
        this.goToBrowseByState = this.goToBrowseByState.bind(this);
        this.getLCPDealers = this.getLCPDealers.bind(this);
        this.getDealersForState = this.getDealersForState.bind(this);
        this.createPreferredDealerView = this.createPreferredDealerView.bind(this);
        this.handleDealerSelectCallback = this.handleDealerSelectCallback.bind(this);
        this.resetView = this.resetView.bind(this);
        this.resetFiltersHandler = this.resetFiltersHandler.bind(this);
        this.backToResultsCallback = this.backToResultsCallback.bind(this);
        this.togglePreferredDealer = this.togglePreferredDealer.bind(this);

        this.cacheDOM();
        this.attachEvents();

        if (this.config.featureFlags.showSearchContainer) {
            this.createSearchBar();
        }

        if (this.config.featureFlags.showSubheading) {
            this.setSubHeading();
        }

        if (this.config.featureFlags.showFilterBar) {
            this.createFilterBar();
        }

        this.createMap();

        if (this.router.getParams().browseAll) {
            this.goToBrowseByState();
        }

        if (this.model.state.lcp) {
            this.getLCPDealers();
            this.ctaContainer.classList.add('hide');
        } else if (!this.params.searchText || isSearchTextNumeric) {
            this.getDealers();
        }

        this.render();
        this.setHeight();
    }

    validateSource(input) {
        const cleanedInput = DOMPurify.sanitize(input);
        return cleanedInput;
    }
    /**
     * @method destroy
     * @description Destroys the Dealer Locator Plugin by destroying subcomponents and then removing DOM element
     */
    destroy() {
        this.detachEvents();
        this.searchContainer.style.height = `${this.searchContainer.parentElement.offsetHeight}px`;
        this.searchContainer.style.overflow = 'hidden';
        this.renderLoader();
    }

    /**
     * @method readUrlParams
     * @description Reads the URL search string, parses to Object and assign values
     * to properties
     */
    readUrlParams() {
        this.urlParamString = this.validateSource(window.location.search.slice(1));
        this.params = deserializeObject(this.urlParamString);
    }

    /**
     * @method cacheDOM
     * @description Caches DOM Elements
     */
    cacheDOM() {
        this.searchBarElm = this.element.querySelector(`.${CLASSES.SEARCH_BAR}`);
        this.filterBarElm = this.element.querySelector(`.${CLASSES.FILTER_BAR}`);
        this.mapElm = this.element.querySelector(`.${CLASSES.MAP}`);
        this.modalMapElm = this.element.querySelector(`.${CLASSES.MODAL_MAP}`);
        this.dealerResultElm = this.element.querySelector(`.${CLASSES.RESULTS}`);
        this.showMapBtn = this.element.querySelector(`.${CLASSES.SHOW_BTN}`);
        this.searchContainer = this.element.querySelector(`.${CLASSES.SEARCH_CONTAINER}`);
        this.subheadingContainerElms = [].slice.call(this.element.querySelectorAll(`.${CLASSES.SUBHEADING_CONTAINER}`));
        this.changeLocationElm = this.element.querySelector(`.${CLASSES.CHANGE_BLOCK}`);
        this.spinContainer = this.element.querySelector(`.${CLASSES.SPINNER_CONTAINER}`);
        this.browseAllCTA = this.element.querySelector(`.${CLASSES.BROWSE_ALL_CTA}`);
        this.preferredDealerElm = this.element.querySelector(`.${CLASSES.PREFERRED_DEALER}`);
        this.ctaContainer = this.element.querySelector(`.${CLASSES.CTA_CONTAINER}`);
        this.resetFiltersCTA = this.element.querySelector(`.${CLASSES.RESET_FILTERS}`);
        this.dealerDetailContainer = this.element.querySelector(`.${CLASSES.DEALER_DETAIL_CONTAINER}`);
        this.headingElm = [].slice.call(this.element.querySelectorAll(`.${CLASSES.HEADING}`));
    }

    /**
     * @method attachEvents
     * @description Attachs events
     */
    attachEvents() {
        this.model.attach(this);

        this.showMapBtn.addEventListener(EVENTS.CLICK, (e) => {
            if (!this.modal || (this.modal && !this.modal.isActive)) {
                this.openModal(e);
                this.backToMap = true;
            } else {
                this.backToMap = false;
                this.modal.close();
            }
        });

        if (this.config.featureFlags.showBrowseState) {
            this.browseAllCTA.addEventListener(EVENTS.CLICK, () => {
                this.resetView(true);
                this.createPreferredDealerView();
                this.createBrowseByState();
                this.router.setParams({ browseAll: true });
            });
        }

        screen.addResizeListener(this.onResize);
        window.addEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            (event) => {
                this.searchBar.destroy();
                this.setSearch(event.detail);
                this.createSearchBar();
            });

        if (this.config.featureFlags.showFilterBar) {
            this.resetFiltersCTA.addEventListener(EVENTS.CLICK, () => {
                this.dealerFilterBar.selectDefaultOption();
                this.setProgramFilter(this.content.filters[0].value);
            });
        }
    }

    /**
     * @method detachEvents
     * @description Detaches events
     */
    detachEvents() {
        this.model.detach(this);

        this.showMapBtn.removeEventListener(EVENTS.CLICK, this.toggleMap);
        if (this.config.featureFlags.showBrowseState) {
            this.browseAllCTA.removeEventListener(EVENTS.CLICK, this.goToBrowseByState);
        }
        screen.removeResizeListener(this.onResize);
        window.removeEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            this.globalNavSearchListener);

        if (this.config.featureFlags.showFilterBar) {
            this.resetFiltersCTA.removeEventListener(EVENTS.CLICK, this.resetFiltersHandler);
        }
    }

    /**
     * @method setSearchInMap
     * @description Set searchTerm, searchByType and searchLocation to map
     * @param {Object} map
     */
    setSearchInMap(map) {
        const { searchTerm, searchLocation, searchByType } = this.model.state;

        map.setSearchTerm(searchTerm);
        map.setSearchByType(searchByType);
        map.setSearchLocation(searchLocation);
    }

    /**
     * @method addDealersToMap
     * @description Adds markers to searchMap and to searchMapModal (if exists)
     * @param {Array} dealers - Array of dealer objects
     * @param {Boolean} resetMarkers - Checks if a list is being created or appended
     */
    addDealersToMap(dealers, resetMarkers = true) {
        this.searchMap.addDealers(dealers, resetMarkers);
        if (this.searchMapModal) {
            this.searchMapModal.addDealers(dealers, resetMarkers);
        }
    }

    /**
     * @method setPreferredDealerToMap
     * @description Sets the preferred dealer
     * @param {Object} preferredDealer - Array of dealer objects
     */
    setPreferredDealerToMap(preferredDealer) {
        this.searchMap.setPreferredDealer(preferredDealer);
        if (this.searchMapModal) {
            this.searchMapModal.setPreferredDealer(preferredDealer);
        }
    }

    /**
     * @method resetMap
     * @description Resets searchMap and searchMapModal if exists
     */
    resetMap() {
        this.searchMap.resetMap();
        if (this.searchMapModal) {
            this.searchMapModal.resetMap();
        }
    }

    /**
     * @method onUpdate
     * @description When model updates, this callback is triggered
     * @param prevState {Object} Previous state before change
     * @param nextState {Object} Next state after change
     */
    onUpdate(prevState, nextState) {
        const { searchLocation, searchByType, filter, preferredDealer, lcp } = this.model.state;

        if (this.config.isURLParamUsed) {
            this.router.setParams({ searchLocation, searchByType, filter, lcp });
        }

        this.setSearchInMap(this.searchMap);

        if (this.searchMapModal) {
            this.setSearchInMap(this.searchMapModal);
        }

        // Boolean value to check if searchLocation has been changed and is not null
        const searchLocationCheck = prevState.searchLocation !== nextState.searchLocation
            && nextState.searchLocation !== null;

        if (this.config.featureFlags.showSubheading && prevState.searchLocation !== nextState.searchLocation) {
            this.setSubHeading();
        }

        if (searchLocationCheck && this.config.featureFlags.showFilterBar) {
            this.createFilterBar();
        }

        if (searchLocationCheck && this.config.featureFlags.showChangeLocation) {
            this.toggleSearchChangeBlock();
        }

        // If changed, call UserProfile to save lastLocation to localStorage object
        if (prevState.searchLocation !== nextState.searchLocation &&
            nextState.searchLocation !== null) {
            this.profile.setLastLocation({ searchLocation, searchByType });
        }

        if (prevState.searchLocation !== nextState.searchLocation || prevState.filter !== nextState.filter &&
            nextState.searchLocation !== null) {
            // Make only API call here which sets the dealers
            currentSearchIndex = 0;
            totalCount = 0;
            this.setMainHeading(this.content.moduleHeading);
            window.document.title = META_TAGS.DEFAULT_TITLE;
            this.getDealers();
            // Sets attributes back to default states with new search
            this.toggleBrowseAll(true);
            if (this.resetFiltersCTA) {
                this.resetFiltersCTA.classList.add('hide');
            }
            if (this.ctaContainer) {
                this.ctaContainer.classList.remove('hide');
            }

            if (preferredDealer && !preferredDealer.badges.includes(nextState.filter) && this.preferredModule) {
                this.searchMap.destroyMarker(preferredDealer.id);
                if (this.searchMapModal) {
                    this.searchMapModal.destroyMarker(preferredDealer.id);
                }
                if (this.preferredModule) {
                    this.preferredModule.destroy();
                }
                this.preferredModule = null;
                this.setPreferredDealerToMap(null);
            }
        }

        // Compare dealers and render results and update map
        if (nextState.dealers !== null && hasDealersChanged(prevState.dealers, nextState.dealers)) {
            // If there are results for the current search
            if (nextState.dealers.length) {
                if (currentSearchIndex === 0) {
                    // If preferred dealer should be shown along with list of results
                    // don't create individual preferred dealer module
                    if (!this.config.featureFlags.inlinePreferredDealer) {
                        this.createPreferredDealerView();
                    }

                    this.createDealerResultsList();
                    this.element.classList.add(CLASSES.HAS_RESULT);
                    this.addDealersToMap(nextState.dealers);
                    this.showMapBtn.classList.remove('hide');
                } else {
                    this.addDealersToMap(nextState.dealers, false);
                }
            } else {
                // If there are no results for the current search
                this.resetMap();
                if (!this.config.featureFlags.inlinePreferredDealer) {
                    this.createPreferredDealerView();
                    this.addDealersToMap([]);
                }
                this.createDealerResultsList();
                this.element.classList.remove(CLASSES.HAS_RESULT);
                this.element.classList.add(CLASSES.NO_RESULT);
                if (this.resetFiltersCTA) {
                    this.resetFiltersCTA.classList.remove('hide');
                }
                this.toggleBrowseAll(false);
            }
            this.destroyLoader();
        }

        // Compare preferred dealers
        if (nextState.preferredDealer !== prevState.preferredDealer) {
            // Set preferred dealer to user profile. If preferred dealer is being remove
            // null is set to profile
            this.profile.setPreferredDealer(preferredDealer);

            // Since preferred dealer is set only from dealer detail, remove the associated
            // pin before setting the preferred dealer pin.
            // If preferred dealer is being removed, add dealer detail pin
            this.searchMap.destroyMarker(this.dealerDetail.dealer.id);
            this.searchMap.addDealerDetail(this.dealerDetail.dealer, !!preferredDealer);
            if (this.searchMapModal) {
                this.searchMapModal.destroyMarker(this.dealerDetail.dealer.id);
                this.searchMapModal.addDealerDetail(this.dealerDetail.dealer, !!preferredDealer);
            }
            // If preferred dealer is being removed/updated:
            // 1. Destroy instance of previous preferred dealer
            // 2. Remove preferred dealer flag from dealer in dealer results list.
            // 3. Set the current dealer detail as not preferred (gets updated below if
            // preferred dealer is being changed and not remove)
            if (prevState.preferredDealer) {
                if (this.preferredModule) {
                    this.preferredModule.destroy();
                }
                this.preferredModule = null;
                this.setPreferredDealerToMap(null);
                (this.dealerResultsList || this.browseByState).unsetPreferredDealer(prevState.preferredDealer.dealerId);
                this.dealerDetail.setPreferred(false);
            }

            // If preferred dealer is being set:
            // 1. Create preferred dealer view, this won't be visible to user since user
            // would still be in dealer detail view
            // 2. Set preferred dealer in the result, this hides the preferred dealer in results list
            // 3. Set preferred dealer flag for dealer detail, used to add CSS class to control icons
            if (preferredDealer) {
                this.createPreferredDealerView();
                (this.dealerResultsList || this.browseByState).setPreferredDealer(preferredDealer.dealerId);
                this.dealerDetail.setPreferred(true);
            }
        }
    }

    /**
     * @method globalNavSearchListener
     * @description event listener for globalnavsearch
     * @param {Event} event Event for when user submits search in nav dealer finder
     */
    globalNavSearchListener(event) {
        this.searchBar.destroy();
        this.setSearch(event.detail);
        this.createSearchBar();
    }

    /**
     * @method loadMoreResults
     * @description Callback function for loading more results
     */
    loadMoreResults() {
        this.getDealers().then(() => {
            this.appendDealerResultsList();
            if (currentSearchIndex < totalCount) {
                // The currentSearchIndex represents the end of the appended dealers. Subtracting the dealers
                // length gives us the beginning of the appended dealers
                this.dealerResultsList.focusOnResult(currentSearchIndex - this.model.state.dealers.length);
            }
        });
    }

    /**
     * @method createSearchBar
     * @description Instantiates a DealerSearchBar for searching for
     * dealers by a form input field
     */
    createSearchBar() {
        const {
            searchTerm,
            searchLocation,
            searchByType,
            searchType
        } = this.model.state;

        this.searchBar = new DealerSearchBar({
            analyticsTrigger: 'search',
            autoSubmitLocation: !this.config.defaultLocation,
            country: this.config.country,
            ctaLabel: this.content.searchCTA,
            dealerSearchErrorMessage: this.content.searchErrorMessage,
            defaultLocation: this.config.defaultLocation ? this.config.defaultLocation : {
                searchTerm,
                searchLocation,
                searchByType,
                searchType
            },
            onSubmit: this.setSearch,
            searchInputLabel: this.content.searchInputLabel,
            theme: DealerSearchBar.THEMES.DEALER_SEARCH,
            buttonStyle: 'button button_primary'
        });

        renderer.insert(this.searchBar.render(), this.searchBarElm);
    }

    /**
     * @method createFilterBar
     * @description Instantiates a SelectControl for filtering through
     * result list with select categories. Filter list is trimmed of any false values
     */
    createFilterBar() {
        const { searchLocation, filter } = this.model.state;

        if (searchLocation) {
            const trimmedFilters = this.content.filters.filter((filterObject) => filterObject);
            const defaultFilterSelection =
                trimmedFilters.findIndex((filterObject) => filterObject.value === filter);

            this.dealerFilterBar = new SelectControl(
                trimmedFilters,
                {
                    icon: 'icon-filters',
                    labelText: `${this.content.filter}:`,
                    analyticsTrigger: 'cta-filter',
                    required: false,
                    theme: SelectControl.THEMES.DROPDOWN,
                    selectionCallback: this.setProgramFilter,
                    defaultSelection: defaultFilterSelection !== -1 ? defaultFilterSelection : 0
                }
            );
            renderer.insert(this.dealerFilterBar.render(), this.filterBarElm);
        }
    }

    /**
     * @method createChangeLocationBlock
     * @description Search term is grabbed from model state
     * TokenReplace function is used to replace placeholder copy with search term
     * If searchTerm exists then the DealerChangeLocation view is rendered
     */
    createChangeLocationBlock() {
        // Set up search term
        const { searchTerm } = this.model.state;
        const newTerm = tokenReplace(this.content.subtext, { searchTerms: searchTerm });

        if (searchTerm) {
            this.dealerChangeBlock = new DealerChangeLocation(
                newTerm, this.content.changeLocationCTA, this.resetView.bind(this, true)
            );
            renderer.insert(this.dealerChangeBlock.render(), this.changeLocationElm);
            this.dealerChangeBlock.focusChangeLocationCTA();
        }
    }

    /**
     * @method createDealerResultsList
     * @description Creates the Dealer Results List View
     * @param {Boolean} serviceDown - Checks the status of the api service
     */
    createDealerResultsList(serviceDown) {
        const { dealers, lcp } = this.model.state;

        this.dealerResultsList = new DealerResultsList(
            dealers,
            {
                ...this.config,
                showResultsListCTA: !lcp
            },
            this.content,
            this.loadMoreResults,
            this.goToBrowseByState,
            this.handleDealerSelectCallback,
            serviceDown);
        renderer.insert(this.dealerResultsList.render(), this.dealerResultElm);
        if (dealers) {
            currentSearchIndex += this.model.state.dealers.length;
        }
    }

    /**
     * @method appendDealerResultsList
     * @description Creates the Dealer Results List View
     */
    appendDealerResultsList() {
        const { dealers } = this.model.state;

        this.dealerResultsList.renderDealers(dealers);
        currentSearchIndex += 10;

        if (currentSearchIndex >= totalCount) {
            this.dealerResultsList.endOfResultsMsg();
            this.toggleBrowseAll(false);
        }
    }

    /**
     * @method handleDealerSelectCallback
     * @description Handles the function toggle between inventory for on dealer select or a new one
     * @param dealer {Object} dealer object that goes into callback
     */
    handleDealerSelectCallback(dealer) {
        const dealerCopy = dealerLocatorApi.deepCloneDealer(dealer);
        const screenState = screen.getState();
        if (screenState.small) {
            if (this.modal && this.modal.isActive) {
                this.modal.close();
            }
        }
        if (this.config.onDealerSelect) {
            this.config.onDealerSelect(dealerCopy);
        } else {
            this.createDealerDetail(dealerCopy);
            this.searchMap.resetMap();
            this.searchMap.addDealerDetail(dealerCopy, dealerCopy.isPreferred);
        }
    }

    /**
     * @method goToBrowseByState
     * @description Go to Browse by state view
     * Resets model, creates preferred dealer view, creates browse by state view, sets router param
     */
    goToBrowseByState() {
        this.resetView(true);
        this.createPreferredDealerView();
        this.createBrowseByState();
        this.router.setParams({ browseAll: true });
    }

    /**
     * @method createBrowseByState
     * @description Browse By State click handler. This resets to default search view, and renders the
     * BrowseByState view
     */
    createBrowseByState() {
        dealerLocatorApi.getLocations(this.config.country, this.config.language, this.config.brand)
            .then((states) => {
                this.browseByState = new BrowseByState(
                    states,
                    this.config,
                    this.content,
                    this.getDealersForState,
                    this.handleDealerSelectCallback);
                renderer.insert(this.browseByState.render(), this.dealerResultElm);

                this.element.classList.add(CLASSES.HAS_RESULT);
                if (this.ctaContainer) {
                    this.ctaContainer.classList.add('hide');
                }
            });
    }

    /**
     * @method getLCPDealers
     * @description This grabs the lcp and preferredDealer from state to make an api call
     * and set dealers within the model
     */
    getLCPDealers() {
        const { lcp, preferredDealer } = this.model.state;

        dealerLocatorApi.getDealersByLCP(this.config.country, this.config.language, lcp, this.config.brand)
            .then((response) => {
                META_TAGS.DEFAULT_TITLE = document.title;
                const dealerNameParts = /(?:Mercedes-Benz\s)?(.*)\s(Dealers)/g.exec(response.lcpHeader);

                if (!this.config.featureFlags.hasOverrideTitle) {
                    this.setMainHeading(response.lcpHeader);
                }

                document.title = `Mercedes-Benz ${dealerNameParts[1]} | ${dealerNameParts[2]}`;

                const modifiedResults =
                    response.results
                        .filter((dealer) => (preferredDealer ? dealer.dealerId !== preferredDealer.dealerId : true));
                this.model.setDealers(modifiedResults);
            });
    }

    /**
     * @method createDealerDetail
     * @description Create dealer detail view for the dealer that is passed in
     * @param {Object} dealer - Dealer object with which the detail view should be created
     */
    createDealerDetail(dealer) {
        this.dealerDetail = new DealerDetail(
            dealer,
            this.content,
            {
                useScheduleServiceURL: this.config.useServiceURL,
                showDealerUrl: this.config.showDealerUrl,
            },
            this.backToResultsCallback,
            this.togglePreferredDealer);
        renderer.insert(this.dealerDetail.render(), this.dealerDetailContainer);
        const transitionEndHandler = () => {
            this.element.removeEventListener(EVENTS.TRANSITION_END, transitionEndHandler);
            this.setDetailTileHeight();
            this.dealerDetailContainer.style.top = '0px';
            scrollTo(0, 0);
            this.dealerDetail.setFocusBackToResults();
        };
        this.currentScrollPos = this.backToMap ? '0px' : this.getScrollTop();
        this.dealerDetailContainer.style.top = `${this.currentScrollPos}px`;
        this.element.addEventListener(EVENTS.TRANSITION_END, transitionEndHandler);
        this.element.classList.add(CLASSES.SHOW_DETAIL_VIEW);
    }

    /**
     * @method getScrollTop
     * @description Get current scroll top of the page
     */
    getScrollTop() {
        return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    }

    /**
     * @method getDealers
     * @description Gets the dealers and sets it in the model
     */
    getDealers() {
        const { searchLocation, searchByType, filter, preferredDealer } = this.model.state;
        const opts = {
            brand: this.config.brand,
            country: this.config.country,
            language: this.config.language,
            searchByType,
            searchLocation,
            filterBy: this.config.filterBy,
            isNew: true,
            start: currentSearchIndex,
            count: 10,
            filter
        };

        if (searchLocation) {
            this.renderLoader();
            return dealerLocatorApi.getDealersAlt(opts)
                .then((response) => {
                    let modifiedResults = [];

                    // If the preferred dealer should be shown along with the results, add isPreferred flag
                    if (response.results.length) {
                        modifiedResults =
                            response.results
                                .map((dealer) => ({
                                    ...dealer,
                                    isPreferred: preferredDealer && dealer.dealerId === preferredDealer.dealerId
                                }));
                    }

                    this.model.setDealers(modifiedResults);

                    if (totalCount === 0) {
                        totalCount = response.totalCount;
                    }
                })
                .catch((error) => {
                    this.serviceDownHandler(error.message);
                })
                .finally(() => {
                    this.destroyLoader();
                });
        }
        return null;
    }

    /**
     * @method setSubheading
     * @description Sets the subheading to different copy depending on view
     * Based upon the requirements of the inventory page
     * Because there are two subheading elements we loop through an array
     * and change the copy within the elements depending on searchLocation
     */
    setSubHeading() {
        const subHeadingCopy =
            this.model.state.searchLocation ?
                this.content.resultsSubheading : this.content.defaultSubheading;

        this.subheadingContainerElms.forEach((item) => {
            const subHeading = renderer.fromTemplate(`<p class="dealer-locator-module__subheading">${subHeadingCopy}</p>`);
            renderer.insert(subHeading, item);
        });
    }

    /**
     * @method setHeading
     * @description Toggles the main heading to a different copy between LCP and default views
     * @param {String} heading - LCP or default main header
     */
    setMainHeading(heading) {
        this.headingElm.forEach((item) => {
            item.innerHTML = heading;
        });
    }

    /**
     * @method getDealersForState
     * @description Gets a list of dealers for each state
     * @param {String} state - ISO 3166-2 value for the state
     * @returns {Promise} A promise that resolves with an array of dealers for the state
     */
    getDealersForState(state) {
        const { preferredDealer } = this.model.state;
        const opts = {
            brand: this.config.brand,
            country: this.config.country,
            language: this.config.language,
            filter: this.config.filter,
            isNew: true,
            count: 1000, // Weird hack because API does not support an "ALL" value
            state
        };

        return dealerLocatorApi.getDealersAlt(opts)
            .then((response) => {
                let modifiedResults = [];

                // If the preferred dealer should be shown along with the results, add isPreferred flag
                if (response.results.length) {
                    modifiedResults =
                        response.results
                            .map((dealer) => ({
                                ...dealer,
                                isPreferred: preferredDealer && dealer.dealerId === preferredDealer.dealerId
                            }));
                }
                this.showMapBtn.classList.remove('hide');
                this.addDealersToMap(modifiedResults);
                return modifiedResults;
            });
    }

    /**
     * @method setSearch
     * @description Sets the selected search location
     * @param location {String} Location postal code
     */
    setSearch(location) {
        this.model.setSearchTerm(location.searchString);
        this.model.setSearchByType(location.searchByType);
        this.model.setSearchLocation(location.searchLocation);
        this.model.setLCP(null);
    }

    /**
     * @method setProgramFilter
     * @description Sets the selected filter to the model
     * @param {String} filter Selected filter
     */
    setProgramFilter(filter) {
        this.model.setFilter(filter);
    }

    /**
     * @method createMap
     * @description Instantiates a DealerSearchMap and sets a reference to the instance
     */
    createMap() {
        if (!this.config.mapModalToggleDisabled) {
            this.searchMapModal = new DealerSearchMap(
                this.modalMapElm,
                {
                    brand: this.config.brand,
                    country: this.config.country,
                    includePreferredMarker: this.config.featureFlags.inlinePreferredDealer,
                    includeHideCustomControl: true,
                    enabledLarge: false
                },
                this.content,
                this.toggleMap,
                null,
                this.handleDealerSelectCallback
            );
        }

        this.searchMap = new DealerSearchMap(
            this.mapElm,
            {
                brand: this.config.brand,
                country: this.config.country,
                includePreferredMarker: this.config.featureFlags.inlinePreferredDealer,
                includeHideCustomControl: false,
                enabledLarge: true
            },
            this.content,
            this.toggleMap,
            null,
            this.handleDealerSelectCallback
        );
    }

    /**
     * @method setDetailTileHeight
     * @description Sets detail tile container height based on detail tile content height
     */
    setDetailTileHeight(isBackToResults = false) {
        const screenState = screen.getState();
        if (screenState.small && this.dealerDetail) {
            this.element.style.height = isBackToResults ? 'auto' : `${this.dealerDetail.element.offsetHeight}px`;
        }
    }

    /**
     * @method setMapHeight
     * @description Sets the map element's height based on:
     * 1. 60% of window width for small screen
     * 2. Window and top navigation height for large screen
     */
    setHeight() {
        const screenState = screen.getState();
        let mapHeight;
        this.element.style.maxHeight = '';
        this.element.style.height = '';

        if (!screenState.small) {
            if (this.config.theme === DealerLocator.THEME.MEDIUM_SIZE) {
                mapHeight = 615;
                this.element.style.maxHeight = `${mapHeight}px`;
                this.element.style.height = `${mapHeight}px`;
            } else {
                const windowHeight = window.innerHeight;
                const { top } = dimensions.getOffset(this.element);
                mapHeight = windowHeight - top;
            }
        } else {
            const windowWidth = window.innerWidth;
            mapHeight = 0.6 * windowWidth;
        }

        this.mapElm.style.height = `${mapHeight}px`;
    }

    /**
     * @method onResize
     * @description Function that attaches to the show map link with a event listener
     * and handles hiding the map
     */
    onResize() {
        this.setHeight();
        this.setDetailTileHeight();

        if (!screen.getState().small && this.modal && this.modal.isActive) {
            this.backToMap = false;
            this.modal.close();
        }
    }

    /**
     * @method toggleSearchChangeBlock
     * @description Function that handles the toggle of the search block with the change location
     * block. Will be used in onUpdate for inventory views and in resetView
     * Should only be used in conjunction with a check for whether the showChangeLocation flag
     * is set
     */
    toggleSearchChangeBlock() {
        const { searchLocation } = this.model.state;

        if (searchLocation) {
            if (this.searchBar) {
                this.searchBar.destroy();
                this.searchBar = null;
            }

            this.createChangeLocationBlock();
        } else {
            if (this.dealerChangeBlock) {
                this.dealerChangeBlock.destroy();
                this.dealerChangeBlock = null;
            }

            this.createSearchBar();
        }
    }

    /**
     * @method toggleMap
     * @description Function that toggles between hiding and showing the map
     */
    toggleMap(e) {
        if (!this.modal || (this.modal && !this.modal.isActive)) {
            this.openModal(e);
            this.backToMap = true;
        } else {
            this.backToMap = false;
            this.modal.close();
        }
    }

    /**
     * @method resetView
     * @description Handles resetting the view for dealer locator and inventory
     * Resets the scroll position and Removes has results class on element
     * Resets to the base view with no search terms, results or filter bar
     * Resets subheading and map
     * Sets the model with null; Destroys instances of search bar, change location block and
     * filter bar and results list.
     * For search bar since the changelocation block already has is removed it has
     * an extra conditional to ensure the view does not contain the changeLocation logic
     */
    resetView(resetAll = false) {
        this.searchContainer.scrollTop = 0;
        this.element.classList.remove(CLASSES.HAS_RESULT);

        if (resetAll) {
            this.model.setSearchByType(null);
            this.model.setSearchLocation(null);
        }
        this.model.setDealers(null);
        this.model.setFilter(this.config.filter);
        this.model.setSearchTerm(null);

        this.resetMap();
        this.showMapBtn.classList.add('hide');

        if (this.config.featureFlags.subHeading) {
            this.setSubHeading();
        }

        if (this.searchBar && !this.dealerChangeBlock) {
            this.searchBar.destroy();
            this.searchBar = null;
            this.createSearchBar();
        }

        if (this.dealerChangeBlock) {
            this.dealerChangeBlock.destroy();
            this.dealerChangeBlock = null;
            this.createSearchBar();
        }

        if (this.dealerFilterBar) {
            this.dealerFilterBar.destroy();
            this.dealerFilterBar = null;
        }

        if (this.dealerResultsList) {
            this.dealerResultsList.destroy();
            this.dealerResultsList = null;
        }

        if (this.modal) {
            this.backToMap = false;
            this.modal.close();
        }
    }

    /**
     * @method renderLoader
     * @description Renders the loading spinner
     */
    renderLoader() {
        if (!this.loader) {
            this.loader = new LoadingSpinner();
            this.element.classList.add(CLASSES.ACTIVE_LOADER);
            renderer.append(this.loader.render(), this.spinContainer);
        }
    }

    /**
     * @method destroyLoader
     * @description Removes the loading spinner
     */
    destroyLoader() {
        this.element.classList.remove(CLASSES.ACTIVE_LOADER);
        if (this.loader) {
            this.loader.destroy();
            this.loader = null;
        }
    }

    /**
     * @method createPreferredDealerView
     * @description Creates Preferred Dealer module
     * If preferredDealer already exists, destroy and set to null
     */
    createPreferredDealerView() {
        const { preferredDealer, filter } = this.model.state;

        if (preferredDealer && (preferredDealer.badges.includes(filter) || filter === 'all')) {
            this.preferredModule = new PreferredDealer(
                { ...preferredDealer, isPreferred: true },
                this.config,
                this.content,
                this.handleDealerSelectCallback);
            this.setPreferredDealerToMap(preferredDealer);
            renderer.insert(this.preferredModule.render(), this.preferredDealerElm);
        }
    }

    /**
     * @method toggleBrowseAll
     * @description Toggles the browse all cta between primary and secondary CTA styling
     * @param {Boolean} isSecondary - If true, add necessary classes to change to plain link, else change to
     * primary CTA
     */
    toggleBrowseAll(isSecondary) {
        if (this.config.featureFlags.showBrowseState) {
            this.browseAllCTA.classList[isSecondary ? 'add' : 'remove']('link', 'link_plain-link');
            this.browseAllCTA.classList[isSecondary ? 'remove' : 'add']('button', 'button_primary', 'button--full-width');
        }
    }

    /**
     * @method resetFiltersHandler
     * @description Resets the filters when the appropriate CTA is selected
     */
    resetFiltersHandler() {
        this.dealerFilterBar.selectDefaultOption();
        this.setProgramFilter(this.content.filters[0].value);
    }

    /**
     * @method backToResultsCallback
     * @description Callback for when Back To Results CTA is clicked in Dealer Details. This removes the container
     * CSS class, and destroys instance of DealerDetail
     */
    backToResultsCallback(event) {
        event.preventDefault();

        if (this.backToMap && screen.getState().small) {
            this.toggleMap(event);
        }

        const transitionEndHandler = () => {
            if (this.dealerDetail) {
                this.dealerDetail.destroy();
                this.dealerDetail = null;
            }
            this.element.removeEventListener(EVENTS.TRANSITION_END, transitionEndHandler);
        };

        this.element.addEventListener(EVENTS.TRANSITION_END, transitionEndHandler);

        this.element.classList.remove(CLASSES.SHOW_DETAIL_VIEW);

        let dealersToAdd = [];
        if (this.dealerResultsList && this.dealerResultsList.dealers && this.dealerResultsList.dealers.length) {
            dealersToAdd = this.dealerResultsList.dealers;
        } else if (this.browseByState && this.browseByState.dealers) {
            dealersToAdd = this.browseByState.dealers;
        } else if (this.LCPView && this.LCPView.dealers.length) {
            dealersToAdd = this.LCPView.dealers;
        }

        this.addDealersToMap(dealersToAdd);

        this.dealerDetailContainer.style.top = `${this.currentScrollPos}px`;
        this.setDetailTileHeight(true);
        scrollTo(this.currentScrollPos, 0);
    }

    /**
     * @method togglePreferredDealer
     * @description Toggles the preferred dealer by setting it in model
     * @param {Boolean} isPreferred - Boolean indicating if the dealer should be set as preferred
     * @param {Object} dealer - Dealer object to be set/unset as preferred
     */
    togglePreferredDealer(isPreferred, dealer) {
        this.model.setPreferredDealer(isPreferred ? dealer : null);
    }

    /**
     * @method onModalAfterOpen
     * @description Callback for modal afterOpen event
     */
    onModalAfterOpen() {
        this.searchMapModal.fitBounds();
    }

    /**
     * @method onModalBeforeClose
     * @description Callback for modal beforeClose event
     */
    onModalBeforeClose() {
        this.showMapBtn.focus();
    }

    /**
     * @method openModal
     * @description Open the Modal with the respective options by package
     * @param {Object} currentTarget the clicked opener Element
     */
    openModal({ currentTarget }) {
        if (!this.targetModalElm) {
            const targetModalId = currentTarget.getAttribute(ARIA_ATTRIBUTES.ARIA_CONTROLS);
            this.targetModalElm = document.getElementById(targetModalId);

            this.modal = new Modal(undefined, {
                modalContent: this.targetModalElm,
                sizeSmall: Modal.SIZES.FULLSCREEN,
                sizeLarge: Modal.SIZES.DEFAULT,
                callbacks: {
                    afterOpen: this.onModalAfterOpen,
                    beforeClose: this.onModalBeforeClose
                },
                theme: 'dealer-locator',
                unclosable: true
            });
        }
        this.targetModalElm.setAttribute(ARIA_ATTRIBUTES.ARIA_HIDDEN, false);
        this.modal.open({ callingContainer: currentTarget });

        // The following uses MutationObserver and sets focus to the hide map button
        // @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        const observer = new MutationObserver(() => {
            this.hideMapBtn = document.querySelector(`.${CLASSES.HIDE_BTN}`);
            if (this.hideMapBtn) {
                this.hideMapBtn.parentNode.focus();
                observer.disconnect();
            }
        });
        observer.observe(document.getElementById('js-modal-parent-node'), { childList: true, subtree: true });
    }

    /**
     * @method serviceDownHandler
     * @description Creates dealer results list with service down condition
     */
    serviceDownHandler(serviceStatus) {
        this.toggleBrowseAll(false);
        this.createDealerResultsList(serviceStatus);
        this.element.classList.remove(CLASSES.HAS_RESULT);
        this.element.classList.add(CLASSES.NO_RESULT);
        this.resetMap();
        this.preferredDealerElm.innerHTML = '';
    }

    /**
     * @method render
     * @description Renders the dealer locator plugin into container DOM element
     */
    render() {
        renderer.insert(this.element, this.containerElement);
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Utils imports
import { AuthenticationApi } from 'partials/authentication';

// Local imports
import quickRegistrationSuccessTemplate from './../templates/quickRegistrationSuccessTemplate';

/**
 * @const CLASSES
 * @description selecto for classes
 * @type {Object}
 */
const CLASSES = {
    CONTINUE: 'mbs-quick-registration__continue',
};

/**
 * @class  QuickRegistrationSucess
 * @description  show the success state after quick registration
 * @param {Object} config
 */
export default class QuickRegistrationSuccess {
    /**
     * @method constructor
     * @description setup the default state for the view
     * @param  {Object} config
     */
    constructor(config) {
        this.config = config;
        this.onCompleteCallback = this.config.onCompleteCallback;
        this.authAPI = new AuthenticationApi();
        this.cookieInfo = this.authAPI.parseQuickRegisterCookies();

        this.init();
    }

    /**
     * @method init
     * @description  init the view
     */
    init() {
        this.element = quickRegistrationSuccessTemplate(this.config, this.cookieInfo)({ getNode: true });
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description  this function get the elements for the dom
     */
    cacheDOM() {
        this.continueButton = this.element.querySelector(`.${CLASSES.CONTINUE}`);
        this.loginButton = this.element.querySelector(`.${CLASSES.LOGIN_BUTTON}`);
    }

    /**
     * @method attachEvents
     * @description  attach events to the DOM
     */
    attachEvents() {
        this.continueButton.addEventListener('click', this.onCompleteCallback);
    }

    /**
     * @method render
     * @description return the dom element to be rendered
     * @return {XML}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Utils imports
import { tokenReplace } from 'utils';

// Local imports
import dealerFormConfirmationTemplate from './../templates/dealerFormConfirmationTemplate';


/**
 * @constant defaultConfig
 * @type {Object}
 */
const defaultContent = {
    body: '',
    disclaimer: '',
    title: '',
};

/**
 * @class  DealerFormSuccess
 * @description  show the success state after dealer from submission
 * @param {Object} config
 */
export default class DealerFormConfirmation {
    /**
     * @method constructor
     * @description setup the default state for the view
     * @param  {Object} content
     * @param
     */
    constructor(content, dealer) {
        this.content = { ...defaultContent, ...content };
        this.dealer = dealer;
        this.createSuccess();
    }

    /**
     * @method createSuccess
     * @description  get the node from the template to be render
     */
    createSuccess() {
        this.element = dealerFormConfirmationTemplate({
            body: tokenReplace(this.content.body, { dealerName: this.dealer.name }),
            disclaimer: tokenReplace(this.content.disclaimer, { dealer: this.dealer.name }),
            title: this.content.title
        })({ getNode: true });
    }

    /**
     * @method render
     * @description return the dom element to be rendered
     * @return {XML}
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

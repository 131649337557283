/**
 * @constant keyboard
 * @description Collection of keyboard key values
 */
export default {
    ENTER: 13,
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9,
    ESC: 27,
    SPACE: 32
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

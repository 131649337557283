/**
 * Template for a Preferred Dealer.
 * Uses ES6 template literal to composite the markup for a Preferred Dealer
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying the preferred dealer module
 * @param content {Object} Localization Object
 * @param config {Object} Configuration Object
 */
export default (content, config) => htmlNode`
    <div class="preferred-dealer">
        ${config.featureFlags.showListHeading ? `<h3 class="wrapper preferred-dealer__heading">${content.preferredDealer}</h3>` : ''}
        <div class="preferred-dealer__dealer">
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d


import {  EVENTS } from 'Constants';
import { screen } from 'utils';
/**
 * hamburger menu
 * content intractions
 */
export default class HamBurgerMenu {
    constructor(element) {
        this.element = element;
        this.hamburgerIcon = {};
        this.hamburgerMenu = {};
        this.hamburgerMenuClose = {};
        this.hamburgerMenuOverlay = {};
        this.oldScrollY = window.scrollY;

        this.init();
    }

    /**
     * @method init
     * @description Initialize the Hamburger Menu instance
     */
    init() {
        this.cacheDom();
        this.setBindings();
        this.attachEvents();
    }


    /**
     * @method cacheDom
     * @description Cache DOM elements
     */
    cacheDom() {
        this.hamburgerIcon = this.element.querySelector('.ham-burger-menu');
        this.hamburgerMenu = this.element.querySelector('.ham-burger-menu__list');
        // console.log(this.hamburgerMenu);
        this.hamburgerMenuClose = this.element.querySelector('.ham-burger-menu__close-icon');
        this.hamburgerMenuOverlay = this.element.querySelector('.ham-burger-menu__overlay');
    }

    /**
     * @method setBindings
     * @description Binds event handlers to the instance
     */
    setBindings() {
        this.menuClick = this.menuClick.bind(this);
        this.menuClose = this.menuClose.bind(this);
        this.onScreenScroll = this.onScreenScroll.bind(this);
    }

    menuClick() {
        if (this.hamburgerMenu.classList.contains('active')) {
            const isLarge = screen.gte(screen.SIZES.LARGE);
            if (!isLarge) {
                this.hamburgerMenu.classList.remove('active');
                this.hamburgerMenuOverlay.classList.remove('active');
                this.hamburgerIcon.classList.remove('active');
                window.document.getElementsByTagName('body')[0].style.overflow = '';
            }
        } else {
            this.hamburgerMenu.classList.add('active');
            this.hamburgerMenuOverlay.classList.add('active');
            this.hamburgerIcon.classList.add('active');
            window.document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        }
    }

    menuClose() {
        this.hamburgerMenu.classList.remove('active');
        this.hamburgerMenuOverlay.classList.remove('active');
        this.hamburgerIcon.classList.remove('active');
        window.document.getElementsByTagName('body')[0].style.overflow = 'unset';
    }

    onScreenScroll() {
        if (document.body.getBoundingClientRect().top < -72) {
            this.element.querySelector('.ham-burger-menu__container').classList.add('pinned');
        } else {
            this.element.querySelector('.ham-burger-menu__container').classList.remove('pinned');
        }
        const isLarge = screen.gte(screen.SIZES.LARGE);
        if (!isLarge) {
            if (this.oldScrollY < window.scrollY) {
                this.element.querySelector('.ham-burger-menu__container').classList.add('ham-burger-menu__hide');
            } else {
                this.element.querySelector('.ham-burger-menu__container').classList.remove('ham-burger-menu__hide');
            }
        }
        this.oldScrollY = window.scrollY;
        if (isLarge) {
            // hide if we get to the footer, show when we scroll above the footer
            const footerScrollTop = document.getElementsByTagName('footer')[0].offsetTop;
            if (this.oldScrollY > footerScrollTop - 72) {
                this.element.querySelector('.ham-burger-menu__container').classList.add('hidden');
            } else {
                this.element.querySelector('.ham-burger-menu__container').classList.remove('hidden');
            }
        }
    }
    /**
     * @method attachEvents
     * @description Registers event handlers with events
     */
    attachEvents() {
        this.hamburgerIcon.addEventListener(EVENTS.CLICK, this.menuClick);
        this.hamburgerMenuClose.addEventListener(EVENTS.CLICK, this.menuClose);
        window.document.addEventListener(EVENTS.SCROLL, this.onScreenScroll);
    }


    /**
     * @method attachEvents
     * @description Registers event handlers with events
     */
    detachEvents() {
        this.hamburgerIcon.removeEventListener(EVENTS.CLICK, this.menuClick);
        this.hamburgerMenuClose.removeEventListener(EVENTS.CLICK, this.menuClose);
        window.document.removeEventListener(EVENTS.SCROLL, this.onScreenScroll);
    }




    /**
     * @method destroy
     * @description Destroys the instance by removing all event listeners
     */
    destroy() {
        this.detachEvents();
        this.element = null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { noop, generateUniqueID as ID, scrollTo, Tabbables, customEventDispatcher } from 'utils';
import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import Adapter from '../api/Adapter';

const DEFAULT_CALLBACKS = {
    beforeOpen: noop,
    afterOpen: noop,
    beforeClose: noop
};

const MODAL_ID_PREFIX = 'modal-link';

const CLASSES = {
    MODAL_CONTENT: 'modal__content',
    MODAL_CONTAINER: 'modal__container',
    MODAL_CLOSE: 'modal__close'
};

/**
 * @const KEYS
 * @description Keyboard codes used in the module for keydown events
 */
const KEYS = {
    TAB: 9
};


/**
 * Modal component
 */
export default class Modal {
    /**
     * @static ROLES
     * @description Optional roleType configuration types
     * @type {Modal.ROLES}
     */
    static ROLES = {
        ALERT: 'alertdialog',
        DIALOG: 'dialog',
    };

    /**
     * @static THEMES
     * @description Optional theme configuration types
     * @type {{DEFAULT: string, LIGHT: string}}
     */
    static THEMES = {
        DEFAULT: 'dark',
        LIGHT: 'light',
        DISABLE_OVERFLOW: 'disable-overflow',
        SIDE_PANEL: 'side-panel'
    };

    /**
     * @static SIZES
     * @description Optional size configuration types
     * @type {{
            DEFAULT: string,
            DIALOG: string,
            FULLSCREEN: string,
            FULL_OVERLAY: string,
            INSET: string
        }}
     */
    static SIZES = {
        DEFAULT: 'full-width',
        DIALOG: 'dialog',
        FULLSCREEN: 'full-screen',
        FULL_OVERLAY: 'full-overlay',
        INSET: 'inset'
    };

    /**
     * @static EVENTS
     * @description Event types for Modal
     * @type {{SCROLL_TO: string}}
     */
    static EVENTS = {
        SCROLL_TO: 'modal-scroll-to'
    };

    static PANEL_DIRECTION = {
        DEFAULT: 'right',
        LEFT: 'left',
        RIGHT: 'right'
    };

    /**
     * Creates a Modal instance
     * @param {Element} element - The element associated to the modal
     * @param {Object} [options = {}] - Options for the modal
     * @param {String} options.modalContent - Content to be populated in modal
     * @param {Object} [options.callbacks = DEFAULT_CALLBACKS] - Object with callbacks
     * @param {Function} options.callbacks.beforeOpen - A callback which is called before modal open
     * @param {Function} options.callbacks.afterOpen - A callback which is called after modal open
     * @param {Function} options.callbacks.beforeClose - A callback which is called before modal close
     * @param {String} options.roleType - The role type that the modal will display as (eg. dialog, alertdialog, etc)
     * @param {String} options.theme - Theme to apply to the modal styling
     * @param {String} options.themeModifier - Optional modifier theme to apply to the modal styling
     * @param {String} options.size - Size to apply to the modal styling
     * @param {String} options.sizeSmall - Optional size to apply to small screen
     * (if set overrides the 'size' property)
     * @param {String} options.sizeLarge - Optional size to apply to large screen
     * (if set overrides the 'size' property)
     * @param {String} options.sizeXLarge - Optional size to apply to xlarge screen
     * (if set overrides the 'size' property)
     * @param {String} options.disableSmall - Flag to disable the modal on small screens
     * @param {String} options.transitionInOut - Optional apply opacity transition on
     * open/close (large & xlarge only)
     * @param {String} options.unclosable - Optional precludes inclusion of close button
     * @param {String} options.modalTitle - Optional hidden accessibility title for the modal
     * @param {String} options.modalTitleId - Id of a child element containing the accessibility title for the modal
     */
    constructor(element, {
        modalContent = '',
        callbacks = {},
        roleType = Modal.ROLES.DIALOG,
        theme = Modal.THEMES.DEFAULT,
        themeModifier = null,
        size = Modal.SIZES.DEFAULT,
        sizeSmall = null,
        sizeLarge = null,
        sizeXLarge = null,
        disableSmall = false,
        transitionInOut = false,
        unclosable = false,
        dataAnalyticContainer = null,
        dataAnalyticTriggerClose = null,
        modalTitle = '',
        modalTitleId = null,
        panelDirection = Modal.PANEL_DIRECTION.DEFAULT,
    } = {}) {
        // Attach all properties to current instance
        Object.assign(this, {
            element,
            modalContent,
            roleType,
            theme,
            themeModifier,
            size,
            sizeSmall,
            sizeLarge,
            sizeXLarge,
            disableSmall,
            transitionInOut,
            unclosable,
            dataAnalyticContainer,
            dataAnalyticTriggerClose,
            modalTitle,
            modalTitleId,
            panelDirection,
            callbacks: {
                ...DEFAULT_CALLBACKS,
                ...callbacks
            }
        });
        this.isActive = false;

        // method aliases
        this.onClickHandler = this.onClick.bind(this);
        this.onScrollToEvent = this.onScrollToEvent.bind(this);
        this.setFirstAndLastTabbable = this.setFirstAndLastTabbable.bind(this);
        this.manageFocus = this.manageFocus.bind(this);
        this.onCloseButtonKeydown = this.onCloseButtonKeydown.bind(this);

        this.init();
    }

    /**
     * Init method
     */
    init() {
        this.ID = ID();
        if (this.element) {
            this.element.setAttribute('data-id', `${MODAL_ID_PREFIX}${this.ID}`);
        }
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.modalContentElm = document.querySelector(`.${CLASSES.MODAL_CONTENT}`);
        this.modalContainer = document.querySelector(`.${CLASSES.MODAL_CONTAINER}`);
        this.modalCloseElm = document.querySelector(`.${CLASSES.MODAL_CLOSE}`);
        this.tabbables = new Tabbables(this.modalContainer);
        this.setFirstAndLastTabbable();
    }

    /**
     * Destroy method
     */
    destroy() {
        if (this.element) {
            this.detachEvents();
        }

        // only destroy the Adapter if the Modal is active.
        // this solves scenarios when there are multiple instances of a Modal
        if (this.isActive) {
            Adapter.destroy(true);
        }
    }

    /**
     * Attach events
     */
    attachEvents() {
        if (this.element) {
            this.element.addEventListener(EVENTS.CLICK, this.onClickHandler, true);
        }
        window.addEventListener(Modal.EVENTS.SCROLL_TO, this.onScrollToEvent);
    }

    /**
     * @method attachModalEvents
     * @description Attach events after modal is created
     */
    attachModalEvents() {
        if (this.tabbables) {
            this.firstTabbable.addEventListener(EVENTS.KEYDOWN, this.manageFocus);
            this.lastTabbable.addEventListener(EVENTS.KEYDOWN, this.manageFocus);
        }
        if (this.modalCloseElm) {
            this.modalCloseElm.addEventListener(EVENTS.KEYDOWN, this.onCloseButtonKeydown);
        }
    }

    /**
     * Detach events
     */
    detachEvents() {
        if (this.element) {
            this.element.removeEventListener(EVENTS.CLICK, this.onClickHandler, true);
        }

        window.removeEventListener(Modal.EVENTS.SCROLL_TO, this.onScrollToEvent);

        if (this.tabbables) {
            this.firstTabbable.removeEventListener(EVENTS.KEYDOWN, this.manageFocus);
            this.lastTabbable.removeEventListener(EVENTS.KEYDOWN, this.manageFocus);
        }
    }

    /**
     * Calls the Adapter api to open a modal with the modalContent,
     * and apply the lifecycle callbacks
     * @param {Element} options.callingContainer - The element that triggered/was clicked for Modal to open
     * @param {String} options.callingContainerId - ID of the element that triggered/was clicked for Modal to open
     */
    open({ callingContainer = null, callingContainerId = null } = {}) {
        if (!this.isActive) {
            this.callbacks.beforeOpen.call(this);
            const panelDirection =
                (this.theme === Modal.THEMES.FULLSCREEN_PANEL || this.theme === Modal.THEMES.SIDE_PANEL) ?
                    this.panelDirection : null;

            Adapter.openModal(this.modalContent, {
                callbacks: {
                    afterOpen: this.afterOpen.bind(this),
                    beforeClose: this.beforeClose.bind(this)
                },
                roleType: this.roleType,
                theme: this.theme,
                themeModifier: this.themeModifier,
                transitionInOut: this.transitionInOut,
                unclosable: this.unclosable,
                size: this.size,
                sizeDevice: this.getSizeDeviceObject(),
                disableSmall: this.disableSmall,
                panelDirection,
                dataAnalyticContainer: this.dataAnalyticContainer,
                dataAnalyticTriggerClose: this.dataAnalyticTriggerClose,
                modalTitle: this.modalTitle,
                modalTitleId: this.modalTitleId,
                callingContainer,
                callingContainerId
            });
        }
    }

    /**
     * Calls the Adapter api to close a modal
     * @param skipTransition {Boolean} skip close transition
     * @param ignoreFocus {Boolean} Ignores focusing the callingContainer when closing
     */
    close(skipTransition = false, ignoreFocus = false) {
        if (this.isActive) {
            Adapter.closeModal(skipTransition, ignoreFocus);
        }
    }

    /**
     * Click handler for the modal instance
     * Opens the modal and passes in content and necessary callbacks to Adapter
     * NOTE: The callbacks are bound to the current instance of the Modal so that it's attributes
     * can be accessed in callback
     */
    onClick(e) {
        this.open();
        e.preventDefault();
        return this;
    }

    /**
     * @method onScrollToEvent
     * @description Callback handler for Modal.EVENTS.SCROLL_TO that validates the event when dispatched
     * and applies the scrollToElement method
     * @param event {Event} Custom Event object containing the element to scroll to
     */
    onScrollToEvent(event) {
        if (this.isActive && event.detail.element && this.modalContainer.contains(event.detail.element)) {
            this.scrollToElement(event.detail.element);
        }
    }

    /**
     * Callback to apply before a modal opens
     */
    beforeOpen() {
        this.callbacks.beforeOpen();
    }

    /**
     * @method afterOpen
     * Sets the isActive state and applies callback after the modal opens
     */
    afterOpen(modalNode) {
        this.isActive = true;
        this.callbacks.afterOpen(modalNode);
        this.cacheDOM();
        this.attachModalEvents();
    }

    /**
     * @method beforeClose
     * Sets the isActive state and applies callback before the modal closes
     */
    beforeClose() {
        this.isActive = false;
        this.callbacks.beforeClose();
    }

    /**
     * @method getActiveState
     * @method Getter for the active state of the modal
     * @return {boolean}
     */
    getActiveState() {
        return this.isActive;
    }

    /**
     * @method getSizeDeviceObject
     * @description Returns an object with the required format for the sizeDevice option,
     * in case the sizeSmall, sizeLarge or sizeXLarge props are defined
     * @return {Object} sizeDevice object or null
     */
    getSizeDeviceObject() {
        let sizeByDevice = null;

        if (this.sizeSmall || this.sizeLarge || this.sizeXLarge) {
            sizeByDevice = {
                small: this.sizeSmall || Modal.SIZES.DEFAULT,
                large: this.sizeLarge || Modal.SIZES.DEFAULT,
                xlarge: this.sizeXLarge || this.sizeLarge || Modal.SIZES.DEFAULT
            };
        }

        return sizeByDevice;
    }

    /**
     * @method scrollToElement
     * @description Scrolls the element to top of modal. Since scrolling container could either be
     * modalContent or modalContainer based on the type of modal, use both as reference to scrollTo
     * @param {HTMLElement} Element to scroll
     */
    scrollToElement(element) {
        const offset = element.offsetTop;

        if (element && offset && !isNaN(offset)) {
            scrollTo(offset, 250, 'vertical', this.modalContainer);
            scrollTo(offset, 250, 'vertical', this.modalContentElm);
        }
    }

    /**
     * @method setFirstAndLastTabbable
     * @description utility method to filter out ostensibly tabbable items that are not tabbable in the current state
     * and save the first and last for use in focus management
     */
    setFirstAndLastTabbable() {
        const tabs = this.tabbables.allTabbables.filter((item) => item.tabIndex === 0);
        this.firstTabbable = tabs[0];
        this.lastTabbable = tabs[tabs.length - 1];
    }

    /**
     * @method manageFocus
     * @description Manage the focus to prevent keyboard users from tabbing outside the modal while it's open.
     * @param e {Event} the event that triggers this code (in this case, EVENTS.KEYDOWN).
     */
    manageFocus(e) {
        if (e.keyCode === KEYS.TAB && !e.shiftKey && (e.target === this.lastTabbable)) {
            e.preventDefault();
            this.firstTabbable.focus();
        }
        if (e.keyCode === KEYS.TAB && e.shiftKey && (e.target === this.firstTabbable)) {
            this.lastTabbable.focus();
        }
    }

    /**
     * @method onCloseButtonKeydown
     * @description Handler keydown callback for close button
     * @param e {Event} the event that triggers this code (in this case, EVENTS.KEYDOWN).
     */
    onCloseButtonKeydown(e) {
        if (e.keyCode === KEYS.TAB && !e.shiftKey) {
            e.preventDefault();
            this.modalCloseElm.removeEventListener(EVENTS.KEYDOWN, this.onCloseButtonKeydown);
            customEventDispatcher.dispatchEvent(
                    customEventDispatcher.createCustomEvent(
                        CUSTOM_EVENTS.CLOSE_MODAL_BLUR
                    )
                );
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * API responsible for parsing and constructing the saved builds data
 */

// Util dependencies
import {
    formatNumber,
    time,
    serializeObject as serialize
} from 'utils';

// Partial dependencies
import { authenticationFetchApi } from 'partials/authentication';

/**
 * @const defaultOptions
 * @description Default fetch options
 */
const defaultOptions = {
    body: null,
    headers: {
        'Content-type': 'application/json',
        'Referrer-Policy': 'origin',
        Referer: window.location.href
    },
    method: 'POST'
};

/**
 * @function parseSavedBuildsInfo
 * @description Makes needed formats to saved builds
 * @param {Object} savedBuilds
 * @param {Object} content
 * @returns {Object} An object with saved builds properties
 */
function parseSavedBuildsInfo(savedBuilds, content) {
    return {
        pagination: {
            totalCount: savedBuilds.pagination.totalCount,
            requestCount: savedBuilds.pagination.reqCount
        },
        savedBuilds: savedBuilds.builds.map((savedBuildItem) => ({
            ...savedBuildItem,
            timestampFormatted: time.toRelativeTime(savedBuildItem.timestamp, content.timeDistance),
            totalCostFormatted: formatNumber.toCurrency(savedBuildItem.totalCost),
            outOfDate: savedBuildItem.year < savedBuildItem.currentByoYear
        }))
    };
}

/**
 * @function getSavedBuildsInfo
 * @description Get the response data from fetching the Saved Builds on a given profile
 * and returns the `savedBuilds` object
 * @param {String} endpoint - Service endpoint
 * @param {Object} content - Object containing content key/value props
 * @param {String} sortBy - sort by value
 * @param {Number} start - start value
 * @param {Number} count - count value
 * @param {String} emailId - (Optional) DDT email
 * @param {Boolean} isVariant - (optional) is variant page
 * @param {Object} options - (optional) Overwrites fetch parameters
 * @returns {Object} An object with savedBuilds properties
 */
function getSavedBuildsInfo(endpoint, content, sortBy, start, count, emailId = '', isVariant = false, options = {}) {
    const params = {
        start,
        count,
        sort: sortBy.data
    };
    if (isVariant) {
        const fetchOptions = { ...defaultOptions, ...options };
        params.emailId = encodeURIComponent(emailId);

        return fetch(`${endpoint}${serialize(params)}`, {
            headers: {
                ...fetchOptions.headers,
            },
            body: fetchOptions.body ? JSON.stringify(fetchOptions.body) : null,
            method: fetchOptions.method
        })
            .then((response) => response.json())
            .then((savedBuildsData) =>
                parseSavedBuildsInfo(savedBuildsData.result, content)
            )
            .catch((err) => {
                console.warn(err);
                throw err;
            });
    } else {
        return authenticationFetchApi.authenticationFetch(`${endpoint}${serialize(params)}`)
            .then((savedBuildsData) =>
                parseSavedBuildsInfo(savedBuildsData.result, content)
            );
    }
}

/**
 * @function removeSavedBuild
 * @description Deletes a selected saved build from a given profile
 * @param {String} endpoint - Service endpoint
 * @returns {Object} An object with status
 */
function removeSavedBuild(endpoint) {
    return authenticationFetchApi.authenticationFetch(endpoint);
}

/**
 * @function renameSavedBuild
 * @description Updates saved build nickname
 * @param {String} endpoint - Service endpoint
 * @returns {Object} An object with status
 */

function renameSavedBuild(endpoint) {
    return authenticationFetchApi.authenticationFetch(endpoint);
}

// Exports public api methods
export default {
    getSavedBuildsInfo,
    removeSavedBuild,
    renameSavedBuild
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { Modal } from 'partials/modal';
import { noop } from 'utils';
import VehicleSelector from './VehicleSelector';
import vehicleSelectorModalTemplate from './../templates/vehicleSelectorModalTemplate';

const CLASSES = {
    HEADING: 'vehicle-selector-modal__overlay-heading',
    DROPDOWN_CONTAINER: 'vehicle-selector-modal__dropdowns-container'
};

const defaultConfig = {
    selectControlCSSModifierClass: '',
    disableChosenVehicleView: true,
    submitCtaLabel: '',
    submitCtaClass: '',
    submitCtaCallback: noop,
    heading: '',
    callingContainer: ''
};

export default class VehicleSelectorModal {
    /**
     * @method constructor
     * @description View component for displaying a VehicleSelectorModal
     * @param config {Object} Configuration data
     */
    constructor(config = defaultConfig) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.onModalClose = this.onModalClose.bind(this);
        this.element = vehicleSelectorModalTemplate({
            heading: this.config.heading
        })({ getNode: true });
        this.cacheDOM();
    }

    /**
     * @method cacheDOM
     * @description caches DOM elements
     */
    cacheDOM() {
        this.heading = this.element.querySelector(`.${CLASSES.HEADING}`);
        this.dropdownContainer = this.element.querySelector(`.${CLASSES.DROPDOWN_CONTAINER}`);
    }

    /**
     * @method renderVehicleSelector
     * @description Renders the vehicle selector into the the dropdown container
     */
    renderVehicleSelector() {
        this.vehicleSelector = new VehicleSelector({
            selectControlCSSModifierClass: this.config.selectControlCSSModifierClass,
            disableChosenVehicleView: this.config.disableChosenVehicleView,
            submitCtaLabel: this.config.submitCtaLabel,
            submitCtaClass: this.config.submitCtaClass,
            submitCtaCallback: this.config.submitCtaCallback,
            enableYears: this.config.enableYears
        });

        this.dropdownContainer.appendChild(this.vehicleSelector.render());
    }

    /**
     * @method open
     * @description Creates an instance of Modal and opens the modal
     */
    open() {
        this.renderVehicleSelector();
        const modal = new Modal(undefined, {
            modalContent: this.element,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.DEFAULT,
            callbacks: {
                beforeClose: this.onModalClose
            }
        });

        modal.open({ callingContainer: this.config.callingContainer });
    }

    /**
     * @method onModalClose
     * @description Modal Close callback
     */
    onModalClose() {
        this.vehicleSelector.destroy();
    }

    /**
     * @method render
     * @description Render VehicleSelectorModal
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

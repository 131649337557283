import { EVENTS } from 'Constants';
import { generateUniqueID as id } from 'utils';

// Local dependencies
import InputControl from './InputControl';
import inputFileControlTemplate from './../templates/inputFileControlTemplate';

import {
    verify,
    VALIDATION_TYPE
} from './../api/inputValidator';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    ERROR_ELEMENT: 'form__input-error',
    FILE_LIST: 'form__file-input-list',
    FILE_RESET: 'form__file-input-reset',
    INPUT_ELEMENT: 'form__file-input',
    LABEL_ELEMENT: 'form__file-input-label'
};

/**
 * @function cloneFile
 * @description For a given filee, make copy and return copy
 * @param {File} file - File object to be copied
 * @returns {File} - Returns a copy of File
 */
function cloneFile(file) {
    const fileCopy = new File([file], file.name, { type: file.type });
    fileCopy.id = file.id;
    return fileCopy;
}

/**
 * Class that will set up an InputFileControl
 */
export default class InputFileControl extends InputControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputFileControl
     * @type {Object}
     * @property {Boolean} singleUpload - True if this input will allow only single file to
     * @property {String} ctaLabel - Label of the upload CTA
     * @property {Array} acceptedFileTypes - A array of MIME types that defines the file types the file input should
     * accept. This string is a comma-separated list of unique file type specifiers.
     * one file be uploaded
     * @property {Number} maxFileCount - Max number of files that can be selected. -1 if no limit.
     * @property {Number} maxFileSize - Max size of any given file allowed in bytes (1MB = 1 * 1024 * 1024 bytes)
     */
    static DEFAULT_CONFIG = {
        ...InputControl.DEFAULT_CONFIG,
        singleUpload: false,
        ctaLabel: 'Upload',
        acceptedFileTypes: '',
        maxFileCount: -1,
        maxFileSize: -1
    };

    /**
     * @static VALIDATION_TYPE
     * @description Default validation types available for an InputFileControl
     * @type {Object}
     */
    static VALIDATION_TYPE = {
        ...VALIDATION_TYPE,
        MAX_FILE_COUNT: 'maxFileCount'
    };

    /**
     * @constructor Create an InputFileControl
     */
    constructor(config = InputFileControl.DEFAULT_CONFIG) {
        super({
            ...InputFileControl.DEFAULT_CONFIG,
            ...config
        });

        this.removeFileItem = this.removeFileItem.bind(this);

        this.files = [];
        this.init();
    }

    /**
     * @method init
     * @description Initialization method. Creates the view and calls the super class's view
     */
    init() {
        this.createView(inputFileControlTemplate({
            accept: this.getConfig('acceptedFileTypes').join(','),
            analyticsTrigger: this.getConfig('analyticsTrigger'),
            ctaLabel: this.getConfig('ctaLabel'),
            id: this.getConfig('id'),
            inputClassNames: this.getConfig('inputClassNames'),
            labelText: this.getConfig('labelText'),
            required: this.getConfig('required')
        })({ getNode: true }));

        super.init();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM element needed for this component
     */
    cacheDOM() {
        super.cacheDOM();
        this.labelElm = this.getInput().querySelector(`.${CLASSES.LABEL_ELEMENT}`);
        this.setInputElement(this.getInput().querySelector(`.${CLASSES.INPUT_ELEMENT}`));
        this.fileListElm = this.getInput().querySelector(`.${CLASSES.FILE_LIST}`);
        this.errorElement = this.getInput().querySelector(`.${CLASSES.ERROR_ELEMENT}`);
    }

    /**
     * @method attachEvents
     * @description Attachs the events for the file control
     */
    attachEvents() {
        super.attachEvents();
        this.fileListElm.addEventListener(EVENTS.CLICK, this.removeFileItem);
    }

    /**
     * @method detachEvents
     * @description Detaches the events for the file control
     */
    detachEvents() {
        super.detachEvents();
        this.fileListElm.removeEventListener(EVENTS.CLICK, this.removeFileItem);
    }

    /**
     * @method isValid
     * @description Returns the valid attribute
     * @returns {Boolean} true if valid
     */
    isValid() {
        return this.files.every((file) => file.isValid);
    }

    /**
     * @method validate
     * @description Checks the validation flag of the input and triggers error accordingly
     * @param {boolean} displayError - Flag that can be used to not show error message
     */
    validate(displayError = true) {
        const valid = this.isValid();

        if (displayError || (!displayError && valid)) {
            this.setErrorStatus(!valid, this.errorMessage);
        }

        return valid;
    }

    /**
     * @method validateFile
     * @description Check validation and sets the `valid` and `errorMessage` state based
     * on the input values validity
     * @param {File} file - File to be validated
     */
    validateFile(file) {
        let valid = true;
        this.errorMessage = '';

        // loop through all validation rules
        if (file) {
            [].slice.call(this.getConfig('validation')).map((validationType) => {
                if (valid && !verify(
                    file,
                    validationType.type,
                    this.config,
                    validationType.config
                )) {
                    valid = false;
                    this.errorMessage = validationType.errorMessage;
                }

                if (validationType.type === InputFileControl.VALIDATION_TYPE.MAX_FILE_COUNT) {
                    const isValidFileCount = (validationType.config.maxFileCount !== -1 &&
                        !isNaN(validationType.config.maxFileCount)) ?
                            (this.files.length < validationType.config.maxFileCount) : true;

                    if (!isValidFileCount) {
                        valid = false;
                        this.errorMessage = validationType.errorMessage;
                    }
                }

                return validationType;
            });
        }

        this.setErrorStatus(!valid, this.errorMessage);

        return valid;
    }

    /**
     * @method onChange
     * @description The on change handler for input file component
     */
    onChange(e) {
        const file = [].slice.call(this.getInputElement().files)[0];
        const isValid = this.validateFile(file);

        // Reset the file associated with input control to allow users from
        // uploading same document if they remove file (clicking x) accidently
        this.getInputElement().value = '';

        if (!isValid) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }

        file.id = id();
        file.isValid = true;

        this.files.push(file);
        this.renderFileList();
        this.config.onChangeCallback(this.files.map(cloneFile), e);
        this.dispatchChange();

        return null;
    }

    /**
     * @method removeFileItem
     * @description
     */
    removeFileItem(event) {
        const targetElm = event.target;

        if (targetElm.matches(`.${CLASSES.FILE_RESET}`)) {
            const fileItemElm = this.fileListElm.querySelector(`[data-file-item=${targetElm.dataset.fileId}]`);

            if (fileItemElm) {
                fileItemElm.remove();
            }

            const fileIndex = this.files.findIndex((file) => file.id === targetElm.dataset.fileId);

            if (fileIndex !== -1) {
                this.files.splice(fileIndex, 1);
            }

            this.config.onChangeCallback(this.files.map(cloneFile), event);
        }
    }

    /**
     * @method renderFileList
     * @description Renders the list of file names into the DOM for user interation
     */
    renderFileList() {
        const fileElmList = this.files.map((file) => `
            <div class="form__file-input-list-item" data-file-item="${file.id}">
                <span class="form__file-input-list-item-name">
                    ${file.name}
                </span>
                <button class="form__input-reset form__file-input-reset" tabindex="-1" aria-label="Delete" type="button" data-file-id="${file.id}">
                    <span class="offscreen">Delete</span>
                </button>
            </div>
        `);

        this.fileListElm.innerHTML = fileElmList.join('');
    }

    /**
     * @method getFiles
     * @description Gets the list of files associated with this input component
     */
    getFiles() {
        return this.files;
    }

    /**
     * @method setFiles
     * @description Sets the files associated with this input and renders the file list container
     */
    setFiles(files) {
        this.files = files;
        this.renderFileList();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * @function
 * @name renderAMGIcons
 * @description render AMG Icons
 * @param {Object} dealer object
 * @return {String} template string
 */
const renderAMGIcons = (dealer, dealerClass) => {
    let str = '';
    if (dealer.isAmg) {
        str += `<i class="${dealerClass} icon-AMG_logo-performance-center"></i>`;
    }
    if (dealer.isAmgElite) {
        str += `<i class="${dealerClass} icon-AMG_logo-performance-center-elite"></i>`;
    }
    return str;
};

/**
 * @function
 * @name renderCTAs
 * @description render CTAs if not On Display
 * @param {Object} variant boolean
 * @param {Object} dealer object
 * @param {Object} content object
 * @return {String} template string
 */
const renderCTAs = (variant, dealer, content) => {
    if (variant.isOnDisplay) {
        return '';
    } else if (variant.isDealer || variant.isWheelstand) {
        return `<ul class="dealer-info__link-list">
            <li class="dealer-info__link-list-item">
                <a class="dealer-info__preferred-dealer-link--make link link_secondary link_bold_sub" href="mb-request://localStorageDidChange?key=MB_USER_PROFILE" data-analytics-trigger="cta" data-preferred-dealer-link>${content.makePreferDealer}<span class="icon-mb icon-preferred-dealer dealer-info__preferred-dealer-icon"></span></a>
                <a class="dealer-info__preferred-dealer-link--remove link link_secondary" href="#" data-analytics-trigger="cta" data-remove-preferred-dealer-link>${content.removePreferDealer}</a>
            </li>
            </ul>`;
    }

    return `<a class="link link_in-text dealer-info__inventory-link--collapsed ${!dealer.hasInventory ? 'hide' : ''}" href="${dealer.inventoryUrl}" data-analytics-trigger="cta-inventory">
                ${content.viewInventory}
        </a>
        <a class="button button_primary dealer-info__inventory-link--expanded ${!dealer.hasInventory ? 'hide' : ''}" href="${dealer.inventoryUrl}" data-analytics-trigger="cta-inventory">
                ${content.viewInventory}
        </a>
        <ul class="dealer-info__link-list">
            <li class="dealer-info__link-list-item">
                <a class="dealer-info__preferred-dealer-link--make link link_secondary link_bold_sub" href="#" data-analytics-trigger="cta" data-preferred-dealer-link>${content.makePreferDealer}<span class="icon-mb icon-preferred-dealer dealer-info__preferred-dealer-icon"></span></a>
                <a class="dealer-info__preferred-dealer-link--remove link link_secondary" href="#" data-analytics-trigger="cta" data-remove-preferred-dealer-link>${content.removePreferDealer}</a>
            </li>
            <li class="dealer-info__link-list-item">
                <a class="link link_secondary" href="${dealer.directionsLink}" target="_blank" rel="noopener" data-analytics-trigger="cta">
                    ${content.getDirections}
                </a>
            </li>
            <li class="${dealer.scheduleServiceUrl === 'NO_SCHEDULE_SERVICE_URL' ? 'hideLink' : ''} dealer-info__link-list-item">
                <a  class="link link_secondary" href="${dealer.scheduleServiceUrl}" target="_blank" rel="noopener" data-analytics-trigger="cta">
                    ${content.scheduleService}
                </a>
            </li>
        </ul>`;
};

/**
 * @function
 * @name renderDealerName
 * @description render dealer name as a link if not On Display
 * @param {Object} variant boolean
 * @param {Object} dealer object
 * @return {String} template string
 */
const renderDealerName = (variant, dealer) => {
    if (variant.isOnDisplay || variant.isDealer || variant.isWheelstand) {
        return `<span class="dealer-info__dealer-name link link_bold">${dealer.name}</span>`;
    }
    return `<a class="dealer-info__dealer-name link link_bold" href="${dealer.url}" target="_blank" rel="noopener" data-analytics-trigger="cta">${dealer.name}</a>`;
};

/**
 * @function
 * @name renderDealerPhoneNumber
 * @description render dealer phone number as a link if not On Display
 * @param {String} variant boolean
 * @param {Object} dealer object
 * @return {String} template string
 */
const renderDealerPhoneNumber = (variant, dealer) => {
    if (variant.isOnDisplay || variant.isDealer || variant.isWheelstand) {
        return `<span class="dealer-info__phone-number">${dealer.phoneFormatted}</span>`;
    }
    return `<a href="tel:${dealer.phone}" class="dealer-info__phone-number" data-analytics-trigger="call-dealer">${dealer.phoneFormatted}</a>`;
};

/**
 * Template for a Dealer view.
 * Uses ES6 template literal to composite the markup for a Dealer module
 */
export default (dealer, content, index, isPreferred, variant = {}) => htmlNode `
    <div class="dealer-info" data-index="${index}" data-selected-dealer="false" data-analytics-container="{ dealer-card: '${dealer.name}', preferred: ${isPreferred}}">
        <h5 class="dealer-info__preferred-dealer__small-heading">${content.yourPreferredDealer}</h5>
        <address class="dealer-info__address-container-dealer" tabindex="0">
            <div>
                ${renderDealerName(variant, dealer)}
                <span class="dealer-info__dealer-distance">${dealer.distance}</span>
            </div>
            <div class="dealer-info__address dealer-info__address--street">${dealer.address}</div>
            <div class="dealer-info__address dealer-info__address--main">${dealer.city}, ${dealer.state}, ${dealer.zip}</div>
            <div>
                ${renderDealerPhoneNumber(variant, dealer)}
            </div>
            ${renderAMGIcons(dealer, 'dealer-info__icon')}
        </address>
        ${renderCTAs(variant, dealer, content)}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

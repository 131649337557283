/**
 * @description types of vehicle conditions
 * @type {{
 *   NEW: [string]
 *   CERTIFIED: [string]
 *   USED: [string]
 * }}
 */
export default {
    NEW: 'New',
    CERTIFIED: 'Certified',
    USED: 'Used'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { noop } from 'utils';
/**
 * @const RESTRICTION_TYPE
 * @description Collection of constant values for restriction types
 */
export const RESTRICTION_TYPE = {
    DIGIT: 'digit',
    NUMERIC_FORMAT: 'numeric_format',
    NUMERIC: 'numeric',
    EMAIL_SPECIAL_CHARS: 'emailSpecialChars',
    PHONE_NUMBER: 'phoneNumberCharacters',
    ALPHA_NUMERIC: 'alphaNumeric',
    DATE: 'date',
};

/**
 * API for restricting value patterns
 */
/**
 * @method getCharKey
 * @description Parses the character value from a keyboard event
 * @param event {KeyboardEvent}
 * @return {string} Character pressed
 */
function getCharKey(event) {
    return event.key || String.fromCharCode(event.keyCode);
}

/**
 * @method isSpecialCharacter
 * @description Checks to see if a character code is within a set of special character codes
 * @param event {KeyboardEvent} Keyboard event
 * @param charKey {String} Character code from event
 * @returns {Boolean} True if charKey is a special character
 */
function isSpecialCharacter(event, charKey) {
    const {
        altKey,
        ctrlKey,
        metaKey,
    } = event;

    const isFuncKey = altKey || ctrlKey || metaKey;
    const isActionKey = charKey === 'Enter' || charKey === 'Delete' || charKey === 'Clear' || charKey === 'Backspace' || charKey === 'Tab';
    const isArrowKey = charKey.indexOf('Arrow') === 0;

    return (
        isFuncKey ||
        isActionKey ||
        isArrowKey
    );
}

/**
 * @method restrictNumber
 * @description Tests if the character is a pure digit without commas or decimals
 * @param event {KeyboardEvent} Keyboard event
 * @returns {*} Returns false if the character is a digit; otherwise prevents the event
 */
function restrictDigit(event) {
    const char = getCharKey(event);
    const isAllowedCharacter = /[0-9]/.test(char);
    const isSpecialChar = isSpecialCharacter(event, char);

    return (
        !isSpecialChar &&
        !isAllowedCharacter
    );
}

/**
 * @method restrictNumber
 * @description Tests if the character is a number that include commas and decimals
 * @param event {KeyboardEvent} Keyboard event
 * @returns {*} Returns false if the character is a formatted number; otherwise prevents the event
 */
function restrictNumberFormat(event) {
    const char = getCharKey(event);
    const isAllowedCharacter = /[0-9,.]/.test(char);
    const isSpecialChar = isSpecialCharacter(event, char);

    return (
        !isSpecialChar &&
        !isAllowedCharacter
    );
}

/**
 * @method restrictNumber
 * @description Tests if the character is a number
 * @param event {KeyboardEvent} Keyboard event
 * @returns {*} Returns false if the character is a number; otherwise prevents the event
 */
function restrictNumber(event) {
    const char = getCharKey(event);
    return /[0-9]/.test(char);
}

/**
 * @method restrictAlphaNumeric
 * @description Tests if the character is alpha-numeric or a hyphen
 * @param event {KeyboardEvent} Keyboard event
 * @returns {*} Returns false if the character is alpha-numeric or a hyphen; otherwise prevents the event
 */
function restrictAlphaNumeric(event) {
    const char = getCharKey(event);
    return /[^a-zA-Z0-9-]/.test(char);
}

/**
 * @method restrictFirstDigit
 * @description Tests if the first digit is '0' or '1'
 * @param event {KeyboardEvent} Keyboard event
 * @returns {*} Returns false if first digit is '0' or '1'; otherwise prevents the event
 */
function restrictFirstDigit(event, value) {
    const char = getCharKey(event);
    if (value === undefined || value === null) { value = ''; }
    const regex = (value.length === 0) ? /^(?:[0-9]\*|[2-9])$/g : /[0-9]/;
    return regex.test(char);
}

/**
 * @method restrictEmailSpecialCharacter
 * @description Tests the special characters that are not allowed for email
 * @param event {KeyboardEvent} Keyboard event
 * @returns {Boolean} Returns true if any of the restricted characters are entered
 */
function restrictEmailSpecialCharacter(event) {
    const char = getCharKey(event);

    return /[&*/%'"]/.test(char);
}

/**
 * @method restrictPhoneSpecialCharacter
 * @description Checks the character code to verify that the it is part of the allowed list of
 * characters +(0-9)
 * @param event {KeyboardEvent} Keyboard event
 * @returns {Boolean} True if any non allowed characters are entered
 */
function restrictPhoneSpecialCharacter(event) {
    const {
        which,
        target: { value, selectionStart }
    } = event;

    const charKey = getCharKey(event);
    const isSpecialChar = isSpecialCharacter(event, charKey);
    const isPlusKey = which === 187 || charKey === '+';

    return (
        !(selectionStart === 0 && isPlusKey && value.indexOf('+') === -1) &&
        !restrictFirstDigit(event, value) &&
        !isSpecialChar
    );
}

/**
 * @method restrictDate
 * @description Checks the character code to verify that the it is part of the allowed list of
 * characters (0-9)/
 * @param event {KeyboardEvent} Keyboard event
 * @returns {Boolean} True if any non allowed characters are entered
 */
function restrictDate(event) {
    const charKey = getCharKey(event);
    const isAllowedCharacter = /[0-9/]/.test(charKey);
    const isSpecialChar = isSpecialCharacter(event, charKey);

    return (
        !isAllowedCharacter &&
        !isSpecialChar
    );
}

/**
 * @method getRestriction
 * @description Get value of restricted characters for restriction type
 * @param restrictionType {String} Restriction type
 * @returns {Function} Function which does the restriction
 */
function getRestriction(restrictionType) {
    switch (restrictionType) {
    case RESTRICTION_TYPE.DIGIT:
        return restrictDigit;

    case RESTRICTION_TYPE.NUMERIC_FORMAT:
        return restrictNumberFormat;

    case RESTRICTION_TYPE.NUMERIC:
        return restrictNumber;

    case RESTRICTION_TYPE.EMAIL_SPECIAL_CHARS:
        return restrictEmailSpecialCharacter;

    case RESTRICTION_TYPE.PHONE_NUMBER:
        return restrictPhoneSpecialCharacter;

    case RESTRICTION_TYPE.ALPHA_NUMERIC:
        return restrictAlphaNumeric;

    case RESTRICTION_TYPE.DATE:
        return restrictDate;

    default:
        return noop;
    }
}

/**
 * @method restrict
 * @description Tests whether char should be restricted or not
 * @param event {Object} Keyboard event
 * @param restrictionType {String} Restriction type
 * @returns {Boolean} True if char should be restricted
 */
export function restrict(event, restrictionType) {
    if (event instanceof KeyboardEvent) {
        return getRestriction(restrictionType).call(null, event);
    }

    return false;
}

/**
 * export public api
 */
export default {
    RESTRICTION_TYPE,
    restrict
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// util dependencies
import {
    cookie,
    serializeObject,
    tokenReplace
} from 'utils';

// constant dependencies
import { COOKIES } from 'Constants';

// partial dependencies
import {
    inventorySearchVehicleApi,
    inventorySearchVehiclesApi
} from 'partials/inventory-search';

/**
 * @function getAnalyticsContainer
 * @description Constructs an analytics container for the component based on the vehicle data
 * @param bladeName {String} Blade value
 * @param vehicle {Object} Vehicle data
 * @param vin {String} Vehicle vin
 * @param dealer {[Object]} Optional dealer object
 * @return {string} Stringified json object
 */
function getAnalyticsContainer(bladeName, vehicle, vin, dealer) {
    const obj = {
        blade: bladeName,
        vehicle: {
            body: vehicle.bodyStyleId,
            class: vehicle.classId,
            exteriorMetaColor: vehicle.exteriorMetaColor,
            interiorMetaColor: vehicle.interiorMetaColor,
            mileage: vehicle.mileage ? vehicle.mileage.value : null,
            reservable: !!vehicle.reservable,
            model: vehicle.modelId,
            vin,
            year: vehicle.year
        },
        pricingDetails: {
            price: vehicle.msrp.price,
            discount: vehicle.reservable ? vehicle.msrp.price - vehicle.reservePrice.price : null,
            totalOnlinePrice: vehicle.reservable ? vehicle.reservePrice.price : vehicle.msrp.price
        }
    };

    if (dealer) {
        obj.dealer = {
            id: dealer.dealerId,
            name: dealer.name
        };
    }

    return JSON.stringify(obj);
}

/**
 * @function getRecentlyViewedVehicleCookie
 * @description get the cookie with the recently viewed vehicle properties in it
 */
function getRecentlyViewedVehicleCookie() {
    const recentlyViewedCookie = cookie.getCookie(COOKIES.RECENTLY_VIEWED_RESERVE);

    if (recentlyViewedCookie) {
        return JSON.parse(recentlyViewedCookie);
    }
    return recentlyViewedCookie;
}

/**
 * @function updateVinUnavailable
 * @description update the recently viewed cookie with `isAvailable` set to false and
 * an expiration of two days in the future
 */
function updateVinUnavailable() {
    const currentCookieData = getRecentlyViewedVehicleCookie();

    let expires = null;
    if (currentCookieData.twoDayExp) {
        expires = new Date(currentCookieData.twoDayExp);
    } else {
        expires = new Date();
        expires.setDate(expires.getDate() + 2);

        currentCookieData.twoDayExp = expires;
    }

    const newCookieData = JSON.stringify({
        ...currentCookieData,
        isAvailable: false
    });

    cookie.setCookie({
        expires,
        name: COOKIES.RECENTLY_VIEWED_RESERVE,
        value: newCookieData
    });
}

/**
 * @function getRecentSimilarVehicles
 * @desc get the full vehicle data for similar vehicles to the user's recently viewed vehicle
 * @param config {Object} configuration object with endpoints and other info needed by getCPOVehicles search API
 * @param filters {Object} user's filter information included in search for vehicles
 * @param sortBy {String} sort preference
 * @param includeAllPreOwned {Boolean} flag to include all pre-owned vehicles
 * @param includeReserveOnly {Boolean} flag to include reservable only vehicles
 * @param imagePath {Object} fallback image info in case vehicle info is not provided
 * @return {Promise<any>}
 */
function getRecentSimilarVehicles(
    config,
    filters,
    sortBy,
    includeAllPreOwned,
    includeReserveOnly,
    imagePath
) {
    const vehicleCookie = getRecentlyViewedVehicleCookie();

    if (vehicleCookie) {
        const modelInfo = {
            bodystyle: vehicleCookie.bodyStyleId,
            id: vehicleCookie.model,
            vin: vehicleCookie.vin,
        };
        const locationDistance = {
            distance: 'ANY',
            location: {
                type: 'zip',
                value: vehicleCookie.zip
            }
        };
        return inventorySearchVehiclesApi.getCPOVehicles(
            config,
            locationDistance,
            filters,
            modelInfo,
            sortBy,
            includeAllPreOwned,
            includeReserveOnly,
            imagePath
        ).then(
            (vehicles) => ({
                vehicles,
                recentVin: vehicleCookie.vin,
                similarVehicleName: vehicleCookie.vehicleName
            })
        );
    }

    return Promise.reject('Recently viewed vehicle not available');
}

/**
 * @function getRecentlyViewedVehicle
 * @description get the full vehicle data for the user's recently viewed vehicle
 * @param {Object} config configuration object used by the inventorySearchVehicleApi
 * @param {Object} content content object used by inventorySearchApi vehicle parser
 * @param {Object} imagePath Alternate image path in case on is not provided
 * @param {Object} fromCPOInventory indicator that determines if we reset the expiration on the cookie
 */
function getRecentlyViewedVehicle(config, content, imagePath, fromCPOInventory = false) {
    const vehicleCookie = getRecentlyViewedVehicleCookie();

    if (vehicleCookie) {
        return inventorySearchVehicleApi.getCPOVehicle(
            config,
            vehicleCookie.dealerId,
            vehicleCookie.vin,
            content,
            imagePath
        )
        .then((vehicle) => ({
            ...vehicle,
            exteriorBaseImage: vehicle.images[0] ?
                vehicle.images[0] : vehicle.fallbackImage,
            displayReservePrice: true

        }))
        .catch(() => {
            if (fromCPOInventory) {
                updateVinUnavailable();
            }
            console.info('Error fetching the recently view vehicle.');
        });
    }

    return Promise.reject('Recently viewed vehicle not available');
}

/**
 * @function setRecentlyViewedVehicle
 * @description sets a cookie that holds the most recently viewed vehicle in the commerce flow
 * @param {Object} vehicleData all the data to be stored in the cookie
 * @param {String} vehicleData.bodyStyleId vehicle body style
 * @param {String} vehicleData.dealerId vehicle dealer id
 * @param {Boolean} vehicleData.isAvailable indicator for if the vehicle is sold out
 * @param {String} vehicleData.model vehicle model id
 * @param {string} vehicleData.vehicleName vehicle name
 * @param {String} vehicleData.vin vehicle vin
 * @param {String} vehicleData.zip user's last searched zip code
 */
function setRecentlyViewedVehicle(vehicleData) {
    const vehicleDataString = JSON.stringify({
        bodyStyleId: vehicleData.bodyStyleId,
        dealerId: vehicleData.dealerId,
        isAvailable: true,
        isNew: vehicleData.isNew || false,
        model: vehicleData.model,
        twoDayExp: '',
        vehicleName: vehicleData.vehicleName,
        vin: vehicleData.vin,
        zip: vehicleData.zip || ''
    });
    const expires = new Date();
    const expirationYear = expires.getFullYear() + 5;
    expires.setFullYear(expirationYear);

    cookie.setCookie({
        expires,
        name: COOKIES.RECENTLY_VIEWED_RESERVE,
        value: vehicleDataString
    });
}

/**
 * @method getReservePath
 * @description Constructs a vehicle reservation path based on the props (vehicle info) and location values
 * @param pathConfig {Object} Values for `endpoint` and `suffixPath`å
 * @param props {Object} Params object with values for dealerName, dealerId, class, bodyStyle, model and vin
 * @param location [{Object}] Object with `type` and `value` props to set the location params to the url
 * @return {string} endpoint to reservation flow
 */
function getReservePath(pathConfig, props, location) {
    const {
        path,
        suffixPath
    } = pathConfig;

    // normalize the endpoint and add the suffix for variants
    let endpoint = path.replace(suffixPath, '').replace(/\.html/g, '');
    endpoint = `${endpoint}/{dealerName}/{dealerId}/{class}/{bodyStyle}/{model}/{vin}${suffixPath}`;
    endpoint = tokenReplace(endpoint, props);

    if (location) {
        endpoint = `${endpoint}${serializeObject({
            [location.type]: location.value
        })}`;
    }

    return endpoint;
}

/**
 * @method goToReserve
 * @description Redirects the user to the reserve page and injects the associated route params
 * @param pathConfig {Object} Values for `endpoint` and `suffixPath`å
 * @param props {Object} Params object with values for dealerName, dealerId, class, bodyStyle, model and vin
 * @param location [{Object}] Object with `type` and `value` props to set the location params to the url
 */
function goToReserve(pathConfig, props, location) {
    window.location =  getReservePath(pathConfig, props, location);
}

/**
 * @function deleteRecentlyViewedVehicle
 * @description delete recently viewed cookie
 */
function deleteRecentlyViewedVehicle() {
    cookie.deleteCookie({ name: COOKIES.RECENTLY_VIEWED_RESERVE });
}

export default {
    deleteRecentlyViewedVehicle,
    getAnalyticsContainer,
    getRecentlyViewedVehicle,
    getRecentlyViewedVehicleCookie,
    getRecentSimilarVehicles,
    getReservePath,
    goToReserve,
    setRecentlyViewedVehicle,
    updateVinUnavailable
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

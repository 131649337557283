// Util dependencies
import { htmlNode as html, generateUniqueID as ID } from 'utils';

/**
 * @const id {String} Value of attribute id
 */
const id = ID();

/**
 * @description Template for View More CTA
 * Uses ES6 template literal to composite the markup for a View More CTA
 */
export default (content, options) => html`
    <div class="view-more-drawer">
        <div class="view-more-drawer__content-container">
            <div class="view-more-drawer__content" id="view-more-drawer-${id}">
                ${content}
            </div>
        </div>
        <button
            class="view-more-drawer__cta"
            aria-expanded="false"
            aria-controls="view-more-drawer-${id}"
            data-analytics-trigger="cta">
            <span class="view-more-drawer__cta-copy">${options.viewMoreCopy}</span>
            <span class="icon-mb icon-caret-down-thin"></span>
        </button>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * An api for creating an inputTextControlParser component from specially tagged DOM element
 *
 * Example markup for creating an checkbox from html tags:
 *
 * <div data-input-text-control
 *      data-id="[String]"
 *      data-required="[Boolean]"
 *      data-validate="[Boolean]"
 *      data-focused="[Boolean]"
 *      data-allow-reset="[Boolean]"
 *      data-data-attributes="[String]"
 *      data-input-class-names="[String]"
 *      data-label-class-names="[String]"
 *      data-validation="[Array]" // an array of object with:
 *                                //{ type: VALIDATION_TYPE, errorMessage: string }
 *      data-restrictions="[String]"
 *      data-value-text="[String]"
 *      data-label-text="[String]"
 *      data-label-icon-class="[String]">
 *      maxlength="[Number]"
 *     ...
 * </div>
 */

// Local dependencies
import InputTextControl from './../views/InputTextControl';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{INPUT_TEXT_CONTROL: string}}
 */
const ATTRIBUTES = {
    INPUT_TEXT_CONTROL: 'data-input-text-control'
};

/**
 * @method findInputTextControls
 * @description Parses a DOM element for InputTextControl tags and returns array of elements
 * @param element {Element} The element to parse for inputTextControl tags
 * @returns {NodeList} List of elements with ATTRIBUTES.INPUT_TEXT_CONTROL tag
 */
export function findInputTextControls(element) {
    return element.querySelectorAll(`[${ATTRIBUTES.INPUT_TEXT_CONTROL}]`);
}

/**
 * @method parseValidation
 * @description Parses a validation JS array string from the DOM and converts it
 * to a JS validation object
 * @param validation {String} String array of of objects with {value, errorMessage} props
 * @return {Array|null}
 */
function parseValidation(validation) {
    if (validation) {
        const jsonValidation = validation.replace(/'/g, '"');
        return JSON.parse(jsonValidation);
    }

    return null;
}

/**
 * @method createInputTextControl
 * @description Parses a DOM element for inputTextControl data attributes
 * and renders an inputTextControl for each instance
 * @param element {Element} The element with ATTRIBUTES.INPUT_TEXT_CONTROL tag
 */
export function createInputTextControl(element) {
    if (!element) {
        throw new TypeError('createInputTextControl: "element" is null or not defined');
    }

    const textInputData = element.dataset;

    const config = {};

    config.type = 'text';

    if (textInputData.id) {
        config.id = textInputData.id;
    }

    if (textInputData.required) {
        config.required = textInputData.required;
    }

    if (textInputData.validate) {
        config.validate = !!textInputData.validate;
    }

    if (textInputData.allowReset) {
        config.allowReset = !!textInputData.allowReset;
    }

    if (textInputData.focused) {
        config.focused = true;
    }

    if (textInputData.dataAttributes) {
        config.dataAttributes = textInputData.dataAttributes.split(',');
    }

    if (textInputData.inputClassNames) {
        config.inputClassNames = textInputData.inputClassNames.split(',');
    }

    if (textInputData.labelClassNames) {
        config.labelClassNames = textInputData.labelClassNames.split(',');
    }

    if (textInputData.validation) {
        config.validation = parseValidation(textInputData.validation);
    }

    if (textInputData.restrictions) {
        config.restrictions = textInputData.restrictions.split(',');
    }

    if (textInputData.errorMessage) {
        config.errorMessage = textInputData.errorMessage;
    }

    if (textInputData.valueText) {
        config.valueText = textInputData.valueText;
    }

    if (textInputData.labelText) {
        config.labelText = textInputData.labelText;
    }

    if (textInputData.labelIconClass) {
        config.labelIconClass = textInputData.labelIconClass;
    }

    if (element.maxLength) {
        config.maxLength = element.maxLength;
    }

    const textInput = new InputTextControl(config);

    element.parentNode.replaceChild(textInput.render(), element);
}

/**
 * export public api
 */
export default {
    findInputTextControls,
    createInputTextControl
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * PeekABooHeader Module
 * Has methods related to composition of the header module
 */
// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { throttle, screen, scrollTo } from 'utils';

import SpecialityHeader from './SpecialityHeader';

/**
 * @const THROTTLE_WAIT
 * @description Throttle threshold for scroll
 */
const THROTTLE_WAIT = 300;

/**
 * @const SCROLL_THRESHOLD
 * @description Threshold to hide menu after scrolling
 */
const SCROLL_THRESHOLD = 300;

/**
 * @const ARIA_ATTRIBUTES
 * @description constant for the attribute used for accessibility purposes
 */
const ARIA_ATTRIBUTES = {
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_EXPANDED: 'aria-expanded'
};

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    SHOW_NAV_BTN: 'global-header__show-nav-btn',
    SKIP_NAV: 'top-bar__skip-nav',
    HAMBURGER_BUTTON: 'top-bar__hamburger-button'
};

export default class PeekABooHeader extends SpecialityHeader {
    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onPageScroll = throttle(this.onPageScroll.bind(this), THROTTLE_WAIT);
        this.onGoToNavClick = this.onGoToNavClick.bind(this);
        this.onHamburgerBtnClick = this.onHamburgerBtnClick.bind(this);
        super.setBindings();
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.goToNavBtnElm = document.querySelector(`.${CLASSES.SHOW_NAV_BTN}`);
        this.skipNavBtnElm = this.element.querySelector(`.${CLASSES.SKIP_NAV}`);
        this.hamburgerBtnElm = this.element.querySelector(`.${CLASSES.HAMBURGER_BUTTON}`);
        super.cacheDOMElements();
    }

    /**
     * @method attachEvents
     * @description Adds an event listener and callback for when the window is scrolled
     */
    attachEvents() {
        window.addEventListener(EVENTS.SCROLL, this.onPageScroll);
        this.goToNavBtnElm.addEventListener(EVENTS.FOCUS, this.onGoToNavFocus);
        this.goToNavBtnElm.addEventListener(EVENTS.CLICK, this.onGoToNavClick);
        this.hamburgerBtnElm.addEventListener(EVENTS.CLICK, this.onHamburgerBtnClick);
        screen.addResizeListener(this.onScreenResize.bind(this));
        super.attachEvents();
    }

    /**
     * @method detachEvents
     * @description Removes the event listener and callback for when the window is scrolled
     */
    detachEvents() {
        window.removeEventListener(EVENTS.SCROLL, this.onPageScroll);
        this.goToNavBtnElm.removeEventListener(EVENTS.FOCUS, this.onGoToNavFocus);
        this.goToNavBtnElm.removeEventListener(EVENTS.CLICK, this.onGoToNavClick);
        this.hamburgerBtnElm.removeEventListener(EVENTS.CLICK, this.onHamburgerBtnClick);
        screen.removeResizeListener(this.onScreenResize.bind(this));
    }

    /**
     * @method afterInit
     * @description to called after init method
     */
    afterInit() {
        this.goToNavBtnElm.innerText = this.localization.goToNav;
        super.afterInit();
    }

    /**
     * @method onPageScroll
     * @description dock nav when scrolling down
     *              undock nav when scrolling up
     */
    onPageScroll() {
        const isExpanded = this.hamburgerBtnElm.getAttribute(ARIA_ATTRIBUTES.ARIA_EXPANDED) === 'true';
        if (isExpanded) {
            return;
        }
        const { scrollY, pageYOffset } = window;
        const { isVisible, lastScrollPosition } = this.getState();
        const currentScrollPosition = scrollY || pageYOffset;

        const newDockedState = currentScrollPosition > lastScrollPosition;
        if (!isVisible && currentScrollPosition < SCROLL_THRESHOLD) {
            this.updateMenuVisibility(false);
        } else if (isVisible !== newDockedState) {
            this.updateMenuVisibility(newDockedState);
        }

        // update scroll position for future comparisons
        this.updateState({ lastScrollPosition: currentScrollPosition });
    }

    /**
     * @method updateMenuVisibility
     * @description updates the visibilty of the nav as well as the aria attribute
     * @param dockedState {Bool}
     */
    updateMenuVisibility(dockedState) {
        this.setDockingState(dockedState);
        this.element.setAttribute(ARIA_ATTRIBUTES.ARIA_HIDDEN, dockedState);
        this.updateState({ isVisible: dockedState });
    }

    /**
     * @method onGoToNavFocus
     * @description helper function, ensures page is scrolled to top on button focus
     */
    onGoToNavFocus() {
        if (screen.gte(screen.SIZES.XLARGE)) {
            scrollTo(0, 0);
        }
    }

    /**
     * @method onGoToNavClick
     * @description helper function that shows the nav and goes to Skip nav CTA
     */
    onGoToNavClick() {
        this.updateMenuVisibility(false);
        this.skipNavBtnElm.focus();
    }

    /**
     * @method onHamburgerBtnClick
     * @description helper function that ensures nav is visible
     *              when hamburger menu is open
     */
    onHamburgerBtnClick() {
        this.updateMenuVisibility(false);
    }

    /**
     * @method destroy
     */
    destroy() {
        this.detachEvents();
        super.destroy();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

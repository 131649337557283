/**
 * Template for AMG Detail Overlay Loading Spinner
 * Uses ES6 template literal to composite the markup
 */
import { htmlNode } from 'utils';

export default () => htmlNode`
    <div class="specialty-detail__loading-spinner-wrapper">
        <svg class="specialty-detail__loading-spinner" width="130" height="130">
            <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stop-color="#C8000F" stop-opacity="1"></stop>
                <stop offset="100%" stop-color="#C8000F" stop-opacity="0.9"></stop>
            </linearGradient>
            <linearGradient id="linearColors2" x1="0.5" y1="0" x2="0.5" y2="1">
                <stop offset="0%" stop-color="#C8000F" stop-opacity="0.9"></stop>
                <stop offset="100%" stop-color="#C8000F" stop-opacity="0.8"></stop>
            </linearGradient>
            <linearGradient id="linearColors3" x1="1" y1="0" x2="0" y2="1">
                <stop offset="0%" stop-color="#C8000F" stop-opacity="0.8"></stop>
                <stop offset="100%" stop-color="#C8000F" stop-opacity="0.65"></stop>
            </linearGradient>
            <linearGradient id="linearColors4" x1="1" y1="1" x2="0" y2="0">
                <stop offset="0%" stop-color="#C8000F" stop-opacity="0.65"></stop>
                <stop offset="100%" stop-color="#C8000F" stop-opacity="0.4"></stop>
            </linearGradient>
            <linearGradient id="linearColors5" x1="0.5" y1="1" x2="0.5" y2="0">
                <stop offset="0%" stop-color="#C8000F" stop-opacity="0.4"></stop>
                <stop offset="100%" stop-color="#C8000F" stop-opacity="0.2"></stop>
            </linearGradient>
            <linearGradient id="linearColors6" x1="0" y1="1" x2="1" y2="0">
                <stop offset="0%" stop-color="#C8000F" stop-opacity="0.2"></stop>
                <stop offset="100%" stop-color="#C8000F" stop-opacity="0"></stop>
            </linearGradient>
            <path d="M65 5 a60 60 0 0 1 51.9615 30" fill="none" stroke="url(#linearColors1)" stroke-width="2"></path>
            <path d="M116.9615 35 a60 60 0 0 1 0 60" fill="none" stroke="url(#linearColors2)" stroke-width="2"></path>
            <path d="M116.9615 95 a60 60 0 0 1 -51.9615 30" fill="none" stroke="url(#linearColors3)" stroke-width="2"></path>
            <path d="M65 125 a60 60 0 0 1 -51.9615 -30" fill="none" stroke="url(#linearColors4)" stroke-width="2"></path>
            <path d="M13.039 95 a60 60 0 0 1 0 -60" fill="none" stroke="url(#linearColors5)" stroke-width="2"></path>
            <path d="M13.039 35 a60 60 0 0 1 51.9615 -30" fill="none" stroke="url(#linearColors6)" stroke-width="2"></path>
        </svg>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @function getActiveStepIndex
 * @description Gets the current step and sub-step index from state
 * @param currentIndex {Number} Current active step index
 * @param steps {Array} Collection of steps
 * @return {{subStepIndex: *, stepIndex: *}}
 */
export function getActiveStepIndex(currentIndex, steps) {
    const currentSubStep = steps[currentIndex] && steps[currentIndex].subSteps ?
        steps[currentIndex].subSteps.findIndex((subStep) => subStep.isActive) :
        -1;

    return {
        stepIndex: currentIndex,
        subStepIndex: currentSubStep > -1 ? currentSubStep : null
    };
}

/**
 * @function getPrevStep
 * @description Gets the index of the prev step and sub-step
 * @param currentIndex {Number} Current active step index
 * @param steps {Array} Collection of steps
 * @return {{prevStepIndex: number, prevSubStepIndex: null}}
 */
export function getPrevStep(currentIndex, steps) {
    const stepIndexes = getActiveStepIndex(currentIndex, steps);

    const prevStepIndex = stepIndexes.subStepIndex > 0 ? stepIndexes.stepIndex : stepIndexes.stepIndex - 1;
    let prevSubStepIndex = null;

    if (stepIndexes.subStepIndex > 0) {
        prevSubStepIndex = stepIndexes.subStepIndex - 1;
    } else if (
        (stepIndexes.subStepIndex === 0 || !stepIndexes.subStepIndex) &&
        (steps[prevStepIndex] && steps[prevStepIndex].subSteps)
    ) {
        prevSubStepIndex = steps[prevStepIndex].subSteps.length - 1;
    }

    return {
        prevStepIndex,
        prevSubStepIndex
    };
}

/**
 * @function getNextStep
 * @description Gets the index of the next step and sub-step
 * @param currentIndex {Number} Current active step index
 * @param steps {Array} Collection of steps
 * @return {{nextStepIndex: number, nextSubStepIndex: null}}
 */
export function getNextStep(currentIndex, steps) {
    const stepIndexes = getActiveStepIndex(currentIndex, steps);

    const nextStepIndex = steps[stepIndexes.stepIndex].subSteps && steps[stepIndexes.stepIndex].subSteps.length > 1 &&
    stepIndexes.subStepIndex !== (steps[stepIndexes.stepIndex].subSteps.length - 1) ?
        stepIndexes.stepIndex : stepIndexes.stepIndex + 1;
    let nextSubStepIndex = null;

    if (steps[nextStepIndex].subSteps && !steps[stepIndexes.stepIndex].subSteps ||
        (stepIndexes.subStepIndex && (stepIndexes.subStepIndex === steps[stepIndexes.stepIndex].subSteps.length - 1))) {
        nextSubStepIndex = 0;
    } else if (steps[nextStepIndex].subSteps && steps[nextStepIndex].subSteps.length > 1) {
        nextSubStepIndex = stepIndexes.subStepIndex + 1;
    }

    return {
        nextStepIndex,
        nextSubStepIndex
    };
}

/**
 * @function getPrevStepObject
 * @description Gets the previous step and sub-step object based on the currentIndex
 * @param currentIndex {Number} Current active step index
 * @param steps {Array} Collection of steps
 * @return {{step: *, subStep: null}}
 */
export function getPrevStepObject(currentIndex, steps) {
    const prevSteps = getPrevStep(currentIndex, steps);

    return {
        step: steps[prevSteps.prevStepIndex] && steps[prevSteps.prevStepIndex],
        stepIndex: prevSteps.prevStepIndex,
        subStep: (
            steps[prevSteps.prevStepIndex] &&
            steps[prevSteps.prevStepIndex].subSteps
        ) ?
            steps[prevSteps.prevStepIndex].subSteps[prevSteps.prevSubStepIndex] :
            null,
        subStepIndex: prevSteps.prevSubStepIndex
    };
}

/**
 * @method getStepIndex
 * @description Gets the step indexes of the current step and sub step
 * @param stepId {string} Step Id to go to
 * @param subStepId {string} Sub-step Id to go to
 * @param steps {object} Collection of steps
 * @return {{stepIndex: *, subStepIndex: *}}
 */
export function getStepIndex(stepId, subStepId, steps) {
    const currentStepIndex = steps.findIndex((step) => step.id === stepId);
    let currentSubStepIndex;

    if (currentStepIndex > -1 && subStepId && steps[currentStepIndex].subSteps) {
        // if the current step has a substep and a subStepId was defined, then find the substep's index
        const subStepIndex = steps[currentStepIndex].subSteps.findIndex((subStep) => subStep.id === subStepId);
        currentSubStepIndex = (subStepIndex !== -1 && subStepIndex < steps[currentStepIndex].subSteps.length) ?
            subStepIndex :
            0;
    } else {
        // otherwise default the subStep index
        currentSubStepIndex = currentStepIndex > -1 && steps[currentStepIndex].subSteps ? 0 : null;
    }

    return {
        stepIndex: currentStepIndex,
        subStepIndex: currentSubStepIndex
    };
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Utility dependencies
import {
    formatString,
    storage
} from 'utils';

/**
 * @property instance
 * @description Stores a singleton instance of the UserProfile
 * @type {null|UserProfile}
 */
let instance = null;

/**
 * @const STORAGE_KEYS
 * @description Keys names for data stored in storage
 * @type {{USER_PROFILE: string, CLASSIC_PREFERRED_DEALER: string}}
 */
const STORAGE_KEYS = {
    USER_PROFILE: 'MB_USER_PROFILE',
    CLASSIC_INVENTORY_DEALER: 'inventoryDealer',
    CLASSIC_PREFERRED_DEALER: 'preferredDealer',
};

/**
 * @property initialProfile
 * @description Stub for the default data of a user profile
 * @type {{preferredDealer: null}}
 */
const initialProfile = {
    preferredDealer: null,
    inventoryDealer: null,
    lastLocation: {}
};

/**
 * @class UserProfile
 * @description Interface for managing the state and data of user profile
 */
export default class UserProfile {
    /**
     * @constructor
     * @description Creates a singleton instance of a UserProfile
     * @return {null|UserProfile}
     */
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
        this.data = null;

        this.create();
    }

    /**
     * @method create
     * @description Creates and saves a user profile object to local storage
     */
    create() {
        const now = new Date();
        this.data = storage.localStorage.read(STORAGE_KEYS.USER_PROFILE);

        if (!this.data) {
            this.data = initialProfile;

            storage.localStorage.create(
                STORAGE_KEYS.USER_PROFILE,
                this.data,
                new Date(now.setDate(now.getDate() + 60))
            );
        }

        if (!this.data.preferredDealer || !this.data.inventoryDealer) {
            this.readClassicData();
        }
    }

     /**
     * @method readClassicData
     * @description loads data from classic vans site
     */
    readClassicData() {
        try {
            const  update = {};
            if (!this.data.preferredDealer) {
                // Check if there is Classic Preferred Dealer set and use it in the OW User Profile storage item
                const preferredDealer = this.getClassicDealer(STORAGE_KEYS.CLASSIC_PREFERRED_DEALER);
                update.preferredDealer = preferredDealer;
                if (preferredDealer) {
                    update.lastLocation = {};
                }
            }

            if (!this.data.inventoryDealer) {
                // Check if there is Classic Inventory Dealer set and use it in the OW User Profile storage item
                const inventoryDealer = this.getClassicDealer(STORAGE_KEYS.CLASSIC_INVENTORY_DEALER);
                update.inventoryDealer = inventoryDealer;
            }

            this.data = {
                ...this.data,
                ...update,
            };

            storage.localStorage.update(
                STORAGE_KEYS.USER_PROFILE,
                update
            );
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * @method getBadgesFromClassicDealer
     * @description Set array of badges according to classic's dealer dealerFeaturesInfo
     * TODO: can be removed when OW Inventory is released
     * @param dealer {Object} dealer from classic
     * @return {Array}
     */
    getBadgesFromClassicDealer(dealer) {
        const badges = ['mbdealer'];

        if (dealer.dealerFeaturesInfo) {
            dealer.dealerFeaturesInfo.forEach((feature) => {
                if (feature.featureId === 1) {
                    badges.push('VanCertifiedCollisionCenter');
                } else if (feature.featureId === 2) {
                    badges.push('VanCAREExpress');
                } else if (feature.featureId === 4) {
                    badges.push('VanCAREMobile');
                }
            });
        }

        return badges;
    }

    /**
     * @method getClassicDealer
     * @description reads dealer data from classic vans site
     * TODO: can be removed when OW Inventory is released
    */
    getClassicDealer(classicKey) {
        const classicData =  window.localStorage.getItem(classicKey);

        if (classicData && classicData !== 'null') {
            const classicObject = JSON.parse(classicData);
            const badges = this.getBadgesFromClassicDealer(classicObject);
            const dealerNameHyphenated = formatString.hyphenate(
                formatString.toAlphaNumeric(classicObject.name.toLowerCase())
            );
            const inventoryUrl = `/sprinter/inventory/${dealerNameHyphenated}/dealer/${classicObject.dealerID || classicObject.id}`;

            return {
                ...classicObject,
                address: classicObject.street || '',
                badges,
                contact: [{ type: 'phone', value: classicObject.phone }],
                dealerId: classicObject.dealerID || classicObject.id,
                distance: classicObject.distance && `${classicObject.distance} mi`,
                hasInventory: true,
                hasVanCare: badges.includes('VanCAREExpress'),
                hasVanCareMobile: badges.includes('VanCAREMobile'),
                isDealership: true,
                inventoryUrl,
                location: {
                    lat: classicObject.latitude,
                    lng: classicObject.longitude
                },
                marketCode: '',
                phoneFormatted: classicObject.phone || '',
                regionCode: '',
            };
        }
        return null;
    }

    /**
     * @method
     * @description Normalizes previously saved preferred dealer objects
     * to conform to code changes prior to the objects being saved.
     * Note: this is needed to ensure that preferred dealers have 'contact', 'isDealership', 'marketCode', 'regionCode'
     * This was an update made in R18.0.1 (Aug 2019) and should be revisited post release to
     * track when this interim fix can be removed
     * Also fix decimal separator according to page language
     * @param preferredDealer {Object} Dealer object
     * @return {*}
     */
    normalizePreferredDealer(preferredDealer) {
        const dealerProperties = ['contact', 'isDealership', 'marketCode', 'regionCode'];

        if (dealerProperties.map(Object.hasOwnProperty.bind(preferredDealer)).some((value) => !value)) {
            this.setPreferredDealer(null);
            return null;
        }

        if (preferredDealer.distance) {
            // fix decimal separator of distance stored in another language
            if (window.mbVans.ns('pageData', 'language') === 'fr') {
                // dot to comma
                preferredDealer.distance = preferredDealer.distance.replace(/(\d)\.(\d)/, '$1,$2');
            } else {
                // comma to dot
                preferredDealer.distance = preferredDealer.distance.replace(/(\d),(\d)/, '$1.$2');
            }
        }

        return preferredDealer;
    }

    /**
     * @method setPreferredDealer
     * @description Sets the preferred dealer of the user to the profile
     * @param dealer {Object} Dealer to set as preferred dealer
     */
    setPreferredDealer(dealer) {
        this.data = {
            ...this.data,
            preferredDealer: dealer
        };

        storage.localStorage.update(
            STORAGE_KEYS.USER_PROFILE,
            this.data
        );

        // For compatibility from One Web to Classic
        // TODO: can be removed when OW Inventory is released
        this.setClassicPreferredDealer(dealer);
    }

    /**
     * @method setClassicPreferredDealer
     * @description Saves a local storage item for the preferred dealer in Classic Vans format
     * TODO: can be removed when OW Inventory is released
     * @param dealer {Object} Dealer to set as preferred dealer
     */
    setClassicPreferredDealer(dealer) {
        let preferredDealer = dealer;

        if (dealer) {
            const dealerEmail = dealer.contact && dealer.contact.find((contact) => contact.type === 'email');

            preferredDealer = {
                brand: 'SP,MB',
                city: dealer.city,
                dealerEmail: dealerEmail && dealerEmail.value,
                dealerID: dealer.dealerId,
                distance: dealer.distance.substring(0, dealer.distance.indexOf(' mi')),
                isPreferred: true,
                latitude: dealer.location && dealer.location.lat,
                longitude: dealer.location && dealer.location.lng,
                name: dealer.name,
                phone: dealer.phoneFormatted,
                state: dealer.state,
                street: dealer.address,
                webSiteUrl: dealer.url,
                zip: dealer.zip,
            };
        }

        window.localStorage.setItem(
            STORAGE_KEYS.CLASSIC_PREFERRED_DEALER,
            JSON.stringify(preferredDealer),
        );
    }

    /**
     * @method getPreferredDealer
     * @description Retrieves the preferred dealer from the user profile
     * @return {Object}
     */
    getPreferredDealer() {
        return this.data.preferredDealer ?
            this.normalizePreferredDealer(this.data.preferredDealer) :
            this.data.preferredDealer;
    }

    /**
     * @method getInventoryDealer
     * @description Retrieves the inventory dealer from the user profile
     * @return {Object}
     */
    getInventoryDealer() {
        return this.data.inventoryDealer;
    }

    /**
     * @method setInventoryDealer
     * @description Saves the inventory dealer into the user profile
     * @param {Object} dealer
     */
    setInventoryDealer(dealer) {
        this.data = {
            ...this.data,
            inventoryDealer: dealer
        };

        storage.localStorage.update(
            STORAGE_KEYS.USER_PROFILE,
            {
                inventoryDealer: dealer
            }
        );
    }

    /**
     * @method setLastLocation
     * @description Sets the last location the user searched to the user profile
     * @param lastLocation {{searchLocation: string, searchByType: 'zip'|'place'}} to set as last location searched
     */
    setLastLocation(lastLocation) {
        this.data = {
            ...this.data,
            lastLocation
        };

        storage.localStorage.update(
            STORAGE_KEYS.USER_PROFILE,
            this.data
        );
    }

    /**
     * @method getLastLocation
     * @description Retrieves the last location searched from the user profile
     * @return {String}
     */
    getLastLocation() {
        return this.data.lastLocation || initialProfile.lastLocation;
    }

    /**
     * @method get
     * @description Retrieves the user profile data
     * @return {Object} User profile data object
     */
    get() {
        return this.data;
    }
}

export function testRemoveInstance() {
    instance = null;
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

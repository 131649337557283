import { tokenReplace } from 'utils';

const ROOF_CODES = {
    HIGHROOF: 'HR',
    STANDARDROOF: 'SR',
};

const { country }  = window.mbVans.pageData;

/**
 * @function getWheelCode
 * @description returns the correct wheel code to fetch the image
 * @param {string} modelCode 6 letter model code
 * @returns {string} a two letter wheel code
 */
function getWheelCode(modelCode) {
    // 1500, 2500 --> SI
    // 3500, 3500x, 4500 --> DR
    if (country === 'ca') {
        return modelCode.substr(0, 1) < 3 ? 'SI' : 'DR';
    }
    return modelCode.substr(1, 1) < 3 ? 'SI' : 'DR';
}

/**
 * @function getRoofCode
 * @description Gets the roof code needed for getting the image
 * @param {string} variant The roof height
 * @returns {string} a two letter roof code
 */
function getRoofCode(variant) {
    const roofCode = ROOF_CODES[variant];
    if (!roofCode) {
        console.error(variant, "can't be mapped to a roof code");
    }
    return roofCode;
}

/**
 * @function getColorCode
 * @description extracts the 3 of 4 digits code from the vista file code
 * @param {string} color vista file color code, as 2:xxxx
 * @returns {string} the standard color code
 */
function getColorCode(color) {
    // remove "2:"
    return color.substring(2);
}

/**
 * @function getEngineMappedModelCode
 * @description map gas and 4-cyl model codes to codes with 6-cyl engines which have images
 *      new for MY'23 - also map "standard output" (S), "high output" (H), and "AWD" (A) to "6".
 * @param {string} modelCode
 * @returns {string} the code for the vehicle with 6-cyl engine but same outside view
 */
function getEngineMappedModelCode(modelCode) {
    return modelCode.replace(/[aghs4]$/i, '6');
}

/**
 * @function getImageUrl
 * @description gets the path for a model image
 * @param {string} basePath base path, should include placeholders
 * @param {string} classCode METRIS or SPRINTER
 * @param {string} modelCode the 6 letters code
 * @param {string} roof STANDARDROOF or HIGHROOF
 * @param {string} color 2:xxxx vista file color code
 * @param {string} angle angle code
 * @returns {string} full path to an image
 */
function getImageUrl({ basePath, classCode, modelCode, roof, color, angle }) {
    if (!basePath) return null;
    const isSprinter = ['SPRINTER', 'eSprinter'].includes(classCode);
    const params = {
        modelCode: isSprinter ? getEngineMappedModelCode(modelCode) : modelCode,
        wheelsCode: isSprinter && getWheelCode(modelCode),
        roofCode: getRoofCode(roof),
        colorCode: getColorCode(color),
        angleCode: angle,
    };
    return tokenReplace(basePath, params);
}

export default {
    getImageUrl
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

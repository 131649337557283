import warrantyDetailInfoContainer from '../templates/warrantyDetailInfoTemplate';

/**
 * View responsible for the warranty detailed information associated with a VIN
 */
export default class WarrantyDetailInfo {
    /**
     * @constructor
     * @description Constructs the RecallDetailInfo
     * @param (object} content - Content associated with the detail info
     * @param (object} config - Config associated with the detail info
     * @param {object} warrantyInfo - Warranty info object from the API response
     */
    constructor(content, config, warrantyInfo) {
        this.content = content;
        this.config = config;
        this.warrantyInfo = warrantyInfo;
        this.element = warrantyDetailInfoContainer(content, warrantyInfo)({ getNode: true });
    }

    /**
     * @method render
     * @description Returns the DOM element for RecallDetailInfo
     */
    render() {
        return this.element;
    }
}

import { EVENTS, KEYBOARD } from 'Constants';
import { Observer } from 'partials/observer';
import collisionCenterContent from './../config/content';
import template from './../templates/collisionCenterTemplate';

const ATTRIBUTES = {
    CENTER_SELECTED: 'data-selected-center'
};

/**
 * View responsible for rendering the dealerInfo module
 */
export default class CollisionCenter extends Observer {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     */
    constructor(center = null, content = null, index = 0, model) {
        super();

        this.element = template(
            center,
            Object.assign(collisionCenterContent, content),
            index
        )({ getNode: true }); // DOM element to render
        this.model = model;
        this.onSelectHandler = this.onSelectHandler.bind(this);
        this.selectCenter = this.selectCenter.bind(this);
        this.center = center;
        this.id = center.id;
        this.attachEvents();
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, this.onSelectHandler);
        this.element.addEventListener(EVENTS.KEYDOWN, this.onSelectHandler);
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.CLICK, this.onSelectHandler);
        this.element.removeEventListener(EVENTS.KEYDOWN, this.onSelectHandler);
    }

    /**
     * @method onSelectHandler
     * @description The select handler when collisionCenter node is clicked or
     * selected through keyboard
     */
    onSelectHandler(event) {
        const { which } = event;

        if (event.type === EVENTS.CLICK || which === KEYBOARD.ENTER) {
            this.selectCenter(this.center);
        }
    }

    selectCenter(center) {
        this.expand();
        this.model.setSelectedRetailer(center);
    }

    /**
     * @method expand
     * @description Sets the data-selected-center attribute on the collisionCenter node to true
     * This data-attr is used by CSS to show/hide more details
     */
    expand() {
        this.element.setAttribute(ATTRIBUTES.CENTER_SELECTED, true);
    }

    /**
     * @method collapse
     * @description Sets the data-selected-center attribute on the collisionCenter node to false
     */
    collapse() {
        this.element.setAttribute(ATTRIBUTES.CENTER_SELECTED, false);
    }

    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.detachEvents();
        this.element.setAttribute(ATTRIBUTES.CENTER_SELECTED, false);
        this.element.remove();
    }

    /**
     * @method render
     * @description Renders the dealer search types element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import constants from './constants';

/**
 * @property pageData
 * @description get properties from window
 * @type {{
 *     byo: Object,
 *     country: string,
 *     localization: Object,
 *     paymentEstimatorConfig: Object,
 *     variantInfo: Object
 * }}
 */
export const {
    byo,
    country,
    localization,
    paymentEstimatorConfig,
    variantInfo
} = window.mbVans.ns('pageData');

/**
 * @properties inventoryConfig
 * @description get properties from window applicationProperties
 * @type {{
 *     inventoryConfig: Object
 * }}
 */
export const { inventoryConfig } = window.mbVans.applicationProperties;

export default {
    byo,
    country,
    inventoryConfig,
    localization,
    paymentEstimatorConfig,
    variantInfo
};


export function getBaumusterCode() {
    const baumusterCode = byo.config.MODEL_BYO_CONTENT.baumuster;
    if (!baumusterCode) return '907643';
    return baumusterCode;
}

export function getModelSWTCode() {
    const modelSWTCode = byo.config.MODEL_BYO_CONTENT.modelSWT;
    if (!modelSWTCode) return '90764313_V3D';
    return modelSWTCode;
}

export function getUserSelectedCan() {
    const userSelectedCan = localStorage.getItem('userSelectedCan');

    if (!userSelectedCan) {
        return {
            can: '',
            companyName: ''
        };
    }
    return JSON.parse(userSelectedCan);
}

export const getUserGroupId = (groupId) => {
    const { userDetails } = window.mbVans.ns('pageData');
    const availableGroups = userDetails.groups;
    const groupsArray = availableGroups.split(',');
    const isGroupExists = groupsArray.includes(groupId);
    return isGroupExists;
};

/**
 * @method getClassSpecificFees
 * @description Return the selected class applicable taxes to show
 *
 */
export function getClassSpecificFees(vehicleClassCode) {
    const {
        vehicleClassesFee
    } = window.mbVans.ns('pageData');
    let tax = 0;
    const allFees = vehicleClassesFee ? JSON.parse(vehicleClassesFee) : [];
    // For metris class there is no tax available.
    if (vehicleClassCode.toLocaleLowerCase() === constants.CLASS.METRIS.toLocaleLowerCase()) {
        return tax;
    }
    if (allFees.length) {
        tax = allFees?.find((fee) => fee.vehicleClass.toLowerCase() === vehicleClassCode.toLowerCase())?.totalTax;
        return parseFloat(tax || 0);
    }
    return tax;
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

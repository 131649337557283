/**
 * Template for a Slider app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying the Slider plugin
 * @param content {Object} Localization object
 * @param disabledLarge {Boolean} Conditonal to disable plugin in certain windows
 * @param buttonHideSmall {Boolean} Conditional to hide buttons on viewport below large.
 * @param optionalClass {String} Optional class attribute for the slider
 */
export default (content, { buttonHideSmall, disabledLarge, optionalClass }) => htmlNode`
    <div class="slider ${disabledLarge ? 'slider--disabled-large' : ''} ${optionalClass}">
        <button class="slider__button prevButton ${buttonHideSmall ? 'slider__button--hide-small' : ''}" data-analytics-trigger="slider-arrow-left">
            <span class="offscreen">${content.previous}</span>
        </button>
        <button class="slider__button nextButton ${buttonHideSmall ? 'slider__button--hide-small' :  ''}" data-analytics-trigger="slider-arrow-right">
            <span class="offscreen">${content.next}</span>
        </button>
        <ul class="slider__list-container"></ul>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

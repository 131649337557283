/**
 * Image Toggle Links Module
 * Module to display multiple images on click of toggle items
 */
import { EVENTS } from 'Constants';

const CLASSES = {
    IMAGE_TOGGLE: 'image-toggle__image-container',
    IMAGE_TOGGLE_ACTIVE: 'image-toggle__image-container--active',
    LINK_TOGGLE: 'image-toggle-link',
    LINK_TOGGLE_ACTIVE: 'image-toggle-link--active',
    HEADING_COPY: 'image-toggle__heading',
    BODY_COPY: 'image-toggle__body',
    HEADING_COPY_DEFAULT: 'image-toggle__heading--default',
    BODY_COPY_DEFAULT: 'image-toggle__body--default',
    HEADING_COPY_ACTIVE: 'image-toggle__heading--visible',
    BODY_COPY_ACTIVE: 'image-toggle__body--visible',
    HEADING_COPY_HIDDEN: 'image-toggle__heading--hidden',
    BODY_COPY_HIDDEN: 'image-toggle__body--hidden',
    CTA_LINK: 'image-toggle__cta',
    CTA_LINK_ACTIVE: 'image-toggle__cta--visible',
    CTA_LINK_HIDDEN: 'image-toggle__cta--hidden'
};

const ATTRIBUTES = {
    DATA_TOGGLE_INDEX: 'data-image-toggle-index',
};

const TYPES = {
    HEADING_COPY: 'heading',
    BODY_COPY: 'body',
    CTA_LINK: 'cta'
};

export default class ImageToggleLinks {
    constructor(element) {
        this.element = element;
        this.cacheDOMElements.bind(this);
        this.attachEvents.bind(this);
        this.init();
    }

    init() {
        this.cacheDOMElements();
        this.attachEvents();
    }

    cacheDOMElements() {
        this.toggleLinks = this.element.querySelectorAll(`.${CLASSES.LINK_TOGGLE}`);
        this.toggleImages = this.element.querySelectorAll(`.${CLASSES.IMAGE_TOGGLE}`);
        this.headingCopyDefault = this.element.querySelector(`.${CLASSES.HEADING_COPY_DEFAULT}`);
        this.bodyCopyDefault = this.element.querySelector(`.${CLASSES.BODY_COPY_DEFAULT}`);
        this.headingCopyAlt = this.element.querySelectorAll(`.${CLASSES.HEADING_COPY}`);
        this.bodyCopyAlt = this.element.querySelectorAll(`.${CLASSES.BODY_COPY}`);
        this.ctaAlt = this.element.querySelectorAll(`.${CLASSES.CTA_LINK}`);
    }

    attachEvents() {
        Array.prototype.forEach.call(this.toggleLinks, (link) => {
            link.addEventListener(EVENTS.CLICK, this.onClick.bind(this));
        });
    }

    onClick(event) {
        event.preventDefault();
        this.removeActiveClass();
        const selectedLink = event.currentTarget;
        this.setActiveClass(selectedLink);
    }

    removeActiveClass() {
        // Remove active class from toggle links
        Array.prototype.forEach.call(this.toggleLinks, (link) => {
            link.classList.remove(CLASSES.LINK_TOGGLE_ACTIVE);
        });

        // Remove active class from images
        Array.prototype.forEach.call(this.toggleImages, (img) => {
            img.classList.remove(CLASSES.IMAGE_TOGGLE_ACTIVE);
        });
    }

    setActiveClass(selectedLink) {
        const toggleIndex = selectedLink.getAttribute(ATTRIBUTES.DATA_TOGGLE_INDEX);
        const selectedImage = this.element.querySelector(`.${CLASSES.IMAGE_TOGGLE}[${ATTRIBUTES.DATA_TOGGLE_INDEX}='${toggleIndex}']`);
        selectedImage.classList.add(CLASSES.IMAGE_TOGGLE_ACTIVE);
        selectedLink.classList.add(CLASSES.LINK_TOGGLE_ACTIVE);
        this.setAlternativeCopy(toggleIndex);
    }

    setAlternativeCopyClasses(array, type) {
        this.array = array;
        this.type = type;
        Array.prototype.forEach.call(this.array, (item) => {
            if (this.type === TYPES.HEADING_COPY) {
                item.classList.remove(CLASSES.HEADING_COPY_ACTIVE);
                item.classList.add(CLASSES.HEADING_COPY_HIDDEN);
            } else if (this.type === TYPES.BODY_COPY) {
                item.classList.remove(CLASSES.BODY_COPY_ACTIVE);
                item.classList.add(CLASSES.BODY_COPY_HIDDEN);
            } else if (this.type === TYPES.CTA_LINK) {
                item.classList.remove(CLASSES.CTA_LINK_ACTIVE);
                item.classList.add(CLASSES.CTA_LINK_HIDDEN);
            }
        });
    }

    setAlternativeCopyDefaultClasses(array, type) {
        this.array = array;
        this.type = type;

        if (this.type === TYPES.HEADING_COPY) {
            this.array.classList.remove(CLASSES.HEADING_COPY_HIDDEN);
            this.array.classList.add(CLASSES.HEADING_COPY_ACTIVE);
        } else if (this.type === TYPES.BODY_COPY) {
            this.array.classList.remove(CLASSES.BODY_COPY_HIDDEN);
            this.array.classList.add(CLASSES.BODY_COPY_ACTIVE);
        }
    }

    setAlternativeCopy(index) {
        const alternativeHeading = this.element.querySelector(`.${CLASSES.HEADING_COPY}[${ATTRIBUTES.DATA_TOGGLE_INDEX}='${index}']`);
        const alternativeBody = this.element.querySelector(`.${CLASSES.BODY_COPY}[${ATTRIBUTES.DATA_TOGGLE_INDEX}='${index}']`);
        const alternativeCTA = this.element.querySelector(`.${CLASSES.CTA_LINK}[${ATTRIBUTES.DATA_TOGGLE_INDEX}='${index}']`);

        if (alternativeHeading) {
            this.setAlternativeCopyClasses(this.headingCopyAlt, TYPES.HEADING_COPY);
            alternativeHeading.classList.remove(CLASSES.HEADING_COPY_HIDDEN);
        } else {
            this.setAlternativeCopyClasses(this.headingCopyAlt, TYPES.HEADING_COPY);
            this.setAlternativeCopyDefaultClasses(this.headingCopyDefault, TYPES.HEADING_COPY);
        }

        if (alternativeBody) {
            this.setAlternativeCopyClasses(this.bodyCopyAlt, TYPES.BODY_COPY);
            alternativeBody.classList.remove(CLASSES.BODY_COPY_HIDDEN);
        } else {
            this.setAlternativeCopyClasses(this.bodyCopyAlt, TYPES.BODY_COPY);
            this.setAlternativeCopyDefaultClasses(this.bodyCopyDefault, TYPES.BODY_COPY);
        }

        if (alternativeCTA) {
            this.setAlternativeCopyClasses(this.ctaAlt, TYPES.CTA_LINK);
            alternativeCTA.classList.remove(CLASSES.CTA_LINK_HIDDEN);
        } else {
            this.setAlternativeCopyClasses(this.ctaAlt, TYPES.CTA_LINK);
            this.setAlternativeCopyDefaultClasses(this.ctaAlt, TYPES.CTA_LINK);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import DOMPurify from 'dompurify';

function validateSource(input) {
    const cleanedInput = DOMPurify.sanitize(input);
    return cleanedInput;
}
/**
 * @description base path of the page
 */
let basePath = '';

/**
 * @function readParams
 * @description Reads the URL search string and returns a parsed Object
 * @return {Object}
 */
const readParams = () => {
    let pathname = window.location.pathname;
    pathname = validateSource(pathname);
    // Find the first occurence of a filter
    const firstFilterIndex = pathname.search(/\/(lcp|searchLocation|searchByType|filter|browseAll)-([^/]+)/);
    basePath = pathname;

    // If any filters are present in the URL
    // set the basePath to substring from beginning to before the first filter
    // parse url params into an object
    if (firstFilterIndex !== -1) {
        basePath = basePath.substring(0, firstFilterIndex);
        const urlParamString = pathname.substring(firstFilterIndex + 1, pathname.length);
        return urlParamString
            .split('/')
            .reduce((paramsObj, urlPart) => {
                // For each string that is filterKey-filterValue,
                // match the key and value
                // Ref to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match
                const [, key, value] = urlPart.match(/(\w+)-(.*)/);
                paramsObj[key] = decodeURIComponent(value);
                return paramsObj;
            }, {});
    }

    return {};
};

/**
 * @class Router
 * @description Handles setting/getting the URL params
 */
export default class Router {
    /**
     * @constructor
     * @description Initializes Dealer Search router, and sets the params
     */
    constructor(params) {
        if (params) {
            this.params = params;
        } else {
            this.params = readParams();
        }
    }

    /**
     * @method getParams
     * @description Gets the params object which maps to current state
     */
    getParams() {
        return this.params;
    }

    /**
     * @method setParams
     * @param params {Object} A params object which maps to the state of the app
     * @description Sets the params proprety, and sets the history state with the serialized
     * version of the object
     */
    setParams(params) {
        this.params = params;
        // Convert an object of params to / separated serialized
        // string where key value is joined by -
        const serializedString =
            Object.keys(this.params)
                .filter((key) => this.params[key])
                .map((key) => `${key}-${this.params[key]}`)
                .join('/');
        window.history.pushState(null, null, `${basePath}/${serializedString}`);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @class ImagePromiseCollection
 * @description A Promise-like object that observes the loading state of
 * a collection of ImagePromises and creates callback handlers for when
 * the images successfully/unsuccessfully load, and to cancel the requests
 */
class ImagePromiseCollection {
    constructor(imageCollection, percentCallback) {
        this.imageCollection = imageCollection;
        this.percentCallback = percentCallback;

        this.deferredImages = new Promise((resolve, reject) => {
            let deferredCount = 0;

            imageCollection.forEach((imagePromise) => {
                imagePromise
                .success(() => {
                    deferredCount += 1;

                    if (this.percentCallback) {
                        this.percentCallback(deferredCount, imageCollection.length);
                    }

                    if (deferredCount === imageCollection.length) {
                        resolve();
                    }
                })
                .error(reject);
            });
        });
    }

    /**
     * @method success
     * @description Callback applied when images successfully load
     * @param callback
     * @returns {ImagePromiseCollection}
     */
    success(callback) {
        this.deferredImages.then(callback);
        return this;
    }

    /**
     * @method error
     * @description Callback applied when images unsuccessfully load
     * @param callback
     * @returns {ImagePromiseCollection}
     */
    error(callback) {
        this.deferredImages.catch(callback);
        return this;
    }

    /**
     * @method cancel
     * @description Iterates a collection of imagePromise and cancels each request
     */
    cancel() {
        this.imageCollection.forEach((imagePromise) => {
            imagePromise.cancel();
        });
    }
}

export default ImagePromiseCollection;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import DealerLocatorPlugin from 'plugins/dealer-locator/js';

/**
 * @properties config, localization
 * @description Dealer Locator properties defined in
 * /apps/mb-vans/components/content/dealer-locator-module/dealer-locator-module.html
 */
const {
    config,
    localization
} = window.mbVans.pageData.dealerLocator;

/**
 * @class DealerLocator
 * @description Class associated with AEM dealer locator module
 * This class instantiates Dealer Locator plugin by passing in
 * necessary params and inserting the rendered plugin into container
 */
export default class DealerLocator {
    /**
     * @constructor
     * Initializes the DealerLocator module
     */
    constructor(element) {
        this.dealerLocatorPlugin = new DealerLocatorPlugin(element, config, localization);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { EVENTS } from 'Constants';
import { debounce, renderer, screen } from 'utils';

const CLASSES = {
    NAV_IS_ACTIVE: 'multi-slide-nav--active',
    ACTIVE_ON_SMALL: 'multi-slide-small--active',
    ACTIVE_ON_LARGE: 'multi-slide-large--active',
    ACTIVE_ON_XLARGE: 'multi-slide-xlarge--active'
};

const ATTRIBUTES = {
    CAROUSEL: 'data-multi-slide-carousel',
    CAROUSEL_ITEMS_LIST: 'data-multi-slide-carousel-items-list',
    NAV_LEFT_ARROW: 'data-multi-slide-carousel-nav-left',
    NAV_RIGHT_ARROW: 'data-multi-slide-carousel-nav-right'
};

const PROPS = {
    UNITS: 'px',
    SPEED: 'ms'
};

const defaultCarouselConfig = {
    // TODO: fix endlessLooping animation
    // endlessLooping: false, / if true the carousel will loop endless
    // TODO: fix backward functionality for advanceSingleItem
    // advanceSingleItem: false, // if false the navigation is based on the viewport width
    activeSmall: true, // if false the carousel will not show on small screens
    activeLarge: false, // if false the carousel will not show on large screens only on mobile
    activeXLarge: false, // if false the carousel will not show on extra large screens
    transitionSpeed: 1 // transition speed for the carousel
};

/**
 * Multi Slide Carousel
 * This module creates a carousel with multiple visible items
 */
class MultiSlideCarousel {
    /**
     * Instantiates the MultiSlideCarousel class
     * @param {Object} element
     */
    constructor(element, config = {}) {
        this.element = element;
        this.config = {
            ...defaultCarouselConfig,
            ...config
        };
        this.navControls = {};
        this.navOffset = 0;
        this.navOffsetCount = 0;
        this.navOffsetCountSingle = 0;
        this.navWidth = 0;
        this.navItemWidth = 0;
        this.navParentWidth = 0;
        this.carouselIsActive = true;
        this.currentScreenState = screen.getState();
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.cacheDOM();
        this.setDefaults();
        this.attachEvents();
    }

    /**
     * @method cacheDom
     * @description Caches DOM elements of the view, for later use
     */
    cacheDOM() {
        this.carouselElm = this.element.querySelector(`[${ATTRIBUTES.CAROUSEL}]`);
        this.itemsListElm = this.element.querySelector(`[${ATTRIBUTES.CAROUSEL_ITEMS_LIST}]`);
        this.navControls.leftArrowElm = this.element.querySelector(`[${ATTRIBUTES.NAV_LEFT_ARROW}]`);
        this.navControls.rightArrowElm = this.element.querySelector(`[${ATTRIBUTES.NAV_RIGHT_ARROW}]`);
    }

    /**
     * @method setDefaults
     * @description set defaults values for carousel
     * */
    setDefaults() {
        this.navItemWidth = this.itemsListElm.firstElementChild.offsetWidth;
        this.navWidth = this.navItemWidth * this.itemsListElm.childElementCount;
        this.navParentWidth = this.itemsListElm.parentElement.offsetWidth;

        this.itemsListElm.style.width = `${this.navWidth}${PROPS.UNITS}`;
        this.itemsListElm.style.left = `0${PROPS.UNITS}`;

        if (this.config.activeSmall) {
            this.carouselElm.classList.add(CLASSES.ACTIVE_ON_SMALL);
        }
        if (this.config.activeLarge) {
            this.carouselElm.classList.add(CLASSES.ACTIVE_ON_LARGE);
        }
        if (this.config.activeXLarge) {
            this.carouselElm.classList.add(CLASSES.ACTIVE_ON_XLARGE);
        }

        this.carouselIsActive = (this.itemsListElm.offsetWidth > this.carouselElm.offsetWidth);
        if (this.carouselIsActive) {
            this.navControls.rightArrowElm.classList.add(CLASSES.NAV_IS_ACTIVE);
            this.navControls.leftArrowElm.classList.remove(CLASSES.NAV_IS_ACTIVE);

            this.navOffset = 0;
            this.navOffsetCount = 0;
            this.navOffsetCountSingle = 0;

            /* Disable endlessLooping functionality
            TODO: Animation needs to be fixed
            if (this.config.endlessLooping) {
                this.navControls.leftArrowElm.classList.add(CLASSES.NAV_IS_ACTIVE);
            }
            */
        } else {
            this.navControls.rightArrowElm.classList.remove(CLASSES.NAV_IS_ACTIVE);
            this.navControls.leftArrowElm.classList.remove(CLASSES.NAV_IS_ACTIVE);
        }
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners and associated callbacks to the view
     */
    attachEvents() {
        this.navControls.rightArrowElm.addEventListener(EVENTS.CLICK, (e) => {
            this.arrowClickListener(e);
        });

        this.navControls.leftArrowElm.addEventListener(EVENTS.CLICK, (e) => {
            this.arrowClickListener(e);
        });

        [].slice.call(this.itemsListElm.childNodes).forEach((item) => {
            item.addEventListener(EVENTS.TOUCHSTART,
                debounce(this.touchListenerStart.bind(this), 10));

            item.addEventListener(EVENTS.TOUCHMOVE,
                debounce(this.touchListenerMove.bind(this), 20));
        });

        [].slice.call(this.itemsListElm.children).forEach((item, index) => {
            item.addEventListener(EVENTS.CLICK, () => this.itemClickListener(item, index));
        });

        screen.addResizeListener((screenState) => {
            this.screenResizeListener(screenState);
        });
    }

    /**
     * @method itemClickListener
     * @description view item when it is partialy hidden
     * @param {Object} item - clicked item
     */
    itemClickListener(item) {
        if (this.carouselIsActive) {
            const listPosition = parseInt(this.itemsListElm.style.left, 10);
            const itemPosition = item.offsetLeft + this.navItemWidth + listPosition;
            const rightEdge = this.navParentWidth - this.navControls.rightArrowElm.offsetWidth;
            const listLeftPosition = -listPosition - this.navControls.leftArrowElm.offsetWidth;
            const listRightPosition = -listPosition + this.navControls.leftArrowElm.offsetWidth;
            if (item.offsetLeft === -listPosition ||
                (item.offsetLeft > listLeftPosition && item.offsetLeft < listRightPosition)) {
                // else needs to move one space to the left
                this.showItem(false, true);
            } else if (itemPosition > rightEdge) {
                // needs to move to the right one space
                this.showItem(true, true);
            }
        }
    }

    /**
     * @method arrowClickListener
     * @description callback for left and right click event for the pagination controls
     * @param {Event} event - arrow click event
     * */
    arrowClickListener(event) {
        if (this.carouselIsActive) {
            const isRightArrow = event.currentTarget.hasAttribute(ATTRIBUTES.NAV_RIGHT_ARROW);
            this.showItem(isRightArrow);

            /* Disable endlessLooping functionality
            TODO: fix animation
             this[this.config.endlessLooping ? 'paginateLooping' : 'showItem'](isRightArrow);
              */
        }
    }

    /**
     * @method screenResizeListener
     * @description Handles screen resize events; resets carousel if necessary
     * @param screenState {Object} screenState object from Screen utility
     * */
    screenResizeListener(screenState) {
        if (this.currentScreenState.viewport.w !== screenState.viewport.w) {
            this.setDefaults();
        }
        this.currentScreenState = screenState;
    }

    /**
     * @method paginateLooping
     * @description paginate the carousel moving/looping the DOM elements
     * @param {Boolean} paginateRight - boolean to see if it is moving to the right or left
     * */
    paginateLooping(paginateRight) {
        if (this.carouselIsActive) {
            // TODO: try another approach
            /* Disabled advanceSingleItem
            const itemsToLoop = this.config.advanceSingleItem ?
                1 : Math.floor(this.navParentWidth / this.navItemWidth);
             */
            const itemsToLoop = Math.floor(this.navParentWidth / this.navItemWidth);
            const itemsSelector = paginateRight ?
                `li:nth-child(-n+${itemsToLoop}` : `li:nth-last-child(-n+${itemsToLoop}`;

            [].slice.call(this.itemsListElm.querySelectorAll(itemsSelector))
                .forEach((i) => renderer[paginateRight ? 'append' : 'prepend'](i, this.itemsListElm));
        }
    }

    /**
     * @method showItem
     * @description paginate the carousel and disable the arrows if it is necessary
     * @param {Boolean} paginateRight - boolean to see if it is moving to the right or left
     * @param {Boolean} singleItem -
     * boolean to see if it should move one single item to the left or right
     * */
    showItem(paginateRight, singleItem) {
        /* Disabled advanceSingleItem
         const offsetStep = this.config.advanceSingleItem || singleItem ? 1 :
         Math.floor(this.navParentWidth / this.navItemWidth);
         */
        const offsetStep = singleItem ? 1 :
            Math.floor(this.navParentWidth / this.navItemWidth);
        const prevNavOffset = this.navOffset;
        const listPosition = parseInt(this.itemsListElm.style.left, 10);

        if (singleItem) {
            // when moving one item at a time by clicking on the item itself
            this.navOffsetCountSingle = paginateRight ? -offsetStep : offsetStep;
            this.navOffset = this.navItemWidth * Math.abs(this.navOffsetCountSingle);
        } else {
            // when moving by block or one by one but with arrows
            this.navOffsetCount += paginateRight ? -offsetStep : offsetStep;
            this.navOffsetCountSingle = this.navOffsetCount;
            this.navOffset = this.navItemWidth * Math.abs(this.navOffsetCount);
            if (-listPosition < this.navParentWidth && !paginateRight) {
                this.navOffset = 0;
            }
        }

        // backwards
        if (this.navOffset > 0 && !paginateRight && !singleItem) {
            this.navOffset = prevNavOffset - this.navOffset;
        }
        if (!paginateRight && singleItem) {
            this.navOffset = prevNavOffset - this.navItemWidth;
        } else if (paginateRight && singleItem) {
            this.navOffset = prevNavOffset + this.navItemWidth;
        }

        const disableRightArrow = (this.navWidth - this.navOffset) < this.navParentWidth;

        // is it the last carousel step?, take the max offset
        this.navOffset = disableRightArrow ?
            this.navWidth - this.navParentWidth : this.navOffset;

        this.navControls.leftArrowElm
            .classList[this.navOffset <= 0 ? 'remove' : 'add'](CLASSES.NAV_IS_ACTIVE);
        this.navControls.rightArrowElm
            .classList[disableRightArrow ? 'remove' : 'add'](CLASSES.NAV_IS_ACTIVE);

        // set transition duration according amount of pixel to move
        let transition = 0;
        if (this.navOffset > prevNavOffset) {
            transition = (this.navOffset - prevNavOffset) * this.config.transitionSpeed;
        } else if (this.navOffset < prevNavOffset) {
            transition = (prevNavOffset - this.navOffset) * this.config.transitionSpeed;
        }

        this.itemsListElm.style.transitionDuration = `${transition}${PROPS.SPEED}`;
        this.itemsListElm.style.left = `-${this.navOffset}${PROPS.UNITS}`;

        if (this.navOffset === 0) {
            this.navOffsetCount = 0;
        }
    }

    /**
     * @method touchListenerStart
     * @param {Event} event - a touchstart event
     */
    touchListenerStart(event) {
        this.xCoordinate = event.touches[0].pageX;
    }

    /**
     * @method touchListenerMove
     * @param {Event} event - a touchmove event
     */
    touchListenerMove(event) {
        if (!this.carouselIsActive || !this.xCoordinate) {
            return;
        }
        const xDiff = this.xCoordinate - event.touches[0].pageX;

        if (xDiff > 0 && this.navControls.rightArrowElm.classList.contains(CLASSES.NAV_IS_ACTIVE)) {
            // left swipe
            this.navControls.rightArrowElm.click();
        } else if (xDiff < 0 &&
            this.navControls.leftArrowElm.classList.contains(CLASSES.NAV_IS_ACTIVE)) {
            // right swipe
            this.navControls.leftArrowElm.click();
        }
    }
}

export default MultiSlideCarousel;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

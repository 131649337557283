/**
 * Template for Specialty Page Click Shield
 * Uses ES6 template literal to composite the markup for the overlay
 */
import { htmlNode } from 'utils';

export default () => htmlNode`
<section id="webAnimationTestWrapper">
    <style>
        #webAnimationTestWrapper {
            position: fixed;
            bottom: 0;
            right: 0;
            line-height: 1.6rem;
            width: 1px;
            height: 1px;
            z-index: -1;
            overflow: hidden;
            -moz-user-select: none;
            user-select: none;
            opacity: 0.01;
            pointer-events: none;
        }
        #webAnimationTestWrapper *, #webAnimationTestWrapper *:before {
          box-sizing: border-box;
        }
        #webAnimationTestWrapper h2 {
          margin-bottom: 1rem;
        }
        #webAnimationTestWrapper p, #webAnimationTestWrapper p + ul {
          margin-bottom: 2rem;
        }
        #webAnimationTestWrapper li::before, #webAnimationTestWrapper span::before {
          content: 'N';
          color: white;
          text-align: center;
          font-size: 0.9em;
          vertical-align: text-top;
          padding-top: .1rem;
          width: 1rem;
          height: 1rem;
          background-color: red;
          display: inline-block;
          margin: 0 .5rem;
          line-height: 0.9rem;
          font-weight: bold;
        }
        #webAnimationTestWrapper li.deprecated, #webAnimationTestWrapper span.deprecated {
          opacity: .4;
        }
        #webAnimationTestWrapper li.deprecated::before, #webAnimationTestWrapper span.deprecated::before {
          background-color: black;
          content: "O";
        }
        #webAnimationTestWrapper li.passed::before, #webAnimationTestWrapper span.passed::before {
          background-color: green;
          border-radius: 50%;
          content: "S";
        }
        #webAnimationTestWrapper li.passed.deprecated::before, #webAnimationTestWrapper span.passed.deprecated::before {
          content: 'O';
        }
        #webAnimationTestWrapper li li, #webAnimationTestWrapper span li {
          margin-left: 1rem;
        }
        #webAnimationTestWrapper li:target {
          border-left: .5rem solid orange;
        }
        @-webkit-keyframes pulse {
          50% {
            background: orange;
          }
        }
        @keyframes pulse {
          50% {
            background: orange;
          }
        }
        #webAnimationTestWrapper aside {
          opacity: 0.05;
        }
        #webAnimationTestWrapper aside div {
          display: inline-block;
          transition: opacity 100s linear;
        }
        #webAnimationTestWrapper #css-anim {
          -webkit-animation: pulser 1s 0s infinite alternate linear;
                  animation: pulser 1s 0s infinite alternate linear;
        }
        @-webkit-keyframes pulser {
          100% {
            opacity: 0;
          }
        }
        @keyframes pulser {
          100% {
            opacity: 0;
          }
        }
    </style>
    <ul>
        <li id="test-animate">Element.animate() support
            <ul>
                <li id="test-animate-kfa" class="sub">First parameter as array of keyframes like: [{opacity: 1}, {opacity: 0}]</li>
                <li id="test-animate-kfo" class="sub">First parameter as object with property arrays like: { opacity: [1, 0] }</li>
                <li id="test-animate-single" class="sub">Single keyframe (<a href="https://codepen.io/danwilson/pen/qrMpWP/" target="_blank" tabindex="-1">Example</a>)</li>
            </ul>
        </li>
        <li id="test-kec">KeyframeEffect constructor</li>
    </ul>

    <aside>
        <div id="waapi-anim">Infinite WAAPI Animation</div>
        <div id="css-anim">Infinite CSS Animation</div>
        <div id="css-tran">Long CSS Transition</div>
    </aside>
</section>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { screen, ClickOutside, renderer } from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    SUB_PAGE_NAV: 'sub-page-nav',
    NAV_DROPDOWN: 'sub-page-nav--dropdown',
    NAV_NOT_READY: 'sub-page-nav__container--hidden',
    NAV_DROPDOWN_OPEN: 'sub-page-nav--dropdown-open',
    NAV_DROPDOWN_TOGGLE: 'sub-page-nav__dropdown-toggle',
    NAV_LIST: 'sub-page-nav__items-list',
    NAV_LIST_LINK: 'sub-page-nav__item-link',
    NO_SCROLL: 'no-scroll'
};

/**
 * @const ARIA_ATTRIBUTES
 * @description Collection of constant values for related aria attributes of the module
 * @type {{EXPANDED: string}}
 */
const ARIA_ATTRIBUTES = {
    EXPANDED: 'aria-expanded'
};

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related attributes of the module
 * @type {{TABINDEX: string}}
 */
const ATTRIBUTES = {
    TABINDEX: 'tabindex'
};

/**
 * @const LOCAL_CONSTANTS
 * @description Stores a collection of constants names
 * @property {int} OFFSET_PADDING offset used to calculate the padding of the navitems container
 */
const LOCAL_CONSTANTS = {
    OFFSET_PADDING: 20
};

/**
 * @class SubPageNav
 * @description component for the SubPageNav partial
 */
export default class SubPageNav {
    /**
     * @constructor
     * @param element {Object}
     */
    constructor(element) {
        this.element = element;

        // method aliases
        this.onToggleClick = this.onToggleClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);

        this.init();
    }

    /**
     * @method init
     * @description Caches DOM, Attaches events
     */
    init() {
        this.cacheDOM();
        this.attachEvents();
        this.setMenuDisplayType();
        this.markAsReady();
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.bodyElm = document.body;
        this.toggleBtnElm = this.element.querySelector(`.${CLASSES.NAV_DROPDOWN_TOGGLE}`);
        this.listItemsElms = [...this.element.querySelectorAll(`.${CLASSES.NAV_LIST_LINK}`)];
        this.itemList = this.element.querySelector(`.${CLASSES.NAV_LIST}`);
        this.subNavSection = this.bodyElm.querySelector(`.${CLASSES.SUB_PAGE_NAV}`);
    }

    /**
     * @method attachEvents
     * @description handles event listeners for our click events
     */
    attachEvents() {
        this.accordionClickHandler = new ClickOutside(
            this.toggleBtnElm,
            this.onToggleClick,
            this.closeMenu,
            this.element.parentElement);
        screen.addResizeListener(this.onScreenResize);
    }

    /**
     * @method markAsReady
     * @description remove class which hides the sub-page navigation,
     * after checking if it is a dropdown (prevents FOUC)
     */
    markAsReady() {
        this.element.classList.remove(CLASSES.NAV_NOT_READY);
    }

    /**
     * @method onScreenResize
     * @description handles the screen rezise event and check for items size
     * @param newScreenState the new screen state
     */
    onScreenResize(newScreenState) {
        this.setMenuDisplayType();
        if (!newScreenState.small) {
            this.bodyElm.classList.remove(CLASSES.NO_SCROLL);
        }
    }

    /**
     * @method setMenuDisplayType
     * @description Checks combined width of all list items. If combined width is greater
     * than container's width, menu is displayed as dropdown
     */
    setMenuDisplayType() {
        const itemsWidthSum = this.listItemsElms.reduce((size, item) => {
            size += item.clientWidth;
            return size;
        }, 0);
        const dropdownMode =
              itemsWidthSum >= (this.itemList.clientWidth - LOCAL_CONSTANTS.OFFSET_PADDING);
        this.element.classList[dropdownMode ? 'add' : 'remove'](CLASSES.NAV_DROPDOWN);

        this.listItemsElms.forEach((item) => {
            item[dropdownMode ? 'setAttribute' : 'removeAttribute'](ATTRIBUTES.TABINDEX, '-1');
        });
    }

    /**
     * @method onToggleClick
     * @description click handler for toggle button
     */
    onToggleClick() {
        this[this.element.classList.contains(CLASSES.NAV_DROPDOWN_OPEN) ? 'closeMenu' : 'openMenu']();
    }

    /**
     * @method openMenu
     * @description open the dropdown menu
     */
    openMenu() {
        this.toggleBtnElm.setAttribute(ARIA_ATTRIBUTES.EXPANDED, 'true');
        this.element.classList.add(CLASSES.NAV_DROPDOWN_OPEN);
        if (this.element.classList.contains(CLASSES.NAV_DROPDOWN)) {
            this.listItemsElms.forEach((item) => {
                item.removeAttribute(ATTRIBUTES.TABINDEX);
            });
        }

        if (screen.getState().small) {
            this.bodyElm.classList.add(CLASSES.NO_SCROLL);
            renderer.insertAdjacentElement(this.element, this.bodyElm, 'afterbegin');
        }
    }

    /**
     * @method closeMenu
     * @description close the dropdown menu
     */
    closeMenu() {
        this.toggleBtnElm.setAttribute(ARIA_ATTRIBUTES.EXPANDED, 'false');
        this.element.classList.remove(CLASSES.NAV_DROPDOWN_OPEN);
        if (this.element.classList.contains(CLASSES.NAV_DROPDOWN)) {
            this.listItemsElms.forEach((item) => {
                item.setAttribute(ATTRIBUTES.TABINDEX, '-1');
            });
        }

        if (screen.getState().small) {
            this.bodyElm.classList.remove(CLASSES.NO_SCROLL);
            renderer.insertAdjacentElement(this.element, this.subNavSection, 'afterbegin');
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import {
    renderer,
    noop
} from 'utils';

// Partial dependencies
import { UserProfile } from 'partials/profile';
import { SlimDealer } from 'partials/dealer';

/**
 * View responsible for rendering the DealerSelectorDealerList module
 */
export default class DealerSelectorDealerList {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     * @param {array} dealers - Array of dealer objects from API response
     * @param {object} callbacks - Callback methods
     * @param {function} callbacks.onDealerSelect - Callback method applied on selecting a dealer
     * @param {function} callbacks.onDealerHover - Callback method applied on hovering over dealer
     */
    constructor(
        dealers,
        {
            onDealerSelect = noop,
            onDealerHover = noop
        } = {}) {
        this.dealers = dealers;
        this.onDealerSelect = onDealerSelect;
        this.onDealerHover = onDealerHover;
        this.resultsList = [];
        this.profile = new UserProfile();
        this.element = renderer.fromTemplate('<div class="dealer-selector-list__results-list-container"></div>');

        // method aliases
        this.hoverDealer = this.hoverDealer.bind(this);
        this.selectDealer = this.selectDealer.bind(this);

        this.createDealerList();
    }

    /**
     * @method createDealerList
     * @description Creates a list of DealerInfo instances and renders them
     * to the results list element
     */
    createDealerList() {
        if (this.dealers && this.dealers.length > 0) {
            this.dealers.forEach((dealerItem) => {
                const result = new SlimDealer({
                    dealer: dealerItem,
                    isPreferred: this.isPreferredDealer(dealerItem),
                    onSelectDealer: this.selectDealer,
                    onHoverDealer: this.hoverDealer
                });

                this.resultsList.push(result);
                this.element.appendChild(result.render());
            });
        }
    }

    /**
     * @method isPreferredDealer
     * @description Checks if dealer is preferred dealer
     * @param dealer {Object} Dealer to check
     */
    isPreferredDealer(dealer) {
        const preferredDealer = this.profile.getPreferredDealer();

        return preferredDealer &&
            preferredDealer.dealerId === dealer.dealerId &&
            preferredDealer.zip === dealer.zip;
    }

    /**
     * @method selectDealer
     * @description Applies callback `onDealerSelect` with selected dealer
     */
    selectDealer(dealer) {
        this.onDealerSelect(dealer);
    }

    /**
     * @method hoverDealer
     * @description Applies callback `onDealerHover` with hovered dealer
     */
    hoverDealer(dealer) {
        this.onDealerHover(dealer);
    }

    /**
     * @method destroy
     * @description Destroys the view by destroying the dealer results and
     * deleting element from the DOM
     */
    destroy() {
        this.resultsList.forEach((result) => result.destroy());
        this.element.remove();
    }

    /**
     * @method render
     * @description Renders the element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

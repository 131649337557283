
let specialOffersCache = null;
let hasLoggedErrors = false;

/**
 * @function getSpecialOffers
 * @param {object} config Payment estimator configuration
 * @return {Promise} all special offers
 */
function getSpecialOffers(config) {
    if (!specialOffersCache) {
        specialOffersCache = fetch(config.specialOffersApiUrl)
            .then((response) => response.json());
    }
    return specialOffersCache;
}

/**
 * @function formatOffer
 * @description derives a compact object from a full offer
 * @param {object} selectedOffer full selected offer object
 * @returns {object}
 */
export function formatOffer(selectedOffer) {
    if (!selectedOffer) {
        return null;
    }
    return {
        offerCode: selectedOffer.offerCode,
        apr: parseFloat(selectedOffer.offerSummary.rateValueApr, 10),
        term: parseInt(selectedOffer.offerSummary.rateValueTermLength, 10),
        mileage: parseInt((selectedOffer.offerSummary.rateValueAnnualMileage || '').replace(/\D/g, ''), 10),
    };
}

/**
 * @function validateSpecialOffer
 * @description checks if a previously selected offer is still valid
 * @param {object} selectedOffer
 * @param {array} specialOffers available offers
 * @returns the offer if it is valid, null otherwise
 */
export function validateSpecialOffer(selectedOffer, specialOffers) {
    if (specialOffers && specialOffers.length) {
        if (selectedOffer && specialOffers.some((offer) => offer.offerCode === selectedOffer.offerCode)) {
            return selectedOffer;
        }
        return formatOffer(specialOffers[0]);
    }
    return null;
}

/**
 * @function getSpecialOffersForVehicleByType
 * @description filters the full list of offers to those valid for this vehicle and type
 * @param {array} specialOffers full list of offers
 * @param {object} vehicle
 * @param {string} type finance or lease
 * @returns
 */
function getSpecialOffersForVehicleByType(specialOffers, vehicle, type) {
    const specialOffersForVehicle = [];
    const offerTypeMap = {
        finance: 'SPECIAL_APR_FINANCE',
        lease: 'SPECIAL_LEASE_RATE',
    };
    const isFinance = type === 'finance';
    const isLease = type === 'lease';

    const isDefined = (prop) => !/undefined|null|none/i.test(`${prop}`);

    let hasMissingData = false;
    ((specialOffers || {}).splOfferGroups || []).forEach((group) => {
        if (group.vehicleClass.code === vehicle.class) {
            group.subHeads.forEach((subgroup) => {
                subgroup.offerPages.forEach((offerPage) => {
                    const { offerSummary } = offerPage;
                    // ensure that the offer has the required rateValue attributes
                    if (
                        !isDefined(offerSummary.rateValueTermLength) ||
                        (isFinance && !isDefined(offerSummary.rateValueApr)) ||
                        (isLease && !isDefined(offerSummary.rateValueAnnualMileage))
                    ) {
                        if (!hasMissingData && !hasLoggedErrors) {
                            hasMissingData = true;
                            console.groupCollapsed('%c Special Offers w/missing data', 'color: #FF9900');
                        }
                        // required data is missing...
                        // ...log a warning...
                        if (!hasLoggedErrors) {
                            console.warn(`Offer, code, ${offerPage.offerCode}, "${offerSummary.offerTagline}", is missing rate values`);
                        }
                        // ...and skip this one.
                        return;
                    }
                    // ensure that the offer is of the same type and for this model.
                    if (
                        offerSummary.offerType === offerTypeMap[type] &&
                        offerSummary.vehicleYear === vehicle.year &&
                        offerSummary.bodyStyleClasses.some((bodyStyleClass) =>
                            bodyStyleClass.vehicleModels.some((model) =>
                                // is it the same model code?
                                vehicle.model === model.code
                            )
                        )
                    ) {
                        specialOffersForVehicle.push(offerPage);
                    }
                });
            });
        }
    });
    if (hasMissingData) {
        console.groupEnd();
        hasLoggedErrors = true;
    }

    specialOffersForVehicle.sort((offerA, offerB) => {
        const a = offerA.offerSummary;
        const b = offerB.offerSummary;

        // APR
        const aprDifference = a.rateValueApr - b.rateValueApr;
        if (isFinance && aprDifference) {
            // sort low to high
            return aprDifference < 0 ? -1 : 1;
        }

        // Term
        const termDifference = a.rateValueTermLength - b.rateValueTermLength;
        if (termDifference) {
            // sort high to low
            return termDifference > 0 ? -1 : 1;
        }

        // Mileage
        const mileageDifference = a.rateValueAnnualMileage - b.rateValueAnnualMileage;
        if (isLease && mileageDifference) {
            // sort low to high
            return mileageDifference < 0 ? -1 : 1;
        }

        return 1;
    });

    return specialOffersForVehicle;
}

/**
 * @function getSpecialOffersForVehicle
 * @description gets all special offers for the given vehicle
 * @param {object} config payment estimator config
 * @param {object} vehicle
 * @returns {object} a key for each payment type (lease and finance)
 */
export async function getSpecialOffersForVehicle(config, vehicle) {
    const specialOffers = await getSpecialOffers(config);

    return {
        finance: getSpecialOffersForVehicleByType(specialOffers, vehicle, 'finance'),
        lease: getSpecialOffersForVehicleByType(specialOffers, vehicle, 'lease'),
    };
}


export default {};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for contents of Inventory Redirect Modal
 */
import { htmlNode } from 'utils';

export default (content = {}) => htmlNode`
    <div class="inventory-simple-redirect">
        <h2 class="inventory-simple-redirect__heading">${content.moduleHeading}</h2>
        <p class="inventory-simple-redirect__subheading">${content.moduleDescription}</p>
        <form class="inventory-simple-redirect__form" data-search-form novalidate="true">
            <div class="form__input-wrapper">
                <input class="form__input-field"
                    required
                    data-search-input=""
                    id="inventorySimpleRedirectInput"
                    autoComplete="off" />
                <label class="form__input-label" for="inventorySimpleRedirectInput"> 
                    ${content.searchInputLabel}
                </label>
            </div>
            <button class="inventory-simple-redirect__cta button button_primary button--wide" data-analytics-trigger="cta">
                ${content.searchCTA}
            </button>
        </form>
    </div>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d


/**
 * @description Template for forms vehicle selector
 * Uses ES6 template literal to composite the markup for a vehicle selector
 */

// Util dependencies
import { htmlNode } from 'utils';
import { country } from '../../../../plugins/byo/js/config';
import constants from '../../../../plugins/byo/js/config/constants';

const renderPrice = (chosenVehicle, priceComingSoonLabel, startingAtLabel, msrpDisclaimerLegend) => {
    if (country !== constants.COUNTRY.CA.toLowerCase()) {
        return (`
        <span class="vehicle-selector__label">
        ${chosenVehicle.msrp === 999999 ?
            `${priceComingSoonLabel}` :
            `${startingAtLabel} <strong>${chosenVehicle.msrpFormatted}</strong>
            ${msrpDisclaimerLegend ? `<a href="#disclaimer-drawer" aria-controls="disclaimer-drawer" class="disclaimer__marker" data-legend="${msrpDisclaimerLegend}" data-chosen-vehicle-disclaimer>${msrpDisclaimerLegend}</a>` : ''}`
        }
        </span>
        `
        );
    }
    return '';
};

export default ({ chosenVehicle = {}, startingAtLabel = '', priceComingSoonLabel = '', msrpDisclaimerLegend = '', showYear = false }) => htmlNode`
    <div class="vehicle-selector__chosen-vehicle" data-analytics-container="contact-selected-vehicle:${chosenVehicle.class}:${chosenVehicle.model}:${chosenVehicle.bodystyle}:${chosenVehicle.year}">
        <h4 class="vehicle-selector__model-name">${showYear ? chosenVehicle.nameWithYear : chosenVehicle.name}</h4>
        ${
            renderPrice(chosenVehicle, priceComingSoonLabel, startingAtLabel, msrpDisclaimerLegend)
        }
        <img class="vehicle-selector__jellybean" src="${chosenVehicle.jellybean}" alt="" />
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constants dependencies
import { EVENTS, KEYBOARD } from 'Constants';

// Util dependencies
import {
    ClickOutside,
    screen,
    generateUniqueID,
    noop,
    findAncestor
} from 'utils';

// Local dependencies
import toolTipTemplate from './../templates/toolTipTemplate';

/**
 * @constant ATTRIBUTES
 * @description Map of attribute names for ToolTip elements
 * @type {{ICON: string, FLYOUT: string, POSITION: string}}
 */
const ATTRIBUTES = {
    ICON: 'data-tool-tip-icon',
    FLYOUT: 'data-tool-tip-flyout',
    CONTENT: 'data-tool-tip-content',
    POSITION: 'data-tool-tip-position',
    ARIA_HIDDEN: 'aria-hidden'
};

/**
 * @constant CLASSES
 * @description Map of class names for ToolTip elements
 * @type {{ACTIVE: string}}}
 */
const CLASSES = {
    TOOL_TIP: 'tool-tip',
    ACTIVE: 'tool-tip--active'
};

/**
 * @const DEFAULT_LOCALIZATION
 * @description Default localization labels
 * skipTo: The label for the "Skip to" cta
 * @type {{SKIP_TO: String}}
 */
const DEFAULT_LOCALIZATION = {
    LABEL: 'More Info'
};


/**
 * @property defaultConfig
 * @description Default configuration option for the ToolTip
 * @type {{
 *  position: string,
 *  constraint: HTMLElement,
 *  label: string,
 *  size: string,
 *  content: null,
 *  onOpen: *,
 *  onClose: *
 * }}
 */
const defaultConfig = {
    position: 'center',
    constraint: document.body,
    label: DEFAULT_LOCALIZATION.LABEL,
    size: 'default',
    content: null,
    onOpen: noop,
    onClose: noop,
    analyticsTrigger: 'cta',
};

/**
 * @class ToolTip
 * @description View responsible for displaying a tool-tip element with an
 * icon that when clicked opens a flyout with additional content
 */
export default class ToolTip {
    /**
     * @static
     * @description Available sizes to set the tool tip flyout
     *
     * Note: using the `CUSTOM` size will require the user to
     * implement their own css to set the size of the flyout
     * @type {{DEFAULT: string, CUSTOM: string}}
     */
    static SIZE = {
        DEFAULT: 'default',
        CUSTOM: 'custom-size'
    };

    /**
     * @static
     * @description Available positions to set the tool tip flyout
     * @type {{LEFT: string, CENTER: string, RIGHT: string}}
     */
    static POSITION = {
        LEFT: 'left',
        CENTER: 'center',
        RIGHT: 'right'
    };

    /**
     * @constructor
     * @description On instantiation, sets the instance properties, configuration and
     * method aliases, then initializes the view
     * @param content {Node} DOM element to set at the flyout content
     * @param config {Object} Configurable options containing: {
     *     position {String} Position to set the flyout (options: left, center, right),
     *     constraint {Node} DOM element to constrain the overflow of the flyout from,
     *     label {String} Localized label for tool tip icon
     *     size {string} Size to set the tool tip flyout
     *     onOpen {Function} Callback method applied when the tool tip opens
     *     onClose {Function} Callback method applied when the tool tip closes
     * }
     */
    constructor(content, config = {}) {
        this.element = null;
        this.contentElm = null;
        this.flyoutElm = null;
        this.content = content;
        this.clickHandler = null;
        this.isActive = false;
        this.id = generateUniqueID();
        this.config = {
            ...defaultConfig,
            ...config,
            label: config.label || defaultConfig.label,
            analyticsTrigger: config.analyticsTrigger || defaultConfig.analyticsTrigger
        };
        // method aliases
        this.toggle = this.toggle.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);

        // initialize
        this.create();
        this.cacheDOM();
        this.attachEvents();
        this.renderContent();
    }

    /**
     * @method create
     * @description Creates a tool tip element from the the toolTipTemplate
     */
    create() {
        this.element = toolTipTemplate(
            this.config.analyticsTrigger,
            this.config.label,
            this.id,
            this.config.size
        )({ getNode: true });
    }

    /**
     * @method destroy
     * @description Destroys the view by detaching the events
     */
    destroy() {
        this.detachEvents();
        this.detachDocumentKeyDown();
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the view
     */
    cacheDOM() {
        this.iconElm = this.element.querySelector(`[${ATTRIBUTES.ICON}]`);
        this.flyoutElm = this.element.querySelector(`[${ATTRIBUTES.FLYOUT}]`);
    }

    /**
     * @method attachEvents
     * @description Creates a ClickOutside instance to handle the click events of
     * the tool tip, and adds an event listener to set the position of the flyout
     * when the screen resizes
     */
    attachEvents() {
        this.clickHandler = new ClickOutside(
            this.iconElm,
            this.toggle,
            this.close,
            this.flyoutElm
        );
        this.element.addEventListener(EVENTS.KEYDOWN, this.onKeyDown, true);

        screen.addResizeListener(this.setPosition);
    }

    /**
     * @method detachEvents
     * @description Detaches the clickHandler instance
     */
    detachEvents() {
        this.clickHandler.destroy();
        this.clickHandler = null;
        this.element.removeEventListener(EVENTS.KEYDOWN, this.onKeyDown, true);

        screen.removeResizeListener(this.setPosition);
    }

    /**
     * @method attachDocumentKeyDown
     * @description Attaches the keyDown instance on the document
     */
    attachDocumentKeyDown() {
        document.addEventListener(EVENTS.KEYDOWN, this.onDocumentKeyDown, true);
    }

    /**
     * @method detachDocumentKeyDown
     * @description Detaches the keyDown instance on the document
     */
    detachDocumentKeyDown() {
        document.removeEventListener(EVENTS.KEYDOWN, this.onDocumentKeyDown, true);
    }

    /**
     * @method getPosition
     * @description Sets the position of the tooltip content flyout to fit within
     * the this.config.constraint element
     */
    setPosition() {
        if (!this.isActive) {
            return;
        }

        // set default styling to test the position
        this.flyoutElm.style.display = 'block';
        this.flyoutElm.style.left = '';
        this.flyoutElm.setAttribute(ATTRIBUTES.POSITION, this.config.position);

        const isSmall = screen.getState().small;
        const constraintElm = this.flyoutElm.closest('dialog') || this.config.constraint;
        const constraintPosition = constraintElm.getBoundingClientRect();
        const flyoutPosition = this.flyoutElm.getBoundingClientRect();
        let positionAttribute = this.config.position;
        let positionLeft = '';

        if (
            !isSmall &&
            flyoutPosition.left < constraintPosition.left &&
            flyoutPosition.right < constraintPosition.right
        ) {
            // if the position of the flyout is not contained by
            // the left of the constraint, set to left align
            positionAttribute = ToolTip.POSITION.LEFT;
        } else if (
            !isSmall &&
            flyoutPosition.right > constraintPosition.right &&
            flyoutPosition.left > constraintPosition.left
        ) {
            // if the position of the flyout is not contained by
            // the right of the constraint, set to right align
            positionAttribute = ToolTip.POSITION.RIGHT;
        } else if (
            !isSmall &&
            flyoutPosition.left < constraintPosition.left &&
            flyoutPosition.right > constraintPosition.right
        ) {
            // if the position of the flyout is not contained by
            // either the left or right of the constraint, set to center align
            positionAttribute = ToolTip.POSITION.CENTER;
        } else if (isSmall) {
            // if the device is small, center the flyout to the center of the window
            const centerPosition = (document.body.clientWidth - flyoutPosition.width) / 2;
            const leftPosition = flyoutPosition.left - centerPosition;
            positionAttribute = '';
            positionLeft = `-${leftPosition}px`;
        }

        this.flyoutElm.style.display = '';
        this.flyoutElm.style.left = positionLeft;
        this.flyoutElm.setAttribute(ATTRIBUTES.POSITION, positionAttribute);
    }

    /**
     * @method toggle
     * @description Toggles the active state of the tool tip
     * @param e {Event} Dom event from click handler
     */
    toggle(e) {
        if (e) {
            e.preventDefault();
        }

        if (!this.isActive) {
            this.open();
        } else {
            this.close();
        }
    }

    /**
     * @method open
     * @description Sets the tool tip to active and sets the flyout position
     */
    open() {
        this.isActive = true;
        this.element.classList.add(CLASSES.ACTIVE);
        this.flyoutElm.setAttribute(ATTRIBUTES.ARIA_HIDDEN, 'false');
        this.setPosition();
        this.attachDocumentKeyDown();
        this.config.onOpen();
    }

    /**
     * @method close
     * @description Sets the tool tip to inactive
     */
    close() {
        this.isActive = false;
        this.element.classList.remove(CLASSES.ACTIVE);
        this.flyoutElm.setAttribute(ATTRIBUTES.ARIA_HIDDEN, 'true');
        this.detachDocumentKeyDown();
        this.config.onClose();
    }

    /**
     * @method onKeyDown
     * @description Toggles tooltip with Enter key
     */
    onKeyDown(e) {
        if (e.keyCode === KEYBOARD.ENTER || e.keyCode === KEYBOARD.ESC || e.keyCode === KEYBOARD.SPACE) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.keyCode === KEYBOARD.ENTER || e.keyCode === KEYBOARD.SPACE) {
            this.toggle();
        } else if (e.keyCode === KEYBOARD.ESC) {
            this.close();
        }
    }

    /**
     * @method onDocumentKeyDown
     * @description Closes the tooltip with ESC key
     */
    onDocumentKeyDown(e) {
        if (findAncestor(e.target, `.${CLASSES.TOOL_TIP}`, true)) {
            return;
        }

        e.stopPropagation();
        if (e.keyCode === KEYBOARD.ESC) {
            this.close();
        }
    }

    /**
     * @renderContent
     * @description Renders the content element to the flyout
     */
    renderContent() {
        if (this.config.content) {
            this.contentElm.appendChild(this.config.content);
        }

        if (this.content !== null) {
            this.flyoutElm.appendChild(this.content);
        }
    }

    /**
     * @method render
     * @description Returns the tool tip DOM element
     * @return {*|null}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

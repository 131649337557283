/**
 * @const COOKIES
 */
export default {
    AUTH_REDIRECT: 'MBUSA_AUTH_REDIRECT', // callback URL after successful auth action (log in, log out, register, etc.)
    AUTH_EMAIL_CHANGED: 'MBUSA_AUTH_EMAIL_CHANGED',
    AUTH_PASSWORD_CHANGED: 'MBUSA_AUTH_PASSWORD_CHANGED',
    HASHED: 'mbusa_hashed',
    LOGIN_COMPLETE: 'mbusa_login_complete',
    FIRST_LOGIN_COMPLETE: 'mbusa_first_login_complete',
    PROFILE_INFO: 'mbusa_profile_info', // user profile info (initially, first name only)
    QUICK_REGISTER_ID: 'MBUSA_AUTH_QUICK_REGISTER_ID', // user's CIAM ID
    QUICK_REGISTER_EMAIL: 'MBUSA_AUTH_QUICK_REGISTER_EMAIL', // user's CIAM email address
    RECENTLY_VIEWED_RESERVE: 'MBUSA_RECENTLY_VIEWED_RESERVE', // vehicle data of vehicle that was recently viewed in the commerce (VehicleShopping) flow
    SHOW_TERMS: 'mbusa_show_terms' // indicates first visit in authenticated state; user must accept terms & conditions before continuing with site
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

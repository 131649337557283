import { renderer } from 'utils';

import tabPanelTemplate from './../templates/tabPanelTemplate';

/**
 * @const CLASSES
 * @description CSS classes for Tabs
 */
const CLASSES = {
    ACTIVE: 'tab-panel__active'
};

/**
 * @class TabPanel
 * @description Class for panel
 */
export default class TabPanel {
    /**
     * @constructor
     * @description Constructs the TabPanel
     * @param {HTMLElement} content - DOM Element to be inserted into panel
     */
    constructor({ id, content }) {
        this.element = tabPanelTemplate(id)({ getNode: true });
        renderer.insert(content, this.element);
    }

    /**
     * @method activate
     * @description Activate the tab panel
     */
    activate() {
        this.element.classList.add(CLASSES.ACTIVE);
    }

    /**
     * @method deactivate
     * @description Deactivate the tab panel
     */
    deactivate() {
        this.element.classList.remove(CLASSES.ACTIVE);
    }

    /**
     * @method render
     * @description Returns the DOM element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

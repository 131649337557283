/**
 * API responsible to get and set values for MBOW_RESERVE_PRICE_VEHICLES cookie
 */
import { cookie } from 'utils';


 /**
 * @const COOKIES
 * @description A collection of cookie name values for utility
 * @type {{
 *   RESERVE_PRICE_VEHICLES: string, // Caches Vins for reserve price vehicles
 *   RESERVE_VEHICLES_ALERT: string of 2 boolean values
 * }}
 */
const COOKIES = {
    RESERVE_PRICE_VEHICLES: 'MBOW_RESERVE_PRICE_VEHICLES',
    RESERVE_VEHICLES_ALERT: 'MBOW_RESERVE_VEHICLE_ALERT'
};

/**
 * @method validateReservePriceVehicleCookie
 * @description Validates that a vin is defined as a cookie
 * @param vin {String} Vin to validate
 * @return {boolean}
 */
export function validateReservePriceVehicleCookie(vin) {
    const reservePriceCookie = cookie.getCookie(COOKIES.RESERVE_PRICE_VEHICLES);

    return reservePriceCookie.indexOf(vin) !== -1;
}

/**
 * @method
 * @description Sets a cookie that stores a collection of vins that the reserve price
 * has been viewed for
 * @param vin {String} Vin to add to cookie
 */
export function setReservePriceVehiclesCookie(vin) {
    const reservePriceCookie = cookie.getCookie(COOKIES.RESERVE_PRICE_VEHICLES);
    const selectedVins = reservePriceCookie ? reservePriceCookie.split(',') : [];

    selectedVins.push(vin);

    cookie.setCookie({
        name: COOKIES.RESERVE_PRICE_VEHICLES,
        value: selectedVins.join(','),
    });
}

/**
 * @method setReserveVehicleAlertCookie
 * @description Creates a cookie that stores values of whether the first time popup has been displayed before
 * and whether popup has been permanently dismissed, sets expiry time to 1hour or default session (30 days)
 * @param dismissAlert {Boolean} Indicator to set as the "do not show" value of the cookie
 */
export function setReserveVehicleAlertCookie(dismissAlert) {
    const date = new Date();
    date.setTime(date.getTime() + (60 * 60 * 1000));
    const expiresTime = dismissAlert ? '' : date.toGMTString();

    if (!cookie.getCookie(COOKIES.RESERVE_VEHICLES_ALERT)) {
        cookie.setCookie({
            name: COOKIES.RESERVE_VEHICLES_ALERT,
            value: `true,${dismissAlert}`,
            expires: expiresTime,
        });
    }
}

/**
 * @method checkReservePriceVehiclesCookie
 * @description Checks whether 'MBOW_RESERVE_PRICE_VEHICLES' cookie exists.
 */
export function checkReservePriceVehiclesCookie() {
    return cookie.cookieExists(COOKIES.RESERVE_PRICE_VEHICLES);
}

/**
 * @method checkReserveVehicleAlertCookie
 * @description Checks whether COOKIES.RESERVE_VEHICLES_ALERT cookie exists
 */
export function checkReserveVehicleAlertCookie() {
    return cookie.cookieExists(COOKIES.RESERVE_VEHICLES_ALERT);
}

export default {
    checkReservePriceVehiclesCookie,
    checkReserveVehicleAlertCookie,
    setReservePriceVehiclesCookie,
    setReserveVehicleAlertCookie,
    validateReservePriceVehicleCookie,
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { serializeObject as serialize } from 'utils';

export const ERROR_STATUS = {
    VIN_ERROR: 'No VIN',
    SERVICE_ERROR: 'Service Error'
};

/**
 * @function searchRecalls
 * @description Submits the fleet lead form
 * @param {string} vin - VIN
 * @param {string} recaptchaResponse - recaptcha Response
 * @param {string} recallsEndpoint - Endpoint for Recalls API
 * @returns {Promise} A promise which resolves with API response when submitted
 */
export function searchRecalls(vin, recaptchaResponse, recallsEndpoint) {
    const endpoint = `${recallsEndpoint}${serialize({ 'g-recaptcha-response': recaptchaResponse })}`;

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin'
        },
        body: JSON.stringify({ vin })
    })
    .then((response) => response.json())
    .then((response) => {
        if (response.result.page.status === 'vinError') {
            throw ERROR_STATUS.VIN_ERROR;
        } else if (response.status.code !== 200) {
            throw ERROR_STATUS.SERVICE_ERROR;
        } else {
            return response;
        }
    });
}

/**
 * @function getWarranty
 * @description Get warranty details
 * @param {string} modelYear - model year
 * @param {string} modelCode - model code
 * @param {string} warrantyEndpoint - Endpoint for Warranty API
 * @returns {Promise} A promise which resolves with API response when submitted
 */
export function getWarranty(modelYear, modelCode, warrantyEndpoint) {
    return fetch(`${warrantyEndpoint}/${modelYear}/${modelCode}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin'
        }
    })
    .then((response) => response.json())
    .then((response) => {
        if (response.status.code !== 200) {
            throw ERROR_STATUS.SERVICE_ERROR;
        } else {
            return response.result.payload;
        }
    });
}

/**
 * @function submitTakataLead
 * @description Submits the takata stay informed form
 * @param {object} data - Data object
 * @param {string} stayInformedEndpoint - Endpoint for takata stay informed form
 * @returns {Promise} A promise which resolves with the API response when submitted
 */
export function submitTakataLead(stayInformedEndpoint, data) {
    return fetch(stayInformedEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin'
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((response) => {
        if (response.status.code !== 200) {
            throw ERROR_STATUS.SERVICE_ERROR;
        } else {
            return response;
        }
    });
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @function
 * @name renderChangeCta
 * @description render 'Change' CTA if enabled
 * @return {String} template string
 */
const renderChangeCta = (content, disableChange) => {
    if (disableChange) {
        return '';
    }
    return `<button type="button" 
                    class="dealer-info-card__change-button link link_plain-link" 
                    data-analytics-trigger="cta"
                    data-change-dealer="">${content.change}</button>`;
};

/**
 * @function
 * @name renderCtas
 * @description render 'View Dealer Website' and 'Get Directions' CTAs if enabled
 * @return {String} template string
 */
const renderCtas = (dealer, content, disableCtas) => {
    if (disableCtas) {
        return '';
    }

    return `<ul class="dealer-info-card__link-list">
                <li class="dealer-info-card__link-list-item">
                    <h2>
                        <a class="link link_plain-link" href="${dealer.url}" target="_blank" rel="noopener"
                        data-analytics-trigger="cta">
                        ${content.viewDealerWebsite}
                        </a>
                    </h2>
                </li>
                <li class="dealer-info-card__link-list-item">
                    <a class="link link_plain-link" href="${dealer.directionsLink}" target="_blank" rel="noopener"
                       data-analytics-trigger="cta" itemprop="maps">
                        ${content.getDirections}
                    </a>
                </li>
            </ul>`;
};

/**
 * @description render 'Contact Dealer' CTA id enabled
 * @param content {Object} Content object with key/values
 * @param disableCta {Boolean} Indicator to disable displaying cta button
 * @return {*}
 */
const renderContactCta = (content, disableCta) => {
    if (disableCta) {
        return '';
    }

    return `<div>
                <button class="button button_primary button--wide"
                        data-analytics-trigger="cta-contact-dealer"
                        data-contact-dealer="">
                         ${content.contactDealer}
                </button>
            </div>`;
};

/**
 * @description render Dealer Thumbnail
 * @param disableImage {Boolean} Indicator to disable image
 * @return {*}
 */
const renderContactImage = (disableImage) => {
    if (disableImage) {
        return '';
    }

    return '<div class="dealer-info-card__media" data-dealer-image></div>';
};

/**
 * @method renderPhone
 * @description renders Dealer phone number
 * @param dealer {Object} Dealer
 * @return {*}
 */
const renderPhone = (dealer, variant) => {
    if (!dealer || !dealer.phone) {
        return '';
    }

    return `
        <span class="dealer-info-card__address-item">
            ${variant.isDDTVariant ? dealer.phoneFormatted : `<a href="tel:${dealer.phone}" class="dealer-info-card__phone-number" data-analytics-trigger="cta" itemprop="telephone">${dealer.phoneFormatted}</a>`}
        </span>
    `;
};

/**
 * @method renderViewMore
 * @description renders Dealer view more cta button
 * @param content {Object} Content object with key/values
 * @param viewMoreUrl {Object} Contains dealer url and new tab indicator
 * @return {*}
 */
const renderViewMore = (content, viewMoreUrl) => {
    if (!(viewMoreUrl.url && content.viewMoreCTA)) {
        return '';
    }
    return `
        <div class="dealer-info-card__view-more">
            <a class="button button_call-out button--wide" href="${viewMoreUrl.url}" 
                ${viewMoreUrl.newTab ? 'target="_blank"' : ''} rel="noopener" data-analytics-trigger="cta">
                ${content.viewMoreCTA}
            </a>
        </div>
    `;
};

/**
 * Template for a Dealer Info Card view.
 * Uses ES6 template literal to composite the markup for a DealerInfoCard
 */
export default (dealer, content, isPreferred, disableCtas, disableImage, viewMoreUrl, variantInfo) => htmlNode`
    <div class="dealer-info-card"
         data-analytics-container="{'dealer-card': ${dealer.dealerId}, 'preferred': ${isPreferred}}"
         itemscope itemtype="http://schema.org/AutoDealer">
        ${renderContactImage(disableImage)} 
        <div class="dealer-info-card__dealer">
            <div class="dealer-info-card__address-container">
                <div class="dealer-info-card__title">
                    <h1 class="dealer-info-card__dealer-name" itemprop="name">${dealer.name}</h1>
                    ${renderChangeCta(content, disableCtas)}
                </div>
                <div class="dealer-info-card__address" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                    <span class="dealer-info-card__address-item" itemprop="streetAddress">${dealer.address}</span>
                    <span class="dealer-info-card__address-item"><span itemprop="addressLocality">${dealer.city}</span>, <span itemprop="addressRegion">${dealer.state}</span>, <span itemprop="postalCode">${dealer.zip}</span></span>
                    ${renderPhone(dealer, variantInfo)}
                </div>
            </div>
            ${renderViewMore(content, viewMoreUrl)}
            ${renderCtas(dealer, content, disableCtas)}
            ${renderContactCta(content, disableCtas)}
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

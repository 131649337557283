// Platform dependencies
import { DealerInfoCard, DealerMap, DealerNotes } from 'partials/dealer';

// Local dependencies
import dealerInfoMapTemplate from './../templates/dealerInfoMapTemplate';

/**
 * @const CLASSES
 * @description has the defined classes to cache the dom
 * @type {Object}
 */
const CLASSES = {
    INFO_CARD: 'dealer-info-map__info-card',
    INFO_MAP: 'dealer-info-map__info-map',
    INFO_MAP_CONTAINER: 'dealer-info-map__info-map-container'
};

/**
 * Class representing a module for Dealer Info + Map
 */
export default class DealerInfoMap {
    /**
     * Creates an instance of DealerInfoMap
     * @param {Object} element - Element to which DealerInfoMap should be attached to
     * @param {Object} dealer - Dealer object
     * @param {Object} content - i18n content object
     * @param {Object} config - configuration for dealer info card
     */
    constructor(element, dealer, content, config = {}) {
        this.element = element;
        this.dealer = dealer;
        this.content = content;
        this.config = config;

        this.renderDealerInfoMapTemplate();
        this.cacheDOM();
        this.renderDealerInfoCard();
        this.renderDealerInfoMap();
        this.renderDealerNotes();
    }

    /**
     * @method cacheDOM
     * @description  in charge of caching the elements from the DOM
     */
    cacheDOM() {
        this.infoCardElm = this.element.querySelector(`.${CLASSES.INFO_CARD}`);
        this.infoMapContainerElm = this.element.querySelector(`.${CLASSES.INFO_MAP_CONTAINER}`);
        this.infoMapElm = this.element.querySelector(`.${CLASSES.INFO_MAP}`);
    }

    /**
     * @method destroy
     * @description Destroys the element by deleting it from the DOM
     */
    destroy() {
        this.dealerMap.destroy();
        this.dealerInfoCard.destroy();
        this.element.remove();
    }

    /**
     * @method renderDealerInfoMapTemplate
     * @description  renders the template that has placeholder divs for info card and map
     */
    renderDealerInfoMapTemplate() {
        this.dealerInfoElm = dealerInfoMapTemplate()({ getNode: true });
        this.element = this.dealerInfoElm;
    }

    /**
     * @method renderDealerInfoCard
     * @description  renders the info card and appends to template
     */
    renderDealerInfoCard() {
        const dealer = this.dealer;
        this.dealerInfoCard = new DealerInfoCard({
            ...this.config,
            content: this.content,
            dealer,
            id: dealer.dealerId,
            disableCtas: true,
            disableImage: true
        });

        this.infoCardElm.appendChild(this.dealerInfoCard.render());
    }

    /**
     * @method renderDealerInfoMap
     * @description  renders the map and appends to template
     */
    renderDealerInfoMap() {
        this.dealerMap = new DealerMap(this.infoMapElm, this.dealer, false);
    }

    /**
     * @method renderDealerNotes
     * @description  renders the dealer notes and appends to template
     */
    renderDealerNotes() {
        if (this.dealer.notes) {
            this.dealerNotes = new DealerNotes(this.content.dealerNotes, this.dealer.notes);
            this.infoMapContainerElm.appendChild(this.dealerNotes.render());
            this.element.classList.add('dealer-info-map--has-notes');
        }
    }

    /**
     * @method render
     * @description Returns the view element node
     * @return {Element|*}
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

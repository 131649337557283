import { htmlNode } from 'utils';

/**
 * Template for a DealerForm confirmation success.
 * Uses ES6 template literal to composite the markup for a Dealer Form confirmation success view
 */
export default ({ title = '', body = '', disclaimer = '' }) => htmlNode `
    <div class="dealer-form">
        <div class="dealer-form__success-container" data-analytics-container="{'blade': 'dealer-form'}">
            <div class="dealer-form__header">
                ${title}
            </div>
            <div class="dealer-form__success-body">
                ${body}
            </div>
            <div class="dealer-form__success-footnote">
                ${disclaimer}
            </div>
        </div>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for AMG Specialty Page Engine Chapter Slug Template
 * Uses ES6 template literal to composite the markup
 */
import { htmlNode } from 'utils';

export default () => htmlNode`
    <button aria-controls="disclaimer-drawer" data-legend="**" class="disclaimer__marker specialty-page__disclaimer-marker" tabindex="-1">
        <sup class="disclaimer__legend-reference">**</sup>
    </button>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

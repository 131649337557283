// Constant dependencies
import { EVENTS } from 'Constants';

// Local dependencies
import dealerSelectorActiveDealerTemplate from './../templates/dealerSelectorActiveDealerTemplate';

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{CONTINUE_CTA: string, CHANGE_LOCATION_CTA: string}}
 */
const ATTRIBUTES = {
    CONTINUE_CTA: 'data-continue-cta',
    CHANGE_LOCATION_CTA: 'data-change-location-cta'
};

/**
 * View responsible for rendering the DealerSelectorActiveDealer module
 */
export default class DealerSelectorActiveDealer {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     * @param dealer {object} Dealer to render
     * @param isPreferred {boolean} Is dealer preferred dealer
     * @param content {object} Object with key/value pairs containing authorable content fields
     * @param onDealerContinue {function} Callback to continue with current active dealer
     * @param onLocationChange {function} Callback to change location
     */
    constructor({ dealer = {}, isPreferred = false, content = {},
                  onDealerContinue, onLocationChange }) {
        this.dealer = dealer;
        this.content = content;
        this.onDealerContinue = onDealerContinue;
        this.onLocationChange = onLocationChange;
        this.onChange = this.onChange.bind(this);
        this.onContinue = this.onContinue.bind(this);

        this.element = dealerSelectorActiveDealerTemplate({
            dealer,
            isPreferred,
            content
        })({ getNode: true }); // DOM element to render

        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description Caches reference to DOM elements from the view
     */
    cacheDOM() {
        this.continueCta = this.element.querySelector(`[${ATTRIBUTES.CONTINUE_CTA}]`);
        this.changeLocationCta = this.element.querySelector(`[${ATTRIBUTES.CHANGE_LOCATION_CTA}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches an event listener for click of CTAs
     */
    attachEvents() {
        this.continueCta.addEventListener(EVENTS.CLICK, this.onContinue);
        this.changeLocationCta.addEventListener(EVENTS.CLICK, this.onChange);
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callbacks from the view
     */
    detachEvents() {
        this.continueCta.removeEventListener(EVENTS.CLICK, this.onContinue);
        this.changeLocationCta.removeEventListener(EVENTS.CLICK, this.onChange);
    }

    /**
     * @method onContinue
     * @description Callback when a 'Continue' is selected
     */
    onContinue() {
        this.onDealerContinue(this.dealer);
    }

    /**
     * @method onChange
     * @description Callback when a 'Change Location' cta is selected
     */
    onChange() {
        this.onLocationChange(this.dealer);
    }

    /**
     * @method destroy
     * @description Detaches events and deletes element from the DOM
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method render
     * @description Renders the element
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

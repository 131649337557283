/**
 * @const defaultDLLocalization
 * @description Default localization labels for Dealer Locator Plugin
 * @type {{label: String}}
 */
const defaultDLLocalization = {
    moduleHeading: 'Find Local Inventory',
    searchInputLabel: 'Enter ZIP Code or City, State',
    searchCTA: 'Search',
    hideMapCTA: 'Hide Map',
    showMapCTA: 'Show Map',
    searchErrorMessage: 'Invalid ZIP Code or City, State',
    suggestedDealer: 'Suggested',
    next: 'Next',
    previous: 'Previous',
    defaultSubheading: 'Enter your location to view vehicles at a dealer near you.',
    resultsSubheading: 'Select the dealer whose inventory you\'d like to see.',
    subtext: 'These are the dealers near {searchTerms}.',
    changeLocationCTA: 'Change Location',
    preferredDealer: 'Your Preferred Dealer',
    noResultsFound: 'No Results Found',
    noResultsToView: 'There are no dealerships to view. Try entering a different ZIP code.',
    resetFilters: 'Reset Your Filters',
    serviceDownFirst: 'We’re sorry, an error has occurred.',
    serviceDownSecond: 'We were unable to process your request. Please try again.'
};

// Deconstruct localization object
const {
    moduleHeading,
    searchInputLabel,
    searchCTA,
    hideMapCTA,
    showMapCTA,
    searchErrorMessage,
    suggestedDealer,
    next,
    previous,
    defaultSubheading,
    resultsSubheading,
    subtext,
    changeLocationCTA,
    preferredDealer,
    noResultsFound,
    noResultsToView,
    resetFilters,
    serviceDownFirst,
    serviceDownSecond
} = Object.assign(defaultDLLocalization, window.mbVans.ns('pageData', 'dealerLocatorPlugin').localization);

export default {
    moduleHeading,
    searchInputLabel,
    searchCTA,
    hideMapCTA,
    showMapCTA,
    searchErrorMessage,
    suggestedDealer,
    next,
    previous,
    defaultSubheading,
    resultsSubheading,
    subtext,
    changeLocationCTA,
    preferredDealer,
    noResultsFound,
    noResultsToView,
    resetFilters,
    serviceDownFirst,
    serviceDownSecond
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Partial dependencies
import { SelectControl } from 'partials/select-control';
import { LoadingSpinner } from 'partials/loading-spinner';
import { FORM_CONTROL_EVENTS } from 'partials/form-control';

// Util dependencies
import { renderer, noop, customEventDispatcher } from 'utils';

// Local dependencies
import {
    PAGE_DATA_LANGUAGE,
    PAGE_DATA_COUNTRY,
    PAGE_DATA_BRAND,
    PAGE_DATA_CURRENCY,
    PAGE_SHOW_ESPRINTER
} from './../config';

import vehicleSelectorContent from './../config/content';

import vehicleSelectorApi from './../api/vehicleSelectorApi';

import vehicleSelectorTemplate from './../templates/vehicleSelectorTemplate';

import ChosenVehicle from './ChosenVehicle';

/**
 * @constant CLASSES
 * @description Class references for the VehicleSelector view
 * @type {{VEHICLE_SELECTED: string}}
 */
const CLASSES = {
    VEHICLE_SELECTED: 'vehicle-selector--selected',
    SHOW_LOADER: 'vehicle-selector--show-loader',
    LOADER_CONTENT_CONTAINER: 'vehicle-selector__loader-content-container',
    SUBMIT_CTA: 'vehicle-selector__submit-cta',
    SUBMIT_CTA_WRAPPER: 'vehicle-selector__submit-cta__wrapper',
    SUBMIT_CTA_ERROR: 'vehicle-selector__submit-cta__error',
    VEHICLE_SELECTOR: 'vehicle-selector__selection',
    DISABLED: 'disabled',
    ERROR: 'error',
};

/**
 * @constant ATTRIBUTES
 * @description Attribute references for the VehicleSelector view
 * @type {{SELECT_CLASSES: string, SELECT_MODELS: string, VEHICLE_SWITCH: string,
 * CHOSEN_VEHICLE: string}}
 */
const ATTRIBUTES = {
    SELECT_CLASSES: 'data-vehicle-selector-classes',
    SELECT_MODELS: 'data-vehicle-selector-models',
    SELECT_YEARS: 'data-vehicle-selector-years',
    VEHICLE_SWITCH: 'data-vehicle-selector-switch',
    CHOSEN_VEHICLE: 'data-vehicle-selector-chosen-vehicle',
    ARIA_DESCRIBED_BY: 'aria-describedby',
};

/**
 * @const defaultConfig
 * @description Default configuration options for a vehicle selector
 * @type {{
 *   changeVehicleCtaLabel: string,
 *   chosenVehicle: object,
 *   disableChange: boolean,
 *   disableChosenVehicleView: boolean,
 *   enableYears: boolean,
 *   formId: string,
 *   modelCode: string,
 *   bodystyle: string,
 *   onSelectCallback: function,
 *   selectVehicleOnSubmit: boolean,
 *   showYear: boolean,
 *   submitCtaLabel: 'Submit',
 *   submitCtaClass: '',
 *   submitCtaCallback: noop
 * }}
 */
const defaultConfig = {
    changeVehicleCtaLabel: '',
    chosenVehicle: null,
    disableChange: false,
    disableChosenVehicleView: false,
    enableYears: false,
    formId: '',
    modelCode: '',
    bodystyle: '',
    onSelectCallback: noop,
    selectVehicleOnSubmit: false,
    showYear: false,
    submitCtaLabel: '',
    submitCtaClass: '',
    submitCtaCallback: noop
};

/**
 * @class VehicleSelector
 * @description Utilizes the forms select control to populate vehicle drop-downs to select
 * a vehicle
 * @param {Object} element
 */
export default class VehicleSelector {
    /**
     * @method constructor
     * @description View component for displaying a VehicleSelector
     * @param config {Object} Configuration data
     */
    constructor(config = defaultConfig) {
        this.config = {
            ...defaultConfig,
            ...config
        };

        this.activeModels = null;
        this.chosenVehicle = null;
        this.chosenModel = null;
        this.chosenModelCode = null;
        this.element = null;
        this.loader = new LoadingSpinner();
        this.selectedYear = null;
        this.selectedClass = null;
        this.valid = false;
        this.createBodystyleSelect = this.createBodystyleSelect.bind(this);
        this.createModelSelect = this.createModelSelect.bind(this);
        this.createYearSelect = this.createYearSelect.bind(this);
        this.createChosenVehicleView = this.createChosenVehicleView.bind(this);
        this.resetView = this.resetView.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.dispatchChange = this.dispatchChange.bind(this);
        this.setDisabled = this.setDisabled.bind(this);
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.createView();
        this.cacheDOM();

        if (this.config.enableYears && !this.config.chosenVehicle) {
            renderer.insert(this.loader.render(), this.selectYearElement);
            this.constructYearItems()
                .then(this.createYearSelect)
                .then(this.createBodystyleSelect)
                .then(this.createModelSelect);
        } else if (this.config.chosenVehicle) {
            this.prepopulateSelectData();
        } else {
           // Added this to prepopulate the lead form with eSprinter for Canada epsrinter campaign.
            let defaultSelected = null;
            const SHOW_ESPRINTER = PAGE_SHOW_ESPRINTER;
            const ESPRINTER_VALUE = 'eSprinter:CA'; // Hard coded the eSprinter value
            if (SHOW_ESPRINTER && PAGE_DATA_COUNTRY.toLowerCase() === 'ca' && !this.config.bodystyle) {
                defaultSelected = ESPRINTER_VALUE;
            } else {
                defaultSelected = this.config.bodystyle;
            }
            this.getVehicleBodystyles()
             .then((items) => this.createBodystyleSelect(items, defaultSelected));
            this.createModelSelect();
        }

        if (this.config.modelCode) {
            this.selectVehicleByCode(this.config.modelCode, this.config.variant);
        }

        this.attachEvents();
    }

    /**
     * @method createView
     * @description Create view
     */
    createView() {
        this.element = vehicleSelectorTemplate({
            changeVehicleCtaLabel: this.config.changeVehicleCtaLabel,
            content: vehicleSelectorContent,
            disableChange: this.config.disableChange,
            enableYears: this.config.enableYears,
            selectControlCSSModifierClass: this.config.selectControlCSSModifierClass,
            submitCtaLabel: this.config.submitCtaLabel,
            submitCtaClass: this.config.submitCtaClass,
            submitCtaCallback: this.config.submitCtaCallback,
            errorText: vehicleSelectorContent.vehicleSelectorError
        })({ getNode: true });
    }


    /**
     * @method setButtonErrorState
     * @description sets error state of submit button
     * @param isValid {Boolean} indicates of state is valid
     */
    setButtonErrorState(isValid) {
        if (this.submitCtaElement) {
            if (isValid) {
                this.submitCtaElementWrapper.classList.remove(CLASSES.ERROR);
                this.submitCtaElement.removeAttribute(ATTRIBUTES.ARIA_DESCRIBED_BY);
            } else {
                this.submitCtaElementWrapper.classList.add(CLASSES.ERROR);
                this.submitCtaElement.setAttribute(ATTRIBUTES.ARIA_DESCRIBED_BY, this.submitCtaError.id);
            }
        }
    }

    /**
     * @method resetView
     * @description Goes back to the selection view to be able to choose another vehicle
     * class and model
     */
    resetView() {
        const {
            bodystyleId,
            model: {
                code: modelCode,
                defaultVariant,
            },
        } = this.chosenVehicle.chosenVehicleData;
        const modelId = `${modelCode}:${defaultVariant}`;

        this.element.classList.remove(CLASSES.VEHICLE_SELECTED);
        this.chosenVehicle.destroy();
        if (this.classSelect) {
            this.classSelect.destroy();
        }
        if (this.modelSelect) {
            this.modelSelect.destroy();
        }

        this.getVehicleBodystyles().then((classSelectOptions) => {
            this.createBodystyleSelect(classSelectOptions, bodystyleId);
            this.getVehicleModels(bodystyleId).then((modelSelectOptions) => {
                this.createModelSelect(modelSelectOptions, modelId);
            });
        });
    }

    /**
     * @method register
     * @description Dispatches an event to notify a form that an input should be registered
     */
    register() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.REGISTER,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method unregister
     * @description Dispatches an event to notify a form that an input should be unregistered
     */
    unregister() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.UNREGISTER,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method dispatchChange
     * @description Dispatches an event to notify a form that vehicle selector has changed
     */
    dispatchChange() {
        if (this.config.formId) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    FORM_CONTROL_EVENTS.INPUT_CHANGE,
                    {
                        detail: {
                            formId: this.config.formId,
                            input: this
                        }
                    }
                )
            );
        }
    }

    /**
     * @method cacheDOM
     * @description caches DOM elements
     */
    cacheDOM() {
        this.selectClassElement = this.element.querySelector(`[${ATTRIBUTES.SELECT_CLASSES}]`);
        this.selectModelElement = this.element.querySelector(`[${ATTRIBUTES.SELECT_MODELS}]`);
        this.selectYearElement = this.element.querySelector(`[${ATTRIBUTES.SELECT_YEARS}]`);
        this.vehicleSwitch = this.element.querySelector(`[${ATTRIBUTES.VEHICLE_SWITCH}]`);
        this.chosenVehicleElement = this.element.querySelector(`[${ATTRIBUTES.CHOSEN_VEHICLE}]`);
        this.loaderContentContainer = this.element.querySelector(`.${CLASSES.LOADER_CONTENT_CONTAINER}`);
        this.submitCtaElement = this.element.querySelector(`.${CLASSES.SUBMIT_CTA}`);
        this.submitCtaElementWrapper = this.element.querySelector(`.${CLASSES.SUBMIT_CTA_WRAPPER}`);
        this.submitCtaError = this.element.querySelector(`.${CLASSES.SUBMIT_CTA_ERROR}`);
        this.vehicleSelector = this.element.querySelector(`.${CLASSES.VEHICLE_SELECTOR}`);
    }

    /**
     * @method attachEvents
     * @description Attaches click event to the switch vehicle link
     */
    attachEvents() {
        if (this.vehicleSwitch) {
            this.vehicleSwitch.addEventListener(EVENTS.CLICK, this.resetView);
        }
        if (this.config.submitCtaLabel) {
            this.submitCtaElement.addEventListener(EVENTS.CLICK, this.onSubmit);
        }
    }

    /**
     * @method detachEvents
     * @description Detaches click event to the switch vehicle link
     */
    detachEvents() {
        if (this.vehicleSwitch) {
            this.vehicleSwitch.removeEventListener(EVENTS.CLICK, this.resetView);
        }
    }

    /**
     * @method destroy
     * @description Destroys the element by removing the events and
     * deleting it from the DOM
     */
    destroy() {
        this.detachEvents();
        this.element.remove();
    }

    /**
     * @method constructYearItems
     * @description Constructs the years data to populate the year dropdown
     */
    async constructYearItems() {
        return this.getModelsByYear()
            .then((data) => (Object.values(data.years).map(({ year }) => ({ label: year, value: year })))
            );
    }

    /**
     * @method prepopulateSelectData
     * @description Prepopulates dropdowns with selected vehicle if user navigates to another step
     * and then goes back to vehicle information
     */
    async prepopulateSelectData() {
        const year = this.config.chosenVehicle.year;
        const classId = this.config.chosenVehicle.class;
        const modelId = this.config.chosenVehicle.modelId;

        let classSelectOptions = [];
        let modelSelectOptions = [];

        if (year && this.config.enableYears) {
            const yearItems = await this.constructYearItems();
            const modelsByYear = await this.getModelsByYear();
            classSelectOptions =
                Object.values(modelsByYear.years[year].classes)
                    .map((classes) => ({
                        label: classes.className,
                        value: classes.classId
                    }));
            modelSelectOptions =
                Object.values(modelsByYear.years[year].classes[classId].models)
                    .map((model) => this.normalizeModel(model, classId, year));

            this.createYearSelect(yearItems);
        } else {
            classSelectOptions = await this.getVehicleBodystyles();
            modelSelectOptions = await this.getVehicleModels(classId);
        }

        this.createBodystyleSelect(classSelectOptions, classId);
        this.createModelSelect(modelSelectOptions, modelId);
    }

    /**
     * @method getVehicleClasses
     * @description Calls api to get classes label/value
     */
    async getVehicleBodystyles() {
        return vehicleSelectorApi.getBodystyles(PAGE_DATA_COUNTRY, PAGE_DATA_LANGUAGE, PAGE_DATA_BRAND);
    }

    /**
     * @method getModelsByYear
     * @description Calls api to get models by year
     */
    async getModelsByYear() {
        return vehicleSelectorApi.getModelsByYear();
    }

    /**
     * @method getVehicleModels
     * @description Calls api to get models for the given class
     * @param bodystyleCode {String} class code to search for models
     */
    getVehicleModels(bodystyleCode) {
        renderer.insert(this.loader.render(), this.selectModelElement);

        return vehicleSelectorApi
            .getModels(PAGE_DATA_COUNTRY, PAGE_DATA_LANGUAGE, PAGE_DATA_BRAND, bodystyleCode);
    }

    /**
     * @method selectVehicleByCode
     * @description Calls api to get model for the model code
     * @param modelCode {String} model code to fetch model data
     * @param variant {String} variant value (STANDARDROOF or HIGHROOF)
     */
    selectVehicleByCode(modelCode, variant) {
        this.setLoadingSpinner();
        vehicleSelectorApi
            .getModel(PAGE_DATA_COUNTRY, PAGE_DATA_LANGUAGE, PAGE_DATA_BRAND, modelCode, variant)
            .then((model) => {
                this.chosenModel = model;
                this.createChosenVehicleView(model);
            });
    }

    /**
     * @method getChosenVehicleData
     * @description takes the response of model details api and looks for the
     * given model code
     * @returns pass the response to chosen createChosenVehicleView.
     */
    getChosenVehicleData(modelCodeRoofHeight) {
        const [modelCode, roofHeight] = modelCodeRoofHeight.split(':');
        this.chosenModel = this.activeModels.find((activeModel) =>
            activeModel.model.code === modelCode &&
            activeModel.model.defaultVariant === roofHeight
        );

        if (this.chosenModel && !this.chosenModel.year && this.config.enableYears) {
            this.chosenModel.year = this.yearSelect.getValue();
        }

        this.config.onSelectCallback(this.chosenModel);
        if (!this.config.disableChosenVehicleView) {
            this.createChosenVehicleView(this.chosenModel);
        }
        this.vehicleSelector.setAttribute('data-analytics-container', JSON.toString({
            'vehicle-data': {
                class: this.chosenModel.model.vehicleClass.name,
                body: this.chosenModel.bodystyle.name,
                model: this.chosenModel.model.name,
            },
        }));
        this.dispatchChange();
    }

    /**
     * @method getChosenModelBuildURL
     * @description fetches the modelBuildPage url from the chosenModel object
     * @returns modelBuildPage url
     */
    getChosenModelBuildURL() {
        return this.chosenModel.modelBuildPage;
    }

    /**
     * @method setLoadingSpinner
     * @description shows the loader container div and inserts loading spinner to it.
     */
    setLoadingSpinner() {
        this.element.classList.add(CLASSES.SHOW_LOADER);
        renderer.insert(this.loader.render(), this.loaderContentContainer);
    }

    /**
    * @method setDisabled
    * @description Sets the disabled appropriate on select boxes and cta
    * @param disabledMask {Boolean} if true the controls will be disabled
    */
    setDisabled(disabledMask) {
        if (this.yearSelect) {
            this.yearSelect.setDisabled(disabledMask);
        }
        if (this.bodystyleSelect) {
            this.bodystyleSelect.setDisabled(disabledMask);
        }
        if (this.modelSelect) {
            this.modelSelect.setDisabled(disabledMask);
        }
        if (this.submitCtaElement) {
            this.submitCtaElement.disabled = disabledMask;
            if (disabledMask) {
                this.submitCtaElement.classList.add(CLASSES.DISABLED);
            } else {
                this.submitCtaElement.classList.remove(CLASSES.DISABLED);
            }
        }
    }

    /**
     * @method unsetLoadingSpinner
     * @description hides the loader container div and removes loading spinner.
     */
    unsetLoadingSpinner() {
        this.element.classList.remove(CLASSES.SHOW_LOADER);
        this.loader.destroy();
    }

    /**
     * @method createClassSelect
     * @description Creates a SelectControl for the classes
     * @param {Array} items - items to display in the select-control list
     * @param {String} defaultBodystyle - vehicle class to prepopulate as default
     */
    createBodystyleSelect(items = [], defaultBodystyle) {
        const selectedOption = !defaultBodystyle ? -1 :
            items.findIndex((bodystyleId) => bodystyleId.value === defaultBodystyle);

        this.bodystyleSelect = new SelectControl(
            items,
            {
                errorMessage: vehicleSelectorContent.selectBodystyleErrorMessage,
                cssClass: `leads-form__select ${this.config.selectControlCSSModifierClass}`,
                defaultSelection: selectedOption,
                selectionCallback: this.onSelectBodystyle.bind(this),
                required: true,
                analyticsTrigger: 'cta-select-vehicle',
                labelText: vehicleSelectorContent.selectBodystyle
            }
        );
        renderer.insert(this.bodystyleSelect.render(), this.selectClassElement);
        if (selectedOption >= 0) {
            this.onSelectBodystyle(items[selectedOption].value);
        }
    }

    /**
     * @method createYearSelect
     * @description Creates a SelectControl for the years
     * @param items {Array} items to display in the select-control list
     */
    createYearSelect(items = []) {
        const selectedOption = this.config.chosenVehicle ?
            items.findIndex((year) => year.value === this.config.chosenVehicle.year) : -1;
        this.yearSelect = new SelectControl(
            items,
            {
                errorMessage: vehicleSelectorContent.selectYearErrorMessage,
                cssClass: 'leads-form__select',
                defaultSelection: selectedOption,
                selectionCallback: this.onSelectYear.bind(this),
                required: true,
                analyticsTrigger: 'cta-select-year',
                labelText: vehicleSelectorContent.selectYear
            }
        );

        renderer.insert(this.yearSelect.render(), this.selectYearElement);
    }

    /**
     * @method createModelSelect
     * @description Creates a SelectControl for the models
     * @param {Array} activeModels
     * @param {String} defaultModel
     */
    createModelSelect(activeModels, defaultModel) {
        // sets the model details for selected class.
        this.activeModels = activeModels;
        // gets the model name and code details used to fill drop down
        const items = this.parseModelItems();
        let selectedOption = defaultModel ?
            items.findIndex((model) => model.value === defaultModel) : -1;

        if (items.length === 1 && selectedOption < 0) {
            selectedOption = 0;
            this.chosenModelCode = items[0].value;
        }

        this.modelSelect = new SelectControl(
            items,
            {
                ariaLabel: vehicleSelectorContent.selectModel,
                errorMessage: vehicleSelectorContent.selectModelErrorMessage,
                cssClass: `leads-form__select ${this.config.selectControlCSSModifierClass}`,
                defaultSelection: selectedOption,
                selectionCallback: this.onSelectModel.bind(this),
                required: true,
                analyticsTrigger: 'cta-select-trim',
                labelText: vehicleSelectorContent.selectModel
            }
        );

        renderer.insert(this.modelSelect.render(), this.selectModelElement);
    }

    /**
     * @method normalizeModel
     * @description create model object with expected format
     * @param model {Object} model data
     * @param classId {String} model's class
     * @param year {String} model's year
     */
    normalizeModel(model, classId, year) {
        return {
            ...model,
            bodystyle: {
                name: model.bodystyleId
            },
            class: classId,
            model: {
                name: model.modelName,
                code: model.modelId,
                vehicleClass: {
                    name: classId
                }
            },
            year
        };
    }

    /**
     * @method parseModelItems
     * @description gets the model name and code details from the activeModels which
     * will be used to fill the models dropdown
     * @returns {array} items which contain models name and code
     */
    parseModelItems() {
        return (this.activeModels || []).map((model) => (
            {
                label: model.displayName,
                value: `${model.model.code}:${model.model.defaultVariant}`,
            }
        ));
    }

    /**
     * @method createChosenVehicleView
     * @description Create view for chosen vehicle details
     */
    createChosenVehicleView(chosenModel) {
        if (chosenModel) {
            this.chosenVehicle = new ChosenVehicle(this.chosenVehicleElement, chosenModel,
                PAGE_DATA_COUNTRY, PAGE_DATA_LANGUAGE, PAGE_DATA_BRAND, PAGE_DATA_CURRENCY, this.config.showYear);
            this.element.classList.add(CLASSES.VEHICLE_SELECTED);
        } else {
            this.recreateModelSelect();
        }

        this.element.focus();
        this.unsetLoadingSpinner();
    }

    /**
     * @method onSelectBodystyle
     * @description Callback method for when a class is selected
     * to load the models according to the selected class
     * @param option {Object} The selected option
     */
    onSelectBodystyle(option) {
        this.setButtonErrorState(true);
        this.selectedClass = option;
        if (this.modelSelect) {
            this.modelSelect.destroy();
        }

        if (this.config.chosenVehicle || this.chosenModel) {
            this.config.onSelectCallback(null);
        }

        if (this.config.enableYears) {
            // TODO: Do we need enableYears ?
            renderer.insert(this.loader.render(), this.selectModelElement);
            return this.getModelsByYear()
                .then((data) => (
                    Object.values(data.years[this.yearSelect.getValue()].classes[option].models)
                        .map((model) => this.normalizeModel(model, this.selectedClass, this.selectedYear))
                ))
                .then(this.createModelSelect)
                .then(this.dispatchChange);
        } else {
            this.chosenModel = null;
            this.setDisabled(true);
            return this.getVehicleModels(option)
                .then(this.createModelSelect)
                .catch((e) => { console.error(e); })
                .then(() => { this.setDisabled(false); });
        }
    }

    /**
     * @method onSelectYear
     * @description Callback method for when a year is selected
     * to load the classes according to the selected year
     * @param option {Object} The selected option
     */
    onSelectYear(option) {
        this.selectedYear = option;
        if (this.bodystyleSelect && this.modelSelect) {
            this.bodystyleSelect.destroy();
            this.modelSelect.destroy();
        }

        if (this.config.chosenVehicle || this.chosenModel) {
            this.config.onSelectCallback(null);
        }

        renderer.insert(this.loader.render(), this.selectClassElement);
        this.getModelsByYear()
            .then((data) => (Object.values(data.years[option].classes).map((classes) => (
                { label: classes.className, value: classes.classId }))))
            .then(this.createBodystyleSelect)
            .then(this.createModelSelect);
        this.dispatchChange();
    }

    /**
     * @method onSelectModel
     * @description Callback method for when a model is selected
     * to show the chosen vehicle view
     * @param option {Object} The selected option
     */
    onSelectModel(option) {
        this.setButtonErrorState(true);
        if (!this.config.selectVehicleOnSubmit) {
            this.getChosenVehicleData(option);
        } else {
            this.chosenModelCode = option;
        }
    }

    /**
     * @method onSubmit
     * @description Callback method for when the submit cta
     * is clicked, this will validate the class/model have been selected
     * before triggering the callback passed in the config
     */
    onSubmit() {
        this.setButtonErrorState(true);

        if (!this.bodystyleSelect.validate() || !this.modelSelect.validate()) {
            return;
        }

        if (this.config.selectVehicleOnSubmit && this.chosenModelCode) {
            this.getChosenVehicleData(this.chosenModelCode);
        }

        if (this.chosenModel) {
            this.config.submitCtaCallback(this.chosenModel);
        }
    }

    /**
     * @method isValid
     * @description Returns validity state
     * @returns {Boolean} True if component passes validation
     */
    isValid() {
        if (this.config.chosenVehicle) {
            return true;
        }
        return this.valid;
    }

    /**
     * @method validate
     * @description Check validation
     */
    validate(displayError = true) {
        this.valid = !!this.chosenModel;
        if (!this.valid && this.bodystyleSelect && this.modelSelect) {
            if (this.bodystyleSelect.validate(displayError)) {
                this.modelSelect.validate(displayError);
            }
        }

        if (!this.valid && this.config.enableYears && this.yearSelect) {
            this.yearSelect.validate(displayError);
        }

        if (!this.valid &&
            (!this.yearSelect || this.yearSelect.isValid()) &&
            (!this.bodystyleSelect || this.bodystyleSelect.isValid()) &&
            (!this.modelSelect || this.modelSelect.isValid())
        ) {
            this.setButtonErrorState(false);
        }
    }

    /**
     * @method prevalidate
     * @description prevalidates the input; however bypasses display an error message to the ui
     */
    prevalidate() {
        this.validate(false);
    }

    /**
     * @method render
     * @description Return the element containing the vehicle selector
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

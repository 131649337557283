/**
 * A list of trade-in vendors that map the the usedCarBook values
 */
export default {
    Default: 'Other',
    Blackbook: 'BlackBook',
    Carfax: 'Other'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

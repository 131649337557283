// Constant dependencies
import { CUSTOM_EVENTS } from 'Constants';

// Util dependencies
import { customEventDispatcher, noop } from 'utils';

// Partial dependencies
import { Modal } from 'partials/modal';

const defaultCallback = {
    afterModalOpen: noop,
    beforeModalClose: noop
};

class YouTubeModal {
    /**
     * @constructor
     * @description Initializes the component
     * @param videoId {string} video id of youtube video player
     * @param contentElement {element} content to render inside of modal
     * @param callbacks {object} callback methods used when opening and closing modal
     * @param theme {string} modal theme
     * @param triggerElement {element} referenced element that triggered modal to open, so element will refocus on
     * modal close
     */
    constructor(videoId, contentElement, callbacks, theme, triggerElement) {
        this.videoId = videoId;
        this.contentElem = contentElement;
        this.callbacks = {
            ...defaultCallback,
            ...callbacks
        };
        this.theme = theme;
        this.triggerElement = triggerElement;
        this.init();
    }

    /**
     * @method init
     * @description initialize the component
     * @param triggerElement {element} optional argument -  updated reference to trigger element
     * if it has changed since instantiation
     */
    init(triggerElement) {
        this.triggerElement = triggerElement || this.triggerElement;
        this.setBindings();
        this.initYouTubeModal();
        this.youTubeModal.open({ callingContainer: this.triggerElement });
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onAfterYouTubeModalOpen = this.onAfterYouTubeModalOpen.bind(this);
        this.onBeforeYouTubeModalClose = this.onBeforeYouTubeModalClose.bind(this);
        this.closeYouTubeModal = this.closeYouTubeModal.bind(this);
        this.destroy = this.destroy.bind(this);
    }

    /**
     * @method onAfterYouTubeModalOpen
     * @description callback method for after modal opens
     */
    onAfterYouTubeModalOpen() {
        this.callbacks.afterModalOpen();
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.YOUTUBE_MODAL_OPENED,
                {
                    detail: {
                        youTubeId: this.videoId,
                        pageElement: this.contentElem
                    }
                }
            )
        );
    }

    /**
     * @method onBeforeYouTubeModalClose
     * @description callback method for when modal will close
     */
    onBeforeYouTubeModalClose() {
        this.callbacks.beforeModalClose();
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.YOUTUBE_MODAL_CLOSED,
                {
                    detail: {
                        youTubeId: this.videoId,
                        pageElement: this.contentElem
                    }
                }
            )
        );
        this.destroy();
    }

    /**
     * @method initYouTubeModal
     * @description initializes modal
     */
    initYouTubeModal() {
        this.youTubeModal = new Modal(undefined, {
            modalContent: this.contentElem,
            callbacks: {
                afterOpen: this.onAfterYouTubeModalOpen,
                beforeClose: this.onBeforeYouTubeModalClose
            },
            theme: this.theme,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.DEFAULT
        });
    }

    /**
     * @method closeYouTubeModal
     * @description close modal
     */
    closeYouTubeModal() {
        if (this.youTubeModal) {
            this.youTubeModal.close();
        }
    }

    /**
     * @method destroy
     * @description Destroys youTubeModal
     */
    destroy() {
        if (this.youTubeModal) {
            this.youTubeModal.destroy();
            this.youTubeModal = null;
        }
    }
}

export default YouTubeModal;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

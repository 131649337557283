// Util dependencies
import { htmlNode } from 'utils';

/**
 * @const errorMessages
 * @description Constructs the markup for multiple error messages
 * @param errors {Array} Collection of message object
 * @return {string}
 */
const errorMessages = (errors) => {
    let messages = '';

    errors.forEach((error) => {
        messages += `
        <div class="form-error-message__message">
            <div class="form-error-message__text">
                <div class="form-error-message__title">${error.title}</div>
                <div class="form-error-message__description">
                    ${error.description}
                </div>
            </div>
        </div>
        `;
    });

    return messages;
};

/**
 * @description Template for Form Error Message when displaying multiple messages
 * Uses ES6 template literal to composite the markup for a Form Error Message
 */
export default ({ errors }) => htmlNode`
    <div class="form-error-message" tabindex="0">
        <div class="form-error-message__icon">
        </div>
        <div class="form-error-message__errors">
            ${errorMessages(errors)}
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

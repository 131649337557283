/**
 * Template for an accordion
 * Uses ES6 template literal to composite the markup for a accordion
 */
import { html } from 'utils';

export default () => html`
    <div class="accordion" />
`.trim();
// do not delete 9fbef606107a605d69c0edbcd8029e5d

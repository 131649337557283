// Utils
import {
    formatNumber,
    storage
} from 'utils';

// Constants
import {
    STORAGE_KEYS
} from 'Constants';

/**
 * @method loadUserInput
 * @description Load user input from payment driver cookie
 * @return {Object} restored options
 */
function loadUserInput() {
    return storage.sessionStorage.read(STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA);
}

/**
 * @function normalizeNumber
 * @description Normalizes a value to a number if defined; otherwise returns undefined
 * @param value {string|number} Value to normalize
 * @param {Object} config object with country, language and currency
 * @return {undefined|number|*}
 */
function normalizeNumber(value, config) {
    if (typeof value === 'number') {
        return value;
    } else if (value && typeof value === 'string') {
        return formatNumber.toNumber(value, config.country, config.language, config.currency);
    }

    return undefined;
}

/**
 * @function normalizeString
 * @description Normalizes a value to a string if defined; otherwise returns undefined
 * @param value {string|number} Value to normalize
 * @return {string|undefined|*}
 */
function normalizeString(value) {
    if (typeof value === 'number') {
        return value.toString();
    } else if (value) {
        return value;
    }

    return undefined;
}

/**
 * @function persistUserInput
 * @description Sets a cookie to save the payment driver data
 * @param paymentType {String} Payment type : lease or finance
 * @param data {Object} payment driver data to be set on the cookie
 * @param {Boolean} hasDataChanged Indicator for whether the user has interacted with the form
 * @param {Object} config object with country, language and currency
 */
function persistUserInput(paymentType, data, hasDataChanged, config) {
    try {
        const initialInputData = {
            lease: {},
            finance: {},
            ...loadUserInput()
        };
        const userInputData = {
            ...initialInputData,
            _currentState: paymentType
        };

        if (data.lease) {
            userInputData.lease = {
                annualMileage: normalizeString(data.lease.annualMileage),
                creditScore: data.lease.creditScore ? data.lease.creditScore : initialInputData.lease.creditScore,
                downPayment: normalizeNumber(data.lease.downPayment, config),
                rate: typeof data.lease.rate === 'number' ? data.lease.rate : initialInputData.lease.rate,
                township: data.lease.township ? {
                    ...data.lease.township,
                } : initialInputData.lease.township,
                tradeIn: normalizeNumber(data.lease.tradeIn, config),
                terms: normalizeString(data.lease.terms),
                zipCode: data.lease.zipCode ? data.lease.zipCode : initialInputData.lease.zipCode,
            };
        }

        if (data.finance) {
            userInputData.finance = {
                apr: data.finance.apr ? normalizeNumber(data.finance.apr, config) : initialInputData.finance.apr,
                creditScore: data.finance.creditScore ? data.finance.creditScore : initialInputData.finance.creditScore,
                downPayment: normalizeNumber(data.finance.downPayment, config),
                isBalloon: data.finance.isBalloon,
                rate: typeof data.finance.rate === 'number' ? data.finance.rate : initialInputData.finance.rate,
                township: data.finance.township ? {
                    ...data.finance.township,
                } : initialInputData.finance.township,
                tradeIn: normalizeNumber(data.finance.tradeIn, config),
                terms: normalizeString(data.finance.terms),
                zipCode: data.finance.zipCode ? data.finance.zipCode : initialInputData.finance.zipCode,
            };
        }

        if (hasDataChanged) {
            storage.sessionStorage.create(
                STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
                userInputData
            );
        }
    } catch (e) {
        console.error('Error saving the payment estimator data', e);
    }
}

/**
 * @method persistPaymentType
 * @description Persists the payment type selected by user
 * @param {String} paymentType String to indiciate the type of payment selected
 */
function persistPaymentType(paymentType) {
    const userInput = loadUserInput();
    storage.sessionStorage.create(
        STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
        {
            ...userInput,
            _currentState: paymentType
        }
    );
}

function clearDownPayment() {
    const userInput = loadUserInput();
    if (!userInput) return;
    if (userInput.finance) {
        userInput.finance.downPayment = 0;
    }
    if (userInput.lease) {
        userInput.lease.downPayment = 0;
    }
    storage.sessionStorage.create(
        STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
        userInput
    );
}

function clearTradeIn() {
    const userInput = loadUserInput();

    if (!userInput) return;
    if (userInput.finance) {
        userInput.finance.tradeIn = 0;
    }
    if (userInput.lease) {
        userInput.lease.tradeIn = 0;
    }
    storage.sessionStorage.create(
        STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
        userInput
    );
}

export default {
    clearDownPayment,
    clearTradeIn,
    persistUserInput,
    persistPaymentType,
    loadUserInput
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

 // Partial dependencies
import { Slider } from 'partials/slider';
import { SLIDER_TYPE } from '../partials/slider/views/Slider';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    ITEMS_CONTAINER: 'large-tile-grid__copy-container',
    READY: 'large-tile-grid--ready'
};


/**
 * LargeTileGrid Module
 * This module updates the view when grid behavior is Slider
 */
export default class LargeTileGrid {
    constructor(element) {
        this.element = element;
        this.cacheDOMElements.bind(this);
        this.init();
    }

    init() {
        this.cacheDOMElements();

        if (this.element.dataset.gridBehavior === 'slider') {
            this.renderSlider();
        }

        this.element.classList.add(CLASSES.READY);
    }

    cacheDOMElements() {
        this.itemsContainer = this.element.querySelector(`.${CLASSES.ITEMS_CONTAINER}`);
    }

    /**
     * @method renderSlider
     * @description Creates an array of badge HTML elements and instantiates the Slider
     */
    renderSlider() {
        const sliderItems = [];
        if (this.itemsContainer.children.length > 0) {
            [].slice.call(this.itemsContainer.children).forEach((tileElm) => {
                if (tileElm.children.length) {
                    const slideElm = document.createElement('li');
                    slideElm.appendChild(tileElm.children[0]);
                    slideElm.className = tileElm.className.replace('layout-tile-item', 'slider-tile-item');
                    sliderItems.push(slideElm);
                }
            });
        }

        // Copy classes assigned from the grid container to the slider.
        const classNames = [];
        let i = 0;
        while (this.itemsContainer.classList.item(i)) {
            classNames.push(this.itemsContainer.classList.item(i));
            i += 1;
        }

        const sliderPlugin = new Slider(sliderItems, { previous: '', next: '' }, false, `large-tile-grid__slider ${classNames.join(' ')}`, SLIDER_TYPE.STEP, true);
        this.element.removeChild(this.itemsContainer);
        this.element.appendChild(sliderPlugin.render());
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

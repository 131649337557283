// Partial dependencies
import AnalyticsApi from 'partials/analytics';

// Local dependencies
import ERRORS_MAP from './../constants/creditAppErrorMap';

/**
 * @method parseError
 * @description Parses the errors from the response data and constructs a more readable list of
 * errors based on the applicant section (primaryApplicant ||  coApplicant)
 * @param response {Object} Response from finance driver service
 * @return {*}
 */
function parseError(response) {
    const analyticsApi = new AnalyticsApi('satellite');
    const {
        result: {
            errorDetails = []
        } = {}
    } = response;
    const specificErrors = [];

    errorDetails.forEach((err) => {
        if (err.errorCode && err.errorMessage && ERRORS_MAP.ERRORS[err.errorCode]) {
            const mappedError = ERRORS_MAP.ERRORS[err.errorCode];

            // Applicant Types
            const isSpouseError = err.errorMessage.indexOf(ERRORS_MAP.APPLICATION_TYPE.SPOUSE) !== -1;
            const isPrimaryError = err.errorMessage.indexOf(ERRORS_MAP.APPLICATION_TYPE.PRIMARY) !== -1;
            const isPrimarySpouseError = isPrimaryError && isSpouseError;
            const isCoError = err.errorMessage.indexOf(ERRORS_MAP.APPLICATION_TYPE.CO) !== -1;
            const isCoSouseError = isCoError && isSpouseError;

            /*
             * Section types
             */

            // Personal Info Type
            const isPersonalInfo = err.errorMessage.indexOf(ERRORS_MAP.APPLICANT_INFO) !== -1;

            // Address Types
            const isCurrentAddress = err.errorMessage.indexOf(ERRORS_MAP.ADDRESS_TYPE.CURRENT) !== -1;
            const isPreviousAddress = err.errorMessage.indexOf(ERRORS_MAP.ADDRESS_TYPE.PREVIOUS) !== -1;
            const isSpouseAddress = err.errorMessage.indexOf(ERRORS_MAP.ADDRESS_TYPE.SPOUSE) !== -1;

            // Employment Types
            const isCurrentEmployer = err.errorMessage.indexOf(ERRORS_MAP.EMPLOYMENT_TYPE.CURRENT) !== -1;
            const isPreviousEmployer = err.errorMessage.indexOf(ERRORS_MAP.EMPLOYMENT_TYPE.PREVIOUS) !== -1;

            let section;

            // Determine the section that the error relates to base on the applicant and section types
            if (isPrimaryError && isPersonalInfo) {
                section = ERRORS_MAP.SECTIONS.PRIMARY_PERSONAL_INFO;
            } else if (isCoError && isPersonalInfo) {
                section = ERRORS_MAP.SECTIONS.CO_PERSONAL_INFO;
            } else if (isPrimaryError && isCurrentAddress && !isPrimarySpouseError) {
                section = ERRORS_MAP.SECTIONS.PRIMARY_RESIDENCE_CURRENT;
            } else if (isPrimaryError && isPreviousAddress) {
                section = ERRORS_MAP.SECTIONS.PRIMARY_RESIDENCE_PREVIOUS;
            } else if ((isPrimaryError && isSpouseAddress) || isPrimarySpouseError) {
                section = ERRORS_MAP.SECTIONS.PRIMARY_SPOUSE_INFORMATION;
            } else if (isCoError && isCurrentAddress && !isCoSouseError) {
                section = ERRORS_MAP.SECTIONS.CO_RESIDENCE_CURRENT;
            } else if (isCoError && isPreviousAddress) {
                section = ERRORS_MAP.SECTIONS.CO_RESIDENCE_PREVIOUS;
            } else if ((isCoError && isSpouseAddress) || isCoSouseError) {
                section = ERRORS_MAP.SECTIONS.CO_SPOUSE_INFORMATION;
            } else if (isPrimaryError && isCurrentEmployer) {
                section = ERRORS_MAP.SECTIONS.PRIMARY_EMPLOYMENT_CURRENT;
            } else if (isPrimaryError && isPreviousEmployer) {
                section = ERRORS_MAP.SECTIONS.PRIMARY_EMPLOYMENT_PREVIOUS;
            } else if (isCoError && isCurrentEmployer) {
                section = ERRORS_MAP.SECTIONS.CO_EMPLOYMENT_CURRENT;
            } else if (isCoError && isPreviousEmployer) {
                section = ERRORS_MAP.SECTIONS.CO_EMPLOYMENT_PREVIOUS;
            }

            // Make sure that the error was not already added
            const isNewError = !specificErrors.find((errItem) => (
                errItem.id === mappedError.id && errItem.section === section
            ));

            if (isNewError) {
                specificErrors.push({
                    code: err.errorCode,
                    id: mappedError.id,
                    labelId: mappedError.labelId,
                    messageId: mappedError.messageId,
                    section
                });

                analyticsApi.logEvent(
                    `FINANCE_DRIVER_VALIDATION_ERROR:${mappedError.id}`
                );
            }
        }
    });

    response.status.error.errors = specificErrors;

    return response;
}

export default {
    parseError
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import DependencyLoader from 'utils/DependencyLoader';

/**
 * @const PREFERRED_DEALER_PLUGIN_ID
 * @description ID of container for preferred dealer plugin
 */
const PREFERRED_DEALER_PLUGIN_ID = 'preferred-dealer-plugin-container';

/**
 * @class FormsPreFooter
 * @description Component for forms pre footer
 * @param {Object} element
 */
export default class FormsPreFooter {
    constructor(element) {
        this.element = element;
        this.dependencyLoader = new DependencyLoader();

        this.cacheDOM();
        this.loadPreferredDealerPlugin();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM Element
     */
    cacheDOM() {
        this.pluginContainer = this.element.querySelector(`#${PREFERRED_DEALER_PLUGIN_ID}`);
    }

    /**
     * @method loadPreferredDealerPlugin
     * @description Loads the preferred dealer plugin if the container is available
     */
    loadPreferredDealerPlugin() {
        if (this.pluginContainer) {
            this.dependencyLoader.loadPlugins(['preferred-dealer']);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

/**
 * Template for a DealerSelectorLocationSearch view.
 * Uses ES6 template literal to composite the markup for a DealerSelectorLocationSearch module
 */
export default (content) => htmlNode `
    <div class="dealer-selector-location-search">
        <h1 class="dealer-selector__title">${content.dealerSelectorFindTitle}</h1>
        <p class="dealer-selector__desc">${content.enterYourLocation}</p>
        <div class="dealer-selector-location-search__bar" data-dealer-search-bar></div>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// util dependencies
import {
    formatNumber,
    tokenReplace
} from 'utils';

import ServiceRequestCache from 'utils/ServiceRequestCache';

// Local dependencies
import PACKAGE_TYPE_CODES from '../constants/packageTypeCodes';

/**
 * @property warrantyMaintenanceCache
 * @description Instance of the ServiceRequestCache that is used for caching
 * successful service requests
 * @type {ServiceRequestCache}
 */
const warrantyMaintenanceCache = new ServiceRequestCache();

/**
 * @function isRequestSuccessful
 * @description Checks if the response code is a 2xx
 * @returns {Boolean} True if the response code is 2xx
 */
function isRequestSuccessful(response) {
    return /^2\d\d$/.test(response.status.code);
}

/**
 * @function ExceptionError
 * @description Constructor function that creates a custom error object
 * @param {number} code status code from response
 * @param {string} message error message
 */
function ExceptionError(code, message) {
    this.code = code;
    this.message = message;
    return this;
}

/**
 * @function createProduct
 * @description Constructs an array of package products
 * @param config {Object} Config with country, currency and language codes
 * @param products {Array} Array of product object from the package-rates service
 * @param packageContent {Object} Package content from the CAAS
 * @param vehicleCondition {string} Vehicle condition NEW | CPO | USED
 * @return {*}
 */
function createProduct(config, products, packageContent, vehicleCondition) {
    const {
        options: optionContent = {}
    } = packageContent || {};
    const isNewVehicle = vehicleCondition === 'NEW';

    return products.map((product, index) => {
        let productType = '';
        const quantifier = isNewVehicle && optionContent.additional ? optionContent.additional : optionContent.total;
        const serviceLength = product.serviceLength;
        const title = serviceLength > 1 ?
            optionContent.titleMultiple :
            optionContent.title;
        const extendedTitle = isNewVehicle && optionContent.titleMiles && product.serviceMileage ?
            tokenReplace(optionContent.titleMiles,
                {
                    mileage: formatNumber.toCommaSeparated(product.serviceMileage)
                })
            : '';

        if (product.isPremier || product.isStar) {
            productType = product.isPremier ? optionContent.premier : optionContent.star;
        }

        const productTitle = product.packageTypeCode === 'PPM'
            ? product.packageName.toLowerCase().replace('s', 'S')
            : tokenReplace(
                title,
                { number: serviceLength, productType, quantifier, extendedTitle }
            );

        const productPriceValue = product.price && product.price.retailRate ?
            parseFloat(product.price.retailRate) :
            0;
        const productPrice = productPriceValue > 0 ?
        {
            formattedValue: formatNumber.toCurrency(
                formatNumber.toNumber(product.price.retailRate),
                config.country,
                config.language,
                config.currency
            ),
            value: parseFloat(product.price.retailRate)
        } :
        {
            formattedValue: optionContent.priceContact,
            value: productPriceValue
        };

        const productPriceNet = product.price && product.price.netRate ?
        {
            formattedValue: formatNumber.toCurrency(
                formatNumber.toNumber(product.price.netRate),
                config.country,
                config.language,
                config.currency
            ),
            value: parseFloat(product.price.netRate)
        } :
        {
            formattedValue: '',
            value: ''
        };

        return {
            ctaAdd: optionContent.ctaAdd,
            ctaAddLabel: tokenReplace(
                optionContent.ctaAdd,
                {
                    productName: productTitle,
                    cost: (productPrice && productPrice.formattedValue)
                }
            ),
            ctaRemove: optionContent.ctaRemove,
            ctaRemoveLabel: tokenReplace(
                optionContent.ctaRemoveLabel,
                {
                    productName: productTitle,
                    cost: (productPrice && productPrice.formattedValue)
                }
            ),
            description: optionContent.description ? optionContent.description : null,
            id: product.packageCodeId,
            image: Array.isArray(packageContent.imgs) && packageContent.imgs.length ?
                (packageContent.imgs[index] || packageContent.imgs[0]) :
                null,
            isSuggested: product.isPopular,
            isSuggestedDesc: optionContent.flag,
            name: product.packageName,
            packageDisclaimer: packageContent.disclaimer,
            packageTypeDescription: optionContent.packageTypeDescription,
            price: productPrice,
            priceMonthly: optionContent.termMonth,
            priceNetRate: productPriceNet,
            pricingNoteMonthly: optionContent.priceNoteMBFS,
            pricingNoteTotal: optionContent.priceNote,
            productDisclaimer: optionContent.productDisclaimer,
            productType: PACKAGE_TYPE_CODES[product.packageTypeCode],
            serviceLength,
            term: product.term,
            title: productTitle
        };
    });
}

/**
 * @function createPackages
 * @description Constructs a collection of packages with products
 * @param packageData {Object} Collection of products from package-rates service
 * @param config {Object} Config with country, currency and language codes
 * @param content {Object} Package content from CAAS
 * @param vehicleCondition {string} Vehicle condition NEW | CPO | USED
 * @param mileage {string} vehicle mileage
 * @return {{}}
 */
function createPackages(packageData, config, content, vehicleCondition, mileage) {
    const packages = {};

    Object.keys(packageData).forEach((packageKey) => {
        const packageContent = content[packageKey];
        const {
            options: optionContent = {}
        } = packageContent;
        const products = packageData[packageKey];
        let description;

        if (vehicleCondition && vehicleCondition !== 'CPO' && packageContent.headerNonCpoDescription) {
            // if the vehicle condition is not cpo and there is a special non-cpo description, set that
            description = products && products.length > 0
                ? packageContent.headerNonCpoDescription
                : packageContent.headerDescriptionEmpty;
        } else {
            description = products && products.length > 0
                ? tokenReplace((packageContent.headerDescription || ''), { mileage })
                : packageContent.headerDescriptionEmpty;
        }

        packages[packageKey] = {
            title: packageContent.headerTitle,
            description,
            disclaimer: packageContent.disclaimer,
            moreInfo: packageContent.moreInfo,
            moreInfoLinks: packageContent.moreInfoLinks,
            priceMonthly: optionContent.termMonth,
            pricingNoteMonthly: optionContent.priceNoteMBFS,
            pricingNoteTotal: optionContent.priceNote,
            productDisclaimer: optionContent.productDisclaimer,
            products: createProduct(config, products, packageContent, vehicleCondition),
            startingAt: packageContent.startingAt,
            type: packageKey,
            unselectPackage: packageContent.options && packageContent.options.unselect ?
                tokenReplace(packageContent.options.unselect, { packageTitle: packageContent.headerTitle }) :
                null,
            vehicleCondition
        };
    });

    // this is for overriding the ppm obj, so when there are esc products we'll be storing them in the ppm obj,
    // since these products are the same
    if (packages.esc && packages.esc.products.length > 0) {
        packages.ppm = {
            ...packages.esc,
            type: packages.ppm.type,
        };
    }
    delete packages.esc;

    return packages;
}

/**
 * @method parseWarrantyAndMaintenance
 * @desc Iterates through the packages and updates prices with formatted price values based on location
 * @param data {Object} returned object after successfully fetching warranty/maintenance products
 * @param config {Object} Configuration object containing: country, currency, language
 * @param content {Object} content {object} warranty maintenance localized content
 * @param mileage {string} vehicle mileage
 * @return {*}
 */
function parseWarrantyAndMaintenance(data, config, content, mileage) {
    const packageData = { ...data };
    delete packageData.vehicleCondition;
    const packageOptions = createPackages(packageData, config, content, data.vehicleCondition, mileage);

    return {
        packageOptions,
        vehicleCondition: data.vehicleCondition
    };
}

/**
 *
 * @param config {Object} configuration object containing country, currency, language, endpoint
 * @param config.endpoint {string} endpoint needed for warranty/maintenance product request
 * @param config.country {string} country code
 * @param config.currency {string} currency code
 * @param config.language {string} language code
 * @param dealerId {string} dealer id
 * @param mileage {string} vehicle mileage
 * @param vin {string} vehicle vin
 * @param {string} vehicleType Vehicle type
 * @param {boolean} isCertified Flag to indicate if vehicle is certified
 * @param content {object} warranty maintenance localized content
 * @return {Promise<any | never>}
 */
function getWarrantyMaintenance(config, dealerId, mileage, vin, vehicleType, isCertified, content) {
    let vehicleCondition;
    if (vehicleType === 'PRE') {
        vehicleCondition = isCertified ? 'Certified' : 'Used';
    } else if (vehicleType === 'NEW') {
        vehicleCondition = 'New';
    }

    const options = {
        headers: {
            'Content-type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST',
        body: JSON.stringify({
            vin: vin.toUpperCase(),
            dealerId,
            mileage,
            vehicleCondition
        })
    };
    return warrantyMaintenanceCache.fetch(config.endpoint, options)
        .then((response) => response.json())
        .then((response) => {
            if (!isRequestSuccessful(response)) {
                throw new ExceptionError(
                    response.status.code,
                    'Warranty & Maintenance Products Not Successful'
                );
            }

            return parseWarrantyAndMaintenance(response.result, config, content, mileage);
        })
        .catch((error) => {
            throw error;
        });
}

export default {
    getWarrantyMaintenance
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @description Template for forms vehicle selector
 * Uses ES6 template literal to composite the markup for a vehicle selector
 */

// Util dependencies
import { htmlNode, tokenReplace, generateUniqueID } from 'utils';

const renderSubHeading = (clickHereLabel = '', changeSelectionLabel = '', changeVehicleCtaLabel) => {
    const changeSelectionCTA = `<button data-vehicle-selector-switch class="link link_plain-link" type="button">${changeVehicleCtaLabel || clickHereLabel}</button>`;
    const subHeading = changeVehicleCtaLabel ?
        changeSelectionCTA : tokenReplace(changeSelectionLabel, [changeSelectionCTA]);
    return subHeading;
};

export default ({ changeVehicleCtaLabel = '', content = {}, disableChange = false, enableYears = false, submitCtaLabel = '', submitCtaClass = '', errorText = '' }) => {
    const ctaId = generateUniqueID();

    return htmlNode`
    <div class="vehicle-selector ${enableYears ? 'vehicle-selector--years-enabled' : ''}" data-analytics-container="{'blade':'vehicle-selector'}" tabindex="0">
        <div class="vehicle-selector__loader-container">
            <div class="vehicle-selector__loader-content-container"></div>
        </div>
        <fieldset class="vehicle-selector__selection">
            <legend class="leads-form__sub-heading ${!content.selectVehicleDetails ? 'hide' : ''}">
                <h3>${content.selectVehicleDetails}</h3>
            </legend>
            <div data-vehicle-selector-years class="vehicle-selector__years ${!enableYears ? 'hide' : ''}"></div>
            <div class="vehicle-selector__classes" data-vehicle-selector-classes></div>
            <div class="vehicle-selector__models" data-vehicle-selector-models></div>
        </fieldset>
        <div class="vehicle-selector__submit-cta__wrapper">
        ${submitCtaLabel ? `<button type="button" class="button button_primary button--wide vehicle-selector__submit-cta ${submitCtaClass}" data-analytics-trigger="cta">${submitCtaLabel}</button>` : ''}
            <p id="${ctaId}" class="vehicle-selector__submit-cta__error">${errorText}</p>
        </div>
        <div class="vehicle-selector__vehicle">
            <h3 class="leads-form__sub-heading">${content.vehicleSelected}</h3>
            ${!disableChange &&
            `<span class="vehicle-selector__switch-back">
                ${renderSubHeading(content.clickHere, content.changeVehicleSelection, changeVehicleCtaLabel)}
            </span>`}

            <div data-vehicle-selector-chosen-vehicle></div>
        </div>
    </div>
`;
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// util dependencies
import { screen, customEventDispatcher } from 'utils';
import { EVENTS, CUSTOM_EVENTS } from 'Constants';

// local depencies
import LevelThreeMenu from './LevelThreeMenu';
import DealerLevelThreeMenu from './DealerLevelThreeMenu';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{GLOBAL_HEADER_MENU_L3: string, GLOBAL_HEADER_MENU_L3_OPEN: string}}
 */
const CLASSES = {
    GLOBAL_HEADER_MENU_L3: 'global-header__menu-l3',
    GLOBAL_HEADER_MENU_L3_OPEN: 'global-header__menu-l3--open',
    GLOBAL_HEADER_MENU_L3_NO_TRANSITION: 'global-header__menu-l3--no-transition',
    GLOBAL_HEADER_MENU_L1_LIST_BUTTON: 'global-header__menu-l1-list-button'
};

const MENU_INDEXES = {
    VEHICLE_MENU_INDEX: 0,
    SHOPPING_MENU_INDEX: 1,
    DEALER_MENU_INDEX: 3,
    UTILITY_MENU_INDEX: 2
};

export default class LevelThreeContainer {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        const isMB = window.mbVans.pageData.brand === 'MB';
        this.setBindings();
        this.cacheDOMElements();
        this.attachEvents();

        this.menus = [];
        if (isMB) {
            this.menus.push(new LevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[MENU_INDEXES.VEHICLE_MENU_INDEX]
            ));
            this.menus.push(new LevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[MENU_INDEXES.SHOPPING_MENU_INDEX]
            ));
            this.menus.push(new LevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[MENU_INDEXES.UTILITY_MENU_INDEX]
            ));
            this.menus.push(new DealerLevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[MENU_INDEXES.DEALER_MENU_INDEX]
            ));
        } else {
            this.menus.push(new LevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[0]
            ));
            this.menus.push(new LevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[1]
            ));
            this.menus.push(new DealerLevelThreeMenu(
                this.menuElm.children[this.menus.length],
                this.menuButtonElms[2]
            ));
        }

        this.state = {
            currentScreenState: screen.getCurrentState(),
            openMenuIndex: null,
            menusInactive: false
        };
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.menuElm = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L3}`);
        this.menuButtonElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L1_LIST_BUTTON}`)];
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onMenuTransitionEnd = this.onMenuTransitionEnd.bind(this);
        this.onMenuCloseTransitionEnd = this.onMenuCloseTransitionEnd.bind(this);
        this.removeNoTransitionsClass = this.removeNoTransitionsClass.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onGlobalNavDealerSearch = this.onGlobalNavDealerSearch.bind(this);
    }

    /**
     * @method attachEvents
     * @description Attaches event listener
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            this.onGlobalNavDealerSearch
        );
    }

    /**
     * @method detachEvents
     * @description detach events
     */
    detachEvents() {
        screen.addResizeListener(this.onScreenResize);
        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            this.onGlobalNavDealerSearch
        );
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        const newScreenState = screen.getCurrentState();
        const { currentScreenState, openMenuIndex } = this.getState();
        if (currentScreenState !== newScreenState) {
            this.reset();
            this.updateState({ currentScreenState: newScreenState });
        } else if (openMenuIndex !== null && screen.gte(screen.SIZES.XLARGE)) {
            this.setHeight(false, openMenuIndex);
        }
    }

    /**
     * @method onGlobalNavDealerSearch
     * @description handler for the GLOBAL_NAV_DEALER_SEARCH custom event
     */
    onGlobalNavDealerSearch() {
        this.reset();
    }

    /**
     * @method onMenuCloseTransitionEnd
     * @description when transition for closing menu three ends
     */
    onMenuCloseTransitionEnd() {
        this.menuElm.removeEventListener(EVENTS.TRANSITION_END, this.onMenuCloseTransitionEnd);
        this.deactivateAll();
    }

    /**
     * @method onMenuTransitionEnd
     * @description sets the overflowY to auto once the menu has fully dropped down
     *              and allows any content to scroll if necessary
     */
    onMenuTransitionEnd(e) {
        if ((e.propertyName === 'height' || e.propertyName === 'transform') && e.target === this.menuElm) {
            console.log('menu index', this.menuElm.menuItemIndex);
            const contentHeight = this.menus[this.menuElm.menuItemIndex].scrollHeight;
            const borderWidth = Math.round(parseFloat(getComputedStyle(this.menuElm).borderBottomWidth));
            const totalHeight = contentHeight + borderWidth;
            const isContentFits = totalHeight <= Math.min(this.menuElm.offsetHeight, window.innerHeight - 80);
            this.menuElm.removeEventListener(EVENTS.TRANSITION_END, this.onMenuTransitionEnd);
            this.menuElm.style.overflowY = isContentFits ? '' : 'auto';
            this.menus[e.target.menuItemIndex].focusFirstElement();
        }
    }

    /**
     * @method open
     * @description open level three menu container
     * @param openLevelOneMenuItemIndex {int} index of level one item already open
     * @param clickedItemIndex {int} index of level two item clicked
     */
    open(openLevelOneMenuItemIndex, clickedItemIndex = null) {
        this.menus[openLevelOneMenuItemIndex].activateContent(clickedItemIndex);
        this.updateState({ menusInactive: false });
        this.menuElm.style.overflowY = 'hidden';
        this.menuElm.menuItemIndex = openLevelOneMenuItemIndex;
        this.menuElm.addEventListener(EVENTS.TRANSITION_END, this.onMenuTransitionEnd);
        this.menuElm.classList.add(CLASSES.GLOBAL_HEADER_MENU_L3_OPEN);

        // dispatch model selectors close event
        if (screen.gte(screen.SIZES.XLARGE)) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.GLOBAL_NAV_MENU_OPENED)
            );
        }
    }

    /**
     * @method close
     * @description close level three menu container
     */
    close(isReset = false) {
        this.menuElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L3_OPEN);
        if (!screen.gte(screen.SIZES.XLARGE) && !this.getState().menusInactive && !isReset) {
            this.menuElm.addEventListener(EVENTS.TRANSITION_END, this.onMenuCloseTransitionEnd);
        } else {
            this.deactivateAll();
        }
    }

    /**
     * @method activate
     * @description open level three menu container for XL+
     * @param clickedItemIndex {int} index of level one item clicked
     */
    activate(clickedItemIndex) {
        this.open(clickedItemIndex);
        this.menus[clickedItemIndex].activate();
        this.setHeight(false, clickedItemIndex);
        this.updateState({ openMenuIndex: clickedItemIndex });
    }

    /**
     * @method deactivate
     * @description close level three menu container
     * @param clickedItemIndex {int} index of level one item clicked
     */
    deactivate(clickedItemIndex) {
        this.close();
        this.setHeight(true, clickedItemIndex);
        this.menus[clickedItemIndex].deactivate();
        this.updateState({ openMenuIndex: null });
    }

    /**
     * @method deactivateAll
     * @description deactivate all menus
     */
    deactivateAll() {
        this.updateState({ menusInactive: true });
        this.menus.forEach((menu) => {
            menu.deactivate();
        });
        this.updateState({ openMenuIndex: null });
    }

    /**
     * @method setHeight
     * @description set height for sub navigation based on content
     * @param willClose {boolean} if the menu will be close or open
     * @param clickedItemIndex {int} index of level one item clicked
     */
    setHeight(willClose, clickedItemIndex) {
        const { openMenuIndex } = this.getState();
        const contentHeight = this.menus[clickedItemIndex].element.scrollHeight;
        const borderWidth = Math.round(parseFloat(getComputedStyle(this.menuElm).borderBottomWidth));
        if (!willClose &&
            openMenuIndex !== null &&
            contentHeight + borderWidth !== this.menuElm.offsetHeight
        ) {
            this.menuElm.classList.add(CLASSES.GLOBAL_HEADER_MENU_L3_NO_TRANSITION);
            window.requestAnimationFrame(this.removeNoTransitionsClass);
        }
        this.menuElm.style.height = willClose ? '0' : `${contentHeight + borderWidth}px`;
    }

    removeNoTransitionsClass() {
        this.menuElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L3_NO_TRANSITION);
    }

    /**
     * @method reset
     * @description reset level three menu on screen resize
     */
    reset() {
        if (this.menuElm.classList.contains(CLASSES.GLOBAL_HEADER_MENU_L3_NO_TRANSITION)) {
            this.removeNoTransitionsClass();
            window.requestAnimationFrame(this.reset);
        } else {
            this.close(true);
            if (screen.gte(screen.SIZES.XLARGE)) {
                this.menuElm.style.height = null;
            }
            this.deactivateAll();
        }
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {object} new state value
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return this.state;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import InputRadioControl from './InputRadioControl';
import inputRadioCardControlTemplate from './../templates/inputRadioCardControlTemplate';


const CLASSES = {
    INPUT_ELEMENT: 'form__radio-card',
    LABEL_ELEMENT: 'form__radio-card-label',
};

export default class InputRadioCardControl extends InputRadioControl {
    /**
     * @constructor Construct InputRadioCardControl
     */
    constructor(config = InputRadioControl.DEFAULT_CONFIG) {
        super({
            ...InputRadioControl.DEFAULT_CONFIG,
            ...config
        });
        this.init();
    }

    /**
     * @method init
     * @description creates and renders radio cards in groups, registers events to them
     */
    init() {
        this.createView(inputRadioCardControlTemplate({
            analyticsTrigger: this.getConfig('analyticsTrigger'),
            checked: this.getConfig('checked'),
            displayIcons: this.getConfig('displayIcons'),
            eyebrow: this.getConfig('eyebrow'),
            extendedDescription: this.getConfig('extendedDescription'),
            iconClass: this.getConfig('iconClass'),
            id: this.getConfig('id'),
            labelText: this.getConfig('labelText'),
            name: this.getConfig('name'),
            valueText: this.getConfig('valueText')
        })({ getNode: true }));
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description caches the dom
     */
    cacheDOM() {
        this.labelElement = this.getInput().querySelector(`.${CLASSES.LABEL_ELEMENT}`);
        this.setInputElement(this.getInput().querySelector(`.${CLASSES.INPUT_ELEMENT}`));
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

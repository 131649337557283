// Module dependencies
import { screen } from 'utils';
import { CarouselGallery, TileGallery } from 'partials/gallery';
import { MenuSelector } from 'partials/menu-selector';

// Local dependencies
import galleryApi from './api/galleryApi';

/**
 * Gallery module
 * This imports the JS submodule for small and large gallery and adds screen resize listener to
 * instantiate the respective gallery module.
 */
export default class Gallery {
    constructor(element) {
        this.element = element;
        this.data = null;
        this.loadModule = this.element.dataset.loadModule;
        this.isCarouselGallery = false;
        this.galleryDataEndpoint = this.element.dataset.jsonEndpoint;
        this.enableFilters = this.element.dataset.disableFilters === 'false';
        this.filteredData = null;
        this.filterBy = {
            label: 'All',
            value: 'all'
        };

        this.getData()
            .then(this.createGalleryFilter.bind(this))
            .then(this.createGallery.bind(this))
            .then(this.attachEvents.bind(this));
    }

    getData() {
        return galleryApi.getData({ endpoint: this.galleryDataEndpoint })
            .then((response) => {
                this.data = response.data;
                this.filters = response.filters;
            });
    }

    /**
     * @method createGalleryFilter
     * @description Creates the MenuSelector and attaches to DOM
     */
    createGalleryFilter() {
        if (this.enableFilters) {
            this.galleryFilter = new MenuSelector(
                this.filters,
                {
                    selectionCallback: this.filterData.bind(this),
                    loadModule: this.loadModule,
                }
            );
            this.element.appendChild(this.galleryFilter.render());
        }
    }

    /**
     * @method createGallery
     * @description Checks the current screen state and initiates the respective gallery
     */
    createGallery() {
        const defaultData = galleryApi.filterData(this.data, this.filters[0].value);

        if (screen.getState().small) {
            this.galleryModule = new CarouselGallery(defaultData, {
                enableThumbnailNavigation: true
            });
            this.isCarouselGallery = true;
        } else {
            this.galleryModule = new TileGallery(defaultData);
            this.isCarouselGallery = false;
        }

        this.element.appendChild(this.galleryModule.render());

        if (this.enableFilters) {
            this.galleryFilter.updateToggleAriaAttribute();
        }
    }

    /**
     * @method filterData
     * @param {String} filter - The string value of the selected filter
     * @description Filters data using API method. This is a callback method passed into'
     * MenuSelector
     */
    filterData(filter) {
        this.filteredData = galleryApi.filterData(this.data, filter);
        this.galleryModule.updateData(this.filteredData);
    }

    /**
     * @method attachEvents
     * @description Adds screen resize handler
     */
    attachEvents() {
        screen.addResizeListener(this.onResizeHandler.bind(this));
    }

    /**
     * @method onResizeHandler
     * @description Calls destroy method on the current gallery module,
     * and instantiates new gallery module
     */
    onResizeHandler() {
        if (this.galleryModule &&
            ((this.isCarouselGallery && !screen.getState().small) ||
            (!this.isCarouselGallery && screen.getState().small))) {
            this.galleryModule.destroy();
            this.createGallery();
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @static RELATIONSHIP_TYPES
 * @description relationship type values
 */
export default {
    OTHER: 'Other',
    RELATIVE: 'Relative',
    SPOUSE: 'Spouse'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Api responsible for searching for inventory models by dealer and filter data
 */

// Import utils
import {
    serializeObject,
    uppercaseWorker,
} from 'utils';

// Local dependencies
import {
    createPricingObject
} from './inventorySearchUtils';

/**
 * @method parseModelsData
 * @description Parses the model search response to add price object with raw number and formatted
 * value
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {Object} modelsResponse - Response object of models search API
 * @return {Array} An array of model objects each having msrp objects
 */
function parseModelsData(config, modelsResponse) {
    return modelsResponse.result.models.map((model) => {
        model.msrp = createPricingObject(config, model.msrp);
        model.name = uppercaseWorker(model.name);
        return model;
    });
}

/**
 * @method getModels
 * @description Fetches the available models for the provided class & body style combo
 * @param {Object} config - Configuration object {country, language, currency, endpoint}
 * @param {String} dealerId - Dealer ID of the currently selected dealer
 * @param {Object} filters - Filters object that includes filter options and applied filters
 * @param {Boolean} currentDealerOnly - Indicator for whether to include only current dealer vehicles
 * @return {Promise} A promise which resolves with data of list of models
 */
function getModels(config, dealerId, filters, currentDealerOnly) {
    // Resolve with an empty array if no models are available
    if (!filters.model) {
        return Promise.resolve([]);
    }

    // Make API call only if any model IDs are available
    const requestURLParams = serializeObject({
        brand: config.brand,
        currentDealerOnly,
        dealerId,
        exterior: filters.exterior,
        interior: filters.interior,
        feature: filters.feature,
        maxPrice: filters.maxPrice,
        minPrice: filters.minPrice,
        model: filters.model,
        notMappedFeature: filters.notMappedFeature,
        powerTrain: filters.powerTrain,
        roofHeight: filters.roofHeight,
        wheelbase: filters.wheelbase,
        year: filters.year,
        distance: filters.distance
    });

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(parseModelsData.bind(null, config));
}

/**
 * @method Fetches the available CPO models for a provided location and filters combo
 * @param {Object} config - Configuration object {country, language, currency, endpoint}
 * @param {Object} locationDistance - Location info containing `distance`, `location` properties
 * @param {Object} filters - Filters object that includes filter options and applied filters
 * @param {Boolean} includeAllPreOwned - Indicator for whether to include all pre-owned vehicles
 * @param {Boolean} includeReserveOnly - Indicator for whether to include only reservable vehicles
 * @param {Boolean} currentDealerOnly - Indicator for whether to include only current dealer vehicles
 * @param {String} dealerId - Dealer ID of the currently selected dealer
 */
function getCPOModels(
    config,
    locationDistance,
    filters,
    includeAllPreOwned,
    includeReserveOnly,
    currentDealerOnly,
    dealerId
) {
    const {
        location,
        distance
    } = locationDistance;

    const params = {
        brand: config.brand,
        driveTrain: filters.driveTrain,
        exterior: filters.exterior,
        interior: filters.interior,
        invType: includeAllPreOwned ? 'all' : null,
        lat: location.type === 'place' ? location.lat : null,
        lng: location.type === 'place' ? location.lng : null,
        maxMileage: filters.maxMileage,
        maxPrice: filters.maxPrice,
        maxYear: filters.maxYear || config.invYears.maxInvYear,
        minMileage: filters.minMileage,
        minPrice: filters.minPrice,
        minYear: (
            +filters.minYear === +config.invYears.minInvYear
                ? config.invYears.minApiInvYear
                : filters.minYear || config.invYears.minApiInvYear
        ),
        model: filters.model,
        powertrain: filters.powertrain,
        roofHeight: filters.roofHeight,
        zip: location.type === 'zip' ? location.value : null,
    };

    if (currentDealerOnly && dealerId) {
        params.dealerId = dealerId;
        params.currentDealerOnly = currentDealerOnly;
    } else {
        params.distance = distance;
    }

    const requestURLParams = serializeObject(params);

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(parseModelsData.bind(null, config));
}


// Exports public api methods
export default {
    getCPOModels,
    getModels
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { GoogleMap } from 'partials/google-maps';

/**
 * @const ICONS
 * @description Path for preferred dealer pin icon
 */
const ICONS = {
    preferredDealer: '/etc/designs/mb-vans/images/icon-mb-dealer-location_denim.png',
    dealer: '/etc/designs/mb-vans/images/icon-mb-dealer-location_black.png'
};

/**
 * View responsible for rendering the dealer map and displaying the
 * preferred dealer on it
 */
export default class DealerMap {
    /**
     * @constructor
     * @description Creates DealerMap
     * @param {Node} element - Element to render the map to
     * @param {Object} dealer - Dealer to show in the map
     * @poram {boolean} isPreferredDealer - is the dealer also the preferred dealer
     */
    constructor(element, dealer, isPreferredDealer) {
        this.element = element;
        this.dealer = dealer;
        this.map = null;
        this.isPreferredDealer = isPreferredDealer;

        this.createMap();
    }

    /**
     * @method destroy
     * @description Destroys the view
     */
    destroy() {
        if (this.map) {
            this.map.destroyMap();
        }

        this.map = null;
    }

    /**
     * @method createMap
     * @description Creates a Google Map object to render to the map element
     */
    createMap() {
        const mapConfig = {
            center: {
                lat: parseFloat(this.dealer.location.lat),
                lng: parseFloat(this.dealer.location.lng)
            },
            streetViewControl: false,
            zoomControl: false
        };

        this.map = new GoogleMap({
            element: this.element,
            config: mapConfig,
            onMapCreatedCallback: this.addDealerMarker.bind(this),
            markerClickCallback: this.onMarkerClick.bind(this)
        });
    }

    /**
     * @method addDealerMarker()
     * @description Sets the icon to use for dealer pin and calls
     * this.map.addMarkers to render the dealer in the map
     */
    addDealerMarker() {
        const dealer = { ...this.dealer };
        dealer.icon = this.isPreferredDealer ? ICONS.preferredDealer : ICONS.dealer;
        this.map.addMarkers([dealer], true);
    }

    /**
     * @method onMarkerClick()
     * @description callback on marker click to open a new tab with the directions link
     */
    onMarkerClick() {
        window.open(this.dealer.directionsLink);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

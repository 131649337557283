/**
 * @const STORAGE_KEYS
 */
export default {
    CREDIT_APPLICATION: 'mbvans-credit-application',
    LOGIN_TOKEN: 'mbvans-login-token',
    VEHICLE_FAVORITE: 'mbvans-vehicle-favorite',
    PAYMENT_ESTIMATOR_DATA: 'mbvans-payment-estimator-data',
    RECENTLY_VIEWED_VEHICLE: 'mbvans-recently-viewed-vehicle',
    SAVED_INPROGRESS_RESERVATION: 'mbvans-reservation-in-progress',
    RECALLS_VIN: 'mbvans-recalls-vin',
    TAKATA_PAGE_VISIT: 'mbvans-takata-page-visit',
    TCO_INFO: 'mbvans-tco-info',
    UNSAVED_BUILD_INFO: 'mbvans-unsaved-builds',
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a carousel
 * Uses ES6 template literal to composite the markup for a carousel
 */
 import { html } from 'utils';

 export default (transitionType = '', href = '', linkLabel = '') => html`
     <div class="carousel">
         <${href ? `a href=${href} aria-label="${linkLabel}"` : 'div'} data-carousel-swipe-container class="carousel--${transitionType}">
             <ul data-carousel-slides
                 class="carousel__slides">
             </ul>
         </${href ? 'a' : 'div'}>
         <div data-carousel-navigation />
     </div>
 `.trim();

 // do not delete 9fbef606107a605d69c0edbcd8029e5d

import { EVENTS } from 'Constants';
import { noop } from 'utils';

// Partial dependencies
import ToggleDrawer from 'partials/ToggleDrawer';
import { YouTube } from 'partials/youtube';

// Local dependencies
import content from '../config/content';
import youTubeModalItemTemplate from '../templates/youTubeModalItemTemplate';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{HTML5_VIDEO_CONTROL: string, VIDEO: string, VIDEO_WIDTH: string, COPY_CONTAINER: string}}
 */
const CLASSES = {
    HIDE: 'hide',
    TRANSCRIPT_CONTENT: 'youtube-transcript__content',
    TRANSCRIPT_TOGGLE_BUTTON: 'youtube-transcript__toggle-button',
    TRANSCRIPT_WRAPPER: 'youtube-transcript__wrapper',
    TRIGGER_BUTTON: 'youtube-trigger__modal',
    YOUTUBE_CONTAINER: 'youtube-container-iframe'
};

/**
 * @const ATTRIBUTES
 * @description Collection of attributes to interface with
 * @type {{ARIA_EXPANDED: string}}
 */
const ATTRIBUTES = {
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_HIDDEN: 'aria-hidden'
};

class YouTubeModalContent {
    constructor({
        videoId,
        youTubeElement,
        videoTranscript
    }) {
        this.videoId = videoId;
        this.youTubeModalElem = youTubeElement;
        this.videoTranscript = videoTranscript;
        this.transcriptDrawer = null;
        this.setBindings();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.initialize = this.initialize.bind(this);
        this.onTriggerClick = this.onTriggerClick.bind(this);
        this.beforeYouTubeModalClose = this.beforeYouTubeModalClose.bind(this);
        this.onYouTubePlayerReady = this.onYouTubePlayerReady.bind(this);
        this.onTranscriptToggle = this.onTranscriptToggle.bind(this);
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.transcriptWrapperElem = this.youTubeModalElem.querySelector(`.${CLASSES.TRANSCRIPT_WRAPPER}`);
        this.transcriptToggleButtonElem = this.youTubeModalElem.querySelector(`.${CLASSES.TRANSCRIPT_TOGGLE_BUTTON}`);
        this.transcriptContentElem = this.youTubeModalElem.querySelector(`.${CLASSES.TRANSCRIPT_CONTENT}`);
    }

    /**
     * @method detachEvents
     * @description removes event listeners
     */
    detachEvents() {
        if (this.triggerElem) {
            this.triggerElem.removeEventListener(EVENTS.CLICK);
        }
    }

    /**
     * @method initialize
     * @description initialize the component
     */
    initialize() {
        this.youTubePlayerVars = {
            events: {
                onReady: this.onYouTubePlayerReady
            },
        };
        this.initYouTubePlayer();
        this.cacheDOM();
        if (!this.transcriptDrawer && this.videoTranscript) {
            this.initTranscriptDrawer();
        }
    }

    /**
     * @method update
     * @description Update youtube player with videoId and update transcript content
     */
    update(videoId, videoTranscript) {
        this.ytPlayer.loadVideoById({ videoId });
        this.videoId = videoId;
        this.videoTranscript = videoTranscript;
        if (this.transcriptDrawer) {
            if (this.transcriptDrawer.isExpanded) {
                this.transcriptToggleButtonElem.click();
            }
            this.transcriptDrawer.destroy();
            this.transcriptDrawer = null;
            this.transcriptWrapperElem.classList.add('hide');
        }
        if (this.videoTranscript) {
            this.transcriptContentElem.innerHTML = this.videoTranscript;
            this.transcriptWrapperElem.classList.remove('hide');
            this.initTranscriptDrawer();
        }
    }

    /**
     * @method enable
     * @description Inits YouTube player
     */
    enable() {
        if (!this.ytPlayer) {
            this.enableTrigger();
        }
        if (!this.transcriptDrawer && this.videoTranscript) {
            this.cacheDOM();
            this.initTranscriptDrawer();
        }
    }

    /**
     * @method disable
     * @description Pauses YouTube player
     */
    disable() {
        if (this.ytPlayer) {
            this.ytPlayer.pauseVideo();
        }
        if (this.transcriptDrawer) {
            this.transcriptDrawer.closeDrawer();
        }
    }

    enableTrigger() {
        if (this.triggerElem) {
            this.triggerElem.classList.remove(CLASSES.HIDE);
            this.triggerElem.removeAttribute(ATTRIBUTES.ARIA_HIDDEN);
            this.triggerElem.addEventListener(EVENTS.CLICK, this.onTriggerClick);
        }
    }

    disableTrigger() {
        if (this.triggerElem) {
            this.triggerElem.removeEventListener(EVENTS.CLICK, this.onTriggerClick);
            this.triggerElem.remove();
            this.triggerElem = null;
        }
    }

    onTriggerClick() {
        this.initialize();
    }

    get triggerElem() {
        if (!this.te) {
            this.te = this.youTubeModalElem.querySelector(`.${CLASSES.TRIGGER_BUTTON}`);
        }
        return this.te;
    }

    set triggerElem(te) {
        this.te = te;
    }

    /**
     * @method beforeYouTubeModalClose
     * @description callback method for when modal will close
     */
    beforeYouTubeModalClose() {
        if (this.transcriptDrawer) {
            this.transcriptDrawer.destroy();
            this.transcriptDrawer = null;
        }
    }

    /**
     * @method onYouTubePlayerReady
     * @description callback method for YouTube player ready event
     * @param e {Event} event object
     */
    onYouTubePlayerReady(e) {
        this.ytPlayer = e.target;
        this.disableTrigger();
    }

    /**
     * @method initYouTubePlayer
     * @description initializes youTube video player
     */
    initYouTubePlayer() {
        const container = this.youTubeModalElem.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);
        YouTube.createInstance(
            this.videoId,
            container,
            noop,
            this.youTubePlayerVars
        );
    }

    /**
     * @method createYouTubeModalElement
     * @description creates modal content element
     */
    createYouTubeModalElement() {
        this.youTubeModalElem = youTubeModalItemTemplate({
            videoTranscript: this.videoTranscript
        })({ getNode: true });
        return this.youTubeModalElem;
    }

    /**
     * @method initTranscriptDrawer
     * @description initializes the transcript drawer
     */
    initTranscriptDrawer() {
        this.transcriptDrawer = new ToggleDrawer(this.transcriptToggleButtonElem, {
            closedText: content.hideVideoTranscript,
            openedText: content.showVideoTranscript,
            onToggle: this.onTranscriptToggle
        });
    }

    /**
     * @method onTranscriptToggle
     * @description Callback function for ToggleDrawer click event
     * @param e {Event} Click event object
     */
    onTranscriptToggle(e) {
        const expanded = e.target.getAttribute(ATTRIBUTES.ARIA_EXPANDED) === 'true';
        this[expanded ? 'transcriptContentElem' : 'transcriptToggleButtonElem'].focus();
    }

    destroy() {
        this.detachEvents();
        if (this.transcriptDrawer) {
            this.transcriptDrawer.destroy();
            this.transcriptDrawer = null;
        }
        if (this.ytPlayer) {
            this.ytPlayer.destroy();
        }
    }
}

export default YouTubeModalContent;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode, tokenReplace } from 'utils';

/**
 * Urgent Recalls template
 */
export default (content, config) => htmlNode`
    <div class="urgent-recalls">
        <div class="recalls-hero">
            <div class="recalls-hero__wrapper wrapper">
                <div class="recalls-hero__content">
                    <div class="recalls-hero__heading ${content.isMerkley ? 'hide' : ''}">
                        ${config.backLink.destination ?
                            `<a href="${config.backLink.destination}" class="recalls-hero__back-link link link_bold_sub" data-analytics-trigger="cta-back-link-recall-landing">${config.backLink.label}</a>` : ''
                            }
                        <h1 class="recalls-hero__title">${content.hero.urgentTitle}</h1>
                        <div class="recalls-hero__body rte">${tokenReplace(content.hero.urgentBody, { landingPage: config.landingPage })}</div>
                    </div>
                    <div class="recalls-hero__search-module ${content.isMerkley ? 'recalls-hero__search-module--full-width' : ''}"></div>
                </div>
            </div>
        </div>
        <div class="urgent-recalls__body">
            <div class="urgent-recalls__body-wrapper wrapper">
                <div class="urgent-recalls__airbag-container"></div>
                <div class="urgent-recalls__parts-availability"></div>
                <div class="urgent-recalls__detailed-info">
                    <div class="urgent-recalls__detailed-info-desc">
                        <h2 class="urgent-recalls__detailed-info-desc-header">${content.detailedInfo.desc.heading}</h2>
                        <div class="urgent-recalls__detailed-info-desc-copy">${content.detailedInfo.desc.copy}</div>
                    </div>
                    <div class="urgent-recalls__detailed-info-additional">
                        <h2 class="urgent-recalls__detailed-info-additional-header">${content.detailedInfo.additional.heading}</h2>
                        <ul>
                            ${content.detailedInfo.additional.links.map((link) => `
                                <li class="media-links__link-element">
                                    <a href="${link.href}" class="link link_plain-link" target="_blank" data-analytics-trigger="cta-additional-resources">${link.label}</a>
                                </li>
                            `).join('')}
                        </ul>
                    </div>
                </div>
                <div class="urgent-recalls__faq">
                    <h2 class="urgent-recalls__faq-header">${content.faq.heading}</h2>
                    <div class="urgent-recalls__faq-accordion"
                        data-accordion
                        data-accordion-name="urgent-recalls-faq">
                        ${
                            content.faq.questionList.map((item) => `
                                <div data-accordion-item class="urgent-recalls-faq-item">
                                    <h3 data-accordion-title class="urgent-recalls-faq-question">
                                        <button data-accordion-button aria-expanded="false">
                                            ${item.question}
                                        </button>
                                    </h3>
                                    <div data-accordion-pane>${item.answer}</div>
                                </div>
                            `).join('')
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

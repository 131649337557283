// Util dependencies
import { htmlNode as html } from 'utils';

/**
 * @function
 * @description Input date field
 * @returns {function} Template function with inpute date
 */
export const inputDate = ({ id, minDate }) => html`
    <input type="date" id="${id}" required class="form__input-field form__input-field--date" min=${minDate}>
`;

/**
 * @function
 * @description Input text, has role="presentation" to prevent screenreaders. Also has disabled and
 * readonly to prevent any user input.
 * @returns {function} Template function with inpute text used for datepicker
 */
export const inputText = ({ id }) => html`
    <input type="text" id="${id}" role="presentation" disabled="disabled" readonly="readonly" class="form__input-field form__input-field--date">
`;

/**
 * @function
 * @description Input Label
 * @returns {function} Template function with label for input field
 */
export const inputLabel = ({ labelCopy, forID }) => html`
    <label class="form__input-label" id="label-${forID}" for="${forID}">${labelCopy}</label>
`;

/**
 * @function
 * @description Container for date picker
 * @returns {function} Template function with DOM elements for date input and trigger CTA
 */
export const template = ({ errorMessage, id }) => html`
    <div class="form__input form__input--date">
        <div data-input-container></div>
        <button type="button" class="form__input-date-trigger" aria-describedby="label-${id} ${id}" data-date-trigger
            data-analytics-trigger="schedule-date">
            <i class="icon-mb icon-calendar" role="presentation"></i>
        </button>
        <span class="form__input-error">${errorMessage}</span>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for an drawer link for authentication large
 */
import { htmlNode } from 'utils';

export default () => htmlNode`
<button class="mbs-auth-nav__cta top-nav__link" data-analytics-trigger="account-open">
    <span class="mbs-auth-nav__name"></span>
</button>
<div class="mbs-auth-nav__dropdown" aria-haspopup="true" aria-expanded="false" data-analytics-container="{blade: 'header-user-subnav'}">
    <ul class="mbs-auth-nav__links-container">

    </ul>
</div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

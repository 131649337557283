// Local dependencies
import videoHubTextTemplate from '../templates/videoHubTextTemplate';

/**
 * @const defaultLocalization
 * @description  localization object for i18n content
 * @type {Object}
 */
const defaultLocalization = {
    videoTranscript: 'Video Transcript'
};

/**
 * @const localization
 * @description constant for the localization content created
 * in src/main/content/jcr_root/apps/mb-vans/pages/other/video-hub/partials/psjs.html
 */
const localization = window.mbVans.ns('pageData', 'localization').videoHub;

/**
 * @class VideoHubText
 * @description View component for displaying the text associated with an Overlay Video Grid video
 */
export default class VideoHubText {
    /**
     * @constructor Create a VideoHubText
     * @param data {Object} Object about the video to be shown
     * @param tag {Object} Object containing information about the class of videos
     */
    constructor(data, tag) {
        this.data = data;
        this.tag = tag;
        this.content = { ...defaultLocalization, ...localization };

        this.render = this.render.bind(this);
    }

    /**
     * @method render
     * @description given a VideoHubText, it returns the associated element with
     * rendered template using properties from data and tag
     * @returns {Element} A rendered element with the template
     */
    render() {
        return videoHubTextTemplate(this.data, this.content, this.tag)({ getNode: true });
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

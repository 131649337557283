// Util dependencies
import {
    customEventDispatcher,
    noop,
} from 'utils';

// Local dependencies
import FORM_CONTROL_EVENTS from './../constants/formControlEvents';

/**
 * @const defaultConfig
 * @description Default configuration options for a form control
 * @type {Object}
 * @type defaultConfig.element {Element} Element that the form should append to
 * @type defaultConfig.prevalidate {Boolean} Indicator for whether to prevalidate the form when an input changes
 * @type defaultConfig.onPrevalidate {Function} Callback method applied on pre-validation
 * @type defaultConfig.onSubmit {Function} Callback method applied when the form successfully submits without errors
 * @type defaultConfig.onInvalid {Function} Callback method applied when the form unsuccessfully submits with errors
 */
const defaultConfig = {
    element: null,
    prevalidate: false,
    onPrevalidate: noop,
    onSubmit: noop,
    onInvalid: noop
};

/**
 * @class FormControl
 * @description View component for displaying a FormControl responsible for
 * managing the state of a form element and a collection of input types.
 */
export default class FormControl {
    /**
     * Create a FormControl
     * @param config {Object} Configuration data
     */
    constructor(config = defaultConfig) {
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.formElement = null; // stores a reference to the form element
        this.fields = []; // stores a collection of registered form fields
        this.valid = true; // indicator for if the form is valid

        // method aliases
        this.onInputChange = this.onInputChange.bind(this);
        this.onRegister = this.onRegister.bind(this);
        this.onUnregister = this.onUnregister.bind(this);
        this.submit = this.submit.bind(this);

        // initialize
        this.createForm();
        this.attachEvents();
    }

    /**
     * @method createForm
     * Creates a form element and appends the config.element to it
     */
    createForm() {
        this.formElement = document.createElement('form');
        this.formElement.setAttribute('novalidate', 'true');
        if (this.config.element) {
            this.formElement.appendChild(this.config.element);
        }
    }

    /**
     * @method destroy
     * @description Destroys the form control and detaches its events
     */
    destroy() {
        this.detachEvents();
        this.formElement = null;
    }

    /**
     * @method attachEvents
     * @description Adds an event listener for `submit`
     * and applies a callback to `this.submit`
     */
    attachEvents() {
        this.formElement.addEventListener('submit', this.submit);
        customEventDispatcher.addEventListener(FORM_CONTROL_EVENTS.INPUT_CHANGE, this.onInputChange);
        customEventDispatcher.addEventListener(FORM_CONTROL_EVENTS.REGISTER, this.onRegister);
        customEventDispatcher.addEventListener(FORM_CONTROL_EVENTS.UNREGISTER, this.onUnregister);
    }

    /**
     * @method detachEvents
     * @description Removes the `submit` event and callback from the form
     */
    detachEvents() {
        if (this.formElement) {
            this.formElement.removeEventListener('submit', this.submit);
        }
        customEventDispatcher.removeEventListener(FORM_CONTROL_EVENTS.INPUT_CHANGE, this.onInputChange);
        customEventDispatcher.removeEventListener(FORM_CONTROL_EVENTS.REGISTER, this.onRegister);
        customEventDispatcher.removeEventListener(FORM_CONTROL_EVENTS.UNREGISTER, this.onUnregister);
    }

    /**
     * @method register
     * @description Registers a field to the form.
     * @param field {Object} form field
     */
    register(field) {
        const hasField = this.fields.find((f) =>
            field && field.id === f?.id
        );

        if (!hasField) {
            this.fields.push(field);
        }

        if (this.config.prevalidate) {
            this.prevalidate();
        }
    }

    /**
     * @method onInputChange
     * @description Callback handler for when a registered input's value changes
     * that applies prevalidation when the `config.prevalidate` is truthy
     * @param event {Event} Event object with a `detail` object containing a `formId` and input
     */
    onInputChange(event) {
        const eventDetail = event && event.detail;

        if (eventDetail.formId === this.config.id && eventDetail.input && this.config.prevalidate) {
            this.prevalidate();
        }
    }

    /**
     * @method onRegister
     * @description Callback handler for when an input is added and dispatches an event to register itself to a form
     * that applies registering an input when the `formId` matches the `config.id`
     * @param event {Event} Event object with a `detail` object containing a `formId` and input
     */
    onRegister(event) {
        const eventDetail = event && event.detail;

        if (eventDetail.formId === this.config.id && eventDetail.input) {
            this.register(eventDetail.input);
        }
    }

    /**
     * @method onRegister
     * @description Callback handler for when an input is destroyed and dispatches an event to unregister itself from
     * a form that applies unregistering an input when the `formId` matches the `config.id`
     * @param event {Event} Event object with a `detail` object containing a `formId` and input
     */
    onUnregister(event) {
        const eventDetail = event && event.detail;

        if (eventDetail.formId === this.config.id && eventDetail.input) {
            this.unregister(eventDetail.input);
        }
    }

    /**
     * @method unregister
     * @description Unregisters a field from the form by checking id
     * @param field {Object} form field
     */
    unregister(field) {
        this.fields = this.fields.filter((f) =>
            field.id !== f.id
        );
    }

    /**
     * @method submit
     * @description Prevents default form submit event, validates form fields
     * and if all fields are valid calls the 'onSubmit' callback method,
     * otherwise the 'onInvalid' one
     */
    submit(e) {
        if (e) {
            e.preventDefault();
        }

        this.validate();

        if (this.valid) {
            this.config.onSubmit();
        } else {
            this.config.onInvalid();
        }
    }

    /**
     * @method prevalidate
     * @description Validates the form inputs without submitting the form and
     * applies the `config.onPrevalidate` if defined
     */
    prevalidate() {
        this.validate(false);

        if (this.config.onPrevalidate) {
            this.config.onPrevalidate(this.valid);
        }
    }

    /**
     * @method validate
     * @description Validates form fields. Call validate method for each field to set the
     * appropriate error status for each one, then checks if any field is invalid,
     * to set the form as invalid
     * @param displayError {Boolean} Flag to show/hide the validation error messages
     */
    validate(displayError = true) {
        this.fields.forEach((field) => {
            if (field && field.validate instanceof Function) {
                field.validate(displayError);
            }
        });

        this.valid = this.fields.every((field) =>
            field.isValid()
        );
    }

    /**
     * @method render
     * @description Returns the formElement element
     * @return {Element}
     */
    render() {
        return this.formElement;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// import partials
import { Modal } from 'partials/modal';

/**
 * @constant CLASSES
 * @type {Object}
 */
const CLASSES = {
    HIDE: 'hide',
    LAST: 'featured-offer--last'
};

/**
 * @constant DATA
 * @type {Object}
 */
const DATA = {
    CTA: 'modal-button'
};

/**
 * @class FeaturedOffer
 * @description  class that works a view for help all the featured offer
 * to show the modal when click open details
 * @param {Object} element
 * @param {String} modalContent
 */
export default class FeaturedOffer {
    /**
     * @method constructor
     * @description  manage the instantiation of the FeaturedOffer view
     * and setup the default properties
     * @param  {Object} element
     * @param  {String} modalContent
     * @param  {Object} markets
     */
    constructor(element, modalContent, markets) {
        this.element = element;
        this.modalContentOfferDetails = modalContent;
        this.markets = markets;
        this.init();
    }

    /**
     * @method  init
     * @description initiate all the functions after the
     * setup all the default properties.
     */
    init() {
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description this function cache all the elements from the dom
     * in the FeaturedOffer properties.
     */
    cacheDOM() {
        this.offerDetailsCTA = this.element.querySelector(`[data-${DATA.CTA}]`);
    }

    /**
     * @method attachEvents
     * @description  attach all events for FeaturedOffer view
     */
    attachEvents() {
        if (this.offerDetailsCTA) {
            this.offerDetailsCTA.addEventListener('click', this.clickHandlerOfferDetailsCTA.bind(this));
        }
    }

    /**
     * @method clickHandlerOfferDetailsCTA
     * @description handler in charge of open modal based on the modelContent
     */
    clickHandlerOfferDetailsCTA() {
        let modal;

        modal = new Modal(undefined, {
            modalContent: this.modalContentOfferDetails,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            callbacks: {
                beforeClose: () => {
                    // clean up and remove the modal on close
                    modal.destroy();
                    modal = null;
                }
            }
        });

        modal.open({ callingContainer: this.offerDetailsCTA });
    }

    testMarket(market, isLast, forceHide) {
        this.element.classList[(this.markets[market] && !forceHide) ? 'remove' : 'add'](CLASSES.HIDE);
        this.element.classList[isLast ? 'add' : 'remove'](CLASSES.LAST);
        return this.markets[market];
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { CUSTOM_EVENTS } from 'Constants';
import { customEventDispatcher, noop } from 'utils';
import YoutubeVideoAPI from '../api/YouTubeApi';
import YouTubeAnalyticsApi from '../api/YouTubeAnalyticsApi';
// import config from './../config';

const defaultPlayerVars = {
    autoplay: 1,
    fs: 1,
    iv_load_policy: 3,
    modestbranding: 1,
    origin: `//${window.document.domain}`,
    playsinline: 1,
    rel: 0,
    wmode: 'transparent'
};

class YouTube {
    constructor(videoId, placeholder, callback, playerVars = {}) {
        this.videoId = videoId;
        this.placeholder = placeholder;
        this.callback = callback;
        this.API = null;
        this.analyticsAPI = null;
        this.player = null;

        this.playerVars = {
            ...defaultPlayerVars,
            ...playerVars
        };

        this.onReady = noop;
        if (this.playerVars.events && this.playerVars.events.onReady) {
            this.onReady = this.playerVars.events.onReady;
        }

        this.promise = new YoutubeVideoAPI()
            .then((API) => {
                this.API = API;
            })
            // .then(this.getVideoData.bind(this))
            .then(this.initializeVideoPlayer.bind(this));
        this.attachEvents();
    }

    /**
     * attachEvents()
     * handles event listeners
     */
    attachEvents() {
        window.addEventListener(CUSTOM_EVENTS.PAUSE_YOUTUBE_VIDEO, (event) => {
            this.pauseYoutubeVideo(event);
        });
    }

    /**
     * @method getVideoData
     * @description  request the videoData using the YouTube Data Api v3
     * https://developers.google.com/youtube/v3/ and also assign the json
     * with data to this.videoData from this object we can get the video
     * title and the video id.
    getVideoData() {
        // const apiUrl = `${config.YOUTUBE_API_URL}?id=${this.videoId}&key=${config.YOUTUBE_API_KEY}&part=snippet`;

        fetch(apiUrl)
            .then(response => response.json())
            .then((data) => {
                this.videoData = data.items[0] || {};
            });
    }
     */

    pauseYoutubeVideo(event) {
        if (this.isPlayerReady()) {
            // check if this player is the one for the video id passed by the event
            if (event.detail && event.detail.videoId === this.videoId) {
                this.player.pauseVideo();
            }
        }
    }

    dispatchStateEvent(event) {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.YOUTUBE_VIDEO_STATE_CHANGE,
                {
                    detail: {
                        videoId: this.videoId,
                        player: event.target.a,
                        currentState: event.data,
                        states: window.YT.PlayerState
                    }
                }
            )
        );
    }

    isPlayerReady() {
        return this.player && typeof this.player.playVideo === 'function';
    }

    initializeVideoPlayer() {
        if (this.placeholder === null) {
            this.player = null;
        } else {
            this.player = new this.API.Player(this.placeholder, {
                width: 640,
                height: 320,
                videoId: this.videoId,
                events: {
                    onReady: (event) => {
                        this.analyticsAPI = new YouTubeAnalyticsApi(this.videoId);
                        this.onReady(event);
                    },
                    onStateChange: (event) => {
                        if (this.analyticsAPI) {
                            this.analyticsAPI.handleStateChange(event);
                        }
                        const isEnded = event.data === this.API.PlayerState.ENDED;
                        this.dispatchStateEvent(event);
                        this.callback(isEnded);
                    }
                },
                playerVars: this.playerVars
            });
        }
        return this.player;
    }

    /**
     * When the returned player instance is not required, use this static method to
     * create a new instance.
     *
     * @param {string} videoId - Youtube identifier for the video
     * @param {Element} placeholder - Element into which the iframe is embedded
     * @param {function} [callback] - Callback function
     */
    static createInstance(videoId, placeholder, callback = noop, playerVars = {}) {
        return new this(videoId, placeholder, callback, playerVars);
    }
}

export default YouTube;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

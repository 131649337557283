// Utils dependencies
import {
    cookie as cookieUtils,
    storage
} from 'utils';

// Constants
import {
    COOKIES,
    STORAGE_KEYS
} from 'Constants';

// Local dependencies
import { authenticationFetch } from './authenticationFetchApi';

const AUTH_STATE = {
    AUTH_UNKNOWN: 'UNKNOWN',
    AUTHENTICATED: true,
    UNAUTHENTICATED: false
};


/**
 * @properties
 * @description Profile endpoint
 */
const {
    profileService,
    profileServiceLocale
} = window.mbVans.applicationProperties;

// default state of the object to be return
const accountCookieData = {
    loggedState: AUTH_STATE.AUTH_UNKNOWN,
    partialLoggedState: AUTH_STATE.AUTH_UNKNOWN,
    accountData: {}
};

// object to manage the singleton instance
let instanceAuth = null;

/**
 * @method AuthenticationApi
 * @description singleton class in charge of load the cookie
 * for the user profile
 * @return {null | AuthenticationApi class}
 */
class AuthenticationApi {
    /**
     * @static AUTH_PAGES
     * @description ciam page types
     */
    static AUTH_PAGES = {
        REGISTRATION: 'registration',
        LOGIN: 'login',
        LOGOUT: 'logout'
    };

    /**
     * @method constructor
     * @description singleton constructor retrieve the instance if exists,
     * if not create a new one
     */
    constructor() {
        if (instanceAuth) {
            return instanceAuth;
        }

        instanceAuth = this;
    }

    /**
     * @method getAuthState
     * @description  create the data for the userProfile from the cookie
     */
    getAuthState() {
        const hasLoggedInCookie = this.isCookie(COOKIES.PROFILE_INFO);
        const hasQuickRegisteredCookie = this.isCookie(COOKIES.QUICK_REGISTER_ID) &&
            this.isCookie(COOKIES.QUICK_REGISTER_EMAIL);
        const authData = hasLoggedInCookie ? this.parseProfileInfoCookie() : {};
        const unAuthData = hasQuickRegisteredCookie ? this.parseQuickRegisterCookies() : {};
        return {
            ...accountCookieData,
            loggedState: hasLoggedInCookie ? AUTH_STATE.AUTHENTICATED :
                AUTH_STATE.UNAUTHENTICATED,
            partialLoggedState: hasQuickRegisteredCookie ? AUTH_STATE.AUTHENTICATED :
                AUTH_STATE.UNAUTHENTICATED,
            accountData: hasLoggedInCookie ? authData : unAuthData
        };
    }

    /**
     * @method createAuthRedirectCookie
     * @description creates MBUSA_AUTH_REDIRECT cookie with current page url as value
     * @param overrideRedirectUrl {String} page url that overrides default redirection
     */
    createAuthRedirectCookie(overrideRedirectUrl = null) {
        const redirectURI = overrideRedirectUrl || window.location.pathname + window.location.search +
            window.location.hash;

        if (redirectURI.indexOf(window.location.pathname) !== -1 || overrideRedirectUrl) {
            cookieUtils.setCookie({
                name: COOKIES.AUTH_REDIRECT,
                value: redirectURI
            });
        }
    }

    /**
     * @method authRedirect
     * @description calls createAuthRedirectCookie, then calls goToAuthPage to redirect to page type uri
     * (registration, login or logout)
     * @param pageType {String} page type to redirect to: 'registration', 'login' or 'logout'
     * @param redirectUrl {String} page url to redirect after login flow
     */
    authRedirect(pageType, redirectUrl = null) {
        this.createAuthRedirectCookie(redirectUrl);
        this.goToAuthPage(pageType);
    }

    /**
     @method isCookie
     @description determines whether the cookie exists or not
     @param {String} name
     @returns {Bool}
     */
    isCookie(name) {
        const cookie = document.cookie;
        return (cookie.indexOf(`${name}=`) !== -1);
    }

    /**
     @method getCookieValue
     @description retrieves value of cookie passed in
     @param {String} cookieName - name of cookie to parse
     @returns {String} value of cookie
     */
    getCookieValue(cookieName) {
        const cookieSubstring = document.cookie.substring(document.cookie.indexOf(`${cookieName}=`)).split(';')[0];
        const cookieSubstringValue = cookieSubstring.split(`${cookieName}=`)[1].replace(/['"]+/g, '');
        return cookieSubstringValue;
    }

    /**
     @method parseProfileInfoCookie
     @description returns object based on value of profile cookie
     @returns {Object} {lastName:String, prefix:String}
     */
    parseProfileInfoCookie() {
        const profileInfoCookieValue = this.getCookieValue(COOKIES.PROFILE_INFO);
        const profileInfoParsed = profileInfoCookieValue.split(',');
        const profileInfo = new Map();
        profileInfoParsed.forEach((valueElement) => {
            const vals = valueElement.split('|');
            profileInfo.set(vals[0], decodeURIComponent(vals[1]));
        });
        return Object.fromEntries(profileInfo);
    }

    /**
     @method parseQuickRegisterCookies
     @description returns object based on values of the quick registration cookies
     @returns {Object} {id:String, email:String}
     */
    parseQuickRegisterCookies() {
        const quickRegisterIdCookieValue = this.getCookieValue(COOKIES.QUICK_REGISTER_ID);
        const quickRegisterEmailCookieValue = this.getCookieValue(COOKIES.QUICK_REGISTER_EMAIL);
        const quickRegisterInfo = {
            id: quickRegisterIdCookieValue,
            email: quickRegisterEmailCookieValue
        };
        return quickRegisterInfo;
    }

    /**
     * @method getProfile
     * @return {Object}
     */
    getProfile() {
        return this.getAuthState();
    }

    /**
     * @method parseAuthProfile
     * @description Parses the response data from fetching a user profile
     * and returns the `profile` object
     * @param response {Object} Response object from getAuthProfile
     * @return {Object} USer profile object
     */
    parseAuthProfile(response) {
        const {
            result: {
                response: {
                    result: {
                        profile
                    }
                },
                changeEmailUrl,
                changePasswordUrl
            }
        } = response;

        if (!profile) {
            throw new Error('Error parsing AuthProfile');
        }

        profile.urls = { changeEmailUrl, changePasswordUrl };

        return profile;
    }

    /**
     * @method getAuthProfile
     * @method Fetches an authenticated user's profile
     * @return {Promise} A Promise that resolves with an authenticated user profile
     */
    getAuthProfile() {
        return authenticationFetch(`${profileServiceLocale}/secure/profile`)
            .then(this.parseAuthProfile.bind(this));
    }

    /**
     * @method  isLoggedIn
     * @description  verify if the logged state is true
     * @return {Boolean}
     */
    isLoggedIn() {
        const { loggedState } = this.getAuthState();
        return loggedState === AUTH_STATE.AUTHENTICATED;
    }

    /**
     * @method isPartiallyLoggedIn
     * @description Verify if the user is half registered / partially logged in
     * (registered but not logged in)
     * @return {Boolean}
     */
    isPartiallyLoggedIn() {
        const { partialLoggedState } = this.getAuthState();
        return partialLoggedState === AUTH_STATE.AUTHENTICATED;
    }

    /**
     * @method goToAuthPage
     * @description For register, login and logout ctas, call corresponding ciam service and redirect to result url
     */
    goToAuthPage(pageType) {
        const endpoint = `${profileService}/ciam/authenticate/web?type=${pageType}`;
        fetch(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'origin'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((response) => window.location.replace(response.result))
            .catch((err) => {
                console.warn(err);
                throw err;
            });
    }

    /**
     * @method getLoginToken
     * @description Reads login token from local storage
     */
    getLoginToken() {
        return storage.localStorage.read(
            STORAGE_KEYS.LOGIN_TOKEN
        );
    }
}

// export the component
export default AuthenticationApi;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { MultiSlideCarousel } from 'partials/multi-slide-carousel';

/**
 * @class MultiSlideCarousel
 * @description Creates a carousel in a set of elements on the Style Guide page
 * @param {Node} element
 */
export default class MultiSlideCarouselModule {
    /**
     * @method constructor
     * @description Instantiates the MultiSlideCarousel
     * @param {Object} element
     */
    constructor(element) {
        this.element = element;
        this.carousel = {};
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.carousel = new MultiSlideCarousel(this.element, { minItems: 3, activeLarge: true });
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Api responsible for providing common utility methods for inventory search
 */

// Import utils
import {
    formatNumber
} from 'utils';

// import partial dependencies
import { dealerLocatorApi } from 'partials/dealer-locator-api';

/**
 * @method createMediaObject
 * @description Converts service image object to format needed for media object
 * @param {Object} baseImage - object containing desktop, mobile and alt text info
 * @return {Object} - An object that has formatted media info
 */
export function createMediaObject(baseImage) {
    if (baseImage.error) {
        return null;
    }

    return {
        imgL: baseImage.desktop && baseImage.desktop.url,
        imgS: baseImage.mobile && baseImage.mobile.url,
        alt: baseImage.altText || ''
    };
}

/**
 * @method createMediaObjectFromPath
 * @description Create media object from the image path
 * @param {String} imagePath - Path to image
 */
export function createMediaObjectFromPath(imagePath) {
    return {
        imgL: imagePath || null,
        imgS: imagePath || null,
        alt: ''
    };
}

/**
 * @method createImagesArray
 * @description creates an array of image media objects from an images array of image paths
 * @param images {Array} Collection of image paths
 * @param fallbackImage {String} Image path to create image object for if there are no images
 * @return {Array}
 */
export function createImagesArray(images, fallbackImage) {
    if (
        !images ||
        images.length === 0 ||
        (images.length === 1 && !images[0])
    ) {
        return [createMediaObjectFromPath(fallbackImage)];
    }

    return images.map(createMediaObjectFromPath);
}

/**
 * @method createPricingObject
 * @description Converts a msrp number into an object that has currency formatted value
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {Number} msrp - MSRP number value
 * @return {Object} - An object that has both the MSRP and the currency formatted value
 */
export function createPricingObject({ country, language, currency }, msrp) {
    return {
        price: msrp,
        priceCurrency: formatNumber.toCurrency(msrp, country, language, currency)
    };
}

/**
 * @method createMileageObject
 * @description Converts a distance to an object with a raw and formatted value
 * @param {String} country - Country code
 * @param {String} language - Language code
 * @param {String|Number} mileage - mileage to format
 * @param {String} mileageUnit - Mileage Unit
 * @return {{value: *, formattedValue: (String|*)}}
 */
export function createMileageObject({ country, language }, mileage, mileageUnit) {
    return {
        value: mileage,
        formattedValue: formatNumber.toStringNumber(
            mileage,
            country,
            language
        ),
        unit: mileageUnit
    };
}

/**
 * @method createDealerObject
 * @description Constructs and normalizes a dealer object
 * @param {String} country - Country code
 * @param {String} language - Language code
 * @param {Object} dealer - Dealer to normalize
 * @param {String} notes - Dealer notes
 * @return {{address, phoneFormatted: (String|*)}}
 */
export function createDealerObject({ country, language }, dealer, notes = '') {
    const dealerAddress = dealer.address[0];
    const formattedDealer = dealerLocatorApi.formatDealer(dealer, language);

    return {
        ...formattedDealer,
        distance: formatNumber.toFormattedStringNumber(
            dealerAddress.location.dist,
            country,
            language
        ),
        notes
    };
}

export default {
    createDealerObject,
    createMediaObject,
    createMediaObjectFromPath,
    createMileageObject,
    createPricingObject
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Import Utils
import {
    formatString,
    tokenReplace
} from 'utils';

/**
 * @method calculateDuration
 * @description Calculate duration in months
 * @param {String} years
 * @param {String} months
 * @returns { duration }
 */
export function calculateDuration(years, months) {
    const duration = ((parseInt(years, 10) || 0) * 12) + (parseInt(months, 10) || 0);

    return duration;
}

/**
 * @method constructAddress
 * @description Parses address object for the request
 * @param {Object} data Address data
 * @param {String} data.addressType The type of address (CurrentAddress | PreviousAddress)
 * @param {String} data.address The address info
 * @param {String} data.city The address city
 * @param {String} data.state The address state
 * @param {String} data.zipCode The address state
 * @param {String} data.months The number of months of residence
 * @param {String} data.years The number of years of residence
 * @param {Boolean} setEmptyFlag Indicator to have an isEmpty flag set on the object
 * @return {{
 *     addressType: String,
 *     addressLine1: String,
 *     addressLine2: String,
 *     isEmpty: Boolean,
 *     city: String,
 *     state: String,
 *     tenure: Number,
 *     zipCode: String
 * }}
 */
export function constructAddress(data, setEmptyFlag = false) {
    const {
        addressType,
        address,
        city,
        state,
        zipCode,
        years,
        months
    } = data;
    const tenureLength = calculateDuration(years, months);
    const addr = {
        addressType,
        addressLine1: address ? formatString.normalizeAccentedString(address) : null,
        addressLine2: '',
        city: city ? formatString.normalizeAccentedString(city) : null,
        state: state ? formatString.normalizeAccentedString(state) : null,
        tenure: tenureLength > 0 ? tenureLength : undefined,
        zipCode
    };

    if (setEmptyFlag) {
        // set empty flag if all parameters except addressType are empty
        addr.isEmpty = !Object.keys(addr).some((key) => !/addressType/.test(key) && addr[key]);
    }

    return addr;
}

/**
 * @method constructResidence
 * @description Parses address object for the request
 * @param {Object} residenceInfo Address data
 * @return {Array} Array of address objects
 */
export function constructResidence(residenceInfo) {
    return {
        address: [
            constructAddress({
                addressType: 'CurrentAddress',
                address: residenceInfo.currentAddress,
                city: residenceInfo.currentCity,
                state: residenceInfo.currentState,
                zipCode: residenceInfo.currentZipCode,
                months: residenceInfo.currentMonths,
                years: residenceInfo.currentYears
            }, true),
            constructAddress({
                addressType: 'PreviousAddress',
                address: residenceInfo.previousAddress,
                city: residenceInfo.previousCity,
                state: residenceInfo.previousState,
                zipCode: residenceInfo.previousZipCode,
                months: residenceInfo.previousMonths,
                years: residenceInfo.previousYears
            }, true),
            constructAddress({
                addressType: 'SpouseAddress',
                address: residenceInfo.spouseAddress,
                city: residenceInfo.spouseCity,
                state: residenceInfo.spouseState,
                zipCode: residenceInfo.spouseZipCode
            }, true)
        ].reduce((addrArr, addr) => {
            // remove any empty addresses from the array
            if (!addr.isEmpty) {
                delete addr.isEmpty;
                addrArr.push(addr);
            }
            return addrArr;
        }, [])
    };
}

/**
 * @method parseErrorList
 * @description Parses the error messages into a human readable list based on the credit app content
 * @param errors {Object} List of server errors after submitting
 * @param content {Object} Credit App Content object
 * @param inPageError {boolean} Indicator if error should include verbiage about returning to step vs
 * in-page section message
 * @return {*}
 */
export function parseErrorList(errors, content, inPageError = false) {
    let errorList = null;

    if (errors) {
        const {
            reviewApplication: {
                headings
            },
            submissionErrors: {
                submitErrorTitles,
                submitErrorMessages,
                submitAddressVerificationErrors,
                submitAddressVerificationSection
            }
        } = content;

        // iterate through the submitErrorMessages and map a title and message for
        // each error based on the errorType and section it applies to
        if (Array.isArray(errors)) {
            errorList = errors.map((error) => {
                const sectionName = error.section ? headings[error.section] : null;

                const addressDescription = inPageError ?
                    tokenReplace(submitAddressVerificationErrors[error.labelId],
                        {
                            addressErrorSection: submitAddressVerificationSection[error.section]
                        })
                    :
                    tokenReplace(
                        submitErrorMessages[error.messageId],
                        { sectionName }
                    );

                return {
                    title: inPageError ? '' : submitErrorTitles[error.labelId],
                    description: submitErrorMessages[error.messageId] ? addressDescription : null
                };
            });
        }
    }

    return errorList;
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import Dealer from './views/Dealer';
import CollisionCenter from './views/CollisionCenter';

export default class RetailerFactory {
    static createInstance(retailType, info, content, index, model) {
        if (retailType === 'dealer') {
            return new Dealer(info, content, index, model);
        }

        if (retailType === 'collisionCenter') {
            return new CollisionCenter(info, content, index, model);
        }

        return null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

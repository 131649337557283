/**
 * Default configuration of a GoogleMap
 */

const country = window.mbVans.ns('pageData').country || 'us';
const lang = window.mbVans.ns('pageData').language || 'en';

const countryCenters = {
    us: { // Center of the USA, by default (Buffalo, KS)
        lat: 37.774929,
        lng: -95.712891
    },
    ca: {
        lat: 60.015785,
        lng: -101.990666
    },
    mx: {
        lat: 23.6345,
        lng: -102.5528
    }
};

export default {
    center: countryCenters[country],
    zoom: 4,
    panControl: false,
    streetViewControl: true,
    scaleControl: false,
    mapTypeControl: false,
    zoomControl: true,
    zoomControlOptions: {
        style: 1
    },
    trafficLayerControl: true,
    language: lang,
    enableHoverInteractions: true,
    enabledLarge: true,
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

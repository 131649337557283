// Util dependencies
import { tokenReplace } from 'utils';

/**
 * @method getDealerCodeMBFS
 * @description call credit dealer info API
 * @param {String} url - Service endpoint
 * @param {String} dealerId - dealer ID
 * @return {Promise}
 */
function getDealerCodeMBFS(url, dealerId, country) {
    const endpoint = tokenReplace(url, { dealerId, country });
    return fetch(endpoint, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
    .then((response) => response.json())
    .then((response) => response.result.dealer.mbfsDealerCode)
    .catch((error) => {
        console.warn('getDealerCodeMBFS error', error);
        return '';
    });
}

/**
 * @method isDealerValidMBFS
 * @description Validate dealer is allowed in Credit Applications.
 * @param {String} endpoint - dealerInfo service endpoint
 * @param {String} dealerId - dealer ID
 * @param {String} country - country code
 * @return {Promise}
 */
export function isDealerValidMBFS(endpoint, dealerId, country) {
    return getDealerCodeMBFS(endpoint, dealerId, country)
        .then((dealerCodeMBFS) => !!dealerCodeMBFS);
}

export default {
    isDealerValidMBFS
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

export const form = (content, vin) => htmlNode`
    <div class="recalls-stay-informed">
        <div class="recalls-stay-informed__container" data-analytics-container="{'blade': 'stay-informed-form'}">
            <header class="recalls-stay-informed__header">
                <h2 class="recalls-info__title">${content.heading.title}</h2>
                <p class="recalls-info__heading-copy">${content.heading.copy}</p>
                <strong class="recalls-info__subhead">${content.heading.vin}: ${vin}</strong>
            </header>
            <div class="recalls-stay-informed__form-container">
                <div class="recalls-stay-informed__form">
                    <div class="recalls-stay-informed__fields-container">
                    </div>
                    <div class="recalls-stay-informed__cta-container">
                        <button class="button button_primary button--wide" type="submit" data-analytics-container="cta-recall-stay-informed-form-submit">${content.submit.label}</button>
                    </div>
                    <div class="recalls-stay-informed__error-container">
                    </div>
                </div>
            </div>
        </div>
    </div>
`;

export const success = (content) => htmlNode`
    <div class="recalls-stay-informed__success">
        <header class="recalls-stay-informed__header">
            <div class="recalls-stay-informed__success-icon"></div>
            <h2 class="recalls-info__title">${content.heading.title}</h2>
            <p class="recalls-info__heading-copy">${content.heading.copy}</p>
            <address class="recalls-stay-informed__contact-address">
                ${content.address.line1} <br />
                ${content.address.line2} <br />
                ${content.address.line3} <br />
                ${content.address.line4} <br />
            </address>
            <a class="link link_plain-link" href="tel:${content.phone}">${content.phone}</a>
        </header>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for an drawer link for authentication small
 */
import { htmlNode } from 'utils';

export default (link, isSmall, target) => htmlNode`
    <li class="mbs-auth-nav ${isSmall ? 'mbs-auth-nav--small-link top-nav__item' : 'mbs-auth-nav--large-link'}">
        <a href="${link.linkDest}" class="${isSmall ? 'top-nav__link' : 'link link_in-text'}  " target="${target}" rel="noopener" data-analytics-trigger="cta">${link.linkText}</a>
    </li>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

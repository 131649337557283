/**
 * Map Module
 * Has methods related to composition of the map module
 */

import { screen, customEventDispatcher, generateUniqueID as id } from 'utils';
import { CUSTOM_EVENTS } from 'Constants';
import { GoogleMap } from 'partials/google-maps';

const CLASSES = {
    MAP_WRAPPER: 'map-module__map'
};

const ICONS = {
    dealers: '/etc/designs/mb-vans/images/icon-mb-dealer-location_black.png'
};

const ATTRIBUTES = {
    NONE: 'none'
};

const INFO_WINDOW_MAX_WIDTHS = {
    maxWidthSmall: 180,
    maxWidthLarge: 230
};

export default class MapModule {
    constructor(element) {
        this.element = element;
        this.map = null;
        this.options = window.mbVans.ns('pageData').variantInfo;
        this.isHidden = false;
        this.shouldBeResetForDisplay = false;
        this.containerDisplayStateChangedBound = this.onContainerDisplayStateChanged.bind(this);
        this.init();
    }

    /**
     * init()
     * @description calls initial functions
     */
    init() {
        this.cacheDOM();
        this.isHidden = this.getHiddenState();
        if (!this.isHidden) {
            this.createMap();
        } else {
            this.shouldBeResetForDisplay = true;
        }
        this.attachEvents();
    }

    cacheDOM() {
        this.mapWrapper = this.element.querySelector(`.${CLASSES.MAP_WRAPPER}`);
        this.mapDataId = this.mapWrapper.getAttribute('data-map-source');
    }

    attachEvents() {
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.CONTAINER_DISPLAY_STATE_CHANGED,
            this.containerDisplayStateChangedBound
        );
    }

    /**
     * @method createMap()
     * @description Sets the maps container and instantiates
     * a Google Map object to render the map element
     */
    createMap() {
        const opts = {
            maxWidth: (screen.getState().small) ?
            INFO_WINDOW_MAX_WIDTHS.maxWidthSmall :
            INFO_WINDOW_MAX_WIDTHS.maxWidthLarge
        };
        this.map = new GoogleMap({
            element: this.mapWrapper,
            variantOptions: this.options,
            infoWindowOptions: opts,
            onMapCreatedCallback: this.addDataToMap.bind(this)
        });
    }

    /**
     * @method addDataToMap()
     * @description Parses the data and calls
     * map.addMarker to render the data
     */
    addDataToMap() {
        const jsonData = JSON.parse(unescape(window.mbVans.moduleData.mapModule[this.mapDataId]));

        if (jsonData && jsonData.length) {
            [].slice.call(jsonData).forEach((mapData) => {
                mapData.id = id();
                mapData.icon = ICONS.dealers;
                mapData.location = {
                    lat: parseFloat(mapData.latitude),
                    lng: parseFloat(mapData.longitude)
                };
            });
        }

        this.map.addMarkers(jsonData, false);
    }

    /**
     * @method destroyMap()
     * @description kills this.map
     */
    destroyMap() {
        this.map.destroyMap();
        this.map = null;
    }

    /**
     * @method resetMap()
     * @description resets this.map
     */
    resetMap() {
        this.createMap();
    }

    /**
     * @method forceMapResize()
     * @description calls GoogleMaps.triggerResize()
     */
    forceMapResize() {
        this.map.triggerResize();
    }

    /**
     * @method getHiddenState()
     * @description Returns current hidden state
     */
    getHiddenState() {
        let el = this.element;

        // return true if element has an ancestor that has display:none
        while (el.parentNode && el.parentNode !== document.querySelector('html')) {
            el = el.parentNode;
            if (window.getComputedStyle(el).display === ATTRIBUTES.NONE) {
                return true;
            }
        }
        return false;
    }

    /**
     * @method onContainerDisplayStateChanged()
     * @description Handles map displayed state; resets map
     */
    onContainerDisplayStateChanged() {
        const willbeHidden = this.getHiddenState();
        if (!willbeHidden && this.isHidden !== willbeHidden) {
            if (this.shouldBeResetForDisplay) {
                this.createMap();
                this.shouldBeResetForDisplay = false;
            } else {
                this.forceMapResize();
            }
        }
        this.isHidden = willbeHidden;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

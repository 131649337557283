// Local dependencies
import reCaptchaV3BrandingTemplate from '../templates/reCaptchaV3BrandingTemplate';

/**
 * @property branding
 * @description Recaptcha V3 applicationProperties branding info
 * @type {string}
 * @memberof ReCaptchaV3Branding
 */
const {
    applicationProperties: {
        recaptchaV3: {
            branding = {}
        } = {}
    } = {}
} = window.mbVans || {};

/**
 * @description Default content object that maps to the applicationProperties or fallback text
 * @memberof ReCaptchaV3Branding
 */
const defaultContent = {
    note: branding.note || 'This site is protected by reCAPTCHA and the Google {privacyPolicy} and {termsOfUse} apply.',
    privacyPolicy: branding.privacyPolicy || 'Privacy Policy',
    privacyPolicyUrl: branding.privacyPolicyUrl || 'https://policies.google.com/privacy',
    termsOfService: branding.termsOfService || 'Terms of Service',
    termsOfServiceUrl: branding.termsOfServiceUrl || 'https://policies.google.com/terms'
};

/**
 * @class ReCaptchaV3Branding
 * @description A view for displaying the Recaptcha branding copy with policy and terms of use links
 */
export default class ReCaptchaV3Branding {
    /**
     * @constructor
     * @param {Object} content Object that maps to the defaultContent object structure
     */
    constructor(content = {}) {
        this.element = null;
        this.content = {
            ...defaultContent,
            ...content
        };

        this.createView();
    }

    /**
     * @method createView
     * @description Create view from the template
     */
    createView() {
        this.element = reCaptchaV3BrandingTemplate(this.content)({ getNode: true });
    }

    /**
     * @method render
     * @description Return the element containing the reCAPTCHA
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

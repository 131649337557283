// Util dependencies
import { htmlNode } from 'utils';
import menuSelectorButtonTemplate from './menuSelectorButtonTemplate';
import menuSelectorRadioTemplate from './menuSelectorRadioTemplate';

export const getClassNames = (theme, classSuffix) => `${theme ?
    `menu-selector${classSuffix} menu-selector${classSuffix}--${theme}` : `
    menu-selector${classSuffix}`}`;
/**
 * @description Template for menu selector
 * Uses ES6 template literal to composite the markup for a carousel gallery
 */
export default ({
    items = [],
    legend = '',
    a11yTip = '',
    useRadioButtons = false,
    defaultSelection,
    group,
    theme,
    loadModule,
 }) => htmlNode`
    <div class="${getClassNames(theme, '')}">
        <button class="${getClassNames(theme, '__selected-item')}" aria-controls="menu-selector-list-${group}">${items[defaultSelection].label}</button>
        ${!useRadioButtons ?
            menuSelectorButtonTemplate({
                items,
                buttonClasses: getClassNames(theme, '__item-link'),
                defaultSelection,
                group,
                theme,
                loadModule
            }) :
            menuSelectorRadioTemplate({
                items,
                defaultSelection,
                group,
                inputClasses: getClassNames(theme, '__item-radio'),
                labelClasses: getClassNames(theme, '__item-label'),
                legend,
                a11yTip,
                theme
            })}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

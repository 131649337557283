import { STORAGE_KEYS } from 'Constants';
import { renderer, scrollTo, storage } from 'utils';
import recallsLandingTemplate from './../templates/recallsLandingTemplate';
import RecallDetailInfo from './RecallDetailInfo';
import RecallsVinView from './RecallsVINView';
import WarrantyDetailInfo from './WarrantyDetailInfo';

/**
 * @const CLASSES
 * @description Collection of constant values for classes of elements that will hold
 * the RecallLanding modules
 */
const CLASSES = {
    RECALL_INFO: 'recalls-info',
    SEARCH_MODULE: 'recalls-hero__search-module',
    RECALL_BODY_CONTENT: 'recalls-body__content',
    WARRANTY_CONTENT: 'warranty',
    RECALL_CONTENT: 'recalls'
};

const ID = {
    VIN_INPUT_FIELD: 'vin'
};

/**
 * RecallsLanding class
 */
export default class RecallsLanding {
    /**
     * Constructs the RecallsLanding class
     * @param {Object} content - Content object
     * @param {string} endpoint - Recall info endpoint
     */
    constructor(content, config) {
        this.content = content;
        this.config = config;
        this.element = recallsLandingTemplate(content, config)({ getNode: true });
        this.renderDetails = this.renderDetails.bind(this);

        const endpoint = config.endpoints;
        const cachedVin = this.getCachedVIN();

        this.cacheDOM();
        this.renderVINInput(content, endpoint, cachedVin);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.searchModule = this.element.querySelector(`.${CLASSES.SEARCH_MODULE}`);
        this.recallInfoElm = this.element.querySelector(`.${CLASSES.RECALL_INFO}`);
        this.recallBodyContent = this.element.querySelector(`.${CLASSES.RECALL_BODY_CONTENT}`);
        this.warrantyContent = this.element.querySelector(`.${CLASSES.WARRANTY_CONTENT}`);
        this.recallContent = this.element.querySelector(`.${CLASSES.RECALL_CONTENT}`);
    }

    /**
     * @method getCachedVIN
     * @description Gets the cached VIN if coming back from takata page
     * @returns {string} The cached VIN value if available
     */
    getCachedVIN() {
        const takataPageVisit = storage.sessionStorage.read(STORAGE_KEYS.TAKATA_PAGE_VISIT);
        let cachedVin = '';

        if (takataPageVisit) {
            storage.sessionStorage.create(STORAGE_KEYS.TAKATA_PAGE_VISIT, false);
            cachedVin = storage.sessionStorage.read(STORAGE_KEYS.RECALLS_VIN);
        }

        return cachedVin;
    }

    /**
     * @method renderVINInput
     * @description Renders the VIN view
     */
    renderVINInput(content, endpoint, vinInput) {
        this.vinInput = new RecallsVinView(content, endpoint, this.renderDetails, vinInput, this.config.pageType);
        renderer.insert(this.vinInput.render(), this.searchModule);
        this.vinInputFieldElm = this.element.querySelector(`#${ID.VIN_INPUT_FIELD}`);
        this.vehicleVin = window.location.hash.substring(1);
        if (this.vehicleVin) {
            this.vinInputFieldElm.value = this.vehicleVin;
        }
    }

    /**
     * @method renderDetails
     * @description Renders the details for the recall info associated with a VIN
     */
    renderDetails(recallInfo) {
        console.log('response of recall/warranty apis');
        console.log(recallInfo);

        storage.sessionStorage.create(
            STORAGE_KEYS.RECALLS_VIN,
            recallInfo.recallsDetail.result.page.vin
        );

        if (this.config.pageType === 'warranty') {
            this.warrantyDetailInfo = new WarrantyDetailInfo(this.content.warrantyDetail,
                this.config, recallInfo);
            renderer.insert(this.warrantyDetailInfo.render(), this.warrantyContent);
        } else {
            this.recallDetailInfo = new RecallDetailInfo(this.content.recallDetail,
                this.config, recallInfo.recallsDetail.result.page);
            renderer.insert(this.recallDetailInfo.render(), this.recallContent);
        }

        scrollTo(this.recallBodyContent.offsetTop);
    }

    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

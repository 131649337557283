/**
 * Template for sticky nav
 * Uses ES6 template literal to composite the markup for a sticky nav
 */
import { html } from 'utils';

export default (waypointSections = [], { SKIP_TO = '', MENU = '' }, id, initialActiveIndex = -1) => html`
    <div class="sticky-nav__container" data-waypoint-offset="">
        <div class="wrapper sticky-nav__wrapper">
            <button class="sticky-nav__nav-toggle" 
                    aria-label="${MENU}"
                    aria-controls="sticky-nav-links-${id}"
                    aria-expanded="false">${SKIP_TO}</button>
            <ul class="sticky-nav__items-list" id="sticky-nav-links-${id}">
                ${waypointSections.map((waypointSection, index) => `
                    <li class="sticky-nav__item${index === initialActiveIndex ? ' sticky-nav__item--active' : ''}${waypointSection.excludeSection === 'true' ? ' hide-large' : ''}${waypointSection.isLastVisibleItem === true ? ' sticky-nav__item--last-visible' : ''}">
                        <a class="sticky-nav__item-link"
                        href="#${waypointSection.deepLink}" data-analytics-trigger="page-nav-${waypointSection.deepLink}"><span class="sticky-nav__item-link-label">${waypointSection.navigationLabel}</span></a>
                    </li>
                `).join('')}                
            </ul>
        </div>
    </div>
`.trim();
// do not delete 9fbef606107a605d69c0edbcd8029e5d

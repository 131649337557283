import { tokenReplace } from 'utils';
import urgentRecallsAirbagTemplate from './../templates/urgentRecallsAirbagTemplate';

const CLASSES = {
    HEADER: 'urgent-recalls-airbag__header',
    TABLE: 'urgent-recalls-airbag__table',
    CAPTION: 'urgent-recalls-airbag__caption'
};

export default class UrgentRecallsAirbag {
    constructor(content, chosenModel) {
        this.chosenModel = chosenModel;
        this.element = urgentRecallsAirbagTemplate(content)({ getNode: true });

        this.cacheDOM();
        this.renderHeader(content, chosenModel);
        this.renderTableCells(content, chosenModel);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.headerElm = this.element.querySelector(`.${CLASSES.HEADER}`);
        this.tableElm = this.element.querySelector(`.${CLASSES.TABLE}`);
        this.captionElm = this.element.querySelector(`.${CLASSES.CAPTION}`);
    }

    /**
     * @method renderHeader
     * @description Renders the header based on the model state and year
     * @param {Object} content Localization object
     * @param {Array} chosenModel Array of vehicle models
     */
    renderHeader(content, chosenModel) {
        const header = tokenReplace(content.airbagModule.header, [chosenModel[0].MY, chosenModel[0].state]);
        this.headerElm.innerHTML = header;
        this.captionElm.innerHTML = header;
    }

    /**
     * @method renderTableCells
     * @description Renders the table cells for the airbag availability
     * @param {Object} content Localization object
     * @param {Array} chosenModel Array of vehicle models
     */
    renderTableCells(content, chosenModel) {
        chosenModel.forEach((model) => {
            const row = this.tableElm.insertRow(-1);
            const airbagCell = row.insertCell(0);
            const dateCell = row.insertCell(1);

            dateCell.innerHTML = model['Month Effective Launch Date'];
            dateCell.classList.add = 'table-cell';
            airbagCell.classList.add = 'table-cell';

            if (model.Inflator === 'DAB') {
                airbagCell.innerHTML = content.airbagModule.driverSide;
            } else if (model.Inflator === 'PAB') {
                airbagCell.innerHTML = content.airbagModule.passengerSide;
            } else if (model.Inflator === 'DAB, PAB') {
                airbagCell.innerHTML = content.airbagModule.driverSide;
                const secondRow = this.tableElm.insertRow(-1);
                const secondAirbagCell = secondRow.insertCell(0);
                const secondDateCell = secondRow.insertCell(1);
                secondAirbagCell.innerHTML = content.airbagModule.passengerSide;
                secondDateCell.innerHTML = model['Month Effective Launch Date'];
            }
        });
    }

    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { htmlNode } from 'utils';

export default (locations, content) => htmlNode`
    <div class="browse-by-state">
        <h3 class="wrapper dealer-results-list__heading">${content.browseAllCTA}</h3>
        <div
            data-accordion=""
            data-expand-multiple="false"
            data-accordion-name="dealerLocatorAccordion"
            data-analytics-container="dealer-list"
            class="dealer-group-search__locations">
            ${locations.map((location) => `
                <div
                    data-accordion-item=""
                    class="browse-by-state__state-item"
                    data-location-group="${location.code}">
                    <div data-accordion-title="">
                        <button class="browse-by-state__state-cta" data-accordion-button="" data-analytics-trigger="area-expand">
                            ${location.name}
                        </button>
                    </div>
                    <div class="browse-by-state__results" data-accordion-pane="" data-location-results=""></div>
                </div>
            `.trim()).join('')}
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Local dependencies
import preferredDealerTemplate from './../templates/preferredDealerTemplate';
import DealerResult from './DealerResult';

function validateDOMNode(node) {
    if (
        node instanceof Node &&
        (node.tagName !== 'SCRIPT') &&
        (node instanceof HTMLElement || node instanceof SVGElement)
    ) {
        return node;
    }
    return null;
}

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    DEALER_CONTAINER: 'preferred-dealer__dealer'
};

export default class PreferredDealer {
    /**
     * @constructor
     * @description Creates new PreferredDealer
     * @param element {Node} Element to render the PreferredDealer to
     * @param {Object} config - Configuration object
     * @param {Object} content - Localization object
     * @param {Function} dealerSelectCallback - Callback function for Dealer Selection
     */
    constructor(dealer, config, content, dealerSelectCallback) {
        this.dealer = dealer;
        this.config = config;
        this.content = content;
        this.dealerSelectCallback = dealerSelectCallback;
        this.element = preferredDealerTemplate(content, config)({ getNode: true });

        this.dealerResult = null;

        this.cacheDOM();
        this.renderDealer(dealer);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.dealerContainer = this.element.querySelector(`.${CLASSES.DEALER_CONTAINER}`);
    }

    /**
     * @method destroy
     * @description Destroys instances of PreferredDealer
     */
    destroy() {
        this.dealerResult.destroy();
        this.element.remove();
        this.element = null;
    }

    /**
     * @method renderDealer
     * @param {Object} dealer - Dealer object
     * @description Renders a DealerResult on the dealer container
     */
    renderDealer(dealer) {
        this.dealerResult = new DealerResult(
            { ...dealer, isAOIDealer: false },
            {
                showDistance: this.config.featureFlags.showDistance,
                showHoursOfOp: this.config.featureFlags.showDealerHours,
                showSecondaryCTA: this.config.featureFlags.showSecondaryCTA,
                displayLayout: this.config.displayLayout,
                useScheduleServiceURL: this.config.useServiceURL,
                showPhoneNumbers: true,
                showArrowIndicator: true,
                showFullAddress: true,
                showVanCare: this.config.featureFlags.showVanCare,
            },
            this.content,
            this.dealerSelectCallback);

        this.dealerContainer.appendChild(validateDOMNode(this.dealerResult.render()));
    }

    /**
     * @method render
     * @description Renders the dealer result module
     */
    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// partial dependencies
import AnalyticsApi from 'partials/analytics';

/**
 * @function parseRatesOptions
 * @description Parse rates obj for returning an array with rate and term properties
 * @param {Object} rates - Object with rates and terms for vehicle model
 * @returns {Object} Parsed out config options
 */
function parseRatesOptions(rates) {
    const optionsArr = [];

    rates.forEach((item) => {
        optionsArr.push({
            rate: item.rate,
            term: item.termFrom,
            validDate: item.validThru
        });
    });

    const sortedArr = optionsArr.sort((a, b) => a.term - b.term);
    return sortedArr;
}

/**
 * @function isRatesApiSuccessful
 * @description Checks if the response code is a 2xx
 * @returns {Boolean} True if the response code is 2xx
 */
function isRatesApiSuccessful(response) {
    return /^2\d\d$/.test(response.status.code);
}

/**
 * @function logErrorEvent
 * @description trigger a satellite event for service errors
 * @param {string} eventName name of analtyics event
 * @param {string} description description of error
 * @param {string} type error status code
 */
function logErrorEvent(eventName, description, type) {
    const analyticsApi = new AnalyticsApi('satellite');
    analyticsApi.logEvent(eventName, {
        name: description,
        type
    });
}

/**
 * @function ExceptionError
 * @description Constructor function that creates a custom error object
 * @param {number} code status code from response
 * @param {string} message error message
 */
function ExceptionError(code, message) {
    this.code = code;
    this.message = message;
    return this;
}

/**
 * @method getRates
 * @description get location data based on zipcode and vehicle info
 * @param {string} endpoint rates api endpoint
 * @param {object} vehicle data
 */
function getRates(endpoint, vehicle) {
    const requestObj = {
        modelCode: vehicle.modelId,
        year: vehicle.year
    };

    return fetch(endpoint, {
        body: JSON.stringify(requestObj),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST',
    })
        .then((response) => response.json())
        .then((response) => {
            if (!isRatesApiSuccessful(response)) {
                throw new ExceptionError(
                    response.status.code,
                    response.status.description
                );
            }

            return parseRatesOptions(response.ratesTerms);
        })
        .catch((error) => {
            const code = error.code;
            if (error instanceof TypeError && !navigator.onLine) {
                throw new ExceptionError(
                    600,
                    'Connectivity Error'
                );
            }

            logErrorEvent(`error${code || ''}`, `Payment Driver Rates Api - ${error.message || error}`, code || '');

            throw error;
        });
}


export default {
    getRates
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Results List container
 */
import { htmlNode } from 'utils';

/**
 * @method renderSuggestedDealer
 * @description Checks to see whether suggested dealer eyebrow should be displayed
 * @param isSuggested {Boolean} Boolean for suggested eyebrow
 * @param suggestedCopy {String} Copy for suggested eyebrow
 * @return {HTML, string} Returns suggested eyebrow if condition is true, else blank string
 */
/* const renderSuggestedDealer = (isSuggested, suggestedCopy) => {
    if (isSuggested) {
        return `<span class="dealer-result-list-item__suggested-dealer">${suggestedCopy}</span>`;
    }
    return '';
}; */

export default ({ dealer }) => htmlNode`
    <li class="dealer-result-list-item ${''}" data-analytics-trigger="contact-dealer-select-${dealer.name}" tabindex="0">
        <div class="dealer-result-list-item__address-container">
            <div>
                <span class="dealer-result-list-item__dealer-name">${dealer.name}</span>
                <span class="dealer-result-list-item__dealer-distance">${dealer.distance}</span>
            </div>
            <div class="dealer-result-list-item__address">${dealer.address}, ${dealer.city}, ${dealer.state} ${dealer.zip}</div>
            <div class="dealer-result-list-item__error" id="dealerError-${dealer.dealerId}"/>
        </div>
    </li>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Api responsible for vehicle data by vin and class, model and year
 */

// Import utils
import {
    formatNumber,
    serializeObject
} from 'utils';

// Local dependencies
import {
    createDealerObject,
    createImagesArray,
    createMediaObject,
    createMediaObjectFromPath,
    createMileageObject,
    createPricingObject
} from './inventorySearchUtils';


/**
 * @method getVehicleProperty
 * @description Gets property value for given property name
 * @param {Array} properties - array of vehicle properties
 * @param {String} name - property name to find
 * @param {String} title - title value to set
 * @param {[String]} icon - optional icon value key to set
 * @return {{id, icon: *, title: *, subtitle: *, value}} - a specifications object with id, icon, title,
 * subtitle and value props/values
 */
function getVehicleProperty(properties, name, title, icon = null) {
    const prop = properties.find((property) => property.name === name);
    return prop && {
        id: prop.name,
        icon,
        title,
        subtitle: prop.subtitle,
        value: prop.value
    };
}

/**
 * @method createVehicleSpecsProperty
 * @description Creates an vehicle specifications object
 * @param id
 * @param title
 * @param value
 * @param icon
 * @return {{id: *, icon: *, title: *, value: *}}
 */
function createVehicleSpecsProperty(id, title, value, icon = null) {
    return {
        id,
        icon,
        title,
        value
    };
}

/**
 * @method createSpecificationsObject
 * @description creates specifications object with a collection of primary and secondary specification objects
 * @param {Object} vehicle - Object to parse specifications from
 * @param {Object} content - content object containing properties for each specification title
 * @return {{primary: [], secondary: []}} - specifications object
 */
function createSpecificationsObject(vehicle, content) {
    // Year, Engine, Exterior, Interior,
    // Payload Capacity, Max Towing Capacity, Cargo Volume, Inc. Standing Height, and Max Seating Capacity.
    const specifications = {
        primary: [
            getVehicleProperty(vehicle.properties, 'YEAR', content.year),
            getVehicleProperty(vehicle.properties, 'ENGINE', content.engine),
            getVehicleProperty(vehicle.properties, 'EXTERIOR_COLOR', content.exteriorColor),
            getVehicleProperty(vehicle.properties, 'INTERIOR_COLOR', content.interiorColor),
            getVehicleProperty(vehicle.properties, 'PAYLOAD_CAPACITY', content.payloadCapacity),
            getVehicleProperty(vehicle.properties, 'MAX_TOWING_CAPACITY', content.towingCapacity),
            getVehicleProperty(vehicle.properties, 'CARGO_VOLUME', content.cargoVolume),
            getVehicleProperty(vehicle.properties, 'INTERIOR_STANDING_HEIGHT', content.stadingHeight),
            getVehicleProperty(vehicle.properties, 'MAX_SEATING_CAPACITY', content.maxSeatingCapacity),
        ],
        secondary: [
        ]
    };

    specifications.primary = specifications.primary.filter((spec) => spec !== undefined);
    specifications.secondary = specifications.secondary.filter((spec) => spec !== undefined);
    return specifications;
}

/**
 * @method getCpoVehicleProperty
 * @description Gets property value for given property name
 * @param {Array} properties - array of vehicle properties
 * @param {String} name - property name to find
 * @param {String} title - title value to set
 * @return {{id: *, icon: *, title: *, value: *}} - a specifications object with id, icon, title,
 * subtitle and value props/values
 */
function getCpoVehicleProperty(properties, name, title) {
    const prop = properties.find((property) => property.code === name);
    return prop && createVehicleSpecsProperty(name, title, prop.description);
}

/**
 * @method createCPOSpecifications
 * @description creates CPO specifications object with a collection of primary and secondary specification objects
 * @param {Object} vehicle - Object to parse specifications from
 * @param {Object} config - Object containing config props for country and language
 * @param {Object} content - content object containing properties for each specification title
 * @return {{primary: [], secondary: []}} - specifications object
 */
function createCPOSpecifications(vehicle, config, content) {
    const cpoSpecs = {
        primary: [
            createVehicleSpecsProperty('mileage', content.mileage, formatNumber.toStringNumber(vehicle.usedVehicleAttributes.mileage, config.country, config.language), 'ACCELERATION'),
            createVehicleSpecsProperty('vin', content.vin, vehicle.vin, 'MODEL-CHOOSER')
        ],
        secondary: [
            createVehicleSpecsProperty('exteriorColor', content.exteriorColor, vehicle.paint.name),
            createVehicleSpecsProperty('interiorColor', content.interiorColor, vehicle.upholstery.name),
            createVehicleSpecsProperty('stockNumber', content.stockNumber, vehicle.usedVehicleAttributes.stockId),
            createVehicleSpecsProperty('powertrain', content.powertrain, vehicle.powertrain.name),
        ]
    };

    if (vehicle.specifications && vehicle.specifications.CAPACITY) {
        const capacitySpecs = vehicle.specifications.CAPACITY.specifications || [];
        const payload = getCpoVehicleProperty(capacitySpecs, 'PAYLOAD_CAPACITY', content.payloadCapacity);
        if (payload) {
            cpoSpecs.secondary.push(payload);
        }

        const towing = getCpoVehicleProperty(capacitySpecs, 'MAX_TOWING_CAPACITY', content.towingCapacity);
        if (towing) {
            cpoSpecs.secondary.push(towing);
        }

        const cargoVolume = getCpoVehicleProperty(capacitySpecs, 'CARGO_VOLUME', content.cargoVolume);
        if (cargoVolume) {
            cpoSpecs.secondary.push(cargoVolume);
        }

        const seatingCapacity = getCpoVehicleProperty(capacitySpecs, 'MAX_SEATING_CAPACITY', content.seatingCapacity);
        if (seatingCapacity) {
            cpoSpecs.secondary.push(seatingCapacity);
        }
    }

    return cpoSpecs;
}

/**
 * @method createDisclaimersList
 * @description parses disclaimers to needed format
 * @param {Array} disclaimers
 * @return {Array} - disclaimer list
 */
function createDisclaimersList(disclaimers) {
    return disclaimers.map((disclaimer) => {
        if (typeof disclaimer === 'string') {
            return {
                code: 'custom',
                desc: disclaimer
            };
        }

        return disclaimer;
    });
}

/**
 * @method createOptionsFeaturesList
 * @description Creates a list of features containing optional feature name
 * and features details object.
 * @param {Array} options - array of options
 * @return {Array} - List of feature objects
 */
function createOptionsFeaturesList(options) {
    return options.map((optionObj) => ({
        id: optionObj.id,
        isOption: optionObj.isOption,
        featureText: optionObj.name,
        option: optionObj.isOption ? {
            id: optionObj.id,
            name: optionObj.name,
            desc: optionObj.description,
            media: optionObj.image ? [{
                imgL: optionObj.image.url,
                alt: optionObj.image.altText
            }] : null,
            disclaimers: optionObj.disclaimers && createDisclaimersList(optionObj.disclaimers)
        } : null
    }));
}

/**
 * @method createCPOStandardFeature
 * @description Creates standard feature object
 * @param {String} section - features key
 * @param {String} sectionDescription - section title
 * @param {Array} options - Collection of standard features
 * @return {Object}
 */
function createCPOStandardFeature(section, sectionDescription, options) {
    const feature = {
        section,
        sectionDescription,
        featureList: []
    };

    feature.featureList = options.map((optionObj) => ({
        id: optionObj.code,
        isOption: false,
        featureText: optionObj.text
    }));

    return feature;
}

/**
 * @method createCPOStandardFeatures
 * @description Creates standard features list object from CPO attributes
 * @param {Object} vehicleAttributes - Object to parse features from
 * @param {Object} content - content object containing properties for each features section title
 * @return {Array}
 */
function createCPOStandardFeatures(vehicleAttributes, content) {
    const standardFeatures = [];

    if (vehicleAttributes.optionList) {
        const option = createCPOStandardFeature('options', content.options, vehicleAttributes.optionList);
        standardFeatures.push(option);
    }

    if (vehicleAttributes.comfortList) {
        const comfort = createCPOStandardFeature('comfort', content.comfort, vehicleAttributes.comfortList);
        standardFeatures.push(comfort);
    }

    if (vehicleAttributes.safetyList) {
        const safety = createCPOStandardFeature('safety', content.safety, vehicleAttributes.safetyList);
        standardFeatures.push(safety);
    }

    if (vehicleAttributes.performanceList) {
        const performance = createCPOStandardFeature('performance', content.performance, vehicleAttributes.performanceList);
        standardFeatures.push(performance);
    }

    return standardFeatures;
}

/**
 * @method createPackagesList
 * @description Creates a list of packages containing package name
 * and features details object.
 * @param {Array} packages - array of packages
 * @return {Array} - List of package objects
 */
function createPackagesList(packages) {
    return packages.map((packageObj) => ({
        id: packageObj.id,
        name: packageObj.name,
        features: packageObj.includedOptions ? createOptionsFeaturesList(packageObj.includedOptions) : [],
        disclaimers: packageObj.disclaimers
    }));
}

/**
 * @method parseVehicleData
 * @description Parses a vehicle search response and returns a vehicle object
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {Object} vehicleResponse - Response object from API
 * @param {Object} imagePath - Image Fallback Config Object
 * @param {Object} content - Object containing content key/value props
 * @return {null}
 */
function parseVehicleData(config, imagePath, content, vehicleResponse) {
    try {
        const { vehicle } = vehicleResponse.result;
        const defaultImage = vehicle.classId === 'METRIS' ? imagePath.metris : imagePath.default;

        if (vehicle) {
            vehicle.msrp = createPricingObject(config, vehicle.msrp);
            vehicle.exteriorBaseImage =
                vehicle.exteriorBaseImage ?
                    createMediaObject(vehicle.exteriorBaseImage) :
                    createMediaObjectFromPath(defaultImage);
            vehicle.interiorBaseImage =
                vehicle.interiorBaseImage ?
                    createMediaObject(vehicle.interiorBaseImage) :
                    createMediaObjectFromPath(defaultImage);
            vehicle.fallbackImage = defaultImage;
            vehicle.specifications = createSpecificationsObject(vehicle, content);
            vehicle.packages = createPackagesList(vehicle.packages);
            vehicle.options = createOptionsFeaturesList(vehicle.options);
            vehicle.standardFeatures = [];
            vehicle.hasMSRP = vehicle.msrp !== 999996;
            if (config.country !== 'ca') {
                vehicle.images = createImagesArray(vehicle.vehicleAttributes.images, defaultImage);
            }
        }

        return vehicle;
    } catch (e) {
        throw ('Error while parsing vehicle response', e);
    }
}

/**
 * @method parseCPOVehicleData
 * @description Parses a CPO vehicle search response and returns a vehicle object
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {Object} content - Object containing content key/value props
 * @param {Object} imagePath - Paths for images, such as default (fallback) image
 * @param {Object} vehicleResponse - Response object from API
 * @return {Object}
 */
function parseCPOVehicleData(config, content, imagePath, vehicleResponse) {
    try {
        const { vehicle } = vehicleResponse.result;

        if (vehicle) {
            const defaultImage = (
                imagePath.fallBackImage[vehicle.classId.toLowerCase()]
                || imagePath.fallBackImage.default
            );
            const vehicleObj = {
                ...vehicle,
                ...vehicle.usedVehicleAttributes,
                available: true,
                dealer: vehicle.usedVehicleAttributes.dealer &&
                createDealerObject(
                    config,
                    vehicle.usedVehicleAttributes.dealer,
                    vehicle.usedVehicleAttributes.comments
                ),
                exteriorColor: vehicle.paint.name,
                fallbackImage: defaultImage,
                historyReport: {
                    isEnabled: vehicle.usedVehicleAttributes.historyReport,
                    linkoutURL: vehicle.usedVehicleAttributes.historyReportUrl
                },
                hasHomeDelivery: vehicle.usedVehicleAttributes.deliveryOption === 'B' ||
                vehicle.usedVehicleAttributes.deliveryOption === 'D',
                hasHomeTestDrive: vehicle.usedVehicleAttributes.deliveryOption === 'B' ||
                vehicle.usedVehicleAttributes.deliveryOption === 'T',
                hasMSRP: vehicle.msrp !== 999996,
                images: createImagesArray(vehicle.usedVehicleAttributes.images, defaultImage),
                interiorColor: vehicle.upholstery.name,
                msrp: createPricingObject(config, vehicle.msrp),
                mileage: createMileageObject(
                    config,
                    vehicle.usedVehicleAttributes.mileage,
                    vehicle.usedVehicleAttributes.mileageUnit),
                name: vehicle.usedVehicleAttributes.headerText,
                reserveDiscount: vehicle.usedVehicleAttributes.reservePrice ?
                    createPricingObject(config, (vehicle.msrp - vehicle.usedVehicleAttributes.reservePrice)) :
                    null,
                reservePrice: vehicle.usedVehicleAttributes.reservePrice ?
                    createPricingObject(config, vehicle.usedVehicleAttributes.reservePrice) :
                    null,
                specifications: createCPOSpecifications(vehicle, config, content),
                standardFeatures: createCPOStandardFeatures(vehicle.usedVehicleAttributes, content)
            };

            delete vehicleObj.usedVehicleAttributes;

            return vehicleObj;
        }

        return null;
    } catch (e) {
        throw ('Error while parsing CPO vehicle response', e);
    }
}

/**
 * @method getStandardFeatures
 * @description Fetches the standard features for a vehicle
 *
 * @param config
 * @param classId
 * @param modelId
 * @param year
 */
function getStandardFeatures(config, { classId, modelId, year }) {
    const requestURLParams = serializeObject({
        brand: config.brand,
        class: classId,
        model: modelId,
        year
    });

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    }).then((response) => {
        if (!response.ok) {
            return null;
        }

        return response.json();
    });
}

/**
 * @method getVehicle
 * @description Fetches a vehicle for the provided vin & dealer combo
 * @param {Object} config - Configuration object { endpoint }
 * @param {String} dealerId - Dealer ID of the currently selected dealer
 * @param {String} vin - Vehicle vin
 * @param {Object} imagePath - Image Fallback Config Object
 * @param {Object} content - Object containing content key/value props
 * @return {Promise} A promise which resolves with a vehicle object
 */
function getVehicle(config, dealerId, vin, imagePath, content) {
    const requestURLParams = serializeObject({
        brand: config.brand || window.mbVans.ns('pageData').brand,
        vin,
        dealerId
    });

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(parseVehicleData.bind(null, config, imagePath, content));
}

/**
 * @method getCPOVehicle
 * @description Fetches a CPO vehicle for the provided vin & dealer combo
 * @param {Object} config - Configuration object { endpoint }
 * @param {String} dealerId - Dealer ID of the currently selected dealer
 * @param {String} vin - Vehicle vin
 * @param {Object} content - Object containing content key/value props
 * @return {Promise} A promise which resolves with a vehicle object
 */
function getCPOVehicle(config, dealerId, vin, content, imagePath) {
    const requestURLParams = serializeObject({
        vin,
        dealerId,
        brand: config.brand || window.mbVans.ns('pageData').brand,
    });

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(parseCPOVehicleData.bind(null, config, content, imagePath))
        .catch((error) => {
            throw (error);
        });
}

/**
 * @method getVehicleDetails
 * @description Fetches the vehicle details for a vin and the vehicle's
 * standard features
 * @param config {Object} Configuration object for service endpoints
 * @param dealerId {String} Dealer Id to retrieve vehicle details from
 * @param vin {String} VIN to retrieve vehicle details from
 * @param {Object} imagePath - Image Fallback Config Object
 * @param {Object} content - Object containing content key/value props
 * @return {Promise} Promise that resolves with the vehicle
 */
function getVehicleDetails(config, dealerId, vin, imagePath, content) {
    return getVehicle({
        ...config,
        endpoint: config.endpoints.vehicleLookup,
    }, dealerId, vin, imagePath, content);
}

// Exports public api methods
export default {
    getStandardFeatures,
    getVehicleDetails,
    getVehicle,
    getCPOVehicle
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import ADDRESS_TYPES from './../constants/residenceAddressTypes';

/**
 * @function getRelevantResidence
 * @description Constructs an object with a users relevant current and previous address information
 * @param residenceInfo {Object} Residence information
 * @return {{
 *   currentAddress: (*|null|string),
 *   currentCity: (*|null),
 *   currentMaritalStatus: (*|null|Object.residenceInfo.currentMaritalStatus|Object.currentMaritalStatus),
 *   currentMonths: (*|string),
 *   currentMortgageRentAmount: (*|null),
 *   currentOccupancyType: (*|null),
 *   currentState: *,
 *   currentYears: (*|null),
 *   currentZipCode: (*|null),
 *   previousAddress: (*|null),
 *   previousCity: (*|null),
 *   previousMonths: (*|string),
 *   previousState: (*|null),
 *   previousYears: (*|null),
 *   previousZipCode: (*|null)
 * }}
 */
export function getRelevantResidence(residenceInfo) {
    return {
        currentAddress: residenceInfo.currentAddress,
        currentCity: residenceInfo.currentCity,
        currentMonths: residenceInfo.currentMonths,
        currentMortgageRentAmount: residenceInfo.currentMortgageRentAmount,
        currentOccupancyType: residenceInfo.currentOccupancyType,
        currentState: residenceInfo.currentState,
        currentYears: residenceInfo.currentYears,
        currentZipCode: residenceInfo.currentZipCode,
        previousAddress: residenceInfo.previousAddress,
        previousCity: residenceInfo.previousCity,
        previousMonths: residenceInfo.previousMonths,
        previousState: residenceInfo.previousState,
        previousYears: residenceInfo.previousYears,
        previousZipCode: residenceInfo.previousZipCode
    };
}

/**
 * @function getAddress
 * @description Constructs an address object from the `residenceInfo` based on the address type
 * @param residenceInfo {Object} Residence information
 * @param type {string} Address type
 * @return {{zipCode: *, address: *, city: *, state: *}}
 */
export function getAddress(residenceInfo, type = ADDRESS_TYPES.CURRENT) {
    return {
        address: residenceInfo[`${type}Address`],
        city: residenceInfo[`${type}City`],
        state: residenceInfo[`${type}State`],
        zipCode: residenceInfo[`${type}ZipCode`],
    };
}

/**
 * @function isSpouseEmpty
 * @description Determines if the spouse residence info is empty or has values
 * @param residenceInfo {Object} Residence information
 */
export function isSpouseEmpty(residenceInfo) {
    return !(
        residenceInfo.spouseFirstName ||
        residenceInfo.spouseLastName ||
        residenceInfo.spouseAddress ||
        residenceInfo.spouseCity ||
        residenceInfo.spouseState ||
        residenceInfo.spouseZipCode
    );
}


// do not delete 9fbef606107a605d69c0edbcd8029e5d

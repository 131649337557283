import { generateUniqueID } from 'utils';

/**
 * @method ModalCarouselItemDisclaimerTemplate
 * @description Template for a ModalCarouselItemDisclaimer view.
 * Uses ES6 template literal to composite the markup for a ModalCarouselItemDisclaimer view
 * @param {Array} disclaimers - disclaimers list
 * @param {String} disclaimerCopy - disclaimer button copy
 * @param {String} modalId - optional id used by the disclaimer drawer
 */
export default (disclaimers = [], disclaimerCta, disclaimerCopy, modalId = generateUniqueID()) => `
    ${disclaimers.length === 0 ? '' :
        `<div class="modal-carousel__disclaimer">
            <button
              class="disclaimer__toggle disclaimer__drawer-cta"
              aria-expanded="false"
              aria-controls="modal-options-toggle-container-${modalId}"
              data-timing=".3s"
              data-analytics-trigger="cta">
              <span class="button_toggle-copy" data-toggle-drawer-button>${disclaimerCopy}</span>
              <span class="icon-mb icon-arrow-down-square"></span>
            </button>
            <div id="modal-options-toggle-container-${modalId}" class="disclaimer__drawer">
              <ul>
                ${disclaimers.reduce((list, disclaimer) => {
                    list += `<li>
                        ${(disclaimer.code && disclaimer.code !== 'custom') ? `<p class="disclaimer__legend" data-legend="${disclaimer.code}">${disclaimer.code}</p>` : ''}
                        <div class="disclaimer__text">${disclaimer.desc}</div>
                    </li>`;
                    return list;
                }, '')}
              </ul>
            </div>
        </div>`
    }`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying the list of all dealer results
 * @param resultHeading {String} Heading of dealer results
 * @param showMoreLocations {String} Show more CTA copy
 */
export default (resultHeading, showListHeading, showLoadMore, showResultsListCTA, showMoreLocations, serviceDownFirst, serviceDownSecond) => htmlNode`
    <div class="dealer-results-list">
        ${showListHeading ? `<h3 class="wrapper dealer-results-list__heading">${resultHeading}</h3>` : ''}
        <div class="dealer-results-list__results-container">
            <div class="dealer-results-list__no-results hide">
                <p class='dealer-results-list__service-down hide'>${serviceDownFirst}</p>
                <p class='dealer-results-list__service-down-two hide'>${serviceDownSecond}</p>
            </div>
        </div>
        ${showResultsListCTA ?
        `<div class="dealer-results-list__cta-container" data-analytics-container="dealer-footer">
            <span class="dealer-results-list__results-end"></span>
            ${showLoadMore ? `<button class="dealer-results-list__load-more button button_primary button--full-width" aria-label="${showMoreLocations}" data-analytics-trigger="cta">${showMoreLocations}</button>` : ''}
        </div>` : ''}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { noop } from 'utils';

// Partial dependencies
import { UserProfile } from 'partials/profile';
import { GoogleMap, ICONS } from 'partials/google-maps';

/**
 * View responsible for rendering the DealerSelectorMap module
 */
export default class DealerSelectorMap {
    /**
     * @constructor
     * @description Initializes the view and sets the initial state
     * @param {object} config - Configuration object
     * @param {string} config.country - Country ISO
     * @param {string} config.language - Language ISO
     * @param {Node} element - Element to render the map to
     * @param {array} dealers - Array of dealer objects from API response
     * @param {function} onDealerSelect - Callback method applied on selecting a dealer
     */
    constructor(
        config = {},
        element,
        dealers,
        onDealerSelect = noop
    ) {
        this.dealers = dealers;
        this.config = config;
        this.element = element;
        this.onDealerSelect = onDealerSelect;
        this.map = null;
        this.profile = new UserProfile();

        this.createMap();
    }

    /**
     * @method createMap
     * @description Creates a Google Map object to render to the map element
     */
    createMap() {
        this.map = new GoogleMap({
            element: this.element,
            onMapCreatedCallback: this.addDataToMap.bind(this),
            markerClickCallback: this.selectDealer.bind(this)
        });
    }

    /**
     * @method addDataToMap()
     * @description Parses the data and calls
     * map.addMarker to render the data
     */
    addDataToMap() {
        if (this.dealers) {
            // For each dealer add an icon
            this.dealers = this.dealers.map((retailer) => {
                retailer.icon = this.isPreferredDealer(retailer) ?
                    ICONS.preferredDealer : ICONS.dealers;
                return retailer;
            });

            this.map.addMarkers(this.dealers, false);
        }
    }

    /**
     * @method isPreferredDealer
     * @description Checks if dealer is preferred dealer
     * @param dealer {Object} Dealer to check
     */
    isPreferredDealer(dealer) {
        const preferredDealer = this.profile.getPreferredDealer();

        return preferredDealer &&
            preferredDealer.dealerId === dealer.dealerId &&
            preferredDealer.zip === dealer.zip;
    }

    /**
     * @method selectDealer
     * @description Applies callback `onDealerSelect` with selected dealer
     */
    selectDealer(dealer) {
        this.onDealerSelect(dealer);
    }

    /**
     * @method hoverDealer
     * @description Calls the GoogleMap method to hover pin
     */
    hoverDealer(dealer) {
        this.map.markerMouseOverHandlerById(dealer.id);
    }

    /**
     * @method destroy
     * @description Destroys the view
     */
    destroy() {
        if (this.map) {
            this.map.destroyMap();
        }

        this.map = null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

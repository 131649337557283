import { htmlNode } from 'utils';

/**
 * @function
 * @name renderCertified
 * @description render Certified Label
 * @param {Object} center object
 * @return {String} template string
 */
const renderCertified = (center, content) => {
    let str = '';
    if (center.isCertified) {
        str += `<span class="dealer-info__collision-center--certified">${content.certifiedLabel}</span><br/>`;
    }
    return str;
};

/**
 * Template for a Dealer view.
 * Uses ES6 template literal to composite the markup for a Dealer module
 */

export default (collisionCenter, content, index) => htmlNode `
    <div class="dealer-info dealer-info__collision-center" data-index="${index}" data-selected-center="false" data-analytics-container="{ collision-card: '${collisionCenter.name}'}">
        <address class="${collisionCenter.country === 'mx' ? 'dealer-info__address-container-dealer' : 'dealer-info__address-container-cc'}" tabindex="0">
            <div>
                <span class="dealer-info__dealer-name">${collisionCenter.name}</span>
                <span class="dealer-info__dealer-distance">${collisionCenter.distance}</span>
            </div>
            <div class="dealer-info__address dealer-info__address--street">${collisionCenter.address}</div>
            <div class="dealer-info__address dealer-info__address--main">${collisionCenter.city}, ${collisionCenter.state}, ${collisionCenter.zip}</div>
            ${renderCertified(collisionCenter, content)}
            <div>
                <a href="tel:${collisionCenter.phone}" class="dealer-info__phone-number" data-analytics-trigger="call-dealer">${collisionCenter.phoneFormatted}</a>
            </div>
            ${collisionCenter.country !== 'mx' ? `<div class="dealer-info__collision-center--sponsor">${content.collisionCenterSponsor} ${collisionCenter.dealer}</div>` : ''}
        </address>
    </div>`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import {
    BatchAssetLoader,
    customEventDispatcher,
    detect,
    renderer,
    screen,
    validate
} from 'utils';
import { EVENTS, CUSTOM_EVENTS, TRANSITION_STAGES } from 'Constants';
import AnimationElement from 'partials/animation-element';
import { Modal } from 'partials/modal';

import { minSizeViolationTemplate } from './../../templates';

const { isiOSMobile, isSafari } = detect;

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 */
const CLASSES = {
    SPECIALTY_PAGE_OUTER: 'specialty-page-outer',
    SPECIALTY_CHAPTER: 'specialty-chapter',
    SPECIALTY_CHAPTER_BORDER: 'specialty-chapter__border',
    SPECIALTY_CHAPTER_BORDER_INNER: 'specialty-chapter__border-inner',
    MODAL_SIZE_VIOLATION: 'specialty-screen-size-violation',
    SIZE_VIOLATION_COPY: 'specialty-page__size-violation-copy--strong'
};

/**
 * @const NAME_VALUE
 */
const NAME_VALUE = {
    NAME: 0,
    VALUE: 1
};

/**
 * @const LOCAL_CONSTANTS
 * @description Stores a collection of string constants
 */
const LOCAL_CONSTANTS = {
    TIMEOUT_INTERVAL: 500
};

/**
 * @class BaseChapter
 * @description Encapsulate common logic between Specialty Chapters
 */
export default class BaseChapter {
    constructor(chapterData) {
        // properties
        this.chapterData = chapterData;
        this.setConfiguration();
        this.violationModalOpen = false;
        this.assetsToLoad = null;
        this.isiOSMobile = false;
        this.batchLoadAssets = [];
        this.batchAssetLoaderMethod = BatchAssetLoader.REQUEST_METHODS.GET;
        this.element = null;
        this.animationElements = {};
        this.localization = window.mbVans.ns('specialtyPageData', 'specialty-app-amg').localization;
        this.amgDamUrlPrefix = `/content/dam/mb-vans/${window.mbVans.ns('pageData').country}/amg`;
        this.setBindings();
        this.init();
    }

    /**
     * @method setConfiguration
     * @description sets configuration property
     */
    setConfiguration() {
        this.configuration = { ...this.constructor.defaultConfig };
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onHashChange = this.onHashChange.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onScreenResizeForViolation = this.onScreenResizeForViolation.bind(this);
        this.onGlobalHeaderOpen = this.onGlobalHeaderOpen.bind(this);
        this.onGlobalHeaderClosed = this.onGlobalHeaderClosed.bind(this);
        this.onDockedFooterOpen = this.onDockedFooterOpen.bind(this);
        this.onDockedFooterClosed = this.onDockedFooterClosed.bind(this);
        this.onAfterViolationModalOpen = this.onAfterViolationModalOpen.bind(this);
        this.onBeforeViolationModalClose = this.onBeforeViolationModalClose.bind(this);
        this.onBatchAssetLoaderStatusUnsent = this.onBatchAssetLoaderStatusUnsent.bind(this);
        this.onBatchAssetLoaderStatusOpened = this.onBatchAssetLoaderStatusOpened.bind(this);
        this.onBatchAssetLoaderStatusHeadersReceived = this.onBatchAssetLoaderStatusHeadersReceived.bind(this);
        this.onBatchAssetLoaderProgress = this.onBatchAssetLoaderProgress.bind(this);
        this.onBatchAssetLoaderStatusDone = this.onBatchAssetLoaderStatusDone.bind(this);
        this.onBatchAssetLoaderComplete = this.onBatchAssetLoaderComplete.bind(this);
        this.onBatchAssetLoaderAbort = this.onBatchAssetLoaderAbort.bind(this);
        this.onBatchAssetLoaderError = this.onBatchAssetLoaderError.bind(this);

        if (isiOSMobile() && !isSafari()) {
            this.isiOSMobile = true;
            this.oniOSOrientationChange = this.oniOSOrientationChange.bind(this);
        }
    }

    /**
     * @method init
     * @description initializes BaseChapter instance
     */
    init() {
        if (this.assetsToLoad) {
            this.cacheLoadingStateDOM();
            this.prepBatchAssetLoader();
            this.loadAssets();
            this.enterChapterLoadingState();
        } else {
            this.commenceChapterUI();
        }
    }

    /**
     * @method cacheLoadingStateDOM
     * @description Caches DOM elements of the view, for use in Loading State
     */
    cacheLoadingStateDOM() {
        this.specialtyChapterBorderInnerElm = document.querySelector(`.${CLASSES.SPECIALTY_CHAPTER_BORDER_INNER}`);
    }

    /**
     * @method prepBatchAssetLoader
     * @description creates and initializes a BatchAssetLoader instance,
     * for use by child classes
     */
    prepBatchAssetLoader() {
        this.batchAssetLoader = new BatchAssetLoader({
            unsent: this.onBatchAssetLoaderStatusUnsent,
            opened: this.onBatchAssetLoaderStatusOpened,
            headersReceived: this.onBatchAssetLoaderStatusHeadersReceived,
            progress: this.onBatchAssetLoaderProgress,
            done: this.onBatchAssetLoaderStatusDone,
            abort: this.onBatchAssetLoaderAbort,
            error: this.onBatchAssetLoaderError,
            complete: this.onBatchAssetLoaderComplete
        });
    }

    /**
     * @method loadAssets
     * @description loads chapter assets via BatchAssetLoader;
     * intended for use by child classes
     */
    loadAssets() {
        this.batchLoadAssets = this.batchAssetLoader.loadAssets(
            this.assetsToLoad, this.batchAssetLoaderMethod);
    }

    /**
     * @method enterChapterLoadingState
     * @description begin the loading view for chapter playin
     */
    enterChapterLoadingState() {
        if (this.loadingMessage) {
            renderer.insert(this.loadingMessage, this.specialtyChapterBorderInnerElm);
        }
    }

    /**
     * @method exitChapterLoadingState
     * @description end loading view for chapter playin
     */
    exitChapterLoadingState() {
        if (this.loadingMessage) {
            renderer.empty(this.specialtyChapterBorderInnerElm);
            this.loadingMessage = null;
        }
        this.commenceChapterUI();
    }

    /**
     * @method commenceChapterUI
     * @description populates template and randers chapter UI
     */
    commenceChapterUI() {
        this.populateTemplate();
        this.cacheDOM();
        this.registerAnimationElements();
        this.attachEvents();
        this.render();
    }

    /**
     * @method registerAnimationElements
     * @description registers AnimationElement instances;
     * intended for use by child classes
     */
    registerAnimationElements() {

    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements of the view, for later use
     */
    cacheDOM() {
        this.specialtyChapterBorderElm = document.querySelector(`.${CLASSES.SPECIALTY_CHAPTER_BORDER}`);
        this.specialtyChapterBorderInnerElm =
            this.specialtyChapterBorderElm.querySelector(`.${CLASSES.SPECIALTY_CHAPTER_BORDER_INNER}`);
    }

    /**
     * @method populateTemplate
     * @description pupulate the base chapter DOM based on the given data
     */
    populateTemplate() {

    }

    /**
     * @method render
     * @description renders to the DOM and begins chapter playin animations
     */
    render() {
        renderer.insert(this.element, this.specialtyChapterBorderInnerElm);
        this.beginPlayin();
    }

    /**
     * @method beginPlayin
     * @description begins chapter playin animations
     */
    beginPlayin() {
        Object.entries(this.animationElements).forEach((animator) => {
            animator[1].beginPlayin();
        });
        this.dispatchPlayinBegun();
    }

    /**
     * @method beginPlayout
     * @description begins chapter playout animations
     */
    beginPlayout() {
        Object.entries(this.animationElements).forEach((animator) => {
            if (animator[1].isActive) {
                animator[1].beginPlayout();
            }
        });
        this.dispatchPlayoutBegun();
    }

    /**
     * @method triggerSizeViolationModal
     * @description shows the height violation message modal
     */
    triggerSizeViolationModal() {
        this.violationModalOpen = true;
        if (this.onBeforeViolationModalOpen && typeof this.onBeforeViolationModalOpen === 'function') {
            this.onBeforeViolationModalOpen();
        } else {
            this.openSizeViolationModal();
        }
    }

    /**
     * @method openSizeViolationModal
     * @description shows the height violation message modal
     */
    openSizeViolationModal() {
        this.violationModalElm = minSizeViolationTemplate(
            this.localization
        )({ getNode: true });

        this.violationModal = new Modal(undefined, {
            modalContent: this.violationModalElm,
            callbacks: {
                afterOpen: this.onAfterViolationModalOpen,
                beforeClose: this.onBeforeViolationModalClose
            },
            theme: CLASSES.MODAL_SIZE_VIOLATION,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.FULLSCREEN,
            transitionInOut: true,
            unclosable: true
        });
        this.violationModal.open();
    }

    /**
     * @method closeSizeViolationModal
     * @description closes the height violation message modal
     */
    closeSizeViolationModal() {
        this.violationModalOpen = false;
        this.violationModal.destroy();
        this.violationModal.close();
    }

    /**
     * @method onAfterViolationModalOpen
     * @description callback method for after min height violation modal opens
     */
    onAfterViolationModalOpen() {
        const copy = this.violationModalElm.querySelector(`.${CLASSES.SIZE_VIOLATION_COPY}`);
        copy.focus();
    }

    /**
     * @method onBeforeViolationModalClose
     * @description callback method for when min height violation modal will close
     */
    onBeforeViolationModalClose() {

    }

    /**
     * @method attachEvents
     * @description helper method to attach events for the component
     */
    attachEvents() {
        screen.addResizeListener(this.onScreenResize);
        screen.addResizeListener(this.onScreenResizeForViolation);
        customEventDispatcher.addEventListener(CUSTOM_EVENTS.SPECIALTY_PAGE_HASH_UPDATED, this.onHashChange);
        customEventDispatcher.addEventListener(CUSTOM_EVENTS.GLOBAL_NAV_MENU_OPENED, this.onGlobalHeaderOpen);
        customEventDispatcher.addEventListener(CUSTOM_EVENTS.GLOBAL_NAV_MENU_CLOSED, this.onGlobalHeaderClosed);
        customEventDispatcher.addEventListener(CUSTOM_EVENTS.DOCKED_FOOTER.OPEN, this.onDockedFooterOpen);
        customEventDispatcher.addEventListener(CUSTOM_EVENTS.DOCKED_FOOTER.CLOSE, this.onDockedFooterClosed);

        if (this.isiOSMobile) {
            window.addEventListener(EVENTS.ORIENTATIONCHANGE, this.oniOSOrientationChange);
        }
    }

    /**
     * @method detachEvents
     * @description helper method to detach events for the component
     */
    detachEvents() {
        screen.removeResizeListener(this.onScreenResize);
        screen.removeResizeListener(this.onScreenResizeForViolation);
        customEventDispatcher.removeEventListener(CUSTOM_EVENTS.SPECIALTY_PAGE_HASH_UPDATED, this.onHashChange);
        customEventDispatcher.removeEventListener(CUSTOM_EVENTS.GLOBAL_NAV_MENU_OPENED, this.onGlobalHeaderOpen);
        customEventDispatcher.removeEventListener(CUSTOM_EVENTS.GLOBAL_NAV_MENU_CLOSED, this.onGlobalHeaderClosed);
        customEventDispatcher.removeEventListener(CUSTOM_EVENTS.DOCKED_FOOTER.OPEN, this.onDockedFooterOpen);
        customEventDispatcher.removeEventListener(CUSTOM_EVENTS.DOCKED_FOOTER.CLOSE, this.onDockedFooterClosed);

        if (this.isiOSMobile) {
            window.removeEventListener(EVENTS.ORIENTATIONCHANGE, this.oniOSOrientationChange);
        }
    }

    /**
     * @method onAnimElemPlayinComplete
     * @description callback method for AnimationElements
     */
    onAnimElemPlayinComplete() {
        let elementsComplete = true;
        Object.entries(this.animationElements).forEach((animator) => {
            if (animator[NAME_VALUE.VALUE].currentState !== AnimationElement.STATES.SETTLED_IN) {
                elementsComplete = false;
            }
        });
        if (elementsComplete) {
            this.dispatchPlayinComplete();
            this.onScreenResizeForViolation();
        }
    }

    /**
     * @method onAnimElemPlayoutComplete
     * @description callback method for AnimationElements
     * @param animElemId {String} identifier for completed AnimationElement
     * @param keepAnimation {bool} indicates if the animation should be keep
     */
    onAnimElemPlayoutComplete(animElemId, keepAnimation) {
        if (!keepAnimation) {
            delete this.animationElements[animElemId];
        }

        if (validate.isEmpty(this.animationElements)) {
            this.destroy();
        } else {
            let elementsComplete = true;
            Object.entries(this.animationElements).forEach((animator) => {
                if (animator[NAME_VALUE.VALUE].isActive) {
                    elementsComplete = false;
                }
            });
            if (elementsComplete) {
                this.destroy();
            }
        }
    }

    /**
     * @method onBatchAssetLoaderStatusUnsent
     * @description callback method for BatchAssetLoader readystatechange event
     * @param loadingAsset {Object} data object for loading asset
     */
    onBatchAssetLoaderStatusUnsent(/* loadingAsset */) {
//        console.log('\nonBatchAssetLoaderStatusUnsent', loadingAsset);
    }

    /**
     * @method onBatchAssetLoaderStatusOpened
     * @description callback method for BatchAssetLoader readystatechange event
     * @param loadingAsset {Object} data object for loading asset
     */
    onBatchAssetLoaderStatusOpened(/* loadingAsset */) {
//        console.log('\nonBatchAssetLoaderStatusOpened', loadingAsset);
    }

    /**
     * @method onBatchAssetLoaderStatusHeadersReceived
     * @description callback method for BatchAssetLoader readystatechange event
     * @param loadingAsset {Object} data object for loading asset
     * @param headers {Map} Map of all response headers
     */
    onBatchAssetLoaderStatusHeadersReceived(/* loadingAsset, headers */) {
//        console.log('\nonBatchAssetLoaderStatusHeadersReceived', loadingAsset, headers);
    }

    /**
     * @method onBatchAssetLoaderProgress
     * @description callback method for BatchAssetLoader progress event
     * @param loadingAsset {Object} data object for loading asset
     */
    onBatchAssetLoaderProgress(/* loadingAsset */) {
//        console.log('\nonBatchAssetLoaderProgress', loadingAsset);
    }

    /**
     * @method onBatchAssetLoaderStatusDone
     * @description callback method for BatchAssetLoader readystatechange event
     * @param loadingAsset {Object} data object for loading asset
     */
    onBatchAssetLoaderStatusDone(/* loadingAsset */) {
//        console.log('\nonBatchAssetLoaderStatusDone', loadingAsset);
//        console.log('batchAssetLoader.currentBatchStatus = ', this.batchAssetLoader.currentBatchStatus);
    }

    /**
     * @method onBatchAssetLoaderAbort
     * @description callback method for BatchAssetLoader abort event
     * @param e {ProgressEvent} abort event
     */
    onBatchAssetLoaderAbort(e) {
        console.log('\nonBatchAssetLoaderAbort', e);
    }

    /**
     * @method onBatchAssetLoaderError
     * @description callback method for BatchAssetLoader error
     * @param failedAsset {Object} data object for failed asset
     */
    onBatchAssetLoaderError(failedAsset) {
        console.log('\nonBatchAssetLoaderError', failedAsset);
    }

    /**
     * @method onBatchAssetLoaderComplete
     * @description callback method for BatchAssetLoader full load complete
     */
    onBatchAssetLoaderComplete() {
        this.exitChapterLoadingState();
    }

    /**
     * @method onScreenResize
     * @description Handles screen resize events
     */
    onScreenResize() {

    }

    /**
     * @method onGlobalHeaderOpen
     * @description Handles custom global header open event
     */
    onGlobalHeaderOpen() {
        if (screen.getCurrentState() === screen.SIZES.SMALL) {
            screen.removeResizeListener(this.onScreenResizeForViolation);
        }
    }

    /**
     * @method onGlobalHeaderClosed
     * @description Handles custom global header close event
     */
    onGlobalHeaderClosed() {
        screen.removeResizeListener(this.onScreenResizeForViolation);
        screen.addResizeListener(this.onScreenResizeForViolation);
        this.onScreenResizeForViolation();
    }

    /**
     * @method onDockedFooterOpen
     * @description Handles custom footer open event
     */
    onDockedFooterOpen() {
        if (screen.getCurrentState() === screen.SIZES.SMALL) {
            screen.removeResizeListener(this.onScreenResizeForViolation);
        }
    }

    /**
     * @method onDockedFooterClosed
     * @description Handles custom footer close event
     */
    onDockedFooterClosed() {
        screen.removeResizeListener(this.onScreenResizeForViolation);
        screen.addResizeListener(this.onScreenResizeForViolation);
        this.onScreenResizeForViolation();
    }

    /**
     * @method oniOSOrientationChange
     * @description when the orientation changes on an iOS device, delay calling onScreenResizeForViolation
     *              to allow for iOS browsers (exception: safari) to accurately report window.innerHeight dimensions
     */
    oniOSOrientationChange() {
        setTimeout(this.onScreenResizeForViolation, LOCAL_CONSTANTS.TIMEOUT_INTERVAL);
    }

    /**
     * @method onScreenResizeForViolation
     * @description Handles screen resize events
     */
    onScreenResizeForViolation() {
        const newScreenCurrentState = screen.getCurrentState();
        const violationH = this.configuration.heightViolations[newScreenCurrentState];
        if (!this.violationModalOpen &&
            window.innerHeight <= violationH) {
            this.triggerSizeViolationModal();
        } else if (this.violationModalOpen &&
                   window.innerHeight > violationH) {
            this.closeSizeViolationModal();
        }
    }

    /**
     * @method onHashChange
     * @description  helper method to attach events for the component
     */
    onHashChange({ detail }) {
        if (detail.nextChapterData.deepLink !== this.chapterData.deepLink) {
            this.beginPlayout();
        }
    }

    /**
    * @method dispatchPlayinBegun
    * @description dispatch a LIFECYCLE_TRANSITION event
     */
    dispatchPlayinBegun() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.LIFECYCLE_TRANSITION,
                {
                    detail: {
                        transitionStage: TRANSITION_STAGES.PLAYIN_BEGUN
                    }
                }
            )
        );
    }

    /**
    * @method dispatchPlayinComplete
    * @description dispatch a LIFECYCLE_TRANSITION event
     */
    dispatchPlayinComplete() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.LIFECYCLE_TRANSITION,
                {
                    detail: {
                        transitionStage: TRANSITION_STAGES.PLAYIN_COMPLETE
                    }
                }
            )
        );
    }

    /**
    * @method dispatchPlayoutBegun
    * @description dispatch a LIFECYCLE_TRANSITION event
     */
    dispatchPlayoutBegun() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.LIFECYCLE_TRANSITION,
                {
                    detail: {
                        transitionStage: TRANSITION_STAGES.PLAYOUT_BEGUN
                    }
                }
            )
        );
    }

    /**
    * @method dispatchPlayoutComplete
    * @description dispatch a LIFECYCLE_TRANSITION event
     */
    dispatchPlayoutComplete() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.LIFECYCLE_TRANSITION,
                {
                    detail: {
                        transitionStage: TRANSITION_STAGES.PLAYOUT_COMPLETE
                    }
                }
            )
        );
    }

    /**
     * @method destroy
     * @description destroy the component
     */
    destroy() {
        AnimationElement.playinFinish = null;
        AnimationElement.playoutFinish = null;
        this.detachEvents();
        this.element.remove();
        this.dispatchPlayoutComplete();
    }
}

BaseChapter.defaultConfig = {
    heightViolations: {
        small: 550,
        large: 920,
        xlarge: 600,
        xxlarge: 600
    }
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom radio button control
 * Uses ES6 template literal to composite the markup for a custom radio button control
 */
export default ({ id = '', name = '', ariaLabel = '', dataAttributes = '', inputClassNames = '', labelClassNames = '',
    checked = false, valueText = '', labelText = '', analyticsTrigger = '', disclaimer = '', groupId = '' }) => htmlNode`
    <div class="form__radio-control-container ${inputClassNames ? `${inputClassNames}` : ''}">
        <label ${id ? `for="${id}"` : ''} class="form__radio-control">
            <span class="form__radio-label ${labelClassNames}">
                ${labelText}${disclaimer ? ` <sup aria-label="${disclaimer.label}">${disclaimer.legend}</sup>` : ''}
            </span>
            <input class="form__radio"
                type="radio"
                ${checked ? ' checked' : ''}
                ${id ? `id="${id}"` : ''}
                ${name ? `name="${name}"` : ''}
                ${dataAttributes ? ` ${dataAttributes}` : ''}
                ${valueText ? ` value="${valueText}"` : ''}
                ${analyticsTrigger ? ` data-analytics-trigger="${analyticsTrigger}"` : ''}
                ${labelText || ariaLabel ? `aria-label="${ariaLabel || labelText}"` : ''}
                ${groupId ? ` aria-labelledby="${groupId}"` : ''}
            />
            <div class="form__radio-indicator"></div>
        </label>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

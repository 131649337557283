// Constant dependencies
import { EVENTS, CUSTOM_EVENTS } from 'Constants';

// Util dependencies
import { customEventDispatcher } from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {
 *     BUTTON: String,
 *     DISCLAIMER: String,
 *     TILE_IMAGE_CONTAINER: String,
 *     TRIGGER_LINK: String
 * }
 */
const CLASSES = {
    BUTTON: 'multimedia-tile__button',
    DISCLAIMER: 'disclaimer__legend-reference',
    TILE_IMAGE_CONTAINER: 'content-tile__image-container',
    TRIGGER_LINK: 'multimedia-tile__slideshow-btn'
};

/**
 * @const ATTRIBUTES
 * @description constant for the attribute used to find grid container element
 * @type {
 *     HREF: String,
 *     ID: String,
 *     TARGET: String
 * }
 */
const ATTRIBUTES = {
    HREF: 'href',
    ID: 'id',
    TARGET: 'target'
};

export default class MultiMediaTile {
    /**
     * @method constructor
     * @description Instantiates the MultiMediaTile Module
     * @param element {Object} DOM representation of tile
     */
    constructor(element) {
        this.element = element;
        this.init();
    }

    /**
     * @method init
     * @description  this method executed exactly after all the properties
     * have been setup by default.
     */
    init() {
        this.setBindings();
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.onLinkButtonClick = this.onLinkButtonClick.bind(this);
        this.triggerCustomEvent = this.triggerCustomEvent.bind(this);
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.linkButton = this.element.querySelector(`.${CLASSES.BUTTON}`);
        this.triggerElm = this.element.querySelector(`.${CLASSES.TRIGGER_LINK}`);
    }

    /**
     * @method attachEvents
     * @description Attach event listeners
     */
    attachEvents() {
        if (this.linkButton) {
            this.linkButton.addEventListener(EVENTS.CLICK, this.onLinkButtonClick);
        }
        if (this.triggerElm) {
            this.triggerElm.addEventListener(EVENTS.CLICK, this.triggerCustomEvent);
        }
    }

    /**
     * @method attachEvents
     * @description Detach event listeners
     */
    detachEvents() {
        if (this.linkButton) {
            this.linkButton.removeEventListener(EVENTS.CLICK, this.onLinkButtonClick);
        }
        if (this.triggerElm) {
            this.triggerElm.removeEventListener(EVENTS.CLICK, this.triggerCustomEvent);
        }
    }

    /**
     * @method onLinkButtonClick
     * @description Handler for button click events
     * @param {Event} e Event object
     */
    onLinkButtonClick(e) {
        const href = this.linkButton.getAttribute(ATTRIBUTES.HREF);

        if (e.target.classList.contains(CLASSES.DISCLAIMER)) {
            return;
        }
        if (this.linkButton.getAttribute(ATTRIBUTES.TARGET) === '_blank') {
            window.open(href, '_blank', 'noreferrer');
        } else {
            window.location.href = href;
        }
    }

    /**
     * @method triggerCustomEvent
     * @description Triggers an event so that the container can do whatever it needs to do;
     * passes the id of the image container
     * @param {Event} e Custom Event object
     */
    triggerCustomEvent(e) {
        if (!e.target.classList.contains(CLASSES.DISCLAIMER)) {
            const imageContainer = this.element.querySelector(`.${CLASSES.TILE_IMAGE_CONTAINER}`);
            let imageContainerId;
            if (imageContainer) {
                imageContainerId = imageContainer.getAttribute(ATTRIBUTES.ID);
                customEventDispatcher.dispatchEvent(
                    customEventDispatcher.createCustomEvent(
                        CUSTOM_EVENTS.CONTENT_TILE_VEHICLE_OVERLAY_BUTTON_TRIGGER,
                        {
                            detail: {
                                tile: this.element.parentElement,
                                imageContainer: imageContainerId
                            }
                        }
                    )
                );
            }
        }
    }

    /**
     * @method destroy
     * @description Destroys the module by detaching its events
     */
    destroy() {
        this.detachEvents();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode, validate } from 'utils';

/**
 * @description Template for custom select control
 * Uses ES6 template literal to composite the markup for a custom select control
 */
export default ({
        heading = 'This Is a Simple Form',
        callout = '',
        cssClass = '', id = '',
        submitLabel = 'Submit',
        parentClass = 'simple-form',
        trigger = ''
    }) => htmlNode`
    <section class="simple-form module-separator ${cssClass}" id="${id}">
        <form class="${parentClass}__form" novalidate="true">
          <fieldset>
            <legend class="${parentClass}__heading">${heading}</legend>
            ${(validate.isString(callout) && !validate.isEmpty(callout.trim())) ? `<p class="${parentClass}__callout">${callout}</p>` : ''}
            <div class="error-message-display" data-error-message=""></div>
            <div class="${parentClass}__inputs-wrapper"></div>
            <button class="button button_primary button--wide ${parentClass}__submit" data-analytics-trigger="${trigger}">${submitLabel}</button>
          </fieldset>
        </form>
    </section>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

export default ({ ctaLabel, buttonStyle, sectionHeading }) => htmlNode`
    <form onSubmit="return false;">
        <h3 class="preferred-dealer-plugin__sub-heading">${sectionHeading}</h3>
        <div class="dealer-search-bar">
            <div class="dealer-search-bar__location-search" data-location-search=""></div>
            <div class="dealer-search-bar__cta">
                <button type="button" class="${buttonStyle}" data-cta-button="" data-analytics-trigger="cta">
                    ${ctaLabel}
                </button>
            </div>
        </div>
    </form>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Api responsible for searching for inventory vehicles by dealer and filter data
 */

// Import utils
import {
    serializeObject,
    uppercaseWorker,
} from 'utils';

// Partial dependencies
import { dealerLocatorApi } from 'partials/dealer-locator-api';
import { vehicleImagesApi } from 'partials/vehicle-image';

// Local dependencies
import {
    createDealerObject,
    createImagesArray,
    createMediaObjectFromPath,
    createMileageObject,
    createPricingObject
} from './inventorySearchUtils';

/**
 * @method createMediaObject
 * @description Creates object with format needed for media object
 * @param {Object} vehicle - vehicle info
 * @param {Object} modelInfo - Model Info object
 * @param {String} imageTokenizedPath - image path with tokens to be replaced with vehicle data to create the image path
 * @return {Object}
 */
function createMediaObject(vehicle, modelInfo, imageTokenizedPath) {
    const basePath = imageTokenizedPath.split('|')[0];
    const imagePath = vehicleImagesApi.getImageUrl({
        basePath,
        classCode: vehicle.classId,
        modelCode: vehicle.modelId,
        roof: modelInfo.roofHeight.value,
        color: vehicle.paint.id,
        angle: 'PS', // Passenger Side (3/4)
    });

    return {
        alt: '',
        imgS: imagePath,
        imgL: imagePath
    };
}

/**
 * @method parseVehicle
 * @description Parses the vehicle object
 * @param {Object} vehicle - vehicle object to parse
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {Object} imagePath - Image path object
 * @param {Object} modelInfo - Model Info object
 * @param {boolean} hasFeatureMatch - whether there are feature filters selected
 * @return {Object} Object containing vehicles (array of vehicles from the vehiclesResponse object) and
 * dealersMatch (object of current and neighbor dealers relevance)
 */
function parseVehicle(vehicle, config, imagePath, modelInfo, hasFeatureMatch) {
    const defaultImage = vehicle.classId === 'METRIS' ? imagePath.metris : imagePath.default;
    const imageTokenizedPath = imagePath.imageTokenizedPaths && imagePath.imageTokenizedPaths[`${vehicle.classId}-${vehicle.bodyStyleId}`];
    vehicle.msrp = createPricingObject(config, vehicle.msrp);
    vehicle.fallbackImage = defaultImage;
    vehicle.exteriorBaseImage =
        imageTokenizedPath ?
            createMediaObject(vehicle, modelInfo, imageTokenizedPath) :
            createMediaObjectFromPath(defaultImage);
    vehicle.hasFeatureMatch = hasFeatureMatch;
    vehicle.hasMSRP = vehicle.msrp !== 999996;
    return vehicle;
}

/**
 * @method parseVehiclesData
 * @description Parses the vehicle search response to return records. Also modifies msp to have
 * pricing object
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {Object} imagePath - Image path object
 * @param {Object} filters - Applied filters object
 * @param {Object} modelInfo - Model Info object
 * @param {Object} vehiclesResponse - Response object from API
 * @return {Object} Object containing parsed vehicles and dealer info
 */
function parseVehiclesData(config, imagePath, filters, modelInfo, vehiclesResponse) {
    const hasFeatureMatch = filters.feature || filters.notMappedFeature;

    try {
        let vehiclesByDealer = vehiclesResponse.result;

        vehiclesByDealer = vehiclesByDealer.map((result) => {
            const dealer = result.dealer ? dealerLocatorApi.formatDealer(result.dealer.dealerInfo) : {};
            const vehicles = result.vehicles.map((vehicle) =>
                parseVehicle(vehicle, config, imagePath, modelInfo, hasFeatureMatch)
            );

            return {
                dealer,
                vehicles,
                resultType: result.resultType
            };
        });

        return vehiclesByDealer;
    } catch (e) {
        console.log('Error while parsing vehicles response', e);
        return [];
    }
}

/**
 * method parseCPOVehiclesData
 * @description Parses the cpo vehicle search response to returned records and constructs
 * necessary properties
 * @param {Object} config - Configuration data to get country/language/currency
 * @param {String} imagePath - Image path object
 * @param {Object} location - User's search location object
 * @param {Object} vehiclesResponse - Response object from API
 * @return {Array} Array of vehicles from the vehiclesResponse object
 */
function parseCPOVehiclesData(config, imagePath, location, vehiclesResponse) {
    try {
        const vehicles = vehiclesResponse.result.pagedVehicles.records;

        if (vehicles) {
            return vehicles.map((vehicle) => {
                const defaultImage = vehicle.bodyStyleName === 'SUV' ? imagePath.fallBackImage.suv : imagePath.fallBackImage.default;
                const vehicleObj = {
                    ...vehicle,
                    ...vehicle.usedVehicleAttributes,
                    available: true,
                    dealer: createDealerObject(config, vehicle.usedVehicleAttributes.dealer),
                    exteriorBaseImage: createMediaObjectFromPath(vehicle.usedVehicleAttributes.images[0]),
                    exteriorColor: vehicle.paint && vehicle.paint.name,
                    fallbackImage: (defaultImage),
                    hasHomeDelivery: vehicle.usedVehicleAttributes.deliveryOption === 'B' ||
                    vehicle.usedVehicleAttributes.deliveryOption === 'D',
                    hasHomeTestDrive: vehicle.usedVehicleAttributes.deliveryOption === 'B' ||
                    vehicle.usedVehicleAttributes.deliveryOption === 'T',
                    hasMSRP: vehicle.msrp !== 999996,
                    historyReport: {
                        isEnabled: vehicle.usedVehicleAttributes.historyReport,
                        linkoutURL: vehicle.usedVehicleAttributes.historyReportUrl
                    },
                    interiorColor: vehicle.upholstery && vehicle.upholstery.name,
                    images: createImagesArray(vehicle.usedVehicleAttributes.images, defaultImage),
                    msrp: createPricingObject(config, vehicle.msrp),
                    mileage: createMileageObject(
                        config,
                        vehicle.usedVehicleAttributes.mileage,
                        vehicle.usedVehicleAttributes.mileageUnit),
                    name: vehicle.usedVehicleAttributes.headerText,
                    optionList: vehicle.usedVehicleAttributes.optionList,
                    reservePrice: vehicle.usedVehicleAttributes.reservePrice ?
                        createPricingObject(config, vehicle.usedVehicleAttributes.reservePrice) :
                        null,
                    searchLocation: { ...location }
                };

                delete vehicleObj.usedVehicleAttributes;

                // uppercase WORKER
                [
                    'descriptionLabel',
                    'headerText',
                    'modelName',
                    'name',
                ].forEach((prop) => {
                    vehicleObj[prop] = uppercaseWorker(vehicleObj[prop]);
                });

                return vehicleObj;
            });
        }

        return vehicles;
    } catch (e) {
        console.log('Error while parsing vehicles response', e);
        return [];
    }
}


/**
 * @method getVehicles
 * @description Fetches the available vehicles for the provided model & dealer combo
 * @param {Object} config - Configuration object { endpoint }
 * @param {String} dealerId - Dealer ID of the currently selected dealer
 * @param {Object} filters - applied filters object
 * @param {String} sortBy - sort by value
 * @param {Object} modelInfo - Model Info object
 * @param {Object} imagePath - Image Fallback Path Object
 * @param {Boolean} currentDealerOnly - Indicator for whether to include only current dealer vehicles
 * @param {Boolean} shared - Indicator to include shared dealer P4 results
 * @return {Promise} A promise which resolves with data of list of vehicles
 */
function getVehicles(config, dealerId, filters, sortBy, modelInfo, imagePath, currentDealerOnly, shared) {
    const rearDoor = modelInfo.parentId === 'METRIS' && modelInfo.rearDoor ? modelInfo.rearDoor.value : null;
    const requestURLParams = serializeObject({
        brand: config.brand,
        model: modelInfo.id,
        dealerId,
        start: config.start,
        count: config.count,
        minPrice: filters.minPrice,
        maxPrice: filters.maxPrice,
        year: filters.year,
        exterior: filters.exterior,
        interior: filters.interior,
        feature: filters.feature,
        notMappedFeature: filters.notMappedFeature,
        rearDoor,
        roofHeight: modelInfo.roofHeight && modelInfo.roofHeight.value,
        sortBy,
        distance: filters.distance,
        currentDealerOnly,
        shared,
    });

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('getVehicles error');
            }
        })
        .then(parseVehiclesData.bind(null, config, imagePath, filters, modelInfo));
}

/**
 * @method getCPOVehicles
 * @description Fetches the available vehicles for the provided model & location combo
 * @param {Object} config - Configuration object { endpoint }
 * @param {Object} locationDistance - Location info containing `distance`, `location` properties
 * @param {Object} filters - filters object
 * @param {Object} modelInfo - Model Info object
 * @param {String|null} sortBy
 * @param {Boolean} includeAllPreOwned - Indicator for whether to include all pre-owned vehicles
 * @param {Boolean} includeReserveOnly - Indicator for whether to include only reservable vehicles
 * @param {Object} imagePath - Image Fallback Path Object
 * @param {Boolean} currentDealerOnly - Indicator for whether to include only current dealer vehicles
 * @param {String} dealerId - Dealer ID of the currently selected dealer
 * @return {Promise} A promise which resolves with data of list of vehicles
 */
function getCPOVehicles(
    config,
    locationDistance,
    filters,
    modelInfo,
    sortBy,
    includeAllPreOwned,
    includeReserveOnly,
    imagePath,
    currentDealerOnly,
    dealerId
) {
    const {
        location,
        distance
    } = locationDistance;

    const rearDoor = modelInfo.parentId === 'METRIS' && modelInfo.rearDoor ? modelInfo.rearDoor.value : null;

    const params = {
        brand: config.brand,
        bodystyle: modelInfo.bodystyle,
        count: config.count,
        driveTrain: filters.driveTrain,
        exterior: filters.exterior,
        fuelType: filters.fuelType,
        interior: filters.interior,
        invType: includeAllPreOwned ? 'all' : null,
        lat: location.type === 'place' ? location.lat : null,
        lng: location.type === 'place' ? location.lng : null,
        maxMileage: filters.maxMileage,
        maxPrice: filters.maxPrice,
        maxYear: filters.maxYear || config.invYears.maxInvYear,
        minMileage: filters.minMileage,
        minPrice: filters.minPrice,
        minYear: (
            +filters.minYear === +config.invYears.minInvYear
                ? config.invYears.minApiInvYear
                : filters.minYear || config.invYears.minApiInvYear
        ),
        model: modelInfo.id.join(),
        resvOnly: includeReserveOnly,
        powertrain: modelInfo.powertrain && modelInfo.powertrain.value,
        rearDoor,
        roofHeight: modelInfo.roofHeight && modelInfo.roofHeight.value,
        sortBy,
        start: config.start,
        zip: location.type === 'zip' ? location.value : null,
    };

    if (currentDealerOnly && dealerId) {
        params.currentDealerOnly = currentDealerOnly;
        params.dealerId = dealerId;
    } else {
        params.distance = distance;
    }

    const requestURLParams = serializeObject(params);

    return fetch(`${config.endpoint}${requestURLParams}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(parseCPOVehiclesData.bind(null, config, imagePath, location));
}


// Exports public api methods
export default {
    getCPOVehicles,
    getVehicles
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

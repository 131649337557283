// utils
import {
    storage,
    formatNumber
} from 'utils';

// Constants
import {
    STORAGE_KEYS
} from 'Constants';
import PaymentEstimatorConstants from '../constants/PaymentEstimatorConstants';

const { INPUT_SOURCE } = PaymentEstimatorConstants;

/**
 * @function constructPaymentOptions
 * @description Construct payment options based on vehicle pricing
 * @param {Object} options - Default options
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @param {string} paymentType - Estimate Type
 * @returns {Object} Options object
 */
function constructPaymentOptions(options, vehicleBuild, paymentType) {
    const msrp = vehicleBuild.pricing.total.price;
    const downPayment = options.downPayment !== null ?
        options.downPayment : options.defaultDownPayment;
    let type = paymentType;

    if (paymentType !== 'lease') {
        if (options.isBalloon) { // paymentType equals FINANCE
            type = 'balloon';
        } else {
            type = 'retail';
        }
    }

    return {
        ...options,
        msrp,
        type,
        downPayment
    };
}

/**
 * @function constructRequest
 * @description Constructs the request object
 * @param {Object} config - Config from store
 * @param {Object} options - Options from store
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @return {Object} Request object
 */
function constructRequest(config, options, vehicleBuild) {
    const valuesSource = options.inputSource === 'specialOffer' && options.specialOffer
        ? options.specialOffer
        : options;
    const {
        apr,
        term,
        mileage,
    } = valuesSource;

    let deliveryFee = null;

    if (config.deliveryFee && vehicleBuild) {
        deliveryFee = parseInt(JSON.parse(config.deliveryFee)[`${vehicleBuild.class}-${vehicleBuild.year}`], 10);
    }

    let aprValue = '';

    if (!options.disableAPR) {
        const isFinance = options.type === PaymentEstimatorConstants.FINANCE ||
            options.type === PaymentEstimatorConstants.RETAIL;

        const defaultApr = isFinance ? 0 : '';

        aprValue = !apr ? defaultApr : parseFloat(apr).toFixed(2);

        if (aprValue === 'NaN') {
            aprValue = '';
        }
    }

    return {
        paymentEstimatorNewRequest: {
            deviceId: config.deviceId,
            deviceLanguage: config.language.toUpperCase(),
            deviceType: 'Phone',
            mymbfsVersion: config.myMBFSVersion,
            networkType: config.networkType,
            osDetail: window.navigator.userAgent,
            requestor: config.requestor,
            sessionId: '',
            systemId: config.systemId,
            estimatorType: config.byoEstimatorType, // Defaulting to S for BYO
            maxMonthlyPayment: '',
            mileage,
            modelClass: vehicleBuild.class,
            modelCode: vehicleBuild.model,
            modelName: (vehicleBuild.vehicleName || vehicleBuild.modelName || '').replace(/["″]/g, ''),
            modelYear: vehicleBuild.year,
            msrp: deliveryFee ? options.msrp + deliveryFee : options.msrp,
            downPayment: options.downPayment,
            term,
            tradeInValue: options.tradeValue,
            type: options.type,
            vehicleID: '',
            apr: aprValue,
            zipCode: ''
        }
    };
}

/**
 * @function setErrorState
 * @description Sets the error state to true
 * @return {Object} Error object
 */
function setErrorState(response) {
    const status = response.paymentEstimatorNewResponse.status || {};
    const hasNoModelDataServerError = status.statusCode && Number(status.statusCode) === 6003;

    return {
        isLoading: false,
        hasError: true,
        hasNoModelDataServerError,
    };
}

/**
 * @function parseResponse
 * @description Parses response for payment estimator API and normalizes data
 * @param {Object} config - Config from store
 * @param {Object} options - Payment Estimator Options
 * @param {Object} response - Response from service
 * @return {Object} normalized estimate response
 */
function parseResponse(config, options, response) {
    if (response &&
        response.paymentEstimatorNewResponse &&
        response.paymentEstimatorNewResponse.status &&
        response.paymentEstimatorNewResponse.status.shortMessage &&
        response.paymentEstimatorNewResponse.status.shortMessage === 'SUCCESS'
    ) {
        const estimate = response.paymentEstimatorNewResponse.paymentEstimator[0];
        return {
            ...estimate,
            isLoading: false,
            hasError: false,
            totalDueAtSigning: {
                value: parseFloat(estimate.totalDueAtSigning),
                formattedValue: formatNumber.toCurrency(
                    parseFloat(estimate.totalDueAtSigning),
                    config.country,
                    config.language,
                    config.currency,
                    config.currencyDecimalDigits,
                    config.currencyDecimalDigits)
            },
            estimatedMonthlyPayment: {
                value: parseFloat(estimate.estimatedMonthlyPayment),
                formattedValue: formatNumber.toCurrency(
                    parseFloat(estimate.estimatedMonthlyPayment),
                    config.country,
                    config.language,
                    config.currency,
                    config.currencyDecimalDigits,
                    config.currencyDecimalDigits)
            },
            downPayment: {
                value: parseFloat(estimate.downPayment),
                formattedValue: formatNumber.toCurrency(
                    parseFloat(estimate.downPayment),
                    config.country,
                    config.language,
                    config.currency,
                    config.currencyDecimalDigits,
                    config.currencyDecimalDigits)
            },
            amountFinanced: {
                value: parseFloat(estimate.amountFinanced),
                formattedValue: formatNumber.toCurrency(
                    parseFloat(estimate.amountFinanced),
                    config.country,
                    config.language,
                    config.currency,
                    config.currencyDecimalDigits,
                    config.currencyDecimalDigits)
            },
            finalPayment: {
                value: parseFloat(estimate.finalPayment),
                formattedValue: formatNumber.toCurrency(
                    parseFloat(estimate.finalPayment),
                    config.country,
                    config.language,
                    config.currency,
                    config.currencyDecimalDigits,
                    config.currencyDecimalDigits)
            },
            mileage: {
                value: parseFloat(options.mileage),
                formattedValue: formatNumber.toStringNumber(
                    parseFloat(options.mileage),
                    config.country,
                    config.language)
            },
            tradeValue: {
                value: parseFloat(options.tradeValue),
                formattedValue: formatNumber.toCurrency(
                    parseFloat(options.tradeValue),
                    config.country,
                    config.language,
                    config.currency,
                    config.currencyDecimalDigits,
                    config.currencyDecimalDigits)
            },
            apr: {
                value: parseFloat(estimate.apr).toFixed(2),
                formattedValue: formatNumber.toPercentage(
                    (parseFloat(estimate.apr) / 100),
                    config.country,
                    config.language
                )
            }
        };
    }

    // If response is not valid, go to error flow
    return setErrorState(response);
}

/**
 * @function getPaymentEstimate
 * @description Gets the payment estimate based on the payment data
 * @param {Object} config - Config from store
 * @param {Object} options - Options from store
 * @param {Object} vehicleBuild - Vehicle Build Object
 * @param {string} paymentType - Estimate Type
 * @returns {Object} Promise which resolves with response
 */
function getPaymentEstimate(config, options, vehicleBuild, paymentType) {
    options = constructPaymentOptions(options, vehicleBuild, paymentType);
    const requestBody = JSON.stringify(constructRequest(config, options, vehicleBuild));

    return fetch(config.serviceEndpoint, {
        body: requestBody,
        headers: {
            appID: config.eaiAppId,
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST',
        credentials: 'same-origin'
    })
        .then((response) => response.json())
        .then((response) => parseResponse(config, options, response));
}

/**
 * @function fetchModels
 * @description Construct payment options based on vehicle pricing
 * @param {string} endpoint - Endpoint URL
 * @returns {Promise} Fetch promise
 */
export const fetchModels = (endpoint) =>
    fetch(endpoint).then((response) => response.json());

/**
 * @function findVehiclesByBudget
 * @description Construct payment options based on vehicle pricing
 * @param {string} endpoint - Endpoint URL
 * @param {Object} userParams - User input params to configure the request
 * @param {Object} format - Object with information for formatting by locale
 * @param {Object} vehicleFallbackImage - Object with 2 keys 'sdn' and 'suv' with fallback image urls
 * @returns {Promise} Fetch promise
 */
export const findVehiclesByBudget = (
    endpoint,
    userParams,
    {
        country,
        language,
        currency
    },
    vehicleFallbackImage
) => {
    const requestParams = {
        apr: '0',
        deviceId: '',
        deviceLanguage: '',
        deviceType: 'desktop',
        downPayment: '0',
        estimatorType: '',
        mymbfsVersion: '',
        maxMonthlyPayment: '0',
        mileage: 0,
        modelClass: '',
        modelCode: '',
        modelName: '',
        modelYear: '',
        msrp: 0,
        networkType: '',
        osDetail: window.navigator.userAgent,
        requestor: '',
        sessionId: '',
        systemId: '',
        term: '0',
        tradeInValue: '0',
        type: '',
        vehicleID: '',
        zipCode: ''
    };
    const requestData = {
        ...requestParams,
        ...userParams
    };

    return fetch(endpoint, {
        body: JSON.stringify({ paymentEstimatorNewRequest: requestData }),
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        credentials: 'same-origin',
        method: 'POST'
    })
        .then((response) => response.json())
        .then((response) => {
            const status = response.status;
            // 200 - found results
            // 6000 - no results found
            if (status.code === 200 || status.code === 6000) {
                let results = [];
                if (response.result && Array.isArray(response.result.models)) {
                    results = response.result.models.map(({
                        amountFinanced,
                        apr,
                        byoModel,
                        downPayment,
                        estimatedMonthlyPayment,
                        fallBackImageBodyStyle,
                        image,
                        modelClass,
                        modelCode,
                        modelName,
                        modelYear,
                        msrp,
                        term
                    }) => ({
                        amountFinanced: parseFloat(amountFinanced),
                        amountFinancedFormatted: formatNumber.toCurrency(
                            parseFloat(amountFinanced),
                            country,
                            language,
                            currency
                        ),
                        apr: parseFloat(apr),
                        byo: byoModel,
                        downPayment: parseFloat(downPayment),
                        estimatedMonthlyPayment: parseFloat(estimatedMonthlyPayment),
                        estimatedMonthlyPaymentFormatted: formatNumber.toCurrency(
                            parseFloat(estimatedMonthlyPayment),
                            country,
                            language,
                            currency
                        ),
                        image: {
                            small: image.mobile ? image.mobile.url : '',
                            large: image.desktop ? image.desktop.url : '',
                            alt: modelName,
                            fallback: fallBackImageBodyStyle === 'SDN' ? vehicleFallbackImage.sdn : vehicleFallbackImage.suv,
                            host: PaymentEstimatorConstants.IMAGE_HOST_BBD_TEST.test(
                                image.mobile.url || image.desktop.url
                            ) ? PaymentEstimatorConstants.IMAGE_HOST_BBD : PaymentEstimatorConstants.IMAGE_HOST_MS
                        },
                        modelClass,
                        modelCode,
                        modelName,
                        modelYear: parseInt(modelYear, 10),
                        msrp: parseFloat(msrp),
                        msrpFormatted: formatNumber.toCurrency(
                            parseFloat(msrp),
                            country,
                            language,
                            currency
                        ),
                        term: parseInt(term, 10)
                    }));
                }
                return results;
            } else {
                throw new Error(status.message);
            }
        });
};

/**
 * @method persistUserInput
 * @description Update user input into session storage for data persistence
 * @param {String} financeType Type of finance - Lease / Finance
 * @param {Object} newOptions Data from input fields in the form
 * @param {Object} paymentEstimatorConfig - payment estimator config
 * @return {boolean} Input unchanged
*/
export function persistUserInput(financeType, newOptions, paymentEstimatorConfig) {
    const prevOptions = paymentEstimatorConfig.temp[financeType];
    const newData = {};
    // Verify if user updated any values and only save if values were different than previous
    if (financeType === PaymentEstimatorConstants.FINANCE) {
        if (Number(prevOptions.apr) !== Number(newOptions.apr)) {
            newData.apr = newOptions.apr;
        }
        if (prevOptions.isBalloon !== newOptions.isBalloon) {
            newData.isBalloon = newOptions.isBalloon;
        }
    } else if (prevOptions.mileage !== newOptions.mileage) {
        newData.mileage = newOptions.mileage;
    }
    if (prevOptions.tradeValue !== newOptions.tradeValue) {
        newData.tradeValue = newOptions.tradeValue;
    }
    if (prevOptions.defaultDownPayment !== newOptions.downPayment) {
        newData.downPayment = newOptions.downPayment;
    }
    if (prevOptions.term !== newOptions.term) {
        newData.term = newOptions.term;
    }
    if (prevOptions.inputSource !== newOptions.inputSource) {
        newData.inputSource = newOptions.inputSource;
    }
    if (prevOptions.wasCustomized !== newOptions.wasCustomized) {
        newData.wasCustomized = newOptions.wasCustomized;
    }
    if (JSON.stringify(prevOptions.specialOffer || {}) !== JSON.stringify(newOptions.specialOffer || {})) {
        newData.specialOffer = newOptions.specialOffer;
    }

    const newSessionData = {
        ...(storage.sessionStorage.read(STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA) || {}),
        [PaymentEstimatorConstants.CURRENT_STATE_FIELD_NAME]: financeType,
        [financeType]: newData
    };

    storage.sessionStorage.create(
        STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
        newSessionData
    );

    return true;
}

/**
 * @method persistPaymentType
 * @description Update just the selected payment type in the session
 * @param {String} financeType Type of finance - Lease / Finance
 * @return {boolean} true
*/
export function persistPaymentType(financeType) {
    const newSessionData = {
        ...(storage.sessionStorage.read(STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA) || {}),
        [PaymentEstimatorConstants.CURRENT_STATE_FIELD_NAME]: financeType
    };

    storage.sessionStorage.create(
        STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
        newSessionData
    );

    return true;
}

/**
 * @function clearDownpaymentTradeIn
 * @description clears stored downpayment and tradeIn values for a given payment type
 * @param {string} financeType lease or finance
 * @returns {boolean}
 */
export function clearDownpaymentTradeIn(financeType) {
    const prevSessionData = storage.sessionStorage.read(STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA);
    let newSessionData = {};

    if (prevSessionData) {
        newSessionData = {
            ...prevSessionData,
            [financeType]: {
                ...prevSessionData[financeType],
                downPayment: undefined,
                tradeValue: undefined,
            }
        };
    }

    storage.sessionStorage.create(
        STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA,
        newSessionData
    );

    return true;
}

/**
 * @method parseConfigSession
 * @description Parses valid values from the stored sessions and format to options
 * @param {Object} storedValue User stored values
 * @param {Object} config Configuration data for the forms, used to validate
 * @param {string} type Payment estimator type
 * @return {Object} config options
*/
function parseConfigSession(storedValue, config, type) {
    const options = {};
    const typeOptions = storedValue?.[type] || {};

    options.specialOffer = typeOptions.specialOffer || null;
    options.inputSource = typeOptions.inputSource;
    options.wasCustomized = typeOptions.wasCustomized;
    if (typeOptions.inputSource === INPUT_SOURCE.DEFAULT) {
        if (typeOptions.wasCustomized === undefined) {
            // if wasCustomized has not been set to true or false yet, but is undefined, that may indicate a preexisting
            // session.  We can infer that it is custom if the value is different than those in the config (defaults).
            options.wasCustomized = (
                (type === 'finance' && (
                    (typeOptions.apr !== undefined && typeOptions.apr !== config.apr) ||
                    (typeOptions.term !== undefined && typeOptions.term !== config.financeTerm)
                )) ||
                (type === 'lease' && (
                    (typeOptions.mileage !== undefined && typeOptions.mileage !== config.mileage) ||
                    (typeOptions.term !== undefined && typeOptions.term !== config.leaseTerm)
                ))
            );
        }
        if (options.wasCustomized) {
            options.inputSource = INPUT_SOURCE.CUSTOM;
        }
        if (typeOptions.specialOffer) {
            // special offer is priority
            options.inputSource = INPUT_SOURCE.SPECIAL_OFFER;
        }
    }
    if (+typeOptions.apr >= 0 && config.disableAPR === 'false') {
        options.apr = typeOptions.apr;
    }
    if (typeOptions.term) {
        const configTermOptionsForThisType = config[`${type}TermOptions`].split(',');
        if (configTermOptionsForThisType.includes(String(typeOptions.term))) {
            options[`${type}Term`] = typeOptions.term;
        }
    }
    if (typeOptions.mileage) {
        const mileageOptions = config.leaseAnnualMileageOptions.split(',');
        if (mileageOptions.includes(String(typeOptions.mileage))) {
            options.mileage = typeOptions.mileage;
        }
    }
    if (+typeOptions.downPayment >= 0) {
        options.downPayment = typeOptions.downPayment;
    }
    if (+typeOptions.tradeValue >= 0) {
        options.tradeValue = typeOptions.tradeValue;
    }

    return options;
}

/**
 * @method loadUserInput
 * @description Load user input from a session
 * @param {Object} config Configuration data for the forms, used to validate
 * @param {string} type Payment estimator type
 * @return {Object} restored options
*/
export function loadUserInput(config, type) {
    return parseConfigSession(storage.sessionStorage.read(STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA), config, type);
}

/**
 * @method loadPaymentType
 * @description Load user input from a session
 * @return {String} saved payment type
*/
export function loadPaymentType() {
    const sessionData = storage.sessionStorage.read(STORAGE_KEYS.PAYMENT_ESTIMATOR_DATA);
    if (sessionData) {
        const storedType = sessionData[PaymentEstimatorConstants.CURRENT_STATE_FIELD_NAME];
        if (storedType === PaymentEstimatorConstants.LEASE || storedType === PaymentEstimatorConstants.FINANCE) {
            return storedType;
        }
    }
    return undefined;
}



export default {
    fetchModels,
    findVehiclesByBudget,
    getPaymentEstimate,
    persistUserInput,
    persistPaymentType,
    loadPaymentType,
    loadUserInput
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { googleLocationsApi } from 'partials/google-maps';
import { Observable } from 'partials/observer';

// Utils dependencies
import { isValidZip } from '@mbusa/now-ui-utils.validate';

/**
 * @property initialState
 * @description The initial state of a LocationSearchModel
 * @type {{locations: null}}
 */
const defaultState = {
    locations: null,
    locationValue: {
        type: 'string',
        value: ''
    },
};

/** ///////////////////////////////////////////////////////// */
/** -------------------- PRIVATE METHODS -------------------- */
/** \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

/**
 * @method createPlaceLocation
 * @description Parses google location object to construct a `locationValue` object
 * @param {object} location Google Location object
 * @returns {{type: string, value: string, id: *}}
 * @private
 */
function createPlaceLocation(location) {
    return {
        type: 'place',
        value: `${location.terms[0].value}, ${location.terms[1].value}`,
        id: location.place_id
    };
}

/**
 * @method createZipLocation
 * @description Construct a `locationValue` object from a zip code
 * @param {string} zip Zip code string value
 * @returns {{type: string, value: *}}
 * @private
 */
function createZipLocation(zip) {
    return {
        type: 'zip',
        value: zip
    };
}

/**
 * @method createStringLocation
 * @description Construct a `locationValue` object from a string code
 * @param {string} value String value
 * @returns {{type: string, value: *}}
 * @private
 */
function createStringLocation(value) {
    return {
        type: 'string',
        value
    };
}

/**
 * @method constructLocation
 * @description Constructs a location object based on the locationValue
 * @param locationValue {string|object} A string value or Google Places object
 * @returns {object}
 * @private
 */
function constructLocation(locationValue) {
    let location;

    const isPlace = typeof locationValue === 'object' && Object.prototype.hasOwnProperty.call(locationValue, 'place_id');

    if (isPlace) {
        location = createPlaceLocation(locationValue);
    } else if (isValidZip(locationValue)) {
        location = createZipLocation(locationValue);
    } else {
        location = createStringLocation(locationValue);
    }

    return location;
}

/**
 * @class LocationSearchModel
 * @description A model for managing the state of a LocationSearch
 */
export default class LocationSearchModel extends Observable {
    constructor(initialState = defaultState) {
        super(initialState);
    }

    /**
     * @method setLocation
     * @description Sets the locationValue object based on the type
     * @param locationValue
     */
    setLocation(locationValue) {
        this.state.locationValue = constructLocation(locationValue);
    }

    /**
     * @method getLocations
     * @description Fetches locations from the googleLocationsApi and sets a collection
     * of locations based on the results
     * @param searchString {String} Value to search places from
     * @return {Promise.<T>|*} Promise that resolves when a list of location has been returned
     */
    getLocations(searchString, country) {
        return googleLocationsApi.getCityNames(searchString, country).then((places) => {
            if (places) {
                this.state.locations = places;
            }
        })
        .catch((error) => {
            throw (error);
        });
    }

    /**
     * @method clearLocation
     * @description Clears the locations value
     */
    clearLocations() {
        this.state.locations = null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constants
import {
    COOKIES,
    STORAGE_KEYS
} from 'Constants';

// Utils dependencies
import {
    storage
} from 'utils';


/**
 * @method successfulQuickReg
 * @description  verify if the status after the quickReg
 * is 'OK' and create the respective cookies, if not return false
 * @param  {Object} data
 * @return {Object}
 */
function successfulQuickReg(data) {
    if (data.result.response.status === 'OK') {
        const d = new Date();
        const tkn = data.result.response.result.tkn;

        d.setTime(d.getTime() + (1000 * 60 * 60 * 24));

        document.cookie = `${COOKIES.QUICK_REGISTER_ID}=${data.result.response.result.mbuserId.toString()}; expires=${d}; path=/`;
        document.cookie = `${COOKIES.QUICK_REGISTER_EMAIL}=${data.result.response.result['Debug-email']}; expires=${d}; path=/`;
        document.cookie = `${COOKIES.QUICK_REGISTER_FIRST_NAME}=${data.result.response.result['Debug-firstName']}; expires=${d}; path=/`;

        if (tkn) {
            storage.localStorage.create(
                STORAGE_KEYS.LOGIN_TOKEN,
                tkn,
                d
            );
        }

        return {
            successful: data.result.response.status === 'OK'
        };
    }

    return {
        successful: false,
        existingEmailError: data.result.response.errors.code === '2003'
    };
}

/**
 * @method submitQuickReg
 * @description  fetch the data to the endpoint and verify
 * is was successful if not throw and error
 * @param  {String} endpoint
 * @param  {Object} data
 * @return {Promise}
 */
export function submitQuickReg(endpoint, data) {
    const values = Object.keys(data).map((key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        ).join('&');

    return fetch(`${endpoint}?${values}`, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'POST'
    })
    .then((response) => response.json())
    .then(successfulQuickReg)
    .then((response) => {
        if (!response.successful) {
            if (response.existingEmailError) {
                throw Error('ERR_CONFIRMED_USER_EXISTS');
            }

            throw Error('Quick Registration Not Successful');
        }
    });
}

// expose functions for external use
export default {
    submitQuickReg
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

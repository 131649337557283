// Util dependencies
import { htmlNode as html } from 'utils';

const BUTTON_BASE_CLASS = 'button';

const generateClassNames = (type, modifier, cssClasses, disabled) => (
    `${BUTTON_BASE_CLASS} ${type ? `${BUTTON_BASE_CLASS}_${type}` : ''} ${modifier ? `${BUTTON_BASE_CLASS}--${modifier}` : ''} ${disabled ? 'disabled' : ''} ${cssClasses}`.trim()
);

export default ({ type, modifier, disabled, cssClasses, valueText, analyticsTrigger, ariaLabel }) => html`
    <button class="${generateClassNames(type, modifier, cssClasses, disabled)}" 
            type="button"
            ${analyticsTrigger ? `data-analytics-trigger="${analyticsTrigger}"` : ''}
            ${ariaLabel ? `aria-label="${ariaLabel}"` : ''}>
        <span class="${BUTTON_BASE_CLASS}__value-text">
            ${valueText}
        </span>
    </button>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

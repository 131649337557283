import { htmlNode } from 'utils';

export default (content) => htmlNode`
    <div class="urgent-recalls-airbag">
        <h2 class="urgent-recalls-airbag__header"></h2>
        <table class="urgent-recalls-airbag__table">
            <caption class="urgent-recalls-airbag__caption hide"></caption>
            <tr>
                <th class="table-header" scope="col">
                    ${content.airbagModule.airbagPosition}
                </th>
                <th class="table-header" scope="col">
                    ${content.airbagModule.estimatedData}
                </th>
            </tr>
        </table>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

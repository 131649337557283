/**
 * Template for a Preferred Dealer info.
 * Uses ES6 template literal to composite the markup for a Preferred Dealer
 */
import { htmlNode } from 'utils';

/**
 * @function
 * @name renderAMGIcons
 * @description render AMG Icons
 * @param {Object} dealer object
 * @param {String} dealerClass css class
 * @param {String} amgPerformanceCenterLabel
 * @param {String} amgEliteLabel
 * @return {String} template string
 */
const renderAMGIcons = (dealer, dealerClass, amgPerformanceCenterLabel, amgEliteLabel) => {
    let str = '';
    if (dealer.isAmg) {
        str += `<i class="${dealerClass} icon-AMG_logo-performance-center">
                    <span class="offscreen">${amgPerformanceCenterLabel}</span>
                </i>`;
    }
    if (dealer.isAmgElite) {
        str += `<i class="${dealerClass} icon-AMG_logo-performance-center-elite">
                    <span class="offscreen">${amgEliteLabel}</span>
                </i>`;
    }
    return str;
};

/**
 * @function
 * @name renderInventoryLink
 * @description render show inventory link
 * @param {String} inventoryUrl
 * @param {String} viewLabel
 * @return {String} template string
 */
const renderInventoryLink = (inventoryUrl, viewLabel) => {
    const inventoryCTA = `
        <a href="${inventoryUrl}" class="choose-a-dealer__link link link_secondary" target="_blank" rel="noopener" data-analytics-trigger="cta">
            ${viewLabel}
        </a>
    `;

    return inventoryCTA;
};

export default ({ dealer = {}, content, disableChange = false, showMap, showInventory }) => htmlNode`
    <div>
        <h3 class="choose-a-dealer-plugin__sub-heading">${content.selectedDealerHeading}</h3>
        <p class="choose-a-dealer-plugin__sub-heading-copy dealer-results__sub-heading-copy ${disableChange ? 'hide' : ''}">
            <button class="link link_plain-link" data-change-search-cta role="presentation" data-analytics-trigger="change-zip">
                ${content.changeDealer}
            </button>
        </p>
        <div class="choose-a-dealer" data-analytics-container="contact-dealer-selected:${dealer.name}:${dealer.dealerId}">
            <address class="choose-a-dealer__address-container-dealer">
                <div>
                    <a href="${dealer.url}" class="choose-a-dealer__dealer-name link link_bold" target="_blank" rel="noopener">${dealer.name}</a>
                </div>
                <div class="choose-a-dealer__address choose-a-dealer__address--main">${dealer.address}, ${dealer.city}, ${dealer.state}, ${dealer.zip}</div>
                <div>
                    <a href="tel:${dealer.phone}" class="choose-a-dealer__phone-number">${dealer.phoneFormatted}</a>
                </div>
                ${renderAMGIcons(dealer, 'choose-a-dealer__icon', content.amgPerformanceCenter, content.amgPerformanceCenterElite)}
            </address>
            ${showInventory ? renderInventoryLink(dealer.inventoryUrl, content.viewInventory) : ''}
            ${showMap ? '<div class="choose-a-dealer__map" data-dealer-map></div>' : ''}
        </div>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * [TO DO]: new strings can be added here & maintained as global constants
 * GLOBAL CONSTANTS
 */
const CONSTANTS = {
    RESERVATION_STATUSES: {
        RESERVED: 'RESERVED',
        RESERVABLE: 'RESERVABLE',
        NOT_RESERVABLE: 'NOT_RESERVABLE',
    },
    POSITIONS: {
        LEFT: 'left',
        CENTER: 'center',
        RIGHT: 'right',
    },
    COUNTRIES: {
        US: 'us',
        CA: 'ca'
    }
};

export default CONSTANTS;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { DealerSelectorModal } from 'partials/dealer-selector';
import { UserProfile } from 'partials/profile';
import DOMPurify from 'dompurify';
import { dealerLocatorApi } from 'partials/dealer-locator-api';

// Util dependencies
import {
    cookie,
    formatString,
    getLocationSuffix,
    serializeObject,
    tokenReplace
} from 'utils';

/**
 * @constant DEFAULT_CONFIG
 * @description Default configuration for InventoryInterceptorApi
 * @type {Object}
 */
const DEFAULT_CONFIG = window.mbVans.ns('applicationProperties').inventoryConfig || {
    cpoInventoryUrl: null,
    cpoVdpUrl: null,
    inventoryUrl: null,
    isInventoryEnabled: true,
    isDDTVariant: false,
    vdpUrl: null
};

const language = window.mbVans.ns('pageData').language;
const country = window.mbVans.ns('pageData').country;

/**
 * @class InventoryInterceptorApi
 * @description Class responsible for intercepting a user action to go to
 * the Inventory App that will first display a DealerSelectorModal then
 * route the user to inventory based on the dealer selection and vehicle
 * information provided
 */
export default class InventoryInterceptorApi {
    /**
     * @method constructor
     * @description On instantiation, set the instance properties
     * @param config {Object} Configuration for interceptor
     * @param config.cpoVdpUrl {String} Url for CPO inventory VDP page
     * @param config.cpoInventoryUrl {String} Url for CPO inventory app
     * @param config.inventoryUrl {String} Url for new inventory app
     * @param config.isInventoryEnabled {Boolean} Flag for if the inventory is enabled for locale
     * @param config.isNewInventory {Boolean} Flag for if the inventory is new or CPO
     * @param config.vdpUrl {String} Url for New inventory VDP page
     */
    constructor(config) {
        this.config = {
            ...DEFAULT_CONFIG,
            ...config
        };
        this.dealerSelectorModal = null;
        this.isNewInventory = true;
        this.vehicleInfo = null;

        // method aliases
        this.goToInventoryApp = this.goToInventoryApp.bind(this);
        this.destroyDealerSelector = this.destroyDealerSelector.bind(this);
    }

    /**
     * @method getInventoryUrl
     * @description Retrieves the inventory url based on `isNewInventory` value
     * @return {*}
     */
    getInventoryUrl() {
        const {
            cpoInventoryUrl,
            inventoryUrl
        } = this.config;

        return this.isNewInventory ? inventoryUrl : cpoInventoryUrl;
    }

    /**
     * @method getVDPUrl
     * @description Retrieves the inventory vdp url based on `isNewInventory` value
     * and `isNewInventory` values
     * @return {*}
     */
    getVDPUrl() {
        const {
            cpoVdpUrl,
            vdpUrl
        } = this.config;

        return this.isNewInventory ? vdpUrl : cpoVdpUrl;
    }

    /**
     * @method goTo
     * @description If inventory is enabled, displays a DealerSelectorModal with callbacks
     * to route the user to the inventory app upon dealer selection; otherwise routes the user
     * to the the inventory URL directly
     * @param isNew {Boolean} Indicator for whether inventory is new || cpo
     * @param data {Object} Payload to pass to inventory routing
     * @param data.includeReserveOnly [{boolean}] Filter selection for reservable vehicles
     * @param data.dealer [{Object}] Dealer object
     * @param data.searchLocation [{Object}] Type and value of the users search location
     * @param data.callingElementCta [{element}] element that was clicked on to initiate
     * this method (needed to retain focus for accessibility)
     */
    goTo(isNew, data = {}) {
        const {
            includeReserveOnly,
            searchLocation,
            vehicleInfo
        } = data;
        let { dealer } = data;
        this.vehicleInfo = vehicleInfo;
        this.isNewInventory = isNew;
        const dealerId = cookie.getCookie('_dealerID');

        if (this.config.isDDTVariant) {
            const profile = new UserProfile();
            dealer = profile.getPreferredDealer();
        }

        if (!isNew) {
            this.goToCPOInventoryApp(searchLocation, includeReserveOnly);
        } else if (this.config.isDDTVariant && this.config.isInventoryEnabled && dealerId !== '') {
            dealerLocatorApi.getDealer({
                country,
                id: dealerId,
                language,
                type: 'dealers'
            }).then((dealerInfo) => this.goToInventoryApp(dealerInfo));
        } else if (this.config.isInventoryEnabled) {
            this.goToInventoryApp(dealer);
        } else {
            this.goToInventoryUrl();
        }
    }

    /**
     * @method goToVDP
     * @description navigate user to new or cpo VDP page for a specific vehicle
     * @param {Boolean} isNew whether to navigate to cpo or new inventory
     * @param {Object} dealer dealer name and id data
     * @param {Object} vehicleInfo vehicle info needed to create vdp route
     * @param {String} vehicleInfo.bodyStyle body style of vehicle
     * @param {String} vehicleInfo.class class of vehicle
     * @param {String} vehicleInfo.model model of vehicle
     * @param {String} vehicleInfo.vin vin of vehicle
     */
    goToVDP(isNew, dealer, vehicleInfo) {
        const {
            bodyStyle,
            model,
            vin
        } = vehicleInfo;
        const {
            dealerId,
            name
        } = dealer;
        const dealerName = formatString.hyphenate(
            formatString.toAlphaNumeric(name)
        );
        this.isNewInventory = isNew;
        const endpointBase = this.getVDPUrl().split('.')[0];
        // get the current page suffix for .variant and .html support
        const endpointSuffix = getLocationSuffix();
        const endpoint = tokenReplace(
            endpointBase,
            {
                dealerName,
                dealerId,
                classId: vehicleInfo.class,
                bodyStyleId: bodyStyle,
                modelId: model,
                vin
            }
        );

        window.location = `${endpoint}${endpointSuffix}`;
    }

    /**
     * @method goToInventoryApp
     * @description Routes the url to the inventory app and sets the dealer and vehicle parameters
     * @param dealer {Object} Dealer object
     */
    goToInventoryApp(dealer) {
        if (this.config.isClassicInventory) {
            this.goToClassicInventoryApp(dealer);
            return;
        }

        const endpointBase = this.getInventoryUrl().split('.')[0];
        // get the current page suffix for .variant and .html support
        const endpointSuffix = getLocationSuffix();

        // Handle vehicleInfo (class, model, year and model data)
        const vehicleParams = {};

        if (this.vehicleInfo) {
            if (((this.vehicleInfo.options || {}).options || {}).STAND_ALONE_OPTIONS) {
                const isHighRoof = this.vehicleInfo.options.options.STAND_ALONE_OPTIONS.some(
                    (option) => option.id === '0:D03'
                );

                if (isHighRoof) {
                    vehicleParams.roofHeight = 'HIGHROOF';
                } else {
                    vehicleParams.roofHeight = 'STANDARDROOF';
                }
            } else if (this.vehicleInfo.variant) {
                if (this.vehicleInfo.variant === 'HIGHROOF') {
                    vehicleParams.roofHeight = 'HIGHROOF';
                } else {
                    vehicleParams.roofHeight = 'STANDARDROOF';
                }
            }

            if (
                (this.vehicleInfo.class)
            ) {
                if (this.vehicleInfo.bodyStyle) {
                    vehicleParams.bodyStyleClass = `${this.vehicleInfo.class}:${this.vehicleInfo.bodyStyle}`;
                } else {
                    vehicleParams.class = this.vehicleInfo.class;
                }
            }

            if (this.vehicleInfo.cylinder) {
                vehicleParams.powertrain = this.vehicleInfo.cylinder;
            }

            if (this.vehicleInfo.wheelbase) {
                vehicleParams.wheelbase = this.vehicleInfo.wheelbase;
            }

            if (this.vehicleInfo.year) {
                vehicleParams.year = this.vehicleInfo.year;
            }

            if (this.vehicleInfo.exteriorColor) {
                vehicleParams.exteriorColor = this.vehicleInfo.exteriorColor;
            }

            if (this.vehicleInfo.interiorColor) {
                vehicleParams.interiorColor = this.vehicleInfo.interiorColor;
            }

            if (this.vehicleInfo.mappedFeatures) {
                vehicleParams.packagesOptions = this.vehicleInfo.mappedFeatures;
            }

            if (this.vehicleInfo.notMappedFeatures) {
                vehicleParams.byoOptions = this.vehicleInfo.notMappedFeatures;
            }
        }

        // Redirect the user to the inventory page with the dealer id and query params

        let inventoryUrl;

        if (dealer) {
            const {
                dealerId,
                name
            } = dealer;
            const dealerName = formatString.hyphenate(
                formatString.toAlphaNumeric(name)
            );
            inventoryUrl = `${endpointBase}/${dealerName}/${dealerId}${endpointSuffix}${serializeObject(vehicleParams)}`;
        } else {
            inventoryUrl = `${endpointBase}${endpointSuffix}${serializeObject(vehicleParams)}`;
        }

        window.location = inventoryUrl;
    }

    /**
     * @method goToClassicInventoryApp
     * @description Routes the url to the classic inventory app and sets the dealer and vehicle parameters,
     * @param dealer {Object} Dealer object
     */
    goToClassicInventoryApp(dealer) {
        const {
            dealerId,
        } = dealer;
        const isCanada = window.mbVans.ns('pageData').country === 'ca';
        const endpointBase = this.getInventoryUrl().split('.')[0];

        if (isCanada) {
            const zipRaw = dealer.zip || '';
            const zip = zipRaw.replace(/\s/g, '');
            const inventoryEndpoint = endpointBase.split('.')[0];
            const url = `${inventoryEndpoint}/postal:${zip}`;
            window.location = url;
            return;
        }


        // Handle vehicleInfo (class, model, year and model data)
        const vehicleParams = {};

        if (this.vehicleInfo) {
            vehicleParams.roofHeights = 'std';
            if (
                this.vehicleInfo.class && this.vehicleInfo.bodyStyle
            ) {
                const classId = this.vehicleInfo.class.toLowerCase();
                const bodyStyleId = this.vehicleInfo.bodyStyle.toUpperCase();

                vehicleParams.models = `${classId}_${bodyStyleId}`;
            }

            if (this.vehicleInfo.year) {
                vehicleParams.years = this.vehicleInfo.year;
            }

            if (this.vehicleInfo.wheelbase) {
                vehicleParams.wheelbases = this.vehicleInfo.wheelbase;
                if (this.vehicleInfo.wheelbase === '170EXT') {
                    vehicleParams.wheelbases = '170E';
                }
            }

            if (this.vehicleInfo.cylinder) {
                const CYLINDERS_MAP = {
                    '4CYLGAS': '4cyl-gas',
                    '4CYLDIESEL': undefined,
                    '6CYLDIESEL': '6cyl-diesel',
                    '6CYLDIESEL4x4': '4x4-diesel',
                };
                vehicleParams.powertrains = CYLINDERS_MAP[this.vehicleInfo.cylinder];
            }

            if (((this.vehicleInfo.options || {}).options || {}).STAND_ALONE_OPTIONS) {
                const isHighRoof = this.vehicleInfo.options.options.STAND_ALONE_OPTIONS.some(
                    (option) => option.id === '0:D03'
                );

                if (isHighRoof) {
                    vehicleParams.roofHeights = 'high';
                }
            }

            if (this.vehicleInfo.options) {
                const options = (this.vehicleInfo.options.options || {}).STAND_ALONE_OPTIONS || [];
                const packages = (this.vehicleInfo.options.packages || {}).PACKAGES || [];
                const allOptions = [...options, ...packages].map((option) => option.id.split(':')[1]);
                vehicleParams.packagesAndOptions = allOptions.join(',');
            }
        }

        // Redirect the user to the inventory page with the dealer id and query params
        const inventoryUrl = `${endpointBase}/dealer/${dealerId}#${serializeObject(vehicleParams, true)}`;
        window.location = inventoryUrl;
    }

    /**
     * @method goToCPOInventoryApp
     * @description Routes the url to the cpo inventory app and sets the search location and vehicle parameters
     * @param searchLocation {Object} Search location object
     * @param includeReserveOnly [{Boolean}] Filter selection for reservable vehicles
     */
    goToCPOInventoryApp(searchLocation, includeReserveOnly = false) {
        const endpointBase = this.getInventoryUrl().split('.')[0];
        // get the current page suffix for .variant and .html support
        const endpointSuffix = getLocationSuffix();

        // Handle vehicleInfo (class, model, year and model data)
        const params = {};

        if (this.vehicleInfo) {
            if (this.vehicleInfo.class && this.vehicleInfo.bodyStyle) {
                const classId = this.vehicleInfo.class;
                const bodyStyleId = this.vehicleInfo.bodyStyle;

                params.bodyStyleClass = `${classId}:${bodyStyleId}`;
            }

            if (this.vehicleInfo.model) {
                params.model = this.vehicleInfo.model;
            }
        }

        if (searchLocation && searchLocation.type && searchLocation.value) {
            params[searchLocation.type] = searchLocation.value;
        }

        if (includeReserveOnly) {
            params.includeReserveOnly = true;
        }

        // Redirect the user to the inventory page with the dealer id and query params
        window.location = `${endpointBase}${endpointSuffix}${serializeObject(params)}`;
    }

    /**
     * @method goToInventoryUrl
     * @description Routes the url to the inventory url and appends any vehicleInfo if available
     * Note: the logic for appending the vehicle information applies to the Classic uri pattern.
     * For OW Inventory see `goToInventoryApp` method
     */
    goToInventoryUrl() {
        let endpoint = this.getInventoryUrl();

        if (this.vehicleInfo) {
            if (this.vehicleInfo.year) {
                endpoint = `${endpoint}/year-${this.vehicleInfo.year}`;
            }

            if (this.vehicleInfo.class && this.vehicleInfo.bodyStyle) {
                endpoint = `${endpoint}/class-${this.vehicleInfo.class}:${this.vehicleInfo.bodyStyle}`;
            }

            if (this.vehicleInfo.model) {
                endpoint = `${endpoint}/model-${this.vehicleInfo.model}`;
            }

            if (this.vehicleInfo.exteriorColor) {
                endpoint = `${endpoint}/exteriorColor-${this.vehicleInfo.exteriorColor}`;
            }

            if (this.vehicleInfo.interiorColor) {
                endpoint = `${endpoint}/interiorColor-${this.vehicleInfo.interiorColor}`;
            }
        }

        window.location = endpoint;
    }

    /**
     * @method createDealerSelector
     * @description Creates an instance of the DealerSelectorModal
     * @param location {Object} location data to send to map
     * @param callingElementCta {element} element that is called to open the modal
     */
    createDealerSelector(location, callingElementCta) {
        this.dealerSelectorModal = new DealerSelectorModal({
            ...callingElementCta && { callingElementCta },
            dealer: null,
            displaySelectedDealer: false,
            enableSelectedDealer: true,
            filterBy: DealerSelectorModal.FILTER_TYPES.INVENTORY,
            isNewInventory: true,
            location,
            onClose: this.destroyDealerSelector,
            onDealerContinue: this.goToInventoryApp,
            onDealerSelect: this.goToInventoryApp
        });
    }

    /**
     * @method destroyDealerSelector
     */
    destroyDealerSelector() {
        this.dealerSelectorModal.destroy();
        this.dealerSelectorModal = null;
    }

    /**
     * @method onDealerSelectorClose
     * @description Callback applied when the DealerSelectorModal closes
     * that destroys the dealerSelector and resets the vehicleInfo value
     */
    onDealerSelectorClose() {
        this.destroyDealerSelector();
        this.vehicleInfo = null;
    }

    /**
     * @method getReservePath
     * @description Constructs a vehicle reservation path based on the props (vehicle info) and location values
     * @param props {Object} Params object with values for dealerName, dealerId, class, bodyStyle, model and vin
     * @param location [{Object}] Object with `type` and `value` props to set the location params to the url
     * @param reservationId [{Number}] Id of saved reservation
     * @return {string} endpoint to reservation flow
     */
    getReservePath(props, location, reservationId) {
        const suffixPath = getLocationSuffix();
        // normalize url props
        const urlProps = {
            ...props,
            dealerName: formatString.hyphenate(
                formatString.toAlphaNumeric(props.dealerName)
            )
        };
        // normalize the endpoint and add the suffix for variants
        let endpoint = this.config.cpoReserveUrl.replace(suffixPath, '').replace(/\.html/g, '');
        endpoint = `${endpoint}/{dealerName}/{dealerId}/{class}/{bodyStyle}/{model}/{vin}${suffixPath}`;
        endpoint = tokenReplace(endpoint, urlProps);
        let params = {};

        if (location) {
            params = {
                ...params,
                [location.type]: location.value
            };
        }

        if (reservationId) {
            params = {
                ...params,
                resvId: reservationId
            };
        }

        if (Object.keys(params).length > 0) {
            endpoint = `${endpoint}${serializeObject(params)}`;
        }

        return endpoint;
    }

    /**
     * @method getReservePathDceNew
     * @description Constructs a vehicle reservation path based on the props (vehicle info) and location values
     * @param props {Object} Params object with values for dealerName, dealerId, class, bodyStyle, model and vin
     * @param location [{Object}] Object with `type` and `value` props to set the location params to the url
     * @param reservationId [{Number}] Id of saved reservation
     * @return {string} endpoint to reservation flow
     */
    getReservePathDceNew(props, location, reservationId) {
        const suffixPath = getLocationSuffix();
        // normalize url props
        const urlProps = {
            ...props,
            dealerName: formatString.hyphenate(
                formatString.toAlphaNumeric(props.dealerName)
            )
        };
        // normalize the endpoint and add the suffix for variants
        let endpoint = this.config.newReserveUrl.replace(suffixPath, '').replace(/\.html/g, '');
        endpoint = `${endpoint}/{dealerName}/{dealerId}/{class}/{bodyStyle}/{model}/{vin}${suffixPath}`;
        endpoint = tokenReplace(endpoint, urlProps);
        let params = {};

        if (location) {
            params = {
                ...params,
                [location.type]: location.value
            };
        }

        if (reservationId) {
            params = {
                ...params,
                resvId: reservationId
            };
        }

        if (Object.keys(params).length > 0) {
            endpoint = `${endpoint}${serializeObject(params)}`;
        }

        return endpoint;
    }


    /**
     * @method goToReserve
     * @description Redirects the user to the reserve page and injects the associated route params
     * @param props {Object} Params object with values for dealerName, dealerId, class, bodyStyle, model and vin
     * @param location [{Object}] Object with `type` and `value` props to set the location params to the url
     * @param reservationId [{Number}] Id of saved reservation
     */
    goToReserve(props, location, reservationId, isNewEnv = undefined) {
        if (!isNewEnv) {
            window.location =  DOMPurify.sanitize(this.getReservePath(props, location, reservationId));
        } else {
            window.location =  DOMPurify.sanitize(this.getReservePathDceNew(props, location, reservationId));
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// partials
import { SelectControl } from 'partials/select-control';

// Util dependencies
import { renderer } from 'utils';

// local dependencies
import { getItems } from './api/parser';
import configContent from './config/content';

/**
 * @constant ATTRIBUTES
 * @description Attribute references for the category selector
 * @type {{CATEGORY_SELECTOR: string}}
 */
const ATTRIBUTES = {
    CATEGORY_SELECTOR: 'data-category-selector',
    MODEL_FINDER_LIGHT_SELECTED: 'data-model-finder-light-selected'
};

/**
 * @class ModelFinderLight
 * @description A module for orchestraing the show and
 * hiding for content tiles on the Model Finder Light.
 */
export default class ModelFinderLight {
    /**
     * @method constructor
     * @description An instance of a Model Finder Light Module
     * @param element {Node} The DOM element of the module
     */
    constructor(element) {
        this.categorySelectorElement = null;
        this.selectControl = null;
        this.selectedItem = null;
        this.element = element;
        this.items = [];

        if (!element) {
            return console.warn('No model finder light content.');
        } else {
            this.items = getItems(element);
            this.cacheDOM();
            // initialization
            this.init();
        }
    }

    /**
     * @method cacheDOM
     * @description caches DOM elements
     */
    cacheDOM() {
        this.categorySelectorElement = this.element.querySelector(`[${ATTRIBUTES.CATEGORY_SELECTOR}]`);
    }

    /**
     * @method createSelectControl
     * @description Creates an instance of a select control
     */
    createSelectControl(items = []) {
        this.selectControl = new SelectControl(
            items,
            {
                cssClass: 'model-finder-light__select-control',
                defaultSelection: 0,
                labelText: configContent.categorySelectorLabelText,
                selectionCallback: this.onSelectCategory.bind(this),
                theme: 'dropdown',
                analyticsTrigger: 'cta-attributes'
            }
        );

        this.categorySelectorElement.innerHTML = '';

        renderer.append(this.selectControl.render(), this.categorySelectorElement);
    }

    /**
     * @method destroy
     * @description Detaches all events and removes the element node
     */
    destroy() {
        this.categorySelectorElement = null;
        this.selectControl.destroy();
        this.selectedItem = null;
        this.items = [];
        this.element.remove();
    }

    /**
     * @method getSelectedItem
     * @description returns the currently selected tile
     * @return {Node} the currently selected content tile.
     */
    getSelectedItem() {
        return this.selectedItem;
    }

    /**
     * @method init
     * @description Inits the select component and logoc to display the content tile
     */
    init() {
        this.createSelectControl(this.items);
        this.setSelectedItem(this.items[0]);
    }

    /**
     * @method onSelectCategory
     * @description Callback method for when a category is selected.
     * @param item {Node} The DOM element of the content tile that is selected.
     */
    onSelectCategory(item) {
        this.getSelectedItem().element.setAttribute(ATTRIBUTES.MODEL_FINDER_LIGHT_SELECTED, false);
        this.setSelectedItem(this.items[item]);
    }

    /**
     * @method setSelectedItem
     * @description sets the content tile to be viewed
     * @param item {Node} The DOM element of the content tile to be set as the selected item..
     */
    setSelectedItem(item) {
        this.selectedItem = item;
        item.element.setAttribute(ATTRIBUTES.MODEL_FINDER_LIGHT_SELECTED, true);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

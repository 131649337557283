// Util Dependencies
import { generateUniqueID } from 'utils';

// Local Dependencies
import InputRadioGroupControl from './InputRadioGroupControl';
import inputRadioCardGroupControlTemplate from './../templates/inputRadioCardGroupControlTemplate';
import InputRadioCardControl from './InputRadioCardControl';



/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    RADIO_GROUP: 'form__radio-card-control-group',
    ERROR_ELEMENT: 'form__input-error',
    ERROR: 'error'
};


export default class InputRadioCardGroupControl extends InputRadioGroupControl {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration options for an InputRadioCardGroupControl
     * @type {Object}
     * @const displayIcons {Boolean} Indicator for whether the radio cards should show icons, defaulted to true
     * @const displayInline {Boolean} True is display elements inline, default false display stacked
     * @const labelOffscreen {Boolean} Indicator to hide the label offscreen
     */
    static DEFAULT_CONFIG = {
        ...InputRadioGroupControl.DEFAULT_CONFIG,
        displayIcons: true,
        displayInline: false,
        labelOffscreen: false
    };

    /**
     * @constructor Creates a new InputRadioCardGroupControl
     */
    constructor(config = InputRadioCardGroupControl.DEFAULT_CONFIG) {
        super({
            ...InputRadioCardGroupControl.DEFAULT_CONFIG,
            ...config
        });
        this.id = config.id || generateUniqueID();
        this.name = config.name;
        this.displayInline = config.displayInline;
        this.radioGroupElement = null;
        this.radios = [];
        this.init();
    }

    /**
     * @method init
     * @description Creates the radio card group and options in the group
     */
    init() {
        this.radioGroupContainer = inputRadioCardGroupControlTemplate({
            id: this.id,
            analyticsTrigger: this.config.analyticsTrigger,
            inputClassNames: this.config.inputClassNames.join(' '),
            displayInline: this.config.displayInline,
            label: this.config.label,
            labelOffscreen: this.config.labelOffscreen
        })({ getNode: true });

        this.cacheDOM();
        this.createRadioOptions();
    }

    /**
     * @method cacheDOM
     * @description caches DOM elements
     */
    cacheDOM() {
        this.radioGroupElement = this.radioGroupContainer.querySelector(`.${CLASSES.RADIO_GROUP}`);
        this.errorElement = this.radioGroupContainer.querySelector(`.${CLASSES.ERROR_ELEMENT}`);
    }

    /**
     * @method createRadioOptions
     * @description Creates one InputRadioCardControl for each Option in the options list
     */
    createRadioOptions() {
        this.config.options.forEach((option) => {
            const radio = new InputRadioCardControl({
                ...option.config,
                displayIcons: this.config.displayIcons,
                id: generateUniqueID(),
                name: this.config.name,
                onChangeCallback: this.onChange.bind(this)
            });
            this.radios.push(radio);
            this.radioGroupElement.appendChild(radio.render());
        });
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for AMG Detail Page Overlay
 * Uses ES6 template literal to composite the markup
 */
import { htmlNode } from 'utils';
import loadingSpinnerTemplate from './loadingSpinnerTemplate';

export default (href, title, fallbackDisclaimer) => htmlNode`
    <div class="specialty-detail__modal-wrapper">
        <iframe
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen="true"
            class="specialty-detail__modal-iframe"
            src="${href}"
            width="500"
            height="500"
            title="${title}">
            <p style="text-align:center">${fallbackDisclaimer}</p>
        </iframe>
        ${loadingSpinnerTemplate()({ getNode: false })}
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Imports
import ReCAPTCHA from './views/ReCAPTCHA';

// APIs
export { default as recaptchaV3Api } from './api/recaptchaV3Api';

// Views
export { ReCAPTCHA };
export { default as ReCaptchaV3Branding } from './views/ReCaptchaV3Branding';

export default {
    ReCAPTCHA
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

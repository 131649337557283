// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom input textarea control
 * Uses ES6 template literal to composite the markup for a custom input textarea control
 */
export default ({
    id = '',
    name = '',
    dataAttributes = '',
    inputClassNames = '',
    required = false,
    maxLength = '',
    labelText = '',
    showLabelText = false,
    placeholder = ''
}) => htmlNode`
        <div class="form__textarea-container form__input ${inputClassNames}">
            <textarea ${id ? `id="${id}"` : ''} 
                ${name !== '' && `name="${name}"`}
                ${labelText ? ` aria-label="${labelText}"` : ''}
                ${dataAttributes ? ` ${dataAttributes}` : ''}
                ${required ? ' data-required required' : ''}
                ${placeholder !== '' && `placeholder="${placeholder}"`}
                ${id ? ` aria-labelledby="label-${id}"` : ''}
                class="form__textarea" 
                maxlength="${maxLength}"
                rows="auto"
                minrows="3"
                aria-required="${required}"></textarea>
            ${showLabelText ? `<label class="form__input-label"${required ? ' data-required' : ''} id="label-${id}" for="${id}">
            <span class="form__input-label-text" aria-hidden="true">${labelText}</span>
            </label>` : ''}
            <span class="form__input-error" id="error${id}"></span>
        </div>
    `;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for the carousel navigation indicators or dots
 * Uses ES6 template literal to composite the markup for a navigation component
 * for the hero carousel
 */
import { html } from 'utils';

export default (index) => html`
    <li>
        <button class="carousel-nav__indicator-dot" data-index="${index}"  tabindex="-1"></button>
    </li>
`.trim();
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Evaluation for checking the filterable property of an option against the
 * filter(s) selected by the user.
 * @param {Array} filterSelections
 * @param {string} type
 * @returns {boolean}
 */
function checkFilterSelections(filterSelections, type) {
    return (optionFilter) => filterSelections
        .some((filter) => (type ? optionFilter[type] : optionFilter) === filter);
}

/**
 * Apply filtering to the optionGroups.
 * @param {Array} optionGroups
 * @param {Array} filterSelections
 * @param {sting} [type = vocations]
 * @returns {Array}
 */
function applyFiltering(optionGroups, filterSelections, type = 'vocations') {
    const filteredUpfitOptionGroups = [];
    optionGroups.forEach((group) => {
        const newGroup = {
            ...group
        };

        let filteredOptions = [];

        // Don't filter accessories.
        if (group.title && group.title !== 'Accessories') {
            // Option vocations (upfitVocation) are an array, so needs evaluative check with Array.prototype.some
            if (type === 'vocations') {
                filteredOptions = group.options &&
                    group.options.filter((option) => option.upfitVocation &&
                        option.upfitVocation.some(checkFilterSelections(filterSelections, 'vocation')));
            }

            // Option vendor (upfitVendor) is a string property and easy to check against selected filters.
            if (type === 'vendors') {
                filteredOptions = group.options &&
                    group.options.filter((option) => option.upfitVendor &&
                    filterSelections.includes(option.upfitVendor));
            }
        }

        // Remove categories with no filtered items
        if (filteredOptions.length > 0) {
            newGroup.options = filteredOptions;
            filteredUpfitOptionGroups.push(newGroup);
        }

        // Reset accessories to show each time a user filters.
        if (group.title && group.title === 'Accessories') {
            filteredUpfitOptionGroups.push(newGroup);
        }
    });

    return filteredUpfitOptionGroups;
}

/**
 * A utility to reconstruct the options per group based on selected
 filters
 * @method filterUpfitOptions
 * @param {Object} filterSelections The filters currently selected stored in a map by type.
 * @param {optionGroups} optionGroups A group of options organized by by category.
 * @returns {Array}
 */
export function filterUpfitOptions(filterSelections, optionGroups) {
    if ((filterSelections.vocations && filterSelections.vocations.length === 0) &&
        (filterSelections.vendors && filterSelections.vendors.length === 0) &&
        (filterSelections.buckets && filterSelections.buckets.length === 0)) {
        return optionGroups;
    }

    let filteredUpfitOptionGroups = optionGroups;
    Object.keys(filterSelections).forEach((filterType) => {
        if (filterSelections[filterType].length > 0) {
            filteredUpfitOptionGroups = applyFiltering(
                filteredUpfitOptionGroups,
                filterSelections[filterType],
                filterType
            );
        }
    });

    return filteredUpfitOptionGroups;
}

/**
 * @method getActiveOption
 * @description Retrieves a set of active options from an options list
 * @param activeOptions {Object} Collection of active options
 * @param type {String} Option type to retrieve active options for
 * @param isSingle {Boolean} Flag to retrieve on the first active option in the list
 * @return {Array|Object}
 */
export function getActiveOption(activeOptions, type, isSingle = false) {
    const option = activeOptions && activeOptions[type];

    if (option && isSingle) {
        return option.length ? option[0] : option;
    }

    return option;
}

/**
 * @method isOptionGroup
 * @description Checks if the option group is a package, stand alone option or accessory
 * @param optionGroup {String} Option group
 * @return {Boolean} Indicator if the given option group is a package,
 * stand alone option or accessory
 */
export function isOptionGroup(optionGroup) {
    return (
        optionGroup === 'PACKAGES' ||
        optionGroup.startsWith('STAND_ALONE_OPTIONS') ||
        optionGroup === 'ACCESSORIES' ||
        optionGroup === 'UPFIT'
    );
}

/**
 * @method getRoofHeightOption
 * @description Gets roof height code from option
 * @param vehicleBuild {Object} Vehicle build object
 * @return {String}
 */
export function getRoofHeightOption(vehicleBuild) {
    if (((vehicleBuild.options || {}).options || {}).STAND_ALONE_OPTIONS) {
        const isHighRoof = vehicleBuild.options.options.STAND_ALONE_OPTIONS.some(
            (option) => option.id === '0:D03'
        );

        if (isHighRoof) {
            return 'HIGHROOF';
        }
    }

    return 'STANDARDROOF';
}

export default {
    filterUpfitOptions,
    getActiveOption,
    getRoofHeightOption,
    isOptionGroup
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

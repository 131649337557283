// Partial dependencies
import { Observable } from 'partials/observer';

const DEFAULT_STATE = {
    searchLocation: null,
    searchByType: null,
    searchTerm: null,
    dealers: null,
    selectedRetailer: null,
    preferredDealer: null,
    filter: 'mbdealer',
    lcp: null
};

/**
 * @class DealerLocatorModel
 * @description Observable instance that is an interface as model
 */
export default class DealerLocatorModel extends Observable {
    constructor(initialState) {
        initialState = { ...DEFAULT_STATE, ...initialState };
        super(initialState);
    }

    /**
     * @method setSearchByType
     * @description Sets the search type to be used for finding location by zip or place
     * @param {String} searchByType - Search by type value. Possible values: zip, place
     */
    setSearchByType(searchByType) {
        this.state.searchByType = searchByType;
    }

    /**
     * @method setSearchLocation
     * @description Sets the searchLocation to the model
     * @param {String} searchLocation - Postcode location to search dealer from
     */
    setSearchLocation(searchLocation) {
        this.state.searchLocation = searchLocation ? searchLocation.trim() : searchLocation;
    }

    /**
     * @method setSearchTerm
     * @description Sets the searchTerm to be used for tracking location address
     * @param {String} searchString - String used in search
     */
    setSearchTerm(searchString) {
        this.state.searchTerm = searchString;
    }

    /**
     * @method setFilter
     * @description Sets the selected filter in the model
     * @param {String} filter - Current filter selected
     */
    setFilter(filter) {
        this.state.filter = filter;
    }

    /**
     * @method setDealers
     * @description Sets the dealers in the model
     * @param {Array} dealers - Array of dealers
     */
    setDealers(dealers) {
        this.state.dealers = dealers;
    }

    /**
     * @method setPreferredDealer
     * @description Sets the preferred dealer in the model
     * @param {Object} dealer - Dealer object
     */
    setPreferredDealer(dealer) {
        this.state.preferredDealer = dealer;
    }

    /**
     * @method setPreferredDealer
     * @description Sets the preferred dealer in the model
     * @param {Object} lcpID - LCP identifier
     */
    setLCP(lcpID) {
        this.state.lcp = lcpID;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Module dependencies
import { EVENTS, CUSTOM_EVENTS } from 'Constants';
import { customEventDispatcher, noop } from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {{DRAWER: string}}
 */
const CLASSES = {
    DRAWER: 'toggle-drawer'
};

/**
 * @const ATTRIBUTES
 * @description Collection of constant values for related data attributes of the module
 * @type {{CONTROLS: string, EXPANDED: string, HIDDEN: string, BUTTON_TOGGLE: string}}
 */
const ATTRIBUTES = {
    CONTROLS: 'aria-controls',
    EXPANDED: 'aria-expanded',
    HIDDEN: 'aria-hidden',
    TIMING: 'data-timing',
    HEIGHT: 'data-height',
    BUTTON_TOGGLE: 'data-toggle-drawer-button'
};

/**
 * @const DEFAULT_OPTIONS
 * @description Collection of default options
 * @type {{onToggle: *, closedText: null, openedText: null, toggleDrawer: null}}
 */
const DEFAULT_OPTIONS = {
    onToggle: noop,
    closedText: null,
    openedText: null,
    toggleDrawer: null
};

/**
 * @class ToggleDrawer
 * @description Toggles a drawer (container element) visibility and aria attributes
 * when clicking on link element
 */
export default class ToggleDrawer {
    /**
     * @constructor
     * @description On instantiation sets properties, caches drawer dom element, and attaches events
     * @param element {Object}
     * @param options {Object}
     */
    constructor(element, options) {
        this.element = element;
        this.options = { ...DEFAULT_OPTIONS, ...options };
        this.clicked = false;
        this.drawer = null;
        this.drawerId = null;
        this.toggleButtonElem = null;
        this.drawerHeight = null;
        this.drawerTiming = null;
        this.isExpanded = false;
        this.toggleDrawerTriggerHandler = this.onClickTrigger.bind(this);
        this.onClickCallback = this.onClickCallback.bind(this);
        this.onDetailsTrainsitionEnd = this.onDetailsTrainsitionEnd.bind(this);
        this.cacheDOM();
        this.checkDrawerChildren();

        this.drawerTiming = this.element.getAttribute(ATTRIBUTES.TIMING);

        this.attachClass();
        this.attachEvents();
    }

    /**
     * @method cacheDOM
     * @description caches DOM element for later use
     */
    cacheDOM() {
        this.drawerId = this.element.getAttribute(ATTRIBUTES.CONTROLS);
        this.drawer = this.options.toggleDrawer || document.querySelector(`#${this.drawerId}`);
        this.toggleButtonElem = this.element.querySelector(`[${ATTRIBUTES.BUTTON_TOGGLE}]`);
    }

    /**
     * @method checkDrawerChildren
     * @description check if the drawer has more than one child and add a container for children
     */
    checkDrawerChildren() {
        if (this.drawer.childElementCount > 1) {
            const wrapper = document.createElement('div');
            [].slice.call(this.drawer.children).map((child) => wrapper.appendChild(child));
            this.drawer.append(wrapper);
        }
    }

    /**
     * @method attachClass
     * @description Attaches drawer class to drawer element
     */
    attachClass() {
        this.drawer.classList.add(CLASSES.DRAWER);
        this.drawer.style.visibility = 'hidden';
    }

    /**
     * @method attachEvents
     * @description Attaches click event and callback to element
     */
    attachEvents() {
        this.element.addEventListener(EVENTS.CLICK, this.onClickCallback);

        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.TOGGLE_DRAWER_TRIGGER,
            this.toggleDrawerTriggerHandler
        );
        this.drawer.addEventListener(EVENTS.TRANSITION_END, this.onDetailsTrainsitionEnd);
    }

    /**
     * @method detachEvents
     * @description Detaches click event and callback to element
     */
    detachEvents() {
        this.element.removeEventListener(EVENTS.CLICK, this.onClickCallback);

        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.TOGGLE_DRAWER_TRIGGER,
            this.toggleDrawerTriggerHandler
        );
        this.drawer.removeEventListener(EVENTS.TRANSITION_END, this.onDetailsTrainsitionEnd);
    }

    /**
     * @method destroy
     * @description Destroys the instance by detaching its events
     */
    destroy() {
        this.detachEvents();
    }

    /**
     * @method onClickTrigger
     * @description check if it is part of the group of ctas
     */
    onClickTrigger(event) {
        if (this.element.classList.contains(event.detail.classElement) &&
            event.detail.element !== this.element
        ) {
            this.onClickCallback(event);
        }
    }

    onDetailsTrainsitionEnd() {
        if (!this.isExpanded) {
            this.drawer.style.visibility = 'hidden';
        }
    }

    /**
     * @method onClickCallback
     * @description checks state of aria-expanded attr and toggles the display of
     * drawer element and its aria attributes.
     */
    onClickCallback(event) {
        event.preventDefault();
        this.isExpanded = this.element.getAttribute(ATTRIBUTES.EXPANDED) === 'false';
        this.element.setAttribute(ATTRIBUTES.EXPANDED, `${this.isExpanded}`);
        if (this.toggleButtonElem && this.options.openedText && this.options.closedText) {
            this.toggleButtonElem.innerText = this.isExpanded ?
                this.options.closedText
                :
                this.options.openedText;
        }
        this.drawer.setAttribute(ATTRIBUTES.HIDDEN, `${!this.isExpanded}`);
        this.drawerHeight = (this.drawer.firstElementChild.offsetHeight > 0)
            ? `${this.drawer.firstElementChild.offsetHeight}px` : '100%';
        if (this.isExpanded) {
            this.drawer.style.visibility = 'visible';
        }
        if (this.isExpanded && this.drawerHeight !== '') {
            this.drawer.style.maxHeight = this.drawerHeight;
        } else if (!this.isExpanded && this.drawerHeight !== '') {
            this.drawer.style.maxHeight = 0;
        }
        if (this.isExpanded && this.drawerTiming !== '') {
            this.drawer.style.transitionDuration = this.drawerTiming;
        }
        if (this.options.onToggle) {
            this.options.onToggle(event);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import {
    InputTextControl,
    InputTextAreaControl,
    InputCheckboxControl,
    InputRadioControl,
    InputRadioGroupControl
} from 'partials/input-control';
import { SelectControl } from 'partials/select-control';
import { ReCAPTCHA } from 'partials/recaptcha';
import { FormError } from 'partials/form-control';

// Util dependencies
import {
    renderer,
    generateUniqueID
} from 'utils';

// Local dependencies
import simpleFormTemplate from './../templates/simpleFormTemplate';
import { INPUT_CONTROL_TYPES } from './../api/SimpleFormApi';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 */
const CLASSES = {
    PARENT_CLASS: 'simple-form',
    SUBMIT: '__submit',
    INPUTS_WRAPPER: '__inputs-wrapper',
    FORM: '__form',
    ERROR_CONTAINER: 'error-message-display',
};

/**
 * @const INPUT_CONTROL_CLASSES
 * @description Collection of InputControl classes to be called
 */
const INPUT_CONTROL_CLASSES = {
    InputTextControl,
    InputTextAreaControl,
    InputCheckboxControl,
    InputRadioControl,
    InputRadioGroupControl,
    SelectControl,
    ReCAPTCHA
};

/**
 * @const defaultConfig
 * @description Default configuration options for a simple form
 * @type {Object}
 * @const heading {String} the form heading (title)
 * @const id {String} the form's unique ID
 * @const onValidationSuccess {Func} callback for when the validation passes
 * @const onValidationFail {Func} callback for when the validation fails
 */
const defaultConfig = {
    heading: 'Simple Form',
    callout: '',
    id: '',
    cssClass: '',
    serverError: {
        title: 'We\'re sorry, an error has occurred.',
        description: 'We were unable to process your request. Please try again.'
    },
    onValidationSuccess: null,
    onValidationFail: null
};

/**
 * @class SimpleForm
 * @description View component for displaying a SimpleForm
 */
export default class SimpleForm {
    /**
     * @constructor
     * @description Creates a SimpleForm
     * @param config {Object} configuration object
     * @param parentClass {String} form parent class name
     */
    constructor(config = defaultConfig, parentClass = CLASSES.PARENT_CLASS) {
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.parentClass = parentClass;
        this.serverError = null;
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.init();
    }

    /**
     * @method init
     */
    init() {
        this.id = this.config.id || generateUniqueID();
        this.inputs = {};

        if (this.element === null || this.element === undefined) {
            this.createView();
        }
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method createView
     * @description Create view
     */
    createView() {
        this.element = simpleFormTemplate({
            heading: this.config.heading,
            callout: this.config.callout,
            id: this.id,
            cssClass: this.config.cssClass,
            submitLabel: this.config.submitLabel,
            parentClass: this.parentClass,
            trigger: this.config.trigger
        })({ getNode: true });
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.errorElement = this.element.querySelector(`.${CLASSES.ERROR_CONTAINER}`);
        this.form = this.element.querySelector(`.${this.parentClass}${CLASSES.FORM}`);
        this.inputsWrapper =
            this.element.querySelector(`.${this.parentClass}${CLASSES.INPUTS_WRAPPER}`);
    }

    /**
     * @method attachEvents
     * @description Attaches events
     */
    attachEvents() {
        this.form.addEventListener('submit', this.onSubmitForm, false);
    }

    /**
     * @method onSubmitForm
     * @description Helper method that validates the form when form is submitted
     * @param e {Event}
     */
    onSubmitForm(e) {
        e.preventDefault();
        this.hideServerError();
        this.validateForm();
    }

    /**
     * @method validateForm
     * @description validates the inputs within the form
     *              the correct callback is initiated on validation success/fail
     */
    validateForm() {
        let isValid = true;
        Object.entries(this.inputs).forEach((input) => {
            if (!input[1].validate()) {
                isValid = false;
            }
        });

        if (isValid &&
            this.config.onValidationSuccess && typeof this.config.onValidationSuccess === 'function') {
            const formData = {};
            Object.entries(this.inputs).forEach((input) => {
                formData[input[1].getName()] = input[1].getValue();
            });
            this.config.onValidationSuccess(formData);
        } else if (!isValid &&
            this.config.onValidationFail && typeof this.config.onValidationFail === 'function') {
            // e.preventDefault();
            this.config.onValidationFail();
        }
    }

    /**
     * @method addInputControl
     * @description adds the correct input control for the input type
     * @param config {Object} configuration object
     */
    addInputControl(config) {
        let className = '';
        switch (config.controlType) {
        case INPUT_CONTROL_TYPES.TEXT:
            className = 'InputTextControl';
            break;
        case INPUT_CONTROL_TYPES.TEXT_AREA:
            className = 'InputTextAreaControl';
            break;
        case INPUT_CONTROL_TYPES.RADIO:
            className = 'InputRadioControl';
            break;
        case INPUT_CONTROL_TYPES.RADIO_GROUP:
            className = 'InputRadioGroupControl';
            break;
        case INPUT_CONTROL_TYPES.CHECKBOX:
            className = 'InputCheckboxControl';
            break;
        case INPUT_CONTROL_TYPES.SELECT:
            className = 'SelectControl';
            break;
        case INPUT_CONTROL_TYPES.ReCAPTCHA:
            className = 'ReCAPTCHA';
            break;
        default:
            return null;
        }

        return this.addInputControlElm(className, config);
    }

    /**
     * @method addInputControlElm
     * @description creates and adds a specific InputControl element to the form
     * @param className {String} the class name of the InputControl to use
     * @param inputConfig {Object} configuration object
     * @see InputTextControl
     */
    addInputControlElm(className, inputConfig) {
        const config = {
            ...{ id: generateUniqueID() },
            ...inputConfig
        };
        switch (className) {
        case 'SelectControl':
            this.inputs[config.id] = new INPUT_CONTROL_CLASSES[className](null, config);
            break;

        default:
            this.inputs[config.id] = new INPUT_CONTROL_CLASSES[className](config);
            break;
        }

        renderer.append(this.inputs[config.id].render(), this.inputsWrapper);
        return this.inputs[config.id];
    }

    /**
     * @method showServerError
     * @description show message when is an error on the server side
     */
    showServerError() {
        if (!this.serverError) {
            this.serverError = new FormError({
                ...this.config.serverError
            });
        }

        this.serverError.show(this.errorElement);
    }

    /**
     * @method hideServerError
     * @description Remove the serverError
     */
    hideServerError() {
        if (this.serverError) {
            this.serverError.hide();
        }
    }

    /**
     * @method render
     * @description Return the element containing the simple form
     */
    render() {
        return this.element;
    }

    /**
     * @method destroy
     * @description  remove all reference for the class
     */
    destroy() {
        this.element.remove();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { renderer, formatNumber } from 'utils';

// Local dependencies
import chosenVehicleTemplate from './../templates/chosenVehicleTemplate';
import vehicleSelectorContent from './../config/content';

/**
 * @constant ATTRIBUTES
 * @description Attribute references for the VehicleSelector view
 * @type {{DISCLAIMER_MARKER: string}}
 */
const ATTRIBUTES = {
    DISCLAIMER_MARKER: 'data-chosen-vehicle-disclaimer'
};

/**
 * @class ChosenVehicle
 * @description Displays chosen vehicle details (name, price, thumbnail image)
 * @param {Object} element
 */
export default class ChosenVehicle {
    /**
     * @method constructor
     * @description View component for displaying a ChosenVehicle
     * @param {Object} element
     * @param {Object} chosenVehicleData
     */
    constructor(element, chosenVehicleData, country, language, brand, currency, showYear) {
        this.element = element;
        this.country = country;
        this.language = language;
        this.brand = brand;
        this.currency = currency;
        this.chosenVehicleData = chosenVehicleData;
        this.showYear = showYear;
        this.disclaimerMarker = null;
        this.msrpDisclaimerLegend = this.getMsrpDisclaimerLegend();
        this.onDisclaimerClickHandler = this.onDisclaimerClick.bind(this);
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.createView();
        this.cacheDOM();
        this.attachEvents();
    }

    /**
     * @method createView
     * @description Create view for chosen vehicle details
     */
    createView() {
        this.chosenVehicleElement = chosenVehicleTemplate({
            chosenVehicle: {
                name: this.chosenVehicleData.vehicleName,
                nameWithYear: this.chosenVehicleData.vehicleNameWithYear,
                msrp: this.chosenVehicleData.model.msrp,
                msrpFormatted: formatNumber.toCurrency(
                    this.chosenVehicleData.model.msrp, this.country, this.language, this.currency
                ),
                jellybean: this.chosenVehicleData.model.modelTileImage,
                class: this.chosenVehicleData.model.vehicleClass.code,
                model: this.chosenVehicleData.model.code,
                bodystyle: this.chosenVehicleData.bodystyle.code,
                year: this.chosenVehicleData.model.year
            },
            startingAtLabel: vehicleSelectorContent.startingAt,
            priceComingSoonLabel: vehicleSelectorContent.priceComingSoon,
            msrpDisclaimerLegend: this.msrpDisclaimerLegend,
            showYear: this.showYear
        })({ getNode: true });

        renderer.insert(this.chosenVehicleElement, this.element);
    }

    /**
     * @method cacheDOM
     * @description caches DOM elements
     */
    cacheDOM() {
        this.disclaimerMarker = this.chosenVehicleElement.querySelector(`[${ATTRIBUTES.DISCLAIMER_MARKER}]`);
    }

    /**
     * @method attachEvents
     * @description Attaches click event to the switch vehicle link
     */
    attachEvents() {
        if (this.disclaimerMarker) {
            this.disclaimerMarker.addEventListener(EVENTS.CLICK, this.onDisclaimerClickHandler);
        }
    }

    /**
     * @method detachEvents
     * @description Attaches event listeners to and callbacks to the view
     */
    detachEvents() {
        if (this.disclaimerMarker) {
            this.disclaimerMarker.removeEventListener(EVENTS.CLICK, this.onDisclaimerClickHandler);
        }
    }

    /**
     * @method destroy
     * @description When the module is destroyed, detaches all events
     */
    destroy() {
        this.detachEvents();
        renderer.empty(this.element);
    }

    /**
     * @method getMsrpDisclaimerLegend
     * @description Get MSRP Global disclaimer legend from pageData
     */
    getMsrpDisclaimerLegend() {
        const { globalDisclaimerLegends } = window.mbVans.pageData;
        return globalDisclaimerLegends ? globalDisclaimerLegends.MSRP : '';
    }

    /**
     * @method onDisclaimerClick
     * @description
     */
    onDisclaimerClick() {
        window.dispatchEvent(
            new CustomEvent('DISCLAIMER:OPEN_DISCLAIMER',
                {
                    detail: {
                        currentTarget: this.disclaimerMarker,
                        legend: this.msrpDisclaimerLegend
                    }
                })
        );
    }

    /**
     * node()
     * gets the innerHTML of the element
     * @returns innerHTML
     */
    get node() {
        return this.element.innerHTML;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for custom radio card button control
 * Uses ES6 template literal to composite the markup for a custom radio card button control
 */
export default ({ id = '', name = '', inputClassNames = '', labelClassNames = '', eyebrow = '',
    checked = false, valueText = '', labelText = '',
    extendedDescription = '', iconClass = 'icon-checkmark-disc', analyticsTrigger = '', displayIcons = true }) => htmlNode`
    <div ${inputClassNames ? `class="${inputClassNames}"` : ''}>
        <label ${id ? `for="${id}"` : ''} class="form__radio-card-control">
            <input class="form__radio-card" 
                type="radio"
                ${checked ? ' checked' : ''}
                ${id ? `id="${id}"` : ''}
                ${name ? `name="${name}"` : ''}
                ${valueText ? ` value="${valueText}"` : ''}
                ${analyticsTrigger ? `data-analytics-trigger="${analyticsTrigger}"` : ''}
            />  
            <div class='form__radio-card-label-wrapper ${eyebrow ? 'form__radio-card-label-wrapper--with-eyebrow' : ''}'> 
                <div>     
                    ${
                        eyebrow ?
                        `<div class="form__radio-card-eyebrow">
                            ${eyebrow}
                        </div>` :
                        ''
                    }
                    ${
                        displayIcons ?
                        `<div class="form__radio-card-icon">
                            <i class="icon-mb icon-checkmark-disc-stroke form__radio-card-icon--selected"></i>
                            <i class="${`icon-mb ${iconClass} form__radio-card-icon--unselected`}"></i>
                        </div>` :
                        ''
                    }
                    <p class="form__radio-card-label ${labelClassNames}">
                        ${labelText}
                    </p>
                    ${
                        extendedDescription ?
                        `<p class="form__radio-card-extended">
                            ${extendedDescription}
                        </p>` :
                        ''
                    }
                </div>
            </div>
        </label>
    </div>
`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Local dependencies
import NonResponsivePicture from './NonResponsivePicture';

/**
 * @class NonResponsivePictureLoader
 * @description Responsible for loading a Non Responsive Picture asset and displaying
 * a loader spinner while the asset loads
 */
export default class NonResponsivePictureLoader {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration for a NonResponsivePictureLoader
     * @type {Object}
     * @const imagePath {String} Image path to load
     * @const alt {String} alt text for img tag
     * @const fallbackMedia {Object} fallback media object - imgS: String, imgL: String, alt: String
     * @const className {String} class name for the picture loader element
     * @const itemProp {String} Value of schema `itemProp` markup
     * @const onPictureLoaded {Function} Callback to be executed when a picture's onLoad event is fired
     */
    static DEFAULT_CONFIG = {
        imagePath: '',
        alt: '',
        fallbackMedia: null,
        className: [],
        itemProp: null,
        onPictureLoaded: null
    };

    /**
     * @constructor Create a NonResponsivePictureLoader
     * @param config {Object} Configuration data
     */
    constructor(config = NonResponsivePictureLoader.DEFAULT_CONFIG) {
        this.config = {
            ...NonResponsivePictureLoader.DEFAULT_CONFIG,
            ...config
        };
        this.element = null;
        this.loader = null;
        this.nonResponsivePicture = null;

        this.onLoad = this.onLoad.bind(this);

        this.createNonResponsivePictureLoader();
    }

    /**
     * @method createNonResponsivePictureLoader
     * @description Creates a NonResponsivePictureLoader
     */
    createNonResponsivePictureLoader() {
        this.element = document.createElement('div');
        this.element.classList.add('content-loader');

        if (this.config.className && this.config.className.length > 0) {
            const classNameArray = this.config.className.split(' ');
            for (let i = 0, length = classNameArray.length; i < length; i += 1) {
                this.element.classList.add(classNameArray[i]);
            }
        }

        this.createLoader();
        this.createNonResponsivePicture();
    }

    /**
     * @method createLoader
     * @description Creates a loading-spinner element and appends it to the pictureLoader
     */
    createLoader() {
        this.loader = document.createElement('div');
        this.loader.classList.add('content-loader__spinner');
        const spinner = document.createElement('div');
        spinner.classList.add('loading-spinner', 'loading-spinner--large');
        this.loader.appendChild(spinner);
        this.element.appendChild(this.loader);
    }

    /**
     * @method createNonResponsivePicture
     * @description Creates a `img` element and appends it to the NonResponsivePictureLoader
     */
    createNonResponsivePicture() {
        const nonResponsivePictureContainer = document.createElement('div');
        nonResponsivePictureContainer.classList.add('content-loader__asset');

        this.nonResponsivePicture = new NonResponsivePicture({
            imagePath: this.config.imagePath,
            alt: this.config.alt,
            fallbackMedia: this.config.fallbackMedia,
            onError: this.onLoad,
            onLoad: this.onLoad,
            itemProp: this.config.itemProp
        });

        nonResponsivePictureContainer.appendChild(this.nonResponsivePicture.render());

        this.element.appendChild(nonResponsivePictureContainer);
    }

    /**
     * @method onLoad
     * @description On successful load of the non responsive picture,
     * remove the loading-spinner and execute any specified callback
     */
    onLoad() {
        this.destroyLoader();
        if (this.config.onPictureLoaded) {
            this.config.onPictureLoaded();
        }
    }

    /**
     * @method destroyLoader
     * @description Destroys the loading-spinner element by deleting it from the DOM
     */
    destroyLoader() {
        if (this.loader) {
            this.loader.remove();
            this.loader = null;
        }
    }

    /**
     * @method destroy
     * @description Destroys the element by deleting it from the DOM
     */
    destroy() {
        this.element.remove();
        this.element = null;
        this.loader = null;
        this.nonResponsivePicture = null;
    }

    /**
     * @method render
     * @description Return the NonResponsivePictureLoader  element
     * @returns {Element} NonResponsivePictureLoader template element
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

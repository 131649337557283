import { tokenReplace } from 'utils';

/**
 * @method getAprData
 * @description Fetches list of all aprs
 * @return {Promise} A promise which resolves with a vehicle object
 */
export function getAprData(aprEndpoint, config) {
    const endpoint = tokenReplace(aprEndpoint, config);
    return fetch(endpoint, {
        headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'origin',
            Referer: window.location.href
        },
        method: 'GET',
        credentials: 'same-origin'
    })
    .then((response) => response.json())
    .then((data) => Promise.resolve(data))
    .catch((error) => {
        Promise.reject(error);
        console.error('No response found in Apr-list api');
        return null;
    });
}

/**
 * @method getAprValue
 * @description Get specific apr value based on the year model and term
 * @param aprList {Array} list of model specific APRs.
 * @param vehicle {Object} Vehicle Properties.
 * @param term {Object} term value for selected vehicle model.
 */
export function getAprValue(aprList, vehicle, term) {
    let modelTermAprKey = null;
    let modelAprKey = null;
    let aprValue = null;
    let modelAprValue = null;
    let defaultApr;
    if (vehicle && term) {
        modelAprKey = `${vehicle.year}-${vehicle.model}`;
        modelTermAprKey = `${vehicle.year}-${vehicle.model}-${term}`;
    }
    if (aprList) {
        [].forEach.call(aprList, (apr) => {
            if (modelTermAprKey && apr.text === modelTermAprKey) {
                aprValue = apr.value;
            } else if (apr.text === modelAprKey) {
                modelAprValue = apr.value;
            } else if (apr.text.toLowerCase() === 'default') {
                defaultApr = apr.value;
            }
        });
    }
    if (aprValue === null) {
        if (modelAprValue) {
            aprValue = modelAprValue;
        } else {
            if (!defaultApr) {
                console.error('Cannot find DEFAULT Apr value in APR List.');
            }
            aprValue = defaultApr;
        }
    }
    return aprValue;
}

export default {
    getAprData,
    getAprValue
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// utils
import {
    formatNumber
} from 'utils';

/**
 * @method calculatePriceByTerms
 * @description Calculates monthly price in terms using user selected term length
 * @param price {string/number} Whole number price of product option
 * @param country {string} country code
 * @param currency {string} currency code
 * @param language {language} language code
 * @param terms {number} user selected term length
 * @return {*}
 */
function calculatePriceByTerms(price, terms, country, currency, language) {
    const defaultTerm = 1;
    const value = Number.isInteger(price) ? formatNumber.toNumber(price) : price;
    const termedValue = value / (terms || defaultTerm);
    const minDecimalValue = Number.isInteger(termedValue) ? 0 : 2;
    return formatNumber.toCurrency(
        (termedValue),
        country,
        language,
        currency,
        2,
        minDecimalValue
    );
}

/**
 * export public api
 */
export default {
    calculatePriceByTerms
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

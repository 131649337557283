export default {
    TYPE: {
        LOCAL_STORAGE: 'local storage'
    },
    STORAGE: {
        // DEALER_ID_COOKIE: '_dealerID',
        // PREFERENCE_MODULE_DEALER_ID_COOKIE: '_preference_module_dealerID',
        LAST_SEARCHED_LOCATION_LOCALSTORAGE: 'LAST_SEARCHED_LOCATION',
        LAST_SELECTED_DEALER_LOCALSTORAGE: 'LAST_SELECTED_DEALER',
        LAST_LOCATION_PROFILE_LS: 'lastLocation',
        PREFERRED_DEALER_LOCALSTORAGE: 'PREFERRED_DEALER',
        USER_PROFILE_LOCALSTORAGE: 'MB_USER_PROFILE'
    },
    TARGET_OFFER_DATA: {
        DEALER_ID: 'dealer_id',
        GEO_LOCATION_ZIP: 'profile.geolocation.zip',
        TARGET: 'target',
        USER_ATTRIBUTE: 'user.crs_attribute_1',
        USER_ATTRIBUTE_QA: 'user.crs_attribute_1_qa'
    },
    PRIORITIZED_DATA_TYPES: {
        LAST_SEARCHED_LOCATION: 'lastSearchedLocation',
        LAST_SELECTED_DEALER: 'lastSelectedDealer',
        PREFERRED_DEALER: 'preferredDealer',
        TARGET_DEALER_ID: 'targetDealer',
        TARGET_GEO_ZIP: 'geolocalization'
    }
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

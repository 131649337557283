// Constant dependencies
import { EVENTS } from 'Constants';

// Util dependencies
import { screen } from 'utils';

// Local dependencies
import AuthenticationApi from './../api/AuthenticationApi';
import authNavLoggedTemplate from './../templates/authNavLoggedTemplate';
import authNavNotLoggedTemplate from './../templates/authNavNotLoggedTemplate';
import AuthNavDropDown from './AuthNavDropDown';
import AuthNavDrawer from './AuthNavDrawer';
import DEFAULT_AUTHENTICATION_INFO from './../config/authenticationInfo';

/**
 * @const CLASSES
 * @description helper for the know classes to catch from the DOM
 * @type {Object}
 */
const CLASSES = {
    AUTH_NAV: 'mbs-auth-nav',
    LINK_LIST: 'top-nav__account-links-container--account-links-active',
    GREETING: 'mbs-auth-nav--greeting',
    NAME: 'mbs-auth-nav__name',
    LOGIN_LINK: 'mbs-auth-nav--login',
    REGISTER_LINK: 'mbs-auth-nav--register',
    LINK_ITEM: 'top-nav__link',
};

/**
 * @const DEFAULT_CONFIG
 * @description helper to map out config object
 * @type {Object}
 */
const DEFAULT_CONFIG = {
    authentication: {
        ...DEFAULT_AUTHENTICATION_INFO,
    },
};

/**
 * @class  AuthNav
 * @description  determines if the user is logged or not
 * defines the state for the authenticated user
 */
export default class AuthNav {
    /**
     * @method constructor
     * @description  manage the default values for the inheritance
     * @param data {Object} the authentication info from the mbVans object
     */
    constructor(config = DEFAULT_CONFIG) {
        this.element = document.querySelector(`.${CLASSES.AUTH_NAV}`);
        this.smallDevice = screen.getState().small;
        this.screenResize = this.screenResize.bind(this);
        this.profile = new AuthenticationApi();
        this.accountCookieData = this.profile.getProfile();
        this.config = {
            ...DEFAULT_CONFIG,
            ...config,
        };
        this.logOutClickHandler = this.logOutClickHandler.bind(this);
        this.createTemplate();
        this.render();
        this.init();
    }

    /**
     * @method init
     * @description  initialize the class
     */
    init() {
        this.cacheDOM();
        this.update();
        this.attachEvents();
    }

    /**
     * @method  createTemplate
     * @description  establish the template base in the
     * state for the authenticated user
     */
    createTemplate() {
        const { login, register, account } = this.config.authentication;
        if (this.accountCookieData.loggedState) {
            this.template = authNavLoggedTemplate(account)({ getNode: true });
        } else {
            this.template = authNavNotLoggedTemplate(
                login,
                register,
                account
            )({ getNode: true });
        }
    }

    /**
     * @method cacheDOM
     * @description  cache the elements required for the class
     */
    cacheDOM() {
        this.drawerContainer = this.element.querySelector(
            `.${CLASSES.LINK_LIST}`
        );
        this.dropdownContainer = this.element.querySelector(
            `.${CLASSES.GREETING}`
        );

        if (!this.accountCookieData.loggedState) {
            this.loginLink = this.template
                .querySelector(`.${CLASSES.LOGIN_LINK}`)
                .querySelector(`.${CLASSES.LINK_ITEM}`);
            this.registerLink = this.template
                .querySelector(`.${CLASSES.REGISTER_LINK}`)
                .querySelector(`.${CLASSES.LINK_ITEM}`);
        }
    }

    /**
     * @method attachEvents
     * @description  add the listeners for the actions required for the class
     */
    attachEvents() {
        screen.addResizeListener(this.screenResize);

        if (!this.accountCookieData.loggedState) {
            this.loginLink.addEventListener(EVENTS.CLICK, () => {
                this.authLinkClickHandler(AuthenticationApi.AUTH_PAGES.LOGIN);
            });

            this.registerLink.addEventListener(EVENTS.CLICK, () => {
                this.authLinkClickHandler(
                    AuthenticationApi.AUTH_PAGES.REGISTRATION
                );
            });
        }
    }

    /**
     * @method detachEvents
     * @description  remove all the listeners required for the class
     */
    detachEvents() {
        screen.removeResizeListener(this.screenResize);

        if (!this.accountCookieData.loggedState) {
            this.loginLink.removeEventListener(
                EVENTS.CLICK,
                this.authLinkClickHandler
            );
            this.registerLink.removeEventListener(
                EVENTS.CLICK,
                this.authLinkClickHandler
            );
        }
    }

    /**
     * @method authLinkClickHandler
     * @description Callback to apply when the login/register link is clicked to
     * prevent the default and apply the this.profile.authRedirect method to
     * log the user out
     * @param pageType {String} page type to redirect to: 'registration', 'login' or 'logout'
     * @param e {Event} Click event
     */
    authLinkClickHandler(pageType, e) {
        e.preventDefault();
        this.profile.authRedirect(pageType);
    }

    /**
     * @method logOutClickHandler
     * @description Callback to apply when the logOutCta is clicked to
     * prevent the default and apply the this.profile.authRedirect method to
     * log the user out
     * @param e {Event} Click event
     */
    logOutClickHandler(e) {
        e.preventDefault();
        this.profile.authRedirect(AuthenticationApi.AUTH_PAGES.LOGOUT);
    }

    /**
     * @methdo screenResize
     * @description  manage the state when the scree changes
     */
    screenResize() {
        this.smallDevice = screen.getState().small;

        this.update();
    }

    /**
     * @method createNav
     * @description manage the state for the authenticated view,
     * small will show the drawer and remove the dropdown,
     * large will show the dropdown and remove the drawer
     */
    createNav() {
        const { account } = this.config.authentication;
        if (this.smallDevice && !this.smallNav) {
            this.smallNav = new AuthNavDrawer(this.drawerContainer, {
                links: account.links,
                authentication: this.config.authentication,
                onLogout: this.logOutClickHandler,
            });

            if (this.largeNav) {
                this.largeNav.destroy();
                delete this.largeNav;
            }
        } else if (!this.smallDevice && !this.largeNav) {
            this.largeNav = new AuthNavDropDown(this.dropdownContainer, {
                links: account.links,
                profile: this.accountCookieData.accountData,
                authentication: this.config.authentication,
                onLogout: this.logOutClickHandler,
            });

            if (this.smallNav) {
                this.smallNav.destroy();
                delete this.smallNav;
            }
        }
    }

    /**
     * @method update
     * @description  manage the update state for the class
     */
    update() {
        if (this.accountCookieData.loggedState) {
            this.createNav();
        }
    }

    /**
     * @method  render
     * @description  add the class to the DOM
     */
    render() {
        this.element.appendChild(this.template);
    }

    /**
     * @method destroy
     */
    destroy() {
        this.detachEvents();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

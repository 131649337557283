import { formatNumber } from 'utils';

import { validateSpecialOffer } from './paymentEstimatorSpecialOffersApi';
import PAYMENT_ESTIMATOR_CONSTANTS from '../constants/PaymentEstimatorConstants';

/**
 * @function calculateDownPayment
 * @description Calculate downpayment based on following logic:
 * - If downpayment already exists, use downpayment
 * - Else if 10% vehicle price > 25000, use 25000; else 10% vehicle price
 * @param {Number} downPayment - Downpayment if available
 * @param {Number} vehiclePrice - Price of vehicle, callee responsible for adjusting price after accessories
 * @param {Number} maxDownPayment - Max downpayment amount
 * @param {Number} downPaymentPercentage - Down Payment Percentage
 * @returns {Number} Downpayment amount
 */
function calculateDownPayment(downPayment, vehiclePrice, maxDownPayment, downPaymentPercentage) {
    if (downPayment !== null) {
        return downPayment;
    }

    maxDownPayment = null;
    // Currently PE is not using maxDownPayment as a validation parmeter.
    // If needed back, cookie validations must be updated as well.
    // return defaultDownPayment > maxDownPayment ? maxDownPayment : defaultDownPayment;

    return Math.floor(vehiclePrice * downPaymentPercentage / 100);
}

/**
 * @method getAccessoriesPrice
 * @description adds up the prices for all the packages and options, including options that are in categories such as
 *     color, service, and upfitting.
 * @param vehiclePricing {{subtotals: Object}} - the vehicle pricing object
 * @return {number}
 */
function getAccessoriesPrice(vehiclePricing) {
    let price = 0;

    // byo categories
    ['color', 'packages', 'options', 'service', 'upfitting'].forEach((category) => {
        price += (vehiclePricing.subtotals[category] || {}).price || 0;
    });

    return price;
}
/**
 * @function getInputSource
 * @description derives the appropriate initial inputSource from validated offer and stored data
 * @param {object} selectedOffer already validated
 * @param {object} storedData
 * @returns {string}
 */
function getInputSource(selectedOffer, { inputSource }) {
    const { INPUT_SOURCE } = PAYMENT_ESTIMATOR_CONSTANTS;

    // if we don't have an explicit selection, first priority is special offer
    if (!inputSource) {
        return selectedOffer ? INPUT_SOURCE.SPECIAL_OFFER : INPUT_SOURCE.DEFAULT;
    }
    // 👇 we do have an input source
    if (inputSource === INPUT_SOURCE.SPECIAL_OFFER) {
        return selectedOffer ? inputSource : INPUT_SOURCE.DEFAULT;
    }
    return inputSource;
}

/**
 * @function parseConfigOptions
 * @description Parse config config to convert string values from default config into number
 * @param {Object} config - Config options that need to be parsed
 * @param {Number} vehiclePrice - Vehicle Price (vehicleBuild.pricing.total.price)
 * (vehiclePricing.subtotals.accessories.price)
 * @param {array} vehicleSpecialOffers special offers for the current vehicle
 * @param {Object} storedUserInput - Data stored in cookie
 * @returns {Object} Parsed out config
 */
function parseConfigOptions(config, vehiclePrice, type, vehicleSpecialOffers, storedUserInput) {
    const leaseStored = storedUserInput.lease || {};
    const financeStored = storedUserInput.finance || {};
    const maxDownPayment = parseFloat(config.maxDownPayment);
    const downPaymentPercentage = parseFloat(config.downPaymentPercentage);
    const defaultDownPayment = calculateDownPayment(
        null,
        vehiclePrice,
        maxDownPayment,
        downPaymentPercentage);

    const financeDownPayment = financeStored.downPayment !== undefined ?
        financeStored.downPayment : null;

    const leaseDownPayment = leaseStored.downPayment !== undefined ?
        leaseStored.downPayment : null;

    const financeOffer = validateSpecialOffer(financeStored.specialOffer, vehicleSpecialOffers.finance);
    const leaseOffer = validateSpecialOffer(leaseStored.specialOffer, vehicleSpecialOffers.lease);

    const leaseConfig = {
        ...config,
        ...leaseStored,
        specialOffer: leaseOffer,
        inputSource: getInputSource(leaseOffer, leaseStored)
    };

    const financeConfig = {
        ...config,
        ...financeStored,
        specialOffer: financeOffer,
        inputSource: getInputSource(financeOffer, financeStored)
    };

    return {
        config: {
            apiContentType: config.apiContentType,
            byoEstimatorType: config.byoEstimatorType,
            country: config.country,
            currency: config.currency,
            currencyDecimalDigits: config.currencyDecimalDigits,
            deviceId: config.deviceId,
            deliveryFee: config.destinationDeliveryFee || null,
            eaiAppId: config.eaiAppId,
            featureManagement: config.featureManagement,
            financialDisclaimer: typeof (config.financialDisclaimer) === 'string' && Boolean(config.financialDisclaimer) ?
                JSON.parse(config.financialDisclaimer) :
                config.financialDisclaimer,
            msrpDisclaimer: typeof (config.msrpDisclaimer) === 'string' && Boolean(config.msrpDisclaimer) ?
            JSON.parse(config.msrpDisclaimer) :
            config.msrpDisclaimer,
            monthlyPaymentDisclaimer: typeof (config.monthlyPaymentDisclaimer) === 'string' && Boolean(config.monthlyPaymentDisclaimer) ?
            JSON.parse(config.monthlyPaymentDisclaimer) :
            config.monthlyPaymentDisclaimer,
            language: config.language,
            myMBFSVersion: config.myMBFSVersion,
            networkType: config.networkType,
            requestor: config.requestor,
            serviceEndpoint: config.serviceEndpoint,
            supportsSpecialOffers: config.supportsSpecialOffers,
            specialOffersApiUrl: config.specialOffersApiUrl,
            systemId: config.systemId
        },
        linkOuts: {
            blackBookURL: config.blackBookURL
        },
        options: {
            lease: {
                apr: parseFloat(leaseConfig.apr).toFixed(2),
                disableAPR: (leaseConfig.disableAPR === 'true'),
                downPaymentPercentage,
                defaultDownPayment,
                downPayment: leaseDownPayment,
                term: parseFloat(leaseConfig.leaseTerm),
                annualMileages: leaseConfig.leaseAnnualMileageOptions.split(','),
                inputSource: leaseConfig.inputSource,
                wasCustomized: leaseConfig.wasCustomized,
                leaseTerms: leaseConfig.leaseTermOptions.split(','),
                maxDownPayment,
                minLeaseRate: parseFloat(leaseConfig.minLeaseRate),
                mileage: parseFloat(leaseConfig.mileage),
                tradeValue: parseFloat(leaseConfig.tradeValue),
                disableAmountDueAtSigning: (leaseConfig.disableAmountDueAtSigning === 'true'),
                specialOffer: leaseConfig.specialOffer,
                vehicleSpecialOffers: vehicleSpecialOffers.lease,
            },
            finance: {
                apr: financeConfig.apr === '' ? '' : parseFloat(financeConfig.apr).toFixed(2),
                disableAPR: (financeConfig.disableAPR === 'true'),
                downPaymentPercentage,
                defaultDownPayment,
                downPayment: financeDownPayment,
                term: parseFloat(financeConfig.financeTerm),
                financeTerms: financeConfig.financeTermOptions.split(','),
                inputSource: financeConfig.inputSource,
                wasCustomized: financeConfig.wasCustomized,
                maxDownPayment,
                minFinanceAmount: parseFloat(financeConfig.minFinanceAmount),
                minLeaseRate: parseFloat(financeConfig.minLeaseRate),
                mileage: parseFloat(financeConfig.mileage), // TODO: remove with WebService integration
                tradeValue: parseFloat(financeConfig.tradeValue),
                isBalloon: financeConfig.disableBalloonFinance === 'false' && financeConfig.isBalloon === true,
                disableBalloonFinanceOption: (financeConfig.disableBalloonFinance === 'true'),
                specialOffer: financeConfig.specialOffer,
                vehicleSpecialOffers: vehicleSpecialOffers.finance,
            }
        }
    };
}

/**
 * @function createSelectControlItems
 * @description Helper to create array of option items for SelectControl
 * @param type {Array} Holds the array of options values
 * @param label {String} Additional SelectControl option label text
 * @param formatValCallback {Function} Callback to format the value in the label text
 * @return {Array} Array of option values configured for SelectControl
 */
function createSelectControlItems(type, label = '', formatValCallback = (val) => val) {
    const selectControlItemArr = [];

    type.forEach((item, index) => {
        selectControlItemArr.push({
            label: `${formatValCallback(item, index)} ${label}`,
            value: item
        });
    });

    return selectControlItemArr;
}

/**
 * @function validateDownPaymentTradeInTotalLease
 * @description Validates downpayement and tradeIn values for lease
 * @param {object} options PE options
 * @param {number} totalPrice total price of vehicle
 * @param {number} downPayment
 * @param {number} tradeIn
 * @return {boolean} is valid
 */
function validateDownPaymentTradeInTotalLease(options,
    totalPrice, downPayment, tradeIn) {
    const { minLeaseRate } = options;
    const isValid = downPayment + tradeIn <= totalPrice * minLeaseRate;

    return isValid;
}

/**
 * @function validateDownPaymentTradeInTotalFinance
 * @description Validates downpayement and tradeIn values for finance
 * @param {object} options PE options
 * @param {number} totalPrice total price of vehicle
 * @param {number} downPayment
 * @param {number} tradeIn
 * @return {boolean} is valid
 */
function validateDownPaymentTradeInTotalFinance(options,
    totalPrice, downPayment, tradeIn) {
    const maxFinanceAmount = options.minFinanceAmount;
    const isValid = downPayment + tradeIn <= totalPrice - maxFinanceAmount;
    return isValid;
}

/**
 * @function validateDownPaymentTradeInTotal
 * @description Validates downpayement and tradeIn values for lease
 * @param {boolean} isLease true for lease, false for finance
 * @param {object} configuration general PE and site configuration
 * @param {function} configuration.onPrevalidate optional function to call before returning
 * @param {object} configuration.config site configuration
 * @param {object} configuration.options PE options
 * @param {number} totalPrice total price of vehicle
 * @param {string|number} downPaymentRaw
 * @param {string|number} tradeInRaw
 * @return {boolean} is valid
 */
function validateDownpaymentTradeInTotal(isLease, { onPrevalidate, config, options },
    totalPrice, downPaymentRaw, tradeInRaw) {
    const validatorFn = isLease ? validateDownPaymentTradeInTotalLease : validateDownPaymentTradeInTotalFinance;

    const downPayment = downPaymentRaw === null ? options.defaultDownPayment :
    (downPaymentRaw && formatNumber.toNumber(
        downPaymentRaw, config.country, config.language)
        ) || 0;

    const tradeIn = (tradeInRaw && formatNumber.toNumber(
        tradeInRaw, config.country, config.language)
        ) || 0;

    const isValid = validatorFn(options, totalPrice, downPayment, tradeIn);
    if (typeof onPrevalidate === 'function') {
        onPrevalidate(isValid);
    }

    return isValid;
}

export default {
    parseConfigOptions,
    createSelectControlItems,
    calculateDownPayment,
    getAccessoriesPrice,
    validateDownpaymentTradeInTotal
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

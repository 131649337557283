import { htmlNode } from 'utils';

const warrantyDetailInfoContainer = (content, warrantyInfo) => htmlNode`
    <div class="warranty__container" data-analytics-container="{'blade': 'recall-details'}">
        <header class="warranty__header">
            <h2 class="warranty__title">${warrantyInfo.recallsDetail.result.page.year} ${warrantyInfo.recallsDetail.result.page.model}</h2>
            <strong class="warranty__subhead">${content.heading.vin}: ${warrantyInfo.recallsDetail.result.page.vin}</strong>
        </header>
        <div class="warranty__content">
            <div class="warranty__content-child">
                <div class="warranty__manuals">
                    <h2 class="warranty__manuals-title"> ${content.manuals.title} </h2>
                    ${warrantyInfo.warrantyDetail.errorMsg
                        ?   `${content.manuals.messageBeforeLink} <a class="link link_plain-link" href="${content.manuals.noCampaignsLink}" target="_blank" rel="noopener noreferrer">${content.manuals.linkText}</a> ${content.manuals.messageAfterLink}`
                        :   `<div class="warranty__manuals-links">
                                <a class="link" href="${
                                    warrantyInfo.recallsDetail.result.page.model.substring(0, 2).toLowerCase() === 'mm'
                                    ? `${content.manuals.ownerManual.link}/${warrantyInfo.recallsDetail.result.page.year}/${content.manuals.ownerManual.mmPdfFileName}`
                                    : `${content.manuals.ownerManual.link}/${warrantyInfo.recallsDetail.result.page.year}/${content.manuals.ownerManual.pdfFileName}`
                                }" target="_blank" rel="noopener noreferrer">
                                    ${content.manuals.ownerManual.label}
                                </a>
                            </div>
                            <div class="warranty__manuals-links">
                                <a class="link" href="${
                                    warrantyInfo.recallsDetail.result.page.model.substring(0, 2).toLowerCase() === 'mm'
                                    ? `${content.manuals.ownerManualSpanish.link}/${warrantyInfo.recallsDetail.result.page.year}/${content.manuals.ownerManualSpanish.mmPdfFileName}`
                                    : `${content.manuals.ownerManualSpanish.link}/${warrantyInfo.recallsDetail.result.page.year}/${content.manuals.ownerManualSpanish.pdfFileName}`
                                }" target="_blank" rel="noopener noreferrer">
                                    ${content.manuals.ownerManualSpanish.label}
                                </a>
                            </div>
                            <div class="warranty__manuals-links">
                                <a class="link" href="${
                                    warrantyInfo.recallsDetail.result.page.model.substring(0, 2).toLowerCase() === 'mm'
                                    ? `${content.manuals.warrantyBooklet.link}/${warrantyInfo.recallsDetail.result.page.year}/${warrantyInfo.recallsDetail.result.page.year}-${content.manuals.warrantyBooklet.mmPdfFileBaseName}`
                                    : `${content.manuals.warrantyBooklet.link}/${warrantyInfo.recallsDetail.result.page.year}/${warrantyInfo.recallsDetail.result.page.year}-${content.manuals.warrantyBooklet.pdfFileBaseName}`
                                }" target="_blank" rel="noopener noreferrer">
                                    ${content.manuals.warrantyBooklet.label}
                                </a>
                            </div>`
                    }
                </div>
                <div class="warranty__campaigns">
                    <h2 class="warranty__campaigns-title"> ${content.campaigns.title} </h2>
                    ${content.campaigns.messageBeforeLink} <a class="link link_plain-link" href="${content.campaigns.link}#${warrantyInfo.vin}" target="_blank" rel="noopener noreferrer">${content.campaigns.linkText}</a> ${content.campaigns.messageAfterLink}
                </div>
            </div>
            <div class="warranty__coverage">
                <h2 class="warranty__coverage-title"> ${content.warrantyCoverage.title} </h2>
                ${warrantyInfo.warrantyDetail.errorMsg ?
                    `<p class="warranty__error-info">${warrantyInfo.warrantyDetail.errorMsg}</p>` :
                    `<div class="warranty__coverage-data">
                        ${Object.keys(warrantyInfo.warrantyDetail).map((warranty) => `
                            ${warrantyInfo.warrantyDetail[warranty] && warranty !== 'footer' && warranty !== 'disclaimer'  ?
                                `<div class="warranty__coverage-data-child-container">
                                    <div class="warranty__coverage-data-child-container-label">
                                        <div>
                                            ${warrantyInfo.warrantyDetail[warranty].label}
                                        </div>
                                        ${warrantyInfo.warrantyDetail[warranty].subHead
                                            ? `<div class="warranty__coverage-data-child-container-subhead">
                                                    ${warrantyInfo.warrantyDetail[warranty].subHead}
                                                </div>`
                                            : ''
                                        }
                                    </div>
                                    <div class="warranty__coverage-data-child-container-value">
                                        ${warrantyInfo.warrantyDetail[warranty].value}
                                    </div>
                                </div>` : ''
                            }`).join('')
                        }
                        ${!warrantyInfo.warrantyDetail.disclaimer ? '' :
                            `<p class="warranty__coverage-notice">
                                ${warrantyInfo.warrantyDetail.disclaimer}
                            </p>`
                        }
                    </div>
                    ${!warrantyInfo.warrantyDetail.footer ? '' :
                        `<p class="warranty__coverage-action">${warrantyInfo.warrantyDetail.footer.value}</p>`
                    }`
                }
            </div>
        </div>
    </div>
`;

export default warrantyDetailInfoContainer;

import clickShieldTemplate from './clickShieldTemplate';
import detailTemplate from './detailTemplate';
import loadingTemplate from './loadingTemplate';
import accelerationDisclaimerTemplate from './accelerationDisclaimerTemplate';
import minSizeViolationTemplate from './minSizeViolationTemplate';

export {
    clickShieldTemplate,
    detailTemplate,
    loadingTemplate,
    accelerationDisclaimerTemplate,
    minSizeViolationTemplate
};

export default {
    clickShieldTemplate,
    detailTemplate,
    loadingTemplate,
    accelerationDisclaimerTemplate,
    minSizeViolationTemplate
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a Dealer Locator app.
 * Uses ES6 template literal to composite the markup for a Dealer Locator
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying dealer locator search module
 * @param content {Object}
 * @param config {Object} Config flags to display elements
 */
export default (content, { featureFlags: flags, theme = '', mapModalToggleDisabled = false }) => htmlNode`
    <div class="dealer-locator-module ${theme.toLowerCase()}">
        <h1 class="dealer-locator-module__heading hide-large">${content.moduleHeading}</h1>
        <div class="dealer-locator-module__subheading-container hide-large"></div>
        <div class="dealer-locator-module__container">
            <div class="dealer-locator-module__dealer-detail-container"></div>
            <div class="dealer-locator-module__search-container">
                <h1 class="dealer-locator-module__heading hide-small">${content.moduleHeading}</h1>
                <div class="dealer-locator-module__subheading-container hide-small"></div>
                <div class="wrapper ${mapModalToggleDisabled ? 'hide' : ''}">
                    <button class="dealer-locator-module__showmap-cta hide" aria-controls="dealer-locator-module-map-in-modal">${content.showMapCTA}</button>
                </div>
                <div class="dealer-locator-module__stickybox">
                    <div class="dealer-locator-module__change-block"></div>
                    <div class="wrapper dealer-locator-module__search-bar" data-dealer-search-bar=""></div>
                    <div class="dealer-locator-module__filter-bar"><div class="wrapper"></div></div>
                </div>
                <div class="dealer-locator-module__preferred-dealer"></div>
                <div class="dealer-locator-module__results">
                </div>
                ${flags.showBrowseState ?
                    `<div class="dealer-locator-module__cta-container" data-analytics-container="dealer-footer">
                        <button class="link link_plain-link dealer-locator-module__browse-all" data-analytics-trigger="cta">${content.browseAllCTA}</button>
                        ${flags.showFilterBar ? `<button class="dealer-locator-module__reset-filters link link_plain-link hide" data-analytics-trigger="cta">${content.resetFilters}</a>` : ''}
                    </div>` : ''}
                <div class="dealer-locator-module__spinner-container"></div>
            </div>
        </div>
        <div id="dealer-locator-module-map" class="dealer-locator-module__map ${mapModalToggleDisabled ? 'dealer-locator-module__map--show-small' : ''}" data-dealer-map=""></div>
        <div id="dealer-locator-module-map-in-modal" class="dealer-locator-module__map dealer-locator-module__map-in-modal ${mapModalToggleDisabled ? 'hide' : ''}" data-dealer-map=""></div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

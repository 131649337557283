/**
 * @static PAYMENT_TYPES
 * @description payment type values
 */
export default {
    RETAIL: 'Retail',
    LEASE: 'Lease'
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for a LocationSearch Option.
 * Uses ES6 template literal to composite the markup for a LocationSearch Option
 */
import { htmlNode } from 'utils';

/**
 * Template for displaying a location search option
 * @param placeId {String} location place Id
 * @param cityName {String} City name to display
 * @param stateId {String} State acronym to display
 */
export default (placeId, cityName, stateId) => htmlNode`
    <li class="location-search__location"
        data-location="${placeId}"
        tabindex="-1"
        role="option">
        ${cityName}, ${stateId}
    </li>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { noop } from 'utils';

import { LazyLoader } from 'modules/LazyLoader';

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{RESPONSIVE_IMAGE: string, ASSET_MINIMIZED: string}}
 */
const CLASSES = {
    RESPONSIVE_IMAGE: 'responsive-image',
    ASSET_MINIMIZED: 'lazy-loader__asset--minimized',
    HAS_SPINNER: 'lazy-loader--has-spinner'
};

/**
 * @const ELEMENTS
 * @description Collection of constant values for related DOM elements of the module
 * @type {{PICTURE: string, SOURCE: string, IMG: string}}
 */
const ELEMENTS = {
    PICTURE: 'picture',
    SOURCE: 'source',
    IMG: 'img'
};

/**
 * @class LazyPicture
 * @description Responsible for show the images from the media object
 */
export default class LazyPicture {
    /**
     * @static DEFAULT_CONFIG
     * @description Default configuration for a LazyPicture
     * @type {Object}
     * @const media {Object} media object - imgS: String, imgL: String, alt: String
     * @const fallbackMedia {Object} fallback media object - imgS: String, imgL: String, alt: String
     * @const forceLoad {Boolean} Indicator to force load the media when initializing
     * @const className {String} class name for the `picture` element
     * @param onError {Function} callback method for when a `picture` element has an error loading
     * @const onLoad {Function} callback method for when a `picture` element has completed loading
     * @const itemProp {String} Value of schema `itemProp` markup
     * @const displaySpinner {Boolean} Flag to display the spinner animation while the image is loading
     */
    static DEFAULT_CONFIG = {
        media: {},
        fallbackMedia: null,
        forceLoad: false,
        className: '',
        onError: noop,
        onLoad: noop,
        itemProp: null,
        deferLazyInit: false,
        displaySpinner: false
    };

    /**
     * @constructor Create a LazyPicture
     * @param config {Object} Configuration data
     */
    constructor(config = LazyPicture.DEFAULT_CONFIG) {
        this.config = {
            ...LazyPicture.DEFAULT_CONFIG,
            ...config
        };
        this.element = null;
        this.imageElem = null;
        this.srcElem = null;

        this.createPicture();

        if (!this.config.deferLazyInit) {
            this.initLazyLoader();
        }
    }

    /**
     * @method createPicture
     * @description Creates a `picture` element
     */
    createPicture() {
        this.element = document.createElement(ELEMENTS.PICTURE);

        if (this.config.className) {
            if (this.config.className.indexOf(' ') !== -1) {
                const classes = this.config.className.split(' ');
                classes.forEach((className) => {
                    if (className) {
                        this.addClassName(className);
                    }
                });
            } else {
                this.addClassName(this.config.className);
            }
        }

        if (this.config.itemProp) {
            this.element.setAttribute('itemprop', this.config.itemProp);
        }

        if (this.config.forceLoad) {
            this.element.setAttribute('data-lazy-force-load', this.config.forceLoad);
        }

        if (this.config.displaySpinner) {
            this.element.classList.add(CLASSES.HAS_SPINNER);
        }

        this.createSrcElem();
        this.createImageElem();

        this.attachEvents();

        this.element.appendChild(this.srcElem);
        this.element.appendChild(this.imageElem);

        this.setPictureSource(this.config.media);
    }

    /**
     * @method createSrcElem
     * @description Creates the `picture` `source` element
     */
    createSrcElem() {
        this.srcElem = document.createElement(ELEMENTS.SOURCE);
        this.srcElem.setAttribute('data-lazy-src', '');
        this.srcElem.media = '(max-width: 767px)';
        this.srcElem.srcset = '/etc/designs/mb-vans/images/blank.gif';
    }

    /**
     * @method createImageElem
     * @description Creates the `picture` `image` element
     */
    createImageElem() {
        this.imageElem = document.createElement(ELEMENTS.IMG);
        this.imageElem.alt = this.config.media.alt;
        this.imageElem.setAttribute('data-lazy-src', '');
        this.imageElem.classList.add(CLASSES.RESPONSIVE_IMAGE, CLASSES.ASSET_MINIMIZED);
        this.imageElem.src = '/etc/designs/mb-vans/images/blank.gif';

        if (this.config.role) {
            this.imageElem.setAttribute('role', this.config.role);
        }
    }

    /**
     * @method setPictureSource
     * @description Sets the image sources for the `picture` element
     * @param media {Object} media object
     */
    setPictureSource(media) {
        this.srcElem.dataset.lazySrc = media.imgS || '';
        this.imageElem.dataset.lazySrc = media.imgL;
    }

    addClassName(className) {
        this.element.classList.add(className);
    }

    /**
     * @method attachEvents
     * @description Attach events to the `picture` element
     */
    attachEvents() {
        this.element.onload = this.config.onLoad.bind(this);
        this.imageElem.onload = this.onImageLoad.bind(this);
        this.element.onerror = this.onError.bind(this);
        this.imageElem.onerror = this.onError.bind(this);
    }

    /**
     * @method removeEvents
     * @description Removes events attached to the `picture` element
     */
    removeEvents() {
        this.element.onload = null;
        this.imageElem.onload = null;
        this.element.onerror = null;
        this.imageElem.onerror = null;
    }

    /**
     * @method onImageLoad
     * @description Callback method for img.onload event
     */
    onImageLoad() {
        if (this.config.deferLazyInit) {
            this.initLazyLoader();
        }
        this.config.onLoad();
    }

    /**
     * @method onError
     * @description Update image source to fallback media
     */
    onError() {
        const { fallbackMedia } = this.config;
        const { srcElem, imageElem } = this;

        if (fallbackMedia) {
            if ((!srcElem.srcSet || srcElem.srcSet !== fallbackMedia.imgS) && imageElem.src !== fallbackMedia.imgL) {
                this.element.onerror = null;
                this.imageElem.onerror = null;
            }

            this.setPictureSource(this.config.fallbackMedia);
            this.lazyLoader.loadAssets();
        }

        this.config.onError();
    }

    /**
     * @method initLazyLoader
     * @description Initialzes LazyLoader util class
     */
    initLazyLoader() {
        this.lazyLoader = new LazyLoader(this.element, this.config.rootElementId);
    }

    /**
     * @method destroy
     * @description Destroys the element by deleting it from the DOM
     */
    destroy() {
        this.removeEvents();
        this.element.remove();
        this.element = null;
        this.imageElem = null;
        this.srcElem = null;
    }

    /**
     * @method render
     * @description Return the `picture` element
     * @returns {Element} LazyPicture template element
     */
    render() {
        return this.element;
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

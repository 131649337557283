/**
 * @function constructRoofHeightNote
 * @param {Object} chosenVehicle - vehicle object
 * @returns {String} Roof Height value
 */
function constructRoofHeightNote(chosenVehicle) {
    return (chosenVehicle && chosenVehicle.model && chosenVehicle.model.defaultVariant) ?
        `Roof Height: ${chosenVehicle.model.defaultVariant}.` :
        '';
}

/**
 * @function constructAvailabilityNote
 * @param {Object} chosenVehicle - vehicle object
 * @param {String} content - Availability note content
 * @returns {String} Availability Note: ${availabilityNote}
 */
function constructAvailabilityNote(chosenVehicle, content = {}) {
    return (chosenVehicle && Object.hasOwnProperty.call(chosenVehicle, 'available') && !chosenVehicle.available) ?
        `Availability Note: ${content.availabilityNote}` :
        '';
}

/**
 * @function constructContactMethod
 * @param {Boolean} isContactByPhone - True if preferred contact method is phone
 * @returns {String} Preferred Contact Method: ${PreferredContactMethod}
 */
function constructContactMethod(isContactByPhone) {
    return `
        Preferred Contact Method: ${isContactByPhone ? 'Phone' : 'Email'}
    `.trim();
}

/**
 * @function constructContactTime
 * @param {Boolean} isContactByPhone - True if preferred contact method is phone
 * @param {String} contactTimePreference - Preferred contact time selected
 * @returns {String} Preferred Time: ${PreferredContactTime}
 */
function constructContactTime(isContactByPhone, contactTimePreference) {
    return isContactByPhone ? `Preferred Time: ${contactTimePreference}` : '';
}

/**
 * @function constructTestDriveDate
 * @param {String} testDriveDate - Selected Date for test drive
 * @returns {String} Test Drive Date: ${TestDriveDate}
 */
function constructTestDriveDate(testDriveDate) {
    return testDriveDate ? `Test Drive Date: ${testDriveDate}` : '';
}

/**
 * @function constructTestDriveTime
 * @param {String} testDriveTime - Selected Time for test drive
 * @returns {String} Test Drive Time: ${TestDriveTime}
 */
function constructTestDriveTime(testDriveTime) {
    return testDriveTime ? `Test Drive Time: ${testDriveTime}` : '';
}

/**
 * @function constructCustomerHomeTestDriveAddress
 * @description Creates the customer home test drive preferred comment field,
 * including home test drive method and address
 * @param testDriveMethod {string} user location of test drive preference
 * @param testDriveLocation {string} user input location of home test drive street address
 * @param content {Object} localized content
 * @returns {String} The customer home test drive preferred method and address
 *
 */
function constructCustomerHomeTestDriveAddress(
    testDriveMethod,
    testDriveLocation,
    content = {}
) {
    let homeTestDriveComments = '';
    if (testDriveMethod && testDriveMethod === 'home') {
        homeTestDriveComments = `${content.homeTestDriveNote} ${content.atHome} at ${testDriveLocation}`;
    } else if (testDriveMethod) {
        homeTestDriveComments = `${content.homeTestDriveNote} ${content.atDealership}`;
    }

    return homeTestDriveComments ? homeTestDriveComments.trim() : '';
}

/**
 * @function constructUnsavedBuildComment
 * @param {Object} unsavedBuild - storage data from byo vehicle
 * @returns {String} Vehicle BYO data
 */
function constructUnsavedBuildComment(unsavedBuild) {
    return [
        'This prospect built the following car on the Vans BYO:',
        `Roof: ${unsavedBuild.roof}.`,
        `Options: ${unsavedBuild.options}.`,
        `Packages: ${unsavedBuild.packages}.`
    ].join('\n ');
}

/**
 * @function constructSpecialOffersComment
 * @param {string} offers - selected offers
 * @returns {string} selected offers data
 */
function constructSpecialOffersComment(offers) {
    return `This prospect is interested on the following offers: \n${offers}`;
}

/**
 * @function constructPaymentEstimatorComment
 * @param {string} paymentEstimatorData - selected PE parameters
 * @returns {string} payment estimator comment
 */
function constructPaymentEstimatorComment(paymentEstimatorData) {
    return `This prospect selected the following in payment estimator: \nFinance: ${paymentEstimatorData.finance}\nLease: ${paymentEstimatorData.lease}`;
}

/**
 * @function constructTcoComment
 * @param {Object} tcoInfo - storage data from TCO
 * @returns {String} TCO comment
 */
function constructTcoComment(tcoInfo) {
    return [
        'This prospect selected the following info on Vans TCO:',
        `Criteria: ${JSON.stringify(tcoInfo.criteria).replace('{', '').replace('}', '')}.`,
        `Vehicles: ${JSON.stringify(tcoInfo.vehicles)}.`,
    ].join('\n ');
}

/**
 * @function constructVehicleComments
 * @description Constructs the string that goes into the field vehicle.comments in the required
 * format
 * @param {Object} - object with all the necessary values
 * @param {String} content -  content for the availability note
 * @param {Object} chosenVehicle - vehicle object
 * @param {Boolean} isContactByPhone - True if preferred contact method is phone
 * @param {String} contactTimePreference - Preferred contact time selected
 * @param {Object} tcoInfo - TCO info
 * @param {String} testDriveTime - Selected Time for test drive
 * @param {String} testDriveDate - Selected Date for test drive
 * @param {Object} unsavedBuild - vehicle data from BYO
 * @returns {String} Comment Vehicle: ${CommentFieldValues}
 */
function constructVehicleComments({
    chosenVehicle,
    content,
    isContactByPhone,
    contactTimePreference,
    tcoInfo,
    testDriveMethod,
    testDriveLocation,
    testDriveDate,
    testDriveTime,
    unsavedBuild,
    offers,
    paymentEstimator,
}) {
    return [
        constructRoofHeightNote(chosenVehicle),
        constructAvailabilityNote(chosenVehicle, content),
        constructContactMethod(isContactByPhone),
        constructContactTime(isContactByPhone, contactTimePreference),
        constructTestDriveDate(testDriveDate),
        constructTestDriveTime(testDriveTime),
        constructCustomerHomeTestDriveAddress(testDriveMethod, testDriveLocation, content),
        unsavedBuild ? constructUnsavedBuildComment(unsavedBuild) : null,
        offers ? constructSpecialOffersComment(offers) : null,
        paymentEstimator ? constructPaymentEstimatorComment(paymentEstimator) : null,
        tcoInfo ? constructTcoComment(tcoInfo) : null,
    ].filter((comment) => !!comment).join('\n ');
}

/**
 * @function constructId
 * @description Uses the passed in id to construct the ID array
 * @param {string} id webId
 * @param {Object} eventInfo includes the id and name fields
 * @returns {Array} Array of object, one of which is a webId
 */
function constructId(id, eventInfo) {
    let leadId = [{
        source: 'webId',
        id
    }
    ];

    if (eventInfo) {
        leadId = leadId.concat(
            [
                {
                    source: 'eventName',
                    id: eventInfo.name,
                },
                {
                    source: 'eventId',
                    id: eventInfo.id,
                }
            ]
        );
    }

    return leadId;
}

/**
 * @function constructVehicle
 * @description Constructs the vehicle array object with the data
 * @returns {Array} Array of vehicle objects
 */
function constructVehicle({
    isContactByPhone,
    contactPreference,
    chosenVehicle,
    options,
    tcoInfo,
    testDriveDate,
    testDriveTime,
    testDriveMethod,
    testDriveLocation,
    unsavedBuild,
    offers,
    paymentEstimator,
}) {
    const vehicleComments = constructVehicleComments({
        chosenVehicle,
        contactTimePreference: isContactByPhone ? contactPreference : '',
        content: options.content,
        isContactByPhone,
        tcoInfo,
        testDriveDate,
        testDriveTime,
        testDriveMethod,
        testDriveLocation,
        unsavedBuild,
        offers,
        paymentEstimator,
    });

    const colorcombination = (unsavedBuild &&
        { exteriorcolor: unsavedBuild.exteriorColor, interiorcolor: unsavedBuild.interiorColor }) ||
        (chosenVehicle &&
            { exteriorcolor: chosenVehicle.model.exteriorColor, interiorcolor: chosenVehicle.model.interiorColor }
        );

    return [{
        interest: options.isCPO ? 'buy' : 'lease',
        status: options.isCPO ? 'CPO' : 'new',
        year: chosenVehicle && chosenVehicle.model.year && chosenVehicle.model.year.toString(),
        make: 'Mercedes-Benz',
        model: chosenVehicle ? `${chosenVehicle.model.class} ${chosenVehicle.model.code}` : 'Sprinter Full Line',
        price: {
            currency: options.currency,
            priceAmount: chosenVehicle && chosenVehicle.model.msrp.toString()
        },
        vin: chosenVehicle && chosenVehicle.model.vin,
        class: chosenVehicle && chosenVehicle.model.class,
        colorcombination,
        comments: vehicleComments
    }];
}

/**
 * @function constructCustomerContact
 * @description Constructs the customer contact object
 * @return {Object} An object with the contact info per the request requirements
 */
function constructCustomerContact({
    address,
    firstName,
    lastName,
    email,
    isContactByPhone,
    contactPreference,
    phoneNumber,
    options
}) {
    const contactObj = {
        address,
        firstName,
        lastName,
        email: {
            emailAddress: email,
            isPreferredContactMethod: !isContactByPhone
        },
        languagePreference: options.language
    };

    if (phoneNumber) {
        contactObj.phone = [{
            type: 'phone',
            time: contactPreference,
            phoneNumber,
            isPreferredContactMethod: isContactByPhone
        }];
    }

    return contactObj;
}

/**
 * @function constructCustomerComments
 * @description Creates the customer comment field
 * @returns {String} The customer comment in required format if user entered comment
 */
function constructCustomerComments(comments) {
    return comments ? comments.trim() : '';
}

/**
 * @function constructDealer
 * @description Creates the dealer object
 * @returns {Object} Dealer object with dealer name and code
 */
function constructDealer({ dealerId, name }) {
    return {
        dealerCode: dealerId,
        dealerName: name
    };
}

/**
 * @function
 * @description Constructs the request object required for the Leads API
 * @param {Object} - object with all necessary fields
 * @param {Object} chosenVehicle - Model data of the chosen vehicle. `chosenVehicle` class
 * property of `partials/vehicle-selector/view/ChosenVehicle`
 * @param {Object} unsavedBuild - vehicle data from BYO
 * @param {Object} dealerInfo - Object which has dealer info
 * @param {String} dealerInfo.dealerId - ID of dealer
 * @param {String} dealerInfo.dealerName - Dealer Name
 * @param {Object} firstName - The InputTextControl for First Name field
 * @param {Object} lastName - The InputTextControl for Last Name field
 * @param {Object} email - The InputTextControl for Email field
 * @param {Object} phoneNumber - The InputTextControl for Phone field
 * @param {Object} contactPreference - The SelectControl for contact preference
 * @param {Object} isContactByPhone - The InputCheckboxControl for contact by phone checkbox
 * @param {Object} comments - The InputTextAreaControl for textarea
 * @param {Object} testDriveDate - The DatePickerInput instance for date
 * @param {Object} testDriveTime - The SelectControl for test drive time
 * @param {string} testDriveMethod - The InputRadioGroupControl option for test drive location
 * @param {string} testDriveLocation - User input home test drive address
 * @param {String} recaptcha - The reCAPTCHA value
 * @param {Object} options - additional options will be passed in this object
 * @param {String} options.webId - Web ID
 * @param {Boolean} options.isCPO - Flag to indicate if CPO
 * @param {String} options.currency - ISO Currency code
 * @param {String} options.language - Two character language code
 * @param {Object} options.provider - Provider object
 * @param {String} options.content - content for the availability note
 * @param {Object} options.context - object that accepts any additional contextual data with a lead submission
 */
function createRequestObject({
    address,
    chosenVehicle,
    unsavedBuild,
    offers,
    dealerInfo,
    firstName,
    lastName,
    email,
    phoneNumber,
    contactPreference,
    isContactByPhone,
    comments,
    tcoInfo,
    testDriveDate,
    testDriveTime,
    testDriveMethod,
    testDriveLocation,
    recaptcha,
    options,
    paymentEstimator,
    eventInfo,
} = {}) {
    return {
        prospect: {
            id: constructId(options.webId, eventInfo),
            requestdate: (new Date().toJSON()),
            vehicle: constructVehicle({
                chosenVehicle,
                contactPreference,
                isContactByPhone,
                options,
                tcoInfo,
                testDriveDate,
                testDriveTime,
                testDriveLocation,
                testDriveMethod,
                unsavedBuild,
                offers,
                paymentEstimator,
            }),
            customer: {
                contact: constructCustomerContact({
                    address,
                    firstName,
                    lastName,
                    email,
                    isContactByPhone,
                    contactPreference,
                    phoneNumber,
                    dealerInfo,
                    options
                }),
                comments: constructCustomerComments(comments)
            },
            dealer: constructDealer(dealerInfo),
            recaptcha,
            provider: options.provider
        },
        context: options.context ? options.context : undefined
    };
}

export default {
    createRequestObject
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const INPUT_CONTROL_TYPES
 * @description the different input types available
 */
export const INPUT_CONTROL_TYPES = {
    TEXT: 'text',
    TEXT_AREA: 'textarea',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    RADIO_GROUP: 'radiogroup',
    SELECT: 'select',
    ReCAPTCHA: 'recaptcha'
};

export default INPUT_CONTROL_TYPES;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * DealerSearch Module
 * Has methods related to composition of the header module
 */
// Partial dependencies
import { DealerSearchBar } from 'partials/dealer-search-bar';

// Util dependencies
import { customEventDispatcher, renderer, screen } from 'utils';

// Constant dependencies
import { EVENTS, CUSTOM_EVENTS } from 'Constants';

/**
 * @const defaultLocalization
 * @description Default localization labels for Leads App
 * @type {{searchCTA: string, dealerSearchErrorMessage: string, searchInputLabel: string}}
 */
const defaultLocalization = {
    searchCTA: 'Search',
    dealerSearchErrorMessage: 'Invalid Zip Code or City, State',
    searchInputLabel: 'Enter Zip Code or City, State'
};

/**
 * @const CONFIG
 * @description config options for the dealers search bar
 * @description Default state for the dealer search config.
 */
const CONFIG = {
    searchType: 'dealers',
    searchByType: 'zip',
    searchLocation: null,
    autoSubmitLocation: false
};

/**
 * @const CLASSES
 * @description Collection of constant values for related class attributes of the module
 * @type {{LOCATION_INPUT_SEARCH_BAR_CONTAINER: string, FORM_INPUT_FIELD: string}}
 */
const CLASSES = {
    ERROR: 'error',
    GLOBAL_HEADER_MENU_L1: 'global-header__menu-l1',
    LOCATION_INPUT_SEARCH_BAR_CONTAINER: 'global-header__location-input__search-bar__container',
    FORM_INPUT_FIELD: 'form__input-field'
};

/**
 * @const ATTRIBUTES
 * @description Stores a collection of attributes for use in the DOM
 * @type {{ARIA_INVALID: string}}
 */
const ATTRIBUTES = {
    ARIA_INVALID: 'aria-invalid'
};

export default class DealerSearch {
    constructor(element) {
        this.element = element;
        this.localization = Object.assign(
            defaultLocalization,
            window.mbVans.ns('pageData', 'globalHeader', 'dealerMenu').localization,
        );
        this.navContainer = null;
        this.searchContainer = null;
        this.country = window.mbVans.ns('pageData').country;
        this.brand = window.mbVans.ns('pageData').brand;
        this.init();
    }

    init() {
        this.cacheDOMElements();
        this.setBindings();
        this.searchInput = null;
        if (this.searchContainer) {
            this.createSearchBar();
            this.attachEvents();
        }
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.searchContainer = this.element.querySelector(`.${CLASSES.LOCATION_INPUT_SEARCH_BAR_CONTAINER}`);
        this.navContainer = document.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L1}`);
    }

    setBindings() {
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
    }

    /**
     * @method attachEvents
     * @description Attaches an event listener for search input on focus
     */
    attachEvents() {
        this.searchInput.addEventListener(EVENTS.FOCUS, this.onSearchInputFocus);
    }

    /**
     * @method detachEvents
     * @description Remove focus event listener for search input
     */
    detachEvents() {
        this.searchInput.removeEventListener(EVENTS.FOCUS, this.onSearchInputFocus);
    }

    /**
     * @method createSearchBar
     * @description Instantiates a DealerSearchBar for searching for
     * dealers by a form input field
     */
    createSearchBar() {
        const { searchCTA, searchInputLabel, dealerSearchErrorMessage } = this.localization;

        this.searchBar = new DealerSearchBar({
            country: this.country,
            ctaLabel: searchCTA,
            dealerSearchErrorMessage,
            defaultLocation: CONFIG,
            isCTASubmit: true,
            onSubmit: this.onSubmit.bind(this),
            searchInputLabel,
            autoSubmitLocation: CONFIG.autoSubmitLocation,
            theme: DealerSearchBar.THEMES.DEALER_SEARCH,
            buttonStyle: 'button button_primary',
            onErrorMessage: this.setInputFocus.bind(this)
        });

        renderer.insert(this.searchBar.render(), this.searchContainer);
        this.searchInput = this.element.querySelector(`.${CLASSES.FORM_INPUT_FIELD}`);
        this.searchContainer.querySelector(`.${CLASSES.FORM_INPUT_FIELD}`).focus();
        this.clearInput();
    }

    /**
     * @method onSearchInputFocus
     * @description on mobile device scroll the global header to show the text field on the screen
     */
    onSearchInputFocus() {
        if (screen.getState().small) {
            setTimeout(() => {
                this.navContainer.scrollTo(0, this.searchContainer.offsetTop);
            }, 300);
        }
    }

    /**
     * @method clear input
     * @description clear the input value, error message and aria-invalid attribute
     */
    clearInput() {
        if (!this.searchBar) {
            return;
        }
        this.searchBar.clearResultList();
        this.searchInput.value = '';
        this.searchInput.setAttribute(ATTRIBUTES.ARIA_INVALID, 'false');
        this.searchInput.parentElement.classList.remove(CLASSES.ERROR);
    }

    /**
     * @method onSubmit
     * @description on dealer search submission, build URL & redirect
     * @param location {Object} LocationSearch obj returned by DealerSearchBar
     */
    onSubmit(location) {
        const {
            dealerSearchUrl,
            isClassicDealerSearch,
        } = window.mbVans.ns('pageData', 'globalHeader', 'dealerMenu', 'config');

        if (window.mbVans.ns('pageData', 'dealerLocator', 'config').isFullPageLayout) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
                    { detail: location }
                )
            );
        } else if (this.brand === 'FL' || isClassicDealerSearch) {
            // Classic dealer locator
            const inventorySearchClassicUrl = window.mbVans.ns('applicationProperties').inventoryConfig.inventorySearchClassicUrl;
            window.location.href = `${inventorySearchClassicUrl}?searchText=${location.searchString}`;
        } else {
            // OW dealer locator
            window.location.href =
                `${dealerSearchUrl}/searchLocation-${location.searchLocation}/searchByType-${location.searchByType}/filter-mbdealer`;
        }
    }

    /**
     * @method setInputFocus
     * @description return the focus on input field when user clicks the submit button cta after an error message
     */
    setInputFocus() {
        this.searchInput.focus();
    }

    /**
     * @method destroy
     * @description Destroys the instance by removing all event listeners
     */
    destroy() {
        if (!this.searchBar) {
            return;
        }
        this.detachEvents();
        this.searchBar.destroy();
        this.searchBar = null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Import Utils
import {
    formatDate,
    formatNumber,
    formatString,
} from 'utils';

// Local dependencies
import RELATIONSHIP_TYPES from '../constants/relationshipTypes';
import TRADE_IN_VENDORS from '../constants/tradeInVendors';
import PAYMENT_TYPES from './../constants/paymentType';
import CREDIT_APP_VENDORS from '../constants/vendors';
import SOURCE_IDS from '../constants/creditAppSourceIds';
import * as creditAppAddressUtil from './../api/creditAppAddressUtil';
/**
 * API Responsible for constructing the Credit App Request payload
 */

/**
 * @method getFinancingType
 * @description Returns  financing type. Can be one of the following values:
 * 1 = ‘Retail’
 * 2 = ‘Lease’
 * 3 = ‘Balloon’
 * @param {String} type
 * @param {Object} data
 * @returns {Number}
 */
function getFinancingType(type, data) {
    let financingType = 0;

    if (type) {
        switch (type) {
        case 'lease':
            financingType = 2;
            break;
        case 'finance':
            if (data.isBalloon) {
                financingType = 3;
            } else {
                financingType = 1;
            }
            break;
        default:
            financingType = 0;
            break;
        }
    }

    return financingType;
}

/**
 * @method getValueSum
 * @description Returns the sum of the `value` properties of an array
 * @param {Array} arr The array of objects
 * @returns {Number} The sum of the total properties
 */
function getValueSum(arr) {
    return Array.isArray(arr) ?
        arr.reduce((total, item) => total + (parseFloat(item.value) || 0), 0) : null;
}

/**
 * @method constructEmployment
 * @description Parses an employment object for the request
 * @param {Object} employmentInfo Employment data
 * @param {String} country The country of Credit Application (e.g., 'US', 'CA')
 * @param {String} language The language of Credit Application (e.g., 'en', 'fr')
 * @return {[{
 *     isCurrent: Boolean,
 *     address: Object,
 *     employmentStatus: String,
 *     employedBy: String,
 *     tenure: Number,
 *     phone: String,
 *     occupation: String,
 *     email: String
 * }]}
 */
function constructEmployment(employmentInfo, country, language) {
    const employment = [{
        isCurrent: true,
        employmentStatus: employmentInfo.currentStatus && employmentInfo.currentStatus.value ?
            formatString.normalizeAccentedString(employmentInfo.currentStatus.value) : undefined,
        employedBy: employmentInfo.currentEmployer ?
            formatString.normalizeAccentedString(employmentInfo.currentEmployer) : undefined,
        tenure: creditAppAddressUtil.calculateDuration(employmentInfo.currentYears, employmentInfo.currentMonths),
        phone: employmentInfo.currentWorkPhone ?
            formatNumber.toNumber(employmentInfo.currentWorkPhone, country, language) : undefined,
        occupation: employmentInfo.currentOccupation ?
            formatString.normalizeAccentedString(employmentInfo.currentOccupation) : undefined,
        email: employmentInfo.currentWorkEmail ?
            formatString.normalizeAccentedString(employmentInfo.currentWorkEmail) : undefined,
    }, {
        isCurrent: false,
        employmentStatus: employmentInfo.previousStatus && employmentInfo.previousStatus.value ?
            formatString.normalizeAccentedString(employmentInfo.previousStatus.value) : undefined,
        employedBy: employmentInfo.previousEmployer ?
            formatString.normalizeAccentedString(employmentInfo.previousEmployer) : undefined,
        tenure: creditAppAddressUtil.calculateDuration(employmentInfo.previousYears, employmentInfo.previousMonths),
        phone: employmentInfo.previousWorkPhone ?
            formatNumber.toNumber(employmentInfo.previousWorkPhone, country, language) : undefined,
        occupation: employmentInfo.previousOccupation ?
            formatString.normalizeAccentedString(employmentInfo.previousOccupation) : undefined,
        email: employmentInfo.previousWorkEmail ?
            formatString.normalizeAccentedString(employmentInfo.previousWorkEmail) : undefined,

    }].reduce((empArr, emp) => {
        // remove any empty employments from the array
        const isEmpty = !Object.keys(emp).some((key) =>
            (!/isCurrent/.test(key) && emp[key]));

        if (!isEmpty) {
            empArr.push(emp);
        }
        return empArr;
    }, []);

    return employment;
}

/**
 * @method constructApplicant
 * @description Parses an applicant object for the request
 * @param {Object} data The info to parse
 * @param {Object} data.applicantType The applicant type data
 * @param {Object} data.consentIndicator The indicator to determine if the user has consented
 * @param {String} data.country The country of Credit Application (e.g., 'US', 'CA')
 * @param {Object} data.creditAppPersonalInfo The applicant's personal info
 * @param {Object} data.creditAppResidence The applicant's residence info
 * @param {Object} data.creditAppEmployment The applicant's employment info
 * @param {String} data.language The language of Credit Application (e.g., 'en', 'fr')
 * @param {Object} data.creditAppEmployment The applicant's relationship to the primary applicant, if applicable
 * @return {Object} Applicant data
 */
function constructApplicant({
    applicantType,
    country,
    creditAppPersonalInfo = {},
    creditAppResidence = {},
    creditAppEmployment = {},
    language,
    relationship
}) {
    const personalInfo = creditAppPersonalInfo.personalInfo || {};
    const residenceInfo = creditAppResidence.residenceInfo || { currentOccupancyType: {} };
    const employmentInfo = creditAppEmployment.form || {};
    const housingStatus = residenceInfo.currentOccupancyType.value;
    const housingAmount = residenceInfo.currentMortgageRentAmount ? parseInt(
        formatNumber.toNumber(residenceInfo.currentMortgageRentAmount, country, language), 10
    ) : null;

    const applicant = {
        applicantType,
        consentIndicator: true,
        residenceDetails: creditAppAddressUtil.constructResidence(residenceInfo),
        dateOfBirth: personalInfo.dob ?
            formatDate.toHyphenatedDate(personalInfo.dob) : null,
        driverLicenseNumber: personalInfo.driversLicenseNumber ?
            formatString.normalizeAccentedString(personalInfo.driversLicenseNumber) : null,
        driverLicenseState: personalInfo.provinceOfIssue || null,
        emailAddress: personalInfo.email ?
            formatString.normalizeAccentedString(personalInfo.email) : null,
        employmentDetails: constructEmployment(employmentInfo),
        firstName: personalInfo.firstName ?
            formatString.normalizeAccentedString(personalInfo.firstName) : null,
        middleInitial: personalInfo.middleInitial ?
            formatString.normalizeAccentedString(personalInfo.middleInitial) : null,
        suffix: personalInfo.suffix ?
            formatString.normalizeAccentedString(personalInfo.suffix) : null,
        homePhone: personalInfo.phoneNumber ?
            formatNumber.toNumber(personalInfo.phoneNumber, country, language) : null,
        housingStatus: housingStatus === 'Mortgage' && housingAmount === 0 ?
            'OwnOutright' : housingStatus || null,
        income: employmentInfo.currentMonthlyIncome ? {
            payrollFrequency: 'Monthly',
            amount: parseInt(formatNumber.toNumber(employmentInfo.currentMonthlyIncome, country, language), 10)
        } : undefined,
        lastName: personalInfo.lastName ?
            formatString.normalizeAccentedString(personalInfo.lastName) : null,
        maritalStatus: residenceInfo.currentMaritalStatus ?
            formatString.normalizeAccentedString(residenceInfo.currentMaritalStatus) : null,
        mortgageDetails: {
            monthlyPayment: residenceInfo.currentMortgageRentAmount ?
                formatNumber.toNumber(residenceInfo.currentMortgageRentAmount, country, language) : null,
            mortgageBalance: residenceInfo.currentMortgageBalance ?
                formatNumber.toNumber(residenceInfo.currentMortgageBalance, country, language) : null,
            mortgageHolder: residenceInfo.currentMortgageHolder || null,
            marketValue: residenceInfo.currentMarketValue ?
                formatNumber.toNumber(residenceInfo.currentMarketValue, country, language) : null,

        },
        otherIncomeSource: employmentInfo.currentSource && employmentInfo.currentSource.value ?
            formatString.normalizeAccentedString(employmentInfo.currentSource.value) : undefined,
        otherMonthlyIncome: parseInt(
            formatNumber.toNumber(employmentInfo.currentYearlyIncome, country, language) / 12, 10
            ) || undefined,
        ssn: personalInfo.ssn ? personalInfo.ssn.replace(/-/g, '') : ''
    };

    if (relationship) applicant.relationship = relationship;

    if (creditAppResidence.showSpouseInfo) {
        applicant.spouseInfo = {
            address: creditAppAddressUtil.constructAddress({
                addressType: 'SpouseAddress',
                address: residenceInfo.spouseAddress,
                city: residenceInfo.spouseCity,
                state: residenceInfo.spouseState,
                zipCode: residenceInfo.spouseZipCode,
                months: null,
                years: null
            }),
            firstName: residenceInfo.spouseFirstName ?
                formatString.normalizeAccentedString(residenceInfo.spouseFirstName) : null,
            lastName: residenceInfo.spouseLastName ?
                formatString.normalizeAccentedString(residenceInfo.spouseFirstName) : null,
            middleInitial: residenceInfo.spouseMiddleInitial ?
                formatString.normalizeAccentedString(residenceInfo.spouseMiddleInitial) : null
        };
    }

    return applicant;
}

/**
 * @method constructFinancingDetails
 * @description Parses an financing object for the request
 * @param {Object} financingData The financing data
 * @param {Object} financingType The financing type
 * @param {Boolean} isPaymentDriverEstimator Indicator for when payment driver estimator is used for the estimates
 * @param {Boolean} isPaymentDriverEstimator The cuurrent estimator data
 * @return {Object} Financing details data
 */
function constructFinancingDetails({
    financingData,
    financingType,
    isPaymentDriverEstimator,
    currentEstimator,
    tradeInCalculatedValue
}) {
    const financingTypeCode = getFinancingType(financingType,
        currentEstimator && isPaymentDriverEstimator && currentEstimator.temp[financingType] ||
        currentEstimator && currentEstimator.estimate.temp[financingType] || {});
    const tradeInValue = financingData.tradeInAmount ? parseInt(financingData.tradeInAmount.value, 10) :
    parseInt(financingData.tradeValue.value, 10);

    return {
        annualPercentageRate: financingData.apr && !isNaN(financingData.apr.value) ? financingData.apr.value : 0,
        downPaymentAmount: financingData.downPayment && parseInt(financingData.downPayment.value, 10) ||
            financingData.downPaymentAmount && parseInt(financingData.downPaymentAmount.value, 10) || null,
        paymentAmount: financingData.estimatedMonthlyPayment &&
            parseInt(financingData.estimatedMonthlyPayment.value, 10) || null,
        financeType: financingTypeCode,
        purchasePrice: financingData.finalPayment && parseInt(financingData.finalPayment.value, 10) || null,
        residualAmount: financingData.estimatedResidualValue && financingData.estimatedResidualValue !== 'NA'
            ? formatNumber.toNumber(financingData.estimatedResidualValue) : null,
        term: parseInt(financingData.term, 10) || 0,
        tradeIn: {
            grossTradeIn: tradeInCalculatedValue.value ? parseInt(tradeInCalculatedValue.value, 10) || 0 : tradeInValue
        }
    };
}

/**
 * @method constructAftermarketProducts
 * @description Parses the aftermarket products for the request
 * @param {Object} aftermarketProducts The applicant-selected aftermarket product data
 * @param {String} country The country of Credit Application (e.g., 'US', 'CA')
 * @param {String} language The language of Credit Application (e.g., 'en', 'fr')
 * @return {Array|null} The aftermarket products
 */
function constructAftermarketProducts(aftermarketProducts, country, language) {
    const { selectedProducts } = aftermarketProducts || {};

    return selectedProducts ? Object.keys(selectedProducts).reduce((flattenedProducts, key) => {
        // map each of the products
        const products =  Array.isArray(selectedProducts[key].products) ?
            selectedProducts[key].products.map((product) => {
                const hasTerm = !!(product.term && product.term.termMonths);
                let term = '';

                if (hasTerm && product.term.termMonths.maxRange) {
                    term = product.term.termMonths.maxRange;
                } else if (hasTerm) {
                    term = product.term.termMonths;
                }

                return {
                    description: product.packageTypeDescription ?
                        formatString.stripSpecialCharacters(product.packageTypeDescription, ' ') : null,
                    name: formatString.stripSpecialCharacters((product.title || 'NA'), ' '),
                    netRate: product.priceNetRate && product.priceNetRate.value ?
                        parseInt(product.priceNetRate.value, 10) : null,
                    retailRate: product.price && product.price.value ?
                        parseInt(product.price.value, 10) : 0,
                    term: term ? formatNumber.toNumber(term, country, language) : null,
                    typeCode: product.productType ? formatString.normalizeAccentedString(product.productType) : null
                };
            }) : [];
        return [...flattenedProducts, ...products];
    }, []) : null;
}

/**
 * @function getUsedCarBook
 * @description Maps the tradeIn vendor to the usedCarBook value
 * @param {string} vendor - TradeInEstimator vendor
 * @return {string|null}
 */
function getUsedCarBook(vendor) {
    if (vendor && TRADE_IN_VENDORS[vendor]) {
        return TRADE_IN_VENDORS[vendor];
    } else if (vendor) {
        return TRADE_IN_VENDORS.Default;
    }

    return null;
}

/**
 * @function getWarrantyCost
 * @description Parses the warranty costs from the aftermarket products
 * @param aftermarketProducts {Object} AftermarketProduct state object with the cost of selected products
 * @return {number}
 */
function getWarrantyCost(aftermarketProducts) {
    const {
        totalCost: {
            gap,
            total
        } = {}
    } = aftermarketProducts || {};

    if (gap && gap.value && total && total.value) {
        return parseInt(total.value - gap.value, 10);
    } else if (total && total.value) {
        return parseInt(total.value, 10);
    } else {
        return 0;
    }
}

/**
 * @function getGapCost
 * @description Parses the gap insurance costs from the aftermarket products
 * @param aftermarketProducts {Object} AftermarketProduct state object with the cost of selected products
 * @return {number}
 */
function getGapCost(aftermarketProducts) {
    const {
        totalCost: {
            gap,
        } = {}
    } = aftermarketProducts || {};

    if (gap && gap.value) {
        return parseInt(gap.value, 10);
    } else {
        return 0;
    }
}

/**
 * @method constructProductInfo
 * @description Parses the product info for the request
 * @param {Object} data The data to be parsed
 * @param {Object} [data.chosenVehicle] The applicant-selected vehicle to be financed
 * @param {String} data.country The country of Credit Application (e.g., 'US', 'CA')
 * @param {Object} [data.financingData] The financing data
 * @param {String} language The language of Credit Application (e.g., 'en', 'fr')
 * @param {Array} [data.serviceContracts] The service contracts data
 * @param {Object} [data.tradeInCalculatedValue] The calculated value of the applicant's trade in
 * @param {Object} [data.tradeInInfo] The data for the applicant's trade in
 * @param {Object} [data.aftermarketProducts] The applicant's Aftermarket Product selections data
 * @return {{}} The Product info, parsed for the request
 */
function constructProductInfo({
    aftermarketProducts,
    chosenVehicle = {},
    country,
    financingData,
    language,
    serviceContracts,
    tradeInCalculatedValue,
    tradeInInfo
}) {
    // Online Price
    const onlinePrice = chosenVehicle.reservable && chosenVehicle.reservePrice ?
        chosenVehicle.reservePrice.price : chosenVehicle.dsrp;

    // Vehicle MSRP
    const mrsp = chosenVehicle.msrp && typeof chosenVehicle.msrp === 'object' ?
        chosenVehicle.msrp.price : chosenVehicle.msrp;

    const vehiclePrice = parseInt((mrsp || onlinePrice || 0), 10);

    // Taxes & Fees
    const {
        taxesAndFees: {
            fees: {
                dealerFees = {},
                dmvFees = {},
                otherFees = {}
            } = {}
        } = {}
    } = financingData || {};

    return {
        serviceContracts,
        apr: financingData.apr && !isNaN(financingData.apr.value) ? parseInt(financingData.apr.value, 10) : 0,
        acquisitionFees: (financingData.acquisitionFees && financingData.acquisitionFees.value) || 0,
        invoiceAmount: chosenVehicle.type !== 'PRE' ?
            (financingData.totalPrice && parseInt(financingData.totalPrice.value, 10) || null) : null,
        basePayment: financingData.estimatedMonthlyPayment &&
            parseInt(financingData.estimatedMonthlyPayment.value, 10) || 0,
        moneyFactor: financingData.apr && parseInt(financingData.apr.value, 10) || 0,
        cashSellingPrice: vehiclePrice,
        salesTax: financingData.advanceSalesTax ? parseInt(financingData.advanceSalesTax.value, 10) : 0,
        title: getValueSum(dmvFees.fees),
        cashDown: financingData.downPaymentAmount && parseInt(financingData.downPaymentAmount.value, 10) || 0,
        rebate: financingData.rebate && parseInt(financingData.rebate.value, 10) || 0,
        creditLifeIns: null,
        term: financingData.term ? formatNumber.toNumber(financingData.term, country, language) : 0,
        warranty: getWarrantyCost(aftermarketProducts),
        gap: getGapCost(aftermarketProducts),
        accidentHealthIns: null,
        frontEndFees: getValueSum(dealerFees.fees),
        msrp: vehiclePrice,
        estimatedPayment: financingData.estimatedMonthlyPayment &&
            parseInt(financingData.estimatedMonthlyPayment.value, 10) || 0,
        estimateBalloonAmount: null,
        usedCarBook: tradeInInfo && getUsedCarBook(tradeInInfo.vendor),
        mileage: chosenVehicle.mileage && parseInt(chosenVehicle.mileage.value, 10) || 0,
        usedCarValue: parseInt(tradeInCalculatedValue.value, 10) || null,
        otherFees: getValueSum(otherFees.fees),
        wholesaleBookSource: null,
        wholesaleCondition: null,
        wholesaleValueType: null,
        wholesaleValue: chosenVehicle.dsrp || null,
        netTrade: financingData.tradeIn ? formatNumber.toNumber(financingData.tradeIn, country, language) : null,
        annualMiles: financingData && financingData.annualMileage ?
            formatNumber.toNumber(financingData.annualMileage, country, language) : null
    };
}

/**
 * @method constructTradeIn
 * @description Parses the Applicant's Trade In info for the request
 * @param {Object} [tradeInInfo]
 * @param {String} country The country of Credit Application (e.g., 'US', 'CA')
 * @param {String} language The language of Credit Application (e.g., 'en', 'fr')
 * @return {{}} The Trade In info, parsed for the request
 */
function constructTradeIn(tradeInInfo, country, language) {
    const {
        selectedVehicle = {},
        vehicleInfo = {}
    } = tradeInInfo || {};

    const trim = selectedVehicle?.series && selectedVehicle.style ?
        `${selectedVehicle.series} ${selectedVehicle.style}` :
        vehicleInfo.trim || null;

    return {
        year: selectedVehicle?.year ? formatNumber.toNumber(selectedVehicle.year, country, language) : null,
        make: selectedVehicle?.make ? formatString.normalizeAccentedString(selectedVehicle.make) : null,
        model: selectedVehicle?.model ? formatString.normalizeAccentedString(selectedVehicle.model) : null,
        modelClass: selectedVehicle?.series || null,
        chromeStyleId: selectedVehicle?.style || null,
        lienHolder: null,
        monthlyPayment: null,
        mileage: vehicleInfo.mileage ? formatNumber.toNumber(vehicleInfo.mileage, country, language) : null,
        trim
    };
}

/**
 * @function constructAdditionalCreditAppInfo
 * @description Constructs the additionalCreditAppInfo object to pass in the credit app request object
 * @param vehicle {Object} Vehicle object associated to the credit app
 * @return {{vehicleInfo: {vehicleImg: (*|null), modelClass, attributes: {mileageUnit: *, mileage}}}}
 */
function constructAdditionalCreditAppInfo(vehicle) {
    return {
        vehicleInfo: {
            modelClass: vehicle.class || vehicle.classId,
            vehicleImg: vehicle.images && vehicle.images[0] ? vehicle.images[0].imgS : undefined,
            attributes: {
                mileageUnit: vehicle.mileageUnit || undefined,
                mileage: vehicle.mileage ? vehicle.mileage.value.toString() : undefined
            }
        }
    };
}

/**
 * @function constructContext
 * @description Constructs the context object to pass in the credit app request object
 * @param data {Object} Credit App data object from constructRequestObject
 * @return {{vehicleInfo: {vehicleImg: (*|null), modelClass, attributes: {mileageUnit: *, mileage}}}}
 */
function constructContext({ creditAppPersonalInfo, dealer, emailSubject }) {
    return {
        attributes: {
            dealer: {
                name: dealer.name,
                address: dealer.address,
                city: dealer.city,
                state: dealer.state,
                zip: dealer.zip,
                phone: dealer.phoneFormatted,
                directionsLink: dealer.directionsLink
            },
            financeType: 'finance',
            firstName: creditAppPersonalInfo.personalInfo.firstName,
            lastName: creditAppPersonalInfo.personalInfo.lastName
        },
        mailSubject: emailSubject,
        mailTo: creditAppPersonalInfo.personalInfo.email,
        mailType: 'FINANCE_DRIVER'
    };
}

/**
 * @method constructRequestObject
 * @description Constructs the Credit app request payload object based on the credit application form data
 * @param aftermarketProducts {Object} The Credit App Aftermarket Product data
 * @param communityDisclosureStates {Object} The Community Disclosure states data
 * @param country {Object} The country of the application
 * @param creditApplication {Object} Credit App data
 * @param creditAppEmployment {Object} Credit App Employment data
 * @param creditAppEmploymentCo {Object} Credit App Employment data for co-applicant
 * @param creditAppPersonalInfo  {Object} Credit App Personal Info data
 * @param creditAppPersonalInfoCo  {Object} Credit App Personal Info data for co-applicant
 * @param creditAppResidence  {Object} Credit App Residence data
 * @param creditAppResidenceCo  {Object} Credit App Residence data for co-applicant
 * @param currentEstimator {Object} Credit App current estimator data
 * @param dealerInfo  {Object} Dealer data
 * @param isPaymentDriverEstimator  {Boolean} Indicator to determine if Payment Driver Estimator used for estimates
 * @param language {Object} The language of the credit application
 * @param tradeInInfo {Object} The Credit App Trade-In data
 * @param vehicleInformation  {Object} Vehicle data
 * @return {{}} The request object
 */
function constructRequestObject({
    aftermarketProducts,
    communityDisclosureStates,
    country,
    creditAppType = {},
    creditApplication = {},
    creditAppEmployment = {},
    creditAppEmploymentCo,
    creditAppPersonalInfo = {},
    creditAppPersonalInfoCo,
    creditAppResidence = {},
    creditAppResidenceCo,
    currentEstimator = {},
    customerAgreements = {},
    dealer = {},
    dealerInfo = {},
    emailSubject = '',
    isPaymentDriverEstimator,
    language,
    tradeInInfo,
    sourceId = SOURCE_IDS.DEFAULT,
    vehicleInformation = {}
}) {
    const chosenVehicle = creditApplication.vehicle || vehicleInformation.chosenVehicle || {};
    const financingType = isPaymentDriverEstimator ?
        currentEstimator.temp.type || creditApplication.paymentEstimate.type || '' :
        currentEstimator.options.temp.type || creditApplication.paymentEstimate.type || '';
    const financingData = isPaymentDriverEstimator ?
        currentEstimator.temp[financingType] || creditApplication.paymentEstimate.estimate || {} :
        currentEstimator.estimate.temp[financingType] || creditApplication.paymentEstimate.estimate || {};
    const financeMethod = financingType === 'lease' ? PAYMENT_TYPES.LEASE : PAYMENT_TYPES.RETAIL;
    const hasCoApplicant = !!creditAppType.hasCoApplicant;
    const serviceContracts = constructAftermarketProducts(aftermarketProducts, country, language);
    const { tradeInValues: { calculatedValue: tradeInCalculatedValue = {} } = {} } = tradeInInfo || {};

    // Instantiate the base requestObject
    const payload = {
        accessedFrom: 'Web',
        vendor: country === 'ca' ? CREDIT_APP_VENDORS.STANDALONE : CREDIT_APP_VENDORS.DCP
    };

    // applicant
    // PrimaryApplicant
    payload.applicant = [
        constructApplicant({
            applicantType: 'PrimaryApplicant',
            country,
            creditAppPersonalInfo,
            creditAppResidence,
            creditAppEmployment,
            language
        })
    ];

    // CoApplicant
    if (hasCoApplicant && (creditAppPersonalInfoCo || creditAppResidenceCo || creditAppEmploymentCo)) {
        payload.applicant.push(constructApplicant({
            applicantType: 'CoApplicant',
            country,
            creditAppPersonalInfo: creditAppPersonalInfoCo,
            creditAppResidence: creditAppResidenceCo,
            creditAppEmployment: creditAppEmploymentCo,
            language,
            relationship: creditAppType.relationship ? creditAppType.relationship.value : RELATIONSHIP_TYPES.OTHER
        }));
    }

    // financingDetails
    payload.financingDetails = constructFinancingDetails({
        financingData,
        financingType,
        isPaymentDriverEstimator,
        currentEstimator,
        tradeInCalculatedValue
    });


    // otherDetails
    payload.otherDetails = {
        // if applying as an individual and the residence is included in the communityDisclosureStates
        // add the communityPropertyDisclosure
        communityPropertyDisclosure: !hasCoApplicant && communityDisclosureStates &&
            communityDisclosureStates.includes(creditAppResidence.residenceInfo.currentState),
        dealerId: dealerInfo.dealerId || '',
        financeMethod,
        privacyNotice: customerAgreements.privacyNotice ? customerAgreements.privacyNotice.hasAgreed : false,
        regulationB: hasCoApplicant,
        sendAdfEmail: false,
        source: sourceId
    };

    // productInfo
    payload.productInfo = constructProductInfo({
        aftermarketProducts,
        chosenVehicle,
        country,
        financingData,
        language,
        serviceContracts,
        tradeInCalculatedValue,
        tradeInInfo
    });

    // tradeInVehicleInfo
    if (tradeInInfo) {
        payload.tradeInVehicleInfo = constructTradeIn(tradeInInfo, country, language);
    }

    // vehicleInfo
    payload.vehicleInfo = {
        vehicleCondition: chosenVehicle.type === 'PRE' ? 'Used' : 'New',
        year: chosenVehicle.year,
        make: 'Mercedes-Benz',
        model: chosenVehicle.modelId,
        modelClass: chosenVehicle.class || chosenVehicle.classId,
        trim: chosenVehicle.modelName ? formatString.normalizeAccentedString(chosenVehicle.modelName) : null,
        vin: chosenVehicle.vin ? chosenVehicle.vin : null,
        stockNumber: chosenVehicle.stockId || null,
        certifiedUsed: chosenVehicle.type === 'PRE' && chosenVehicle.certified ? 'Y' : 'N'
    };

    const additionalCreditAppInfo = constructAdditionalCreditAppInfo(chosenVehicle);

    const context = constructContext({
        creditAppPersonalInfo,
        dealer,
        emailSubject
    });
    return {
        additionalCreditAppInfo,
        context,
        payload
    };
}

export default {
    constructRequestObject
};

// do not delete 9fbef606107a605d69c0edbcd8029e5d

import {
    ClickOutside,
    customEventDispatcher,
    detect,
    DisableBodyScroll,
    screen,
    Tabbables
} from 'utils';
import { EVENTS, CUSTOM_EVENTS, KEYBOARD } from 'Constants';

import AuthenticationApi from 'partials/authentication/api/AuthenticationApi';

import LevelThreeContainer from './LevelThreeContainer';
import DealerSearch from './views/DealerSearch';
import DockingHeader from './views/DockingHeader';
import PeekABooHeader from './views/PeekABooHeader';

const { isiOSMobile } = detect;

const CLASSES = {
    TOP_BAR: 'top-bar',
    TOP_BAR_HAMBURGER_BUTTON: 'top-bar__hamburger-button',
    TOP_BAR_SKIP_NAV: 'top-bar__skip-nav',
    TOP_BAR_BACK_BUTTON: 'top-bar__back-button',
    TOP_BAR_BACK_BUTTON_VISIBLE: 'top-bar__back-button--visible',
    TOP_BAR_LOGO: 'top-bar__logo',
    GLOBAL_HEADER_MENU_L1: 'global-header__menu-l1',
    GLOBAL_HEADER_MENU_L1_CLOSED: 'global-header__menu-l1--closed',
    GLOBAL_HEADER_MENU_L1_INNER: 'global-header__menu-l1__inner',
    GLOBAL_HEADER_MENU_L1_INNER_HIDDEN: 'global-header__menu-l1__inner--hidden',
    GLOBAL_HEADER_MENU_L1_NO_SCROLL: 'global-header__menu-l1--no-scroll',
    GLOBAL_HEADER_MENU_L1_LIST_BUTTON: 'global-header__menu-l1-list-button',
    GLOBAL_HEADER_MENU_L1_LIST_BUTTON_UTILITY_INNER: 'global-header__menu-l1-list-button-utility-inner',
    GLOBAL_HEADER_MENU_L1_LIST_ITEM: 'global-header__menu-l1-list-item',
    GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN: 'global-header__menu-l1-list-item--open',
    GLOBAL_HEADER_MENU_L2: 'global-header__menu-l2',
    GLOBAL_HEADER_MENU_L1_DEALER_LOCATOR_BUTTON: 'global-header__menu-l1-list-button--icon-pin',
    GLOBAL_HEADER_MENU_L2_DEALER_FIELD: 'global-header__menu-l2--dealer',
    GLOBAL_HEADER_MENU_L2_INPUT_FIELD: 'form__input-label',
    GLOBAL_HEADER_MENU_L2_LIST_BUTTON: 'global-header__menu-l2-list-button',
    GLOBAL_HEADER_MENU_L2_LIST_ITEM_OPEN: 'global-header__menu-l2-list-item--open',
    GLOBAL_HEADER_MENU_L3: 'global-header__menu-l3',
    GLOBAL_HEADER_MENU_L3_PANEL_ITEM_ACTIVE: 'global-header__menu-l3-panel-item--active',
    GLOBAL_HEADER_MENU_L3_PANEL_ITEM_SHOPPING: 'global-header__menu-l3-panel-item--shopping',
    GLOBAL_HEADER_MENU_L3_CONTENT_OPEN: 'global-header__menu-l3-content--open',
    GLOBAL_HEADER_MENU_L1_LIST_BUTTON_DEALER_SELECTED: 'global-header__menu-l1-list-button--dealer-selected'
};

const ATTRIBUTES = {
    TABINDEX: 'tabindex',
    MENU_STATE: 'data-global-header-state',
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_LABEL: 'aria-label',
    NAVIGATION_BEHAVIOR: 'data-global-navigation-behavior',
    DEFAULT_OPEN_MENU: 'data-default-open-menu'
};

const isMB = window.mbVans.pageData.brand === 'MB';

const CONSTANTS = {
    HAMBURGER_BUTTON_LABEL: 'main menu button',
    CLOSE_BUTTON_LABEL: 'close main menu button',
    MENU_OPEN: 'open',
    DEALER_L1_INDEX: isMB ? 3 : 2,
    VEHICLES_L1_INDEX: 0
};

const SPECIALTY_HEADER = {
    PEEK_A_BOO: 'peek-a-boo',
    DOCKING: 'docked'
};

export default class GlobalHeader {
    constructor(element) {
        this.element = element;
        this.clickOutsideMenus = [];
        this.authUtilityLabel = null;
        this.unauthUtilityLabel = window.mbVans.ns('pageData', 'globalHeader').config.utilityLabel;
        this.tabbables = new Tabbables();
        this.init();
    }

    init() {
        this.setBindings();
        this.cacheDOMElements();
        this.attachEvents();
        this.checkAuthState();
        this.setSkipNavTabIndex();
        this.levelThreeContainer = new LevelThreeContainer(this.element);
        this.initSpecialtyHeader();
        this.dealerLevelTwoSearchBar = null;
        this.state = {
            hamburgerMenuHeight: null,
            levelOneMenuOpen: false,
            openLevelOneMenuItemIndex: null,
            openLevelTwoMenuItemIndex: null,
            currentScreenState: screen.getCurrentState(),
            forceFocusItemIndex: null
        };
        this.onScreenResize();
        this.initTabbables();
    }

    /**
     * @method initSpecialtyHeader
     * @description Initializes specialty headers, PeekABoo or Docked Header if data attr is present
     */
    initSpecialtyHeader() {
        const globalNavBehavior = this.element.parentElement.getAttribute(ATTRIBUTES.NAVIGATION_BEHAVIOR);
        switch (globalNavBehavior) {
        case SPECIALTY_HEADER.PEEK_A_BOO: {
            this.specialtyHeader = new PeekABooHeader(this.element, this.resetMenus);
            break;
        }
        case SPECIALTY_HEADER.DOCKING: {
            this.specialtyHeader = new DockingHeader(this.element, this.resetMenus);
            break;
        }
        default:
            this.specialtyHeader = null;
        }
    }

    /**
     * @method initTabbables
     * @description Initialised tabbable to level 1
     */
    initTabbables() {
        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.setLevelOneTabbables();
            this.tabbables.saveTabIndices();
            this.disableTabbables();
        }
    }

    /**
     * @method disableTabbables
     * @description Disable tabbables except for the logo and the hamburger menu
     */
    disableTabbables() {
        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.tabbables.disableTabbables();
            this.tabbables.setSpecificTabbables([
                this.logoButtonElm,
                this.hamburgerButtonElm
            ]);
            this.tabbables.enableTabbables();
        }
    }

    /**
     * @method setLevelOneTabbables
     * @description set level one tabbables
     */
    setLevelOneTabbables() {
        const { forceFocusItemIndex, openLevelOneMenuItemIndex } = this.getState();
        if (!screen.gte(screen.SIZES.XLARGE)) {
            if (forceFocusItemIndex === null) {
                this.tabbables.disableTabbables();
            }
            this.tabbables.setSpecificTabbables([
                this.logoButtonElm,
                this.hamburgerButtonElm,
                ...this.levelOneListButtonElms
            ]);
            this.tabbables.enableTabbables();
            this.levelOneListButtonElms[forceFocusItemIndex !== null ?
                forceFocusItemIndex : openLevelOneMenuItemIndex || 0].focus();
        }
    }

    /**
     * @method setLevelTwoTabbables
     * @description set level one + two tabbables
     * @param index {int} opened level one element
     */
    setLevelTwoTabbables(index) {
        const levelTwoTabbables = [...this.levelTwoMenuElms[index].querySelectorAll('a, button, input, [tabindex]')];
        const newlevelTabbableArray = [...this.levelOneListButtonElms];
        newlevelTabbableArray.splice(index + 1, 0, ...levelTwoTabbables);
        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.tabbables.disableTabbables();
            this.tabbables.setSpecificTabbables([
                this.logoButtonElm,
                this.hamburgerButtonElm,
                ...newlevelTabbableArray
            ]);
            this.tabbables.enableTabbables();
            if (levelTwoTabbables.length) {
                levelTwoTabbables[this.getState().openLevelTwoMenuItemIndex || 0].focus();
            }
        }
    }

    /**
     * @method setLevelThreeTabbables
     * @description set level three tabbables
     */
    setLevelThreeTabbables() {
        const { openLevelOneMenuItemIndex } = this.getState();
        let levelThreeTabbables = [];

        if (screen.gte(screen.SIZES.LARGE) && openLevelOneMenuItemIndex !== CONSTANTS.VEHICLES_L1_INDEX) {
            levelThreeTabbables = [
                ...this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L3_CONTENT_OPEN}`).querySelectorAll('a, button, input, [tabindex]')
            ];
        } else {
            levelThreeTabbables = [
                ...this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L3_PANEL_ITEM_ACTIVE}`).querySelectorAll('a, button, input, [tabindex]')
            ];
        }
        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.tabbables.disableTabbables();
            this.tabbables.setSpecificTabbables([
                this.logoButtonElm,
                this.hamburgerButtonElm,
                this.backButtonElm,
                ...levelThreeTabbables
            ]);
            this.tabbables.enableTabbables();
        }
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.beginHamburgerMenuOpen = this.beginHamburgerMenuOpen.bind(this);
        this.finishHamburgerMenuOpen = this.finishHamburgerMenuOpen.bind(this);
        this.onHamburgerButtonClick = this.onHamburgerButtonClick.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.onGlobalNavDealerSearch = this.onGlobalNavDealerSearch.bind(this);
        this.onLevelOneItemClick = this.onLevelOneItemClick.bind(this);
        this.onLevelTwoItemClick = this.onLevelTwoItemClick.bind(this);
        this.onLevelThreeBlur = this.onLevelThreeBlur.bind(this);
        this.resetMenus = this.resetMenus.bind(this);
        this.onLevelOneMenuTransition = this.onLevelOneMenuTransition.bind(this);
        this.onLevelThreeMenuTransition = this.onLevelThreeMenuTransition.bind(this);
        this.onKeydownHandler = this.onKeydownHandler.bind(this);
        this.activateLocatorInput = this.activateLocatorInput.bind(this);
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.bodyElm = window.document.querySelector('body');
        this.skipNavBtnElm = this.element.querySelector(`.${CLASSES.TOP_BAR_SKIP_NAV}`);
        this.topBarElm = window.document.querySelector(`.${CLASSES.TOP_BAR}`);
        this.backButtonElm = this.element.querySelector(`.${CLASSES.TOP_BAR_BACK_BUTTON}`);
        this.hamburgerButtonElm = this.element.querySelector(`.${CLASSES.TOP_BAR_HAMBURGER_BUTTON}`);
        this.logoButtonElm = this.element.querySelector(`.${CLASSES.TOP_BAR_LOGO}`);
        this.levelOneMenuElm = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L1}`);
        this.levelOneMenuInnerElm = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L1_INNER}`);
        this.levelOneListItemElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM}`)];
        this.levelOneListButtonElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L1_LIST_BUTTON}`)];
        this.levelTwoMenuElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L2}`)];
        this.levelTwoListButtonElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L2_LIST_BUTTON}`)];
        this.levelTwoListItemElms = [];
        this.levelTwoListButtonElms.forEach((listButton) => {
            this.levelTwoListItemElms.push(listButton.parentElement);
        });
        this.utilityLevelOneButtonLabelElm = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L1_LIST_BUTTON_UTILITY_INNER}`);
        this.dealerLevelTwoContentElm = this.levelTwoMenuElms[CONSTANTS.DEALER_L1_INDEX];
        this.levelThreeMenuElm = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L3}`);
        this.levelThreePanelItemElms = [...this.element.querySelectorAll(`.${CLASSES.GLOBAL_HEADER_MENU_L3_PANEL_ITEM_SHOPPING}`)];
        this.delerInputFieldItem = this.element.querySelector(`.${CLASSES.GLOBAL_HEADER_MENU_L2_DEALER_FIELD}`);
    }

    /**
     * @method attachEvents
     * @description Attaches event listener for
     * hamburgerButton, level one and two menu items.
     */
    attachEvents() {
        this.hamburgerButtonElm.addEventListener(EVENTS.CLICK, this.onHamburgerButtonClick);
        this.backButtonElm.addEventListener(EVENTS.CLICK, this.onBackButtonClick);
        screen.addResizeListener(this.onScreenResize);
        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            this.onGlobalNavDealerSearch
        );
        this.attachLevelOneEvents();
        this.levelTwoListButtonElms.forEach((listButton) => {
            listButton.addEventListener(EVENTS.CLICK, this.onLevelTwoItemClick);
        });
        if (this.delerInputFieldItem) {
            this.delerInputFieldItem.addEventListener(EVENTS.CLICK, this.activateLocatorInput);
        }
    }

    /**
     * @method attachLevelOneEvents
     * @description Attaches event listener for level one menu buttons
     */
    attachLevelOneEvents() {
        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.levelOneListButtonElms.forEach((listButton) => {
                listButton.addEventListener(EVENTS.CLICK, this.onLevelOneItemClick);
            });
        } else {
            this.levelOneListButtonElms.forEach((listButton) => {
                this.clickOutsideMenus.push(
                    new ClickOutside(
                        listButton,
                        this.onLevelOneItemClick,
                        this.onLevelThreeBlur,
                        [...this.levelOneListButtonElms, this.levelThreeMenuElm]
                    )
                );
            });
        }
    }

    /**
     * @method detachEvents
     * @description detach events
     */
    detachEvents() {
        this.hamburgerButtonElm.removeEventListener(EVENTS.CLICK, this.onHamburgerButtonClick);
        this.backButtonElm.removeEventListener(EVENTS.CLICK, this.onBackButtonClick);
        screen.removeResizeListener(this.onScreenResize);
        customEventDispatcher.removeEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            this.onGlobalNavDealerSearch
        );
        this.levelTwoListButtonElms.forEach((listButton) => {
            listButton.removeEventListener(EVENTS.CLICK, this.onLevelTwoItemClick);
        });
        this.detachLevelOneEvents();
        this.levelOneMenuElm.removeEventListener(EVENTS.TRANSITION_END, this.onLevelOneMenuTransition);
        this.levelThreeMenuElm.removeEventListener(EVENTS.TRANSITION_END, this.onLevelThreeMenuTransition);
        this.delerInputFieldItem.removeEventListener(EVENTS.CLICK, this.activateLocatorInput);
    }

    /**
     * @method detachLevelOneEvents
     * @description detach event listener for level one menu buttons
     */
    detachLevelOneEvents() {
        if (this.clickOutsideMenus.length > 0) {
            this.clickOutsideMenus.forEach((co) => {
                co.destroy();
            });
            this.clickOutsideMenus = [];
        } else {
            this.levelOneListButtonElms.forEach((listButton) => {
                listButton.removeEventListener(EVENTS.CLICK, this.onLevelOneItemClick);
            });
        }
    }

    /**
     * @method setHamburgerMenuHeight
     * @description sets height of hamburger menu
     */
    setHamburgerMenuHeight() {
        const gteLarge = screen.gte(screen.SIZES.LARGE);
        const { levelOneMenuOpen } = this.getState();
        const hamburgerMenuHeight = window.innerHeight - this.topBarElm.offsetHeight;
        this.updateState({
            hamburgerMenuHeight
        });
        if (levelOneMenuOpen) {
            this.levelOneMenuElm.style.height = `${hamburgerMenuHeight}px`;
        }
        this.levelThreeMenuElm.style.height = `${hamburgerMenuHeight}px`;
        this.levelThreePanelItemElms.forEach((contentElm) => {
            if (!gteLarge) {
                contentElm.style.height = `${hamburgerMenuHeight - 70}px`;
            } else {
                contentElm.style.height = '';
            }
        });
    }

    /**
     * @method unsetHamburgerMenuHeight
     * @description unsets height of hamburger menu
     */
    unsetHamburgerMenuHeight() {
        this.updateState({
            hamburgerMenuHeight: null
        });
        this.levelOneMenuElm.style.height = '';
        this.levelThreeMenuElm.style.height = '';
        this.levelThreePanelItemElms.forEach((contentElm) => {
            contentElm.style.height = '';
        });
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        const newScreenState = screen.getCurrentState();
        const { currentScreenState } = this.getState();
        if (currentScreenState !== newScreenState) {
            if (this.authUtilityLabel) {
                this.setUtilityButtonLabel();
            }
            this.resetMenus(currentScreenState);
            this.detachLevelOneEvents();
            this.attachLevelOneEvents();
            this.updateState({ currentScreenState: newScreenState });
            if (screen.gte(screen.SIZES.XLARGE)) {
                this.unsetHamburgerMenuHeight();
            }
        }

        if (screen.gte(screen.SIZES.XLARGE)) {
            this.levelOneMenuInnerElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_INNER_HIDDEN);
        } else {
            this.setHamburgerMenuHeight();
        }

        this.setSkipNavTabIndex();
    }

    onGlobalNavDealerSearch() {
        const { currentScreenState } = this.getState();
        this.resetMenus(currentScreenState);
    }

    /**
     * @method onLevelThreeBlur
     * @description close level three menu when click outside
     */
    onLevelThreeBlur() {
        const { openLevelOneMenuItemIndex } = this.getState();
        if (openLevelOneMenuItemIndex !== null) {
            this.handleLevelThreeMenus(openLevelOneMenuItemIndex);
        }
    }

    /**
     * @method onLevelOneItemClick
     * @description open/close level one menu
     */
    onLevelOneItemClick(e) {
        let clickedItemIndex = null;
        this.levelOneListButtonElms.some((button, index) => {
            if (e.currentTarget === button) {
                clickedItemIndex = index;
                return true;
            }
            return false;
        });

        if (clickedItemIndex !== null && this.levelOneListButtonElms[clickedItemIndex].href) {
            // it is a link, no need to do anything
            return;
        }

        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.handleLevelTwoMenus(clickedItemIndex);
        } else {
            this.handleLevelThreeMenus(clickedItemIndex);
        }
    }

    /**
     * @method handleLevelTwoMenus
     * @description handle open/close for level two menus, this is for S/L
     * @param clickedItemIndex {int} clicked item index on level one
     */
    handleLevelTwoMenus(clickedItemIndex) {
        const { openLevelOneMenuItemIndex } = this.getState();
        if (openLevelOneMenuItemIndex === null) {
            this.openLevelTwoMenu(clickedItemIndex);
        } else if (openLevelOneMenuItemIndex === clickedItemIndex) {
            this.closeLevelTwoMenu(clickedItemIndex);
            clickedItemIndex = null;
        } else {
            // close the previous opened
            this.closeLevelTwoMenu(openLevelOneMenuItemIndex, clickedItemIndex, true);
        }

        this.updateState({ openLevelOneMenuItemIndex: clickedItemIndex });
    }

    /**
     * @method triggerOpenLevelTwoMenu
     * @description update state and open menu after animation
     * @param openLevelTwoMenuItemIndex {number} item index to be closed
     * @param clickedItemIndex {number} clicked item index
     */
    triggerOpenLevelTwoMenu(openLevelTwoMenuItemIndex, clickedItemIndex) {
        this.updateState({ openLevelTwoMenuItemIndex: null });

        if (this.levelOneListButtonElms[clickedItemIndex]) {
            this.openLevelTwoMenu(clickedItemIndex);
        }
    }

    /**
     * @method handleLevelThreeMenus
     * @description handle open/close for level three menus, this is for XL+
     * @param clickedItemIndex {int} clicked item index on level one
     */
    handleLevelThreeMenus(clickedItemIndex) {
        const { openLevelOneMenuItemIndex } = this.getState();
        if (openLevelOneMenuItemIndex === null) {
            this.levelOneListItemElms[clickedItemIndex].classList.add(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);
            this.levelThreeContainer.activate(clickedItemIndex);
        } else if (openLevelOneMenuItemIndex === clickedItemIndex) {
            this.levelOneListItemElms[clickedItemIndex].classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);
            this.levelThreeContainer.deactivate(clickedItemIndex);
            clickedItemIndex = null;
        } else {
            // close the previous one opened
            this.levelOneListItemElms[openLevelOneMenuItemIndex].classList
                .remove(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);
            this.levelThreeContainer.deactivate(openLevelOneMenuItemIndex);
            // open the one just clicked
            this.levelOneListItemElms[clickedItemIndex].classList.add(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);
            this.levelThreeContainer.activate(clickedItemIndex);
        }
        this.updateState({ openLevelOneMenuItemIndex: clickedItemIndex });
    }

    /**
     * @method onLevelTwoItemClick
     * @description open/close level two menu
     */
    onLevelTwoItemClick(e) {
        const { openLevelOneMenuItemIndex } = this.getState();
        let itemIndex = null;
        let localItemIndex = null;
        this.levelTwoListButtonElms.some((button, index) => {
            if (e.currentTarget === button) {
                itemIndex = index;
                localItemIndex = this.getLocalButtonIndex(index);
                return true;
            }
            return false;
        });

        this.levelOneMenuInnerElm.setAttribute(ATTRIBUTES.ARIA_HIDDEN, 'true');
        if (!screen.gte(screen.SIZES.XLARGE)) {
            this.levelThreeMenuElm.addEventListener(EVENTS.TRANSITION_END, this.onLevelThreeMenuTransition);
        }
        this.levelTwoListButtonElms[itemIndex].setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'true');
        this.levelTwoListItemElms[itemIndex].classList.add(CLASSES.GLOBAL_HEADER_MENU_L2_LIST_ITEM_OPEN);
        this.levelOneMenuElm.classList.add(CLASSES.GLOBAL_HEADER_MENU_L1_NO_SCROLL);
        this.levelThreeContainer.open(openLevelOneMenuItemIndex, localItemIndex);
        this.updateState({
            openLevelTwoMenuItemIndex: localItemIndex
        });
        this.updateBackButtonVisibility(true);
        this.setLevelThreeTabbables();
    }

    /**
     * @method onLevelOneMenuTransition
     * @description handle level one height transitions
     * @param e
     */
    onLevelOneMenuTransition(e) {
        if ((e.propertyName === 'height') && e.target === this.levelOneMenuElm) {
            this.levelOneMenuElm.classList.add(CLASSES.GLOBAL_HEADER_MENU_L1_CLOSED);
            this.levelOneMenuElm.removeEventListener(EVENTS.TRANSITION_END, this.onLevelOneMenuTransition);
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.GLOBAL_NAV_MENU_CLOSED)
            );
        }
    }

    /**
     * @method onLevelThreeMenuTransition
     * @description handle level three transitions for S/L. hides the menu
     *              from screen readers
     * @param e
     */
    onLevelThreeMenuTransition(e) {
        if ((e.propertyName === 'transform') && e.target === this.levelThreeMenuElm) {
            this.levelOneMenuInnerElm.classList.add(CLASSES.GLOBAL_HEADER_MENU_L1_INNER_HIDDEN);
            this.levelThreeMenuElm.removeEventListener(EVENTS.TRANSITION_END, this.onLevelThreeMenuTransition);
            if (this.disableBodyScroll) {
                this.disableBodyScroll.setIgnoreElem(`.${CLASSES.GLOBAL_HEADER_MENU_L3}`);
            }
            this.backButtonElm.focus();
        }
    }

    /**
     * @method checkAuthState
     * @description check is user is authenticated
     */
    checkAuthState() {
        this.authApi = new AuthenticationApi();
        const authState = this.authApi.getAuthState();
        if (authState.loggedState) {
            this.authUtilityLabel = `${window.mbVans.ns('pageData', 'globalHeader').localization.hi} ${authState.accountData.name}`;
            this.setUtilityButtonLabel();
        }
    }

    /**
     * @method setUtilityButtonLabel
     * @description set the text value of the Utility Button
     */
    setUtilityButtonLabel() {
        if (screen.gte(screen.SIZES.XLARGE)) {
            this.utilityLevelOneButtonLabelElm.lastChild.textContent = this.authUtilityLabel;
        } else {
            this.utilityLevelOneButtonLabelElm.lastChild.textContent = this.unauthUtilityLabel;
        }
    }

    getLocalButtonIndex(index) {
        const itemParent = this.levelTwoListItemElms[index].parentElement;
        const children = [...itemParent.children];
        return children.indexOf(this.levelTwoListItemElms[index]);
    }

    /**
     * @method setLevelTwoMenuHeight
     * @description set height for sub navigation based on content
     * @param index {int} index of item
     * @param willClose {boolean} if the menu will be close or open
     */
    setLevelTwoMenuHeight(index, willClose) {
        const contentHeight = willClose ? 0 : `${this.levelTwoMenuElms[index].scrollHeight}px`;
        this.levelTwoMenuElms[index].style.height = contentHeight;
    }

    /**
     * @method openLevelTwoMenu
     * @description open menu level two
     * @param index {int} index of item
     */
    openLevelTwoMenu(index) {
        if (index === CONSTANTS.DEALER_L1_INDEX) {
            this.dealerLevelTwoSearchBar = new DealerSearch(this.dealerLevelTwoContentElm);
        }

        this.setLevelTwoMenuHeight(index, false);
        this.levelOneListButtonElms[index].setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'true');
        this.levelOneListItemElms[index].classList.add(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);
        this.setLevelTwoTabbables(index);
    }

    /**
     * @method closeLevelTwoMenu
     * @description open menu level two
     * @param index {number} index of item
     * @param clickedItemIndex {number} clicked nav item index
     * @param openingLevelTwo {boolean} if new level 2 will be opened
     */
    closeLevelTwoMenu(index, clickedItemIndex, openingLevelTwo) {
        this.levelOneListButtonElms[index].addEventListener(
            EVENTS.TRANSITION_END,
            this.triggerOpenLevelTwoMenu.bind(this, index, clickedItemIndex),
            { once: true }
        );

        if (this.dealerLevelTwoSearchBar && index !== CONSTANTS.DEALER_L1_INDEX) {
            this.dealerLevelTwoSearchBar.destroy();
            this.dealerLevelTwoSearchBar = null;
        }

        this.setLevelTwoMenuHeight(index, true);
        this.levelOneListButtonElms[index].setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'false');
        this.levelOneListItemElms[index].classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);

        if (!openingLevelTwo) {
            this.setLevelOneTabbables();
        }
    }

    /**
     * @method onHamburgerButtonClick
     * @description open/close when click on the hamburger button
     */
    onHamburgerButtonClick() {
        this.toggleHamburgerMenu();
    }

    /**
     * @method toggleHamburgerMenu
     * @description open/close hamburger menu
     */
    toggleHamburgerMenu() {
        let { levelOneMenuOpen } = this.getState();
        levelOneMenuOpen = !levelOneMenuOpen;
        this.updateState({ levelOneMenuOpen });
        if (levelOneMenuOpen) {
            this.beginHamburgerMenuOpen();
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.GLOBAL_NAV_MENU_OPENED)
            );
            // Check for default menu to open
            let defaultOpenMenuIndex = null;
            this.levelOneListButtonElms.some((button, index) => {
                const defaultOpenMenu = button.getAttribute(ATTRIBUTES.DEFAULT_OPEN_MENU);
                if (defaultOpenMenu) {
                    defaultOpenMenuIndex = index;
                    return true;
                }
                return false;
            });
            this.updateState({
                forceFocusItemIndex: 0
            });
            if (defaultOpenMenuIndex !== null) {
                this.handleLevelTwoMenus(defaultOpenMenuIndex);
            }
        } else {
            this.closeHambugerMenu();
        }
    }

    /**
     * @method beginHamburgerMenuOpen
     * @description begin steps to open hamburger menu
     */
    beginHamburgerMenuOpen() {
        if (!this.levelOneMenuElm.classList.contains(CLASSES.GLOBAL_HEADER_MENU_L1_CLOSED)) {
            window.requestAnimationFrame(this.finishHamburgerMenuOpen);
        } else {
            this.levelOneMenuElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_CLOSED);
            window.requestAnimationFrame(this.beginHamburgerMenuOpen);
        }
    }

    /**
     * @method finishHamburgerMenuOpen
     * @description complete steps to open hamburger menu
     */
    finishHamburgerMenuOpen() {
        const { hamburgerMenuHeight } = this.getState();
        this.levelOneMenuElm.style.height = `${hamburgerMenuHeight}px`;
        this.levelOneMenuInnerElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_INNER_HIDDEN);
        this.disableBodyScroll = new DisableBodyScroll(
            `.${CLASSES.GLOBAL_HEADER_MENU_L1}`,
            {
                largeIgnore: true
            }
        );
        this.setAttributeOnBodyTag();
        this.setAriaValues();
        this.setLevelOneTabbables();
        document.body.addEventListener(EVENTS.KEYDOWN, this.onKeydownHandler);
        this.updateState({
            forceFocusItemIndex: null
        });
    }

    /**
     * @method closeHambugerMenu
     * @description close hamburger menu
     */
    closeHambugerMenu(resetting = false) {
        if (!resetting) {
            this.levelOneMenuElm.addEventListener(EVENTS.TRANSITION_END, this.onLevelOneMenuTransition);
        }
        if (this.disableBodyScroll) {
            this.disableBodyScroll.destroy();
        }
        this.setAriaValues();
        this.removeAttributeFromBodyTag();
        this.levelOneMenuElm.style.height = '';
        const { openLevelOneMenuItemIndex } = this.getState();
        if (openLevelOneMenuItemIndex !== null) {
            this.closeMenus();
        }
        this.disableTabbables();
        document.body.removeEventListener(EVENTS.KEYDOWN, this.onKeydownHandler);
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.GLOBAL_NAV_MENU_CLOSED)
        );

        this.levelOneMenuElm.scrollTo(0, 0);
    }

    /**
     * @method onBackButtonClick
     * @description go back to main menu view
     */
    onBackButtonClick() {
        const { openLevelOneMenuItemIndex, openLevelTwoMenuItemIndex } = this.getState();
        if (openLevelTwoMenuItemIndex !== null) {
            this.levelOneMenuInnerElm.setAttribute(ATTRIBUTES.ARIA_HIDDEN, 'false');
            this.levelOneMenuInnerElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_INNER_HIDDEN);
            this.levelTwoListButtonElms[openLevelTwoMenuItemIndex].setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'false');
            this.levelTwoListItemElms[openLevelTwoMenuItemIndex].classList
                .remove(CLASSES.GLOBAL_HEADER_MENU_L2_LIST_ITEM_OPEN);
            if (this.disableBodyScroll) {
                this.disableBodyScroll.setIgnoreElem(`.${CLASSES.GLOBAL_HEADER_MENU_L1}`);
            }
            this.levelThreeContainer.close();
            this.levelOneMenuElm.classList.remove(CLASSES.GLOBAL_HEADER_MENU_L1_NO_SCROLL);
            this.updateBackButtonVisibility(false);
            this.setLevelTwoTabbables(openLevelOneMenuItemIndex);
            this.levelTwoListButtonElms[openLevelTwoMenuItemIndex].focus();
        }
    }

    /**
     * @method closeMenus
     * @description close menus
     */
    closeMenus() {
        // close menu L1 if it is open
        const { openLevelOneMenuItemIndex } = this.getState();
        this.levelOneListButtonElms[openLevelOneMenuItemIndex].setAttribute(ATTRIBUTES.ARIA_EXPANDED, 'false');
        this.levelOneListItemElms[openLevelOneMenuItemIndex].classList
            .remove(CLASSES.GLOBAL_HEADER_MENU_L1_LIST_ITEM_OPEN);
        this.setLevelTwoMenuHeight(openLevelOneMenuItemIndex, true);
        this.onBackButtonClick();
        this.updateState({
            openLevelOneMenuItemIndex: null,
            openLevelTwoMenuItemIndex: null
        });
    }

    /**
     * @method updateBackButtonVisibility
     * @description set class depending on visibility
     * @param show {boolean} show or hide the back button
     */
    updateBackButtonVisibility(show) {
        this.backButtonElm.classList[show ? 'add' : 'remove'](CLASSES.TOP_BAR_BACK_BUTTON_VISIBLE);
    }

    /**
     * @method setAriaValues
     * @description set Aria attributes for when the top nav is open
     */
    setAriaValues() {
        this.levelOneMenuElm.setAttribute(ATTRIBUTES.ARIA_EXPANDED, this.getState().levelOneMenuOpen);
        this.hamburgerButtonElm.setAttribute(ATTRIBUTES.ARIA_EXPANDED, this.getState().levelOneMenuOpen);
        if (this.getState().levelOneMenuOpen) {
            this.hamburgerButtonElm.setAttribute(ATTRIBUTES.ARIA_LABEL, CONSTANTS.CLOSE_BUTTON_LABEL);
        } else {
            this.hamburgerButtonElm.setAttribute(ATTRIBUTES.ARIA_LABEL, CONSTANTS.HAMBURGER_BUTTON_LABEL);
        }
    }

    /**
     * @method setAttributeOnBodyTag
     * @description set body attribute for menu state open
     */
    setAttributeOnBodyTag() {
        this.bodyElm.setAttribute(ATTRIBUTES.MENU_STATE, CONSTANTS.MENU_OPEN);
    }

    /**
     * @method removeAttributeFromBodyTag
     * @description remove body attribute for menu state close
     */
    removeAttributeFromBodyTag() {
        this.bodyElm.removeAttribute(ATTRIBUTES.MENU_STATE);
    }

    /**
     * @method resetMenus
     * @description reset all menu levels on screen resize
     * @param previousScreenState {String} previous screen breakpoint
     */
    resetMenus(previousScreenState) {
        const { levelOneMenuOpen, openLevelOneMenuItemIndex } = this.getState();
        if (previousScreenState === screen.SIZES.SMALL || previousScreenState === screen.SIZES.LARGE) {
            if (levelOneMenuOpen) {
                this.updateState({
                    levelOneMenuOpen: false
                });
                this.closeHambugerMenu(true);
            }
        } else if (openLevelOneMenuItemIndex !== null) {
            this.levelThreeContainer.reset();
            this.closeMenus();
        }
    }

    /**
    * setSkipNavTabIndex
    * Set tab index according to screen size
    */
    setSkipNavTabIndex() {
        if (screen.gte(screen.SIZES.XLARGE)) {
            this.skipNavBtnElm.setAttribute(ATTRIBUTES.TABINDEX, '0');
        } else {
            this.skipNavBtnElm.setAttribute(ATTRIBUTES.TABINDEX, '-1');
        }
    }

     /**
     * Keydown handler
     * Traps focus within footer
     */
    onKeydownHandler(e) {
        const { levelOneMenuOpen } = this.getState();
        if (levelOneMenuOpen) {
            // A list of focusable elements
            const listFocusables = this.tabbables.allTabbables;
            if (e.keyCode === KEYBOARD.TAB && listFocusables.indexOf(e.target) >= 0) {
                if (e.shiftKey) {
                    if (e.target === listFocusables[0]) {
                        listFocusables[listFocusables.length - 1].focus();
                        e.preventDefault();
                    }
                } else if (e.target === listFocusables[listFocusables.length - 1]) {
                    listFocusables[0].focus();
                    e.preventDefault();
                }
            }
        }
    }

    /**
     * @method activateLocatorInput
     * @description allow scrolling for movile devices when using keyboard to see search results.
     * @constant {number} the input field item must have a height which can accommodate the search options.
     * @function scrollTo for Android, must be done right after the keyboard shows up.
     */
    activateLocatorInput() {
        const inputHeight = 300;

        if (event.target.classList.contains(CLASSES.GLOBAL_HEADER_MENU_L2_INPUT_FIELD) &&
            this.delerInputFieldItem.offsetHeight < inputHeight) {
            this.delerInputFieldItem.style.height = `${inputHeight}px`;

            if (!isiOSMobile()) {
                setTimeout(() => {
                    this.levelOneMenuElm.scrollTo(0, 250);
                }, 500);
            }
        }
    }

    /**
     * @method updateState
     * @description update State of the component
     * @param newState {object} new state value
     */
    updateState(newState) {
        this.state = { ...this.state, ...newState };
    }

    /**
     * @method getState
     * @description update State of the component
     * @return {object} state of the component
     */
    getState() {
        return this.state;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

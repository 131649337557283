// Util dependencies
import { htmlNode } from 'utils';

import content from '../config/content';

/**
 * @description Template for YouTube trigger element
 * Uses ES6 template literal to composite the markup
 */
export default (smallImage, largeImage, altText = '', imgClass = '', pictureClass = '', buttonClass = '') =>
    htmlNode`<button class="youtube-trigger ${buttonClass}"
            aria-label="${content.modalAlertYouTube}">
        <picture class="youtube-trigger__picture ${pictureClass}">
            <source media="(max-width: 767px)" srcset="${smallImage}" />
            <img src="${largeImage}" alt="${altText}" class="youtube-trigger__image ${imgClass}" role="${altText ? false : 'presentation'}" />
        </picture>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             xml:space="preserve" viewBox="0 0 62 62" class="youtube-trigger__icon-play">
            <circle class="youtube-trigger__circle" cx="31" cy="31" r="31"></circle>
            <polygon class="youtube-trigger__triangle" points="24,17.6 24,43.4 41.3,30.4 "></polygon>
        </svg>
    </button>`;

// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partial dependencies
import { Modal } from 'partials/modal';

// Utils dependencies
import {
    noop,
    renderer
} from 'utils';

// Local dependencies
import DealerSelector from './DealerSelector';

/**
 * @constant defaultConfig
 * @description Default configuration for the view
 * @type {{disableSelectedDealer: boolean, onClose: *}}
 */
const defaultConfig = {
    dealer: null,
    displaySelectedDealer: true,
    callingElementCta: null, // for accessibility when closing modal focus back to clicked CTA
    enableSelectedDealer: true,
    filterBy: '',
    isNewInventory: true,
    onClose: noop,
    onDealerContinue: noop,
    onDealerSelect: noop
};

export default class DealerSelectorModal {
    /**
     * @static FILTER_TYPES
     * @description FilterBy key values
     * @type {{INVENTORY: string}}
     */
    static FILTER_TYPES = { ...DealerSelector.FILTER_TYPES };

    /**
     * @constructor
     * @description On instantiation, sets configuration and initializes
     * the view by creating an instance of the DealerSelector and Modal
     * @param config {Object} Configuration of view
     * @param config.dealer: {[Object]} Optional dealer object to set
     * @param config.enableSelectedDealer: {[Boolean]} Flag for whether to disable displaying
     *                                           the active/selected dealer when loading
     * @param config.displaySelectedDealer {string} Flag for whether to display
     *                                       the selected dealer as the active dealer
     * @param config.onClose {Function} Callback to apply when the modal closes
     * @param config.onDealerContinue {function} Callback when dealer "continue is selected
     * @param config.onDealerSelect {function} Callback when dealer is selected
     *
     */
    constructor(config) {
        this.config = {
            ...defaultConfig,
            ...config
        };
        this.dealerSelector = null;

        this.createDealerSelector();
        this.createModal();
    }

    /**
     * @method createDealerSelector
     * @description Creates an instance of a DealerSelector
     */
    createDealerSelector() {
        this.dealerSelectorElm = renderer.fromTemplate('<div class="dealer-selector-modal"></div>');
        this.dealerSelector = new DealerSelector({
            dealer: this.config.dealer,
            displaySelectedDealer: this.config.displaySelectedDealer,
            enableSelectedDealer: this.config.enableSelectedDealer,
            filterBy: this.config.filterBy,
            isNewInventory: this.config.isNewInventory,
            onDealerContinue: this.config.onDealerContinue,
            onDealerSelect: this.config.onDealerSelect,
            location: this.config.location,
            mapModalToggleDisabled: true, // disabled small view toggle map because this is opening from a modal
        });

        this.dealerSelectorElm.appendChild(this.dealerSelector.render());
    }

    /**
     * @method createModal
     * @description Creates an instance of a modal and passes the dealerSelector
     * as the contents and `beforeClose` callback
     */
    createModal() {
        this.modal = new Modal(undefined, {
            modalContent: this.dealerSelectorElm,
            callbacks: {
                beforeClose: this.config.onClose.bind(this)
            },
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.DEFAULT,
            theme: Modal.THEMES.DISABLE_OVERFLOW
        });
    }

    /**
     * @method destroy
     * @method Destroys the instances of the modal and dealerSelector
     */
    destroy() {
        this.modal.destroy();
        this.dealerSelector.destroy();
        this.modal = null;
        this.dealerSelector = null;
    }

    /**
     * @method close
     * @description Public method to open the modal
     */
    open() {
        this.modal.open({ callingContainer: this.config.callingElementCta });
    }

    /**
     * @method close
     * @description Public method the modal
     */
    close() {
        this.modal.close();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

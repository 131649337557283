import urgentRecallsPartsTemplate from './../templates/urgentRecallsPartsTemplate';

const CLASSES = {
    CHECK_IMG: 'urgent-recalls-parts__check',
    PARTS_STATUS: 'urgent-recalls-parts__parts-status'
};

export default class UrgentRecallsParts {
    constructor(content, config, chosenModel) {
        const currentDate = new Date();
        const availabilityDate = chosenModel.map((c) => new Date(c['Month Effective Launch Date'])).sort()[0];
        this.chosenModel = chosenModel;
        this.content = content;
        this.currentDate = currentDate;
        this.element = urgentRecallsPartsTemplate(content, config)({ getNode: true });

        this.cacheDOM();
        this.renderPartsAvailability(currentDate, availabilityDate);
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.checkElm = this.element.querySelector(`.${CLASSES.CHECK_IMG}`);
        this.partsStatusElm = this.element.querySelector(`.${CLASSES.PARTS_STATUS}`);
    }

    /**
     * @method renderPartsAvailability
     * @description Renders a different status for the part availability with time comparisons
     * @param {Array} currentDate Current date formatted into an array of strings
     * @param {Array} partDate Date of part availability formatted into an array of strings
     */
    renderPartsAvailability(currentDate, partDate) {
        if (currentDate >= partDate) {
            this.checkElm.classList.remove('hide');
            this.partsStatusElm.innerHTML = this.content.partsAvailability.partsAvailable;
        } else if (currentDate < partDate) {
            this.partsStatusElm.innerHTML = this.content.partsAvailability.partsUnavailable;
        }
    }

    render() {
        return this.element;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

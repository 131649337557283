import supportTemplate from './supportTemplate';

export {
    supportTemplate
};

export default {
    supportTemplate
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

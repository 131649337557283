/**
 * @const defaultDLFlags
 * @description Default localization labels for Dealer Locator Plugin
 * @type {{label: String}}
 */
const defaultDLFlags = {
    showSubheading: true,
    showSearchContainer: true,
    showChangeLocation: true,
    showFilterBar: false,
    showNearbyLocations: false,
    showDealerHours: false,
    usePrimaryCTA: false,
    showSecondaryCTA: false,
    showDistance: false,
    showBrowseState: false,
    showLoadMore: true,
    inlinePreferredDealer: true,
    showPhoneNumbers: false,
    showArrowIndicator: false,
    showFullAddress: false,
    showVanCare: false,
};

// Deconstruct localization object
const {
    showSubheading,
    showSearchContainer,
    showChangeLocation,
    showFilterBar,
    showNearbyLocations,
    showDealerHours,
    usePrimaryCTA,
    showSecondaryCTA,
    showDistance,
    showBrowseState,
    showLoadMore,
    inlinePreferredDealer,
    showPhoneNumbers,
    showArrowIndicator,
    showFullAddress,
    showVanCare,
} = Object.assign(defaultDLFlags, window.mbVans.ns('pageData', 'dealerLocatorPlugin', 'config').featureFlags);

export default {
    showSubheading,
    showSearchContainer,
    showChangeLocation,
    showFilterBar,
    showNearbyLocations,
    showDealerHours,
    usePrimaryCTA,
    showSecondaryCTA,
    showDistance,
    showBrowseState,
    showLoadMore,
    inlinePreferredDealer,
    showPhoneNumbers,
    showArrowIndicator,
    showFullAddress,
    showVanCare,
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Partials
import { Modal } from 'partials/modal';

// Local dependencies
import QuickRegistration from './QuickRegistration';
import QuickRegistrationReminder from './QuickRegistrationReminder';

// Default Configuration
const defaultConfig = {
    callingContainer: null,
    callingContainerId: null,
    dataAnalyticContainer: "{'blade': 'quick registration modal'}",
    dataAnalyticTriggerClose: 'cta-close',
    modalTitle: '',
    sizeSmall: Modal.SIZES.FULLSCREEN,
    sizeLarge: Modal.SIZES.INSET,
    theme: Modal.THEMES.SIDE_PANEL,
};

/**
 * @const MODAL_CONTAINER
 * @description Attribute of the Modal content container DOM element
 * @type {string}
 */
const MODAL_CONTAINER = 'data-modal-content';

/**
 * @class  QuickRegistrationModal
 * @description  Display a modal that contains the QuickRegistration View
 * @param {Object} content
 * @param {Object} config
 */
export default class QuickRegistrationModal {
    /**
     * @method constructor
     * @description  setup the initial state for the view
     * @param {Object} modalConfig configuration object for the modal component
     * @param {Object} quickRegConfig configuration object for the quick reg component
     * @param {Object} content content for Quick Reg Modal
     */
    constructor(modalConfig = defaultConfig, quickRegConfig = {}, content = {}, isUnverified = false) {
        this.modalConfig = { ...defaultConfig, ...modalConfig };
        this.modal = null;

        this.onComplete = this.onComplete.bind(this);

        if (isUnverified) {
            this.quickReg = new QuickRegistrationReminder();
        } else {
            this.quickReg = new QuickRegistration(
                {
                    onCompleteCallback: this.onComplete,
                    onUpdateTitleCallback: this.updateTitle.bind(this),
                    onRegistrationCallback: this.modalConfig.onRegistrationCallback,
                    ...quickRegConfig
                },
                content
            );
        }
        this.modalContent = null;
        this.afterOpen = this.afterOpen.bind(this);
        this.beforeClose = this.beforeClose.bind(this);
        this.updateTitle = this.updateTitle.bind(this);

        this.init();
    }

    /**
     * @method  init
     * @description  initilize the class
     */
    init() {
        this.createModal();
    }

    /**
     * @method createModal
     * @description Creates a Modal instance and applies its properties
     */
    createModal() {
        this.modal = new Modal(undefined, {
            callbacks: {
                afterOpen: this.afterOpen,
                beforeClose: this.beforeClose
            },
            dataAnalyticContainer: this.modalConfig.dataAnalyticContainer,
            dataAnalyticTriggerClose: this.modalConfig.dataAnalyticTriggerClose,
            modalContent: undefined,
            modalTitle: this.modalConfig.modalTitle,
            sizeLarge: this.modalConfig.sizeLarge,
            sizeSmall: this.modalConfig.sizeSmall,
            theme: this.modalConfig.theme
        });
    }

    /**
     * @method afterOpen
     * @description Callback to render the children to the Modal DOM container
     * then calls the afterOpen prop callback
     */
    afterOpen() {
        if (this.getModalContainer()) {
            this.modalContent = document.createElement('div');
            this.modalContent.classList.add('mbs-quick-registration__modal');
            this.modalContent.appendChild(this.quickReg.render());
            this.getModalContainer().appendChild(this.modalContent);
            this.updateTitle(this.quickReg.getTitle());
        }
    }

    /**
     * @method beforeClose
     * @description Callback to call the beforeClose prop callback
     * then removes the children to the Modal DOM container
     */
    beforeClose() {
        if (this.getModalContainer()) {
            this.getModalContainer().removeChild(this.modalContent);
        }
    }

    /**
     * @method getModalContainer
     * @description Retrieves the modal container DOM node
     * @return {Element}
     */
    getModalContainer() {
        return document.querySelector(`[${MODAL_CONTAINER}]`);
    }

    /**
     * @method open
     * @description Calls a method to open the modal instance
     */
    open() {
        // pass the callingContainer or id here
        const { callingContainer, callingContainerId } = this.modalConfig;

        if (callingContainer) {
            this.modal.open({ callingContainer });
        } else if (callingContainerId) {
            this.modal.open({ callingContainerId });
        } else {
            this.modal.open();
        }
    }

    /**
     * @method close
     * @description Calls a method to close the modal instance
     */
    close() {
        this.modal.close();
    }

    /**
     * @method onComplete
     * @description Calls callback method that needs to run when registration is completed then closes modal
     */
    onComplete() {
        if (this.modalConfig.onCompleteCallback) {
            this.modalConfig.onCompleteCallback();
        }
        this.close();
    }

    /**
     * @method updateTitle
     * @description Calls modal method to update the title
     * @param newTitle {String} contains new title to update modal when contents change
     */
    updateTitle(newTitle) {
        this.modal.updateTitle(newTitle);
    }

    /**
     * @method getActiveState
     * @description identifies whether the modal is active or not
     * @returns {*|boolean}
     */
    getActiveState() {
        return this.modal.getActiveState();
    }

    /**
     * @method destroyModal
     * @description Destroys the modal instance and unmounts the modalContent
     */
    destroy() {
        this.modal.destroy();
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d
